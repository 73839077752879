import React from 'react';
import { FormGroup, Row, Col, Label, Input } from "reactstrap";

const ThirdCard = ({ conta }) => {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Histórico Padrão</Label>
                            <Input readOnly defaultValue={!!conta.ctn_ds_hist_padrao ? conta.ctn_ds_hist_padrao : "Não Informado"} />
                        </FormGroup>
                    </Col>
                    {/* <Col md="12" xs="12">
                            <FormGroup>
                                <Label>Mnemônico</Label>
                                <Input readOnly defaultValue={!!conta.ctn_ds_mnemonico ? conta.ctn_ds_mnemonico : "Não Informado"} />
                            </FormGroup>
                        </Col> */}
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Contabilização</Label>
                            <Input readOnly defaultValue={!!conta.ctn_st_contabiliza ?
                                conta.ctn_st_contabiliza === "C" ? "Conta" :
                                    conta.ctn_st_contabiliza === "P" ? "Pessoa" :
                                        conta.ctn_st_contabiliza === "N" && "Não Contabilização" : "Não Informado"} />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Conta</Label>
                            <Input readOnly defaultValue={!!conta.ctn_cd_conta_contabil ? conta.ctn_cd_conta_contabil : "Não Informado"} />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Histórico</Label>
                            <Input readOnly defaultValue={!!conta.ctn_cd_hist_padrao ? conta.ctn_cd_hist_padrao : "Não Informado"} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default ThirdCard;