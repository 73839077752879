import { all } from 'redux-saga/effects';
import authSagas from "./auth/saga";
import funcionalidadesSagas from './funcionalidades/saga';
import modulosSagas from './modulos/saga';
import groupsSagas from './groups/saga';
import permissionsSagas from "./permissions/saga";
import usersSagas from "./users/saga";
import departamentosSagas from "./departamentos/saga";
import contasSagas from "./contas/saga";
import contasGruposSagas from "./contasGrupos/saga";
import gruposPaiSagas from "./gruposPai/saga";
import empresasSagas from "./empresas/saga";
import pessoasSagas from "./pessoas/saga";
import estadosSagas from "./estados/saga";
import cidadesSagas from "./cidades/saga";
import tipoPessoasSagas from "./tipoPessoas/saga";
import edocsSagas from "./edocs/saga";
import lancamentosSagas from "./lancamentos/saga";
import centroCustoSagas from "./centroCusto/saga";
import faturasSagas from "./faturas/saga";
import vlaLancamentosSagas from "./vlaLancamentos/saga";
import selCentrosCustoSagas from "./selCentrosCusto/saga";
import rltGerenciaisSagas from "./relatoriosGerenciais/saga";
import rltOperacionaisSagas from "./relatorioOperacional/saga";
import arquivosSagas from "./arquivos/saga";
import clientesSagas from "./clientes/saga";
import tiposEmpresaSagas from "./tiposEmpresa/saga";
import cmvDiarioSagas from "./cmvDiario/saga";
import tipoMovPrdSagas from "./tipoMovPrd/saga";
import produtosSagas from "./produtos/saga";
import notasFiscaisSagas from "./notasFiscais/saga";
import feriadosSagas from "./feriados/saga";

export default function* rootSaga() {
    yield all([
        authSagas(),
        funcionalidadesSagas(),
        modulosSagas(),
        groupsSagas(),
        permissionsSagas(),
        usersSagas(),
        departamentosSagas(),
        contasGruposSagas(),
        gruposPaiSagas(),
        empresasSagas(),
        contasSagas(),
        pessoasSagas(),
        estadosSagas(),
        cidadesSagas(),
        tipoPessoasSagas(),
        edocsSagas(),
        lancamentosSagas(),
        centroCustoSagas(),
        faturasSagas(),
        vlaLancamentosSagas(),
        selCentrosCustoSagas(),
        rltGerenciaisSagas(),
        rltOperacionaisSagas(),
        arquivosSagas(),
        clientesSagas(),
        tiposEmpresaSagas(),
        cmvDiarioSagas(),
        tipoMovPrdSagas(),
        produtosSagas(),
        notasFiscaisSagas(),
        feriadosSagas()
    ]);
}
