import React, { useState, useCallback, useRef, useMemo } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import {
  getListPessoas,
  getFaturasSemBaixa,
  baixarFaturas,
  getListContasBancos,
} from "../../../../../redux/actions";
import Opcoes from "./Opcoes";
import Pessoas from "./Pessoas";
import SelectPagination from "../../../../../components/SelectPagination";
import sweetalert2 from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import NumberFormat from "react-number-format";

const initialState = {
  conta: {},
  baixar_rec_ant: false,
  baixar_adt_for: false,
  baixa_parcial: false,
  tipo_baixa: "",
};

function TabFaturas({
  ftr_sem_baixa,
  loadingFtrSemBaixa,
  getFaturasSemBaixa,
  baixarFaturas,
  loadingBaixarFtr,
  onSave,
  listBancos,
  loadingListCtnBancos,
  getListContasBancos,
}) {
  //ESTADOS
  const [filter, setFilter] = useState({
    ftr_dt_vencimento: format(new Date(), "yyyy-MM-dd"),
    ftr_vl_fatura: null,
    pessoas: [],
    vencimentos_dia: false,
  });
  const [fields, setFields] = useState(initialState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [, updateState] = useState();

  //VARIÁVEIS
  const curFilter = useRef(null);
  const optionsBancos = useMemo(
    () =>
      listBancos.data.map(
        ({ ctn_nm_conta, ctn_cd_conta, pessoa, ctn_st_lan_deb_cre }) => ({
          name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
          value: ctn_cd_conta,
          tiposLancamento: ctn_st_lan_deb_cre,
          pessoa,
        })
      ),
    [listBancos.data]
  );

  const optionsTipoBaixa = useMemo(() => {
    const res = [
      { name: "Débito", value: "D" },
      { name: "Crédito", value: "C" },
    ];
    if (!!fields.conta.value) {
      if (fields.conta.tiposLancamento === "D") {
        res.splice(1, 1);
      } else if (fields.conta.tiposLancamento === "C") {
        res.splice(0, 1);
      }
    }
    return res;
  }, [fields.conta.value, fields.baixar_rec_ant]);

  //FUNÇÕES
  const forceUpdate = useCallback(() => updateState({}), []);
  const onSubmitFilter = (e) => {
    e.preventDefault();
    getFaturasSemBaixa({ fields: { ...filter } });
    curFilter.current = filter;
    setToggleCleared(!toggleCleared);
    setFields(initialState);
  };

  const calculaJuros = (dtVencimento, jurosDiario) => {
    const dia = 24 * 60 * 60 * 1000;
    const vecimento = new Date(dtVencimento);
    const hoje = new Date();
    const diffDays = Math.round(Math.abs(Math.ceil((vecimento - hoje) / dia)));

    return {
      total_dias: diffDays,
      total_juros: parseFloat(jurosDiario) * diffDays,
    };
  };

  const realizarBaixa = async () => {
    const confirm = await sweetalert2.fire({
      title: "Deseja realmente baixar as futuras?",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      reverseButtons: true,
      icon: "question",
    });

    if (confirm.isConfirmed && validator.allValid()) {
      const faturas = ftr_sem_baixa.data.filter(({ ftr_id_fatura }) => {
        return !!selectedRows.find((el) => el.id === ftr_id_fatura);
      });
      const { baixar_rec_ant, tipo_baixa, baixar_adt_for, baixa_parcial } =
        fields;
      faturas.forEach((el, index) => {
        if (!el.ftr_dt_pagamento) {
          faturas[index].ftr_dt_pagamento = curFilter.current.ftr_dt_vencimento;
        }
        // if (!!el.ftr_vl_fatura_parcial && baixa_parcial) {
        //     faturas[index].ftr_vl_fatura = el.ftr_vl_fatura_parcial;
        //     delete el.ftr_vl_fatura_parcial;
        // }
        faturas[index].ftr_nu_cnt_baixa = fields.conta.value;
        faturas[index].conta_baixa = fields.conta;
      });
      baixarFaturas({
        fields: {
          faturas,
          baixar_rec_ant,
          tipo_baixa,
          baixar_adt_for,
          baixa_parcial,
        },
        onSave,
      });
    } else {
      validator.showMessages();
      toast.error("Preencha os campos corretamente.", toast.POSITION.TOP_RIGHT);
    }
  };

  const handleSelectedRows = useCallback(({ selectedRows }) => {
    setSelectedRows(selectedRows.map(({ id }) => ({ id })));
  }, []);

  const handleChange = (e) => {
    const { checked, value, name } = e.target;

    switch (name) {
      case "vencimentos_dia":
        setFilter({ ...filter, [name]: checked });
        break;
      case "baixar_adt_for":
        setFields({ ...fields, [name]: checked });
        break;
      case "baixa_parcial":
        setFields({ ...fields, [name]: checked });
        !checked &&
          ftr_sem_baixa.data.forEach((ftr) => {
            delete ftr.ftr_vl_fatura_parcial;
          });
        break;
      case "baixar_rec_ant":
        setFields({ ...fields, [name]: checked, conta: {} });
        break;
      default:
        setFilter({ ...filter, [name]: value });
        break;
    }
  };

  const handleChangeSelect = (name, selected) => {
    switch (name) {
      case "ftr_dt_vencimento":
        if (!!selected) {
          setFilter({ ...filter, [name]: format(selected, "yyyy-MM-dd") });
        } else {
          setFilter({ ...filter, [name]: format(new Date(), "yyyy-MM-dd") });
        }
        break;
      case "pessoas":
        if (!!selected.length) {
          setFilter({ ...filter, [name]: selected.map(({ value }) => value) });
        } else {
          setFilter({ ...filter, [name]: [] });
        }
        break;
      case "conta":
        if (!!selected.length) {
          setFields({ ...fields, [name]: selected[0] });
        } else {
          setFields({ ...fields, [name]: {} });
        }
        break;
      case "tipo_baixa":
        if (!!selected.length) {
          setFields({ ...fields, [name]: selected[0].value });
        } else {
          setFields({ ...fields, [name]: "" });
        }
        break;
      default:
        if (!!selected.length) {
          setFilter({ ...filter, [name]: selected[0].value });
        } else {
          setFilter({ ...filter, [name]: null });
        }
        break;
    }
  };
  const handleValor = (name, value) => {
    switch (name) {
      default:
        if (!!value) {
          setFilter({ ...filter, [name]: value });
        } else {
          setFilter({ ...filter, [name]: "" });
        }
        break;
    }
  };
  const validFields = (name) => {
    if (!validator.fieldValid(name)) {
      validator.showMessageFor(name);
    } else {
      validator.hideMessageFor(name);
    }
  };

  //VALIDATOR
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate },
      messages: {
        required: "Este Campo é obrigatório.",
        email: "Email inválido.",
        in: "As senhas devem ser iguais.",
        min: "Este campo deve ter no minímo :min caracteres.",
      },
    })
  ).current;
  validator.purgeFields();

  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={onSubmitFilter}>
            <Row>
              <Col lg="6" md="12">
                <Opcoes
                  handleChangeSelect={handleChangeSelect}
                  filter={filter}
                  handleChange={handleChange}
                  handleValor={handleValor}
                />
              </Col>
              <Col lg="6" md="12">
                <Pessoas handleChangeSelect={handleChangeSelect} />
              </Col>
              <Col lg="6" md="12">
                <FormGroup>
                  <Button title="Pesquisar" color="primary">
                    <i className="fa fa-search p-r-5"></i> Pesquisar
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs="12">
          {loadingFtrSemBaixa ? (
            <div className="loader-box">
              <div className="loader-1"></div>
            </div>
          ) : (
            !!Object.keys(ftr_sem_baixa).length && (
              <DataTable
                persistTableHead
                pagination
                selectableRows
                paginationServer
                onSelectedRowsChange={handleSelectedRows}
                paginationPerPage={ftr_sem_baixa.per_page}
                clearSelectedRows={toggleCleared}
                paginationDefaultPage={ftr_sem_baixa.current_page}
                paginationTotalRows={ftr_sem_baixa.total}
                progressPending={loadingFtrSemBaixa || loadingBaixarFtr}
                customStyles={{
                  header: {
                    style: {
                      overflow: selectedRows.length ? "inherit" : "hidden",
                    },
                  },
                }}
                contextActions={[
                  <div key={0} className="m-r-10">
                    <Label className="f-14 d-block m-b-0">
                      <Input
                        className={`checkbox_animated`}
                        name="baixa_parcial"
                        onChange={handleChange}
                        id={`chk-ani123`}
                        type="checkbox"
                      />
                      Baixa Parcial
                    </Label>
                  </div>,
                  <div key={1} className="m-r-10">
                    <Label className="f-14 d-block m-b-0">
                      <Input
                        className={`checkbox_animated ${
                          fields.baixar_adt_for ? "disabled" : ""
                        }`}
                        name="baixar_rec_ant"
                        disabled={fields.baixar_adt_for}
                        onChange={handleChange}
                        id={`chk-ani00`}
                        type="checkbox"
                      />
                      Baixa p/ receita ant.
                    </Label>
                    <Label className="f-14">
                      <Input
                        className={`checkbox_animated ${
                          fields.baixar_rec_ant ? "disabled" : ""
                        }`}
                        disabled={fields.baixar_rec_ant}
                        name="baixar_adt_for"
                        onChange={handleChange}
                        id={`chk-ani01`}
                        type="checkbox"
                      />
                      Baixa p/ Adiantamento a Fornecedor
                    </Label>
                  </div>,
                  <div key={2} style={{ width: 400 }} className="m-r-10">
                    <SelectPagination
                      id="selectBanco"
                      clearButton
                      labelKey={(option) => `${option.name}`}
                      placeholder="Informe o Banco"
                      maxResults={50}
                      isLoading={loadingListCtnBancos}
                      disabled={fields.baixar_rec_ant}
                      selected={(options) =>
                        options.filter(
                          (option) => option.value === fields.conta?.value
                        )
                      }
                      totalResults={listBancos.total}
                      data={optionsBancos}
                      onBlur={() => validFields("conta")}
                      reduxAction={getListContasBancos}
                      onChange={(selected) =>
                        handleChangeSelect("conta", selected)
                      }
                      isInvalid={
                        (validator.visibleFields.includes("conta") ||
                          (!validator.fields.conta &&
                            validator.messagesShown)) &&
                        !fields.baixar_rec_ant
                      }
                    />
                    <FormFeedback
                      style={{
                        fontSize: "50%",
                        display:
                          validator.visibleFields.includes("conta") ||
                          (!validator.fields.conta &&
                            validator.messagesShown &&
                            !fields.baixar_rec_ant)
                            ? "block"
                            : "none",
                      }}
                    >
                      {!fields.baixar_rec_ant &&
                        validator.message(
                          "conta",
                          fields.conta?.name,
                          "required"
                        )}
                    </FormFeedback>
                  </div>,
                  <div key={3} className="m-r-10">
                    <Typeahead
                      id="selectTipoBaixa"
                      clearButton
                      disabled={!fields.conta.name && !fields.baixar_rec_ant}
                      labelKey="name"
                      onBlur={() => validFields("tipo_baixa")}
                      onChange={(selected) =>
                        handleChangeSelect("tipo_baixa", selected)
                      }
                      placeholder="Tipo Baixa"
                      isInvalid={
                        validator.visibleFields.includes("tipo_baixa") ||
                        (!validator.fields.tipo_baixa &&
                          validator.messagesShown)
                      }
                      options={optionsTipoBaixa}
                    />
                    <FormFeedback
                      style={{
                        fontSize: "50%",
                        display:
                          validator.visibleFields.includes("tipo_baixa") ||
                          (!validator.fields.tipo_baixa &&
                            validator.messagesShown)
                            ? "block"
                            : "none",
                      }}
                    >
                      {validator.message(
                        "tipo_baixa",
                        fields.tipo_baixa,
                        "required"
                      )}
                    </FormFeedback>
                  </div>,
                  <div key={4}>
                    <Button
                      color="primary"
                      disabled={loadingBaixarFtr}
                      onClick={() => realizarBaixa()}
                    >
                      Realizar Baixa
                    </Button>
                  </div>,
                ]}
                contextMessage={{
                  message: "Selecionado(s)",
                  singular: "item",
                  plural: "itens",
                }}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  rangeSeparatorText: "de",
                }}
                onChangePage={(page) => {
                  getFaturasSemBaixa({
                    fields: { ...curFilter.current, page },
                  });
                }}
                noDataComponent={
                  <span className="p-20">Nenhuma Fatura Encontrada</span>
                }
                style={{
                  minHeight: 345,
                }}
                columns={[
                  {
                    name: "Pessoa",
                    selector: "pessoa_name",
                  },
                  {
                    name: "Lançamento",
                    selector: "dt_lancamento",
                    center: true,
                  },
                  {
                    name: "Fatura",
                    selector: "ftr_nu_fatura",
                    center: true,
                  },
                  {
                    name: "Vencimento",
                    selector: "dt_vencimento",
                    center: true,
                  },
                  {
                    name: "Valor",
                    selector: "ftr_valor",
                    center: true,
                  },
                  {
                    name: "Pagamento",
                    selector: "dt_pagamento",
                    center: true,
                    width: "16%",
                  },
                  {
                    name: "Juros",
                    selector: "juros",
                    center: true,
                  },
                  {
                    name: "Atraso",
                    selector: "atraso",
                    center: true,
                  },
                ]}
                progressComponent={
                  <div className="loader-box">
                    <div className="loader-1"></div>
                  </div>
                }
                data={ftr_sem_baixa.data.map(
                  (
                    {
                      ftr_id_fatura,
                      pessoa,
                      lancamento: { lan_dt_lancamento },
                      ftr_nu_fatura,
                      ftr_vl_fatura,
                      ftr_dt_vencimento,
                      ftr_vl_fatura_parcial,
                    },
                    index
                  ) => {
                    //CONTEUDO DA TABELA
                    const pessoa_name = (
                      <>
                        <span id={`lan_pessoa${index}`}>
                          {!!pessoa
                            ? `${pessoa.pss_cd_pessoa} - ${pessoa.pss_nm_pessoa}`
                            : "Não Informado"}
                        </span>
                        <UncontrolledTooltip target={`lan_pessoa${index}`}>
                          {!!pessoa
                            ? `${pessoa.pss_cd_pessoa} - ${pessoa.pss_nm_pessoa} - ${pessoa.pss_nu_identificacao}`
                            : "Não Informado"}
                        </UncontrolledTooltip>
                      </>
                    );
                    const dt_lancamento = format(
                      parseISO(lan_dt_lancamento),
                      "dd/MM/yyyy"
                    );
                    const dt_vencimento = format(
                      parseISO(ftr_dt_vencimento),
                      "dd/MM/yyyy"
                    );
                    const ftr_valor = fields.baixa_parcial ? (
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          R$
                        </InputGroupAddon>
                        <NumberFormat
                          placeholder="Informe o Valor"
                          thousandSeparator="."
                          customInput={Input}
                          defaultValue={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(ftr_vl_fatura_parcial || ftr_vl_fatura)}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          onValueChange={({ value }) => {
                            ftr_sem_baixa.data[index].ftr_vl_fatura_parcial =
                              value;
                          }}
                        />
                      </InputGroup>
                    ) : (
                      new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(ftr_vl_fatura)
                    );

                    const dt_pagamento = (
                      <Input
                        defaultValue={curFilter.current.ftr_dt_vencimento}
                        type="date"
                        onChange={({ target: { value } }) => {
                          ftr_sem_baixa.data[index].ftr_dt_pagamento = value;
                        }}
                      />
                    );

                    //CALCULANDO JUROS
                    const { total_dias, total_juros } = calculaJuros(
                      ftr_dt_vencimento,
                      pessoa?.pss_vl_juro_banco || 0
                    );
                    const juros = new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(!!total_juros ? total_juros : 0);
                    const atraso = `${total_dias} Dia(s)`;
                    return {
                      id: ftr_id_fatura,
                      pessoa_name,
                      dt_lancamento,
                      ftr_nu_fatura,
                      dt_vencimento,
                      ftr_valor,
                      dt_pagamento,
                      juros,
                      atraso,
                    };
                  }
                )}
              />
            )
          )}
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = ({ Faturas, Pessoas, Contas }) => {
  const { ftr_sem_baixa, loadingFtrSemBaixa, loadingBaixarFtr } = Faturas;
  const { list_bancos: listBancos, loadingListCtnBancos } = Contas;
  const { list: listPessoas, loadingList: loadingListPessoas } = Pessoas;
  return {
    ftr_sem_baixa,
    loadingFtrSemBaixa,
    listPessoas,
    loadingListPessoas,
    loadingBaixarFtr,
    listBancos,
    loadingListCtnBancos,
  };
};

export default connect(mapStateToProps, {
  getListPessoas,
  getFaturasSemBaixa,
  baixarFaturas,
  getListContasBancos,
})(TabFaturas);
