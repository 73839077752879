import React, {  } from 'react';
import { FormGroup, Label, Col, Row, Input } from 'reactstrap';

function Contabilizacao({ handleChange }) {

    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Contabilização</h6></Label>
                    <Row>
                        {/* <Col xs="12">
                            <FormGroup >
                                <Label>Regime</Label>
                                <FormGroup className="m-checkbox-inline custom-radio-ml">
                                    <div className="radio radio-primary">
                                        <Input id="radioinline1" type="radio" name="emp_tp_contab" value="C" />
                                        <Label className="mb-0" for="radioinline1">Caixa</Label>
                                    </div>
                                    <div className="radio radio-primary">
                                        <Input id="radioinline2" type="radio" name="emp_tp_contab" value="C" />
                                        <Label className="mb-0" for="radioinline2">Competência</Label>
                                    </div>
                                </FormGroup>
                            </FormGroup>
                        </Col> */}
                        <Col xs="12">
                            <FormGroup>
                                <Label>
                                    Pasta
                                </Label>
                                <Input onChange={handleChange} name="emp_ds_pasta_ctb" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </>
    )
}

export default Contabilizacao;