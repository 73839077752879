import { LOGIN_USER_ASYNC, LOGOUT_USER_ASYNC, SWITCH_USER_EMPRESA_ASYNC } from "../actionTypes";

export const loginUser = payload => ({
    type: LOGIN_USER_ASYNC,
    payload
});

export const logoutUser = () => ({
    type: LOGOUT_USER_ASYNC
});

export const switchUserEmpresa = payload => ({
    type: SWITCH_USER_EMPRESA_ASYNC,
    payload
});
