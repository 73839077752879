import React, { useState, useCallback, useRef } from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { connect } from "react-redux";
import {
    getListClientesEmp,
    getListTiposEmp,
    getListGrcContasEmp,
    getListContasEmpresa,
    updateEmpresa,
    getListPessoasEmp
} from "../../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import DadosGerais from "./DadosGerais";
import Bloqueios from "./Bloqueios";
import Contas from "./Contas";
import Grupos from "./Grupos";
import Contabilizacao from "./Contabilizacao";
import OutrosParam from "./OutrosParam";
import { toast } from 'react-toastify';
import { validatorMessages } from "../../../../../constraints/variables";

function Edit({
    isOpen,
    clickClose,
    getListClientesEmp,
    getListTiposEmp,
    loadingUpdateEmp,
    updateEmpresa,
    selectedEmpresa,
    empEmpresas,
    getListGrcContasEmp,
    getListContasEmpresa,
    getListPessoasEmp,
    errors
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    //ESTADOS
    const [fields, setFields] = useState(null);


    //FUNÇÕES
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'emp_cd_grp_imobilizado':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].codigo,
                        emp_cd_grp_pai_imobilizado: selected[0].codGrupoPai
                    });
                } else {
                    setFields({ ...fields, [name]: "", emp_cd_grp_pai_imobilizado: "" });
                }
                break;
            case 'emp_cd_grp_banco':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].codigo,
                        emp_cd_grp_pai_banco: selected[0].codGrupoPai
                    });
                } else {
                    setFields({ ...fields, [name]: "", emp_cd_grp_pai_banco: "" });
                }
                break;
            case 'emp_cd_grp_aplicacao':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].codigo,
                        emp_cd_grp_pai_aplicacao: selected[0].codGrupoPai
                    });
                } else {
                    setFields({ ...fields, [name]: "", emp_cd_grp_pai_aplicacao: "" });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const handleChange = e => {
        const { checked, name, value } = e.target;

        switch (name) {
            case 'emp_st_bloqueio':
            case 'emp_st_bloq_exclusao':
            case 'emp_st_pessoa_ativo_ctp':
                setFields({ ...fields, [name]: checked ? 'S' : '' });
                break;
            case 'emp_nu_cnpj_cpf':
                setFields({ ...fields, [name]: value.replace(/\D+/g, '') });
                break;
            default:
                setFields({ ...fields, [name]: value });
                break;
        }
    }
    const submitEmp = e => {
        e.preventDefault();
        if (validator.allValid()) {
            updateEmpresa({ fields, onSave: clickClose, index: selectedEmpresa });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }

    }
    return (
        <Modal
            isOpen={isOpen}
            size="xxl"
            onOpened={() => {
                const empresa = empEmpresas.data[selectedEmpresa];
                getListGrcContasEmp({ fields: { empresaCod: empresa.emp_cd_empresa } });
                getListContasEmpresa({ fields: { empresaCod: empresa.emp_cd_empresa } });
                getListPessoasEmp({ fields: { empresaCod: empresa.emp_id_empresa } });
                getListTiposEmp()

                setFields(empresa)

            }}
            onClosed={() => {
                setFields(null)
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => !loadingUpdateEmp && clickClose()}>
                Editar Empresa
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitEmp}>
                    <ModalBody>
                        <Row>
                            <Col md="6" sm="12">
                                <DadosGerais
                                    handleChangeSelect={handleChangeSelect}
                                    handleChange={handleChange}
                                    validFields={validFields}
                                    validator={validator}
                                    fields={fields}
                                />
                            </Col>
                            <Col md="6" sm="12">
                                <Grupos
                                    handleChangeSelect={handleChangeSelect}
                                    fields={fields} />
                            </Col>
                            <Col sm="12">
                                <Row>
                                    <Col md="4" sm="12">
                                        <Bloqueios fields={fields} handleChange={handleChange} />
                                    </Col>
                                    <Col md="4" sm="12">
                                        <OutrosParam fields={fields} handleChange={handleChange} />
                                    </Col>
                                    <Col md="4" sm="12">
                                        <Contabilizacao fields={fields} handleChange={handleChange}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12">
                                <Contas
                                    fields={fields}
                                    handleChangeSelect={handleChangeSelect} />
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={loadingUpdateEmp} onClick={clickClose}>
                            Fechar
                  </Button>
                        <Button disabled={loadingUpdateEmp} color="primary">
                            {loadingUpdateEmp ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Empresas }) => {
    const { empEmpresas, loadingUpdateEmp, errors } = Empresas;

    return { empEmpresas, loadingUpdateEmp, errors }
}

export default connect(mapStateToProps, {
    getListClientesEmp,
    getListTiposEmp,
    updateEmpresa,
    getListGrcContasEmp,
    getListContasEmpresa,
    getListPessoasEmp
})(Edit);