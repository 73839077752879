
import React, { useState, useCallback } from 'react';
import { Card, CardBody, Button, Row, Col, Form, CardTitle, Label, FormGroup, Input } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import Add from './Add';
import View from './View';
import Edit from "./Edit";
import { getModulos, destroyModulos } from '../../../../redux/actions';
import sweetalert2 from "sweetalert2";

const Modulos = ({ modulos, loadingModulos, getModulos, destroyModulos, loadingDelModulo }) => {

    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [selectedModulo, setSelectedModulo] = useState(null);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filter, setFilter] = useState({ name: null, modulo_id: null, active: null });

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, name }) => ({ id, name })))
    }, []);
    const deleteRowsFunc = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ name }) => {
                return (`<li class="list-group-item-action list-group-item">${name}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!modulos && modulos.current_page !== 1) ? modulos.current_page : 1;
            const fields = { modulos_ids: selectedRows, page }
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyModulos({ fields, onDelete });
        }

    }
    const submitFilter = e => {
        e.preventDefault();
        const fields = filter;
        getModulos({ fields });
    }
    return (
        <>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} selectedModulo={selectedModulo} />
            <Edit isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} selectedModulo={selectedModulo} />
            <Row>
                <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                    <Button color="primary" size="md" onClick={() => setOpenModal('add')} ><i className="fa fa-plus"></i><span className="p-l-5">Incluir</span></Button>
                </Col>
                <Col xs="12">
                    <Card className="shadow">
                        <CardBody>
                            <CardTitle>
                                <h5>Filtro</h5>
                            </CardTitle>
                            <Form onSubmit={submitFilter}>
                                <Row>
                                    <Col md="9" sm="12">
                                        <FormGroup>
                                            <Label>Nome</Label>
                                            <Input
                                                disabled={loadingModulos}
                                                placeholder="Informe o nome do módulo"
                                                onChange={({ target: { value } }) => {
                                                    setFilter({ ...filter, name: value })
                                                }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2" sm="12">
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Typeahead
                                                id="selectStatus"
                                                labelKey="name"
                                                disabled={loadingModulos}
                                                clearButton
                                                placeholder="Informe o Status"
                                                options={[{ name: 'Ativo', value: 1 }, { name: 'Inativo', value: 0 }]}
                                                onChange={(status) => {
                                                    if (!!status.length) {
                                                        setFilter({ ...filter, active: status[0].value })
                                                    } else {
                                                        setFilter({ ...filter, active: null })
                                                    }

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <FormGroup>
                                            <Button
                                                title="Pesquisar"
                                                disabled={loadingModulos}
                                                className="m-t-30"
                                                color="primary">
                                                <i className="fa fa-search"></i>
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12">
                    <Card className="shadow shadow-showcase">
                        <CardBody className="p-t-20">
                            {loadingModulos ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                !!modulos &&
                                <DataTable
                                    selectableRows
                                    persistTableHead
                                    pagination
                                    paginationServer
                                    paginationPerPage={modulos.per_page}
                                    paginationTotalRows={modulos.total}
                                    onChangePage={(page) => getModulos({ page })}
                                    onSelectedRowsChange={handleSelectedRows}
                                    clearSelectedRows={toggleCleared}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                        rangeSeparatorText: 'de',
                                    }}
                                    progressComponent={
                                        <div className="loader-box">
                                            <div className="loader-1"></div>
                                        </div>
                                    }
                                    progressPending={loadingDelModulo}
                                    noDataComponent={<span className="p-20">Nenhuma Módulo Cadastrado</span>}
                                    contextActions={
                                        <Button color="danger"
                                            disabled={loadingDelModulo}
                                            onClick={deleteRowsFunc}>{loadingDelModulo ? 'Processando...' : 'Remover'}
                                        </Button>}
                                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                    columns={[
                                        {
                                            name: 'Nome',
                                            selector: 'name',
                                            center: true,
                                        },
                                        {
                                            name: 'Status',
                                            selector: 'status',
                                            center: true,
                                        },
                                        {
                                            name: 'Ações',
                                            selector: 'acoes',
                                            center: true,

                                        }
                                    ]}
                                    data={modulos.data.map(({ name, active, id }, index) => {
                                        const status = (active === "1" ? <i className="fa fa-circle font-success"></i> : <i className="fa fa-circle font-danger"></i>);
                                        const acoes = (
                                            <div key={id} style={{ display: "flex", overflow: "auto" }}>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedModulo(index);
                                                        setOpenModal('view');
                                                    }}
                                                >
                                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                </a>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedModulo(index);
                                                        setOpenModal('edit');
                                                    }}
                                                >
                                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                </a>
                                            </div>
                                        )
                                        return ({ id, name, status, acoes });
                                    })}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = ({ Modulos }) => {
    const { modulos, loading: loadingModulos, loadingDelModulo } = Modulos;
    return { modulos, loadingModulos, loadingDelModulo };
}

export default connect(mapStateToProps, {
    getModulos,
    destroyModulos
})(Modulos);