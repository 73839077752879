import {
    GET_LIST_EMPRESAS_ASYNC,
    GET_LIST_EMPRESAS,
    GET_EMPRESAS,
    GET_EMPRESAS_ASYNC,
    STORE_EMPRESAS_ASYNC,
    STORE_EMPRESAS,
    UPDATE_EMPRESAS_ASYNC,
    UPDATE_EMPRESAS,
    DESTROY_EMPRESAS_ASYNC,
    DESTROY_EMPRESAS
} from "../actionTypes";


const initial_state = {
    errors: {},
    list: {
        data: [],
        total: 0
    },
    loadingList: false,
    loadingEmpresas: false,
    empEmpresas: null,
    loadingStoreEmp: false,
    loadingUpdateEmp: false,
    loadingDestroyEmp: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_EMPRESAS_ASYNC:
            return { ...state, loadingEmpresas: true };
        case GET_EMPRESAS:
            return { ...state, ...action.payload, loadingEmpresas: false };
        case GET_LIST_EMPRESAS_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_EMPRESAS:
            return { ...state, ...action.payload, loadingList: false };
        case STORE_EMPRESAS_ASYNC:
            return { ...state, loadingStoreEmp: true };
        case STORE_EMPRESAS:
            return { ...state, ...action.payload, loadingStoreEmp: false };
        case UPDATE_EMPRESAS_ASYNC:
            return { ...state, loadingUpdateEmp: true };
        case UPDATE_EMPRESAS:
            return { ...state, ...action.payload, loadingUpdateEmp: false };
        case DESTROY_EMPRESAS_ASYNC:
            return { ...state, loadingDestroyEmp: true };
        case DESTROY_EMPRESAS:
            return { ...state, ...action.payload, loadingDestroyEmp: false };
        default: return state;
    }
}
