import React, { Fragment } from "react";
import { Row, Col, Card, CardBody, Button, Table, Alert } from "reactstrap";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import PrintComponent from "../../../../components/PrintComponent";

const RltPessoas = ({ rltExtratoPessoas, filter, active_user_empresa }) => {

  return (
    <Row className="b-light p-15 border-5 m-b-20">
      <Col xs="12">
        { !!rltExtratoPessoas.dados &&
          <div className="d-flex justify-content-start font-arial-rlt">
            <span className="">
              {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - 
                ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })}, 
                ${format(parseISO(filter.dataInicial), 'dd/MM/yy')} a ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })} ,
                ${format(parseISO(filter.dataFinal), 'dd/MM/yy')}`
              }
            </span>
          </div>
        }
        <Table className="font-arial-rlt">
          <thead>
            <tr>
              <th> Pessoa</th>
              <th> Lanc.</th>
              <th> Vencimento</th>
              <th> Histórico</th>
              <th> C.Custo</th>
              <th> Débito</th>
              <th> Crédito</th>
              <th> Saldo </th>
            </tr>
          </thead>
          <tbody>
            {!!rltExtratoPessoas.dados
              ? rltExtratoPessoas.dados.map((pessoas, indexPessoas) => {
                let saldoInicial = !!pessoas.valorSaldo ? pessoas.valorSaldo : 0;
                let totalDebito = 0;
                let totalCredito = 0;
                  return (
                
                    <Fragment key={indexPessoas}>
                      <tr>
                        <td colSpan="7">
                          <u>
                            {!!pessoas.pss_cd_pessoa
                              ? `${pessoas.pss_cd_pessoa} - ${pessoas.pss_nm_pessoa}`
                              : "Não informado"}
                          </u>
                        </td>
                        <td colSpan="3">  
                            {!!pessoas.valorSaldo ? 
                            new Intl.NumberFormat('pt-Br',{style: 'currency', currency: 'BRL'}).format(pessoas.valorSaldo)  
                              :
                                 "R$ 00:00"
                             }
                        </td>
                      </tr>

                      {!!pessoas.lancamentos
                        ? Object.keys(pessoas.lancamentos).map(
                            (lancamentos, indexLancamento) => {
                              let valorDiaCredito = 0;
                              let valorDiaDebito = 0;
                              
                              return (
                                <Fragment key={indexLancamento}>
                                    {pessoas.lancamentos[lancamentos].map((dadosLanc,indexLanc) => {
                                        if(dadosLanc.vla_tp_lancamento === "D"){
                                          valorDiaDebito += parseFloat(dadosLanc.vla_vl_lancamento)
                                          totalDebito += valorDiaDebito
                                        }else{
                                          valorDiaCredito += parseFloat(dadosLanc.vla_vl_lancamento)
                                          totalCredito += valorDiaCredito
                                        }
                                        if(indexLanc === pessoas.lancamentos[lancamentos].length - 1){
                                           saldoInicial = (valorDiaDebito - valorDiaCredito) + saldoInicial;
                                        }

                                        return (
                                            <tr key={indexLanc}>
                                                <td> </td>
                                                <td width={10}> {
                                                  indexLanc === 0 && format(parseISO(lancamentos),'dd/MM')
                                                } 
                                                </td>  
                                                <td width={15}> 
                                                  { dadosLanc.contrapartidas[0].faturas.length &&
                                                     `${new Date(dadosLanc.contrapartidas[0].faturas[0].ftr_dt_lancamento).toLocaleString('pt-Br', { weekday: 'short' })},
                                                      ${format(parseISO(dadosLanc.contrapartidas[0].faturas[0].ftr_dt_lancamento), 'dd/MM/yy')}` 
                                                  } 
                                                </td>
                                                <td width={500}> { 
                                                `${dadosLanc.conta.ctn_nm_conta} - ${dadosLanc.vla_ds_historico} - ${pessoas.pss_nm_pessoa}` 
                                                } 
                                                </td>
                                                <td width={200}> {
                                                  `${dadosLanc.centro_custo.cct_cd_centro} - ${dadosLanc.centro_custo.cct_nm_centro}` 
                                                }
                                                </td>
                                                <td width={200}> {
                                                  dadosLanc.vla_tp_lancamento === "D" && 
                                                  new Intl.NumberFormat('pt-Br',{style: 'currency', currency: 'BRL'}).format(dadosLanc.vla_vl_lancamento)
                                                  
                                                }   
                                                </td>
                                                <td width={200}>{
                                                  dadosLanc.vla_tp_lancamento === "C" && 
                                                  new Intl.NumberFormat('pt-Br',{style: 'currency', currency: 'BRL'}).format(dadosLanc.vla_vl_lancamento)
                                                }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {
                                        <tr> 
                                            <td colSpan="4"><span className="d-flex justify-content-end">Movimentação do dia: </span></td>
                                            <td width={200}> {
                                                new Intl.NumberFormat('pt-Br',{style: 'currency', currency: 'BRL'}).format(valorDiaDebito - valorDiaCredito)
                                              }
                                            </td>
                                             <td width={200}>{ 
                                                new Intl.NumberFormat('pt-Br',{style: 'currency', currency: 'BRL'}).format(valorDiaDebito)
                                             }
                                            </td>
                                            <td width={200}>{
                                                new Intl.NumberFormat('pt-Br',{style: 'currency', currency: 'BRL'}).format(valorDiaCredito)
                                              }
                                            </td>
                                            <td width={200}>{
                                                new Intl.NumberFormat('pt-Br',{style:'currency', currency:'BRL'}).format(saldoInicial) 
                                            }</td> 
                                        </tr>
                                    }
                                    {Object.keys(pessoas.lancamentos).length - 1 === indexLancamento &&
                                      <tr>
                                        <td colSpan="5"><span className="d-flex justify-content-end">Total: </span></td>
                                            <td width={200}>
                                                {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalDebito)}
                                            </td>
                                            <td width={200}>
                                                {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalCredito)}
                                            </td>
                                            <td></td>
                                      </tr>
                                    }
                                </Fragment>
                              );
                            }
                          )
                        : 
                            <tr>
                                <td colSpan="8"><Alert color="light">Nenhuma movimentação neste dia para esta pessoa</Alert></td>
                            </tr>
                          }
                    </Fragment>
                  );
                })
              : 
              <tr>
                <td colSpan="8"><Alert color="light">Sem dados. por favor preencha o filtro de consulta</Alert></td>
               </tr>}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ RltOperacionais, Auth }) => {
  const { loadingRltExtratoContas, rltExtratoPessoas } = RltOperacionais;
  const { active_user_empresa } = Auth;
  return { loadingRltExtratoContas, rltExtratoPessoas, active_user_empresa };
};

export default connect(mapStateToProps, {})(RltPessoas);
