import React, { } from 'react';
import { FormGroup, Label, Row, Col, Input } from 'reactstrap';

function OutrosParam({ handleChange }) {

    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Outros Parâmetros</h6></Label>
                    <Row>
                        <Col xs="12">
                            <Label className="m-t-10 m-b-35">
                                <Input
                                    onChange={handleChange}
                                    className={`checkbox_animated`}
                                    name="emp_st_pessoa_ativo_ctp"
                                    id={`chk-ani04`}
                                    type="checkbox" /> Permitir pessoas em contas de banco na contrapartida
                            </Label>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>
                                    Dias de bloqueio para valores iguais por pessoa
                                </Label>
                                <Input 
                                type="number" 
                                onChange={handleChange} 
                                name="emp_qt_dias_vlr_forn" 
                                placeholder="Dias" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </>
    )
}

export default OutrosParam;