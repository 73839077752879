import React, { } from 'react';
import { Pagination as PaginationReactStrap, PaginationItem, PaginationLink } from 'reactstrap';


const Pagination = ({ currentPage, totalPage, onChangePage, size = "md", numberLimit = 5 }) => {
    const arrPages = [];
    let startPoint = 1;
    let endPoint = numberLimit;

    if (numberLimit > totalPage) {
        endPoint = totalPage;
    } else if (currentPage <= parseInt(numberLimit / 2)) {
        endPoint = numberLimit;
    } else if (currentPage + parseInt(numberLimit / 2) <= totalPage) {
        startPoint = currentPage - parseInt(numberLimit / 2);
        endPoint = currentPage + parseInt(numberLimit / 2);
    } else {
        startPoint = totalPage - (numberLimit - 1);
        endPoint = totalPage;
    }
    startPoint = startPoint === 0 ? 1 : startPoint;

    for (var i = startPoint; i <= endPoint; i++) {
        arrPages.push(i);
    }
    return (
        <PaginationReactStrap size={size} className="pagination justify-content-center pagination-primary">
            <PaginationItem>
                <PaginationLink disabled={currentPage === 1} onClick={() => onChangePage(1)}><span><i className="icon-control-start"></i></span></PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink disabled={currentPage === 1} onClick={() => onChangePage(currentPage - 1)}><span><i className="icon-arrow-left"></i></span></PaginationLink>
            </PaginationItem>
            {arrPages.map((page) => {
                return (
                    <PaginationItem key={page} active={currentPage === page} disabled={page === "..."}>
                        <PaginationLink onClick={() => !(currentPage === page) && onChangePage(page)}>{page}</PaginationLink>
                    </PaginationItem>
                )
            })}
            <PaginationItem>
                <PaginationLink disabled={currentPage === totalPage} onClick={() => onChangePage(currentPage + 1)}><span ><i className="icon-arrow-right"></i></span></PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink disabled={currentPage === totalPage} onClick={() => onChangePage(totalPage)}><span ><i className="icon-control-end"></i></span></PaginationLink>
            </PaginationItem>
        </PaginationReactStrap>
    );
}

export default Pagination;