import { toast } from "react-toastify";
import { takeLatest, all, fork, put, call } from "redux-saga/effects";
import ApiEdocs from "../../services/apiEdocs";
import { IMPORT_XMLS, IMPORT_XMLS_ASYNC, LOGIN_EDOCS_ASYNC, LOGIN_EDOCS } from "../actionTypes";

function* loginEdocsSaga({ payload }) {
    try {
        const { fields } = payload;
        const tokenEdocs = yield call(apiLoginEdocs, fields);
        yield put({ type: LOGIN_EDOCS, payload: { tokenEdocs } });
    } catch (error) {

    }
}
const apiLoginEdocs = async fields => {
    const { data } = await ApiEdocs.post(`/api/user/login`, { ...fields });

    return data;
}

function* importXmlsSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiImportXmls, fields);
        if (!!data) {
            toast.success("Xml's Importados com sucesso.", toast.POSITION.TOP_RIGHT);
            yield put({ type: IMPORT_XMLS, payload: {} });
        }
    } catch (error) {
        yield put({ type: IMPORT_XMLS, payload: {} });
    }
}
const apiImportXmls = async (fields) => {
    const { data } = await ApiEdocs.post(`/api/edocs/importar`, { ...fields });

    return data;
}

function* watchImportXmls() {
    yield takeLatest(IMPORT_XMLS_ASYNC, importXmlsSaga);
}
function* watchEdocsLogin() {
    yield takeLatest(LOGIN_EDOCS_ASYNC, loginEdocsSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchImportXmls),
        fork(watchEdocsLogin)
    ]);
}