import { toast } from "react-toastify";
import { call, put, all, fork, takeLatest, debounce, select } from "redux-saga/effects";
import Api from "../../services/api";
import {
    GET_LIST_CENTRO_CUSTO,
    GET_LIST_CENTRO_CUSTO_ASYNC,
    GET_CENTROS_CUSTO_ASYNC,
    GET_CENTROS_CUSTO,
    DESTROY_CENTROS_CUSTO_ASYNC,
    DESTROY_CENTROS_CUSTO,
    STORE_CENTRO_CUSTO_ASYNC,
    STORE_CENTRO_CUSTO,
    UPDATE_CENTRO_CUSTO,
    UPDATE_CENTRO_CUSTO_ASYNC
} from "../actionTypes";
import { selectorCentroCusto } from "../selectors";

function* getListCentroCustoSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListCentroCusto, fields);
        if (data.ok) {
            const { list } = data;
            yield put({ type: GET_LIST_CENTRO_CUSTO, payload: { list } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_CENTRO_CUSTO, payload: {} });
    }
}
const apiGetListCentroCusto = async fields => {
    const { data } = await Api.get(`/api/centro_custo/list`, { params: fields });

    return data;
}

function* getCentrosCustoSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetCentrosCusto, fields);

        if (data.ok) {
            const { centrosCusto } = data;
            yield put({ type: GET_CENTROS_CUSTO, payload: { centrosCusto } });
        }

    } catch (error) {
        yield put({ type: GET_CENTROS_CUSTO, payload: {} });
    }
}
const apiGetCentrosCusto = async fields => {
    const { data } = await Api.get(`/api/centro_custo`, { params: fields });

    return data;
}

function* destroyCentrosCustoSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyCentrosCusto, fields);

        if (data.ok) {
            const { centrosCusto } = data;
            yield put({ type: DESTROY_CENTROS_CUSTO, payload: { centrosCusto } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }

    } catch (error) {
        yield put({ type: DESTROY_CENTROS_CUSTO, payload: {} });
    }
}
const apiDestroyCentrosCusto = async fields => {

    const { data } = await Api.delete(`/api/centro_custo/destroy`, { data: fields });

    return data;
}

function* storeCentroCustoSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreCentroCusto, fields);

        if (!!data && !!data.ok) {
            const { centrosCusto } = data;
            yield put({ type: STORE_CENTRO_CUSTO, payload: { centrosCusto } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: STORE_CENTRO_CUSTO, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: STORE_CENTRO_CUSTO, payload: {} });
    }
}
const apiStoreCentroCusto = async fields => {
    const data = await Api.post(`/api/centro_custo/store`, fields);

    return data;
}

function* updateCentroCustoSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;
        const centrosCusto = { ...yield select(selectorCentroCusto) };

        const { id } = centrosCusto.data[index];

        const { data, errors } = yield call(apiUpdateCentroCusto, fields, id);

        if (!!data && data.ok) {
            centrosCusto.data.splice(index, 1, data.centroCusto);
            yield put({ type: UPDATE_CENTRO_CUSTO, payload: { centrosCusto } });
            toast.success(data.msg,toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: UPDATE_CENTRO_CUSTO, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: UPDATE_CENTRO_CUSTO, payload: {} });
    }
}
const apiUpdateCentroCusto = async (fields, id) => {
    const data = await Api.put(`/api/centro_custo/update/${id}`, fields);

    return data;
}

function* watchGetListCentroCusto() {
    yield debounce(1000, GET_LIST_CENTRO_CUSTO_ASYNC, getListCentroCustoSaga);
}
function* watchGetCentrosCusto() {
    yield takeLatest(GET_CENTROS_CUSTO_ASYNC, getCentrosCustoSaga);
}
function* watchDestroyCentrosCusto() {
    yield takeLatest(DESTROY_CENTROS_CUSTO_ASYNC, destroyCentrosCustoSaga);
}
function* watchStoreCentroCusto() {
    yield takeLatest(STORE_CENTRO_CUSTO_ASYNC, storeCentroCustoSaga);
}
function* watchUpdateCentroCusto() {
    yield takeLatest(UPDATE_CENTRO_CUSTO_ASYNC, updateCentroCustoSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListCentroCusto),
        fork(watchGetCentrosCusto),
        fork(watchDestroyCentrosCusto),
        fork(watchStoreCentroCusto),
        fork(watchUpdateCentroCusto)
    ]);
}