import React, { useState, useCallback, useRef } from "react";
import {
    ModalBody,
    Modal,
    ModalHeader,
    ModalFooter,
    Button,
    Row,
    Col,
    Form,
} from "reactstrap";
import { connect } from "react-redux";
import {
    getListContasGrupos,
    updateContas,
    changeListGrupos,
    getListPessoas,
    changeListPessoas,
} from "../../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";

const Edit = (
    {
        isOpen,
        clickClose,
        selectedConta,
        contas,
        errors,
        listGrupos,
        getListContasGrupos,
        updateContas,
        loadingUpdate,
        listPessoas,
        getListPessoas,
    },
) => {

    //ESTADOS
    const [conta, setConta] = useState(null);
    const [, updateState] = useState();
    //VARIÁVEIS

    const optionsContabilizacao = [
        { name: "Conta", value: "C" },
        { name: "Pessoa", value: "P" },
        { name: "Não Contabilização", value: "N" }
    ];

    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const handleChange = e => {
        const { name, value, checked } = e.target;
        if (name === "ctn_st_saldo_neg" || name === "ctn_st_pag_cheque" || name === "ctn_st_lanc_manual") {
            checked ? setConta({ ...conta, [name]: value }) : setConta({ ...conta, [name]: "" });
        } else {
            setConta({ ...conta, [name]: value });
        }
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'ctn_cd_grupo':
                if (!!selected.length) {
                    setConta({ ...conta, [name]: selected[0].grc_cd_grupo, ctn_cd_grupo_pai: selected[0].cd_grupo_pai })
                } else {
                    setConta({ ...conta, [name]: "", ctn_cd_grupo_pai: "" })
                }
                break;
            case 'ctn_cd_pessoa':
                if (!!selected.length) {
                    setConta({ ...conta, [name]: selected[0].pss_cd_pessoa })
                } else {
                    setConta({ ...conta, [name]: "" })
                }
                break;
            default:
                if (!!selected.length) {
                    setConta({ ...conta, [name]: selected[0].value })
                } else {
                    setConta({ ...conta, [name]: "" })
                }
                break;
        }
    }
    const handleValor = (name, value) => {
        setConta({ ...conta, [name]: value });
        // clearErrorsForm(name)
    }
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const id = contas.data[selectedConta].id;
            updateContas({ fields: conta, id, onUpdate: clickClose, index: selectedConta });
        } else {
            toast.error("Preencha os campos corretamente.", toast.POSITION.TOP_RIGHT);
        }

    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const onOpenModal = () => {

        const dados = { ...contas.data[selectedConta] };
        if (!listPessoas.data.find((el) => el.pss_cd_pessoa === dados.ctn_cd_pessoa)) {
            getListPessoas({ fields: { codigo: dados.ctn_cd_pessoa } })
        }

        if (!listGrupos.data.find((el) => el.grc_cd_grupo === dados.ctn_cd_grupo && el.grc_cd_grupo_pai === dados.ctn_cd_grupo_pai)) {
            getListContasGrupos({ fields: { codigo: dados.ctn_cd_grupo } });
        }
        delete dados.id;
        delete dados.grupo_pai;
        delete dados.grupo;
        delete dados.pessoa;
        delete dados.row_num;
        setConta(dados);
    }

    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            opcoes: {
                message: 'Você deve escolhe pelo menos uma opção.',
                rule: (val) => {

                    return !val;
                }
            },
            valor_minimo: {
                message: "Valor minímo dever ser menor que máximo",
                rule: (val, params) => {
                    if (!!params[1]) {
                        return parseFloat(params[1]) >= parseFloat(params[0]);
                    }
                }
            },
            valor_maximo: {
                message: "Valor máximo deve ser maior que o minímo.",
                rule: (val, params) => {
                    return parseFloat(params[0]) <= parseFloat(params[1]);
                }
            }
        }
    })).current;

    return (
        <Modal
            isOpen={isOpen} size="lg"
            onOpened={onOpenModal}
            onClosed={() => {
                validator.visibleFields = [];
                setConta(null)
                for (const key in errors) {
                    delete errors[key]
                }
            }}>
            <ModalHeader toggle={() => !loadingUpdate && clickClose()}>Editar Conta</ModalHeader>
            {!!conta &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <FirstCard
                                    conta={conta}
                                    handleChange={handleChange}
                                    validFields={validFields}
                                    validator={validator}
                                    errors={errors}
                                    handleChangeSelect={handleChangeSelect}
                                />
                            </Col>
                            <Col xs="12">
                                <SecondCard
                                    conta={conta}
                                    handleValor={handleValor}
                                    validFields={validFields}
                                    validator={validator}
                                />
                            </Col>
                            <Col xs="12">
                                <ThirdCard
                                    conta={conta}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleChangeSelect={handleChangeSelect}
                                    validFields={validFields}
                                    validator={validator}
                                />
                            </Col>
                            <Col xs="12">
                                <FourthCard
                                    conta={conta}
                                    handleChange={handleChange}
                                    validFields={validFields}
                                    validator={validator}
                                />
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => clickClose()} disabled={loadingUpdate} type="button">
                            Fechar
                        </Button>
                        <Button color="primary" type="submit" disabled={loadingUpdate}>
                            {loadingUpdate ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    );
}

const mapStateToProps = ({ Contas, ContasGrupos, Pessoas }) => {
    const { contas, errors, loadingUpdate } = Contas;
    const { list: listPessoas, loadingList: loadingListPesssoas } = Pessoas;
    const { list: listGrupos, loadingList: loadingListGrupos } = ContasGrupos;
    return { contas, errors, listGrupos, loadingListGrupos, loadingUpdate, listPessoas, loadingListPesssoas }
}

export default connect(mapStateToProps, {
    getListContasGrupos,
    changeListGrupos,
    updateContas,
    getListPessoas,
    changeListPessoas
})(Edit);