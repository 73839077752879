export const mountList = (currentList = [], requestList = [], keysToCompare = []) => {
    const diffRegisters = requestList.filter((elem) => {
        let res = true;
        keysToCompare.forEach((key) => {
            if (currentList.some((cElem) => cElem[key] === elem[key])) {
                res = false;
            }
        });

        return res;
    });

    return [...currentList, ...diffRegisters];
}