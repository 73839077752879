import React from "react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

function View({ isOpen, clickClose, centroCusto }) {
    return (
        <Modal size="lg" isOpen={isOpen}>
            <ModalHeader toggle={() => clickClose()}>
                Informações Centro de Custo
            </ModalHeader>
            <ModalBody>
                {!!centroCusto &&
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input readOnly defaultValue={centroCusto.cct_nm_centro || 'Não Informado'} />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Num.Identificação</Label>
                                <Input readOnly defaultValue={centroCusto.cct_nu_identificacao || 'Não Informado'} />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Pessoa</Label>
                                <Input
                                    readOnly
                                    defaultValue={!!centroCusto.pessoa ? `${centroCusto.pessoa?.pss_cd_pessoa} - ${centroCusto.pessoa?.pss_nm_pessoa} - ${centroCusto.pessoa?.pss_nu_identificacao}` : 'Não Informado'} />
                            </FormGroup>
                        </Col>
                    </Row>
                }

            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default View;