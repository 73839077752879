import React from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Row,
    Label,
    ButtonGroup,
} from "reactstrap";
import { format, parseISO } from "date-fns";
const DadosGerais = ({ user }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Dados Gerais</h6></Label>
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input readOnly defaultValue={user.name} />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <Label className="d-block">Status</Label>
                            <ButtonGroup>
                                <Button outline disabled active={user.active === "1"} color="success">
                                    Ativo
                                </Button>
                                <Button outline disabled active={user.active === "0"} color="danger">
                                    Inativo
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md="7" xs="12">
                            <FormGroup>
                                <Label>Email</Label>
                                <Input readOnly defaultValue={user.email} />
                            </FormGroup>
                        </Col>
                        <Col md="5" xs="12">
                            <FormGroup>
                                <Label>Departamento</Label>
                                <Input readOnly defaultValue={
                                    !!user.departamento && !!user.departamento.dep_nm_departamento ?
                                        user.departamento.dep_nm_departamento :
                                        'Não Informado'} />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>CPF</Label>
                                <Input readOnly defaultValue={user.cpf} />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Data de Nascimento</Label>
                                <Input readOnly defaultValue={
                                    format(parseISO(user.data_nascimento), 'dd/MM/Y')
                                } />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Gênero</Label>
                                <Input readOnly defaultValue={
                                    user.gender === "M" ?
                                        "Masculino" :
                                        "Feminino"
                                } />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>

    )
}

const mapStateToProps = ({ }) => {

    return {};
}
export default connect(mapStateToProps, {})(DadosGerais);