import React, { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { updateTipoEmpresa } from "../../../../../redux/actions";
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';

function Add({
    isOpen,
    clickClose,
    loadingUpdateTiposEmp,
    selectedTipoEmp,
    tiposEmpresa,
    updateTipoEmpresa,
    errors
}) {

    //ESTADOS
    const [fields, setFields] = useState(null);

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.'
        }
    })).current;

    //FUNÇÕES
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            updateTipoEmpresa({ fields, index: selectedTipoEmp, onSave: clickClose })
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }

    }

    const handleChange = e => {
        const { name, value } = e.target;

        setFields({ ...fields, [name]: value });

        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onOpened={() => {
                const tipoEmpresa = { ...tiposEmpresa.data[selectedTipoEmp] };
                delete tipoEmpresa.row_num;
                delete tipoEmpresa.tpe_cd_tipo;
                setFields(tipoEmpresa);
            }}
            onClosed={() => {
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}>
            <ModalHeader toggle={() => !loadingUpdateTiposEmp && clickClose()}>
                Editar Tipo Empresa
           </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        name="tpe_nm_tipo"
                                        onChange={handleChange}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        maxLength={36}
                                        placeholder="Informe o Nome"
                                        defaultValue={fields.tpe_nm_tipo}
                                        invalid={isInvalid('tpe_nm_tipo')}
                                    />
                                    <FormFeedback>
                                        {(!!errors.tpe_nm_tipo) && errors.tpe_nm_tipo.map((erro, index) => <span key={index}>{erro}</span>)}
                                        {validator.message('tpe_nm_tipo', fields.tpe_nm_tipo, 'required|max:36')}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input
                                        name="tpe_ds_tipo"
                                        onChange={handleChange}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        maxLength={100}
                                        placeholder="Informe a Descrição"
                                        defaultValue={fields.tpe_ds_tipo}
                                        invalid={isInvalid('tpe_ds_tipo')}
                                    />
                                    <FormFeedback>
                                        {(!!errors.tpe_ds_tipo) && errors.tpe_ds_tipo.map((erro, index) => <span key={index}>{erro}</span>)}
                                        {validator.message('tpe_ds_tipo', fields.tpe_ds_tipo, 'required|max:100')}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={loadingUpdateTiposEmp}
                            onClick={() => !loadingUpdateTiposEmp && clickClose()}
                            type="button">
                            Fechar
                        </Button>
                        <Button disabled={loadingUpdateTiposEmp} color="primary">
                            {loadingUpdateTiposEmp ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ TiposEmpresa }) => {
    const { loadingUpdateTiposEmp, tiposEmpresa, errors } = TiposEmpresa
    return { loadingUpdateTiposEmp, tiposEmpresa, errors }
}

export default connect(mapStateToProps, {
    updateTipoEmpresa
})(Add);