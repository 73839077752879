import {
    GET_CUSTOS_PRODUTOS_ASYNC,
    GET_CUSTOS_PRODUTOS,
    STORE_CUSTO_PRODUTO_ASYNC,
    STORE_CUSTO_PRODUTO,
    UPDATE_CUSTO_PRODUTO_ASYNC,
    UPDATE_CUSTO_PRODUTO,
    DESTROY_CUSTO_PRODUTO_ASYNC,
    DESTROY_CUSTO_PRODUTO
} from "../actionTypes";

const initalState = {
    errors: {},
    loadingCustosProd: false,
    custosProdutos: null,
    loadingStoreCustoProd: false,
    loadingUpdateCustoProd: false,
    loadingDestroyCustoProd:false,
}
export default (state = initalState, action) => {
    switch (action.type) {
        case GET_CUSTOS_PRODUTOS_ASYNC:
            return { ...state, loadingCustosProd: true };
        case GET_CUSTOS_PRODUTOS:
            return { ...state, ...action.payload, loadingCustosProd: false };
        case STORE_CUSTO_PRODUTO_ASYNC:
            return { ...state, loadingStoreCustoProd: true };
        case STORE_CUSTO_PRODUTO:
            return { ...state, ...action.payload, loadingStoreCustoProd: false };
        case UPDATE_CUSTO_PRODUTO_ASYNC:
            return { ...state, loadingUpdateCustoProd: true };
        case UPDATE_CUSTO_PRODUTO:
            return { ...state, ...action.payload, loadingUpdateCustoProd: false };
        case DESTROY_CUSTO_PRODUTO_ASYNC:
            return { ...state, loadingDestroyCustoProd: true };
        case DESTROY_CUSTO_PRODUTO:
            return { ...state, ...action.payload, loadingDestroyCustoProd: false };
        default: return state;
    }
}