import {
    GET_LIST_CENTRO_CUSTO_ASYNC,
    GET_CENTROS_CUSTO_ASYNC,
    DESTROY_CENTROS_CUSTO_ASYNC,
    STORE_CENTRO_CUSTO_ASYNC,
    UPDATE_CENTRO_CUSTO_ASYNC
} from "../actionTypes";

export const getListCentroCusto = (payload = {}) => ({
    type: GET_LIST_CENTRO_CUSTO_ASYNC,
    payload
});

export const getCentrosCusto = (payload = {}) => ({
    type: GET_CENTROS_CUSTO_ASYNC,
    payload
});

export const destroyCentrosCusto = payload => ({
    type: DESTROY_CENTROS_CUSTO_ASYNC,
    payload
});

export const storeCentroCusto = payload => ({
    type: STORE_CENTRO_CUSTO_ASYNC,
    payload
});

export const updateCentroCusto = payload => ({
    type: UPDATE_CENTRO_CUSTO_ASYNC,
    payload
});