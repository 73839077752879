import {
    GET_CONTAS,
    GET_CONTAS_ASYNC,
    STORE_CONTAS_ASYNC,
    STORE_CONTAS,
    UPDATE_CONTAS_ASYNC,
    UPDATE_CONTAS,
    DESTROY_CONTAS_ASYNC,
    DESTROY_CONTAS,
    GET_LIST_CONTAS_ASYNC,
    GET_LIST_CONTAS,
    GET_LIST_CONTAS_BANCOS_ASYNC,
    GET_LIST_CONTAS_BANCOS,
    GET_LIST_CONTA_PESSOAS_ASYNC,
    GET_LIST_CONTA_PESSOAS,
    GET_LIST_CONTAS_EMPRESA_ASYNC,
    GET_LIST_CONTAS_EMPRESA,
    GET_SALDOS_CONTA_ASYNC,
    GET_SALDOS_CONTA
} from "../actionTypes";

const initial_state = {
    errors: {},
    loading: false,
    loadingStore: false,
    loadingUpdate: false,
    loadingDestroy: false,
    loadingListCtnBancos: false,
    loadingListCtnPessoa: false,
    loadingList: false,
    contas: null,
    list: { data: [], total: 0 },
    list_bancos: { data: [], total: 0 },
    listContaPessoas: { data: [], total: 0 },
    loadingListContasEmp: false,
    listContasEmpresa: { data: [], total: 0 },
    loadingSaldoConta: false,
    saldoConta: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_CONTAS_ASYNC:
            return { ...state, loading: true };
        case GET_CONTAS:
            return { ...state, ...action.payload, loading: false };
        case STORE_CONTAS_ASYNC:
            return { ...state, loadingStore: true };
        case STORE_CONTAS:
            return { ...state, ...action.payload, loadingStore: false };
        case UPDATE_CONTAS_ASYNC:
            return { ...state, loadingUpdate: true };
        case UPDATE_CONTAS:
            return { ...state, ...action.payload, loadingUpdate: false };
        case DESTROY_CONTAS_ASYNC:
            return { ...state, loadingDestroy: true };
        case DESTROY_CONTAS:
            return { ...state, ...action.payload, loadingDestroy: false };
        case GET_LIST_CONTAS_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_CONTAS:
            return { ...state, ...action.payload, loadingList: false };
        case GET_LIST_CONTAS_BANCOS_ASYNC:
            return { ...state, loadingListCtnBancos: true };
        case GET_LIST_CONTAS_BANCOS:
            return { ...state, ...action.payload, loadingListCtnBancos: false };
        case GET_LIST_CONTA_PESSOAS_ASYNC:
            return { ...state, loadingListCtnPessoa: true };
        case GET_LIST_CONTA_PESSOAS:
            return { ...state, ...action.payload, loadingListCtnPessoa: false };
        case GET_LIST_CONTAS_EMPRESA_ASYNC:
            return { ...state, loadingListContasEmp: true };
        case GET_LIST_CONTAS_EMPRESA:
            return { ...state, ...action.payload, loadingListContasEmp: false };
        case GET_SALDOS_CONTA_ASYNC:
            return { ...state, loadingSaldoConta: true };
        case GET_SALDOS_CONTA:
            return { ...state, ...action.payload, loadingSaldoConta: false };

        default: return state;
    }
}
