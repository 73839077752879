import {
    GET_LIST_TIPOS_EMPRESAS_ASYNC,
    GET_TIPOS_EMPRESA_ASYNC,
    STORE_TIPO_EMPRESA_ASYNC,
    DESTROY_TIPOS_EMPRESA_ASYNC,
    UPDATE_TIPO_EMPRESA_ASYNC
} from "../actionTypes";

export const getListTiposEmp = (payload = {}) => ({
    type: GET_LIST_TIPOS_EMPRESAS_ASYNC,
    payload
});

export const getTiposEmpresa = (payload = {}) => ({
    type: GET_TIPOS_EMPRESA_ASYNC,
    payload
});

export const storeTipoEmpresa = payload => ({
    type: STORE_TIPO_EMPRESA_ASYNC,
    payload
});

export const destroyTiposEmpresa = payload => ({
    type: DESTROY_TIPOS_EMPRESA_ASYNC,
    payload
});

export const updateTipoEmpresa = payload => ({
    type: UPDATE_TIPO_EMPRESA_ASYNC,
    payload
});