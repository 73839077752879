import React, { useState, useCallback, useRef } from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { connect } from "react-redux";
import { getListClientes, getListTiposEmp, storeEmpresas } from "../../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import DadosGerais from "./DadosGerais";
import Bloqueios from "./Bloqueios";
import Contabilizacao from "./Contabilizacao";
import OutrosParam from "./OutrosParam";
import { toast } from 'react-toastify';
import { validatorMessages } from "../../../../../constraints/variables";

const inital_state = {
    emp_cd_empresa: "",
    emp_nm_empresa: "",
    emp_nu_cnpj_cpf: "",
    emp_tp_empresa: "",
    emp_tp_dados: "",
    emp_cd_cliente: "",
    emp_st_bloq_exclusao: "",
    emp_st_bloqueio: "",
    emp_qt_dias_bloqueio: "",
    emp_qt_dias_nao_pago: "",
    emp_tp_contab: "",
    emp_st_pessoa_ativo_ctp: "",
    emp_qt_dias_vlr_forn: "",
    emp_ds_pasta_ctb:""

}
function Add({
    isOpen,
    clickClose,
    getListClientes,
    getListTiposEmp,
    loadingStoreEmp,
    storeEmpresas,
    errors
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    //ESTADOS
    const [fields, setFields] = useState({ ...inital_state });

    //FUNÇÕES
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    
    const handleChange = e => {
        const { checked, name, value } = e.target;

        switch (name) {
            case 'emp_st_bloqueio':
            case 'emp_st_bloq_exclusao':
            case 'emp_st_pessoa_ativo_ctp':
                setFields({ ...fields, [name]: checked ? 'S' : '' });
                break;
            case 'emp_nu_cnpj_cpf':
                setFields({ ...fields, [name]: value.replace(/\D+/g, '') });
                break;
            default:
                setFields({ ...fields, [name]: value });
                break;
        }
    }
    const submitEmp = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeEmpresas({ fields, onCreate: clickClose });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }

    }

    return (
        <Modal
            isOpen={isOpen}
            size="xxl"
            onOpened={() => {
                getListClientes()
                // getListTiposEmp()
            }}
            onClosed={() => {
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => !loadingStoreEmp && clickClose()}>
                Adicionar Empresa
            </ModalHeader>
            <Form onSubmit={submitEmp}>
                <ModalBody>
                    <Row>
                        <Col md="12" sm="12">
                            <DadosGerais
                                handleChangeSelect={handleChangeSelect}
                                handleChange={handleChange}
                                validFields={validFields}
                                validator={validator}
                                fields={fields}
                            />
                        </Col>
                        <Col sm="12">
                            <Row>
                                <Col md="4" sm="12">
                                    <Bloqueios fields={fields} handleChange={handleChange} />
                                </Col>
                                <Col md="4" sm="12">
                                    <OutrosParam handleChange={handleChange} />
                                </Col>
                                <Col md="4" sm="12">
                                    <Contabilizacao  handleChange={handleChange}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={loadingStoreEmp} onClick={clickClose}>
                        Fechar
                </Button>
                    <Button disabled={loadingStoreEmp} color="primary">
                        {loadingStoreEmp ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Empresas }) => {
    const { loadingStoreEmp, errors } = Empresas;

    return { loadingStoreEmp, errors }
}

export default connect(mapStateToProps, {
    getListClientes,
    getListTiposEmp,
    storeEmpresas
})(Add);