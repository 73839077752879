import { format, parseISO } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { Row, Table } from "reactstrap";

function RltSintetico({ rltFluxoCaixa }) {
    let totalDeb = 0;
    let totalCred = 0;
    let totalConfiavelDeb = 0;
    let totalConfiavelCred = 0;
    return (
        <Row className="b-light p-15 border-5 m-b-20">
            <Table className="font-arial-rlt">
                <thead>
                    <tr>
                        <td className="text-center b-dark">Data</td>
                        <td className="text-center b-dark">Histórico</td>
                        <td className="text-center b-dark">Débito</td>
                        <td className="text-center b-dark">Crédito</td>
                        <td className="text-center b-dark">Saldo</td>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ backgroundColor: '#c0ddc0' }}>
                        <td colSpan="5" className="text-right">
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rltFluxoCaixa.saldoAnterior)}
                        </td>
                    </tr>
                    {rltFluxoCaixa.lancamentos.map((lan, index) => {
                        const valor = parseFloat(lan.vla_vl_lancamento);

                        if (lan.vla_tp_lancamento === 'D') {
                            totalDeb += valor;
                            if (!!lan.pessoa) {
                                totalConfiavelDeb += !!lan.pessoa.pss_st_conf_fluxo === 'S' ? valor : 0;
                            }

                        } else if (lan.vla_tp_lancamento === 'C') {
                            totalCred += valor;
                            if (!!lan.pessoa) {
                                totalConfiavelCred += !!lan.pessoa.pss_st_conf_fluxo === 'S' ? valor : 0;
                            }

                        }


                        return (
                            <tr key={index}>
                                <td className="text-center">{format(parseISO(lan.ftr_dt_vencimento), 'd/MM/yyyy')}</td>
                                <td className="text-right">
                                    {lan.vla_ds_historico}
                                </td>
                                <td className="text-right">
                                    {lan.vla_tp_lancamento === 'D' && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lan.vla_vl_lancamento)}
                                </td>
                                <td className="text-right">
                                    {lan.vla_tp_lancamento === 'C' && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lan.vla_vl_lancamento)}
                                </td>
                                <td className="text-right" />
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan="2" className="text-right" style={{ color: 'darkred' }}>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between" style={{ width: 200 }}>
                                    <span>Todos</span>
                                    <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb - totalCred)}</span>
                                </div>
                            </div>
                        </td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb)}</td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCred)}</td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rltFluxoCaixa.saldoAnterior + (totalDeb - totalCred))}</td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="text-right" style={{ color: 'darkred' }}>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between" style={{ width: 200 }}>
                                    <span>Confiável</span>
                                    <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalConfiavelDeb - totalConfiavelCred)}</span>
                                </div>
                            </div>
                        </td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalConfiavelDeb)}</td>
                        <td className="text-right" style={{ color: 'darkred' }}>
                            <span className="m-r-30">
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalConfiavelCred)}
                            </span>
                            {/* <span>
                                {!!totalConfiavelCred &&
                                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((100 / totalConfiavelCred) * )
                                }
                            </span> */}
                        </td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rltFluxoCaixa.saldoAnterior + (totalConfiavelDeb - totalConfiavelCred))}</td>
                    </tr>
                </tbody>
            </Table>
        </Row>
    )
}

const mapStateToProps = ({ RltGerenciais }) => {
    const { rltFluxoCaixa } = RltGerenciais;
    return { rltFluxoCaixa }
}

export default connect(mapStateToProps, {})(RltSintetico)