import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Conta from "./Conta";
import DadosGerais from "./DadosGerais";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import { getListContaPessoas, getListContas } from "../../../../../../redux/actions";

const initialState = {
    cnt_cd_conta: "",
    cnt_cd_subconta: "",
    cnt_vl_contrapartida: "",
    cnt_ch_documento: "S",
    cnt_cd_centro_custo: "",
    cnt_ds_historico: "",
    cnt_nu_doc: "",
    conta: {},
    pessoa: {},
    centro_custo: {},
}

const Add = ({
    isOpen,
    clickClose,
    setContraPartidas,
    contrapartidas,
    lancamento,
    getListContaPessoas,
    getListContas,
    listContas,
    listContaPessoas

}) => {
    //ESTADOS
    const [fields, setFields] = useState({ ...initialState, faturas: [] });
    const [, updateState] = useState();


    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;

        switch (name) {
            default:
                setFields({ ...fields, [name]: value })
                break;
        }
    }
    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFields({ ...fields, [name]: value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'cnt_cd_conta':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].ctn_cd_conta,
                        cnt_ds_historico: selected[0].ctn_ds_hist_padrao,
                        cnt_cd_subconta: selected[0]?.def_pessoa?.pss_cd_pessoa,
                        conta: selected[0],
                        pessoa: {
                            value: selected[0]?.def_pessoa?.id,
                            pss_nm_pessoa: selected[0]?.def_pessoa?.pss_nm_pessoa,
                            pss_cd_pessoa: selected[0]?.def_pessoa?.pss_cd_pessoa,
                            pss_nu_identificacao: selected[0]?.def_pessoa?.pss_nu_identificacao
                        }
                    });
                    listContaPessoas.data = [];
                    listContaPessoas.total = 0;
                    !selected[0].def_pessoa && getListContaPessoas({ conta: selected[0].ctn_cd_conta, fields: {} });
                } else {
                    setFields({
                        ...fields,
                        [name]: "",
                        cnt_ds_historico: "",
                        cnt_cd_subconta: "",
                        conta: {},
                    });
                }
                break;
            case 'cnt_cd_subconta':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].pss_cd_pessoa, pessoa: selected[0] });
                } else {
                    setFields({ ...fields, [name]: "", pessoa: {} });
                }
                break;
            case 'cnt_cd_centro_custo':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].value,
                        centro_custo: { cct_nm_centro: selected[0].cct_nm_centro, cct_cd_centro: selected[0].cct_cd_centro }
                    }
                    );
                } else {
                    setFields({ ...fields, [name]: "", centro_custo: {} });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }
    const submitForm = e => {
        e.preventDefault();
        e.stopPropagation();
        if (validator.allValid()) {
            contrapartidas.push(fields);
            setContraPartidas(contrapartidas);
            toast.success('Registro vinculado com sucesso.', toast.POSITION.TOP_RIGHT);
            clickClose();
        } else {
            validator.showMessages();
            toast.error('Preencha todos os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }
    const onOpenModal = () => {
        listContas.data = [];
        listContas.total = 0;
        listContaPessoas.data = [];
        listContaPessoas.total = 0;
        getListContas();
        if (!contrapartidas.length) {
            setFields({
                ...fields,
                cnt_vl_contrapartida: lancamento.lan_vl_lancamento,
                cnt_nu_doc: lancamento.lan_nu_doc
            });
        }
    }
    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            verifica_valor: {
                message: 'O valor deve está entre o mínimo e máximo da conta.',
                rule: (val, params) => {
                    const valor_contrapartida = parseFloat(params[0]);
                    const valor_minimo = parseFloat(params[1]);
                    const valor_maximo = parseFloat(params[2]);

                    return valor_contrapartida >= valor_minimo && valor_contrapartida <= valor_maximo;
                }
            }
        }
    })).current;

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onOpened={onOpenModal}
            onClosed={() => {
                setFields({ ...initialState, faturas: [] });
                validator.visibleFields = [];
                validator.messagesShown = false;
            }}
        >
            <ModalHeader toggle={() => clickClose()}>Incluir Contrapartida</ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Conta
                            fields={fields}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                            validFields={validFields}
                            validator={validator}
                        />
                        <DadosGerais
                            fields={fields}
                            validator={validator}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                            validFields={validFields}
                            handleValor={handleValor}
                            contrapartidas={contrapartidas}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button color="primary" type="submit">
                        Incluir
                </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, listContaPessoas } = Contas;
    return { listContas, listContaPessoas }
}

export default connect(mapStateToProps, {
    getListContaPessoas,
    getListContas
})(Add)