import React, { } from "react";
import {
    Col,
    FormGroup,
    Input,
    Row,
    Label,
    FormFeedback,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
const Lancamentos = ({
    validFields,
    validator,
    fields,
    handleLancamentos
}) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Lançamentos</h6></Label>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Minímo*</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={
                                            validator.visibleFields.includes('valor_minimo') ||
                                            (!validator.fields.valor_minimo && validator.messagesShown)
                                        }
                                        name="valor_minimo"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({value}) => handleLancamentos('valor_minimo',value)}
                                    />
                                </InputGroup>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('valor_minimo')
                                            ||
                                            (!validator.fields.valor_minimo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('valor_minimo', fields.valor_minimo,
                                        `required|valor_minimo:${fields.valor_minimo},${fields.valor_maximo}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Máximo*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        disabled={!fields.valor_minimo}
                                        customInput={Input}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('valor_maximo') ||
                                            (!validator.fields.valor_maximo && validator.messagesShown)
                                        }
                                        name="valor_maximo"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({value}) => handleLancamentos('valor_maximo',value)}
                                    />
                                </InputGroup>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('valor_maximo') ||
                                            (!validator.fields.valor_maximo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('valor_maximo', fields.valor_maximo,
                                        `required|valor_maximo:${fields.valor_minimo},${fields.valor_maximo}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Lancamentos)