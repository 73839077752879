import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Row,
    Label,
    Input,
    FormFeedback,

} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useMemo } from "react";
const ThirdCard = ({
    fields,
    validator,
    validFields,
    handleChangeSelect,
    handleChange,
    errors
}) => {
    const optionsContabilizacao = useMemo(() => [
        { name: "Conta", value: "C" },
        { name: "Pessoa", value: "P" },
        { name: "Não Contabilização", value: "N" }
    ]);
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                {/* <Label><h6>Valores</h6></Label> */}
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Histórico Padrão</Label>
                            <Input
                                name="ctn_ds_hist_padrao"
                                onChange={handleChange}
                                placeholder="Informe a Descrição Histórico Padrão" />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Contabilização*</Label>
                            <Typeahead
                                id="selectContabilizacao"
                                labelKey="name"
                                onBlur={() => validFields('ctn_st_contabiliza')}
                                isInvalid={validator.visibleFields.includes('ctn_st_contabiliza')}
                                options={optionsContabilizacao}
                                placeholder="Selecione a Contabilização"
                                onChange={(selected) => handleChangeSelect('ctn_st_contabiliza', selected)}
                                clearButton
                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_st_contabiliza') ? 'block' : 'none' }}>
                                {validator.message('ctn_st_contabiliza', fields.ctn_st_contabiliza, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Conta*</Label>
                            <Input
                                placeholder="Informe o código da conta"
                                name="ctn_cd_conta_contabil"
                                maxLength={15}
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('ctn_cd_conta_contabil') || !!errors.ctn_cd_conta_contabil}
                                onChange={handleChange}
                            />
                            <FormFeedback>
                                {(!!errors.ctn_cd_conta_contabil) && errors.ctn_cd_conta_contabil.map((erro, index) => <span key={index}>{erro}</span>)}
                                {validator.message('ctn_cd_conta_contabil', fields.ctn_cd_conta_contabil, `required|max:15`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Histórico*</Label>
                            <Input
                                maxLength={5}
                                placeholder="Informe o código do histórico"
                                name="ctn_cd_hist_padrao"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('ctn_cd_hist_padrao')}
                                onChange={handleChange}
                            />
                            <FormFeedback>
                                {validator.message('ctn_cd_hist_padrao', fields.ctn_cd_hist_padrao, `required|max:5`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ }) => {
    return {};
}
export default connect(mapStateToProps, {})(ThirdCard);