import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, FormFeedback, FormGroup, Label, Row, UncontrolledTooltip } from "reactstrap";
import Add from "./Add/";
import Edit from "./Edit/";
import View from "./View/"
import sweetalert2 from "sweetalert2";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";

const ContraPartidas = ({
    setContraPartidas,
    contrapartidas,
    validator,
    errors,
    lancamento,
}) => {
    const [openModal, setOpenModal] = useState(null);
    const [selectedContrapartida, setSelectedContrapartida] = useState(null);
    const removeContrapartida = async index => {

        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente desvincular está contrapartida?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            icon: 'warning',
        });
        if (confirm.value) {
            contrapartidas.splice(index, 1);
            setContraPartidas(contrapartidas);
            toast.success('Registro desvinculado com sucesso.', toast.POSITION.TOP_RIGHT);
        }
    }
    const somaContrapartida = contrapartidas.reduce((acu, elem) => {
        return acu + parseFloat(elem.cnt_vl_contrapartida);
    }, 0).toFixed(2);
    return (
        <>
            <Add
                isOpen={openModal === 'add'}
                clickClose={() => setOpenModal(null)}
                setContraPartidas={setContraPartidas}
                contrapartidas={contrapartidas}
                lancamento={lancamento}
            />
            <Edit
                isOpen={openModal === 'edit'}
                clickClose={() => setOpenModal(null)}
                setContraPartidas={setContraPartidas}
                contrapartidas={contrapartidas}
                selectedContrapartida={selectedContrapartida}
                lancamento={lancamento}
            />
            <View
                isOpen={openModal === 'view'}
                clickClose={() => setOpenModal(null)}
                setContraPartidas={setContraPartidas}
                contrapartida={contrapartidas[selectedContrapartida]}
            />
            <Col xs="12">
                <FormGroup>
                    <div className="shadow shadow-showcase p-15">
                        <div className="d-flex justify-content-between b-b-light">
                            <Label><h6>Contrapartida*</h6></Label>
                            <Button
                                color="link"
                                disabled={!lancamento.lan_vl_lancamento || !lancamento.lan_cd_conta || !lancamento.lan_nu_doc}
                                onClick={() => setOpenModal('add')}
                            >
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                        <Row>
                            <Col xs="12">
                                <Fragment>
                                    <DataTable
                                        noHeader
                                        noDataComponent={<span className="p-20">Nenhuma Contrapartida Vinculada</span>}
                                        highlightOnHover
                                        pointerOnHover
                                        onRowClicked={(row) => {
                                            setSelectedContrapartida(row.key)
                                            setOpenModal('view')
                                        }}
                                        columns={[
                                            {
                                                name: 'Conta',
                                                selector: 'conta_name',

                                            },
                                            {
                                                name: 'Pessoa',
                                                selector: 'pessoa_name',

                                            },
                                            {
                                                name: 'Centro',
                                                selector: 'centro_name',

                                            },
                                            {
                                                name: 'Documento',
                                                selector: 'cnt_nu_doc',

                                            },
                                            {
                                                name: 'Valor',
                                                selector: 'valor_contrapartida',

                                            },
                                            {
                                                name: 'Histórico',
                                                selector: 'cnt_ds_historico',
                                            },
                                            {
                                                name: '',
                                                selector: 'del',
                                                center: true,
                                                width: '10%'
                                            }
                                        ]}
                                        data={contrapartidas.map(({
                                            conta,
                                            pessoa,
                                            centro_custo,
                                            cnt_nu_doc,
                                            cnt_vl_contrapartida,
                                            cnt_ds_historico
                                        },
                                            key
                                        ) => {
                                            const conta_name = `${conta?.ctn_cd_conta} - ${conta?.ctn_nm_conta}`;
                                            const pessoa_name = `${pessoa?.pss_cd_pessoa} - ${pessoa?.pss_nm_pessoa}`;
                                            const centro_name = `${centro_custo?.cct_cd_centro} - ${centro_custo?.cct_nm_centro}`
                                            const valor_contrapartida = (
                                                <>
                                                    <span id={`vl_contrapartida${key}`}>{new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(cnt_vl_contrapartida)}</span>
                                                    <UncontrolledTooltip target={`vl_contrapartida${key}`}>
                                                        {`${new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(cnt_vl_contrapartida)}`}
                                                    </UncontrolledTooltip>
                                                </>
                                            );
                                            const del = (
                                                <>
                                                    <a
                                                        id={`editcontra${key}`}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedContrapartida(key)
                                                            setOpenModal('edit');
                                                        }}
                                                    >
                                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                    </a>
                                                    <UncontrolledTooltip target={`editcontra${key}`}>
                                                        Editar
                                                    </UncontrolledTooltip>
                                                    <a
                                                        id={`delcontra${key}`}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => removeContrapartida(key)}
                                                    >
                                                        <i className="fa fa-times font-danger f-24 m-10"></i>
                                                    </a>
                                                    <UncontrolledTooltip target={`delcontra${key}`}>
                                                        Desvincular
                                                    </UncontrolledTooltip>
                                                </>

                                            );
                                            return { key, conta_name, pessoa_name, centro_name, cnt_nu_doc, valor_contrapartida, cnt_ds_historico, del }
                                        })}
                                    />
                                </Fragment>
                            </Col>
                        </Row>
                    </div>
                    <FormFeedback
                        style={{
                            display: validator.visibleFields.includes('contrapartidas') ||
                                !!contrapartidas.filter((cnt, index) => !!errors[`contrapartidas.${index}.cnt_nu_doc`]) ||
                                (!validator.fields.contrapartidas && validator.messagesShown)
                                ? 'block' : 'none'
                        }}
                    >
                        {validator.message(
                            'contrapartidas',
                            contrapartidas.toString(),
                            `required|calcula_cnt:${somaContrapartida},${lancamento.lan_vl_lancamento}`)}
                        {contrapartidas.map((el, index) => {
                            return errors?.[`contrapartidas.${index}.cnt_nu_doc`]?.map((erro, indxErr) => <span key={indxErr}>{erro}</span>)
                        })}
                    </FormFeedback>
                </FormGroup>
            </Col>
        </>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {
})(ContraPartidas)