import React, { useState, useMemo, Fragment } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Button, UncontrolledTooltip, FormFeedback } from "reactstrap";
import Add from "./Add/";
import Edit from "./Edit/";
import View from "./View/";
import { format, parseISO } from "date-fns";
import sweetalert2 from "sweetalert2";
import { toast } from "react-toastify";

const Faturas = ({ contrapartidas, setContraPartidas, validator }) => {
    const [openModal, setOpenModal] = useState(null);
    const [selectedFatura, setSelectedFatura] = useState(null);
    const faturas = useMemo(() => {
        let res = [];
        contrapartidas.forEach((element, indexCtn) => {
            if (!!element.faturas.length) {
                element.faturas.forEach((fatura, key) => {
                    const { faturas, conta, pessoa, ...infos_contrapartida } = element;
                    res.push({ ...fatura, infos_contrapartida, index_fatura: key, contrapartida: indexCtn });
                });
            }
        });
        return res;
    }, [contrapartidas]);

    const removeFatura = async (indexContrapartida, indexFatura) => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente desvincular está fatura?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            icon: 'warning',
        });
        if (confirm.value) {
            contrapartidas[indexContrapartida].faturas.splice(indexFatura, 1);
            setContraPartidas(contrapartidas);
            toast.success('Registro desvinculado com sucesso.', toast.POSITION.TOP_RIGHT);
        }
    }
    return (
        <>
            <Add
                isOpen={openModal === 'add'}
                contrapartidas={contrapartidas}
                setContraPartidas={setContraPartidas}
                clickClose={() => setOpenModal(null)}
            />
            <Edit
                isOpen={openModal === 'edit'}
                contrapartidas={contrapartidas}
                faturas={faturas}
                setContraPartidas={setContraPartidas}
                clickClose={() => setOpenModal(null)}
                selectedFatura={selectedFatura}
            />
            <View
                isOpen={openModal === 'view'}
                contrapartidas={contrapartidas}
                setContraPartidas={setContraPartidas}
                clickClose={() => setOpenModal(null)}
                fatura={faturas[selectedFatura]}
            />
            <Col xs="12">
                <FormGroup>
                    <div className="shadow shadow-showcase p-15">
                        <div className="d-flex justify-content-between b-b-light">
                            <Label><h6>Fatura</h6></Label>
                            <Button
                                color="link"
                                disabled={!contrapartidas.length}
                                onClick={() => {
                                    setOpenModal('add')
                                }}
                            >
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                        <Row>
                            <Col xs="12">
                                <DataTable
                                    noHeader
                                    noDataComponent={<span className="p-20">Nenhuma Fatura Vinculada</span>}
                                    highlightOnHover
                                    pointerOnHover
                                    onRowClicked={(row) => {
                                        setSelectedFatura(row.key)
                                        setOpenModal('view')
                                    }}
                                    columns={[

                                        {
                                            name: 'Fatura',
                                            selector: 'ftr_nu_fatura',

                                        },
                                        {
                                            name: 'Valor',
                                            selector: 'fat_valor'
                                        },
                                        {
                                            name: 'Vencimento',
                                            selector: 'dt_vencimento'
                                        },
                                        {
                                            name: 'Tipo',
                                            selector: 'ftr_tp_baixa',
                                        },
                                        {
                                            name: '',
                                            selector: 'del',
                                            center: true,
                                            width: '10%'

                                        }
                                    ]}
                                    data={faturas.map(({
                                        ftr_dt_vencimento,
                                        index_fatura,
                                        ftr_tp_baixa,
                                        contrapartida,
                                        ftr_nu_fatura,
                                        ftr_vl_fatura
                                    },
                                        key
                                    ) => {
                                        const fat_valor = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(ftr_vl_fatura);
                                        const dt_vencimento = format(parseISO(ftr_dt_vencimento), 'dd/MM/Y');
                                        const del = (
                                            <>
                                                <a
                                                    id={`editfat${key}`}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedFatura(key)
                                                        setOpenModal('edit');
                                                    }}
                                                >
                                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                </a>
                                                <UncontrolledTooltip target={`editfat${key}`}>
                                                    Editar
                                                </UncontrolledTooltip>
                                                <a
                                                    id={`delfatu${key}`}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => removeFatura(contrapartida, index_fatura)}
                                                >
                                                    <i className="fa fa-times font-danger f-24 m-10"></i>
                                                </a>
                                                <UncontrolledTooltip target={`delfatu${key}`}>
                                                    Desvincular
                                                </UncontrolledTooltip>
                                            </>

                                        );
                                        return ({ key, ftr_nu_fatura, fat_valor, dt_vencimento, ftr_tp_baixa, del })
                                    })}
                                />
                            </Col>
                        </Row>
                    </div>
                    {contrapartidas.map((ctn, index) => {
                        const totalFatura = ctn.faturas.reduce((acu, ftr) => {
                            return acu + parseFloat(ftr.ftr_vl_fatura);
                        }, 0).toFixed(2);
                        const totalContra = parseFloat(ctn.cnt_vl_contrapartida).toFixed(2);
                        return (
                            <FormFeedback
                                style={{
                                    display: validator.visibleFields.includes(`${ctn.cnt_nu_doc}`) ||
                                        (!validator.fields[ctn.cnt_nu_doc] && validator.messagesShown)
                                        ? 'block' : 'none'
                                }}
                                key={index}>
                                {validator.message(`${ctn.cnt_nu_doc}`, ctn.faturas.toString(), `calcula_ftr:${totalFatura},${totalContra}`)}
                            </FormFeedback>
                        )
                    })}
                </FormGroup>
            </Col>
        </>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Faturas);