import React from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import SecondCard from "./SecondCard";
import FirstCard from "./FirstCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";

function View({ isOpen, clickClose, notaFiscal }) {
    return (
        <Modal size="xxl" isOpen={isOpen}>
            <ModalHeader toggle={(() => clickClose())}>
                Informações da Nota Fiscal
            </ModalHeader>
            <ModalBody>
                {notaFiscal &&
                    <Row>
                        <Col md="6" xs="12">
                            <Row>
                                <Col xs="12">
                                    <FirstCard notaFiscal={notaFiscal} />
                                </Col>
                                <Col xs="12">
                                    <SecondCard notaFiscal={notaFiscal} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6" xs="12">
                            <Row>
                                <Col xs="12">
                                    <ThirdCard notaFiscal={notaFiscal} />
                                </Col>
                                <Col xs="12">
                                    <FourthCard notaFiscal={notaFiscal} />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(View);