
import React, { useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { getTiposPessoa, destroyTiposPessoa } from "../../../redux/actions";
import { Button } from "reactstrap";
import sweetalert2 from "sweetalert2";
function Table({
    loadingTiposPessoa,
    tiposPessoa,
    getTiposPessoa,
    setSelectedTps,
    setOpenModal,
    curFilter,
    destroyTiposPessoa,
    loadingDestroyTps
}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ tps_cd_tipo, tps_nm_tipo }) => ({ tps_cd_tipo, tps_nm_tipo })))
    }, []);

    const deleteTiposPessoa = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir os registros selecionados?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ tps_nm_tipo }) => {
                return (`<li class="list-group-item-action list-group-item">${tps_nm_tipo}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.isConfirmed) {
            const page = (!!tiposPessoa && tiposPessoa.current_page !== 1) ? tiposPessoa.current_page : 1;
            const fields = { tiposPessoa: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyTiposPessoa({ fields, onDelete });
        }
    }

    return (
        <>
            {loadingTiposPessoa ?
                <div className="loader-box">
                    <div className="loader-1"></div>
                </div>
                :
                !!tiposPessoa &&
                <DataTable
                    persistTableHead
                    selectableRows
                    pagination
                    paginationServer
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={toggleCleared}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                    }}
                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                    progressComponent={
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    }
                    noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                    contextActions={
                        <Button
                            sid="centro_custo.destroy"
                            disabled={loadingDestroyTps}
                            onClick={deleteTiposPessoa}
                            color="danger">{loadingDestroyTps ? 'Processando...' : 'Remover Tipos(s) de Pessoa'}
                        </Button>
                    }
                    progressPending={loadingDestroyTps}
                    paginationPerPage={tiposPessoa.per_page}
                    paginationTotalRows={tiposPessoa.total}
                    paginationDefaultPage={tiposPessoa.current_page}
                    onChangePage={page => {
                        getTiposPessoa({ fields: { ...curFilter.current, page } })
                    }}
                    selectableRowSelected={(row) => {
                        return selectedRows.some(el => el.tps_cd_tipo === row.tps_cd_tipo)
                    }}
                    columns={[
                        {
                            name: 'Nome',
                            selector: 'tps_nm_tipo',
                            center: true
                        },
                        {
                            name: 'Descrição',
                            selector: 'desc_tipo',
                            center: true
                        },
                        {
                            name: 'Tipo de Documento',
                            selector: 'tp_doc',
                            center: true
                        },
                        {
                            name: 'Ações',
                            selector: 'acoes',
                            center: true
                        }
                    ]}
                    data={tiposPessoa.data.map(({ tps_cd_tipo, tps_nm_tipo, tps_tp_documento, tps_ds_tipo }, index) => {

                        const desc_tipo = tps_ds_tipo || "Não Informado";

                        const tp_doc = tps_tp_documento === "1" ? "CPF" : tps_tp_documento === "0" ? "CNPJ" : 'Não Informado';

                        const acoes = (
                            <div style={{ display: "flex", overflow: "auto" }}>
                                <a
                                    style={{ cursor: 'pointer' }}
                                    title="Editar informações"
                                    onClick={() => {
                                        setSelectedTps(index)
                                        setOpenModal('view')
                                    }}
                                >
                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                </a>
                                <a
                                    sid="tipos_pessoas.update"
                                    style={{ cursor: 'pointer' }}
                                    title="Editar informações"
                                    onClick={() => {
                                        setSelectedTps(index)
                                        setOpenModal('edit')
                                    }}
                                >
                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                </a>
                            </div>
                        )
                        return { tps_cd_tipo, tps_nm_tipo, tp_doc, desc_tipo, acoes }
                    })}
                />

            }
        </>
    )
}

const mapStateToProps = ({ TiposPessoas }) => {
    const { tiposPessoa, loadingTiposPessoa, loadingDestroyTps } = TiposPessoas;
    return { tiposPessoa, loadingTiposPessoa, loadingDestroyTps };
}

export default connect(mapStateToProps, {
    getTiposPessoa,
    destroyTiposPessoa
})(Table);