import {
    GET_LIST_EMPRESAS_ASYNC,
    GET_LIST_EMPRESAS,
    GET_EMPRESAS_ASYNC,
    STORE_EMPRESAS_ASYNC,
    UPDATE_EMPRESAS_ASYNC,
    DESTROY_EMPRESAS_ASYNC
} from "../actionTypes";

export const getEmpresas = (payload = {}) => ({
    type: GET_EMPRESAS_ASYNC,
    payload
})

export const getListEmpresas = (payload = {}) => ({
    type: GET_LIST_EMPRESAS_ASYNC,
    payload
});

export const changeListEmpresas = payload => ({
    type: GET_LIST_EMPRESAS,
    payload
});

export const storeEmpresas = payload => ({
    type: STORE_EMPRESAS_ASYNC,
    payload
});

export const updateEmpresa = payload => ({
    type: UPDATE_EMPRESAS_ASYNC,
    payload
});

export const destroyEmpresa = payload => ({
    type: DESTROY_EMPRESAS_ASYNC,
    payload
});