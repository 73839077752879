import React, { useState, useCallback, useRef } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Form,
    Row,
    Col
} from "reactstrap";
import { connect } from "react-redux";
import DadosGerais from "./DadosGerais";
import QntAndValor from "./QntAndValor";
import { format } from "date-fns";
import { updateCustoProduto } from "../../../../../redux/actions";
import { validatorMessages } from "../../../../../constraints/variables";
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';

function Edit({
    isOpen,
    clickClose,
    updateCustoProduto,
    loadingUpdateCustoProd,
    selectedCustoProd,
    custosProdutos
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    const [fields, setFields] = useState(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleValor = (name, value) => {
        switch (name) {
            case 'cmv_vlr_valor':
                setFields({ ...fields, [name]: value })
                break;
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'cmv_dt_data':
                if (!!selected) {
                    setFields({ ...fields, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const cmv_vlr_valor_produto = parseFloat(fields.cmv_qtd_quantidade) * parseFloat(fields.cmv_vlr_valor);
            updateCustoProduto({
                fields: { ...fields, cmv_vlr_valor_produto },
                onSave: clickClose,
                index: selectedCustoProd
            });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onOpened={() => {
                const dados = { ...custosProdutos.data[selectedCustoProd] };
                delete dados.row_num;
                delete dados.cmv_id;
                setFields(dados);
            }}
            onClosed={() => {
                setFields(null)
                validator.visibleFields = [];
                validator.messagesShown = false;
            }}
        >
            <ModalHeader toggle={() => !loadingUpdateCustoProd && clickClose()}>
                Editar Custo por Produto
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <DadosGerais
                                    fields={fields}
                                    handleChange={handleChange}
                                    validator={validator}
                                    handleChangeSelect={handleChangeSelect}
                                    validFields={validFields}
                                />
                            </Col>
                            <Col xs="12">
                                <QntAndValor
                                    validator={validator}
                                    validFields={validFields}
                                    handleChange={handleChange}
                                    handleValor={handleValor}
                                    fields={fields}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={loadingUpdateCustoProd}
                            onClick={() => !loadingUpdateCustoProd && clickClose()}
                            type="button">
                            Fechar
                        </Button>
                        <Button disabled={loadingUpdateCustoProd} color="primary">
                            {loadingUpdateCustoProd ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ CmvDiario }) => {
    const { loadingUpdateCustoProd, custosProdutos } = CmvDiario;
    return { loadingUpdateCustoProd, custosProdutos }
}

export default connect(mapStateToProps, {
    updateCustoProduto
})(Edit);