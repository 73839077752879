import React from 'react';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { updatePermissions } from "../../../../redux/actions";

const UpdatePermissions = ({ isOpen, adicionadas, removidas, loadingUpdPermissions, updatePermissions, clickClose }) => {
    return (
        <>
            <Modal isOpen={isOpen} size="lg" onOpened={() => updatePermissions()}>
                <ModalHeader toggle={() => clickClose()}>Permissões de Acesso</ModalHeader>
                <ModalBody>
                    {loadingUpdPermissions ?
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                        :
                        <Row>
                            <Col lg="6" sm="12" >
                                <div className="ribbon-wrapper mb-4">
                                    <div className="ribbon ribbon-success ribbon-space-bottom">Adicionadas</div>
                                    <Row>
                                        {
                                            !!adicionadas.length ?
                                                adicionadas.map(({ name }, key) => (
                                                    <Col xs="12" className="m-b-5" key={key}>
                                                        {name}
                                                    </Col>
                                                ))
                                                :
                                                <Col xs="12" className="m-b-5">
                                                    Nenhuma nova permissão adicionada
                                                </Col>
                                        }
                                    </Row>
                                </div>
                            </Col>
                            <Col lg="6" sm="12">
                                <div className="ribbon-wrapper mb-4">
                                    <div className="ribbon ribbon-danger ribbon-space-bottom">Removidas</div>
                                    <Row>
                                        {
                                            !!removidas.length ?
                                                removidas.map(({ name }, key) => (
                                                    <Col xs="12" className="m-b-5" key={key}>
                                                        {name}
                                                    </Col>
                                                ))
                                                :
                                                <Col xs="12" className="m-b-5">
                                                    Nenhuma permissão removida
                                                </Col>
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    }
                </ModalBody>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ Permissions }) => {
    const { adicionadas, removidas, loadingUpdPermissions } = Permissions;
    return { adicionadas, removidas, loadingUpdPermissions }
}

export default connect(mapStateToProps, {
    updatePermissions
})(UpdatePermissions);