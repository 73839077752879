import {
    GET_LIST_TIPO_PESSOAS_ASYNC,
    GET_TIPOS_PESSOA_ASYNC,
    DESTROY_TIPOS_PESSOA_ASYNC,
    STORE_TIPO_PESSOA_ASYNC,
    UPDATE_TIPO_PESSOA_ASYNC
} from "../actionTypes";

export const getListTiposPessoas = () => ({
    type: GET_LIST_TIPO_PESSOAS_ASYNC
});

export const getTiposPessoa = (payload = {}) => ({
    type: GET_TIPOS_PESSOA_ASYNC,
    payload
});

export const destroyTiposPessoa = payload => ({
    type: DESTROY_TIPOS_PESSOA_ASYNC,
    payload
});

export const storeTipoPessoa = payload => ({
    type: STORE_TIPO_PESSOA_ASYNC,
    payload
});

export const updateTipoPessoa = payload => ({
    type: UPDATE_TIPO_PESSOA_ASYNC,
    payload
});