import {
    READOFX_EXTRATO_BANCO_ASYNC,
    READOFX_EXTRATO_BANCO,
    CLEAR_EXTRATO_BANCO,
    READTXT_EXTRATO_BANCO_ASYNC,
    READTXT_EXTRATO_BANCO
} from "../actionTypes";

const initalState = {
    loadingReadOfxBanco: false,
    extratoBanco: null
}

export default (state = initalState, action) => {
    switch (action.type) {
        case READOFX_EXTRATO_BANCO_ASYNC:
            return { ...state, loadingReadOfxBanco: true };
        case READOFX_EXTRATO_BANCO:
            return { ...state, ...action.payload, loadingReadOfxBanco: false };
        case READTXT_EXTRATO_BANCO_ASYNC:
            return { ...state, loadingReadOfxBanco: true };
        case READTXT_EXTRATO_BANCO:
            return { ...state, ...action.payload, loadingReadOfxBanco: false };
        case CLEAR_EXTRATO_BANCO:
            return { ...state, extratoBanco: null };
        default: return state;
    }
}