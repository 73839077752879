import { put, all, call, takeLatest, fork } from "redux-saga/effects";
import {
    GET_RLT_EXTRATO_CONTAS_ASYNC,
    GET_RLT_EXTRATO_CONTAS,
    GET_RLT_EXTRATO_PESSOAS_ASYNC,
    GET_RLT_EXTRATO_PESSOAS,
    GET_RTL_NAO_RECEBIDOS_ASYNC,
    GET_RTL_NAO_RECEBIDOS,
    GET_RLT_NAO_PAGOS_ASYNC,
    GET_RLT_NAO_PAGOS,
    GET_RLT_PESQUISA_ASYNC,
    GET_RLT_PESQUISA,
    GET_RLT_DEB_CRED_ASYNC,
    GET_RLT_DEB_CRED,
    GET_RLT_CNT_ASYNC,
    GET_RLT_CNT,
    GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC,
    GET_RLT_TITULOS_NAO_RECPAGOS
} from "../actionTypes";
import Api from "../../services/api";

function* getRltExtratoContasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltExtratoContas, fields);

        if (data.ok) {
            const { rltExtratoContas } = data;
            yield put({ type: GET_RLT_EXTRATO_CONTAS, payload: { rltExtratoContas } });
        }
    } catch (error) {
        yield put({ type: GET_RLT_EXTRATO_CONTAS, payload: {} });
    }
}
const apiGetRltExtratoContas = async fields => {
    const { data } = await Api.get(`/api/rltOperacionais/relatorioExtratoContas`, { params: fields });
    return data;
}


function* getRltExtratoPessoasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltExtratoPessoas, fields);
        if (data.ok) {
            const { dados } = data;
            yield put({ type: GET_RLT_EXTRATO_PESSOAS, payload: { dados } });
        }
    } catch (error) {
        yield put({ type: GET_RLT_EXTRATO_PESSOAS, payload: {} });
    }
}
const apiGetRltExtratoPessoas = async fields => {
    const { data } = await Api.get(`/api/rltOperacionais/relatorioExtratoPessoa`, { params: fields })
    return data;
}

function* getRltNaoRecebidosSaga({ payload }) {
    try {
        const { fields } = payload;

        const data = yield call(apiGetRtlNaoRecebidos, fields);

        if (data.ok) {
            const { rltNaoRecebidos } = data;
            yield put({ type: GET_RTL_NAO_RECEBIDOS, payload: { rltNaoRecebidos } });
        }

    } catch (error) {
        yield put({ type: GET_RTL_NAO_RECEBIDOS, payload: {} });
    }
}
const apiGetRtlNaoRecebidos = async fields => {
    const { data } = await Api.get(`/api/rltOperacionais/relatorioNaoRecebidos`, { params: fields });

    return data;
}

function* getRltNaoPagosSaga({ payload }) {
    try {
        const { fields } = payload;

        const data = yield call(apiGetRltNaoPagos, fields);

        if (data.ok) {
            const { rltNaoPagos } = data;
            yield put({ type: GET_RLT_NAO_PAGOS, payload: { rltNaoPagos } });

        }

    } catch (error) {
        yield put({ type: GET_RLT_NAO_PAGOS, payload: {} });
    }
}
const apiGetRltNaoPagos = async fields => {
    const { data } = await Api.get(`/api/rltOperacionais/relatorioNaoPagos`, { params: fields });


    return data;
}

function* getRltPesquisaSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltPesquisa, fields);
        const { rltPesquisa } = data;

        yield put({ type: GET_RLT_PESQUISA, payload: { rltPesquisa } });
    } catch (error) {
        yield put({ type: GET_RLT_PESQUISA, payload: {} });
    }
}
const apiGetRltPesquisa = async fields => {
    const { data } = await Api.get(`/api/rltOperacionais/relatorioPesquisa`, { params: fields })

    return data;
}

function* getRltDebCredSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltDebCred, fields);
        const { rltDebCred } = data;
        yield put({ type: GET_RLT_DEB_CRED, payload: { rltDebCred } })
    } catch (error) {
        yield put({ type: GET_RLT_DEB_CRED, payload: {} })
    }
}
const apiGetRltDebCred = async fields => {
    const { data } = await Api.get(`/api/rltOperacionais/relatorioDebCred`, { params: fields });

    return data;

}

function* getRltCntSaga({ payload }) {
    try {
        const { fields } = payload;

        const { rltCnt } = yield call(apiGetRltCnt, fields);

        yield put({ type: GET_RLT_CNT, payload: { rltCnt } });

    } catch (error) {
        yield put({ type: GET_RLT_CNT, payload: {} });
    }
}
const apiGetRltCnt = async fields => {
    const { data } = await Api.get('/api/rltOperacionais/relatorioCnt', { params: fields });

    return data;
}

function* getRltTitulosNaoRecPagosSaga({ payload }) {
    try {
        const { fields } = payload;

        const { rltTitulosNaoRecPagos } = yield call(apiGetRltTitulosNaoRecPagos, fields);

        yield put({ type: GET_RLT_TITULOS_NAO_RECPAGOS, payload: { rltTitulosNaoRecPagos } });

    } catch (error) {
        yield put({ type: GET_RLT_TITULOS_NAO_RECPAGOS, payload: {} });
    }
}
const apiGetRltTitulosNaoRecPagos = async fields => {
    const { data } = await Api.get('/api/rltOperacionais/relatorioTitulosRecPagos', { params: fields });

    return data;
}

function* watchGetRltExtratoContas() {
    yield takeLatest(GET_RLT_EXTRATO_CONTAS_ASYNC, getRltExtratoContasSaga);
}

function* watchGetRltExtratoPessoas() {
    yield takeLatest(GET_RLT_EXTRATO_PESSOAS_ASYNC, getRltExtratoPessoasSaga);
}
function* watchGetRltNaoRecebidos() {
    yield takeLatest(GET_RTL_NAO_RECEBIDOS_ASYNC, getRltNaoRecebidosSaga);
}
function* watchGetRltNaoPagos() {
    yield takeLatest(GET_RLT_NAO_PAGOS_ASYNC, getRltNaoPagosSaga);
}
function* watchGetRltPesquisa() {
    yield takeLatest(GET_RLT_PESQUISA_ASYNC, getRltPesquisaSaga);
}
function* watchGetRltDebCred() {
    yield takeLatest(GET_RLT_DEB_CRED_ASYNC, getRltDebCredSaga);
}
function* watchGetRltCnt() {
    yield takeLatest(GET_RLT_CNT_ASYNC, getRltCntSaga);
}
function* watchGetRltTitulosNaoPagos() {
    yield takeLatest(GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC, getRltTitulosNaoRecPagosSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetRltExtratoContas),
        fork(watchGetRltExtratoPessoas),
        fork(watchGetRltNaoRecebidos),
        fork(watchGetRltNaoPagos),
        fork(watchGetRltPesquisa),
        fork(watchGetRltDebCred),
        fork(watchGetRltCnt),
        fork(watchGetRltTitulosNaoPagos)
    ]);
}