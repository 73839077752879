import React, { useState, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Form
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../constraints/variables";
import { CpfCnpjInput } from "../../../components/CustomInputs";
import SelectPagination from "../../../components/SelectPagination"
import { getListPessoas, storeCentroCusto } from "../../../redux/actions";
import { toast } from "react-toastify";

const initalState = {
    cct_nm_centro: "",
    cct_nu_identificacao: "",
    cct_cd_pessoa: ""
}

function Add({
    isOpen,
    clickClose,
    errors,
    loadingStoreCct,
    loadingListPesssoas,
    listPessoas,
    getListPessoas,
    storeCentroCusto
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;


    const [fields, setFields] = useState(initalState);

    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const clearErrors = name => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
        clearErrors(name)
    }
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeCentroCusto({ fields, onSave: clickClose });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }

    const optionsPessoas = useMemo(() =>
        listPessoas.data.map(({ id, pss_nm_pessoa, pss_cd_pessoa, pss_nu_identificacao }) => {
            return ({
                value: id,
                name: `${pss_cd_pessoa} - ${pss_nm_pessoa}`,
                subItem: `Num.Identificação: ${pss_nu_identificacao}`,
                pss_cd_pessoa
            })
        }), [listPessoas.data]);

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
               !listPessoas.data.length && getListPessoas()
            }}
            onClosed={() => { 
                setFields(initalState)
            }}
        >
            <ModalHeader toggle={() => !loadingStoreCct && clickClose()}>
                Adicionar Centro de Custo
            </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Nome*</Label>
                                <Input
                                    placeholder="Informe o Nome"
                                    name="cct_nm_centro"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={isInvalid('cct_nm_centro')}
                                    onChange={handleChange}
                                />
                                <FormFeedback>
                                    {validator.message('cct_nm_centro', fields.cct_nm_centro, 'required|max:30')}
                                    {(!!errors.cct_nm_centro) && errors.cct_nm_centro.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Num.Identificação</Label>
                                <CpfCnpjInput
                                    placeholder="Informe o Num.Identifição"
                                    name="cct_nu_identificacao"
                                    value={fields.cct_nu_identificacao}
                                    onChange={handleChange}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={isInvalid('cct_nu_identificacao')}
                                />
                                <FormFeedback>
                                    {validator.message('cct_nu_identificacao', fields.cct_nu_identificacao, 'max:20')}
                                    {(!!errors.cct_nu_identificacao) && errors.cct_nu_identificacao.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Pessoa</Label>
                                <SelectPagination
                                    id="selectPessoa"
                                    labelKey="name"
                                    maxResults={50}
                                    placeholder="Selecione a Pessoa Padrão"
                                    data={optionsPessoas}
                                    totalResults={listPessoas.total}
                                    reduxAction={getListPessoas}
                                    clearButton
                                    isLoading={loadingListPesssoas}
                                    onChange={(selected) => {
                                        setFields({ ...fields, cct_cd_pessoa: selected[0]?.pss_cd_pessoa || "" });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" disabled={loadingStoreCct} onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={loadingStoreCct} color="primary">
                        {loadingStoreCct ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ CentroCusto, Pessoas }) => {

    const { errors, loadingStoreCct } = CentroCusto;
    const { list: listPessoas, loadingList: loadingListPesssoas } = Pessoas;

    return { errors, loadingStoreCct, listPessoas, loadingListPesssoas }
}

export default connect(mapStateToProps, {
    getListPessoas,
    storeCentroCusto
})(Add);
