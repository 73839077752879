import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Button, Col, Form } from "reactstrap";
import DadosGerais from "./DadosGerais";
import Contas from "./Contas";
import { getListContas, updateProdutos } from "../../../../../redux/actions";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../../../constraints/variables";
import { toast } from "react-toastify";

function Edit({
    isOpen,
    clickClose,
    getListContas,
    loadingUpdateProduto,
    updateProdutos,
    produtos,
    selectedProduto
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    const [fields, setFields] = useState(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }


    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            updateProdutos({ fields, onSave: clickClose, index: selectedProduto });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
                getListContas()
                const dados = { ...produtos.data[selectedProduto] };
                delete dados.row_num;
                setFields(dados);
            }}
            onClosed={() => {
                setFields(null);
                validator.visibleFields = [];
                validator.messagesShown = false;
            }}
        >
            <ModalHeader toggle={() => !loadingUpdateProduto && clickClose()}>
                Editar Produto
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <DadosGerais
                                    validator={validator}
                                    fields={fields}
                                    handleChange={handleChange}
                                    handleChangeSelect={handleChangeSelect}
                                    validFields={validFields}
                                />
                            </Col>
                            <Col xs="12">
                                <Contas
                                    validator={validator}
                                    fields={fields}
                                    handleChangeSelect={handleChangeSelect}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" disabled={loadingUpdateProduto} onClick={() => !loadingUpdateProduto && clickClose()}>
                            Fechar
                        </Button>
                        <Button disabled={loadingUpdateProduto} color="primary">
                            {loadingUpdateProduto ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Produtos }) => {
    const { loadingUpdateProduto, produtos } = Produtos;
    return { loadingUpdateProduto, produtos }
}

export default connect(mapStateToProps, {
    getListContas,
    updateProdutos
})(Edit);