import React, { useMemo } from "react";
import {
    Col,
    FormGroup,
    Row,
    Label,
    FormFeedback,
} from "reactstrap";
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';

const NivelAcesso = ({
    validFields,
    validator,
    loadingGroups,
    groups,
    user,
    handleChangeSelect
}) => {
    const optionsGroups = useMemo(() => groups.map(({ id, name }) => ({ name, id })), [groups])
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Nivel de Acesso</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Perfis*</Label>
                                <Typeahead
                                    id="multSelectGroup"
                                    labelKey="name"
                                    selected={optionsGroups.filter(({ id }) => !!user.groups.find((el) => el.id === id))}
                                    placeholder="Selecione o Pefil"
                                    emptyLabel={"Nenhum Perfil Encontrado"}
                                    onBlur={() => { validFields('groups') }}
                                    isInvalid={validator.visibleFields.includes('groups') ||
                                        (!validator.fields.groups && validator.messagesShown)
                                    }
                                    clearButton
                                    onChange={(selected) => handleChangeSelect('groups', selected)}
                                    isLoading={loadingGroups}
                                    disabled={loadingGroups}
                                    multiple
                                    options={optionsGroups}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('groups') ||
                                            (!validator.fields.groups && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('groups', user.groups.toString(), `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Groups }) => {
    const { list: groups, loadingList: loadingGroups } = Groups;
    return { groups, loadingGroups }
}

export default connect(mapStateToProps, {})(NivelAcesso)