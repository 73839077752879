import { all, takeLatest, call, put, fork, select } from "redux-saga/effects";
import {
    GET_FERIADOS_ASYNC,
    GET_FERIADOS,
    STORE_FERIADO_ASYNC,
    STORE_FERIADO,
    UPDATE_FERIADO_ASYNC,
    UPDATE_FERIADO,
    DESTROY_FERIADOS_ASYNC,
    DESTROY_FERIADOS
} from "../actionTypes";
import Api from "../../services/api";
import { toast } from "react-toastify";
import { selectorFeriados } from "../selectors";


function* getFeriadosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetFeriados, fields);
        if (data.ok) {
            const { feriados } = data;
            yield put({ type: GET_FERIADOS, payload: { feriados } });
        }
    } catch (error) {
        yield put({ type: GET_FERIADOS, payload: {} });
    }
}
const apiGetFeriados = async fields => {
    const { data } = await Api.get(`/api/feriados`, { params: fields });

    return data;
}

function* storeFeriadoSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreFeriados, fields);

        if (!!data && data.ok) {
            const { feriados } = data;
            yield put({ type: STORE_FERIADO, payload: { feriados } });
            onSave();
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
        }

        if (!!errors) {
            yield put({ type: STORE_FERIADO, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: STORE_FERIADO, payload: {} });
    }
}
const apiStoreFeriados = async fields => {
    const data = await Api.post(`/api/feriados/store`, fields);

    return data;
}

function* updateFeriadoSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;
        const feriados = { ...yield select(selectorFeriados) };
        const { id } = feriados.data[index];
        const { data, errors } = yield call(apiUpdateFeriado, fields, id);
        if (!!data && data.ok) {
            feriados.data.splice(index, 1, data.feriado);
            yield put({ type: UPDATE_FERIADO, payload: { feriados } });
            onSave();
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
        }
        if (!!errors) {
            yield put({ type: UPDATE_FERIADO, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_FERIADO, payload: {} });
    }
}
const apiUpdateFeriado = async (fields, id) => {
    const data = await Api.put(`/api/feriados/update/${id}`, fields);

    return data;
}

function* destroyFeriadosSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyFeriados, fields);
        if (data.ok) {
            const { feriados } = data;
            yield put({ type: DESTROY_FERIADOS, payload: { feriados } });
            toast.success(data.msg, toast.POSITION.BOTTOM_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_FERIADOS, payload: {} });
    }
}
const apiDestroyFeriados = async fields => {
    const { data } = await Api.delete(`/api/feriados/destroy`, { data: fields });

    return data;
}

function* watchGetFeriados() {
    yield takeLatest(GET_FERIADOS_ASYNC, getFeriadosSaga);
}
function* watchStoreFeriado() {
    yield takeLatest(STORE_FERIADO_ASYNC, storeFeriadoSaga);
}
function* watchUpdateFeriadoSaga() {
    yield takeLatest(UPDATE_FERIADO_ASYNC, updateFeriadoSaga);
}
function* watchDestroyFeriados() {
    yield takeLatest(DESTROY_FERIADOS_ASYNC, destroyFeriadosSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetFeriados),
        fork(watchStoreFeriado),
        fork(watchUpdateFeriadoSaga),
        fork(watchDestroyFeriados)
    ]);
}