import React, { Fragment } from 'react';
import { Row, Col,Table, Alert } from 'reactstrap';
import { connect } from "react-redux";
import { format, parseISO } from 'date-fns';

const RltModeloCentroCusto = ({
    rltExtratoContas,
    filter,
}) => {

    return (

        <Row className="b-light p-15 border-5 m-b-20">
            <Col xs="12">
                <Table className="font-arial-rlt">
                    <thead>
                        <tr>
                            <th className="b-light border-3">Conta</th>
                            <th className="b-light border-3">Lanc.</th>
                            <th className="b-light border-3">Vencimento</th>
                            <th className="b-light border-3">Histórico</th>
                            <th></th>
                            <th className="b-light border-3">Débito</th>
                            <th className="b-light border-3">Crédito</th>
                            <th className="b-light border-3">Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rltExtratoContas.map((conta, indexConta) => {
                            return !!conta.groups_cct ? Object.keys(conta.groups_cct).map((keyName, indexCct) => {
                                const arrCct = keyName.split('-');
                                let totalDebito = 0;
                                let totalCredito = 0;
                                let saldo = !!conta.saldo_conta ? parseFloat(conta.saldo_conta) : 0;
                                const groupLancamentos = conta.groups_cct[keyName].reduce((a, b) => {
                                    let res = a;
                                    if (!!b) {
                                        res[b?.vla_dt_lancamento] = [...a[b?.vla_dt_lancamento] || [], b];
                                    }
                                    return res;
                                }, {});

                                return (
                                    <Fragment key={indexCct}>
                                        <tr>
                                            <td width={100}>
                                                <u>{`${conta.ctn_cd_conta}-${arrCct[0]}`}</u>
                                            </td>
                                            <td colSpan="6">
                                                <u>{`${conta.ctn_nm_conta}-${arrCct[1]}`}</u>
                                            </td>
                                            <td>
                                                {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(saldo)}
                                            </td>
                                        </tr>
                                        {!!Object.keys(groupLancamentos).length ?
                                            Object.keys(groupLancamentos).map((objKey, indexGroup) => {
                                                let totalDiaDebito = 0;
                                                let totalDiaCredito = 0;
                                                return (
                                                    <Fragment key={indexGroup}>
                                                        {groupLancamentos[objKey].map((lancamento, indexLan) => {
                                                            if (lancamento.vla_tp_lancamento === 'D') {
                                                                totalDebito += parseFloat(lancamento.vla_vl_lancamento);
                                                                totalDiaDebito += parseFloat(lancamento.vla_vl_lancamento);
                                                            } else {
                                                                totalCredito += parseFloat(lancamento.vla_vl_lancamento);
                                                                totalDiaCredito += parseFloat(lancamento.vla_vl_lancamento);
                                                            }
                                                            if (indexLan === groupLancamentos[objKey].length - 1) {
                                                                saldo = (totalDiaDebito - totalDiaCredito) + saldo;
                                                            }
                                                            return (
                                                                <tr key={indexLan}>
                                                                    <td></td>
                                                                    <td width={10}>{indexLan === 0 && format(parseISO(objKey), 'dd/MM')}</td>
                                                                    <td width={10}>{!!lancamento.dt_ftr_vencimento && format(parseISO(lancamento.dt_ftr_vencimento), 'dd/MM/yy')}</td>
                                                                    <td width={500}>
                                                                        {`${conta.ctn_nm_conta} - 
                                                                          ${lancamento.vla_ds_historico} - 
                                                                          ${!!lancamento.vla_dt_lancamento ? format(parseISO(lancamento.vla_dt_lancamento), 'dd/MM/yy') : ""} - 
                                                                          ${lancamento.pessoa?.pss_nm_pessoa}
                                                                        `}
                                                                    </td>
                                                                    <td></td>
                                                                    <td width={200}>{lancamento.vla_tp_lancamento === 'D' &&
                                                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(lancamento.vla_vl_lancamento)}</td>
                                                                    <td width={200}>{lancamento.vla_tp_lancamento === 'C' &&
                                                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(lancamento.vla_vl_lancamento)}</td>
                                                                    <td width={200}></td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {
                                                            !!filter.totalDia &&
                                                            <tr>
                                                                <td colSpan="4"><span className="d-flex justify-content-end">Movimentação do dia: </span></td>
                                                                <td width={200}>
                                                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalDiaDebito - totalDiaCredito)}
                                                                </td>
                                                                <td width={200}>
                                                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalDiaDebito)}
                                                                </td>
                                                                <td width={200}>
                                                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalDiaCredito)}
                                                                </td>
                                                                <td width={200}>
                                                                    {`${new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(saldo)}`}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {Object.keys(groupLancamentos).length - 1 === indexGroup &&
                                                            <tr className="b-t-dark border-2">
                                                                <td colSpan="5"><span className="d-flex justify-content-end">Total: </span></td>
                                                                <td width={200}>
                                                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalDebito)}
                                                                </td>
                                                                <td width={200}>
                                                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalCredito)}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan="8"><Alert color="light">Nenhuma movimentação neste dia para este centro de custo</Alert></td>
                                            </tr>
                                        }
                                    </Fragment>
                                )
                            })
                                :
                                <Fragment key={indexConta}>
                                    <tr>
                                        <td><u>{`${conta.ctn_cd_conta}`}</u></td>
                                        <td colSpan="6">
                                            <u>{`${conta.ctn_nm_conta}`}</u>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="8"><Alert color="light">Nenhuma movimentação neste dia para esta conta</Alert></td>
                                    </tr>
                                </Fragment>
                        })}

                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

const mapStateToProps = ({ RltOperacionais }) => {
    const { loadingRltExtratoContas, rltExtratoContas } = RltOperacionais;
    return { loadingRltExtratoContas, rltExtratoContas }
}

export default connect(mapStateToProps, {

})(RltModeloCentroCusto);