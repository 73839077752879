import {
    GET_FERIADOS_ASYNC,
    GET_FERIADOS,
    STORE_FERIADO_ASYNC,
    STORE_FERIADO,
    UPDATE_FERIADO_ASYNC,
    UPDATE_FERIADO,
    DESTROY_FERIADOS_ASYNC,
    DESTROY_FERIADOS
} from "../actionTypes";

const initalState = {
    errors: {},
    feriados: null,
    loadingFeriado: false,
    loadingStoreFeriado: false,
    loadingUpdateFeriado: false
}

export default (state = initalState, action) => {

    switch (action.type) {

        case GET_FERIADOS_ASYNC:
            return { ...state, loadingFeriados: true };

        case GET_FERIADOS:
            return { ...state, ...action.payload, loadingFeriados: false };

        case STORE_FERIADO_ASYNC:
            return { ...state, loadingStoreFeriado: true };

        case STORE_FERIADO:
            return { ...state, ...action.payload, loadingStoreFeriado: false };

        case UPDATE_FERIADO_ASYNC:
            return { ...state, loadingUpdateFeriado: true };

        case UPDATE_FERIADO:
            return { ...state, ...action.payload, loadingUpdateFeriado: false };

        case DESTROY_FERIADOS_ASYNC:
            return { ...state, loadingDestroyFeriados: true };

        case DESTROY_FERIADOS:
            return { ...state, ...action.payload, loadingDestroyFeriados: false };

        default: return state;
    }

}