import {
    GET_RLT_MOVIMENTACAO_ASYNC,
    GET_RLT_MOVIMENTACAO,
    CLEAR_RLT_MOVIMENTACAO,
    GET_RLT_PLANO_CONTAS_ASYNC,
    GET_RLT_PLANO_CONTAS,
    GET_RLT_MASTER,
    GET_RLT_MASTER_ASYNC,
    GET_RLT_FLUXO_CAIXA_ASYNC,
    GET_RLT_FLUXO_CAIXA
} from "../actionTypes";

const initial_state = {
    loadingRltMovimentacao: false,
    rltMovimentacao: {
        data: [],
        totais: {
            totalDebitoGpRel1: 0,
            totalCreditoGpRel1: 0,
            totaAtivPer: 0,
            saldoAnt: 0,
            saldoAcuAtiCaixa: 0,
            totalDebitoGpRel2: 0,
            totalCreditoGpRel2: 0
        }
    },
    rltPlanoDeContas: {},
    loadingRltPlanoDeContas: false,
    rltMaster: {},
    loadingRltMaster: false,

    loadingRltFluxoCaixa: false,
    rltFluxoCaixa: {
        lancamentos: [],
        saldoAnterior: 0
    }
}

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_RLT_MOVIMENTACAO_ASYNC:
            return { ...state, loadingRltMovimentacao: true };
        case GET_RLT_MOVIMENTACAO:
            return { ...state, ...action.payload, loadingRltMovimentacao: false };
        case CLEAR_RLT_MOVIMENTACAO:
            return { ...state, rltMovimentacao: initial_state.rltMovimentacao };
        case GET_RLT_PLANO_CONTAS_ASYNC:
            return { ...state, loadingRltPlanoDeContas: true };
        case GET_RLT_PLANO_CONTAS:
            return { ...state, ...action.payload, loadingRltPlanoDeContas: false };
        case GET_RLT_MASTER_ASYNC:
            return { ...state, loadingRltMaster: true };
        case GET_RLT_MASTER:
            return { ...state, loadingRltMaster: false, ...action.payload };
        case GET_RLT_FLUXO_CAIXA_ASYNC:
            return { ...state, loadingRltFluxoCaixa: true };
        case GET_RLT_FLUXO_CAIXA:
            return { ...state, ...action.payload, loadingRltFluxoCaixa: false }

        default: return state;
    }
}