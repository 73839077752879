import {
    GET_LIST_GRUPOS_PAI_ASYNC,
    GET_LIST_CONTAS_GRUPOS_PAI
} from "../actionTypes";


const initial_state = {
    list: {
        data: [],
        total: 0
    },
    loadingList: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_LIST_GRUPOS_PAI_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_CONTAS_GRUPOS_PAI:
            return { ...state, ...action.payload, loadingList: false };
        default: return state;
    }
}
