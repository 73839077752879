import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    TabContent,
    TabPane
} from 'reactstrap';
import TabFaturas from "./TabFaturas/";
import { getListPessoas, getListContasBancos } from "../../../../redux/actions";
import { connect } from "react-redux";

function BaixarFaturas({
    isOpen,
    clickClose,
    getListPessoas,
    getListContasBancos,
    ftr_sem_baixa,
}) {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <Modal size="xxl" isOpen={isOpen} onOpened={() => {
                getListPessoas()
                getListContasBancos()
            }}
                onClosed={() => {
                    for (const key in ftr_sem_baixa) {
                        delete ftr_sem_baixa[key]
                    }
                }}
            >
                <ModalHeader toggle={() => clickClose()}>
                    Baixar Faturas
            </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs="12">

                            <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId={1}>
                                    <TabFaturas onSave={() => clickClose()} />
                                </TabPane>
                            </TabContent>
                            <Nav className="border-tab" tabs>
                                <NavItem>
                                    <NavLink href="#" className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                                        Faturas
                                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={`${activeTab === 2 ? 'active' : ''} disabled`} onClick={() => setActiveTab(2)}>
                                        Arquivo
                                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={`${activeTab === 3 ? 'active' : ''} disabled`} onClick={() => setActiveTab(3)}>
                                        Relatório
                                                </NavLink>
                                </NavItem>
                            </Nav>

                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => clickClose()}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ Faturas, Pessoas, Contas }) => {
    const { loadingFtrSemBaixa, ftr_sem_baixa } = Faturas;
    const { list: listPessoas } = Pessoas;
    const { list_bancos: listBancos } = Contas;
    return { loadingFtrSemBaixa, listPessoas, ftr_sem_baixa, listBancos }
}

export default connect(mapStateToProps, {
    getListPessoas,
    getListContasBancos
})(BaixarFaturas);

//export default BaixarFaturas;