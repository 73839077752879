import { all, put, call, fork, takeLatest } from "redux-saga/effects";
import {
    GET_RLT_MOVIMENTACAO,
    GET_RLT_MOVIMENTACAO_ASYNC,
    GET_RLT_PLANO_CONTAS_ASYNC,
    GET_RLT_PLANO_CONTAS,
    GET_RLT_MASTER_ASYNC,
    GET_RLT_MASTER,
    GET_RLT_FLUXO_CAIXA_ASYNC,
    GET_RLT_FLUXO_CAIXA
} from "../actionTypes";
import Api from "../../services/api";

function* getRltMovimentacaoSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltMovimentacao, fields);
        if (data.ok) {
            const { rltMovimentacao } = data;
            yield put({ type: GET_RLT_MOVIMENTACAO, payload: { rltMovimentacao } })
        }
    } catch (error) {
        yield put({ type: GET_RLT_MOVIMENTACAO, payload: {} })
    }
}

const apiGetRltMovimentacao = async fields => {
    const { data } = await Api.get(`/api/rltGerenciais/relatorioMovimentacao`, { params: fields });

    return data;
}

function* getRltPlanoDeContasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltPlanoDeContas, fields);
        if (data.ok) {
            const { rltPlanoDeContas } = data;
            yield put({ type: GET_RLT_PLANO_CONTAS, payload: { rltPlanoDeContas } });
        }
    } catch (error) {
        yield put({ type: GET_RLT_PLANO_CONTAS, payload: {} });
    }
}
const apiGetRltPlanoDeContas = async fields => {
    const { data } = await Api.get(`/api/rltGerenciais/relatorioPlanoDeContas`, { params: fields });

    return data;
}

function* sagaGetRltMaster({ payload }) {
    try {
        const data = yield call(apiGetRltMaster, payload);

        if (data.ok) {
            yield put({ type: GET_RLT_MASTER, payload: { rltMaster: data.data } });
        } else {
            yield put({ type: GET_RLT_MASTER, payload: {} });
        }
    } catch (err) {
        yield put({ type: GET_RLT_MASTER, payload: {} });
    }
}

const apiGetRltMaster = async params => {
    const { data } = await Api.get('/api/rltGerenciais/relatorioMaster', { params });

    return data;
}

function* getRltFluxoCaixaSaga({ payload }) {
    try {
        const { fields } = payload;

        const data = yield call(apiGetRltFluxoCaixa, fields);

        if (data.ok) {
            const { rltFluxoCaixa } = data;
            yield put({ type: GET_RLT_FLUXO_CAIXA, payload: { rltFluxoCaixa } });
        }

    } catch (error) {
        yield put({ type: GET_RLT_MASTER, payload: {} });
    }
}
const apiGetRltFluxoCaixa = async fields => {
    const { data } = await Api.get(`/api/rltGerenciais/relatorioFluxoCaixa`, { params: fields });

    return data;
}

function* watchGetRltMovimentacao() {
    yield takeLatest(GET_RLT_MOVIMENTACAO_ASYNC, getRltMovimentacaoSaga);
}
function* watchGetRltPlanoDeContas() {
    yield takeLatest(GET_RLT_PLANO_CONTAS_ASYNC, getRltPlanoDeContasSaga);
}

function* watchGetRltMaster() {
    yield takeLatest(GET_RLT_MASTER_ASYNC, sagaGetRltMaster);
}
function* watchGetRltFluxoCaixa() {
    yield takeLatest(GET_RLT_FLUXO_CAIXA_ASYNC, getRltFluxoCaixaSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetRltMovimentacao),
        fork(watchGetRltPlanoDeContas),
        fork(watchGetRltMaster),
        fork(watchGetRltFluxoCaixa)
    ]);
}