import { format, parseISO } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";

function ListConcAuto({ isOpen, clickClose, ftrsConcAuto }) {
    return (
        <Modal size="lg" isOpen={isOpen}>
            <ModalHeader toggle={() => clickClose()}>
                Faturas Concilidas Automaticamente
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs="12">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Extrato</th>
                                    <th>Fatura</th>
                                    <th>Data</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ftrsConcAuto.length ?
                                    ftrsConcAuto.map((ftr, key) => {
                                        return (
                                            <tr key={key}>
                                                    <td>{ftr.extrato.documento}</td>
                                                    <td>{ftr.ftr_nu_fatura}</td>
                                                    <td>{format(parseISO(ftr.ftr_dt_vencimento), 'dd/MM/yyyy')}</td>
                                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(ftr.ftr_vl_fatura)}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="4">
                                            <Alert color="light">Nenhuma fatura foi conciliada</Alert>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ Faturas }) => {
    const { ftrsConcAuto } = Faturas;
    return { ftrsConcAuto }
}

export default connect(mapStateToProps)(ListConcAuto);