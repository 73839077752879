import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { getProdutos } from "../../../../redux/actions";
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, CardTitle } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from "react-redux";
import Table from "./Table";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";

const Produtos = ({ getProdutos, loadingProdutos, produtos }) => {
    useEffect(() => {
        getProdutos();
    }, []);

    const [openModal, setOpenModal] = useState(null);
    const [selectedProduto, setSelectedProduto] = useState(null);

    const [filter, setFilter] = useState({ prd_nm_produto: null, prd_nm_sigla: null, prd_st_ativo: null });
    const curFilter = useRef(null);


    const handleChange = e => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value || null })
    }

    const submitFilter = e => {
        e.preventDefault();
        getProdutos({ fields: filter });
        curFilter.current = filter;
    }
    return (
        <Fragment>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === 'edit'} selectedProduto={selectedProduto} clickClose={() => setOpenModal(null)} />
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} produto={!!produtos && produtos.data[selectedProduto]} />
            <Breadcrumb parent="Estoque" title="Produtos" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                sid="produtos.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => setOpenModal('add')}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>

                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    name="prd_nm_produto"
                                                    onChange={handleChange}
                                                    placeholder="Informe o Nome"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Label>Sigla</Label>
                                                <Input
                                                    name="prd_nm_sigla"
                                                    placeholder="Informe a Sigla"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" xs="12">
                                            <FormGroup>
                                                <Label>Situação</Label>
                                                <Typeahead
                                                    id="selectFillSitu"
                                                    labelKey="name"
                                                    options={[{ name: 'Ativo', value: 'S' }, { name: 'Inativo', value: 'N' }]}
                                                    clearButton
                                                    placeholder="Informe a Sitação"
                                                    onChange={selected => {
                                                        setFilter({ ...filter, prd_st_ativo: !!selected.length ? selected[0].value : null })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    disabled={loadingProdutos}
                                                    title="Pesquisar"
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-t-20">
                                <Table
                                    curFilter={curFilter}
                                    setOpenModal={setOpenModal}
                                    setSelectedProduto={setSelectedProduto}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Produtos }) => {
    const { loadingProdutos, produtos } = Produtos;
    return { loadingProdutos, produtos };
}

export default connect(mapStateToProps, {
    getProdutos
})(Produtos);