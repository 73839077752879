import {
    GET_FERIADOS_ASYNC,
    STORE_FERIADO_ASYNC,
    UPDATE_FERIADO_ASYNC,
    DESTROY_FERIADOS_ASYNC
} from "../actionTypes";

export const getFeriados = (payload = {}) => ({
    type: GET_FERIADOS_ASYNC,
    payload
});

export const storeFeriados = payload => ({
    type: STORE_FERIADO_ASYNC,
    payload
});

export const updateFeriados = payload => ({
    type: UPDATE_FERIADO_ASYNC,
    payload
});

export const destroyFeriados = payload => ({
    type: DESTROY_FERIADOS_ASYNC,
    payload
});