import React, {  } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Conta from "./Conta";
import DadosGerais from "./DadosGerais";

const View = ({ isOpen, clickClose, contrapartida }) => {
    return (
        <Modal
            isOpen={isOpen}
            size="lg"
        >
            <ModalHeader toggle={() => clickClose()}>Informações Contrapartida</ModalHeader>
            <ModalBody>
                {!!contrapartida &&
                    <Row>
                        <Conta
                            contrapartida={contrapartida}
                        />
                        <DadosGerais
                            contrapartida={contrapartida}
                        />
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {

    return {}
}

export default connect(mapStateToProps, {})(View)