import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Button,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Alert
} from 'reactstrap';
import { connect } from "react-redux";
import ScrollArea from 'react-scrollbar';
import { listModulos, listGroups, storeFuncionalidade } from "../../../../redux/actions";
const initalState = { name: null, modulo_id: null, permissions: [], groups: [] };
const initalStateSearch = { permissao: "", group: "" };

const AddFuncionalides = (
    {
        isOpen,
        clickClose,
        permissions,
        loadingPermissions,
        listModulos,
        modulos,
        loadingModulos,
        listGroups,
        groups,
        loadingGroups,
        onSave,
        storeFuncionalidade,
        loadingStoreFunc
    }
) => {
    //STATES
    const [fields, setFields] = useState(initalState);
    const [search, setSearch] = useState(initalStateSearch);

    //VARIÁVEIS
    const filterPermissions = !!permissions && !!permissions.data ?
        permissions.data.filter(({ name }) => new RegExp(`${search.permissao}`, 'i').test(name)) : [];
    const filterGroups = groups.filter(({ name }) => new RegExp(`${search.group}`, 'i').test(name));

    //FUNÇÕES
    const handleSearch = ({ target }) => {
        const { name, value } = target;
        setSearch({ ...search, [name]: value });
    }
    const submitForm = e => {
        e.preventDefault();
        storeFuncionalidade({ fields, onSave });

    }
    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onClosed={() => {
                setFields(initalState);
                setSearch(initalStateSearch);
            }}
            onOpened={() => {
                listModulos();
                listGroups();
            }}>
            <ModalHeader toggle={() => !loadingStoreFunc && clickClose()}>
                Adicionar Funcionalidade
                </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label for="inputName">Nome</Label>
                                <Input
                                    type="text"
                                    id="inputName"
                                    placeholder="Informe o nome"

                                    onChange={({ target: { value } }) => setFields({ ...fields, name: value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Módulo</Label>
                                <Typeahead
                                    id="selectMod"
                                    labelKey="name"
                                    emptyLabel="Nenhum Módulo Cadastrado"
                                    placeholder="Informe o Módulo"
                                    options={modulos.map(({ name, id }) => ({ name, id }))}
                                    isLoading={loadingModulos}
                                    onChange={(modulo) => {
                                        !!modulo.length ?
                                            setFields({ ...fields, modulo_id: modulo[0].id })
                                            :
                                            setFields({ ...fields, modulo_id: null })
                                    }}
                                    disabled={loadingModulos}

                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Permissões</Label>
                                <Row>
                                    <Col xs="6">
                                        <Input
                                            placeholder="Pesquisar Permissões"
                                            autoComplete="off"
                                            name="permissao"
                                            onChange={handleSearch}
                                            className="mb-2" />
                                        {loadingPermissions ?
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                            :
                                            !!filterPermissions.length ?
                                                <ScrollArea vertical={true} horizontal={false}>
                                                    <ListGroup>
                                                        {
                                                            filterPermissions.map(({ name, id }, key) => {
                                                                return (
                                                                    <ListGroupItem
                                                                        key={key}
                                                                        className="list-group-item-action"
                                                                        onClick={() => {
                                                                            const permissions = [...fields.permissions];
                                                                            const index = permissions.findIndex((el) => el.id === id);
                                                                            if (index !== -1) {
                                                                                permissions.splice(index, 1);
                                                                            } else {
                                                                                permissions.push({ id, name });
                                                                            }
                                                                            setFields({ ...fields, permissions })
                                                                        }}

                                                                        style={{ cursor: 'pointer' }}>
                                                                        <Input
                                                                            className="checkbox_animated"
                                                                            checked={!!fields.permissions.filter((el) => el.id === id).length}
                                                                            onChange={() => { }}
                                                                            id={`chk-ani${key}`}
                                                                            type="checkbox" />
                                                                        {name}
                                                                    </ListGroupItem>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                </ScrollArea >
                                                :
                                                <Alert color="light">Nenhuma Permissão foi Encontrada</Alert>
                                        }
                                    </Col>
                                    <Col xs="6">
                                        <div
                                            className="mb-2"
                                            style={{
                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem'
                                            }}>
                                            <span className="pull-right">{`Selecionados ${fields.permissions.length}`}</span>
                                        </div>
                                        {fields.permissions.length ?
                                            <ScrollArea vertical={true} horizontal={false}>
                                                <ListGroup>
                                                    {
                                                        fields.permissions.map(({ name, id }, key) => {
                                                            return (
                                                                <ListGroupItem
                                                                    key={key}
                                                                    className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                    onClick={() => {
                                                                        const permissions = [...fields.permissions];
                                                                        permissions.splice(key, 1);
                                                                        setFields({ ...fields, permissions })
                                                                    }}
                                                                    active={fields.permissions.includes(id)}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    {name}
                                                                    <span>X</span>
                                                                </ListGroupItem>
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                            </ScrollArea >
                                            :
                                            <Alert color="light">Nenhuma Permissão foi Selecionada</Alert>
                                        }
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Perfis</Label>
                                <Row>
                                    <Col xs="6">
                                        <Input
                                            placeholder="Pesquisar Perfis"
                                            autoComplete="off"
                                            name="group"
                                            onChange={handleSearch}
                                            className="mb-2" />
                                        {loadingGroups ?
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                            :
                                            !!filterGroups.length ?
                                                <ScrollArea vertical={true} horizontal={false}>
                                                    <ListGroup>
                                                        {filterGroups.map(({ name, id }, key) => {
                                                            return (
                                                                <ListGroupItem
                                                                    key={key}
                                                                    className="list-group-item-action"
                                                                    onClick={() => {
                                                                        const groups = [...fields.groups];
                                                                        const index = groups.findIndex((el) => el.id === id);
                                                                        if (index !== -1) {
                                                                            groups.splice(index, 1);
                                                                        } else {
                                                                            groups.push({ id, name });
                                                                        }
                                                                        setFields({ ...fields, groups })
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        checked={!!fields.groups.filter((el) => el.id === id).length}
                                                                        onChange={() => { }}
                                                                        id={`chk-ani${key}`}
                                                                        type="checkbox" />
                                                                    {name}
                                                                </ListGroupItem>
                                                            );
                                                        })}
                                                    </ListGroup>
                                                </ScrollArea>
                                                :
                                                <Alert color="light">Nenhum Grupo foi Encontrado</Alert>
                                        }
                                    </Col>
                                    <Col xs="6">
                                        <div
                                            className="mb-2"
                                            style={{
                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem'
                                            }}>
                                            <span className="pull-right">{`Selecionados ${fields.groups.length}`}</span>
                                        </div>
                                        {!!fields.groups.length ?
                                            <ScrollArea vertical={true} horizontal={false}>
                                                <ListGroup>
                                                    {fields.groups.map(({ name, id }, key) => {
                                                        return (
                                                            <ListGroupItem
                                                                key={key}
                                                                className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                onClick={() => {
                                                                    const groups = [...fields.groups];
                                                                    groups.splice(key, 1);
                                                                    setFields({ ...fields, groups });
                                                                }}
                                                                style={{ cursor: 'pointer' }}>
                                                                {name}
                                                                <span>X</span>
                                                            </ListGroupItem>
                                                        );
                                                    })}
                                                </ListGroup>
                                            </ScrollArea>
                                            :
                                            <Alert color="light">Nenhum Grupo foi Selecionado</Alert>
                                        }
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" disabled={loadingStoreFunc} type="button" onClick={() => clickClose()}>Fechar</Button>
                    <Button color="primary" disabled={loadingStoreFunc} type="submit">{loadingStoreFunc ? 'Processando..' : 'Adicionar'}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Permissions, Modulos, Groups, Funcionalidades }) => {
    const { permissions, loading: loadingPermissions } = Permissions;
    const { list: modulos, loadingList: loadingModulos } = Modulos;
    const { loadingStoreFunc } = Funcionalidades;
    const { list: groups, loadingList: loadingGroups } = Groups;
    return { permissions, modulos, loadingModulos, loadingPermissions, groups, loadingGroups, loadingStoreFunc };
}
export default connect(mapStateToProps, {
    listModulos,
    listGroups,
    storeFuncionalidade
})(AddFuncionalides)