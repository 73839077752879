import React from "react";
import { connect } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";

function SecondCard({
    handleChange,
    isInvalid,
    validator,
    fields,
    errors
}) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12" className="d-flex justify-content-center">
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-mr">
                            <Label>Situação Documento*:</Label>
                            <div className="radio radio-primary m-l-10 m-r-20">
                                <Input
                                    id="radioinline1"
                                    name="tps_st_documento"
                                    value="1"
                                    type="radio"
                                    onChange={handleChange}
                                />
                                <Label className="mb-0" for="radioinline1">Sim</Label>
                            </div>
                            <div className="radio radio-primary m-l-20 m-r-20">
                                <Input
                                    id="radioinline2"
                                    name="tps_st_documento"
                                    value="0"
                                    type="radio"
                                    onChange={handleChange}
                                />
                                <Label className="mb-0" for="radioinline2">Não</Label>
                            </div>
                            <FormFeedback style={{ display: isInvalid('tps_st_documento') ? 'block' : 'none' }}>
                                {validator.message('tps_st_documento', fields.tps_st_documento, 'required')}
                                {(!!errors.tps_st_documento) && errors.tps_st_documento.map((erro, index) => <span key={index}>{erro}</span>)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12" className="d-flex justify-content-center">
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-mr">
                            <Label>Situação Importado*:</Label>
                            <div className="radio radio-primary m-l-10 m-r-20">
                                <Input
                                    id="radioinline3"
                                    name="tps_st_importado"
                                    value="1"
                                    type="radio"
                                    onChange={handleChange}
                                />
                                <Label className="mb-0" for="radioinline3">Sim</Label>
                            </div>
                            <div className="radio radio-primary m-l-20 m-r-20">
                                <Input
                                    id="radioinline4"
                                    name="tps_st_importado"
                                    value="0"
                                    type="radio"
                                    onChange={handleChange}
                                />
                                <Label className="mb-0" for="radioinline4">Não</Label>
                            </div>
                            <FormFeedback style={{ display: isInvalid('tps_st_importado') ? 'block' : 'none' }}>
                                {validator.message('tps_st_importado', fields.tps_st_importado, 'required')}
                                {(!!errors.tps_st_importado) && errors.tps_st_importado.map((erro, index) => <span key={index}>{erro}</span>)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(SecondCard)