import { toast } from 'react-toastify';
import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import Api from "../../services/api";
import {
    LOGIN_USER,
    LOGIN_USER_ASYNC,
    LOGOUT_USER_ASYNC,
    LOGOUT_USER,
    SWITCH_USER_EMPRESA_ASYNC,
    SWITCH_USER_EMPRESA
} from '../actionTypes';

function* loginUserSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiLoginUser, fields);
        if (data.ok) {
            const { token, user, user_empresas, active_user_empresa } = data;
            yield put({ type: LOGIN_USER, payload: { token, user, user_empresas, active_user_empresa } });
            window.location.replace('/profile');
        }
    } catch (error) {
        yield put({ type: LOGIN_USER, payload: {} })
    }
}
const apiLoginUser = async fields => {
    const { data } = await Api.post(`/api/auth/login`, { ...fields });

    return data;
}

function* logoutUserSaga() {
    try {
        const data = yield call(apiLogoutUser);
        if (data.ok) {
            localStorage.removeItem('persist:root');
            window.location.replace('/login');
        }
    } catch (error) {

    }
}
const apiLogoutUser = async () => {
    const { data } = await Api.post(`/api/auth/logout`);

    return data;
}

function* switchUserEmpresaSaga({ payload }) {
    try {
        const { fields, onChange } = payload;
        const data = yield call(apiSwitchUserEmpresa, fields);
        if (data.ok) {
            const { active_user_empresa } = data;
            yield put({ type: SWITCH_USER_EMPRESA, payload: { active_user_empresa } });

            onChange();
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
        }
    } catch (error) {
        yield put({ type: SWITCH_USER_EMPRESA, payload: {} });
    }
}
const apiSwitchUserEmpresa = async fields => {
    const { data } = await Api.post(`/api/auth/switch_user_empresa`, { ...fields });

    return data;
}

function* watchLoginUser() {
    yield takeLatest(LOGIN_USER_ASYNC, loginUserSaga);
}
function* watchLogoutUser() {
    yield takeLatest(LOGOUT_USER_ASYNC, logoutUserSaga);
}
function* watchSwitchUserEmpresa() {
    yield takeLatest(SWITCH_USER_EMPRESA_ASYNC, switchUserEmpresaSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchSwitchUserEmpresa)
    ]);
}