import React, { useMemo } from "react";
import { Col, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { Typeahead } from "react-bootstrap-typeahead";
import {
    getListEmpresas
} from "../../../../redux/actions";
const DadosGerais = ({
    validator,
    fields,
    handleChange,
    handleChangeSelect,
    validFields,
    errors,
    loadingListTipoPessoas,
    listTipoPessoas,
}) => {
    const optionsTipo = useMemo(() =>
        listTipoPessoas.map((el) => ({ name: el.tps_nm_tipo, value: el.tps_cd_tipo })),
        [listTipoPessoas]);

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Dados Gerais</h6></Label>
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Nome*</Label>
                                <Input
                                    name="pss_nm_pessoa"
                                    placeholder="Informe o nome"
                                    maxLength={60}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    onChange={handleChange}
                                    invalid={
                                        validator.visibleFields.includes('pss_nm_pessoa') ||
                                        !!errors.pss_nm_pessoa ||
                                        (!validator.fields.pss_nm_pessoa && validator.messagesShown)
                                    }
                                />
                                <FormFeedback>
                                    {validator.message('pss_nm_pessoa', fields.pss_nm_pessoa, 'required')}
                                    {(!!errors.pss_nm_pessoa) && errors.pss_nm_pessoa.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Número Identificação*</Label>
                                <Input
                                    name="pss_nu_identificacao"
                                    placeholder="Informe o Número de Identificação"
                                    maskChar={""}
                                    tag={InputMask}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('pss_nu_identificacao') ||
                                        !!errors.pss_nu_identificacao ||
                                        (!validator.fields.pss_nu_identificacao && validator.messagesShown)
                                    }
                                    mask={'99999999999999'}
                                    value={fields.pss_nu_identificacao}
                                    onChange={handleChange}
                                />
                                <FormFeedback>
                                    {validator.message('pss_nu_identificacao', fields.pss_nu_identificacao, 'required')}
                                    {(!!errors.pss_nu_identificacao) && errors.pss_nu_identificacao.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Tipo*</Label>
                                <Typeahead
                                    id="selectTipo"
                                    labelKey="name"
                                    options={optionsTipo}
                                    isLoading={loadingListTipoPessoas}
                                    disabled={loadingListTipoPessoas}
                                    clearButton
                                    isInvalid={
                                        validator.visibleFields.includes('pss_cd_tipo') ||
                                        !!errors.pss_cd_tipo ||
                                        (!validator.fields.pss_cd_tipo && validator.messagesShown)

                                    }
                                    emptyLabel="Nenhum resultado encontrado"
                                    onBlur={() => validFields('pss_cd_tipo')}
                                    placeholder="Selecione o Tipo"
                                    onChange={(selected) => handleChangeSelect('pss_cd_tipo', selected)}
                                    paginationText={<span className="d-flex justify-content-center">Carregar Mais</span>}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_cd_tipo') ||
                                            !!errors.pss_cd_tipo ||
                                            (!validator.fields.pss_cd_tipo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_cd_tipo', fields.pss_cd_tipo, 'required')}
                                    {(!!errors.pss_cd_tipo) && errors.pss_cd_tipo.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Conta Contábil*</Label>
                                <Input
                                    name="pss_cd_conta_contabil"
                                    maxLength={15}
                                    placeholder="Informe a Conta Contábil"
                                    value={fields.pss_cd_conta_contabil}
                                    onChange={handleChange}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('pss_cd_conta_contabil') ||
                                        !!errors.pss_cd_conta_contabil ||
                                        (!validator.fields.pss_cd_conta_contabil && validator.messagesShown)
                                    }
                                />
                                <FormFeedback>
                                    {validator.message('pss_cd_conta_contabil', fields.pss_cd_conta_contabil, `required|max:15`)}
                                    {(!!errors.pss_cd_conta_contabil) && errors.pss_cd_conta_contabil.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ TiposPessoas }) => {
    const { list: listTipoPessoas, loadingList: loadingListTipoPessoas } = TiposPessoas;
    return { listTipoPessoas, loadingListTipoPessoas }
}

export default connect(mapStateToProps, {
    getListEmpresas
})(DadosGerais);