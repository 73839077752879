import React, { useMemo, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import SelectPagination from "../../../../../components/SelectPagination";
import { getListContasEmpresa } from "../../../../../redux/actions";
import { connect } from "react-redux";

const labelsContas = [
    {
        label: 'Fatura a Pagar',
        name: 'emp_cd_cnt_fat_pag',
        relationName: 'cnt_fatura_paga'
    },
    {
        label: 'Fatura não Paga',
        name: 'emp_cd_cnt_fat_nao_pag',
        relationName: 'cnt_fatura_nao_paga'
    },
    {
        label: 'Provisão de Receita',
        name: 'emp_cd_ctn_prov_rec',
        relationName: 'cnt_prov_receita'
    },
    {
        label: 'Provisão de Despesa',
        name: 'emp_cd_ctn_prov_des',
        relationName: 'cnt_prov_despesa'
    },
    {
        label: 'Fatura a Receber',
        name: 'emp_cd_ctn_fat_rec',
        relationName: 'cnt_fatura_rec'
    },
    {
        label: 'Fatura não Recebida',
        name: 'emp_cd_ctn_fat_nao_rec',
        relationName: 'cnt_fatura_nao_rec'
    },
    {
        label: 'Cheque a Receber',
        name: 'emp_cd_ctn_chq_rec',
        relationName: 'cnt_cheque_rec'
    },
    {
        label: 'Cheque não Recebido',
        name: 'emp_cd_ctn_chq_nao_rec',
        relationName: 'cnt_cheque_nao_rec'
    },
    {
        label: 'Juros Pagos',
        name: 'emp_cd_ctn_juros_pag',
        relationName: 'cnt_juros_pag'
    },
    {
        label: 'Juros Recebidos',
        name: 'emp_cd_ctn_juros_rec',
        relationName: 'cnt_juros_rec'
    },
    {
        label: 'Juros Anistiados',
        name: 'emp_cd_ctn_juros_ani',
        relationName: 'cnt_juros_ani'
    },
    {
        label: 'Cheque Devolvido',
        name: 'emp_cd_ctn_chq_dev',
        relationName: 'cnt_cheque_dev'
    },
    {
        label: 'Receita Antecipada',
        name: 'emp_cd_ctn_rec_antec',
        relationName: 'cnt_rec_antec'
    },
    {
        label: 'Caixa',
        name: 'emp_cd_ctn_caixa',
        relationName: 'cnt_caixa'
    },
    {
        label: 'Descontos Obtidos',
        name: 'emp_cd_ctn_desc_obtidos',
        relationName: 'cnt_desc_obitidos'
    },
    {
        label: 'Descontos Concedidos',
        name: 'emp_cd_ctn_desc_concedidos',
        relationName: 'cnt_desc_concedidos'
    },
    {
        label: 'Fretes/Carretos',
        name: 'emp_cd_ctn_frete',
        relationName: 'cnt_frete'
    },
    {
        label: 'Receita com Frete',
        name: 'emp_cd_ctn_rec_frete',
        relationName: 'cnt_rec_frete'
    },
    {
        label: 'Integração Sistemas',
        name: 'emp_cd_ctn_int_vendas',
        relationName: 'cnt_int_vendas'
    }
];

function Contas({
    listContasEmpresa,
    loadingListContasEmp,
    getListContasEmpresa,
    handleChangeSelect,
    fields
}) {

    const [indexFocusCtn, setIndexFocusCtn] = useState(null);

    const optionsContas = useMemo(() => {
        const res = listContasEmpresa.data.map((conta) =>
            ({ name: `${conta.ctn_cd_conta} - ${conta.ctn_nm_conta}`, value: conta.ctn_cd_conta })
        );

        labelsContas.forEach(({ name, relationName }) => {

            if (!!fields[name] && !!fields[relationName] && !res.find((conta) => conta.value === fields[name])) {
                const conta = fields[relationName];
                res.push({
                    name: `${conta.ctn_cd_conta} - ${conta.ctn_nm_conta}`,
                    value: conta.ctn_cd_conta
                });
            }
        });
        return res;
    }, [listContasEmpresa.data]);

    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Contas</h6></Label>
                    <Row>
                        {labelsContas.map(({ label, name }, index) => {
                            return (
                                <Col md="6" sm="12" key={index}>
                                    <FormGroup>
                                        <Label>{label}</Label>
                                        <SelectPagination
                                            id={`selectConta${index}`}
                                            clearButton
                                            labelKey="name"
                                            onFocus={() => {
                                                setIndexFocusCtn(index);
                                            }}
                                            selected={options => {

                                                return options.filter(option => option.value === fields[name])
                                            }}
                                            placeholder={`Informe a Conta de ${label}`}
                                            maxResults={50}
                                            isLoading={loadingListContasEmp && (indexFocusCtn === index || indexFocusCtn === null)}
                                            totalResults={listContasEmpresa.total}
                                            onChange={selected => handleChangeSelect(name, selected)}
                                            data={optionsContas}
                                            reduxAction={({ fields: params }) => {
                                                getListContasEmpresa({ fields: { ...params, empresaCod: fields.emp_cd_empresa } })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </FormGroup>
        </>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { listContasEmpresa, loadingListContasEmp } = Contas;
    return { listContasEmpresa, loadingListContasEmp }
}

export default connect(mapStateToProps, {
    getListContasEmpresa
})(Contas);