
//Profile
import Profile from "../pages/profile";

//Gestão
import ControleAcesso from "../pages/gestao/controleAcesso";
import Usuarios from "../pages/gestao/usuarios";

//Tabelas
import Empresas from "../pages/tabelas/empresa/cadastros";
import TipoEmpresas from "../pages/tabelas/empresa/tipos";
import CustoProduto from "../pages/tabelas/estoque/custoProduto";
import TipoMovimentacao from "../pages/tabelas/estoque/tipoMovimento";
import Produtos from "../pages/tabelas/estoque/produtos";
import NotasFiscais from "../pages/tabelas/estoque/notasFiscais";
import Feriados from "../pages/tabelas/feriados";
import CentroCusto from "../pages/tabelas/centroCusto";
import TipoPessoa from "../pages/tabelas/tipoPessoas";

//Cadastros
import PlanoContas from "../pages/cadastros/planoContas/Contas";
import PlanoContasGrupos from "../pages/cadastros/planoContas/Grupos";
import Pessoas from "../pages/cadastros/pessoas"

//Relatórios
import RelGerencialPlanoContas from "../pages/relatorios/gerencial/planoContas";
import RelOperacionalExtratoConta from "../pages/relatorios/operacional/extratoConta";
import RelOperacionalLancamentos from "../pages/relatorios/operacional/lancamentos";
import RelExtratoPessoa from "../pages/relatorios/operacional/extratoPessoas";
import RelGerencialMovimentacao from "../pages/relatorios/gerencial/movimentacao";
import RelMaster from '../pages/relatorios/gerencial/master';
import NaoRecebidos from "../pages/relatorios/operacional/naoRecebidos";
import NaoPagos from "../pages/relatorios/operacional/naoPagos"
import FluxoCaixa from "../pages/relatorios/gerencial/fluxoCaixa";
import RelPesquisa from "../pages/relatorios/operacional/pesquisa";
import RelCredDeb from "../pages/relatorios/operacional/credDeb";
import RelContraPartidas from "../pages/relatorios/operacional/contrapartidas";
import TitulosNaoRecebidos from "../pages/relatorios/operacional/titulosNaoRecebidos";


//Operação
import Lancamentos from "../pages/operacao/lancamentos";
import ConcBancaria from "../pages/operacao/concBancaria";

export const routes = [
        { path: `${process.env.PUBLIC_URL}/profile`, Component: Profile },

        //GESTÃO
        { path: `${process.env.PUBLIC_URL}/gestao/controle_acesso`, Component: ControleAcesso },
        { path: `${process.env.PUBLIC_URL}/gestao/usuarios`, Component: Usuarios },

        //TABELAS
        { path: `${process.env.PUBLIC_URL}/tabelas/empresas`, Component: Empresas },
        { path: `${process.env.PUBLIC_URL}/tabelas/tipos_empresa`, Component: TipoEmpresas },
        { path: `${process.env.PUBLIC_URL}/tabelas/estoque/custo_produto`, Component: CustoProduto },
        { path: `${process.env.PUBLIC_URL}/tabelas/estoque/tipo_movimentacao`, Component: TipoMovimentacao },
        { path: `${process.env.PUBLIC_URL}/tabelas/estoque/produtos`, Component: Produtos },
        { path: `${process.env.PUBLIC_URL}/tabelas/estoque/notas_fiscais`, Component: NotasFiscais },
        { path: `${process.env.PUBLIC_URL}/tabelas/feriados`, Component: Feriados },
        { path: `${process.env.PUBLIC_URL}/tabelas/centro_custo`, Component: CentroCusto },
        { path: `${process.env.PUBLIC_URL}/tabelas/tipo_pessoa`, Component: TipoPessoa },


        //CADASTROS
        { path: `${process.env.PUBLIC_URL}/cadastros/plano_contas/contas`, Component: PlanoContas },
        { path: `${process.env.PUBLIC_URL}/cadastros/plano_contas/grupos`, Component: PlanoContasGrupos },
        { path: `${process.env.PUBLIC_URL}/cadastros/pessoas`, Component: Pessoas },

        //RELATÓRIOS GERENCIAIS
        { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/plano_contas`, Component: RelGerencialPlanoContas },
        { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/movimentacao`, Component: RelGerencialMovimentacao },
        { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/master`, Component: RelMaster },
        { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/fluxo_caixa`, Component: FluxoCaixa },

        //RELATÓRIOS OPERACIONAIS
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/extrato_conta`, Component: RelOperacionalExtratoConta },
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/lancamentos`, Component: RelOperacionalLancamentos },
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/extrato_pessoa`, Component: RelExtratoPessoa },
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/nao_recebidos`, Component: NaoRecebidos },
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/nao_pagos`, Component: NaoPagos },
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/pesquisa`, Component: RelPesquisa },
        { path: `${process.env.PUBLIC_URL}/relatorios/operacional/cred_deb`, Component: RelCredDeb },
        { path: `${process.env.PUBLIC_URL}/relatorio/operacional/contrapartidas`, Component: RelContraPartidas },
        { path: `${process.env.PUBLIC_URL}/relatorio/operacional/titulosNaoRecebidos`, Component: TitulosNaoRecebidos },

        //OPERAÇÃO
        { path: `${process.env.PUBLIC_URL}/operacao/lancamentos`, Component: Lancamentos },
        { path: `${process.env.PUBLIC_URL}/operacao/conc_bancaria`, Component: ConcBancaria },
]