import {
    GET_RLT_EXTRATO_CONTAS_ASYNC,
    GET_RLT_EXTRATO_CONTAS,
    CLEAR_RLT_EXTRATO_CONTAS,
    GET_RLT_EXTRATO_PESSOAS_ASYNC,
    GET_RLT_EXTRATO_PESSOAS,
    GET_RTL_NAO_RECEBIDOS_ASYNC,
    GET_RTL_NAO_RECEBIDOS,
    GET_RLT_NAO_PAGOS_ASYNC,
    GET_RLT_NAO_PAGOS,
    GET_RLT_PESQUISA_ASYNC,
    GET_RLT_PESQUISA,
    CLEAR_RLT_PESQUISA,
    GET_RLT_DEB_CRED,
    GET_RLT_DEB_CRED_ASYNC,
    GET_RLT_CNT_ASYNC,
    GET_RLT_CNT,
    GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC,
    GET_RLT_TITULOS_NAO_RECPAGOS,
    CLEAR_RLT_TITULOS_NAO_RECPAGOS,
    CLEAR_RLT_NA0_PAGOS
} from "../actionTypes";

const initial_state = {
    rltExtratoContas: [],
    loadingRltExtratoContas: false,

    rltExtratoPessoas: [],
    loadingRltExtratoPessoas: false,

    rltNaoRecebidos: [],
    loadingRltNaoRecebidos: false,

    rltNaoPagos: [],
    loadingNaoPagos: false,

    rltPesquisa: [],
    loadingRltPesquisa: false,

    rltDebCred: [],
    loadingRltDebCred: false,

    rltCnt: [],
    loadingRltCnt: false,

    rltTitulosNaoRecPagos: [],
    loadingRltTitulosNaoRecPagos: false
}

export default (state = initial_state, action) => {
    switch (action.type) {
        //EXTRATO CONTAS 
        case GET_RLT_EXTRATO_CONTAS_ASYNC:
            return { ...state, loadingRltExtratoContas: true };
        case GET_RLT_EXTRATO_CONTAS:
            return { ...state, ...action.payload, loadingRltExtratoContas: false };
        case CLEAR_RLT_EXTRATO_CONTAS:
            return { ...state, rltExtratoContas: [] };

        //EXTRADO PESSOAS
        case GET_RLT_EXTRATO_PESSOAS_ASYNC:
            return { ...state, loadingRltExtratoPessoas: true }
        case GET_RLT_EXTRATO_PESSOAS:
            return { ...state, rltExtratoPessoas: action.payload, loadingRltExtratoPessoas: false };

        //NÃO RECEBIDOS
        case GET_RTL_NAO_RECEBIDOS_ASYNC:
            return { ...state, loadingRltNaoRecebidos: true };
        case GET_RTL_NAO_RECEBIDOS:
            return { ...state, ...action.payload, loadingRltNaoRecebidos: false };

        //NÃO PAGOS
        case GET_RLT_NAO_PAGOS_ASYNC:
            return { ...state, loadingNaoPagos: true };
        case GET_RLT_NAO_PAGOS:
            return { ...state, ...action.payload, loadingNaoPagos: false };
        case CLEAR_RLT_NA0_PAGOS:
            return { ...state, rltNaoPagos: [] };

        //PESQUISA
        case GET_RLT_PESQUISA_ASYNC:
            return { ...state, loadingRltPesquisa: true };
        case GET_RLT_PESQUISA:
            return { ...state, ...action.payload, loadingRltPesquisa: false }
        case CLEAR_RLT_PESQUISA:
            return { ...state, rltPesquisa: [], loadingRltPesquisa: false }

        //DÉBITO E CRÉDITO
        case GET_RLT_DEB_CRED_ASYNC:
            return { ...state, loadingRltDebCred: true };
        case GET_RLT_DEB_CRED:
            return { ...state, ...action.payload, loadingRltDebCred: false };

        //CONTRAPARTIDAS
        case GET_RLT_CNT_ASYNC:
            return { ...state, loadingRltCnt: true };
        case GET_RLT_CNT:
            return { ...state, ...action.payload, loadingRltCnt: false };

        //TITULOS NÃO PAGOS / TITULOS NÃO RECEBIDOS
        case GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC:
            return { ...state, loadingRltTitulosNaoRecPagos: true };
        case GET_RLT_TITULOS_NAO_RECPAGOS:
            return { ...state, ...action.payload, loadingRltTitulosNaoRecPagos: false };
        case CLEAR_RLT_TITULOS_NAO_RECPAGOS:
            return { ...state, rltTitulosNaoRecPagos: [] }

        default: return state;

    }
}