import { format, parseISO } from "date-fns";
import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function SecondCard({ notaFiscal }) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Vencimento</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.data_ven ? format(parseISO(notaFiscal.data_ven), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Saida/Entrada</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.dhSaiEnt ? format(parseISO(notaFiscal.dhSaiEnt), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Devolução</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.nfe_devoucao ? format(parseISO(notaFiscal.nfe_devoucao), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Emissão da Devolução</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.dat_emi_devolucao ? format(parseISO(notaFiscal.dat_emi_devolucao), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default SecondCard;