import { call, put, takeLatest, fork, all, select } from 'redux-saga/effects'
import Api from "../../services/api";
import {
    REGISTER_USER,
    REGISTER_USER_ASYNC,
    GET_USERS_ASYNC,
    GET_USERS,
    CHANGE_STATUS_USER_ASYNC,
    CHANGE_STATUS_USER,
    DESTROY_USERS_ASYNC,
    DESTROY_USERS,
    STORE_USERS,
    STORE_USERS_ASYNC,
    UPDATE_USERS_ASYNC,
    UPDATE_USERS
} from '../actionTypes';
import { toast } from 'react-toastify';
import { selectorUsers } from "../selectors";
function* registerUserSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiRegisterUser, fields);
        if (!!data && data.ok) {
            yield put({ type: REGISTER_USER, payload: {} });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });

            onCreate();
        }
        if (!!errors) {
            yield put({ type: REGISTER_USER, payload: { errors } });
        }
    } catch (error) {

        yield put({ type: REGISTER_USER, payload: {} })
    }
}
const apiRegisterUser = async fields => {
    const data = await Api.post(`/api/users/register`, { ...fields });

    return data;
}

function* getUsersSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetUsers, fields);
        if (data.ok) {
            const { users } = data;
            yield put({ type: GET_USERS, payload: { users } });
        }
    } catch (error) {
        yield put({ type: GET_USERS, payload: {} });
    }
}
const apiGetUsers = async fields => {
    const { data } = await Api.get(`/api/users`, { params: { ...fields } });

    return data;
}

function* changeStatusUserSaga({ payload }) {
    try {
        const { index } = payload;
        const users = yield select(selectorUsers);
        const { id } = users.data[index];
        const data = yield call(apiChangeStatusUser, id);
        if (data.ok) {
            const { active } = data;
            users.data[index].active = active;
            yield put({ type: CHANGE_STATUS_USER, payload: { users } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    } catch (error) {
        yield put({ type: CHANGE_STATUS_USER, payload: {} });
    }
}
const apiChangeStatusUser = async id => {
    const { data } = await Api.put(`/api/users/change_status/${id}`);

    return data;
}

function* destroyUsersSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyUsers, fields);
        if (data.ok) {
            const { users } = data;
            yield put({ type: DESTROY_USERS, payload: { users } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_USERS, payload: {} })
    }
}
const apiDestroyUsers = async fields => {
    const { data } = await Api.delete(`/api/users/destroy`, { data: { ...fields } });

    return data;
}

function* storeUsersSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStoreUsers, fields);
        if (!!data && data.ok) {
            const { users } = data;
            yield put({ type: STORE_USERS, payload: { users } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });

            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_USERS, payload: { errors } });
        }
    } catch (error) {

        yield put({ type: STORE_USERS, payload: {} })
    }
}
const apiStoreUsers = async fields => {
    const data = await Api.post(`/api/users/store`, { ...fields });

    return data;
}

function* updateUsersSaga({ payload }) {
    try {
        const { fields, onUpdate, id, index } = payload;
        const { data, errors } = yield call(apiUpdateUsers, fields, id);
        if (!!data && data.ok) {
            const users = yield select(selectorUsers);
            users.data.splice(index, 1, data.user);
            yield put({ type: UPDATE_USERS, payload: { users } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });

            onUpdate();
        }
        if (!!errors) {
            yield put({ type: UPDATE_USERS, payload: { errors } })
        }
    } catch (error) {
        yield put({ type: UPDATE_USERS, payload: {} })
    }
}
const apiUpdateUsers = async (fields, id) => {
    const data = await Api.put(`/api/users/update/${id}`, { ...fields });

    return data;
}

function* watchRegisterUser() {
    yield takeLatest(REGISTER_USER_ASYNC, registerUserSaga);
}
function* watchGetUser() {
    yield takeLatest(GET_USERS_ASYNC, getUsersSaga);
}
function* watchChangeStatus() {
    yield takeLatest(CHANGE_STATUS_USER_ASYNC, changeStatusUserSaga);
}
function* watchDestroyUsers() {
    yield takeLatest(DESTROY_USERS_ASYNC, destroyUsersSaga);
}
function* watchStoreUsers() {
    yield takeLatest(STORE_USERS_ASYNC, storeUsersSaga);
}
function* watchUpdateUsers() {
    yield takeLatest(UPDATE_USERS_ASYNC, updateUsersSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchRegisterUser),
        fork(watchGetUser),
        fork(watchChangeStatus),
        fork(watchDestroyUsers),
        fork(watchStoreUsers),
        fork(watchUpdateUsers)
    ]);
}