import React, { useState, useMemo } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
    Col,
    FormGroup,
    Label,
    Row,
    Input,
    ListGroupItem,
    ListGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import ScrollArea from "react-scrollbar";
import ListSelect from "../../../../components/ListSelect";

function TabOptions({
    filter,
    listContas,
    loadingListContas,
    getListContas,
    handleChangeSelect,
    listCentroCusto,
    loadingListCentroCusto,
    getListCentroCusto,
    listPessoas,
    loadingListPessoas,
    getListPessoas
}) {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const optionsContas = useMemo(
        () => listContas.data
            .map(
                ({ ctn_cd_conta, ctn_nm_conta }) => ({
                    name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
                    value: ctn_cd_conta
                })
            ),
        [listContas.data]
    );

    const optionsCentroCusto = useMemo(
        () => listCentroCusto.data
            .map(
                ({ cct_cd_centro, cct_nm_centro }) => ({
                    name: `${cct_cd_centro} - ${cct_nm_centro}`,
                    value: cct_cd_centro
                })
            ),
        [listCentroCusto.data]
    );

    const optionsPessoas = useMemo(() =>
        listPessoas.data.map(
            ({ pss_nm_pessoa, pss_cd_pessoa, pss_nu_identificacao }) => ({
                name: `${pss_cd_pessoa} - ${pss_nm_pessoa} - ${!!pss_nu_identificacao ? pss_nu_identificacao : ""}`,
                value: pss_cd_pessoa
            })
        ),
        [listPessoas.data]
    );

    return (
        <div className="b-light border-2 p-10 mt-2">
            <Nav tabs>
                <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink
                        className={`${activeTab === '1' ? 'active' : ''}`}
                        onClick={() => { toggle('1'); }}
                        style={{ cursor: 'pointer' }}
                    >
                        Conta
                    </NavLink>
                </NavItem>

                <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink
                        className={`${activeTab === '2' ? 'active' : ''}`}
                        onClick={() => { toggle('2'); }}
                        style={{ cursor: 'pointer' }}
                    >
                        Centro de Custo
                    </NavLink>
                </NavItem>

                <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink
                        className={`${activeTab === '3' ? 'active' : ''}`}
                        onClick={() => { toggle('3'); }}
                        style={{ cursor: 'pointer' }}
                    >
                        Pessoa
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <div className="b-light border-2 p-10">
                                <ListSelect
                                    data={optionsContas}
                                    isLoading={loadingListContas}
                                    onChange={(items) => {
                                        handleChangeSelect('contas', items.map(i => i.value));
                                    }}
                                    onPaginate={(rows, searchValue) => {
                                        const offset = rows + 1;
                                        getListContas({ fields: { offset, name: searchValue } })
                                    }}
                                    onSearch={(name, options) => {
                                        if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name.trimEnd()))) {
                                            getListContas({ fields: { name } })
                                        }
                                    }}
                                    totalResults={listContas.total}
                                    containerStyle={{ maxHeight: 180 }}
                                    pagination
                                />
                            </div>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <div className="b-light border-2 p-10">
                                <ListSelect
                                    data={optionsCentroCusto}
                                    isLoading={loadingListCentroCusto}
                                    onChange={(items) => {
                                        handleChangeSelect('centroCusto', items.map(i => i.value));
                                    }}
                                    onPaginate={(rows, searchValue) => {
                                        const offset = rows + 1;
                                        getListCentroCusto({ fields: { offset, name: searchValue } })
                                    }}
                                    onSearch={(name, options) => {
                                        if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name.trimEnd()))) {
                                            getListCentroCusto({ fields: { name } })
                                        }
                                    }}
                                    totalResults={listContas.total}
                                    containerStyle={{ maxHeight: 180 }}
                                    pagination
                                />
                            </div>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tabId="3">
                    <Row>
                        <Col sm="12">
                            <div className="b-light border-2 p-10">
                                <ListSelect
                                    data={optionsPessoas}
                                    isLoading={loadingListPessoas}
                                    onChange={(items) => handleChangeSelect('pessoas', items.map(i => i.value))}
                                    onPaginate={(rows, searchValue) => {
                                        const offset = rows + 1;
                                        getListPessoas({ fields: { offset, name: searchValue } })
                                    }}
                                    onSearch={(name, options) => {
                                        if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name.trimEnd()))) {
                                            getListPessoas({ fields: { name } })
                                        }
                                    }}
                                    totalResults={listPessoas.total}
                                    containerStyle={{ maxHeight: 135 }}
                                    pagination
                                />
                            </div>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default TabOptions;