import React, { useState, useCallback, useRef } from "react";
import {
    ModalBody,
    Modal,
    ModalHeader,
    ModalFooter,
    Button,
    Row,
    Col,
    Form,
} from "reactstrap";
import { connect } from "react-redux";
import {
    getListContasGrupos,
    storeContas,
    changeListGrupos,
    getListPessoas,
    changeListPessoas
} from "../../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";

const initialState = {
    ctn_nm_conta: "",
    ctn_cd_conta: "",
    ctn_tp_conta: "",
    ctn_vl_maximo_lan: "",
    ctn_cd_grupo: "",
    ctn_cd_grupo_pai: "",
    ctn_cd_pessoa: "",
    ctn_ds_hist_padrao: "",
    ctn_ds_mnemonico: "",
    ctn_st_contabiliza: "",
    ctn_cd_conta_contabil: "",
    ctn_cd_hist_padrao: "",
    ctn_st_lan_deb_cre: "",
    ctn_st_saldo_neg: "",
    ctn_st_lanc_manual: "",
    ctn_st_pag_cheque: "",
    ctn_vl_minimo_lan: ""
};

const Add = (
    {
        isOpen,
        clickClose,
        errors,
        storeContas,
        loadingStore,
    },
) => {

    //ESTADOS
    const [fields, setFields] = useState(initialState);
    const [, updateState] = useState();
    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const handleChange = e => {
        const { name, value, checked } = e.target;
        if (name === "ctn_st_saldo_neg" || name === "ctn_st_pag_cheque" || name === "ctn_st_lanc_manual") {
            checked ? setFields({ ...fields, [name]: value }) : setFields({ ...fields, [name]: "" });
        } else {
            setFields({ ...fields, [name]: value });
        }
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleValor = (name, value) => {
        setFields({ ...fields, [name]: value });
        // clearErrorsForm(name)
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'ctn_cd_grupo':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].grc_cd_grupo, ctn_cd_grupo_pai: selected[0].cd_grupo_pai, });
                } else {
                    setFields({ ...fields, ctn_cd_grupo: "s", ctn_cd_grupo_pai: "" });
                }
                break;
            case 'ctn_cd_pessoa':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].pss_cd_pessoa, })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeContas({ fields, onCreate: clickClose });
        } else {
            toast.error('Preencha todos os campos corretamente.', toast.POSITION.TOP_RIGHT);
            validator.showMessages();
        }

    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            opcoes: {
                message: 'Você deve escolhe pelo menos uma opção.',
                rule: (val) => {
                    return !val;
                }
            },
            valor_minimo: {
                message: "Valor minímo dever ser menor que máximo",
                rule: (val, params) => {
                    if (!!params[1]) {
                        return parseFloat(params[1]) >= parseFloat(params[0]);
                    }
                }
            },
            valor_maximo: {
                message: "Valor máximo deve ser maior que o minímo.",
                rule: (val, params) => {
                    return parseFloat(params[0]) <= parseFloat(params[1]);
                }
            }
        }
    })).current;

    return (
        <Modal
            isOpen={isOpen} size="lg"
            onOpened={() => {

            }}
            onClosed={() => {
                validator.visibleFields = [];
                setFields(initialState)
                for (const key in errors) {
                    delete errors[key]
                }
            }}>
            <ModalHeader toggle={() => !loadingStore && clickClose()}>Adicionar Conta</ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <FirstCard
                                fields={fields}
                                validFields={validFields}
                                validator={validator}
                                handleChange={handleChange}
                                errors={errors}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Col>
                        <Col xs="12">
                            <SecondCard
                                fields={fields}
                                validFields={validFields}
                                validator={validator}
                                handleValor={handleValor}
                            />
                        </Col>

                        <Col xs="12">
                            <ThirdCard
                                fields={fields}
                                validFields={validFields}
                                handleChangeSelect={handleChangeSelect}
                                validator={validator}
                                errors={errors}
                                handleChange={handleChange}
                            />
                        </Col>
                        <Col xs="12">
                            <FourthCard
                                fields={fields}
                                handleChange={handleChange}
                                validator={validator}
                                validFields={validFields}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => clickClose()} disabled={loadingStore} type="button">
                        Fechar
                </Button>
                    <Button color="primary" type="submit" disabled={loadingStore}>
                        {loadingStore ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Contas}) => {
    const { errors, loadingStore } = Contas;
    return { errors, loadingStore }
}

export default connect(mapStateToProps, {
    getListContasGrupos,
    changeListGrupos,
    storeContas,
    getListPessoas,
    changeListPessoas
})(Add);