import { GET_LIST_ESTADOS_ASYNC, GET_LIST_ESTADOS } from "../actionTypes";

const initial_state = {
    list: [],
    loadingList: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_LIST_ESTADOS_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_ESTADOS:
            return { ...state, ...action.payload, loadingList: false };
        default: return state;;
    }
}