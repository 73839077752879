import React, { useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { format } from 'date-fns';
import DadosGerais from "./DadosGerais";
import { toast } from "react-toastify";
const initialState = {
    ftr_nu_fatura: "",
    ftr_dt_vencimento: "",
    ftr_vl_fatura: "",
    ftr_tp_baixa: "",
    contrapartida: ""
};

const Add = ({ isOpen, clickClose, contrapartidas, setContraPartidas }) => {
    //ESTADOS
    const [fields, setFields] = useState(initialState);

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            verifica_valor: {
                message: 'O valor deve está entre o mínimo e máximo da conta.',
                rule: (val, params) => {
                    const valor_contrapartida = parseFloat(params[0]);
                    const valor_minimo = parseFloat(params[1]);
                    const valor_maximo = parseFloat(params[2]);

                    return valor_contrapartida >= valor_minimo && valor_contrapartida <= valor_maximo;
                }
            }
        }
    })).current;


    //FUNÇÕES
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name);
        }
    }
    const submitForm = e => {
        e.preventDefault();
        e.stopPropagation();

        if (validator.allValid()) {
            contrapartidas[fields.contrapartida].faturas.push(fields);
            setContraPartidas(contrapartidas);
            toast.success('Registro vinculado com sucesso.', toast.POSITION.TOP_RIGHT);
            clickClose();
        } else {
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
            validator.showMessages();
        }

    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'ftr_dt_vencimento':
                if (!!selected) {
                    setFields({ ...fields, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;

            case 'contrapartida':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].value,
                        ftr_nu_fatura: `${contrapartidas[selected[0].value].cnt_nu_doc}${String.fromCharCode((!!contrapartidas[selected[0].value].faturas.length  ?
                            contrapartidas[selected[0].value].faturas.length + 1 : 1) + 64)}`

                    });
                } else {
                    setFields({ ...fields, [name]: "", ftr_nu_fatura: "" });
                }
                break;

            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        switch (name) {
            default:
                setFields({ ...fields, [name]: value })
                break;
        }
    }
    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFields({ ...fields, [name]: value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onClosed={() => {
                setFields(initialState)
                validator.visibleFields = [];
                validator.messagesShown = false;
            }}
        >
            <ModalHeader toggle={() => clickClose()}>Incluir Fatura</ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <DadosGerais
                            fields={fields}
                            validator={validator}
                            validFields={validFields}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                            handleValor={handleValor}
                            contrapartidas={contrapartidas}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button type="submit" color="primary">
                        Incluir
                </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Add);