import {
    GET_PRODUTOS_ASYNC,
    STORE_PRODUTO_ASYNC,
    UPDATE_PRODUTO_ASYNC,
    DESTROY_PRODUTOS_ASYNC
} from "../actionTypes";

export const getProdutos = (payload = {}) => ({
    type: GET_PRODUTOS_ASYNC,
    payload
});

export const storeProdutos = payload => ({
    type: STORE_PRODUTO_ASYNC,
    payload
});

export const updateProdutos = payload => ({
    type: UPDATE_PRODUTO_ASYNC,
    payload
});

export const destroyProdutos = payload => ({
    type: DESTROY_PRODUTOS_ASYNC,
    payload
});