import React, { useMemo } from "react";
import {
    Input,
    InputGroupAddon,
    InputGroup
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { parseISO } from "date-fns";
import SelectPagination from "../../../../components/SelectPagination";
import { getListEmpresas } from "../../../../redux/actions";
import { connect } from "react-redux";

const ComponentValor = ({
    item,
    index,
    handleChange,
    handleValor,
    handleChangeSelect,
    loadingListEmpresas,
    listEmpresas,
    getListEmpresas

}) => {

    const placeholder = "Informe o valor";

    const optionsEmpresas = useMemo(() =>
        listEmpresas.data.map(({ emp_cd_empresa, emp_nm_empresa }) => ({
            name: `${emp_cd_empresa} - ${emp_nm_empresa}`,
            value: emp_cd_empresa
        })), [listEmpresas.data])

    switch (item.campo) {
        case 'vla_vl_lancamento':
            return (
                <InputGroup>
                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                    <NumberFormat
                        placeholder={placeholder}
                        thousandSeparator='.'
                        customInput={Input}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={({ value }) => handleValor(value, index)}
                    />
                </InputGroup>
            )
        case 'vla_dt_lancamento':
        case 'vla_dt_vencimento':
            return (
                <DatePicker
                    locale={br}
                    autoComplete="off"
                    placeholderText="Informe a data"
                    name="data_nascimento"
                    selected={!!item.valor ? parseISO(item.valor) : null}
                    onChange={(date) => {
                        handleChangeSelect('valor', date, index)
                    }}
                    dateFormat="dd/MM/yyyy"
                    className={`form-control digits`}
                    popperProps={{
                        positionFixed: true
                    }}
                />
            )
        case 'vla_tp_lancamento':
            return (
                <Typeahead
                    id="selectTipo"
                    options={[
                        {
                            name: 'Débito',
                            value: 'D'
                        },
                        {
                            name: 'Crédito',
                            value: 'C'
                        }
                    ]}
                    onChange={(selected) => {
                        handleChangeSelect('valor', selected, index)
                    }}
                    placeholder="Informe o tipo"
                    labelKey="name"
                    positionFixed
                    clearButton
                />
            )
        case 'vla_cd_conta':
            return (
                <Input
                    name="valor"
                    placeholder={`Informe o código da conta`}
                    onChange={e => handleChange(e, index)}
                />)
        case 'vla_cd_subconta':
            return (
                <Input
                    name="valor"
                    placeholder={`Informe o código da pessoa`}
                    onChange={e => handleChange(e, index)}
                />)
        case 'vla_cd_empresa':
            return (
                <SelectPagination
                    id={`selectEmpresa${index}`}
                    clearButton
                    labelKey="name"
                    placeholder="Informe a empresa"
                    maxResults={50}
                    isLoading={loadingListEmpresas}
                    totalResults={listEmpresas.total}
                    onChange={selected => handleChangeSelect('valor', selected, index)}
                    data={optionsEmpresas}
                    reduxAction={getListEmpresas}
                    positionFixed

                />)
        case 'vla_ds_historico':
        case 'ftr_nu_fatura':
        case 'vla_nu_doc':
            return (
                <Input
                    name="valor"
                    placeholder={placeholder}
                    onChange={e => handleChange(e, index)}
                />)
        default:
            return (<Input disabled />)
    }
}

const mapStateToProps = ({ Contas, Empresas }) => {
    const { list: listContas, loadingList: loadingListContas } = Contas;
    const { list: listEmpresas, loadingList: loadingListEmpresas } = Empresas;

    return { listEmpresas, loadingListEmpresas }
}

export default connect(mapStateToProps, {
    getListEmpresas
})(ComponentValor);