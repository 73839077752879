import React from 'react';
import { Col, Row, Input, Label, FormGroup } from "reactstrap";
function ValorMinMax({ lancamento }) {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label>
                    <h6>Vincular Conta</h6>
                </Label> */}
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Mínimo</Label>
                                <Input
                                    defaultValue={!!lancamento.conta?.ctn_vl_minimo_lan ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(lancamento.conta.ctn_vl_minimo_lan)
                                        :
                                        'Não Informado'
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Máximo</Label>
                                <Input
                                    defaultValue={!!lancamento.conta?.ctn_vl_maximo_lan ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(lancamento.conta.ctn_vl_maximo_lan)
                                        :
                                        'Não Informado'
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

export default ValorMinMax;