import { format, parseISO } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Alert, Row, Col } from 'reactstrap';
import { } from "../../../../redux/actions";

function Relatorio({ rltCnt }) {
    return (
        <Row>
            <Col className="b-light p-15 border-5 m-b-20">
                <Table className="font-arial-rlt">
                    <thead>
                        <tr className="text-center">
                            <th className="b-dark">Conta</th>
                            <th className="b-dark">Lançamentos</th>
                            <th className="b-dark">Pessoa</th>
                            <th className="b-dark">Centro de Custo</th>
                            <th className="b-dark">Tipo</th>
                            <th className="b-dark" >Valor</th>
                            <th className="b-dark">Histórico</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!Object.keys(rltCnt).length ?
                            Object.keys(rltCnt).map((codConta, keyConta) => {
                                return (
                                    <Fragment key={keyConta}>
                                        <tr>
                                            <td width="15px">
                                                <u>{codConta}</u>
                                            </td>
                                            <td colSpan="6">
                                                <u>{rltCnt[codConta][0].conta.ctn_nm_conta}</u>
                                            </td>
                                        </tr>
                                        {rltCnt[codConta].map((cnt, keyCnt) => {
                                            return (
                                                <tr key={keyCnt}>
                                                    <td></td>
                                                    <td>{format(parseISO(cnt.vla_dt_lancamento), 'dd/MM/yyyy')}</td>
                                                    <td>{`${cnt.pessoa.pss_cd_pessoa} -  ${cnt.pessoa.pss_nm_pessoa}`}</td>
                                                    <td width="150px">{`${cnt.centro_custo.cct_cd_centro} - ${cnt.centro_custo.cct_nm_centro}`}</td>
                                                    <td>{cnt.vla_tp_lancamento === 'C' ? 'Crédito' : 'Débito'}</td>
                                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cnt.vla_vl_lancamento)}</td>
                                                    <td>{cnt.vla_ds_historico}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan="5" className="f-w-900 text-right">Total:</td>
                                            <td className="f-w-900" width="150px">
                                                Credito: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                rltCnt[codConta].filter(fil => fil.vla_tp_lancamento === 'C').reduce((acu, el) => acu + parseFloat(el.vla_vl_lancamento), 0)
                                            )}
                                            </td>
                                            <td className="f-w-900">
                                                Débito: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                rltCnt[codConta].filter(fil => fil.vla_tp_lancamento === 'D').reduce((acu, el) => acu + parseFloat(el.vla_vl_lancamento), 0)
                                            )}
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            })
                            :
                            <tr>
                                <td colSpan="8"><Alert color="light">Nenhum registro encontrado</Alert></td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ RltOperacionais }) => {

    const { rltCnt } = RltOperacionais;

    return { rltCnt }
}

export default connect(mapStateToProps, {})(Relatorio);