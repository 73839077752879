import React, { useState, useEffect, useRef, useMemo } from "react";
import { Col, Input, ListGroup, InputGroup, InputGroupAddon, InputGroupText, ListGroupItem, Row, Alert } from "reactstrap";
import ScrollArea from 'react-scrollbar';
import { X } from "react-feather";

const MultiSelect = ({
    data = [],
    labelKey = "label",
    onChange = () => { },
    buttonSelectAll = false, //AINDA ARRUMANDO
    searchInput = true,
    pagination = false,
    isLoading = false,
    defaultSelected = [],
    totalResults = 0,
    emptyLabel = "Nenhum Registro",
    emptySelected = "Nenum Registro Selecionado",
    onPaginate = () => { },
    onSearch = () => { },
}) => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const cacheItems = useRef([]);
    const cacheTotalResults = useRef(0);
    const scrollRef = useRef();

    useEffect(() => {
        if (!!defaultSelected.length) {
            setSelectedItems(defaultSelected)
        }
    }, [])
    const handleSelectedItems = (item) => {

        const items = [...selectedItems];
        const index = items.findIndex((el) => el.value === item.value);
        if (index !== -1) {
            items.splice(index, 1);
        } else {
            items.push(item);
        }
        setSelectedItems(items);
        onChange(items);
    }
    const clearSelectedItems = () => {
        setSelectedItems([]);
        onChange([]);
    }


    const fillList = useMemo(() => data.filter((el) => {
        if (!!searchValue) {
            return el[labelKey].includes(searchValue)
        }
        return true;
    }), [searchValue, data])

    return (
        <Row className="p-l-10 p-b-10 p-r-10" >
            <Col md="6" xs="12" className="p-0 b-light" style={{ height: 400 }}>
                {searchInput &&
                    <InputGroup>
                        <Input
                            className=" b-r-0"
                            placeholder="Pesquisar..."
                            onChange={({ target: { value } }) => {
                                onSearch(value);
                                setSearchValue(value);
                                scrollRef.current.scrollArea.scrollTop();
                            }}
                            type="text" />
                        <InputGroupAddon addonType="append">
                            <InputGroupText className="b-r-0">
                                <i className="icofont icofont-ui-search"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                }
                {buttonSelectAll &&
                    <ListGroupItem>
                        <Input
                            className="checkbox_animated"
                            onChange={() => { }}
                            id={`chk-aniAll`}
                            type="checkbox" />
                                   Selecionar Todos
                           </ListGroupItem>
                }
                {isLoading &&
                    <div style={{ zIndex: 2, position: 'absolute', left: 0, right: 0 }} className="loader-box">
                        <div className="loader-1"></div>
                    </div>
                }
                <ScrollArea ref={scrollRef} vertical={true} horizontal={false} >
                    <ListGroup>
                        {!!fillList.length ?
                            <>
                                {fillList.map((el, key) => {
                                    return (
                                        <ListGroupItem
                                            key={key}
                                            className="list-group-item-action"
                                            disabled={isLoading}
                                            onClick={() => handleSelectedItems(el)}
                                            style={{ cursor: 'pointer', borderWidth: 0 }}>
                                            <div>
                                                <Input
                                                    className="checkbox_animated"
                                                    checked={!!selectedItems.find((cItem) => cItem.value === el.value)}
                                                    onChange={() => { }}
                                                    id={`chk-ani-${el[labelKey]}-${key}`}
                                                    type="checkbox" />
                                                <span>{el[labelKey]}</span>
                                            </div>
                                            {el.subItem &&
                                                <small className="m-l-30">{el.subItem}</small>
                                            }
                                        </ListGroupItem>
                                    )
                                })}
                                {(!!data.length && pagination && !isLoading && data.length < totalResults) &&
                                    <ListGroupItem
                                        className="list-group-item-action d-flex justify-content-center"
                                        onClick={() => {
                                            onPaginate(data.length, searchValue)
                                        }}
                                        style={{ cursor: 'pointer', borderWidth: 0 }}
                                    >
                                        <span className="font-primary">Carregar Mais</span>
                                    </ListGroupItem>
                                }
                            </>
                            :
                            !isLoading &&
                            <Alert className="d-flex justify-content-center" color="light">{emptyLabel}</Alert>
                        }

                    </ListGroup>
                </ScrollArea>
            </Col>
            <Col md="6" xs="12" className="p-0 b-light" style={{ height: 400 }}>
                <div className="form-control d-flex justify-content-between b-r-0">
                    <span
                        className="f-14">
                        {`Selecionados ${selectedItems.length}`}
                    </span>
                    <span
                        className="f-14 font-primary"
                        onClick={clearSelectedItems}
                        style={{ cursor: 'pointer' }}>
                        {`Limpar Todos`}
                    </span>
                </div>
                <ScrollArea vertical={true} horizontal={false} >
                    <ListGroup>
                        {!!selectedItems.length ?
                            selectedItems.map((el, key) => {
                                return (
                                    <ListGroupItem
                                        key={key}
                                        disabled={isLoading}
                                        className="list-group-item-action"
                                        onClick={() => handleSelectedItems(el)}
                                        id={`chk-ani${key}`}
                                        style={{ cursor: 'pointer', borderWidth: 0 }}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            {el[labelKey]}
                                            <span><X /></span>
                                        </div>
                                        {el.subItem &&
                                            <small>{el.subItem}</small>
                                        }
                                    </ListGroupItem>
                                )
                            })
                            :
                            !isLoading &&
                            <Alert className="d-flex justify-content-center" color="light">{emptySelected}</Alert>
                        }
                    </ListGroup>
                </ScrollArea>
            </Col>
        </Row>
    )
}

export default React.memo(MultiSelect)