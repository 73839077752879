import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Form } from 'reactstrap';
import {
  getListPessoas,
  getListContas,
  getListCentroCusto,
  getRltExtratoContas,
  clearRltExtratoContas
} from "../../../../redux/actions";
import { connect } from "react-redux";
import { format, parseISO } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import SimpleReactValidator from 'simple-react-validator';
import { validatorMessages } from "../../../../constraints/variables";
import Periodo from "./Periodo";
import Opcoes from "./Opcoes";
import Pessoas from "./Pessoas";
import Contas from "./Contas";
import CentroCusto from "./CentroCusto";
import PrintComponent from "../../../../components/PrintComponent";
import RltModeloConta from "./RltModeloConta";
import RltModeloCct from "./RltModeloCct";
import RltModeloPessoa from "./RltModeloPessoa";

const initalState = {
  contas: [],
  pessoas: [],
  centroCusto: [],
  dataInicial: format(new Date(), 'yyyy-MM-dd'),
  dataFinal: format(new Date(), 'yyyy-MM-dd'),
  totalDia: true,
  apenasSelecao: false,
  modelo: 'conta'
};

const RelOperacionalExtratoConta = ({
  getListPessoas,
  getListContas,
  getListCentroCusto,
  getRltExtratoContas,
  loadingRltExtratoContas,
  clearRltExtratoContas,
  rltExtratoContas,
  active_user_empresa
}) => {
  useEffect(() => {
    getListPessoas()
    getListContas()
    getListCentroCusto()
    return () => {
      clearRltExtratoContas()
    }
  }, []);

  //ESTADOS
  const [filter, setFilter] = useState(initalState);
  
  //VALIDATOR
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const validator = useRef(new SimpleReactValidator({
    autoForceUpdate: { forceUpdate },
    messages: validatorMessages
  })).current;
  //REFS
  const curFilter = useRef(initalState);
  const printCompRef = useRef();

  //FUNÇÕES
  const onSubmitFilter = e => {
    e.preventDefault();
    if (validator.allValid()) {
      getRltExtratoContas({ fields: filter });
      curFilter.current = filter;
    } else {
      validator.showMessages()
    }
  }
  
  const handlePrint = useReactToPrint({
    content: () => printCompRef.current,
    documentTitle: `Relatório Operacional Extrato de Contas ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
    // onBeforePrint: () => setLoadingPrint(true),
    // onAfterPrint: () => setLoadingPrint(false),
    onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
  });

  const handleChangeSelect = (name, selected) => {
    switch (name) {
      case 'dataFinal':
      case 'dataInicial':
        if (!!selected) {
          setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
        } else {
          setFilter({ ...filter, [name]: format(new Date(), 'yyyy-MM-dd') })
        }
        break;
      case 'centroCusto':
      case 'contas':
      case 'pessoas':
        if (!!selected.length) {
          setFilter({ ...filter, [name]: selected.map(({ value }) => value) });
          validator.hideMessageFor(name)
        } else {
          setFilter({ ...filter, [name]: [] });
          validator.showMessageFor(name)
        }
        break;
      default:
        if (!!selected.length) {
          setFilter({ ...filter, [name]: selected[0].value });
        } else {
          setFilter({ ...filter, [name]: null });
        }
        break;
    }

  }
  
  const handleChange = e => {
    const { checked, name } = e.target;

    switch (name) {
      case 'totalDia':
        setFilter({ ...filter, [name]: checked })
        break;
    }
  }


  return (
    <Fragment>
      <Breadcrumb parent="Operacional" title="Extrato de Contas" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <h5>Filtro</h5>
                </CardTitle>
                <Form onSubmit={onSubmitFilter}>
                  <Row>
                    <Col lg="6" className="m-b-5" xs="12">
                      <Row>
                        <Col className="m-b-5" xs="12">
                          <Periodo
                            filter={filter}
                            handleChangeSelect={handleChangeSelect}
                          />
                        </Col>
                        <Col xs="12">
                          <Opcoes
                            handleChangeSelect={handleChangeSelect}
                            handleChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="m-b-5" lg="6" sm="12">
                      <Contas
                        filter={filter}
                        validator={validator}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </Col>
                    <Col className="m-b-5" lg="6" sm="12">
                      <Pessoas
                        handleChangeSelect={handleChangeSelect}
                      />
                    </Col>
                    <Col className="m-b-5" lg="6" sm="12">
                      <CentroCusto
                        handleChangeSelect={handleChangeSelect}
                      />
                    </Col>
                    <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormGroup>
                        <Button
                          title="Pesquisar"
                          disabled={loadingRltExtratoContas}
                          className="m-t-30"
                          color="primary">
                          <i className="fa fa-search"></i>
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            {loadingRltExtratoContas ?
              <div className="loader-box">
                <div className="loader-1"></div>
              </div>
              :
              <Card>
                <CardBody>
                  {!!rltExtratoContas.length &&
                    <div className="m-b-10 d-flex justify-content-end">
                      <Button size="sm" onClick={handlePrint} className="f-16">
                        <i className="fa fa-print"></i>
                        <span className="p-l-5">
                          Imprimir
                    </span>
                      </Button>
                    </div>
                  }
                  <PrintComponent ref={printCompRef}>
                    {!!rltExtratoContas.length &&
                      <div className="d-flex justify-content-start font-arial-rlt">
                        <span className="">
                          {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - 
                                        Extrato de Conta ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })}, 
                                        ${format(parseISO(filter.dataInicial), 'dd/MM/yy')} a ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })} ,
                                        ${format(parseISO(filter.dataFinal), 'dd/MM/yy')} -
                                        Regime: ${!!active_user_empresa ? active_user_empresa.emp_tp_contab === 'C' ? 'Caixa' : 'Mensal' : 'Não informado'} `}
                        </span>
                      </div>
                    }
                    {curFilter.current.modelo === 'conta' &&
                      <RltModeloConta
                        printCompRef={printCompRef}
                        handlePrint={handlePrint}
                        filter={filter}
                      />
                    }
                    {curFilter.current.modelo === 'centroCusto' &&
                      <RltModeloCct
                        printCompRef={printCompRef}
                        handlePrint={handlePrint}
                        filter={filter}
                      />
                    }
                    {curFilter.current.modelo === 'pessoa' &&
                      <RltModeloPessoa
                        printCompRef={printCompRef}
                        handlePrint={handlePrint}
                        filter={filter}
                      />
                    }
                  </PrintComponent>
                </CardBody>
              </Card>
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const mapStateToProps = ({ Auth, RltOperacionais }) => {
  const { loadingRltExtratoContas, rltExtratoContas } = RltOperacionais;
  const { active_user_empresa } = Auth;
  return { loadingRltExtratoContas, rltExtratoContas, active_user_empresa }
}

export default connect(mapStateToProps, {
  getListPessoas,
  getListContas,
  getListCentroCusto,
  getRltExtratoContas,
  clearRltExtratoContas
})(RelOperacionalExtratoConta);