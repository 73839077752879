import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from "react-redux";
import { getListContas, getListCentroCusto, storeSelCentros } from "../../../../redux/actions";
import { useMemo } from 'react';
import ListSelect from "../../../../components/ListSelect";

function CentrosCusto({
    getListCentroCusto,
    listCentroCusto,
    loadingListCentroCusto,
    storeSelCentros,
    selCentroCusto
}) {
    const optionsCentroCusto = useMemo(() =>
        listCentroCusto.data.map(({ cct_cd_centro, cct_nm_centro }) => ({ name: `${cct_cd_centro} - ${cct_nm_centro}`, value: cct_cd_centro })),
        [listCentroCusto.data]);
    return (
        <>
            <div className="b-light border-2 p-10">
                <h6>Centro de Custo</h6>
                <Row>
                    <Col xs="12">
                        <ListSelect
                            data={optionsCentroCusto}
                            isLoading={loadingListCentroCusto}
                            onChange={(items) => {
                                const centrosCusto = items.map(({ value }) => value);
                                storeSelCentros({ fields: { centrosCusto, janela: 'Relatório Movimentacão' } });
                            }}
                            onPaginate={(rows, searchValue) => {
                                const offset = rows + 50;
                                getListCentroCusto({ fields: { offset, name: searchValue } })
                            }}
                            onSearch={(name, options) => {
                                if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name))) {
                                    getListCentroCusto({ fields: { name } })
                                }
                            }}
                        
                            selected={(options) => {
                                return options.filter((option) => selCentroCusto.find((sel) => sel.scc_cd_cct === option.value));
                            }}
                            totalResults={listCentroCusto.total}
                            containerStyle={{ maxHeight: 150 }}
                            pagination
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}
const mapStateToProps = ({ CentroCusto, SelCentrosCusto }) => {
    const { list: listCentroCusto, loadingList: loadingListCentroCusto } = CentroCusto;
    const { selCentroCusto } = SelCentrosCusto;
    return { listCentroCusto, loadingListCentroCusto, selCentroCusto }
}

export default connect(mapStateToProps, {
    getListContas,
    getListCentroCusto,
    storeSelCentros
})(CentrosCusto);