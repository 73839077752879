import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Form } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../../constraints/variables";
import { storeTipoPessoa } from "../../../../redux/actions";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";

const initalState = {
    tps_nm_tipo: "",
    tps_st_documento: "",
    tps_st_importado: "",
    tps_ds_tipo: "",
    tps_tp_documento: "F"
};

function Add({
    isOpen,
    clickClose,
    errors,
    storeTipoPessoa,
    loadingStoreTps
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;


    const [fields, setFields] = useState(initalState);

    const clearErrors = name => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;

        setFields({ ...fields, [name]: value });

        clearErrors(name)
    }

    const handleChangeSelect = (name, selected) => {
        setFields({ ...fields, [name]: selected[0].value || "" });
    }

    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeTipoPessoa({ fields, onSave: clickClose })
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }
    }

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onClosed={() => {
                setFields(initalState)
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => !loadingStoreTps && clickClose()}>
                Adicionar Tipo de Pessoa
            </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <FirstCard
                                handleChange={handleChange}
                                handleChangeSelect={handleChangeSelect}
                                validator={validator}
                                fields={fields}
                                validFields={validFields}
                                isInvalid={isInvalid}
                                errors={errors}
                            />
                        </Col>
                        <Col xs="12">
                            <SecondCard
                                handleChange={handleChange}
                                validator={validator}
                                fields={fields}
                                isInvalid={isInvalid}
                                errors={errors}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" disabled={loadingStoreTps} onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={loadingStoreTps} color="primary">
                        {loadingStoreTps ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ TiposPessoas }) => {
    const { errors, loadingStoreTps } = TiposPessoas;
    return { errors, loadingStoreTps }
}

export default connect(mapStateToProps, {
    storeTipoPessoa
})(Add)