import {
    GET_LIST_PESSOAS_ASYNC,
    GET_LIST_PESSOAS,
    GET_PESSOAS_ASYNC,
    STORE_PESSOAS_ASYNC,
    DESTROY_PESSOAS_ASYNC,
    UPDATE_PESSOAS_ASYNC,
    GET_LIST_PESSOAS_EMPRESA_ASYNC
} from "../actionTypes";

export const getListPessoas = (payload = {}) => ({
    type: GET_LIST_PESSOAS_ASYNC,
    payload
});

export const changeListPessoas = payload => ({
    type: GET_LIST_PESSOAS,
    payload
});

export const getPessoas = (payload = {}) => ({
    type: GET_PESSOAS_ASYNC,
    payload
});

export const storePessoas = payload => ({
    type: STORE_PESSOAS_ASYNC,
    payload
});

export const destroyPessoas = payload => ({
    type: DESTROY_PESSOAS_ASYNC,
    payload
});

export const updatePessoas = payload => ({
    type: UPDATE_PESSOAS_ASYNC,
    payload
});

export const getListPessoasEmp = (payload = {}) => ({
    type: GET_LIST_PESSOAS_EMPRESA_ASYNC,
    payload
})