import { put, debounce, call, all, fork } from "redux-saga/effects";
import {
    GET_LIST_CLIENTES,
    GET_LIST_CLIENTES_ASYNC,
    GET_LIST_CLIENTES_EMPRESA_ASYNC,
    GET_LIST_CLIENTES_EMPRESA
} from "../actionTypes";
import Api from "../../services/api";

function* getListClientesSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListClientes, fields);
        if (data.ok) {
            const { listClientes } = data;
            yield put({ type: GET_LIST_CLIENTES, payload: { listClientes } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_CLIENTES, payload: {} });
    }
}
const apiGetListClientes = async fields => {
    const { data } = await Api.get(`/api/clientes/list`, { params: fields });

    return data;
}

function* getListClientesEmpresaSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListClientesEmpresa, fields);
        if (data.ok) {
            const { listClientesEmp } = data;
            yield put({ type: GET_LIST_CLIENTES_EMPRESA, payload: { listClientesEmp } })
        }
    } catch (error) {

    }
}
const apiGetListClientesEmpresa = async fields => {
    const { data } = await Api.get(`/api/clientes/listClientesEmpresa`, { params: fields });

    return data;
}


function* watchGetListClientes() {
    yield debounce(1000, GET_LIST_CLIENTES_ASYNC, getListClientesSaga);
}
function* watchGetListClientesEmpresa() {
    yield debounce(1000, GET_LIST_CLIENTES_EMPRESA_ASYNC, getListClientesEmpresaSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListClientes),
        fork(watchGetListClientesEmpresa)
    ]);
}