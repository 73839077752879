import { format, parseISO } from "date-fns";
import React, { useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { getFeriados, destroyFeriados } from "../../../redux/actions";
import { Button } from "reactstrap";
import sweetalert2 from "sweetalert2";

function Table({
    loadingFeriados,
    feriados,
    getFeriados,
    setSelectedFeriado,
    setOpenModal,
    curFilter,
    destroyFeriados,
    loadingDestroyFeriados
}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, frd_nm_feriado }) => ({ id, frd_nm_feriado })))
    }, []);

    const deleteTiposMovimentosPrd = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir os registros selecionados?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ frd_nm_feriado }) => {
                return (`<li class="list-group-item-action list-group-item">${frd_nm_feriado}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!feriados && feriados.current_page !== 1) ? feriados.current_page : 1;
            const fields = { feriadosIds: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyFeriados({ fields, onDelete });
        }
    }

    return (
        <>
            {loadingFeriados ?
                <div className="loader-box">
                    <div className="loader-1"></div>
                </div>
                :
                !!feriados &&
                <DataTable
                    persistTableHead
                    selectableRows
                    pagination
                    paginationServer
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={toggleCleared}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                    }}
                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                    progressComponent={
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    }
                    noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                    contextActions={
                        <Button
                            sid="feriados.destroy"
                            disabled={loadingDestroyFeriados}
                            onClick={deleteTiposMovimentosPrd}
                            color="danger">{loadingDestroyFeriados ? 'Processando...' : 'Remover Feriado(s)'}
                        </Button>
                    }
                    progressPending={loadingDestroyFeriados}
                    paginationPerPage={feriados.per_page}
                    paginationTotalRows={feriados.total}
                    paginationDefaultPage={feriados.current_page}
                    onChangePage={page => {
                        getFeriados({ fields: { ...curFilter.current, page } })
                    }}
                    columns={[
                        {
                            name: 'Data',
                            selector: 'dataFeri',
                            center: true
                        },
                        {
                            name: 'Nome',
                            selector: 'frd_nm_feriado',
                            center: true
                        },
                        {
                            name: 'Ações',
                            selector: 'acoes',
                            center: true
                        }
                    ]}
                    data={feriados.data.map(({ id, frd_dt_feriado, frd_nm_feriado }, index) => {
                        const dataFeri = format(parseISO(frd_dt_feriado), 'dd/MM/yyyy');
                        const acoes = (
                            <div style={{ display: "flex", overflow: "auto" }}>
                                <a
                                    sid="feriados.update"
                                    style={{ cursor: 'pointer' }}
                                    title="Editar informações"
                                    onClick={() => {
                                        setSelectedFeriado(index)
                                        setOpenModal('edit')
                                    }}
                                >
                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                </a>
                            </div>
                        )
                        return { id, dataFeri, frd_nm_feriado, acoes }
                    })}
                />

            }
        </>
    )
}

const mapStateToProps = ({ Feriados }) => {
    const { loadingFeriados, feriados, loadingDestroyFeriados } = Feriados;
    return { loadingFeriados, feriados, loadingDestroyFeriados };
}

export default connect(mapStateToProps, {
    getFeriados,
    destroyFeriados
})(Table);