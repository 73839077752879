import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormGroup, Label, Row, Input, ListGroupItem, ListGroup, } from 'reactstrap';
import ScrollArea from "react-scrollbar";

function Opcoes({
    handleChangeSelect,
    filter,
    handleChangeExibir,
    empresas,
    loadingEmpresas,
    handleChangeEmpresas
}) {
    const optionsModelo = [
        /* {
            name: 'Gráfico',
            value: 'grafico',
        }, */
        {
            name: 'Margem',
            value: 'margem',
        },
        {
            name: 'Mensal',
            value: 'mensal',
        },
        {
            name: 'Percentual',
            value: 'percentual',
        },
        /* {
            name: 'Período (dias)',
            value: 'periodo',
        },
        {
            name: 'Referência',
            value: 'referencia',
        }, */
    ];

    const optionsRegime = [
        {
            name: 'Caixa',
            value: 'C'
        },
        {
            name: 'Mensal',
            value: 'M'
        }
    ];

    const optionsIndice = [
        {
            name: '(Nenhum Índice)',
            value: 'nenhum'
        }
    ];

    const optionsExibir = [
        {
            label: 'Centros de Custo Agrup.',
            inputName: 'CttAgrupando',
            isDisabled: false
        },
        {
            label: 'Grupos de Conta',
            inputName: 'GruposConta',
            isDisabled: false
        },
        {
            label: 'Contas',
            inputName: 'Contas',
            isDisabled: false
        },
        {
            label: 'Centros de Custo',
            inputName: 'Cct',
            isDisabled: false
        },
        {
            label: 'Pessoas',
            inputName: 'Pessoas',
            isDisabled: false
        },
    ];

    return (
        <div className="b-light border-2 p-10 mt-2">
            <Row>
                <Col lg="4" sm="12" id="optionsRow1">
                    <h6>Opções</h6>
                    <FormGroup>
                        <Label>Modelo</Label>
                        <Typeahead
                            id="selectModelo"
                            labelKey="name"
                            placeholder="Selecione"
                            onChange={(selected) => handleChangeSelect('modelo', selected[0]?.value)}
                            clearButton
                            options={optionsModelo}
                            selected={optionsModelo.filter(o => o.value == filter.modelo)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Regime</Label>
                        <Typeahead
                            id="selectRegime"
                            labelKey="name"
                            placeholder="Selecione"
                            selected={optionsRegime.filter(o => o.value == filter.regime)}
                            onChange={(selected) => handleChangeSelect('regime', selected[0]?.value)}
                            clearButton
                            options={optionsRegime}
                            disabled
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Índice</Label>
                        <Typeahead
                            id="selectIndice"
                            labelKey="name"
                            placeholder="Selecione"
                            onChange={(selected) => handleChangeSelect('indice', selected[0]?.value)}
                            clearButton
                            options={optionsIndice}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Dec.</Label>
                        <Input
                            id="inputDec"
                            type="number"
                            name="inputDec"
                            value={filter.dec}
                            onChange={({ target: { value } }) => {
                                handleChangeSelect('dec', value);
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col lg="4" sm="12">
                    <h6>Empresas</h6>
                    <ScrollArea horizontal={false} className="mt-4">
                        {loadingEmpresas
                            ? (
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                            )
                            : (
                                <ListGroup>
                                    {empresas.map(({ emp_cd_empresa, emp_nm_empresa }, index) => {
                                        return (
                                            <ListGroupItem
                                                key={index}
                                                className={`list-group-item-action`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleChangeEmpresas(emp_cd_empresa);
                                                }}
                                            >
                                                <div>
                                                    <Input
                                                        checked={filter.empresas.includes(emp_cd_empresa)}
                                                        className="checkbox_animated"
                                                        onChange={() => { }}
                                                        name={`empresa${emp_cd_empresa}`}
                                                        type="checkbox"
                                                    />
                                                    <span>{emp_nm_empresa}</span>
                                                </div>
                                            </ListGroupItem>
                                        )
                                    })}
                                </ListGroup>
                            )
                        }

                    </ScrollArea>
                </Col>

                <Col lg="4" sm="12">
                    <h6>Exibir</h6>
                    <ScrollArea horizontal={false} className="mt-4">
                        <ListGroup>
                            {optionsExibir.map(({ label, inputName, isDisabled }, index) => {
                                const itens = [...filter.exibir];
                                const containItem = itens.includes(inputName);

                                return (
                                    <ListGroupItem
                                        key={index}
                                        className={`list-group-item-action ${isDisabled ? 'disabled' : ""}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {

                                            if (containItem) {
                                                handleChangeExibir(itens.filter(i => i != inputName));
                                            } else {
                                                itens.push(inputName);
                                                handleChangeExibir(itens);
                                            }
                                        }}
                                    >
                                        <div>
                                            <Input
                                                checked={containItem}
                                                className="checkbox_animated"
                                                onChange={() => { }}
                                                name={inputName}
                                                type="checkbox"
                                            />
                                            <span>{label}</span>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </ScrollArea>
                </Col>
            </Row>
        </div>
    )
}

export default Opcoes;