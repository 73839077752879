import React, { } from 'react';
import { FormGroup, Label, Input, Col, Row } from 'reactstrap';

function Bloqueios({ empresa }) {

    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Bloqueios</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label className="m-b-0">
                                    <Input
                                        checked={empresa.emp_st_bloq_exclusao === "S"}
                                        readOnly
                                        className={`checkbox_animated disabled`}
                                        name="emp_st_bloq_exclusao"
                                        id="chk-ani01"
                                        type="checkbox" /> Exclusão
                            </Label>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup row className="m-b-5">
                                <Col md="3" xs="12">
                                    <Label className="m-b-0">
                                        <Input
                                            checked={empresa.emp_st_bloqueio === "S"}
                                            readOnly
                                            className={`checkbox_animated disabled`}
                                            name="emp_st_bloqueio"
                                            id="chk-ani02"
                                            type="checkbox" /> Dias
                                    </Label>
                                </Col>
                                <Col md="9" xs="12">
                                    <Input
                                        disabled={!empresa.emp_st_bloqueio}
                                        defaultValue={empresa.emp_qt_dias_bloqueio}
                                        readOnly
                                        name="emp_qt_dias_bloqueio"
                                        className="form-control"
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup row>
                                <Col md="3" xs="12">
                                    <Label>Dias não pagos</Label>
                                </Col>
                                <Col md="9" xs="12">
                                    <Input
                                        disabled={!empresa.emp_st_bloqueio}
                                        defaultValue={empresa.emp_qt_dias_nao_pago}
                                        readOnly
                                        name="emp_qt_dias_nao_pago"
                                        className="form-control" />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup >
        </>
    )
}

export default Bloqueios;