import { format, parseISO } from "date-fns";
import React, { Fragment } from "react";

import { connect } from "react-redux";
import { Col, Row, Table, Alert } from "reactstrap";

function Relatorio({ rltPesquisa }) {
    let total = 0;

    return (
        <Row className="b-light p-15 border-5 m-b-20">
            <Col xs="12">
                <Table className="font-arial-rlt">
                    <thead>
                        <tr className="text-center">
                            <th className="b-light border-3">Empresa</th>
                            <th className="b-light border-3">Data</th>
                            <th className="b-light border-3">Conta</th>
                            <th className="b-light border-3">Pessoa</th>
                            <th className="b-light border-3">Histórico</th>
                            <th className="b-light border-3">Tipo</th>
                            <th className="b-light border-3">Valor</th>
                            <th className="b-light border-3">Vencimento</th>
                            <th className="b-light border-3">Fatura</th>
                            <th className="b-light border-3">Doc</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!rltPesquisa.length ?
                                rltPesquisa.map(({
                                    empresa,
                                    vla_dt_lancamento,
                                    vla_cd_conta,
                                    vla_cd_subconta,
                                    vla_ds_historico,
                                    vla_tp_lancamento,
                                    vla_vl_lancamento,
                                    ftr_dt_vencimento,
                                    ftr_nu_fatura,
                                    vla_nu_doc
                                }, key) => {
                                    total += parseFloat(vla_vl_lancamento)
                                    return (
                                        <Fragment key={key}>
                                            <tr className="text-right">
                                                <td>{!empresa ? 'Não Informado' : `${empresa?.emp_nm_empresa}`}</td>
                                                <td>{!vla_dt_lancamento ? 'Não Informado' : format(parseISO(vla_dt_lancamento), 'dd/MM/yyyy')}</td>
                                                <td>{vla_cd_conta || 'Não Informado'}</td>
                                                <td>{vla_cd_subconta || 'Não Informado'}</td>
                                                <td>{vla_ds_historico || 'Não Informado'}</td>
                                                <td>{vla_tp_lancamento === 'C' ? 'Crédito' : 'Débito'}</td>
                                                <td>{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(vla_vl_lancamento)}</td>
                                                <td>{!!ftr_dt_vencimento ? format(parseISO(ftr_dt_vencimento), 'dd/MM/yyyy') : 'Não Possui'}</td>
                                                <td>{ftr_nu_fatura || 'Não Informado'}</td>
                                                <td>{vla_nu_doc || 'Não Informado'}</td>
                                            </tr>
                                            {rltPesquisa.length - 1 === key &&
                                                <tr className="b-t-dark border-2 text-right">
                                                    <td colSpan="6"><span className="d-flex justify-content-end">Total: </span></td>
                                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</td>
                                                    <td colSpan="3"></td>
                                                </tr>
                                            }
                                        </Fragment>

                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="10"> <Alert color="light">Nenhum registro encontrado</Alert></td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ RltOperacionais }) => {
    const { rltPesquisa } = RltOperacionais;
    return { rltPesquisa }
}

export default connect(mapStateToProps, {})(Relatorio);