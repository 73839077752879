import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";
import { format, parseISO } from 'date-fns';
const Datas = ({ lancamento }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label>
                        <h6>Datas</h6>
                    </Label> */}
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Data Competência</Label>
                                <Input defaultValue={format(parseISO(lancamento.lan_dt_competencia), 'dd/MM/yyyy')} readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Datas);