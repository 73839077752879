import React, { useMemo } from "react";
import {
    Col,
    FormGroup,
    Row,
    Label,
    FormFeedback,
} from "reactstrap";
import MultiSelect from "../../../../components/MultiSelect";
import { getListEmpresas } from "../../../../redux/actions";
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from "react-redux";
const Empresas = ({
    validator,
    listEmpresas,
    loadingListEmpresas,
    getListEmpresas,
    handleChangeSelect,
    fields,
    validFields,
}) => {
    //VARIÁVEIS
    const optionsEmpresas = useMemo(() =>
        listEmpresas.data.map(({ id, emp_nm_empresa, emp_cd_empresa }, key) =>
        ({
            name: `${emp_cd_empresa} - ${emp_nm_empresa}`,
            value: key,
            principal: 0,
            id,
            emp_cd_empresa
        })),
        [listEmpresas.data]);
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6> Empresas</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Selecione as Empresas*</Label>
                                <MultiSelect
                                    data={optionsEmpresas}
                                    onSearch={(value) => {
                                        const filter = optionsEmpresas.filter(({ name }) => new RegExp(`${value}`, 'i').test(name));
                                        if (!filter.length) {
                                            getListEmpresas({ fields: { name: value } });
                                        }
                                    }}
                                    labelKey="name"
                                    onChange={(selected) => handleChangeSelect('empresas', selected)}
                                    isLoading={loadingListEmpresas}
                                    pagination
                                    onPaginate={(offset, name) => {
                                        getListEmpresas({ fields: { offset, name } })
                                    }}
                                />
                                <FormFeedback style={{
                                    display: validator.visibleFields.includes('empresas') ||
                                        (!validator.fields.empresas && validator.messagesShown)
                                        ? 'block' : 'none'
                                }}>
                                    {validator.message('empresas', fields.empresas.toString(), `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Empresa Padrão*</Label>
                                <Typeahead
                                    id="selectedEmpPadrao"
                                    labelKey="name"
                                    clearButton
                                    options={fields.empresas}
                                    placeholder="Selecione a Empresa Padrão"
                                    onBlur={() => validFields('empresa_padrao')}
                                    isInvalid={
                                        validator.visibleFields.includes('empresa_padrao') ||
                                        (!validator.fields.empresa_padrao && validator.messagesShown)
                                    }
                                    emptyLabel="Selecione empresas acima"
                                    onChange={(selected) => {
                                        const empresas = fields.empresas.map((el) => {
                                            const res = el.value === selected[0]?.value ? { ...el, principal: "1" } : { ...el, principal: "0" };
                                            return res;
                                        });
                                        handleChangeSelect('empresa_padrao', empresas)
                                    }}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('empresa_padrao') ||
                                            (!validator.fields.empresa_padrao && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('empresa_padrao', fields.empresas.find((el) => el.principal === "1"), `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}
const mapStateToProps = ({ Empresas }) => {
    const { list: listEmpresas, loadingList: loadingListEmpresas } = Empresas;

    return { listEmpresas, loadingListEmpresas };
}
export default connect(mapStateToProps, {
    getListEmpresas
})(Empresas);