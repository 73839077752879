import { takeLatest, all, fork, call, put, select } from "redux-saga/effects";
import {
    GET_CUSTOS_PRODUTOS_ASYNC,
    GET_CUSTOS_PRODUTOS,
    STORE_CUSTO_PRODUTO_ASYNC,
    STORE_CUSTO_PRODUTO,
    UPDATE_CUSTO_PRODUTO_ASYNC,
    UPDATE_CUSTO_PRODUTO,
    DESTROY_CUSTO_PRODUTO_ASYNC,
    DESTROY_CUSTO_PRODUTO
} from "../actionTypes";
import Api from "../../services/api";
import { toast } from 'react-toastify';
import { selectorCustoProduto } from "../selectors";

function* getCustosProdutosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiCustoProdutos, fields);

        if (data.ok) {
            const { custosProdutos } = data;
            yield put({ type: GET_CUSTOS_PRODUTOS, payload: { custosProdutos } });
        }
    } catch (error) {
        yield put({ type: GET_CUSTOS_PRODUTOS, payload: {} })
    }
}
const apiCustoProdutos = async fields => {
    const { data } = await Api.get(`/api/cmv_diario`, { params: fields });

    return data;
}

function* storeCustoProdutoSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStoreCustoProduto, fields);
        if (!!data && data.ok) {
            const { custosProdutos } = data;
            yield put({ type: STORE_CUSTO_PRODUTO, payload: { custosProdutos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_CUSTO_PRODUTO, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_CUSTO_PRODUTO, payload: {} });
    }
}
const apiStoreCustoProduto = async fields => {
    const data = await Api.post(`/api/cmv_diario/store`, fields);

    return data;
}

function* updateCustoProdutoSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;
        const custosProdutos = { ...yield select(selectorCustoProduto) };
        const id = custosProdutos.data[index].cmv_id;
        const { data, errors } = yield call(apiUpdateCustoProduto, fields, id);
        if (!!data && data.ok) {
            custosProdutos.data.splice(index, 1, data.custoProduto);
            yield put({ type: UPDATE_CUSTO_PRODUTO, payload: { custosProdutos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: UPDATE_CUSTO_PRODUTO, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: UPDATE_CUSTO_PRODUTO, payload: {} });
    }
}
const apiUpdateCustoProduto = async (fields, id) => {

    const data = await Api.put(`/api/cmv_diario/update/${id}`, fields);

    return data;
}

function* destroyCustoProdutoSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyCustoProduto, fields);
        if (data.ok) {
            const { custosProdutos } = data;
            yield put({ type: DESTROY_CUSTO_PRODUTO, payload: { custosProdutos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_CUSTO_PRODUTO, payload: {} });
    }
}
const apiDestroyCustoProduto = async fields => {
    const { data } = await Api.delete(`/api/cmv_diario/destroy`, { data: fields });

    return data;
}

function* watchGetCustosProdutos() {
    yield takeLatest(GET_CUSTOS_PRODUTOS_ASYNC, getCustosProdutosSaga);
}
function* watchStoreCustoProduto() {
    yield takeLatest(STORE_CUSTO_PRODUTO_ASYNC, storeCustoProdutoSaga);
}
function* watchUpdateCustoProduto() {
    yield takeLatest(UPDATE_CUSTO_PRODUTO_ASYNC, updateCustoProdutoSaga);
}
function* watchDestroyCustoProduto() {
    yield takeLatest(DESTROY_CUSTO_PRODUTO_ASYNC, destroyCustoProdutoSaga);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetCustosProdutos),
        fork(watchStoreCustoProduto),
        fork(watchUpdateCustoProduto),
        fork(watchDestroyCustoProduto)
    ]);
}