import {
    GET_CUSTOS_PRODUTOS_ASYNC,
    STORE_CUSTO_PRODUTO_ASYNC,
    UPDATE_CUSTO_PRODUTO_ASYNC,
    DESTROY_CUSTO_PRODUTO_ASYNC
} from "../actionTypes";

export const getCustosProdutos = (payload = {}) => ({
    type: GET_CUSTOS_PRODUTOS_ASYNC,
    payload
});

export const storeCustoProduto = payload => ({
    type: STORE_CUSTO_PRODUTO_ASYNC,
    payload
});

export const updateCustoProduto = payload => ({
    type: UPDATE_CUSTO_PRODUTO_ASYNC,
    payload
});

export const destroyCustoProduto = payload => ({
    type: DESTROY_CUSTO_PRODUTO_ASYNC,
    payload
});