import React, { useState, useRef, useCallback } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    ModalHeader,
    Row,
} from "reactstrap";
import { connect } from "react-redux";
import {
    getListEstados,
    getListCidades,
    storePessoas,
    getListTiposPessoas,
    updatePessoas,
    getListEmpresas,
    getListContas
} from "../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import DadosGerais from "./DadosGerais";
import Opcoes from "./Opcoes";
import Contas from "./Contas";
import Endereco from "./Endereco";
import Valores from "./Valores";
const Edit = ({
    isOpen,
    clickClose,
    selectedPessoa,
    getListEstados,
    getListCidades,
    getListTiposPessoas,
    updatePessoas,
    pessoas,
    loadingUpdate,
    getListEmpresas,
    getListContas,
    errors
}) => {
    //ESTADOS
    const [pessoa, setPessoa] = useState(null);
    const [, updateState] = useState();

    //VARIÁVEIS

    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const id = pessoas.data[selectedPessoa].id;
            updatePessoas({
                fields: pessoa,
                onUpdate: clickClose,
                index: selectedPessoa,
                id
            });
        } else {
            validator.showMessages();
            toast.error('Preencha todos os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }

    }
    const clearErrorsForm = (name) => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleChange = e => {
        const { name, value, checked } = e.target;
        switch (name) {
            case 'pss_nu_cep':
                setPessoa({ ...pessoa, [name]: value.replace('-', '') });
                break;
            case 'pss_nu_telefone':
                setPessoa({ ...pessoa, [name]: value.replace(/\D/g, "") })
                break;
            case 'pss_cd_pessoa':
            case 'pss_cd_conta_contabil':
                setPessoa({ ...pessoa, [name]: value.replace(' ', '') });
                break;
            case 'pss_st_senha_desc':
            case 'pss_st_senha_juro':
                checked ? setPessoa({ ...pessoa, [name]: value }) : setPessoa({ ...pessoa, [name]: "" });
                break;
            default:
                setPessoa({ ...pessoa, [name]: value });
                break;
        }
        clearErrorsForm(name)
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'pss_estado_id':
                if (!!selected.length) {
                    setPessoa({ ...pessoa, [name]: selected[0].value });
                    getListCidades({ estado_id: selected[0].value })
                } else {
                    setPessoa({ ...pessoa, [name]: "", pss_cidade_id: "" })
                }
                break;
            case 'contas':
                setPessoa({ ...pessoa, [name]: selected });
                break;
            default:
                if (!!selected.length) {
                    setPessoa({ ...pessoa, [name]: selected[0].value });
                } else {
                    setPessoa({ ...pessoa, [name]: "" });
                }
                break;
        }
        clearErrorsForm(name)
    }
    const handleValores = (name, value) => {
        setPessoa({ ...pessoa, [name]: value });
        clearErrorsForm(name)
    }
    const onOpenModal = () => {
        const dados = { ...pessoas.data[selectedPessoa] };
        getListEstados();
        getListTiposPessoas();
        getListEmpresas({ fields: { codigo: dados.pss_cd_empresa } });
        getListContas();
        delete dados.id;
        delete dados.empresa;
        delete dados.tipo;
        delete dados.estado;
        delete dados.cidade;
        delete dados.row_num;
        !!dados.pss_estado_id && getListCidades({ estado_id: dados.pss_estado_id })
        setPessoa(dados);
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
    })).current;

    return (
        <Modal isOpen={isOpen}
            onOpened={onOpenModal}
            onClosed={() => {
                setPessoa(null);
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}
            size="lg"
        >
            <ModalHeader toggle={() => !loadingUpdate && clickClose()}>
                Editar Pessoa
            </ModalHeader>
            {!!pessoa &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <DadosGerais
                                errors={errors}
                                handleChange={handleChange}
                                pessoa={pessoa}
                                handleChangeSelect={handleChangeSelect}
                                validFields={validFields}
                                validator={validator}
                            />
                            <Opcoes
                                errors={errors}
                                handleChange={handleChange}
                                pessoa={pessoa}
                                validFields={validFields}
                                validator={validator}
                            />
                            <Contas
                                handleChangeSelect={handleChangeSelect}
                                pessoa={pessoa}
                            />
                            <Endereco
                                errors={errors}
                                handleChange={handleChange}
                                handleChangeSelect={handleChangeSelect}
                                pessoa={pessoa}
                                validFields={validFields}
                                validator={validator}
                            />
                            <Valores
                                errors={errors}
                                handleValores={handleValores}
                                pessoa={pessoa}
                                validFields={validFields}
                                validator={validator}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={() => clickClose()} disabled={loadingUpdate}>
                            Fechar
                        </Button>
                        <Button type="submit" disabled={loadingUpdate} color="primary">
                            {loadingUpdate ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Estados, Cidades, Pessoas, TiposPessoas, Empresas, Contas }) => {
    const { list: listEstados, loadingList: loadingListEstados } = Estados;
    const { list: listEmpresas, loadingList: loadingListEmpresas } = Empresas;
    const { list: listContas, loadingList: loadingListContas } = Contas;
    const { list: listTipoPessoas, loadingList: loadingListTipoPessoas } = TiposPessoas;
    const { pessoas, loadingUpdate, errors } = Pessoas;
    const { list: listCidades, loadingList: loadingListCidades } = Cidades;
    return {
        listEstados,
        loadingListEstados,
        listCidades,
        loadingListCidades,
        listTipoPessoas,
        loadingListTipoPessoas,
        pessoas,
        loadingUpdate,
        listEmpresas,
        loadingListEmpresas,
        listContas,
        loadingListContas,
        errors
    };
}

export default connect(mapStateToProps, {
    getListEstados,
    getListCidades,
    storePessoas,
    getListTiposPessoas,
    updatePessoas,
    getListEmpresas,
    getListContas
})(Edit);
