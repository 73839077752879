import {
    GET_FATURAS_SEM_BAIXAS_ASYNC,
    BAIXAR_FATURAS_ASYNC,
    GET_FATURAS_CONCILAR_ASYNC,
    CONCILIAR_FATURAS_ASYNC,
    CLEAR_CONCILIAR_FATURAS,
    CHANGE_CONCILIAR_FATURAS,
    CONCILAR_FATURAS_AUTO_ASYNC
} from "../actionTypes";

export const getFaturasSemBaixa = (payload = {}) => ({
    type: GET_FATURAS_SEM_BAIXAS_ASYNC,
    payload
});

export const baixarFaturas = payload => ({
    type: BAIXAR_FATURAS_ASYNC,
    payload
});

export const getFaturasConcilar = payload => ({
    type: GET_FATURAS_CONCILAR_ASYNC,
    payload
});

export const conciliarFaturas = payload => ({
    type: CONCILIAR_FATURAS_ASYNC,
    payload
});

export const concilarFaturasAuto = payload => ({
    type: CONCILAR_FATURAS_AUTO_ASYNC,
    payload
});

export const changeConcilarFaturas = payload => ({
    type: CHANGE_CONCILIAR_FATURAS,
    payload
})

export const clearConciliarFaturas = () => ({
    type: CLEAR_CONCILIAR_FATURAS
});