import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label, Button, Form, Alert, Input } from 'reactstrap';
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { getRltPlanoContas } from "../../../../redux/actions";
import { format } from 'date-fns';
import PrintComponent from "../../../../components/PrintComponent";
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

const initialColums = [
    {
        name: 'Código',
        selector: 'ctn_cd_conta',
        width: '10%',
    },
    {
        name: 'Conta',
        selector: 'ctn_nm_conta',
    },
    {
        name: 'Pagamento Cheque',
        selector: 'pag_cheque',
        width: '10%',
    },
    {
        name: 'Boleto',
        selector: 'boleto',
        width: '10%',
    },
    {
        name: 'Histórico',
        selector: 'hist_padrao',
    },
];
const RelGerencialPlanoContas = ({
    active_user_empresa,
    loadingRltPlanoDeContas,
    rltPlanoDeContas,
    getRltPlanoContas
}) => {

    //REF
    const printCompRef = useRef();

    //ESTADOS
    const [modelo, setdModelo] = useState(null);
    const [colums, setColums] = useState([...initialColums]);
    const [curModelo, setCurModelo] = useState(null);
    const [loadingPrint, setLoadingPrint] = useState(false);

    //VARIÁVEIS
    const optionsModelo = useMemo(() => ([{ name: 'Padrão', value: 'padrao' }, { name: 'Completo', value: 'completo' }]), []);


    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Gerencial Plano de Contas ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        onBeforePrint: () => setLoadingPrint(true),
        onAfterPrint: () => setLoadingPrint(false),
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });

    const handleModelo = selected => {
        !!selected.length ? setdModelo(selected[0].value) : setdModelo(null);
    }
    const handleFilter = e => {
        e.preventDefault();
        getRltPlanoContas({ fields: { modelo } });
        if (modelo === "padrao") {
            setColums(colums.splice(0, 2));
        } else {
            setColums([...initialColums]);
        }
        setCurModelo(modelo)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title="Plano de Contas" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={handleFilter}>
                                    <Row>
                                        <Col md="11" xs="12">
                                            <FormGroup>
                                                <Label>Modelo</Label>
                                                <Typeahead
                                                    id="selectedModelo"
                                                    labelKey="name"
                                                    placeholder="Informe o Modelo"
                                                    clearButton
                                                    options={optionsModelo}
                                                    onChange={handleModelo}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingRltPlanoDeContas || !active_user_empresa}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                {loadingRltPlanoDeContas ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!Object.entries(rltPlanoDeContas).length ?
                                        <>
                                            <div className="m-b-10 d-flex justify-content-end">
                                                <Button disabled={loadingPrint} size="sm" onClick={handlePrint} className="f-16">
                                                    <i className="fa fa-print"></i>
                                                    <span className="p-l-5">
                                                        Imprimir
                                                    </span>
                                                </Button>
                                            </div>
                                            <PrintComponent ref={printCompRef}>
                                                <Row>
                                                    <Col xs="12" className="m-b-10 d-flex justify-content-center">
                                                        <span className="f-20">
                                                            {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - Plano de Contas - Modelo ${curModelo === "completo" ? 'Completo' : 'Padrão'}`}
                                                        </span>
                                                    </Col>
                                                    {Object.entries(rltPlanoDeContas).map((elem, key) => {
                                                        return (
                                                            <Col xs="12" className="m-b-15" key={key}>
                                                                <div className="b-light p-20">
                                                                    <h5 className="f-w-700 ">{elem[0]}</h5>
                                                                    <Row>
                                                                        {Object.entries(elem[1]).map((rows, index) => {
                                                                            return (
                                                                                <Col xs="12" key={index}>
                                                                                    <DataTable
                                                                                        title={<span className="m-0 pull-left f-18">{rows[0]}</span>}
                                                                                        columns={colums}
                                                                                        striped
                                                                                        data={rows[1].sort((a, b) => {
                                                                                            return a.ctn_cd_conta - b.ctn_cd_conta;
                                                                                        }).map(({
                                                                                            ctn_cd_conta,
                                                                                            ctn_nm_conta,
                                                                                            ctn_st_pag_cheque,
                                                                                            ctn_st_boleto,
                                                                                            ctn_cd_hist_padrao,
                                                                                            ctn_ds_hist_padrao
                                                                                        },
                                                                                            index
                                                                                        ) => {
                                                                                            const pag_cheque = !!ctn_st_pag_cheque && ctn_st_pag_cheque === "S" ?
                                                                                                <Label className="d-block" for={`chk-cheque${index}`}>
                                                                                                    <Input
                                                                                                        className="checkbox_animated"
                                                                                                        id={`chk-cheque${index}`}
                                                                                                        type="checkbox"
                                                                                                        disabled
                                                                                                        defaultChecked />
                                                                                                </Label>
                                                                                                :
                                                                                                <Label className="d-block" for={`chk-cheque${index}`}>
                                                                                                    <Input
                                                                                                        className="checkbox_animated"
                                                                                                        id={`chk-cheque${index}`}
                                                                                                        type="checkbox"
                                                                                                        disabled />
                                                                                                </Label>
                                                                                            const boleto = !!ctn_st_boleto && ctn_st_boleto === "S" ?
                                                                                                <Label className="d-block" for={`chk-boleto${index}`}>
                                                                                                    <Input
                                                                                                        className="checkbox_animated"
                                                                                                        id={`chk-boleto${index}`}
                                                                                                        type="checkbox"
                                                                                                        disabled
                                                                                                        defaultChecked />
                                                                                                </Label>
                                                                                                :
                                                                                                <Label className="d-block" for={`chk-boleto${index}`}>
                                                                                                    <Input
                                                                                                        className="checkbox_animated"
                                                                                                        id={`chk-boleto${index}`}
                                                                                                        type="checkbox"
                                                                                                        disabled
                                                                                                        defaultChecked />
                                                                                                </Label>
                                                                                            const hist_padrao = (!!ctn_cd_hist_padrao && !!ctn_ds_hist_padrao) && `${ctn_cd_hist_padrao} - ${ctn_ds_hist_padrao}`
                                                                                            return ({ ctn_cd_conta, ctn_nm_conta, pag_cheque, boleto, hist_padrao })
                                                                                        })}
                                                                                    />
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </PrintComponent>
                                            <div className="m-t-10 d-flex justify-content-end">
                                                <Button disabled={loadingPrint} size="sm" onClick={handlePrint} className="f-16">
                                                    <i className="fa fa-print"></i>
                                                    <span className="p-l-5">
                                                        Imprimir
                                                    </span>
                                                </Button>
                                            </div>
                                        </>
                                        :
                                        <Alert color="light">Informe o modelo acima</Alert>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Contas, Auth, RltGerenciais }) => {
    const { loadingRltPlanoDeContas, rltPlanoDeContas } = RltGerenciais;
    const { active_user_empresa } = Auth;
    return { active_user_empresa, loadingRltPlanoDeContas, rltPlanoDeContas };
}

export default connect(mapStateToProps, {
    getRltPlanoContas
})(RelGerencialPlanoContas);