import {
    GET_FATURAS_SEM_BAIXAS_ASYNC,
    GET_FATURAS_SEM_BAIXAS,
    BAIXAR_FATURAS_ASYNC,
    BAIXAR_FATURAS,
    GET_FATURAS_CONCILAR_ASYNC,
    GET_FATURAS_CONCILAR,
    CONCILIAR_FATURAS_ASYNC,
    CONCILIAR_FATURAS,
    CHANGE_CONCILIAR_FATURAS,
    CLEAR_CONCILIAR_FATURAS,
    CONCILAR_FATURAS_AUTO_ASYNC,
    CONCILAR_FATURAS_AUTO
} from "../actionTypes";

const initial_state = {
    ftr_sem_baixa: {},
    loadingFtrSemBaixa: false,
    loadingBaixarFtr: false,
    loadingGetFtrConcilar: false,
    loadingConciliarFtr: false,
    ftrParaConcilar: [],
    ftrsConcAuto: []
}

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_FATURAS_SEM_BAIXAS_ASYNC:
            return { ...state, loadingFtrSemBaixa: true };
        case GET_FATURAS_SEM_BAIXAS:
            return { ...state, ...action.payload, loadingFtrSemBaixa: false };
        case BAIXAR_FATURAS_ASYNC:
            return { ...state, loadingBaixarFtr: true };
        case BAIXAR_FATURAS:
            return { ...state, ...action.payload, loadingBaixarFtr: false };
        case GET_FATURAS_CONCILAR_ASYNC:
            return { ...state, loadingGetFtrConcilar: true };
        case GET_FATURAS_CONCILAR:
            return { ...state, ...action.payload, loadingGetFtrConcilar: false };
        case CONCILIAR_FATURAS_ASYNC:
            return { ...state, loadingConciliarFtr: true };
        case CONCILIAR_FATURAS:
            return { ...state, ...action.payload, loadingConciliarFtr: false };
        case CHANGE_CONCILIAR_FATURAS:
            return { ...state, ftrParaConcilar: [...action.payload] };
        case CONCILAR_FATURAS_AUTO_ASYNC:
            return { ...state, loadingConciliarFtr: true };
        case CONCILAR_FATURAS_AUTO:
            return { ...state, ...action.payload, loadingConciliarFtr: false };
        case CLEAR_CONCILIAR_FATURAS:
            return { ...state, ftrParaConcilar: [] };
        default: return state;
    }
}