import React from "react";
import { Col, FormGroup, Label, Input, FormFeedback, Row } from "reactstrap";
import { connect } from "react-redux";

const Opcoes = ({
    handleChange,
    pessoa,
    errors,
    validFields,
    validator
}) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Opções</h6></Label>
                    <Row>
                        <Col md="3" xs="12">
                            <Label>Imprimir no Ativo*</Label>
                            <FormGroup className="m-checkbox-inline mb-10 custom-radio">
                                <div className="radio radio-primary">
                                    <Input
                                        id="radioinline1"
                                        className="radio_animated"
                                        type="radio"
                                        defaultChecked={pessoa.pss_st_imp_ativo === "1"}
                                        name="pss_st_imp_ativo"
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        onChange={handleChange}
                                        value="1" />
                                    <Label className="mb-0 mr-0" for="radioinline1">Sim</Label>
                                </div>
                                <div className="radio radio-primary">
                                    <Input
                                        id="radioinline2"
                                        className="radio_animated"
                                        type="radio"
                                        name="pss_st_imp_ativo"
                                        defaultChecked={pessoa.pss_st_imp_ativo === "0"}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        onChange={handleChange}
                                        value="0" />
                                    <Label className="mb-0 mr-0" for="radioinline2">Não</Label>
                                </div>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_st_imp_ativo') ||
                                            !!errors.pss_st_imp_ativo ||
                                            (!validator.fields.pss_st_imp_ativo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_st_imp_ativo', pessoa.pss_st_imp_ativo, 'required')}
                                    {(!!errors.pss_st_imp_ativo) && errors.pss_st_imp_ativo.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3" xs="12">
                            <Label>Confiavel para fluxo*</Label>
                            <FormGroup className="m-checkbox-inline mb-10 custom-radio">
                                <div className="radio radio-primary">
                                    <Input
                                        id="radioinline3"
                                        className="radio_animated"
                                        type="radio"
                                        name="pss_st_conf_fluxo"
                                        defaultChecked={pessoa.pss_st_conf_fluxo === "1"}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        onChange={handleChange}
                                        value="1" />
                                    <Label className="mb-0 mr-0" for="radioinline3">Sim</Label>
                                </div>
                                <div className="radio radio-primary">
                                    <Input
                                        id="radioinline4"
                                        className="radio_animated"
                                        type="radio"
                                        name="pss_st_conf_fluxo"
                                        defaultChecked={pessoa.pss_st_conf_fluxo === "0"}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        onChange={handleChange}
                                        value="0" />
                                    <Label className="mb-0 mr-0" for="radioinline4">Não</Label>
                                </div>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_st_imp_ativo') ||
                                            !!errors.pss_st_conf_fluxo ||
                                            (!validator.fields.pss_st_conf_fluxo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_st_conf_fluxo', pessoa.pss_st_conf_fluxo, 'required')}
                                    {(!!errors.pss_st_conf_fluxo) && errors.pss_st_conf_fluxo.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="5" xs="12">
                            <Label>Controla valor duplicado lançamentos*</Label>
                            <FormGroup className="m-checkbox-inline mb-10 custom-radio">
                                <div className="radio radio-primary">
                                    <Input
                                        id="radioinline5"
                                        className="radio_animated"
                                        type="radio"
                                        name="pss_st_ctr_vlr_dup"
                                        defaultChecked={pessoa.pss_st_ctr_vlr_dup === "1"}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        onChange={handleChange}
                                        value="1" />
                                    <Label className="mb-0 mr-0" for="radioinline5">Sim</Label>
                                </div>
                                <div className="radio radio-primary">
                                    <Input
                                        id="radioinline6"
                                        className="radio_animated"
                                        type="radio"
                                        name="pss_st_ctr_vlr_dup"
                                        defaultChecked={pessoa.pss_st_ctr_vlr_dup === "0"}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        onChange={handleChange}
                                        value="0" />
                                    <Label className="mb-0 mr-0" for="radioinline6">Não</Label>
                                </div>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_st_ctr_vlr_dup') ||
                                            !!errors.pss_st_ctr_vlr_dup ||
                                            (!validator.fields.pss_st_ctr_vlr_dup && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_st_ctr_vlr_dup', pessoa.pss_st_ctr_vlr_dup, 'required')}
                                    {(!!errors.pss_st_ctr_vlr_dup) && errors.pss_st_ctr_vlr_dup.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <Label>Senha para baixa</Label>
                            <FormGroup className="m-checkbox-inline mb-10 custom-radio-ml">
                                <div className="checkbox checkbox-dark m-r-50">
                                    <Input
                                        id="inline-1"
                                        type="checkbox"
                                        name="pss_st_senha_desc"
                                        defaultChecked={pessoa.pss_st_senha_desc === "S"}
                                        value="S"
                                        onChange={handleChange} />
                                    <Label for="inline-1">Em descontos</Label>
                                </div>
                                <div className="checkbox checkbox-dark m-r-50">
                                    <Input
                                        id="inline-2"
                                        type="checkbox"
                                        name="pss_st_senha_juro"
                                        defaultChecked={pessoa.pss_st_senha_juro === "S"}
                                        value="S"
                                        onChange={handleChange} />
                                    <Label for="inline-2">Ao alterar para juros</Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    );
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Opcoes)