import React, { Fragment } from 'react';
import { Alert, Card, CardBody, Table, Col, Row, Button } from 'reactstrap';
import { connect } from "react-redux";
import PrintComponent from "../../../../components/PrintComponent";
import { format, parseISO } from "date-fns";

function RltMovimentacao({
    loadingRltMovimentacao,
    rltMovimentacao,
    filter,
    printCompRef,
    active_user_empresa,
    handlePrint
}) {
    const {
        totalDebitoGpRel1,
        totalCreditoGpRel1,
        totaAtivPer,
        saldoAnt,
        saldoAcuAtiCaixa,
        totalDebitoGpRel2,
        totalCreditoGpRel2
    } = rltMovimentacao.totais;
    const renderRowsGpRel1 = data => {
        return Object.keys(data).map((movName, indexMov) => {
            const movimentacao = data[movName];
            const { color, margin } = movimentacao;
            return (
                <Fragment key={indexMov}>
                    <tr>
                        <td style={{ color }}>
                            <span style={{ marginLeft: margin }}>{movName.toUpperCase()}</span>
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(movimentacao.totalCredito)}
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(movimentacao.totalDebito)}
                        </td>
                    </tr>
                    {!!movimentacao.data &&
                        renderRowsGpRel1(movimentacao.data)
                    }
                </Fragment>
            )
        })
    }
    const renderRowsGpRel2 = data => {
        let movs = data;
        if (filter.tipoExibir === "diff") {
            movs = {};
            Object.keys(data).forEach((movName) => {
                const movimentacao = data[movName];
                const saldoMov = parseFloat(movimentacao.totalDebito) - parseFloat(movimentacao.totalCredito);
                const saldoAtual = parseFloat(movimentacao.saldoAnterior) + saldoMov;
                if (saldoAtual !== 0) {
                    movs[movName] = data[movName];
                }
            });

        }
        return Object.keys(movs).map((movName, indexMov) => {
            const movimentacao = data[movName];
            const { color, margin } = movimentacao;
            const saldoMov = parseFloat(movimentacao.totalDebito) - parseFloat(movimentacao.totalCredito);
            const saldoAtual = parseFloat(movimentacao.saldoAnterior) + saldoMov;
            return (
                <Fragment key={indexMov}>
                    <tr>
                        <td style={{ color }}>
                            <span style={{ marginLeft: margin }}>{movName.toUpperCase()}</span>
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(movimentacao.saldoAnterior)}
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(movimentacao.totalDebito)}
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(movimentacao.totalCredito)}
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoMov)}
                        </td>
                        <td style={{ textAlign: 'right', color }}>
                            {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAtual)}
                        </td>
                    </tr>
                    {!!movimentacao.data &&
                        renderRowsGpRel2(movimentacao.data)
                    }
                </Fragment>
            )
        })
    }
    return (
        <Card >
            <CardBody>
                {
                    loadingRltMovimentacao ?
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                        :
                        <>
                            {(Array.isArray(rltMovimentacao.data) && !!rltMovimentacao.data.length) ||
                                (!Array.isArray(rltMovimentacao.data) && rltMovimentacao.data instanceof Object) &&
                                <div className="m-b-10 d-flex justify-content-end">
                                    <Button size="sm" onClick={handlePrint} className="f-16">
                                        <i className="fa fa-print"></i>
                                        <span className="p-l-5">
                                            Imprimir
                                    </span>
                                    </Button>
                                </div>
                            }

                            <PrintComponent ref={printCompRef}>
                                {(Array.isArray(rltMovimentacao.data) && !!rltMovimentacao.data.length) ||
                                    (!Array.isArray(rltMovimentacao.data) && rltMovimentacao.data instanceof Object) &&
                                    <div className="d-flex justify-content-start font-arial-rlt">
                                        <span>
                                            {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - 
                                        Movimentação por ${filter.tipoRegime === "caixa" ? 'Caixa' : 'Regime'} 
                                        ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })} 
                                        ${format(parseISO(filter.dataInicial), 'dd/MM/yy')} a 
                                        ${new Date(filter.dataFinal).toLocaleString([], { weekday: 'short' })} 
                                        ${format(parseISO(filter.dataFinal), 'dd/MM/yy')}`}
                                        </span>
                                    </div>
                                }
                                <Row className="p-15 m-b-20">
                                    <Col xs="12">
                                        {filter.exibirAtivoCttAgrupando === "N" &&
                                            filter.exibirAtivoGruposConta === "N" &&
                                            filter.exibirAtivoContas === "N" &&
                                            filter.exibirAtivoPessoas === "N" &&
                                            filter.exibirRecDesGruposConta === "N" &&
                                            filter.exibirRecDesContas === "N"
                                            ?
                                            <Table className="font-arial-rlt">
                                                <tbody>
                                                    <tr className="b-dark border-2">
                                                        <td>Saldo do Período Anterior</td>
                                                        <td></td>
                                                        <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAnt)}</td>
                                                    </tr>
                                                    <tr className="b-dark border-2">
                                                        <td>Saldo Acumulado do Ativo de Caixa</td>
                                                        <td></td>
                                                        <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAcuAtiCaixa)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            : !Array.isArray(rltMovimentacao.data) ?
                                                <>
                                                    {/* RENDERIZANDO PRIMEIRA PARTE DO RELATÓRIO */}
                                                    <Table className="font-arial-rlt">
                                                        <thead>
                                                            <tr>
                                                                <th className="b-light border-3">
                                                                    {filter.exibirAtivoCttAgrupando === "S" &&
                                                                        <span className="m-r-50" style={{ color: 'darkgreen' }}>Centros de Custo</span>
                                                                    }
                                                                    {filter.exibirAtivoGruposConta === "S" &&
                                                                        <span className="m-r-50" style={{ color: 'darkblue' }}>Grupo</span>
                                                                    }
                                                                    {filter.exibirAtivoContas === "S" &&
                                                                        <span className="m-r-50">Conta</span>
                                                                    }
                                                                    {filter.exibirAtivoPessoas === "S" &&
                                                                        <span className="m-r-50" style={{ color: 'darkred' }}>Pessoa</span>
                                                                    }
                                                                </th>
                                                                <th className="b-light border-3" style={{ width: 300, textAlign: 'center' }}>Crédito</th>
                                                                <th className="b-light border-3" style={{ width: 300, textAlign: 'center' }}>Débito</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !!rltMovimentacao.data.gpRel1 && renderRowsGpRel1(rltMovimentacao.data.gpRel1)
                                                            }
                                                            <tr className="b-dark border-2">
                                                                <td>Totais</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalCreditoGpRel1)}</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalDebitoGpRel1)}</td>
                                                            </tr>
                                                            <tr className="b-dark border-2">
                                                                <td>Saldo do Período</td>
                                                                <td></td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalCreditoGpRel1 - totalDebitoGpRel1)}</td>
                                                            </tr>
                                                            <tr className="b-dark border-2">
                                                                <td>Total do Ativo de Caixa do Período</td>
                                                                <td></td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totaAtivPer)}</td>
                                                            </tr>
                                                            <tr className="b-dark border-2">
                                                                <td>Saldo do Período Anterior</td>
                                                                <td></td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAnt)}</td>
                                                            </tr>
                                                            <tr className="b-dark border-2">
                                                                <td>Saldo Acumulado do Ativo de Caixa</td>
                                                                <td></td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAcuAtiCaixa)}</td>
                                                            </tr>
                                                            <tr className="b-dark border-2">
                                                                <td colSpan="3" style={{ textAlign: 'center' }}>Saldo do Ativo de Caixa Discriminado</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    {/* RENDERIZANDO SEGUNDA PARTE DO RELATÓRIO */}
                                                    <Table className="font-arial-rlt">
                                                        <thead>
                                                            <tr>
                                                                <th className="b-light border-3">
                                                                    {filter.exibirRecDesGruposConta === "S" &&
                                                                        <span className="m-r-50" style={{ color: 'darkblue' }}>Grupo</span>
                                                                    }
                                                                    {filter.exibirRecDesContas === "S" &&
                                                                        <span className="m-r-50">Conta</span>
                                                                    }
                                                                    {filter.exibirRecDesPessoas === "S" &&
                                                                        <span className="m-r-50" style={{ color: 'darkred' }}>Pessoa</span>
                                                                    }
                                                                </th>
                                                                <th className="b-light border-3">Saldo</th>
                                                                <th className="b-light border-3">Débito</th>
                                                                <th className="b-light border-3">Crédito</th>
                                                                <th className="b-light border-3">Saldo do Mov</th>
                                                                <th className="b-light border-3">Saldo Atual</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {!!rltMovimentacao.data.gpRel2 && renderRowsGpRel2(rltMovimentacao.data.gpRel2)}
                                                            <tr className="b-dark border-2">
                                                                <td>Total Geral do Ativo</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAnt)}</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalDebitoGpRel2)}</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalCreditoGpRel2)}</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalDebitoGpRel2 - totalCreditoGpRel2)}</td>
                                                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(saldoAnt + (totalDebitoGpRel2 - totalCreditoGpRel2))}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </>
                                                :
                                                <Alert color="light">Nenhum registro encontrado</Alert>
                                        }
                                    </Col>
                                </Row>
                            </PrintComponent>
                        </>
                }
            </CardBody>
        </Card>
    )
}

const mapStateToProps = ({ RltGerenciais, Auth }) => {
    const { rltMovimentacao, loadingRltMovimentacao } = RltGerenciais;
    const { active_user_empresa } = Auth;
    return { rltMovimentacao, loadingRltMovimentacao, active_user_empresa }
}

export default connect(mapStateToProps, {})(RltMovimentacao);