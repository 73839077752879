import { GET_NOTAS_FISCAIS, GET_NOTAS_FISCAIS_ASYNC, UPDATE_DEVOLUCAO_NFE_ASYNC, UPDATE_DEVOLUCAO_NFE } from "../actionTypes";

const initalState = {
    notasFiscais: null,
    loadingNotasFiscais: false,
    loadingUpdDevolucaoNFe: false,
    totalValorNfe: 0
}

export default (state = initalState, action) => {
    switch (action.type) {
        case GET_NOTAS_FISCAIS_ASYNC:
            return { ...state, loadingNotasFiscais: true };

        case GET_NOTAS_FISCAIS:
            return { ...state, ...action.payload, loadingNotasFiscais: false };

        case UPDATE_DEVOLUCAO_NFE_ASYNC:
            return { ...state, loadingUpdDevolucaoNFe: true };

        case UPDATE_DEVOLUCAO_NFE:
            return { ...state, ...action.payload, loadingUpdDevolucaoNFe: false }

        default: return state;
    }
}