import React from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Col,
    FormGroup,
    Label,
    Input,
    ListGroup,
    ListGroupItem,
    Alert,
    Row
} from "reactstrap";
import ScrollArea from "react-scrollbar";
const ViewGroups = ({ isOpen, clickClose, selectedGroup, groups }) => {
    const dados = !!groups && groups.data[selectedGroup];
    return (
        <Modal isOpen={isOpen} size="lg">
            <ModalHeader toggle={() => clickClose()}>Informações do Perfil</ModalHeader>
            <ModalBody>
                {!!dados &&
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input readOnly value={dados.name} />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Funcionalidades Vinculadas</Label>
                                {!!dados.funcionalidades.length ?
                                    <ScrollArea vertical={true} horizontal={false} >
                                        <ListGroup>
                                            {
                                                dados.funcionalidades.map(({ name }, index) => {
                                                    return (
                                                        <ListGroupItem
                                                            key={index}
                                                            className="list-group-item-action">
                                                            {name}
                                                        </ListGroupItem>
                                                    );
                                                })
                                            }
                                        </ListGroup>
                                    </ScrollArea>
                                    :
                                    <Alert color="light">Nenhuma Funcionalidade Vinculada</Alert>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>Fechar</Button>
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = ({ Groups }) => {
    const { groups } = Groups;
    return { groups };
}
export default connect(mapStateToProps, {})(ViewGroups);