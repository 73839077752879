import {
    GET_RLT_EXTRATO_CONTAS_ASYNC,
    CLEAR_RLT_EXTRATO_CONTAS,
    GET_RLT_EXTRATO_PESSOAS_ASYNC,
    GET_RTL_NAO_RECEBIDOS_ASYNC,
    GET_RLT_NAO_PAGOS_ASYNC,
    CLEAR_RLT_NA0_PAGOS,
    GET_RLT_PESQUISA_ASYNC,
    CLEAR_RLT_PESQUISA,
    GET_RLT_DEB_CRED_ASYNC,
    GET_RLT_CNT_ASYNC,
    GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC,
    CLEAR_RLT_TITULOS_NAO_RECPAGOS
} from "../actionTypes";

export const getRltExtratoContas = (payload = {}) => ({
    type: GET_RLT_EXTRATO_CONTAS_ASYNC,
    payload
});

export const clearRltExtratoContas = () => ({
    type: CLEAR_RLT_EXTRATO_CONTAS,

});

export const getRltExtratoPessoas = (payload = {}) => ({
    type: GET_RLT_EXTRATO_PESSOAS_ASYNC,
    payload
});

export const getRltNaoRecebidos = payload => ({
    type: GET_RTL_NAO_RECEBIDOS_ASYNC,
    payload
});

export const getRltNaoPagos = payload => ({
    type: GET_RLT_NAO_PAGOS_ASYNC,
    payload
});

export const clearRltNaoPagos = () => ({
    type: CLEAR_RLT_NA0_PAGOS
})

export const getRltPesquisa = payload => ({
    type: GET_RLT_PESQUISA_ASYNC,
    payload
});

export const getRltDebCred = payload => ({
    type: GET_RLT_DEB_CRED_ASYNC,
    payload
})

export const clearRltPesquisa = () => ({
    type: CLEAR_RLT_PESQUISA
});

export const getRltCnt = payload => ({
    type: GET_RLT_CNT_ASYNC,
    payload
});

export const getRltTitulosNaoRecPagos = payload => ({
    type: GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC,
    payload
});

export const clearRltTitulosNaoRecPagos = () => ({
    type: CLEAR_RLT_TITULOS_NAO_RECPAGOS
});
