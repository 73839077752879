import { format, parseISO } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { Row, Table } from "reactstrap";

function RltSintetico({ rltFluxoCaixa }) {
    let totalDeb = 0;
    let totalCred = 0;
    return (
        <Row className="b-light p-15 border-5 m-b-20">
            <Table className="font-arial-rlt">
                <thead>
                    <tr>
                        <td className="text-center b-dark">Data</td>
                        <td className="text-center b-dark">Débito</td>
                        <td className="text-center b-dark">Crédito</td>
                        <td className="text-center b-dark">Movimento</td>
                        <td className="text-center b-dark">Saldo</td>
                        <td className="text-center b-dark">Observações</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-right" style={{ color: 'darkblue' }}>Saldo Anterior</td>
                        <td className="text-right" style={{ color: 'darkblue' }}>
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rltFluxoCaixa.saldoAnterior)}
                        </td>

                    </tr>
                    {rltFluxoCaixa.lancamentos.map((lan, index) => {
                        const valor = parseFloat(lan.vla_vl_lancamento);
                        if (lan.vla_tp_lancamento === 'D') {
                            totalDeb += valor;
                        } else if (lan.vla_tp_lancamento === 'C') {
                            totalCred += valor;
                        }
                        return (
                            <tr key={index}>
                                <td className="text-center">{format(parseISO(lan.ftr_dt_vencimento), 'd/MM/yyyy')}</td>
                                <td className="text-right">
                                    {lan.vla_tp_lancamento === 'D' && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lan.vla_vl_lancamento)}
                                </td>
                                <td className="text-right">
                                    {lan.vla_tp_lancamento === 'C' && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lan.vla_vl_lancamento)}
                                </td>
                                <td className="text-right"/>
                                <td className="text-right"/>
                                <td className="text-right">
                                    {lan.vla_ds_historico}
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td className="text-center" style={{ color: 'darkred' }}>Total</td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb)}</td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCred)}</td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb - totalCred)}</td>
                        <td className="text-right" style={{ color: 'darkred' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rltFluxoCaixa.saldoAnterior + (totalDeb - totalCred))}</td>
                        <td className="text-right"/>
                    </tr>
                </tbody>
            </Table>
        </Row>
    )
}

const mapStateToProps = ({ RltGerenciais }) => {
    const { rltFluxoCaixa } = RltGerenciais;
    return { rltFluxoCaixa }
}

export default connect(mapStateToProps, {})(RltSintetico)