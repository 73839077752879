import {
    REGISTER_USER_ASYNC,
    REGISTER_USER,
    GET_USERS_ASYNC,
    GET_USERS,
    CHANGE_STATUS_USER_ASYNC,
    CHANGE_STATUS_USER,
    DESTROY_USERS_ASYNC,
    DESTROY_USERS,
    STORE_USERS_ASYNC,
    STORE_USERS,
    UPDATE_USERS_ASYNC,
    UPDATE_USERS
} from "../actionTypes";


const initial_state = {
    errors: {},
    loadingRegister: false,
    loadingChangeStatus: false,
    loadingDestroy: false,
    loadingUpdate: false,
    loading: false,
    users: null

};

export default (state = initial_state, action) => {
    switch (action.type) {
        case REGISTER_USER_ASYNC:
            return { ...state, loadingRegister: true };
        case REGISTER_USER:
            return { ...state, ...action.payload, loadingRegister: false };
        case GET_USERS_ASYNC:
            return { ...state, loading: true };
        case GET_USERS:
            return { ...state, ...action.payload, loading: false }
        case CHANGE_STATUS_USER_ASYNC:
            return { ...state, loadingChangeStatus: true }
        case CHANGE_STATUS_USER:
            return { ...state, ...action.payload, loadingChangeStatus: false };
        case DESTROY_USERS_ASYNC:
            return { ...state, loadingDestroy: true };
        case DESTROY_USERS:
            return { ...state, ...action.payload, loadingDestroy: false };
        case STORE_USERS_ASYNC:
            return { ...state, loadingStore: true };
        case STORE_USERS:
            return { ...state, ...action.payload, loadingStore: false };
        case UPDATE_USERS_ASYNC:
            return { ...state, loadingUpdate: true };
        case UPDATE_USERS:
            return { ...state, ...action.payload, loadingUpdate: false };
        default: return state;
    }
}
