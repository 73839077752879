import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Button, CardTitle, Form, FormGroup, Label, Input } from 'reactstrap';
import {
    getContas,
    getListPessoas,
    getListContasGrupos,
    changeListGrupos,
    changeListPessoas,
    destroyContas,
} from "../../../../redux/actions";
import DataTable from "react-data-table-component";
import { connect } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import sweetalert2 from "sweetalert2";
import SelectPagination from "../../../../components/SelectPagination";
import View from "./View/";
import Edit from "./Edit/";
import Add from "./Add/";

const Contas = ({
    getContas,
    contas,
    loading,
    getListPessoas,
    getListContasGrupos,
    listGrupos,
    loadingListGrupos,
    listPessoas,
    loadingListPesssoas,
    destroyContas,
    loadingDestroy,
}) => {
    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [filter, setFilter] = useState({
        ctn_nm_conta: null,
        ctn_cd_conta: null,
        ctn_cd_grupo: null,
        ctn_cd_pessoa: null,
        ctn_tp_conta: null,
        ctn_st_contabiliza: null,
        ctn_cd_grupo_pai: null
    });
    const [selectedConta, setSelectedConta] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [curFilter, setCurFilter] = useState(null);

    useEffect(() => {
        getContas();
        getListPessoas();
        getListContasGrupos();
    }, []);

    const optionsPessoas = useMemo(() =>
        listPessoas.data.map(({ id, pss_nm_pessoa, pss_cd_pessoa, pss_nu_identificacao }) => {
            return ({
                value: id,
                name: `${pss_cd_pessoa} - ${pss_nm_pessoa}`,
                subItem: `Num.Identificação: ${pss_nu_identificacao}`,
                pss_cd_pessoa
            })
        }), [listPessoas.data]);

    const optionsGrupos = useMemo(() => {
        return listGrupos.data.map(({ id, grc_cd_grupo, grc_nm_grupo, grupo_pai }) => {
            const name = grc_nm_grupo.trimEnd();
            return (
                {
                    name,
                    value: id,
                    grc_cd_grupo,
                    grupo_pai_name: !!grupo_pai ? grupo_pai.name : "Não informado",
                    cd_grupo_pai: !!grupo_pai ? grupo_pai.cd_grupo_pai : null,
                    subItem: `Grupo Pai: ${!!grupo_pai ? grupo_pai.name : "Não informado"}`
                }
            )
        })
    }, [listGrupos.data]);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, ctn_nm_conta }) => ({ id, ctn_nm_conta })))
    }, []);
    const submitFilter = e => {
        e.preventDefault();
        getContas({ fields: filter })
        setCurFilter(filter);
        setToggleCleared(!toggleCleared)
    }

    const deleteContas = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ ctn_nm_conta }) => {
                return (`<li class="list-group-item-action list-group-item">${ctn_nm_conta}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!contas && contas.current_page !== 1) ? contas.current_page : 1;
            const fields = { contas_ids: selectedRows, page, curFilter };
            const onDelete = () => setToggleCleared(!toggleCleared);
            destroyContas({ fields, onDelete })
        }
    }

    return (
        <>
            <View isOpen={openModal === "view"} clickClose={() => setOpenModal(null)} selectedConta={selectedConta} />
            <Add isOpen={openModal === "add"} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === "edit"} clickClose={() => setOpenModal(null)} selectedConta={selectedConta} />
            <Breadcrumb parent="Plano de Contas" title="Contas" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                        <Button
                            sid="contas.store"
                            color="primary" size="md"
                            onClick={() => setOpenModal('add')}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                        </Button>
                    </Col>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="10" sm="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    name="ctn_nm_conta"
                                                    placeholder="Informe o Nome"
                                                    onChange={({ target: { name, value } }) => setFilter({ ...filter, [name]: value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" sm="12">
                                            <FormGroup>
                                                <Label>Código</Label>
                                                <Input maxLength={3}
                                                    name="ctn_cd_conta"
                                                    placeholder="Informe o código"
                                                    onChange={({ target: { name, value } }) => setFilter({ ...filter, [name]: value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label>Grupo</Label>
                                                <SelectPagination
                                                    id="selectGrupos"
                                                    labelKey={(option) => `${option.name}`}
                                                    maxResults={50}
                                                    placeholder="Selecione o Grupo"
                                                    data={optionsGrupos}
                                                    reduxAction={getListContasGrupos}
                                                    totalResults={listGrupos.total}
                                                    clearButton
                                                    isLoading={loadingListGrupos}
                                                    onChange={(grupo) => {
                                                        if (!!grupo.length) {
                                                            setFilter({
                                                                ...filter,
                                                                ctn_cd_grupo: grupo[0].grc_cd_grupo,
                                                                ctn_cd_grupo_pai: grupo[0].cd_grupo_pai
                                                            })
                                                        } else {
                                                            setFilter({ ...filter, ctn_cd_grupo: null, ctn_cd_grupo_pai: null });
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label>Pessoa</Label>
                                                <SelectPagination
                                                    id="selectPessoa"
                                                    labelKey="name"
                                                    maxResults={50}
                                                    placeholder="Selecione a Pessoa"
                                                    data={optionsPessoas}
                                                    totalResults={listPessoas.total}
                                                    reduxAction={getListPessoas}
                                                    clearButton
                                                    isLoading={loadingListPesssoas}
                                                    onChange={(pessoa) => {
                                                        if (!!pessoa.length) {
                                                            setFilter({ ctn_cd_pessoa: pessoa[0].pss_cd_pessoa, })
                                                        } else {
                                                            setFilter({ ...filter, ctn_cd_pessoa: null });
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <Label>Tipo</Label>
                                                <Typeahead
                                                    id="selectTipo"
                                                    labelKey="name"
                                                    options={[{ name: "Venda", value: "V" }, { name: "Diversas", value: "D" }]}
                                                    placeholder="Selecione o Tipo"
                                                    onChange={(tipo) => {
                                                        if (!!tipo.length) {
                                                            setFilter({ ctn_tp_conta: tipo[0].value, })
                                                        } else {
                                                            setFilter({ ...filter, ctn_tp_conta: null })
                                                        }
                                                    }}
                                                    clearButton
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <Label>Contabilização</Label>
                                                <Typeahead
                                                    id="selectContabilizacao"
                                                    labelKey="name"
                                                    options={[
                                                        { name: "Conta", value: "C" },
                                                        { name: "Pessoa", value: "P" },
                                                        { name: "Não Contabilização", value: "N" }
                                                    ]}
                                                    placeholder="Selecione a Contabilização"
                                                    onChange={(contabilizacao) => {
                                                        if (!!contabilizacao.length) {
                                                            setFilter({ ctn_st_contabiliza: contabilizacao[0].value })
                                                        } else {
                                                            setFilter({ ...filter, ctn_st_contabiliza: null })
                                                        }
                                                    }}
                                                    clearButton
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loading}
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody className="p-20">
                                {loading ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!contas &&
                                    <DataTable
                                        persistTableHead
                                        selectableRows
                                        pagination
                                        paginationServer
                                        clearSelectedRows={toggleCleared}
                                        onSelectedRowsChange={handleSelectedRows}
                                        columns={
                                            [
                                                {
                                                    name: "Código",
                                                    selector: "ctn_cd_conta",
                                                    center: true
                                                },

                                                {
                                                    name: "Nome",
                                                    selector: "ctn_nm_conta",
                                                    center: true
                                                },
                                                {
                                                    name: 'Grupo',
                                                    selector: 'grupo_name',
                                                    center: true,
                                                },
                                                {
                                                    name: 'Pessoa Padrão',
                                                    selector: 'pessoa_name',
                                                    center: true,
                                                },
                                                {
                                                    name: "Tipo",
                                                    selector: "tipo",
                                                    center: true,
                                                },
                                                {
                                                    name: "Contabilização",
                                                    selector: "contabilizacao",
                                                    center: true,
                                                },
                                                {
                                                    name: 'Ações',
                                                    selector: 'acoes',
                                                    center: true
                                                }
                                            ]
                                        }
                                        data={contas.data.map(({ id, ctn_cd_conta, ctn_nm_conta, ctn_tp_conta, ctn_st_contabiliza, grupo, pessoa }, key) => {
                                            const tipo = !!ctn_tp_conta ? ctn_tp_conta === "V" ? "Venda" : "Diversas" : "Não Informado";
                                            const grupo_name = !!grupo ? grupo.grc_nm_grupo : 'Não Informado';
                                            const pessoa_name = !!pessoa ? pessoa.pss_nm_pessoa : 'Não Informado';
                                            const contabilizacao = !!ctn_st_contabiliza ?
                                                ctn_st_contabiliza === "C" ? "Conta" :
                                                    ctn_st_contabiliza === "P" ? "Pessoa" :
                                                        ctn_st_contabiliza === "N" && "Não Contabilização" : "Não Informado"
                                            const acoes = (
                                                <div style={{ display: "flex", overflow: "auto" }}>
                                                    <a
                                                        sid="contas.index"
                                                        style={{ cursor: 'pointer' }}
                                                        title="Informações"
                                                        onClick={() => {
                                                            setSelectedConta(key);
                                                            setOpenModal('view')
                                                        }}
                                                    >
                                                        <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                    </a>
                                                    <a
                                                        sid="contas.update"
                                                        style={{ cursor: 'pointer' }}
                                                        title="Editar"
                                                        onClick={() => {
                                                            setSelectedConta(key)
                                                            setOpenModal('edit');
                                                        }}
                                                    >
                                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                    </a>

                                                </div>
                                            )
                                            return ({ id, ctn_cd_conta, ctn_nm_conta, grupo_name, pessoa_name, tipo, contabilizacao, acoes, })
                                        })}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                            rangeSeparatorText: 'de',
                                        }}
                                        progressPending={loadingDestroy}
                                        progressComponent={
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        }
                                        paginationPerPage={contas.per_page}
                                        paginationTotalRows={contas.total}
                                        paginationDefaultPage={contas.current_page}
                                        onChangePage={(page) => getContas({ fields: { ...curFilter, page } })}
                                        contextActions={
                                            <Button
                                                sid="contas.destroy"
                                                onClick={deleteContas}
                                                disabled={loadingDestroy}
                                                color="danger">
                                                {loadingDestroy ? 'Processando... ' : ' Remover Contas(s)'}
                                            </Button>
                                        }
                                        contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                        noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = ({ Contas, ContasGrupos, Pessoas }) => {
    const { contas, loading, loadingDestroy } = Contas
    const { list: listPessoas, loadingList: loadingListPesssoas } = Pessoas;
    const { list: listGrupos, loadingList: loadingListGrupos } = ContasGrupos;
    return { contas, loading, listGrupos, loadingListGrupos, listPessoas, loadingListPesssoas, loadingDestroy };
}
export default connect(mapStateToProps, {
    getContas,
    getListPessoas,
    getListContasGrupos,
    changeListGrupos,
    changeListPessoas,
    destroyContas,
})(Contas);