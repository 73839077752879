import React from "react";
import { Col, FormGroup, Label, Row,Input } from "reactstrap";

const Valores = ({ pessoa }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Valores</h6></Label>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor juros diário</Label>
                                <Input
                                    defaultValue={!!pessoa.pss_vl_juro_banco ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(pessoa.pss_vl_juro_banco)
                                        :
                                        null
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor margem</Label>
                                <Input
                                    defaultValue={!!pessoa.pss_vl_juro_margem ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(pessoa.pss_vl_juro_margem)
                                        :
                                        null
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

export default Valores;