import { put, all, takeLatest, fork, call, debounce } from "redux-saga/effects";
import {
    GET_FATURAS_SEM_BAIXAS_ASYNC,
    GET_FATURAS_SEM_BAIXAS,
    BAIXAR_FATURAS_ASYNC,
    BAIXAR_FATURAS,
    GET_FATURAS_CONCILAR_ASYNC,
    GET_FATURAS_CONCILAR,
    CONCILIAR_FATURAS_ASYNC,
    CONCILIAR_FATURAS,
    CONCILAR_FATURAS_AUTO_ASYNC,
    CONCILAR_FATURAS_AUTO
} from "../actionTypes";
import Api from "../../services/api";
import { toast } from "react-toastify";
import { getLancamentos } from "../actions";

function* getFaturasSemBaixaSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiFaturasSemBaixa, fields);
        if (data.ok) {
            const { ftr_sem_baixa } = data;
            yield put({ type: GET_FATURAS_SEM_BAIXAS, payload: { ftr_sem_baixa } });

        }
    } catch (error) {
        yield put({ type: GET_FATURAS_SEM_BAIXAS, payload: {} });
    }
}
const apiFaturasSemBaixa = async fields => {
    const { data } = await Api.get(`/api/faturas/sem_baixa`, { params: fields });
    return data;
}

function* baixarFaturasSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const data = yield call(apiBaixarFaturas, fields);
        if (data.ok) {
            yield put(getLancamentos());
            yield put({ type: BAIXAR_FATURAS, payload: {} });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }
    } catch (error) {
        yield put({ type: BAIXAR_FATURAS, payload: {} });
    }
}
const apiBaixarFaturas = async fields => {
    const { data } = await Api.post(`/api/faturas/baixar_faturas`, { ...fields });

    return data;
}

function* getFaturasConcilarSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetFaturasConcilar, fields);
        if (data.ok) {
            const { ftrParaConcilar } = data;
            yield put({ type: GET_FATURAS_CONCILAR, payload: { ftrParaConcilar } });
        }
    } catch (error) {
        yield put({ type: GET_FATURAS_CONCILAR, payload: {} });
    }
}
const apiGetFaturasConcilar = async fields => {
    const { data } = await Api.get(`/api/faturas/ftrParaConcilar`, { params: fields });

    return data;
}

function* conciliarFaturasSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const data = yield call(apiConciliarFaturas, fields);
        if (data.ok) {
            yield put({ type: CONCILIAR_FATURAS, payload: { ftrParaConcilar: [] } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }
    } catch (error) {
        yield put({ type: CONCILIAR_FATURAS, payload: {} });
    }
}
const apiConciliarFaturas = async fields => {
    const { data } = await Api.post(`/api/faturas/conciliarFtr`, { ...fields });

    return data;
}

function* conciliarFaturasAutoSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { msg, ftrsConcAuto } = yield call(apiConciliarFaturasAuto, fields);
       
        yield put({ type: CONCILAR_FATURAS_AUTO, payload: { ftrsConcAuto } });
        toast.success(msg, toast.POSITION.TOP_RIGHT);
        onSave();
    } catch (error) {
        yield put({ type: CONCILAR_FATURAS_AUTO, payload: {} });
    }
}
const apiConciliarFaturasAuto = async fields => {
    const { data } = await Api.post(`/api/faturas/conciliarFtrAuto`, fields);

    return data;
}

function* watchBaixarFaturas() {
    yield takeLatest(BAIXAR_FATURAS_ASYNC, baixarFaturasSaga)
}

function* watchGetFaturasSemBaixa() {
    yield takeLatest(GET_FATURAS_SEM_BAIXAS_ASYNC, getFaturasSemBaixaSaga);
}

function* watchGetFaturasConcilar() {
    yield debounce(1000, GET_FATURAS_CONCILAR_ASYNC, getFaturasConcilarSaga);
}

function* watchConciliarFaturas() {
    yield takeLatest(CONCILIAR_FATURAS_ASYNC, conciliarFaturasSaga);
}

function* watchConcilarFaturasAuto() {
    yield takeLatest(CONCILAR_FATURAS_AUTO_ASYNC, conciliarFaturasAutoSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetFaturasSemBaixa),
        fork(watchBaixarFaturas),
        fork(watchGetFaturasConcilar),
        fork(watchConciliarFaturas),
        fork(watchConcilarFaturasAuto)
    ]);
}