import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";
import { format, parseISO } from "date-fns";
import View from "./View/";

const Faturas = ({ contrapartidas }) => {
    const [openModal, setOpenModal] = useState(null);
    const [selectedFatura, setSelectedFatura] = useState(null);
    const faturas = useMemo(() => {
        let res = [];
        contrapartidas.forEach(element => {
            if (!!element.faturas.length) {
                element.faturas.forEach((fatura, key) => {
                    const { faturas, conta, pessoa, ...infos_contrapartida } = element;
                    res.push({ ...fatura, infos_contrapartida, index_fatura: key });
                });
            }
        });
        return res;
    }, [contrapartidas]);

    return (
        <>
            <View isOpen={openModal === "view"} fatura={faturas[selectedFatura]} clickClose={() => setOpenModal(null)} />
            <Col xs="12">
                <FormGroup>
                    <div className="shadow shadow-showcase p-15">
                        <div className="d-flex justify-content-between b-b-light">
                            <Label><h6>Fatura</h6></Label>
                        </div>
                        <Row>
                            <Col xs="12">
                                <DataTable
                                    noHeader
                                    noDataComponent={<span className="p-20">Nenhuma Fatura Vinculada</span>}
                                    highlightOnHover
                                    pointerOnHover
                                    onRowClicked={(row) => {
                                        setSelectedFatura(row.key);
                                        setOpenModal('view');
                                    }}
                                    columns={[
                                        {
                                            name: 'Fatura',
                                            selector: 'ftr_nu_fatura',

                                        },
                                        {
                                            name: 'Valor',
                                            selector: 'fat_valor'
                                        },
                                        {
                                            name: 'Vencimento',
                                            selector: 'dt_vencimento'
                                        },
                                        {
                                            name: 'Pagamento',
                                            selector: 'dt_pagamento'
                                        },
                                        {
                                            name: 'Baixa',
                                            selector: 'chk_baixa_fat'
                                        },
                                        {
                                            name: 'Tipo',
                                            selector: 'ftr_tp_baixa'
                                        }
                                    ]}
                                    data={faturas.map(({
                                        ftr_nu_fatura,
                                        ftr_vl_fatura,
                                        ftr_dt_vencimento,
                                        ftr_tp_baixa,
                                        ftr_dt_pagamento,
                                        ftr_dt_baixa
                                    },
                                        key
                                    ) => {
                                        const fat_valor = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(ftr_vl_fatura)
                                        const dt_vencimento = format(parseISO(ftr_dt_vencimento), 'dd/MM/Y');
                                        const dt_pagamento = !!ftr_dt_pagamento && format(parseISO(ftr_dt_pagamento), 'dd/MM/Y');
                                        const chk_baixa_fat = (<Input
                                            className="checkbox_animated disabled"
                                            readOnly
                                            checked={!!ftr_dt_baixa}
                                            id={`chk-ani${key}`}
                                            type="checkbox" />);
                                        return ({ key, ftr_nu_fatura, fat_valor, dt_vencimento, dt_pagamento, chk_baixa_fat, ftr_tp_baixa })
                                    })}
                                />
                            </Col>
                        </Row>
                    </div>
                </FormGroup>
            </Col>
        </>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Faturas);