import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Col, Row } from "reactstrap";

function Tipos({ filter, handleChangeSelect }) {
    const optionsTipos = [
        {
            name: 'Analítico',
            value: 'A',
        },
        {
            name: 'Sintético',
            value: 'S'
        }
    ];
    return (
        <div className="b-light border-2 p-10">
            <h6>Tipo</h6>
            <Row>
                <Col xs="12">
                    <Typeahead
                        id="selectTipo"
                        labelKey="name"
                        placeholder="Informe o tipo"
                        selected={optionsTipos.filter((opt) => opt.value === filter.tipo)}
                        onChange={(selected) => handleChangeSelect('tipo', selected)}
                        options={optionsTipos}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Tipos