import React, { Fragment } from 'react';
import { Row, Col, Table, Alert } from "reactstrap";
import { connect } from 'react-redux';
import { } from 'react';
import { format, parseISO } from 'date-fns';

function Relatorio({
    rltDebCred,
    filter
}) {


    return (
        <Row className="b-light p-15 border-5 m-b-20">
            <Col xs="12">
                <Table className="font-arial-rlt" >
                    <thead>
                        <tr className="text-center">
                            <th className="b-dark">Data</th>
                            <th className="b-dark">Tipo</th>
                            <th className="b-dark">Conta</th>
                            <th className="b-dark">Subconta</th>
                            <th className="b-dark" style={{ width: 120 }}>Centro de Custo</th>
                            <th className="b-dark">Histórico</th>
                            <th className="b-dark">Valor</th>
                            <th className="b-dark">Acumulado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!Object.keys(rltDebCred).length ?
                            Object.keys(rltDebCred).map((dia, keyDia) => {
                                return (
                                    <Fragment key={keyDia}>
                                        <tr>
                                            <td className="text-center f-w-900">
                                                <u>{format(parseISO(dia), 'dd/MM/yyyy')}</u>
                                            </td>
                                            <td colSpan="7"></td>
                                        </tr>
                                        {filter.duplicar &&
                                            <tr>
                                                <td colSpan="8">{` `}</td>
                                            </tr>
                                        }
                                        {Object.keys(rltDebCred[dia]).map((tipo, keyTipo) => {
                                            const labelTipo = tipo === "C" ? 'Crédito' : 'Débito';
                                            const total = rltDebCred[dia][tipo].reduce((acu, el) => acu + parseFloat(el.vla_vl_lancamento), 0);
                                            return (
                                                <Fragment key={keyTipo}>
                                                    <tr>
                                                        <td></td>
                                                        <td className="text-center f-w-900">{labelTipo}</td>
                                                        <td colSpan="6"></td>
                                                    </tr>
                                                    {filter.duplicar &&
                                                        <tr>
                                                            <td colSpan="8">{` `}</td>
                                                        </tr>
                                                    }
                                                    {rltDebCred[dia][tipo].map((lancamentos, keyLan) => {
                                                        return (
                                                            <Fragment key={keyLan}>
                                                                <tr>
                                                                    <td colSpan="2"></td>
                                                                    <td >{`${lancamentos?.conta.ctn_cd_conta} - ${lancamentos?.conta.ctn_nm_conta}`} </td>
                                                                    <td>{`${lancamentos?.pessoa.pss_cd_pessoa} - ${lancamentos?.pessoa.pss_nm_pessoa}`}</td>
                                                                    <td>{`${lancamentos?.centro_custo.cct_cd_centro} - ${lancamentos?.centro_custo.cct_nm_centro}`}</td>
                                                                    <td>{lancamentos.vla_ds_historico}</td>
                                                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lancamentos.vla_vl_lancamento)}</td>
                                                                    <td>{` `}</td>
                                                                </tr>
                                                                {filter.duplicar &&
                                                                    <tr>
                                                                        <td colSpan="8">{` `}</td>
                                                                    </tr>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan="7" className="f-w-900 text-right">Acumulado {labelTipo}:</td>
                                                        <td className="f-w-900">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</td>
                                                    </tr>
                                                    {filter.duplicar &&
                                                        <tr>
                                                            <td colSpan="8">{` `}</td>
                                                        </tr>
                                                    }
                                                </Fragment>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })
                            :
                            <tr>
                                <td colSpan="8"><Alert color="light">Nenhum registro encontrado</Alert></td>
                            </tr>
                        }

                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ RltOperacionais }) => {
    const { rltDebCred } = RltOperacionais;
    return { rltDebCred }
}

export default connect(mapStateToProps, {})(Relatorio);