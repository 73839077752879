import { IMPORT_XMLS_ASYNC, IMPORT_XMLS, LOGIN_EDOCS_ASYNC, LOGIN_EDOCS } from "../actionTypes";

const initial_state = {
    tokenEdocs: null,
    loading: false,

}
export default (state = initial_state, action) => {
    switch (action.type) {
        case LOGIN_EDOCS_ASYNC:
            return { ...state, loading: true };
        case LOGIN_EDOCS:
            return { ...state, ...action.payload, loading: false };
        case IMPORT_XMLS_ASYNC:
            return { ...state, loading: true };
        case IMPORT_XMLS:
            return { ...state, ...action.payload, loading: false };
        default: return state;
    }
}
