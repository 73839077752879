import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Input,
    Row,
    Label,
    ListGroup,
    ListGroupItem,
    Alert
} from "reactstrap";
import ScrollArea from "react-scrollbar";
const Empresas = ({ user }) => {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Empresas</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                {!!user.empresas.length ?
                                    <ScrollArea vertical={true} horizontal={false}>
                                        <ListGroup>
                                            {user.empresas.map(({ emp_nm_empresa }, key) => (
                                                <ListGroupItem className="list-group-item-action" key={key}>
                                                    {emp_nm_empresa}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </ScrollArea>
                                    :
                                    <Alert color="light">Nenhuma Empresa Vinculada</Alert>
                                }
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Empresa Padrão*</Label>
                                <Input readOnly defaultValue={user.empresas.find(({ pivot: { principal } }) => principal === "1")?.emp_nm_empresa} />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({  }) => {
    
    return {  };
}
export default connect(mapStateToProps, {})(Empresas);