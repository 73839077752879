import {
    GET_LIST_TIPOS_EMPRESAS_ASYNC,
    GET_LIST_TIPOS_EMPRESAS,
    GET_TIPOS_EMPRESA_ASYNC,
    GET_TIPOS_EMPRESA,
    STORE_TIPO_EMPRESA_ASYNC,
    STORE_TIPO_EMPRESA,
    DESTROY_TIPOS_EMPRESA_ASYNC,
    DESTROY_TIPOS_EMPRESA,
    UPDATE_TIPO_EMPRESA_ASYNC,
    UPDATE_TIPO_EMPRESA
} from "../actionTypes";
import { all, fork, debounce, put, call, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import Api from "../../services/api";
import { selectorTiposEmpresa } from "../selectors";

function* getListTiposEmpSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListTiposEmp, fields);
        if (data.ok) {
            const { listTiposEmp } = data;
            yield put({ type: GET_LIST_TIPOS_EMPRESAS, payload: { listTiposEmp } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_TIPOS_EMPRESAS, payload: {} });
    }
}
const apiGetListTiposEmp = async fields => {
    const { data } = await Api.get(`/api/tipos_empresa/list`, { params: fields });

    return data;
}

function* getTiposEmpresaSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetTiposEmpresa, fields);
        if (data.ok) {
            const { tiposEmpresa } = data;
            yield put({ type: GET_TIPOS_EMPRESA, payload: { tiposEmpresa } });
        }
    } catch (error) {
        yield put({ type: GET_TIPOS_EMPRESA, payload: {} });
    }
}
const apiGetTiposEmpresa = async fields => {
    const { data } = await Api.get(`/api/tipos_empresa`, { params: fields });

    return data;
}

function* storeTiposEmpresaSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreTiposEmpresa, fields);
        if (!!data && data.ok) {
            const { tiposEmpresa } = data;
            yield put({ type: STORE_TIPO_EMPRESA, payload: { tiposEmpresa } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave()
        }
        if (!!errors) {
            yield put({ type: DESTROY_TIPOS_EMPRESA, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_TIPO_EMPRESA, payload: {} });
    }
}
const apiStoreTiposEmpresa = async fields => {
    const data = await Api.post(`/api/tipos_empresa/store`, { ...fields });

    return data;
}

function* destroyTiposEmpresaSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyTiposEmpresa, fields);
        if (data.ok) {
            const { tiposEmpresa } = data;
            yield put({ type: DESTROY_TIPOS_EMPRESA, payload: { tiposEmpresa } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_TIPOS_EMPRESA, payload: {} });
    }
}
const apiDestroyTiposEmpresa = async fields => {
    const { data } = await Api.delete(`/api/tipos_empresa/destroy`, { data: fields });

    return data;
}

function* updateTipoEmpresaSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;
        const tiposEmpresa = yield select(selectorTiposEmpresa);
        const codigo = tiposEmpresa.data[index].tpe_cd_tipo;
        const { data, errors } = yield call(apiUpdateTipoEmpresa, fields, codigo);
        if (!!data && !!data.ok) {
            tiposEmpresa.data.splice(index, 1, data.tipoEmpresa);
            yield put({ type: UPDATE_TIPO_EMPRESA, payload: { tiposEmpresa } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }
        if (!!errors) {
            yield put({ type: UPDATE_TIPO_EMPRESA, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_TIPO_EMPRESA, payload: {} });
    }
}
const apiUpdateTipoEmpresa = async (fields, codigo) => {
    const data = await Api.put(`/api/tipos_empresa/update/${codigo}`, fields);

    return data;
}

function* watchListTiposEmp() {
    yield debounce(1000, GET_LIST_TIPOS_EMPRESAS_ASYNC, getListTiposEmpSaga);
}
function* watchGetTiposEmpresa() {
    yield takeLatest(GET_TIPOS_EMPRESA_ASYNC, getTiposEmpresaSaga);
}
function* watchStoreTipoEmpresa() {
    yield takeLatest(STORE_TIPO_EMPRESA_ASYNC, storeTiposEmpresaSaga);
}
function* watchDestroyTiposEmpresa() {
    yield takeLatest(DESTROY_TIPOS_EMPRESA_ASYNC, destroyTiposEmpresaSaga);
}

function* watchUpdateTipoEmpresa() {
    yield takeLatest(UPDATE_TIPO_EMPRESA_ASYNC, updateTipoEmpresaSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchListTiposEmp),
        fork(watchGetTiposEmpresa),
        fork(watchStoreTipoEmpresa),
        fork(watchDestroyTiposEmpresa),
        fork(watchUpdateTipoEmpresa)
    ])
}