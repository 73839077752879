import React from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Row,
    Col
} from 'reactstrap';
import DadosGerais from "./DadosGerais";
import Grupos from "./Grupos";
import Contas from "./Contas";
import Bloqueios from "./Bloqueios";
import OutrosParam from "./OutrosParam";
import Contabilizacao from "./Contabilizacao";

function View({ isOpen, empresa, clickClose }) {


    return (
        <Modal isOpen={isOpen} size="xxl">
            <ModalHeader toggle={clickClose}>
                Informações da Empresa
            </ModalHeader>
            <ModalBody>
                {!!empresa &&
                    <Row>
                        <Col md="6" xs="12">
                            <DadosGerais empresa={empresa} />
                        </Col>
                        <Col md="6" xs="12">
                            <Grupos empresa={empresa} />
                        </Col>
                        <Col md="4" xs="12">
                            <Bloqueios empresa={empresa} />
                        </Col>
                        <Col md="4" xs="12">
                            <OutrosParam empresa={empresa} />
                        </Col>
                        <Col md="4" xs="12">
                            <Contabilizacao empresa={empresa} />
                        </Col>
                        <Col xs="12">
                            <Contas
                                empresa={empresa}
                            />
                        </Col>
                    </Row>
                }

            </ModalBody>
            <ModalFooter>
                <ModalFooter>
                    <Button onClick={clickClose}>
                        Fechar
                  </Button>
                </ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default View;