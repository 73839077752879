import React from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DadosGerais from "./DadosGerais";

const Add = ({ isOpen, clickClose, fatura }) => {

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onClosed={() => {

            }}
        >
            <ModalHeader toggle={() => clickClose()}>Informações Fatura</ModalHeader>
            <Form >
                <ModalBody>
                    <Row>
                        <DadosGerais
                            fatura={fatura}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => clickClose()}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Add);