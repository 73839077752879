import React, { useState, useRef, useCallback } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Alert,
    Badge,
    Button,
    FormGroup,
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    Form,
    UncontrolledTooltip
} from 'reactstrap';
import { connect } from "react-redux";
import { getLancamentos, destroyLancamentos } from "../../../redux/actions";
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import sweetalert2 from "sweetalert2";
import { parseISO, format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import Add from "./Add/";
import Edit from "./Edit/";
import View from "./View/";
import BaixarFaturas from "./BaixarFaturas/";
import FecharLancamentos from "./FecharLancamentos";
import ReabrirLancamentos from "./ReabrirLancamentos";

const Lancamentos = ({
    getLancamentos,
    loading,
    lancamentos,
    destroyLancamentos,
    loadingDestroy,
}) => {

    const [openModal, setOpenModal] = useState(null);
    const [filter, setFilter] = useState({
        lan_dt_competencia: null,
        lan_vl_lancamento: null,
        lan_st_fechamento: null,
        lan_tp_lancamento: null
    });
    const curFilter = useRef(null);
    const [selectedLancamento, setSelectedLancamento] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    useEffect(() => {
        getLancamentos()
    }, []);

    //FUNÇOES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ lan_dt_lancamento, data_competencia, lan_nu_doc, lan_nu_lancamento }) => (
            {
                lan_dt_lancamento,
                lan_nu_lancamento,
                name: `Data: ${data_competencia} <br/> Doc: ${lan_nu_doc}`
            }
        )))
    }, [setSelectedRows]);
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'lan_dt_competencia':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: null })
                }
                break;

            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value })
                } else {
                    setFilter({ ...filter, [name]: null })
                }
                break;
        }
    }

    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFilter({ ...filter, [name]: value })
                } else {
                    setFilter({ ...filter, [name]: null })
                }
                break;
        }
    }
    const submitForm = e => {
        e.preventDefault();
        getLancamentos({ fields: filter });
        curFilter.current = filter;
        setToggleCleared(!toggleCleared);
    }
    const removeLancamentos = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ name }) => {
                return (`<li class="list-group-item-action list-group-item">${name}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!lancamentos && lancamentos.current_page !== 1) ? lancamentos.current_page : 1;
            const fields = { lancamentos: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyLancamentos({ fields, onDelete });
        }
    }


    return (
        <>
            <Add isOpen={openModal === "add"} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === "edit"} clickClose={() => setOpenModal(null)} selectedLancamento={selectedLancamento} />
            <View isOpen={openModal === "view"} clickClose={() => setOpenModal(null)} lancamento={!!lancamentos && lancamentos.data[selectedLancamento]} />
            <BaixarFaturas isOpen={openModal === 'baixarFaturas'} clickClose={() => setOpenModal(null)} />
            <FecharLancamentos isOpen={openModal === 'fecharLan'} clickClose={() => setOpenModal(null)} />
            <ReabrirLancamentos isOpen={openModal === 'reabirLan'} clickClose={() => setOpenModal(null)} />
            <Breadcrumb parent="Operação" title="Lançamentos" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                sid="lancamentos.reabrirLancamentos"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('reabirLan')
                                }}>
                                <i className="fa fa-unlock"></i><span className="p-l-5">Reabrir Lancamentos</span>
                            </Button>
                            <Button
                                sid="lancamentos.fecharLancamentos"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('fecharLan')
                                }}>
                                <i className="fa fa-unlock-alt"></i><span className="p-l-5">Fechar Lancamentos</span>
                            </Button>
                            <Button
                                sid="faturas.baixar_faturas"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('baixarFaturas')
                                }}>
                                <i className="fa fa-file-text-o"></i><span className="p-l-5">Baixar Faturas</span>
                            </Button>
                            <Button
                                sid="lancamentos.store"
                                color="primary"
                                className="m-l-10 "
                                size="md"
                                onClick={() => setOpenModal('add')}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                    </Col>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={submitForm}>
                                    <Row>
                                        <Col lg="3" sm="12">
                                            <FormGroup>
                                                <Label>Data Competência</Label>
                                                <DatePicker
                                                    locale={br}
                                                    autoComplete="off"
                                                    placeholderText="Selecione a Data"
                                                    minDate={new Date(1930, 0, 1)}
                                                    maxDate={new Date()}
                                                    selected={!!filter.lan_dt_competencia && parseISO(filter.lan_dt_competencia)}
                                                    isClearable
                                                    dateFormat="dd/MM/yyyy"
                                                    className={`form-control digits`}
                                                    onChange={(selected) => handleChangeSelect('lan_dt_competencia', selected)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="3" sm="12">
                                            <FormGroup>
                                                <Label>Valor</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                                    <NumberFormat
                                                        placeholder="Informe o Valor"
                                                        thousandSeparator='.'
                                                        customInput={Input}
                                                        name="lan_vl_lancamento"
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        onValueChange={({ value }) => handleValor('lan_vl_lancamento', value)}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="3" sm="12">
                                            <FormGroup>
                                                <Label>Situação</Label>
                                                <Typeahead
                                                    id="selectFecha"
                                                    clearButton
                                                    labelKey="name"
                                                    placeholder="Informe o Tipo"
                                                    options={[{ name: 'Aberto', value: 'A' }, { name: 'Fechado', value: 'F' }]}
                                                    onChange={(selected) => handleChangeSelect('lan_st_fechamento', selected)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2" sm="12">
                                            <FormGroup>
                                                <Label>Tipo</Label>
                                                <Typeahead
                                                    id="selectTipo"
                                                    clearButton
                                                    labelKey="name"
                                                    placeholder="Informe o Tipo"
                                                    options={[{ name: 'Débito', value: 'D' }, { name: 'Crédito', value: 'C' }]}
                                                    onChange={(selected) => handleChangeSelect('lan_tp_lancamento', selected)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loading}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow shadow-showcase">
                            <CardBody className="p-t-20">
                                {loading ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!lancamentos ?
                                        <DataTable
                                            persistTableHead
                                            pagination
                                            paginationServer
                                            selectableRows
                                            clearSelectedRows={toggleCleared}
                                            selectableRowDisabled={({ lan_st_fechamento }) => lan_st_fechamento === 'F' || !lan_st_fechamento}
                                            onSelectedRowsChange={handleSelectedRows}
                                            contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                            paginationPerPage={lancamentos.per_page}
                                            paginationTotalRows={lancamentos.total}
                                            paginationDefaultPage={lancamentos.current_page}
                                            progressPending={loadingDestroy}
                                            onChangePage={(page) => getLancamentos({ fields: { ...curFilter.current, page } })}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                                rangeSeparatorText: 'de',
                                            }}
                                            columns={[
                                                {
                                                    name: 'Data Competência',
                                                    selector: 'data_competencia',
                                                },
                                                {
                                                    name: "Conta",
                                                    selector: "conta_name",
                                                    width: '8%',

                                                },
                                                {
                                                    name: "Pessoa",
                                                    selector: "pessoa_name",
                                                    width: '8%',

                                                },
                                                {
                                                    name: 'Tipo',
                                                    selector: "tipo",
                                                    width: '7%',
                                                    center: true
                                                },
                                                {
                                                    name: 'Documento',
                                                    selector: "lan_doc",

                                                    center: true
                                                },
                                                {
                                                    name: 'Histórico',
                                                    selector: 'hist_name',
                                                    center: true
                                                },
                                                {
                                                    name: 'Valor',
                                                    selector: "valor_num",
                                                },
                                                {
                                                    name: 'Situação',
                                                    selector: 'st_fechamento',
                                                    center: true
                                                },
                                                {
                                                    name: 'Ações',
                                                    selector: 'acoes',
                                                    center: true
                                                }
                                            ]}
                                            contextActions={
                                                <Button
                                                    sid="lancamentos.destroy"
                                                    disabled={loadingDestroy}
                                                    onClick={removeLancamentos}
                                                    color="danger">{loadingDestroy ? 'Processando...' : 'Remover Lançamento(s)'}
                                                </Button>
                                            }
                                            noDataComponent={<span className="p-20">Nenhuma Lançamento Encontrado</span>}
                                            progressComponent={
                                                <div className="loader-box">
                                                    <div className="loader-1"></div>
                                                </div>
                                            }
                                            data={lancamentos.data.map(({
                                                id,
                                                lan_tp_lancamento,
                                                lan_dt_lancamento,
                                                lan_nu_lancamento,
                                                lan_st_fechamento,
                                                lan_dt_competencia,
                                                lan_vl_lancamento,
                                                lan_ds_historico,
                                                lan_nu_doc,
                                                conta,
                                                pessoa,
                                            },
                                                index
                                            ) => {
                                                const conta_name = (
                                                    <>
                                                        <span id={`lan_conta${index}`}>{conta?.ctn_cd_conta}</span>
                                                        <UncontrolledTooltip target={`lan_conta${index}`}>
                                                            {`${conta?.ctn_cd_conta} - ${conta?.ctn_nm_conta}`}
                                                        </UncontrolledTooltip>
                                                    </>
                                                );
                                                const pessoa_name = (
                                                    <>
                                                        <span id={`lan_pessoa${index}`}>{pessoa?.pss_cd_pessoa}</span>
                                                        <UncontrolledTooltip target={`lan_pessoa${index}`}>
                                                            {`${pessoa?.pss_cd_pessoa} - ${pessoa?.pss_nm_pessoa}`}
                                                        </UncontrolledTooltip>
                                                    </>
                                                );
                                                const valor_num = !!lan_vl_lancamento && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lan_vl_lancamento);
                                                const hist_name = (
                                                    <>
                                                        <span id={`lan_hist${index}`}>{lan_ds_historico}</span>
                                                        <UncontrolledTooltip target={`lan_hist${index}`}>
                                                            {`${lan_ds_historico}`}
                                                        </UncontrolledTooltip>
                                                    </>
                                                );
                                                const data_competencia = format(parseISO(lan_dt_competencia), 'dd/MM/yyyy');
                                                const lan_doc = (
                                                    <>
                                                        <span id={`lan_nu_doc${index}`}>{lan_nu_doc}</span>
                                                        <UncontrolledTooltip target={`lan_nu_doc${index}`}>
                                                            {`${lan_nu_doc}`}
                                                        </UncontrolledTooltip>
                                                    </>
                                                )
                                                const tipo = lan_tp_lancamento === "D" ? 'Débito' : 'Crédito';
                                                const st_fechamento = lan_st_fechamento === "A" ?
                                                    <Badge color="success">Aberto</Badge> :
                                                    lan_st_fechamento === 'F' ?
                                                        <Badge color="danger">Fechado</Badge>
                                                        :
                                                        <Badge color="light">Não Informado</Badge>;
                                                const acoes = (
                                                    <div style={{ display: "flex", overflow: "auto" }}>
                                                        <a
                                                            sid="lancamentos.index"
                                                            style={{ cursor: 'pointer' }}
                                                            title="Exibir todas informações"
                                                            onClick={() => {
                                                                setSelectedLancamento(index)
                                                                setOpenModal('view')
                                                            }}
                                                        >
                                                            <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                        </a>
                                                        <a
                                                            sid="lancamentos.update"
                                                            style={{ cursor: 'pointer' }}
                                                            title="Editar informações"
                                                            className={lan_st_fechamento === "A" ? '' : 'disabled'}
                                                            onClick={() => {
                                                                if (lan_st_fechamento === "A") {
                                                                    setSelectedLancamento(index)
                                                                    setOpenModal('edit')
                                                                }
                                                            }}
                                                        >
                                                            <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                        </a>
                                                    </div>
                                                )
                                                return {
                                                    id,
                                                    lan_dt_lancamento,
                                                    lan_nu_lancamento,
                                                    data_competencia,
                                                    conta_name,
                                                    pessoa_name,
                                                    lan_nu_doc,
                                                    lan_doc,
                                                    tipo,
                                                    valor_num,
                                                    hist_name,
                                                    lan_st_fechamento,
                                                    st_fechamento,
                                                    acoes
                                                }
                                            })}
                                        />
                                        :
                                        <Alert color="light">Nenhum Registro foi Carregado</Alert>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = ({ Lancamentos }) => {
    const { lancamentos, loading, loadingDestroy } = Lancamentos;
    return { lancamentos, loading, loadingDestroy }
}

export default connect(mapStateToProps, {
    getLancamentos,
    destroyLancamentos
})(Lancamentos);