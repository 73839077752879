import {
    GET_MODULOS_ASYNC,
    LIST_MODULOS_ASYNC,
    STORE_MODULOS_ASYNC,
    DESTORY_MODULOS_ASYNC,
    UPDATE_MODULOS_ASYNC,
} from "../actionTypes";

export const getModulos = (payload = {}) => ({
    type: GET_MODULOS_ASYNC,
    payload
});

export const listModulos = () => ({
    type: LIST_MODULOS_ASYNC
});

export const storeModulos = payload => ({
    type: STORE_MODULOS_ASYNC,
    payload
});

export const updateModulos = payload => ({
    type: UPDATE_MODULOS_ASYNC,
    payload
});

export const destroyModulos = payload => ({
    type: DESTORY_MODULOS_ASYNC,
    payload
});