import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Conta from "./Conta";
import DadosGerais from "./DadosGerais";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import { getListContaPessoas, getListContas } from "../../../../../../redux/actions";

const Edit = ({
    isOpen,
    clickClose,
    setContraPartidas,
    contrapartidas,
    getListContaPessoas,
    getListContas,
    listContas,
    listContaPessoas,
    selectedContrapartida

}) => {
    //ESTADOS
    const [fields, setFields] = useState(null);

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            verifica_valor: {
                message: 'O valor deve está entre o mínimo e máximo da conta.',
                rule: (val, params) => {
                    const valor_contrapartida = parseFloat(params[0]);
                    const valor_minimo = parseFloat(params[1]);
                    const valor_maximo = parseFloat(params[2]);

                    return valor_contrapartida >= valor_minimo && valor_contrapartida <= valor_maximo;
                }
            }
        }
    })).current;

    //FUNÇÕES
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        switch (name) {
            default:
                setFields({ ...fields, [name]: value })
                break;
        }
    }
    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFields({ ...fields, [name]: value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'cnt_cd_conta':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].ctn_cd_conta,
                        cnt_ds_historico: selected[0].ctn_ds_hist_padrao,
                        cnt_cd_subconta: selected[0]?.def_pessoa?.pss_cd_pessoa,
                        conta: selected[0],
                        pessoa: {
                            value: selected[0]?.def_pessoa?.id,
                            pss_nm_pessoa: selected[0]?.def_pessoa?.pss_nm_pessoa,
                            pss_cd_pessoa: selected[0]?.def_pessoa?.pss_cd_pessoa,
                            pss_nu_identificacao: selected[0]?.def_pessoa?.pss_nu_identificacao
                        }
                    });
                    listContaPessoas.data = [];
                    listContaPessoas.total = 0;
                    !selected[0].def_pessoa && getListContaPessoas({ conta: selected[0].ctn_cd_conta, fields: { } });

                } else {
                    setFields({
                        ...fields,
                        [name]: "",
                        cnt_ds_historico: "",
                        cnt_cd_subconta: "",
                        conta: {},
                        pessoa: {}
                    });
                }
                break;
            case 'cnt_cd_subconta':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].pss_cd_pessoa, pessoa: selected[0] });
                } else {
                    setFields({ ...fields, [name]: "", pessoa: {} });
                }
                break;
            case 'cnt_cd_centro_custo':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value, centro_custo: { name: selected[0].name, value: selected[0].value } });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }
    const submitForm = e => {
        e.preventDefault();
        e.stopPropagation();
        if (validator.allValid()) {
            contrapartidas[selectedContrapartida] = fields;
            setContraPartidas(contrapartidas);
            toast.success('Registro atualizado com sucesso.', toast.POSITION.TOP_RIGHT);
            clickClose();
        } else {
            validator.showMessages();
            toast.error('Preencha todos os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }
    const onOpenModal = () => {
        listContas.data = [];
        listContas.total = 0;
        listContaPessoas.data = [];
        listContaPessoas.total = 0;
        getListContas();
        const contrapartida = contrapartidas[selectedContrapartida];
        setFields(contrapartida)
    }

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onOpened={onOpenModal}
            onClosed={() => {
                setFields(null);
                validator.visibleFields = [];
                validator.messagesShown = false;
            }}
        >
            <ModalHeader toggle={() => clickClose()}>Editar Contrapartida</ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        {!!fields &&
                            <>
                                <Conta
                                    fields={fields}
                                    handleChange={handleChange}
                                    handleChangeSelect={handleChangeSelect}
                                    validFields={validFields}
                                    validator={validator}
                                />
                                <DadosGerais
                                    fields={fields}
                                    validator={validator}
                                    handleChange={handleChange}
                                    handleChangeSelect={handleChangeSelect}
                                    validFields={validFields}
                                    handleValor={handleValor}
                                    indexContrapartida={selectedContrapartida}
                                />
                            </>
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button color="primary" type="submit">
                        Salvar
                </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, listContaPessoas } = Contas;
    return { listContas, listContaPessoas }
}

export default connect(mapStateToProps, {
    getListContaPessoas,
    getListContas
})(Edit)