import { format, parseISO } from "date-fns";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Col, Row, Table, Alert } from "reactstrap";

function Relatorio({ rltNaoRecebidos }) {
    let total = 0;
    return (
        <Row className="b-light p-15 border-5 m-b-20">
            <Col xs="12">
                <Table className="font-arial-rlt">
                    <thead>
                        <tr>
                            <th>Pessoa</th>
                            <th>Fatura</th>
                            <th>Valor</th>
                            <th>Lançamento</th>
                            <th>Vencimento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!rltNaoRecebidos.length ?
                                rltNaoRecebidos.map((rlt, index) => {
                                    total += parseFloat(rlt.ftr_vl_fatura);
                                    return (
                                        <Fragment key={index}>
                                            <tr>
                                                <td>{`${rlt.pss_cd_pessoa} ${rlt.pss_nm_pessoa} ${rlt.pss_nu_identificacao}`}</td>
                                                <td>{rlt.ftr_nu_fatura}</td>
                                                <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rlt.ftr_vl_fatura)}</td>
                                                <td>{format(parseISO(rlt.lan_dt_lancamento), 'dd/MM/yyyy')}</td>
                                                <td>{format(parseISO(rlt.ftr_dt_vencimento), 'dd/MM/yyyy')}</td>
                                            </tr>
                                            {rltNaoRecebidos.length - 1 === index &&
                                                <tr className="b-t-dark border-2">
                                                    <td colSpan="4"><span className="d-flex justify-content-end">Total: </span></td>
                                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</td>
                                                </tr>
                                            }
                                        </Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="8"><Alert color="light">Nenhum Registro encontrado.</Alert></td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ RltOperacionais }) => {
    const { loadingRltNaoRecebidos, rltNaoRecebidos } = RltOperacionais;
    return { loadingRltNaoRecebidos, rltNaoRecebidos }
}

export default connect(mapStateToProps, {})(Relatorio);