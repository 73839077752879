import React, {  } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Row,
    Col
} from "reactstrap";
import { connect } from "react-redux";
import DadosGerais from "./DadosGerais";
import QntAndValor from "./QntAndValor";

function View({
    isOpen,
    clickClose,
    custoProduto
}) {

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
        >
            <ModalHeader toggle={() => clickClose()}>
                Adicionar Custo por Produto
            </ModalHeader>
            {!!custoProduto &&
                <>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <DadosGerais
                                    custoProduto={custoProduto}
                                />
                            </Col>
                            <Col xs="12">
                                <QntAndValor
                                    custoProduto={custoProduto}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => clickClose()}
                            type="button">
                            Fechar
                        </Button>
                    </ModalFooter>
                </>
            }
        </Modal>
    )
}

const mapStateToProps = ({ CmvDiario }) => {
    const { loadingStoreCustoProd } = CmvDiario;
    return { loadingStoreCustoProd }
}

export default connect(mapStateToProps, {
})(View);