import {
    GET_LIST_CENTRO_CUSTO_ASYNC,
    GET_LIST_CENTRO_CUSTO,
    GET_CENTROS_CUSTO_ASYNC,
    GET_CENTROS_CUSTO,
    DESTROY_CENTROS_CUSTO_ASYNC,
    DESTROY_CENTROS_CUSTO,
    STORE_CENTRO_CUSTO_ASYNC,
    STORE_CENTRO_CUSTO,
    UPDATE_CENTRO_CUSTO_ASYNC,
    UPDATE_CENTRO_CUSTO
} from "../actionTypes";

const initial_state = {
    errors: {},
    list: { data: [], total: 0 },
    loadingList: false,
    centrosCusto: null,
    loadingCentrosCusto: false,
    loadingDestroyCct: false,
    loadingStoreCct: false,
    loadingUpdateCct: false
}

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_LIST_CENTRO_CUSTO_ASYNC:
            return { ...state, loadingList: true };

        case GET_LIST_CENTRO_CUSTO:
            return { ...state, ...action.payload, loadingList: false };

        case GET_CENTROS_CUSTO_ASYNC:
            return { ...state, loadingCentrosCusto: true };

        case GET_CENTROS_CUSTO:
            return { ...state, ...action.payload, loadingCentrosCusto: false };

        case DESTROY_CENTROS_CUSTO_ASYNC:
            return { ...state, loadingDestroyCct: true };

        case DESTROY_CENTROS_CUSTO:
            return { ...state, ...action.payload, loadingDestroyCct: false };

        case STORE_CENTRO_CUSTO_ASYNC:
            return { ...state, ...action.payload, loadingStoreCct: true };

        case STORE_CENTRO_CUSTO:
            return { ...state, ...action.payload, loadingStoreCct: false };

        case UPDATE_CENTRO_CUSTO_ASYNC:
            return { ...state, loadingUpdateCct: true };

        case UPDATE_CENTRO_CUSTO:
            return { ...state, ...action.payload, loadingUpdateCct: false };

        default: return state;
    }
}