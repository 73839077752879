import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Input,
    ModalHeader,
    Label,
    ModalFooter,
    Button,
    FormGroup,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Alert,
} from "reactstrap";
import ScrollArea from 'react-scrollbar';
import { getListFuncionalidades, storeModulos, updateModulos } from "../../../../redux/actions";

const EditModulo = (props) => {
    //STATES
    const [funcionalidades, setFuncionalidades] = useState([]);
    const [modulo, setModulo] = useState(null);

    useEffect(() => {
        if (!!props.funcionalidades) {
            setFuncionalidades([...props.funcionalidades]);
        }
    }, [props.funcionalidades]);

    //VARIÁVEIS


    //FUNÇÕES
    const submitForm = (e) => {
        e.preventDefault();
        const fields = { ...modulo, page: props.modulos.current_page };
        props.updateModulos({ onUpdate: props.clickClose, fields });

    }
    const handleSearch = ({ target: { value } }) => {
        if (!!props.funcionalidades) {
            const filter = props.funcionalidades.filter(({ name }) => new RegExp(`${value}`, 'i').test(name));
            setFuncionalidades(filter);
        }
    }

    return (
        <>
            <Modal isOpen={props.isOpen} size="lg"
                onOpened={() => {
                    const dados = { ...props.modulos.data[props.selectedModulo] };
                    delete dados.row_num;
                    props.getListFuncionalidades();
                    setModulo(dados);

                }}
                onClosed={() => {
                    setModulo(null);
                }}
            >
                <Form onSubmit={submitForm}>
                    <ModalHeader toggle={() => props.clickClose()}>
                        Editar Módulo
                    </ModalHeader>
                    <ModalBody>
                        {!!modulo &&
                            <Row>
                                <Col md="8" sm="12">
                                    <FormGroup>
                                        <Label>Nome</Label>
                                        <Input
                                            name="name"
                                            placeholder="Nome do Módulo"
                                            defaultValue={modulo.name}
                                            onChange={({ target: { value } }) => setModulo({ ...modulo, name: value })} />
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="12">
                                    <Label className="d-block">Status</Label>
                                    <ButtonGroup>
                                        <Button outline active={modulo.active === "1"} onClick={() => setModulo({ ...modulo, active: "1" })} color="success">
                                            Ativo
                                        </Button>
                                        <Button outline active={modulo.active === "0"} onClick={() => setModulo({ ...modulo, active: "0" })} color="danger">
                                            Inativo
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label>Funcionalidades</Label>
                                        <Row>
                                            <Col xs="6">
                                                <Input
                                                    placeholder="Pesquisar Funcionalidade"
                                                    autoComplete="off"
                                                    name="funcionalidade"
                                                    onChange={handleSearch}
                                                    className="mb-2" />
                                                {props.loadingFuncionalidades ?
                                                    <div className="loader-box">
                                                        <div className="loader-1"></div>
                                                    </div>
                                                    :
                                                    !!funcionalidades.length ?
                                                        <ScrollArea vertical={true} horizontal={false}>
                                                            <ListGroup>
                                                                {funcionalidades.map(({ name, id }, key) => {
                                                                    return (
                                                                        <ListGroupItem
                                                                            key={key}
                                                                            className="list-group-item-action"
                                                                            onClick={() => {
                                                                                const funcionalidades = [...modulo.funcionalidades];
                                                                                const index = funcionalidades.findIndex((el) => el.id === id);
                                                                                if (index !== -1) {
                                                                                    funcionalidades.splice(index, 1);
                                                                                } else {
                                                                                    funcionalidades.push({ id, name });
                                                                                }
                                                                                setModulo({ ...modulo, funcionalidades })
                                                                            }}
                                                                            style={{ cursor: 'pointer' }}>
                                                                            <Input
                                                                                className="checkbox_animated"
                                                                                checked={!!modulo.funcionalidades.filter((el) => el.id === id).length}
                                                                                onChange={() => { }}
                                                                                id={`chk-ani${key}`}
                                                                                type="checkbox" />
                                                                            {name}
                                                                        </ListGroupItem>
                                                                    );
                                                                })
                                                                }
                                                            </ListGroup>
                                                        </ScrollArea>
                                                        :
                                                        <Alert color="light">Nenhuma Funcionalidade foi Encontrada</Alert>
                                                }
                                            </Col>
                                            <Col xs="6">
                                                <div
                                                    className="mb-2"
                                                    style={{
                                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                                        padding: '0.375rem 0.75rem',
                                                        fontSize: '1rem'
                                                    }}>
                                                    <span className="pull-right">{`Selecionados ${modulo.funcionalidades.length}`}</span>
                                                </div>
                                                <ScrollArea vertical={true} horizontal={false} >
                                                    <ListGroup>
                                                        {!!modulo.funcionalidades.length ?
                                                            modulo.funcionalidades.map(({ name, id }, index) => {
                                                                return (
                                                                    <ListGroupItem
                                                                        key={index}
                                                                        className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                        onClick={() => {
                                                                            const funcionalidades = [...modulo.funcionalidades];
                                                                            funcionalidades.splice(index, 1);
                                                                            setModulo({ ...modulo, funcionalidades })
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        {name}
                                                                        <span>X</span>
                                                                    </ListGroupItem>
                                                                );
                                                            })
                                                            :
                                                            <Alert color="light">Nenhuma Funcionalidade Selecionada</Alert>
                                                        }
                                                    </ListGroup>
                                                </ScrollArea>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => props.clickClose()} type="button">Fechar</Button>
                        <Button color="primary" disabled={props.loadingUpdModulo} type="submit">{props.loadingUpdModulo ? 'Processando...' : 'Adicionar'}</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ Funcionalidades, Modulos }) => {
    const { list: funcionalidades, loadingList: loadingFuncionalidades } = Funcionalidades;
    const { modulos, loadingUpdModulo } = Modulos;
    return { funcionalidades, loadingFuncionalidades, modulos, loadingUpdModulo };
}

export default connect(mapStateToProps, {
    getListFuncionalidades,
    storeModulos,
    updateModulos
})(EditModulo);