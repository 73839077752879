import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";

function FirstCard({
    handleChange,
    handleChangeSelect,
    validator,
    isInvalid,
    validFields,
    fields,
    errors
}) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Nome*</Label>
                            <Input
                                name="tps_nm_tipo"
                                placeholder="Informe o Nome"
                                maxLength={40}
                                onChange={handleChange}
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={isInvalid('tps_nm_tipo')}
                            />
                            <FormFeedback>
                                {validator.message('tps_nm_tipo', fields.tps_nm_tipo, 'required|max:40')}
                                {(!!errors.tps_nm_tipo) && errors.tps_nm_tipo.map((erro, index) => <span key={index}>{erro}</span>)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Tipo Documento</Label>
                            <Typeahead
                                id="selectDocumento"
                                labelKey="name"
                                placeholder="Informe o Tipo Documento"
                                onChange={(selected) => handleChangeSelect("tps_tp_documento", selected)}
                                options={[
                                    { name: 'CPF', value: '1' },
                                    { name: 'CNPJ', value: '0' }
                                ]}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Descrição</Label>
                            <Input
                                name="tps_ds_tipo"
                                placeholder="Informe a Descrição"
                                onChange={handleChange}
                                onBlur={({ target: { name } }) => validFields(name)}
                                maxLength={100}
                                invalid={isInvalid('tps_ds_tipo')}
                            />
                            <FormFeedback>
                                {validator.message('tps_ds_tipo', fields.tps_ds_tipo, 'max:100')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>

                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(FirstCard)