import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Label, Row, InputGroup, InputGroupAddon } from "reactstrap";
import { getListCentroCusto } from "../../../../redux/actions";
import SelectPagination from "../../../../components/SelectPagination";
import NumberFormat from 'react-number-format';
const DadosGerais = ({
    handleChange,
    validator,
    fields,
    loadingListCentroCusto,
    listCentroCusto,
    handleChangeSelect,
    validFields,
    getListCentroCusto,
    handleValor,
}) => {
    const optionsCentroCusto = useMemo(() =>
        listCentroCusto.data.map(({ cct_nm_centro, cct_cd_centro }) =>
        ({
            name: `${cct_cd_centro} - ${cct_nm_centro}`,
            value: cct_cd_centro,
            cct_nm_centro,
            cct_cd_centro
        })),
        [listCentroCusto.data])
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Número do Documento</Label>
                                <Input
                                    readOnly
                                    defaultValue={fields.cnt_nu_doc}
                                    placeholder="Informe o número do documento"
                                    name="cnt_nu_doc"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={
                                        validator.visibleFields.includes('cnt_nu_doc') || (!validator.fields.cnt_nu_doc && validator.messagesShown)
                                    }
                                    onChange={handleChange}
                                />
                                <FormFeedback>
                                    {validator.message('cnt_nu_doc', fields.cnt_nu_doc, 'required|max:20')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Centro de Custo*</Label>
                                <SelectPagination
                                    id="selectCentroCusto"
                                    labelKey={(option) => `${option.name}`}
                                    clearButton
                                    placeholder="Selecione o Centro Custo"
                                    emptyLabel="Nenhum resultado encontrado"
                                    data={optionsCentroCusto}
                                    onBlur={() => validFields('cnt_cd_centro_custo')}
                                    reduxAction={getListCentroCusto}
                                    maxResults={50}
                                    isLoading={loadingListCentroCusto}
                                    isInvalid={validator.visibleFields.includes('cnt_cd_centro_custo') ||
                                        (!validator.fields.cnt_cd_centro_custo && validator.messagesShown)
                                    }
                                    totalResults={listCentroCusto.total}
                                    onChange={(selected) => handleChangeSelect('cnt_cd_centro_custo', selected)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('cnt_cd_centro_custo') ||
                                            (!validator.fields.cnt_cd_centro_custo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('cnt_cd_centro_custo', fields.cnt_cd_centro_custo, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Valor Contrapartida*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        readOnly
                                        // onBlur={({ target: { name } }) => validFields(name)}
                                        // invalid={validator.visibleFields.includes('cnt_vl_contrapartida') ||
                                        //     (!validator.fields.cnt_vl_contrapartida && validator.messagesShown)
                                        // }
                                        value={parseFloat(fields.cnt_vl_contrapartida)}
                                        name="cnt_vl_contrapartida"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ value }) => handleValor('cnt_vl_contrapartida', value)}
                                    />
                                </InputGroup>
                                {/* <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('cnt_vl_contrapartida') ||
                                            (!validator.fields.cnt_vl_contrapartida && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('cnt_vl_contrapartida', fields.cnt_vl_contrapartida,
                                        `required|verifica_valor:${fields.cnt_vl_contrapartida},${fields.conta?.ctn_vl_minimo_lan},${fields.conta?.ctn_vl_maximo_lan}`)}
                                </FormFeedback> */}
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ CentroCusto, Contas }) => {
    const { list: listCentroCusto, loadingList: loadingListCentroCusto } = CentroCusto;
    const { list: listContas } = Contas;
    return { listCentroCusto, loadingListCentroCusto, listContas }
}

export default connect(mapStateToProps, {
    getListCentroCusto
})(DadosGerais)