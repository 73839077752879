import { format, parseISO } from "date-fns";
import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";

function SecondCard({ notaFiscal, handleChangeSelect, validator, validFields }) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Vencimento</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.data_ven ? format(parseISO(notaFiscal.data_ven), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Saida/Entrada</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.dhSaiEnt ? format(parseISO(notaFiscal.dhSaiEnt), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Devolução</Label>
                            <DatePicker
                                locale={br}
                                autoComplete="off"
                                showTimeSelect
                                dateFormat="dd/MM/yyyy HH:mm"
                                timeCaption="Hora"
                                selected={!!notaFiscal.nfe_devoucao && parseISO(notaFiscal.nfe_devoucao)}
                                className="form-control digits"
                                placeholderText="Informe a data inicial"
                                onChange={(date) => handleChangeSelect('nfe_devoucao', date)}
                                isClearable
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Emissão da Devolução</Label>
                            <DatePicker
                                locale={br}
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy HH:mm"
                                selected={!!notaFiscal.dat_emi_devolucao && parseISO(notaFiscal.dat_emi_devolucao)}
                                showTimeSelect
                                timeCaption="Hora"
                                className="form-control digits"
                                placeholderText="Informe a data inicial"
                                onChange={(date) => handleChangeSelect('dat_emi_devolucao', date)}
                                isClearable
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default SecondCard;