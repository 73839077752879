import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from "date-fns";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";

function DadosGerais({
    custoProduto,
}) {
    const optionsTipos = [
        { name: 'Devolução', value: 'C' },
        { name: 'Entrada', value: 'E' },
        { name: 'Saida', value: 'S' }
    ];
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Produto</Label>
                            <Input
                                readOnly
                                defaultValue={custoProduto.cmv_prd_produto}
                                placeholder="Informe o Produto"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="7" xs="12">
                        <Label>Data</Label>
                        <FormGroup>
                            <Input
                                readOnly
                                defaultValue={!!custoProduto.cmv_dt_data ? format(parseISO(custoProduto.cmv_dt_data), 'dd/MM/yyyy') : ""} />
                        </FormGroup>
                    </Col>
                    <Col md="5" xs="12">
                        <FormGroup>
                            <Label>
                                Tipo
                            </Label>
                            <Typeahead
                                id="selectTipo"
                                labelKey="name"
                                disabled
                                defaultSelected={optionsTipos.filter((option) => option.value === custoProduto.cmv_tp_tipo)}
                                options={optionsTipos}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default DadosGerais;