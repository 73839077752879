import React, { useState, useCallback, useRef } from 'react';
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    FormFeedback,
    Form
} from 'reactstrap';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { parseISO, format } from 'date-fns';
import { fecharLancamentos } from "../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';

function FecharLancamentos({
    isOpen,
    fecharLancamentos,
    clickClose,
    errors,
    loadingFecharLan
}) {

    const [fields, setFields] = useState({ dtFechamento: null });

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
    })).current;

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    const clearErrorsForm = (name) => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }

    const submitFecharLan = e => {
        e.preventDefault();
        if (validator.allValid()) {
            fecharLancamentos({ fields, onSave: clickClose });
        } else {
            validator.showMessages();
        }
    }

    return (
        <Modal size="md" isOpen={isOpen}>
            <ModalHeader toggle={() => !loadingFecharLan && clickClose()}>Fechar Lançamentos</ModalHeader>
            <Form onSubmit={submitFecharLan}>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Data de Fechamento</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Selecione a Data de Fechamento"
                                    minDate={new Date(1930, 0, 1)}
                                    selected={!!fields.dtFechamento ? parseISO(fields.dtFechamento) : null}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    onCalendarClose={() => validFields('dtFechamento')}
                                    className={`form-control digits 
                                            ${validator.visibleFields.includes('dtFechamento') ||
                                            !!errors.dtFechamento ||
                                            (!validator.fields.dtFechamento && validator.messagesShown)
                                            ? 'is-invalid' : ''}`}
                                    onChange={(selected) => {
                                        if (!!selected) {
                                            setFields({ ...fields, dtFechamento: format(selected, 'yyyy-MM-dd') });
                                        } else {
                                            setFields({ ...fields, dtFechamento: "" });
                                        }
                                        clearErrorsForm('dtFechamento')
                                    }}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('dtFechamento') ||
                                            !!errors.dtFechamento ||
                                            (!validator.fields.dtFechamento && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}
                                >
                                    {validator.message('dtFechamento', fields.dtFechamento, `required`)}
                                    {(!!errors.lan_dt_competencia) && errors.lan_dt_competencia.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={loadingFecharLan} type="button" onClick={() => !loadingFecharLan && clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={loadingFecharLan} color="primary">
                        {loadingFecharLan ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Lancamentos }) => {
    const { errors, loadingFecharLan } = Lancamentos;
    return { errors, loadingFecharLan }
}

export default connect(mapStateToProps, {
    fecharLancamentos
})(FecharLancamentos);