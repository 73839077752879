import { toast } from "react-toastify";
import { all, fork, takeLatest, put, call, select } from "redux-saga/effects";
import Api from "../../services/api";
import {
    GET_LANCAMENTOS,
    GET_LANCAMENTOS_ASYNC,
    STORE_LANCAMENTOS_ASYNC,
    STORE_LANCAMENTOS,
    GET_LIST_BY_DATE_LANCAMENTOS_ASYNC,
    GET_LIST_BY_DATE_LANCAMENTOS,
    UPDATE_LANCAMENTOS_ASYNC,
    UPDATE_LANCAMENTOS,
    DESTROY_LANCAMENTOS_ASYNC,
    DESTROY_LANCAMENTOS,
    FECHAR_LANCAMENTOS_ASYNC,
    FECHAR_LANCAMENTOS,
    UPDATE_USER_EMPRESA,
    GET_LANCAMENTOS_FECHADOS_ASYNC,
    GET_LANCAMENTOS_FECHADOS,
    REABIR_LANCAMENTOS_ASYNC,
    REABIR_LANCAMENTOS
} from "../actionTypes";
import { selectorLancamentos, selectorActiveEmp } from "../selectors";


function* getLancamentosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetLancamentos, fields);
        if (data.ok) {
            const { lancamentos } = data;
            yield put({ type: GET_LANCAMENTOS, payload: { lancamentos } });
        }
    } catch (error) {
        yield put({ type: GET_LANCAMENTOS, payload: {} });
    }
}
const apiGetLancamentos = async fields => {
    const { data } = await Api.get(`/api/lancamentos`, { params: fields });

    return data;
}

function* storeLancamentoSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStoreLancamentos, fields);
        if (!!data && data.ok) {
            const { lancamentos } = data;
            yield put({ type: STORE_LANCAMENTOS, payload: { lancamentos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_LANCAMENTOS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_LANCAMENTOS, payload: {} });
    }
}
const apiStoreLancamentos = async fields => {
    const data = await Api.post(`/api/lancamentos/store`, { ...fields });

    return data;
}

function* getListByDateLancamentos({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListByDateLancamentos, fields);
        if (data.ok) {
            const { listByDate } = data;
            yield put({ type: GET_LIST_BY_DATE_LANCAMENTOS, payload: { listByDate } })
        }
    } catch (error) {
        yield put({ type: GET_LIST_BY_DATE_LANCAMENTOS, payload: {} })
    }
}
const apiGetListByDateLancamentos = async fields => {
    const { data } = await Api.get(`/api/lancamentos/listByDate`, { params: fields });

    return data;
}

function* updateLancamentosSaga({ payload }) {
    try {
        const { fields, index, onUpdate } = payload;
        const lancamentos = yield select(selectorLancamentos);
        const id = lancamentos.data[index].id;
        const { data, errors } = yield call(apiUpdateLancamentos, fields, id);
        if (!!data && data.ok) {
            const { lancamento } = data;
            lancamentos.data.splice(index, 1, lancamento);
            yield put({ type: UPDATE_LANCAMENTOS, payload: { lancamentos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onUpdate();
        }
        if (!!errors) {
            yield put({ type: UPDATE_LANCAMENTOS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_LANCAMENTOS, payload: {} })
    }
}
const apiUpdateLancamentos = async (fields, id) => {
    const data = await Api.put(`/api/lancamentos/update/${id}`, { ...fields });

    return data;
}

function* destroyLancamentosSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyLancamentos, fields);
        if (data) {
            const { lancamentos } = data;
            yield put({ type: DESTROY_LANCAMENTOS, payload: { lancamentos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_LANCAMENTOS, payload: {} });
    }
}
const apiDestroyLancamentos = async fields => {
    const { data } = await Api.delete(`/api/lancamentos/destroy`, { data: fields });

    return data;
}

function* fecharLancamentosSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiFecharLancamentos, fields);
        if (!!data && data.ok) {
            const { lancamentos } = data;
            yield put({ type: FECHAR_LANCAMENTOS, payload: { lancamentos } });

            const active_user_empresa = yield select(selectorActiveEmp);
            active_user_empresa.emp_dt_ult_fech = fields.dtFechamento;

            yield put({ type: UPDATE_USER_EMPRESA, payload: { active_user_empresa } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: FECHAR_LANCAMENTOS, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: FECHAR_LANCAMENTOS, payload: {} });
    }
}
const apiFecharLancamentos = async fields => {
    const data = await Api.put(`/api/lancamentos/fecharLancamentos`, { ...fields });

    return data;
}

function* getLancamentosFechadosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetLancamentosFechados, fields);
        if (data.ok) {
            const { lancamentosFechados } = data;
            yield put({ type: GET_LANCAMENTOS_FECHADOS, payload: { lancamentosFechados } })
        }
    } catch (error) {

    }
}
const apiGetLancamentosFechados = async fields => {
    const { data } = await Api.get(`/api/lancamentos/lancamentosFechados`, { params: fields });

    return data;
}

function* reabrirLancamentosSaga({ payload }) {
    try {
        const { fields,onSave } = payload;
        const data = yield call(apiReabrirLancamentos, fields);
        if (data.ok) {
            const { lancamentosFechados } = data;
            yield put({ type: REABIR_LANCAMENTOS, payload: { lancamentosFechados } });
            onSave();
        }
    } catch (error) {
        yield put({ type: REABIR_LANCAMENTOS, payload: {} });
    }
}
const apiReabrirLancamentos = async fields => {
    const { data } = await Api.put(`/api/lancamentos/reabrirLancamentos`, { ...fields });

    return data
}

function* watchGetLancamentos() {
    yield takeLatest(GET_LANCAMENTOS_ASYNC, getLancamentosSaga);
}
function* watchStoreLancamentos() {
    yield takeLatest(STORE_LANCAMENTOS_ASYNC, storeLancamentoSaga);
}
function* watchGetListByDateLancamentos() {
    yield takeLatest(GET_LIST_BY_DATE_LANCAMENTOS_ASYNC, getListByDateLancamentos);
}
function* watchUpdateLancamentos() {
    yield takeLatest(UPDATE_LANCAMENTOS_ASYNC, updateLancamentosSaga);
}
function* watchDestroyLancamentos() {
    yield takeLatest(DESTROY_LANCAMENTOS_ASYNC, destroyLancamentosSaga);
}
function* watchFecharLancamentos() {
    yield takeLatest(FECHAR_LANCAMENTOS_ASYNC, fecharLancamentosSaga);
}
function* watchGetLancamentosFechados() {
    yield takeLatest(GET_LANCAMENTOS_FECHADOS_ASYNC, getLancamentosFechadosSaga);
}
function* watchReabrirLancamentos() {
    yield takeLatest(REABIR_LANCAMENTOS_ASYNC, reabrirLancamentosSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetLancamentos),
        fork(watchStoreLancamentos),
        fork(watchGetListByDateLancamentos),
        fork(watchUpdateLancamentos),
        fork(watchDestroyLancamentos),
        fork(watchFecharLancamentos),
        fork(watchGetLancamentosFechados),
        fork(watchReabrirLancamentos)
    ]);
}