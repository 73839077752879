import React from "react";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";

function SecondCard({
    tipoPessoa
}) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12" className="d-flex justify-content-center">
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-mr">
                            <Label>Situação Documento*:</Label>
                            <div className="radio radio-primary m-l-10 m-r-20">
                                <Input
                                    id="radioinline1"
                                    name="tps_st_documento"
                                    defaultChecked={tipoPessoa.tps_st_documento === "1"}
                                    value="1"
                                    type="radio"
                                    readOnly
                                />
                                <Label className="mb-0" for="radioinline1">Sim</Label>
                            </div>
                            <div className="radio radio-primary m-l-20 m-r-20">
                                <Input
                                    id="radioinline2"
                                    name="tps_st_documento"
                                    value="0"
                                    defaultChecked={tipoPessoa.tps_st_documento === "0"}
                                    type="radio"
                                    readOnly
                                />
                                <Label className="mb-0" for="radioinline2">Não</Label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12" className="d-flex justify-content-center">
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-mr">
                            <Label>Situação Importado*:</Label>
                            <div className="radio radio-primary m-l-10 m-r-20">
                                <Input
                                    id="radioinline3"
                                    name="tps_st_importado"
                                    defaultChecked={tipoPessoa.tps_st_importado === "1"}
                                    value="1"
                                    type="radio"
                                    readOnly
                                />
                                <Label className="mb-0" for="radioinline3">Sim</Label>
                            </div>
                            <div className="radio radio-primary m-l-20 m-r-20">
                                <Input
                                    id="radioinline4"
                                    name="tps_st_importado"
                                    defaultChecked={tipoPessoa.tps_st_importado === "0"}
                                    value="0"
                                    type="radio"
                                    readOnly
                                />
                                <Label className="mb-0" for="radioinline4">Não</Label>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default SecondCard;