import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import MultiSelect from "../../../../components/MultiSelect";
import { getListContasEmpresa } from "../../../../redux/actions";

function Contas({
    fields,
    handleChangeSelect,
    listContasEmpresa,
    loadingListContasEmp,
    getListContasEmpresa
}) {

    const optionsContas = useMemo(() =>
        listContasEmpresa.data.map(({ ctn_cd_conta, ctn_nm_conta, id, ctn_cd_empresa }) => ({
            name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
            value: id, id,
            ctn_cd_empresa
        })),
        [listContasEmpresa.data])
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Selecione as Contas</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Empresa da Conta</Label>
                                <Typeahead
                                    id="selectedEmpPadrao"
                                    labelKey="name"
                                    options={fields.empresas}
                                    placeholder="Selecione a Empresa da Conta"
                                    selected={fields.empresas.filter((el) => el.emp_cd_empresa === fields.empresaConta)}
                                    clearButton
                                    emptyLabel="Selecione empresas acima"
                                    onChange={(selected) => handleChangeSelect('empresaConta', selected)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Contas</Label>
                                <MultiSelect
                                    data={!!fields.empresaConta ? optionsContas : []}
                                    onSearch={(value) => {
                                        const filter = optionsContas.filter(({ name }) => new RegExp(`${value}`, 'i').test(name));
                                        if (!filter.length) {
                                            getListContasEmpresa({ fields: { empresaCod: fields.empresaConta, name: value } });
                                        }
                                    }}
                                    labelKey="name"
                                    totalResults={listContasEmpresa.total}
                                    defaultSelected={fields.contas.filter((ctn) => ctn.ctn_cd_empresa === fields.empresaConta)}
                                    onChange={(selected) => {
                                        const contas = [...fields.contas.filter((ctn) => ctn.ctn_cd_empresa !== fields.empresaConta)];

                                        contas.push(...selected);

                                        handleChangeSelect('contas', contas)
                                    }}
                                    isLoading={loadingListContasEmp}
                                    pagination
                                    emptyLabel="Nehuma conta encontrada, Informe outra empresa das contas acima"
                                    onPaginate={(offset, name) => {
                                        getListContasEmpresa({ fields: { empresaCod: fields.empresaConta, offset, name } })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

export const mapStateToProps = ({ Contas }) => {
    const { listContasEmpresa, loadingListContasEmp } = Contas;
    return { listContasEmpresa, loadingListContasEmp }
}

export default connect(mapStateToProps, {
    getListContasEmpresa
})(Contas);