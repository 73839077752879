import React, { useEffect, useState, useCallback } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, Button, Form, Input, FormGroup, Label, Badge } from 'reactstrap';
import { getPessoas, destroyPessoas } from "../../../redux/actions";
import { connect } from "react-redux";
import sweetalert2 from "sweetalert2";
import DataTable from 'react-data-table-component';
import Add from './Add/';
import Edit from './Edit/';
import View from "./View/";
const Pessoas = ({
    getPessoas,
    pessoas,
    loading,
    destroyPessoas,
    loadingDestroy,
}) => {
    useEffect(() => {
        getPessoas()
    }, []);
    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedPessoa, setSelectedPessoa] = useState(null);
    const [filter, setFilter] = useState({ name: null, codigo: null, empresa: null });
    const [curFilter, setCurFilter] = useState(null);

    //VARIÁVEIS

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, pss_nm_pessoa }) => ({ id, pss_nm_pessoa })))
    }, []);
    const handleFilter = e => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    }
    const deletePessoas = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ pss_nm_pessoa }) => {
                return (`<li class="list-group-item-action list-group-item">${pss_nm_pessoa}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!pessoas && pessoas.current_page !== 1) ? pessoas.current_page : 1;
            const fields = { pessoas_ids: selectedRows, page, curFilter };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyPessoas({ fields, onDelete });
        }
    }

    const submitFilter = e => {
        e.preventDefault();
        getPessoas({ fields: filter });
        setCurFilter(filter)
    }

    return (
        <>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} selectedPessoa={selectedPessoa} />
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} selectedPessoa={selectedPessoa} />
            <Breadcrumb parent="Cadastros" title="Pessoas" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                        <Button
                            sid="pessoas.store"
                            color="primary"
                            size="md"
                            onClick={() => setOpenModal('add')}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                        </Button>
                    </Col>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="8" xs="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    placeholder="Informe o Nome"
                                                    maxLength={60}
                                                    name="name"
                                                    onChange={handleFilter}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Label>Código</Label>
                                                <Input
                                                    placeholder="Informe o Código"
                                                    name="codigo"
                                                    maxLength={5}
                                                    onChange={handleFilter}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    className="m-t-30"
                                                    disabled={loading}
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow shadow-showcase">
                            <CardBody className="p-t-20">
                                {loading ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!pessoas &&
                                    <DataTable
                                        persistTableHead
                                        selectableRows
                                        pagination
                                        paginationServer
                                        clearSelectedRows={toggleCleared}
                                        onSelectedRowsChange={handleSelectedRows}
                                        paginationPerPage={pessoas.per_page}
                                        paginationTotalRows={pessoas.total}
                                        paginationDefaultPage={pessoas.current_page}
                                        onChangePage={(page) => getPessoas({ fields: { page } })}
                                        progressPending={loading || loadingDestroy}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                            rangeSeparatorText: 'de',
                                        }}
                                        progressComponent={
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        }
                                        contextActions={
                                            <Button
                                                sid="pessoas.destroy"
                                                onClick={deletePessoas}
                                                disabled={loadingDestroy}
                                                color="danger">{loadingDestroy ? 'Processando...' : 'Remover Pessoas(s)'}
                                            </Button>
                                        }
                                        noDataComponent={<span className="p-20">Nenhuma Pessoa Cadastrado</span>}
                                        contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                        columns={[
                                            {
                                                name: 'Código',
                                                selector: 'pss_cd_pessoa',
                                                center: true

                                            },
                                            {
                                                name: 'Nome',
                                                selector: 'pss_nm_pessoa',
                                                center: true

                                            },
                                            {
                                                name: 'Num. Idenfiticação',
                                                selector: 'pss_nu_identificacao',
                                                center: true
                                            },
                                            {
                                                name: 'Contas Vinculadas',
                                                selector: 'contas_vinculadas',
                                                center: true
                                            },
                                            {
                                                name: 'Ações',
                                                selector: 'acoes',
                                                center: true
                                            }
                                        ]}
                                        data={pessoas.data.map(({ id, pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao, contas, empresa }, key) => {
                                            const contas_vinculadas = !!contas.length ? <Badge color="success">Sim</Badge> : <Badge color="danger">Não</Badge>;
                                            const acoes = (
                                                <div style={{ display: "flex", overflow: "auto" }}>
                                                    <a
                                                        sid="pessoas.index"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedPessoa(key);
                                                            setOpenModal('view');
                                                        }}
                                                    >
                                                        <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                    </a>
                                                    <a
                                                        sid="pessoas.update"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedPessoa(key);
                                                            setOpenModal('edit');
                                                        }}
                                                    >
                                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                    </a>

                                                </div>
                                            )
                                            return ({ id, pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao, contas_vinculadas, acoes });
                                        })}

                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
const mapStateToProps = ({ Pessoas, Empresas }) => {
    const { pessoas, loading, loadingDestroy } = Pessoas;
    return { pessoas, loading, loadingDestroy }
}
export default connect(mapStateToProps, {
    getPessoas,
    destroyPessoas,
})(Pessoas);