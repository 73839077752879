import React from "react";
import { FormGroup, Row, Col, Label, Input, FormFeedback } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
function DadosGerais({
    handleChange,
    handleChangeSelect,
    validator,
    fields,
    validFields
}) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Nome*</Label>
                            <Input
                                maxLength={60}
                                onChange={handleChange}
                                name="prd_nm_produto"
                                placeholder="Informe o Nome"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('prd_nm_produto') ||
                                    (!validator.fields.prd_nm_produto && validator.messagesShown)}
                            />
                            <FormFeedback>
                                {validator.message('prd_nm_produto', fields.prd_nm_produto, 'required|max:60')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Sigla*</Label>
                            <Input
                                name="prd_nm_sigla"
                                onChange={handleChange}
                                maxLength={15}
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('prd_nm_sigla') ||
                                    (!validator.fields.prd_nm_sigla && validator.messagesShown)}
                                placeholder="Informe a Sigla" />
                            <FormFeedback>
                                {validator.message('prd_nm_sigla', fields.prd_nm_sigla, 'required|max:15')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Situação*</Label>
                            <Typeahead
                                id="selectSitu"
                                labelKey="name"
                                clearButton
                                options={[{ name: 'Ativo', value: 'S' }, { name: 'Inativo', value: 'N' }]}
                                placeholder="Informe a Situação"
                                onBlur={() => validFields('prd_st_ativo')}
                                onChange={(selected) => handleChangeSelect('prd_st_ativo', selected)}
                                isInvalid={validator.visibleFields.includes('prd_st_ativo') ||
                                    (!validator.fields.prd_st_ativo && validator.messagesShown)}
                            />
                            <FormFeedback style={{
                                display: validator.visibleFields.includes('prd_st_ativo') ||
                                    (!validator.fields.prd_st_ativo && validator.messagesShown) ? 'block' : 'none'
                            }}>
                                {validator.message('prd_st_ativo', fields.prd_st_ativo, 'required|max:15')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default DadosGerais;