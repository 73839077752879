import axios from "axios";
import { store } from "../redux/store";

const apiEdocs = axios.create({
    baseURL: 'http://165.22.8.180:8080/'
});

apiEdocs.interceptors.request.use(async config => {
    const tokenEdocs = store.getState().EDocs.tokenEdocs;
    if (tokenEdocs) {
        config.headers.Authorization = `Bearer ${tokenEdocs}`;
    }
    return config;
});


export default apiEdocs