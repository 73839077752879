import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { parseISO, format } from 'date-fns';


const DadosGerais = ({
    fatura
}) => {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Contrapartida*</Label>
                                <Input
                                    defaultValue={`${fatura.infos_contrapartida?.cnt_ds_historico}`}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Número da Fatura</Label>
                                <Input  defaultValue={fatura.ftr_nu_fatura} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Data de Vencimento</Label>
                                <Input defaultValue={format(parseISO(fatura.ftr_dt_vencimento), 'dd/MM/yyyy')} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Tipo de Baixa</Label>
                                <Input defaultValue={fatura.ftr_tp_baixa === "A" ? "Automática" : "Manual"} readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Valor da Fatura</Label>
                                <Input
                                    defaultValue={!!fatura.ftr_vl_fatura ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(fatura.ftr_vl_fatura)
                                        :
                                        'Não Informado'
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col >
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas } = Contas;
    return { listContas }
}
export default connect(mapStateToProps, {})(DadosGerais)