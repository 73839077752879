import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";
import { getListContas } from "../../../../redux/actions";

const Conta = ({
    lancamento,
}) => {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label>
                        <h6>Vincular Conta</h6>
                    </Label> */}
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Conta</Label>
                                <Input defaultValue={`${lancamento.conta?.ctn_cd_conta} -  ${lancamento.conta?.ctn_nm_conta}`} readOnly />
                            </FormGroup>
                        </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Histórico</Label>
                                    <Input defaultValue={lancamento.lan_ds_historico} readOnly />
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Pessoa</Label>
                                    <Input defaultValue={`${lancamento.pessoa?.pss_cd_pessoa} - ${lancamento.pessoa?.pss_nm_pessoa}`} readOnly />
                                </FormGroup>
                            </Col>
                            <Col xs="12" className="m-b-10">
                                <Label>Tipo de Lançamento</Label>
                                <FormGroup>
                                    <Input readOnly defaultValue={lancamento.lan_tp_lancamento === 'D' ? 'Débito' : 'Crédito'} />
                                </FormGroup>
                            </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {

    return {}
}

export default connect(mapStateToProps, {
    getListContas
})(Conta);