import React, { useState, useCallback, useRef } from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Form } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { validatorMessages } from "../../../../constraints/variables";
import { getListContas, getListCentroCusto, storeLancamentos } from "../../../../redux/actions";
import Conta from "./Conta";
import DadosGerais from "./DadosGerais";

const initialState = {
    cnt_cd_conta: "",
    cnt_cd_subconta: "",
    cnt_vl_contrapartida: "",
    cnt_ch_documento: "S",
    cnt_cd_centro_custo: "",
    cnt_ds_historico: "",
    cnt_nu_doc: "",
    conta: {},
    pessoa: {},
    centro_custo: {},
}
function GerarLan({
    isOpen,
    clickClose,
    dados,
    listContaPessoas,
    getListContas,
    getListCentroCusto,
    storeLancamentos,
    loadingStoreLan,
    onCreate
}) {

    const [fields, setFields] = useState({ ...initialState });

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
        validators: {
            verifica_valor: {
                message: 'O valor deve está entre o mínimo e máximo da conta.',
                rule: (val, params) => {
                    const valor_contrapartida = parseFloat(params[0]);
                    const valor_minimo = parseFloat(params[1]);
                    const valor_maximo = parseFloat(params[2]);

                    return valor_contrapartida >= valor_minimo && valor_contrapartida <= valor_maximo;
                }
            }
        }
    })).current;

    //FUNÇÕES
    const handleChange = e => {
        const { name, value } = e.target;

        switch (name) {
            default:
                setFields({ ...fields, [name]: value })
                break;
        }
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'cnt_cd_conta':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].ctn_cd_conta,
                        cnt_ds_historico: selected[0].ctn_ds_hist_padrao,
                        cnt_cd_subconta: selected[0]?.def_pessoa?.pss_cd_pessoa,
                        conta: selected[0],
                        pessoa: {
                            value: selected[0]?.def_pessoa?.id,
                            pss_nm_pessoa: selected[0]?.def_pessoa?.pss_nm_pessoa,
                            pss_cd_pessoa: selected[0]?.def_pessoa?.pss_cd_pessoa,
                            pss_nu_identificacao: selected[0]?.def_pessoa?.pss_nu_identificacao
                        }
                    });
                    listContaPessoas.data = [];
                    listContaPessoas.total = 0;
                    // getListContaPessoas({ conta: selected[0].ctn_cd_conta, fields: { } });
                } else {
                    setFields({
                        ...fields,
                        [name]: "",
                        cnt_ds_historico: "",
                        cnt_cd_subconta: "",
                        conta: {},
                    });
                }
                break;
            case 'cnt_cd_subconta':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].pss_cd_pessoa, pessoa: selected[0] });
                } else {
                    setFields({ ...fields, [name]: "", pessoa: {} });
                }
                break;
            case 'cnt_cd_centro_custo':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].value,
                        centro_custo: { cct_nm_centro: selected[0].cct_nm_centro, cct_cd_centro: selected[0].cct_cd_centro }
                    }
                    );
                } else {
                    setFields({ ...fields, [name]: "", centro_custo: {} });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }
    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFields({ ...fields, [name]: value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const onSubmitCnt = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const lancamento = {
                lan_tp_lancamento: dados.tipo,
                lan_ch_documento: "S",
                lan_st_fechamento: "F",
                lan_ds_historico: dados?.desc,
                lan_nu_doc: dados.documento,
                lan_cd_conta: dados.ctn_cd_conta,
                lan_cd_subconta: dados.pessoa?.pss_cd_pessoa,
                lan_dt_competencia: dados.dia,
                lan_dt_lancamento: dados.dia,
                lan_vl_lancamento: fields.cnt_vl_contrapartida,
                lan_cd_centro_custo: fields.cnt_cd_centro_custo,
                contra_partidas: [{
                    ...fields,
                    faturas: []
                }]
            }

            storeLancamentos({ fields: lancamento, onCreate })
        }else{
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }

    }

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
                const cnt_vl_contrapartida = dados.valor < 0 ? (dados.valor * -1) : dados.valor;
                setFields(prev => ({ ...prev, cnt_nu_doc: dados.documento, cnt_vl_contrapartida }))
                getListContas();
                getListCentroCusto();
            }}
        >
            <ModalHeader toggle={() => !loadingStoreLan && clickClose()}>
                Incluir Contrapartida
          </ModalHeader>
            <Form onSubmit={onSubmitCnt}>
                <ModalBody>
                    <Row>
                        <Conta
                            fields={fields}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                            validator={validator}
                            validFields={validFields}
                        />
                        <DadosGerais
                            fields={fields}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                            handleValor={handleValor}
                            validFields={validFields}
                            validator={validator}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => !loadingStoreLan && clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={loadingStoreLan} color="primary" type="submit">
                        {loadingStoreLan ? 'Processando...' : 'Incluir'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
const mapStateToProps = ({ Contas, Lancamentos }) => {
    const { listContaPessoas } = Contas;
    const { loadingStore: loadingStoreLan } = Lancamentos
    return { listContaPessoas, loadingStoreLan }
}

export default connect(mapStateToProps, {
    getListContas,
    getListCentroCusto,
    storeLancamentos
})(GerarLan);