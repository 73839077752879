import React from "react";
import { Col, FormGroup, Label, Row,Input } from "reactstrap";
import InputMask from "react-input-mask";

const Endereco = ({ pessoa }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Endereço</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Telefone</Label>
                                <Input
                                    placeholder="Informe o Telefone"
                                    tag={InputMask}
                                    defaultValue={pessoa.pss_nu_telefone}
                                    mask={(!!pessoa.pss_nu_telefone && pessoa.pss_nu_telefone.length <= 10) ? '(99) 9999-99999' : '(99) 99999-9999'}
                                    name="pss_nu_telefone"
                                    readOnly
                                    maskChar={null}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Endereço</Label>
                                <Input
                                    name="pss_ds_endereco"
                                    placeholder="Informe o Endereço"
                                    defaultValue={pessoa.pss_ds_endereco}
                                    maxLength={100}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>CEP</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Informe o CEP"
                                    maskChar={""}
                                    tag={InputMask}
                                    defaultValue={pessoa.pss_nu_cep}
                                    mask={'99999-999'}
                                    name="pss_nu_cep"
                                    readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input
                                    readOnly
                                    defaultValue={!!pessoa.estado ? pessoa.estado.est_nm_estado : 'Não Informado'}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Cidade</Label>
                                <Input
                                    readOnly
                                    defaultValue={!!pessoa.cidade ? pessoa.cidade.cid_nm_cidade : 'Não Informado'}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

export default Endereco;