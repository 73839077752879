import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function ThirdCard({ notaFiscal }) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="7" xs="12">
                        <FormGroup>
                            <Label>Empresa</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.empresa ? notaFiscal.empresa.emp_nm_empresa : 'Não Informada'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="5" xs="12">
                        <FormGroup>
                            <Label>CNPJ</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.CNPJ || 'Não Informada'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default ThirdCard;