import { call, put, fork, all, select, debounce, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
    GET_LIST_EMPRESAS_ASYNC,
    GET_LIST_EMPRESAS,
    GET_EMPRESAS_ASYNC,
    GET_EMPRESAS,
    STORE_EMPRESAS_ASYNC,
    STORE_EMPRESAS,
    UPDATE_EMPRESAS_ASYNC,
    UPDATE_EMPRESAS,
    DESTROY_EMPRESAS_ASYNC,
    DESTROY_EMPRESAS
} from "../actionTypes";
import Api from "../../services/api";
import { selectorEmpresas } from "../selectors";


function* getEmpresasSaga({ payload }) {
    try {
        const { fields } = payload;

        const data = yield call(apiGetEmpresas, fields);
        if (data.ok) {
            const { empEmpresas } = data;
            yield put({ type: GET_EMPRESAS, payload: { empEmpresas } });
        }
    } catch (error) {
        yield put({ type: GET_EMPRESAS, payload: {} })
    }
}
const apiGetEmpresas = async fields => {
    const { data } = await Api.get(`/api/emp_empresa`, { params: fields });

    return data;
}

function* getListEmpresasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListEmpresas, fields);
        if (data.ok) {
            if (!!fields && !!fields.offset) {
                const list = yield select(state => state.Empresas.list);
                list.data = [...list.data, ...data.list.data]
                list.total = data.list.total;
                yield put({ type: GET_LIST_EMPRESAS, payload: { list } });
            } else {
                yield put({ type: GET_LIST_EMPRESAS, payload: { list: data.list } });
            }
        }
    } catch (error) {
        yield put({ type: GET_LIST_EMPRESAS, payload: {} });
    }
}
const apiGetListEmpresas = async fields => {
    const { data } = await Api.get(`/api/emp_empresa/list`, { params: fields });

    return data;
}

function* storeEmpresaSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStoreEmpresa, fields);
        if (!!data && data.ok) {
            const { empEmpresas } = data;
            yield put({ type: STORE_EMPRESAS, payload: { empEmpresas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_EMPRESAS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_EMPRESAS, payload: {} });
    }
}
const apiStoreEmpresa = async fields => {
    const data = await Api.post(`/api/emp_empresa/store`, { ...fields });

    return data;
}

function* updateEmpresaSaga({ payload }) {
    try {
        const { fields, onSave, index } = payload;
        const empresas = yield select(selectorEmpresas);
        const id = empresas.data[index].id;
        const { data, errors } = yield call(apiUpdateEmpresa, fields, id);
        if (!!data && data.ok) {
            empresas.data.splice(index, 1, data.empresa);
            yield put({ type: UPDATE_EMPRESAS, payload: { empresas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }
        if (!!errors) {
            yield put({ type: UPDATE_EMPRESAS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_EMPRESAS, payload: {} });
    }
}
const apiUpdateEmpresa = async (fields, id) => {
    const data = await Api.put(`/api/emp_empresa/update/${id}`, { ...fields });

    return data;
}

function* destroyEmpresaSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyEmpresa, fields);
        if (data.ok) {
            const { empEmpresas } = data;
            yield put({ type: DESTROY_EMPRESAS, payload: { empEmpresas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_EMPRESAS, payload: {} });
    }
}
const apiDestroyEmpresa = async fields => {
    const { data } = await Api.delete(`/api/emp_empresa/destroy`, { data: { ...fields } });

    return data;
}

function* watchGetEmpresas() {
    yield takeLatest(GET_EMPRESAS_ASYNC, getEmpresasSaga);
}
function* watchGetListEmpresas() {
    yield debounce(1000, GET_LIST_EMPRESAS_ASYNC, getListEmpresasSaga);
}
function* watchStoreEmpresa() {
    yield takeLatest(STORE_EMPRESAS_ASYNC, storeEmpresaSaga);
}
function* watchUpdateEmpresa() {
    yield takeLatest(UPDATE_EMPRESAS_ASYNC, updateEmpresaSaga);
}
function* watchDestroyEmpresa() {
    yield takeLatest(DESTROY_EMPRESAS_ASYNC, destroyEmpresaSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListEmpresas),
        fork(watchGetEmpresas),
        fork(watchStoreEmpresa),
        fork(watchUpdateEmpresa),
        fork(watchDestroyEmpresa)
    ]);
}