import {
    GET_RLT_OPERACIONAL_LANCAMENTO_ASYNC,
    GET_RLT_OPERACIONAL_LANCAMENTO,
    CLEAR_RLT_OPERACIONAL_LANCAMENTO
} from "../actionTypes";

const initial_state = {
    rltOperacionalLan: [],
    loadingRltOperacinalLan: false
}

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_RLT_OPERACIONAL_LANCAMENTO_ASYNC:
            return { ...state, loadingRltOperacinalLan: true };
        case GET_RLT_OPERACIONAL_LANCAMENTO:
            return { ...state, ...action.payload, loadingRltOperacinalLan: false };
        case CLEAR_RLT_OPERACIONAL_LANCAMENTO:
            return { ...state, rltOperacionalLan: [] }
        default: return state;
    }
}