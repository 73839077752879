import { call, put, fork, all, select, debounce } from "redux-saga/effects";
import {
    GET_LIST_GRUPOS_PAI_ASYNC,
    GET_LIST_CONTAS_GRUPOS_PAI
} from "../actionTypes";
import { selectorGruposPaiList } from "../selectors";
import Api from "../../services/api";


function* getListContasGruposPaiSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apigetListContasGruposPai, fields);
        if (data.ok) {
            if (!!fields && !!fields.offset) {
                const list = yield select(selectorGruposPaiList);
                list.data = [...list.data, ...data.list.data]
                list.total = data.list.total;
                yield put({ type: GET_LIST_CONTAS_GRUPOS_PAI, payload: { list } });
            } else {
                yield put({ type: GET_LIST_CONTAS_GRUPOS_PAI, payload: { list: data.list } });
            }
        }
    } catch (error) {
        yield put({ type: GET_LIST_CONTAS_GRUPOS_PAI, payload: {} });
    }
}
const apigetListContasGruposPai = async fields => {
    const { data } = await Api.get(`/api/grupos_pai/list`, { params: fields });

    return data;
}

function* watchgetListContasGruposPai() {
    yield debounce(1000, GET_LIST_GRUPOS_PAI_ASYNC, getListContasGruposPaiSaga)
}

export default function* rootSaga() {
    yield all([
        fork(watchgetListContasGruposPai)
    ])
}