import React, { useMemo, useState } from 'react';
import { Col, FormGroup, Row, Label } from "reactstrap";
import { connect } from "react-redux";
import SelectPagination from "../../../../../components/SelectPagination";
import { getListContas } from "../../../../../redux/actions";

const labelsContas = [
    {
        label: 'Conta de Venda Débito',
        name: 'prd_cd_ctn_ven_deb',
        relationName: 'cnt_venda_debito'
    },
    {
        label: 'Conta de Venda Crédito',
        name: 'prd_cd_ctn_ven_cre',
        relationName: 'cnt_venda_credito'
    },
    {
        label: 'Conta de Venda CMV',
        name: 'prd_cd_ctn_ven_cmv',
        relationName: 'cnt_venda_cmv'
    }

];


function Contas({
    listContas,
    loadingListContas,
    getListContas,
    handleChangeSelect,
    fields

}) {

    const [indexFocusCtn, setIndexFocusCtn] = useState(null);

    const optionsContas = useMemo(() => {
        const res = listContas.data.map((conta) =>
            ({ name: `${conta.ctn_cd_conta} - ${conta.ctn_nm_conta}`, value: conta.ctn_cd_conta })
        );

        labelsContas.forEach(({ name, relationName }) => {

            if (!!fields[name] && !!fields[relationName] && !res.find((conta) => conta.value === fields[name])) {
                const conta = fields[relationName];
                res.push({
                    name: `${conta.ctn_cd_conta} - ${conta.ctn_nm_conta}`,
                    value: conta.ctn_cd_conta
                });
            }
        });
        return res;
    }, [listContas.data]);

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    {labelsContas.map(({ label, name }, index) => {
                        return (
                            <Col xs="12" key={index}>
                                <FormGroup>
                                    <Label>{label}</Label>
                                    <SelectPagination
                                        id={`selectConta${index}`}
                                        clearButton
                                        labelKey="name"
                                        onFocus={() => {
                                            setIndexFocusCtn(index);
                                        }}
                                        placeholder={`Informe a Conta de ${label}`}
                                        selected={(options) => options.filter((option) => option.value === fields[name])}
                                        maxResults={50}
                                        isLoading={loadingListContas && (indexFocusCtn === index || indexFocusCtn === null)}
                                        totalResults={listContas.total}
                                        onChange={selected => handleChangeSelect(name, selected)}
                                        data={optionsContas}
                                        reduxAction={getListContas}
                                    />
                                </FormGroup>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, loadingList: loadingListContas } = Contas;
    return { listContas, loadingListContas }
}

export default connect(mapStateToProps, {
    getListContas
})(Contas);