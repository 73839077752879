import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";
import { format, parseISO } from 'date-fns';
const Datas = ({ lancamento }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label>
                        <h6>Datas</h6>
                    </Label> */}
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Cadastrado em</Label>
                                <Input defaultValue={
                                    !!lancamento.lan_dt_cad &&
                                    format(parseISO(lancamento.lan_dt_cad), 'dd/MM/yyyy')
                                } readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Cadastrado por</Label>
                                <Input
                                    defaultValue={lancamento.user?.name}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Datas);