import { GET_LIST_CLIENTES_ASYNC, GET_LIST_CLIENTES_EMPRESA_ASYNC } from "../actionTypes";

export const getListClientes = (payload = {}) => ({
    type: GET_LIST_CLIENTES_ASYNC,
    payload
});

export const getListClientesEmp = payload => ({
    type: GET_LIST_CLIENTES_EMPRESA_ASYNC,
    payload
});