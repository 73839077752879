import React, { useState, useRef, useCallback, useMemo } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, ModalFooter, Button, Form, FormFeedback } from "reactstrap";
import { connect } from "react-redux";
import {
    getListGrupoPai,
    storeContasGrupos,
    changeListEmpresas,
    changeListGruposPai,
    clearErrorsContasGrupos
} from "../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import SelectPagination from "../../../../components/SelectPagination";
import { toast } from "react-toastify";

const initialState = { grc_nm_grupo: "", grc_cd_grupo: "", grc_cd_grupo_pai: "", grc_cd_empresa: "" };

const Add = ({
    clickClose,
    isOpen,
    listGrupoPai,
    loadingListGrupoPai,
    getListGrupoPai,
    storeContasGrupos,
    loadingStore,
    errors,
    clearErrorsContasGrupos
}) => {
    //ESTADOS
    const [fields, setFields] = useState(initialState);
    const [, updateState] = useState();

    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeContasGrupos({ fields, onCreate: clickClose });
        } else {
            toast.error("Preencha todos os campos corretamente.", toast.POSITION.TOP_RIGHT)
        }

    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    //VARIÁVEIS
    const optionsGruposPai = useMemo(() => listGrupoPai.data.map(({ cd_grupo_pai, name }) => {
        return ({ name, value: cd_grupo_pai })
    }), [listGrupoPai.data]);
    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        }
    })).current;

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onClosed={() => {
                clearErrorsContasGrupos({ errors: {} })
                setFields(initialState)
                validator.visibleFields = [];
            }}
        >
            <ModalHeader toggle={() => !loadingStore && clickClose()}>
                Adicionar Grupo
            </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col md="9" xs="12">
                            <FormGroup>
                                <Label>Nome*</Label>
                                <Input
                                    placeholder="Informe o nome do grupo"
                                    maxLength={60}
                                    name="grc_nm_grupo"
                                    invalid={validator.visibleFields.includes('grc_nm_grupo')}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    onChange={({ target: { name, value } }) => setFields({ ...fields, [name]: value })}
                                />
                                <FormFeedback>
                                    {validator.message('grc_nm_grupo', fields.grc_nm_grupo, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3" xs="12">
                            <FormGroup>
                                <Label>Código*</Label>
                                <Input
                                    maxLength={2}
                                    placeholder="Informe o código"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('grc_cd_grupo') || !!errors.grc_cd_grupo}
                                    name="grc_cd_grupo"
                                    onChange={({ target: { name, value } }) => {
                                        setFields({ ...fields, [name]: value })
                                        delete errors[name];
                                        clearErrorsContasGrupos({ errors })
                                    }}
                                />
                                <FormFeedback>
                                    {validator.message('grc_cd_grupo', fields.grc_cd_grupo, `required|max:2`)}
                                    {(!!errors.grc_cd_grupo) && errors.grc_cd_grupo.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Grupo Pai*</Label>
                                <SelectPagination
                                    id="selectGrupoPai"
                                    labelKey="name"
                                    searchText="Procurando..."
                                    isInvalid={validator.visibleFields.includes('grc_cd_grupo_pai')}
                                    onBlur={() => { validFields('grc_cd_grupo_pai') }}
                                    maxResults={50}
                                    placeholder="Selecione o Grupo Pai"
                                    reduxAction={getListGrupoPai}
                                    clearButton
                                    data={optionsGruposPai}
                                    isLoading={loadingListGrupoPai}
                                    onChange={(grupos) => {
                                        if (!!grupos.length) {
                                            setFields({ ...fields, grc_cd_grupo_pai: grupos[0].value });
                                        } else {
                                            setFields({ ...fields, grc_cd_grupo_pai: "" });
                                        }
                                    }}
                                    selected={(options) => options.filter((option) => option.value === fields.grc_cd_grupo_pai)}
                                    totalResults={listGrupoPai.total}
                                />
                                <FormFeedback style={{ display: validator.visibleFields.includes('grc_cd_grupo_pai') ? 'block' : 'none' }}>
                                    {validator.message('grc_cd_grupo_pai', fields.grc_cd_grupo_pai, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => clickClose()} disabled={loadingStore} type="button">Fechar</Button>
                    <Button type="submit" disabled={loadingStore} color="primary">{loadingStore ? 'Processando...' : 'Salvar'}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ GruposPai, ContasGrupos }) => {
    const { loadingStore, errors } = ContasGrupos;
    const { list: listGrupoPai, loadingList: loadingListGrupoPai } = GruposPai;
    return { listGrupoPai, loadingListGrupoPai, loadingStore, errors }
}

export default connect(mapStateToProps, {
    getListGrupoPai,
    storeContasGrupos,
    changeListEmpresas,
    changeListGruposPai,
    clearErrorsContasGrupos
})(Add);