import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form, Row
} from "reactstrap";
import { listGroups, storeUsers, getListEmpresas, getListContasEmpresa } from "../../../../redux/actions";
import { format } from "date-fns";
import { testaCPF } from "../../../../helpers";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import DadosGerais from "./DadosGerais";
import Empresas from "./Empresas";
import NivelAcesso from "./NivelAcesso";
import Lancamentos from "./Lancamentos";
import Contas from "./Contas";
const initalState = {
    name: "",
    departamento_id: "",
    active: "",
    groups: [],
    email: "",
    password: "",
    password_confirmation: "",
    data_nascimento: "",
    cpf: "",
    gender: "",
    valor_minimo: "",
    valor_maximo: "",
    empresas: [],
    contas: [],
    empresaConta: ""
};

const Add = ({
    isOpen,
    clickClose,
    errors,
    loadingStore,
    getListEmpresas,
    listGroups,
    storeUsers,
    getListContasEmpresa
}) => {
    //ESTADOS
    const [fields, setFields] = useState(initalState);
    const [, updateState] = useState();
    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeUsers({ fields, onCreate: clickClose })
        } else {
            validator.showMessages();
            toast.error("Preencha todos os campos corretamente.", toast.POSITION.TOP_RIGHT)
        }
    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'password_confirmation':
            case 'password':
                setFields({ ...fields, [name]: value.trim() });
                break;
            default:
                setFields({ ...fields, [name]: value });
                break;
        }
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleLancamentos = (name, value) => {
        switch (name) {
            case 'valor_minimo':
            case 'valor_maximo':
                setFields({ ...fields, [name]: value })
                break;
            default:
                break;
        }
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'empresas':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected,
                        contas: fields.contas.filter((ctn) => selected.some((emp) => emp.emp_cd_empresa === ctn.ctn_cd_empresa)),
                        empresaConta: selected.find((el) => el.emp_cd_empresa === fields.empresaConta)?.emp_cd_empresa || ""
                    });
                } else {
                    setFields({ ...fields, [name]: [], contas: [], empresaConta: "" });
                }
                break;
            case 'groups':
                setFields({ ...fields, [name]: !!selected.length ? selected : [] });
                break;
            case 'empresa_padrao':
                setFields({
                    ...fields,
                    empresas: selected,
                });
                break;
            case 'data_nascimento':
                if (!!selected) {
                    setFields({ ...fields, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setFields({ ...fields, [name]: null });
                }
                break;
            case 'empresaConta':
                if (!!selected.length) {
                    setFields({ ...fields, empresaConta: selected[0].emp_cd_empresa })
                    getListContasEmpresa({ fields: { empresaCod: selected[0].emp_cd_empresa } })
                } else {
                    setFields({ ...fields, empresaConta: "" })
                }
                break;

            case 'contas':
                setFields({ ...fields, [name]: selected })
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        validators: {
            cpf: {
                message: 'CPF inválido.',
                rule: (val) => {
                    return testaCPF(val)
                }
            },
            name: {
                message: 'Nome inválido.',
                rule: (val) => {
                    return val.includes(" ");
                }
            },
            valor_minimo: {
                message: "Valor minímo dever ser menor que máximo",
                rule: (val, params) => {
                    if (!!params[1]) {
                        return parseFloat(params[1]) >= parseFloat(params[0]);
                    }
                }
            },
            valor_maximo: {
                message: "Valor máximo deve ser maior que o minímo.",
                rule: (val, params) => {
                    return parseFloat(params[0]) <= parseFloat(params[1]);
                }
            }

        },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.'
        }
    })).current;


    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onOpened={() => {
                listGroups();
                getListEmpresas();
            }}
            onClosed={() => {
                setFields(initalState)
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}>
            <ModalHeader toggle={() => clickClose()}>Incluir Usuário</ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <DadosGerais
                            fields={fields}
                            errors={errors}
                            handleChangeSelect={handleChangeSelect}
                            handleChange={handleChange}
                            validFields={validFields}
                            validator={validator}
                        />
                        <Empresas
                            fields={fields}
                            errors={errors}
                            handleChangeSelect={handleChangeSelect}
                            validator={validator}
                            validFields={validFields}
                            getListEmpresas={getListEmpresas}
                        />
                        <Contas
                            handleChangeSelect={handleChangeSelect}
                            fields={fields}
                        />
                        <NivelAcesso
                            fields={fields}
                            errors={errors}
                            handleChangeSelect={handleChangeSelect}
                            validator={validator}
                            validFields={validFields}
                        />
                        <Lancamentos
                            fields={fields}
                            validFields={validFields}
                            validator={validator}
                            handleLancamentos={handleLancamentos}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={loadingStore} onClick={() => clickClose()} type="button">Fechar</Button>
                    <Button color="primary" disabled={loadingStore} type="submit">{loadingStore ? 'Processando...' : 'Salvar'}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Users }) => {
    const { loadingStore, errors } = Users;
    return { loadingStore, errors };
}
export default connect(mapStateToProps, {
    listGroups,
    storeUsers,
    getListEmpresas,
    getListContasEmpresa
})(Add);