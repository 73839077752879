import React, { useState } from "react";
import { 
    Modal, 
    ModalBody, 
    ModalHeader, 
    Form, 
    Input, 
    Row, 
    Col, 
    FormGroup, 
    Label, 
    Alert, 
    ModalFooter, 
    ListGroupItem, 
    ListGroup, 
    Button 
} from "reactstrap";
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { listModulos, listGroups, updateFuncionalidade } from "../../../../redux/actions";
import ScrollArea from 'react-scrollbar';
const initalStateSearch = { permissao: "", group: "" };

const EditFuncionalidade = (
    {
        isOpen,
        clickClose,
        modulos,
        loadingModulos,
        listModulos,
        listGroups,
        permissions,
        loadingPermissions,
        groups,
        loadingGroups,
        funcionalidades,
        selectedFunc,
        onUpdate,
        updateFuncionalidade,
        loadingUpdateFunc
    }
) => {

    //STATES
    const [funcionalidade, setFuncionalidade] = useState(null);
    const [search, setSearch] = useState(initalStateSearch);

    //VÁRIAVEIS
    const optionsModulos = modulos.map(({ name, id }) => ({ name, id }));
    const filterPermissions = !!permissions && !!permissions.data ?
        permissions.data.filter(({ name }) => new RegExp(`${search.permissao}`, 'i').test(name)) : [];
    const filterGroups = groups.filter(({ name }) => new RegExp(`${search.group}`, 'i').test(name));

    //FUNÇÕES    
    const handleSearch = ({ target }) => {
        const { name, value } = target;
        setSearch({ ...search, [name]: value });
    }
    const submitForm = e => {
        e.preventDefault();
        const fields = { ...funcionalidade, page: funcionalidades.current_page };
        updateFuncionalidade({ fields, onUpdate });
    }
    return (
        <Modal isOpen={isOpen} size="lg"
            onOpened={() => {
                const dados = { ...funcionalidades.data[selectedFunc] };
                delete dados.modulo;
                delete dados.row_num;
                setFuncionalidade(dados)
                listModulos();
                listGroups();
            }}
            onClosed={() => {
                setFuncionalidade(null)
                setSearch(initalStateSearch)
            }}
        >
            <ModalHeader toggle={() => !loadingUpdateFunc && clickClose()}>Editar Funcionalidade</ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    {!!funcionalidade &&
                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input defaultValue={funcionalidade.name} onChange={({ target: { value } }) => setFuncionalidade({ ...funcionalidade, name: value })} />
                                </FormGroup>
                            </Col>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <Label>Módulo</Label>
                                    <Typeahead
                                        id="selectMod"
                                        labelKey="name"
                                        emptyLabel="Nenhum Módulo Cadastrado"
                                        placeholder="Informe o Módulo"
                                        selected={optionsModulos.filter(({ id }) => id == funcionalidade.modulo_id)}
                                        options={optionsModulos}
                                        isLoading={loadingModulos}
                                        onChange={(modulo) => {
                                            !!modulo.length ?
                                                setFuncionalidade({ ...funcionalidade, modulo_id: modulo[0].id })
                                                :
                                                setFuncionalidade({ ...funcionalidade, modulo_id: null })
                                        }}
                                        disabled={loadingModulos}

                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Permissões</Label>
                                    <Row>
                                        <Col xs="6">
                                            <Input
                                                placeholder="Pesquisar Permissões"
                                                autoComplete="off"
                                                name="permissao"
                                                onChange={handleSearch}
                                                className="mb-2" />
                                            {loadingPermissions ?
                                                <div className="loader-box">
                                                    <div className="loader-1"></div>
                                                </div>
                                                :
                                                !!filterPermissions.length ?
                                                    <ScrollArea vertical={true} horizontal={false}>
                                                        <ListGroup>
                                                            {filterPermissions.map(({ name, id }, key) => (
                                                                <ListGroupItem
                                                                    key={key}
                                                                    className="list-group-item-action"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        const permissions = [...funcionalidade.permissions];
                                                                        const index = permissions.findIndex((el) => el.id === id);
                                                                        if (index !== -1) {
                                                                            permissions.splice(index, 1);
                                                                        } else {
                                                                            permissions.push({ id, name })
                                                                        }
                                                                        setFuncionalidade({ ...funcionalidade, permissions });
                                                                    }}
                                                                    active={!!funcionalidade.permissions.filter((el) => el.id === id).length}
                                                                >
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        checked={!!funcionalidade.permissions.filter((el) => el.id === id).length}
                                                                        onChange={() => { }}
                                                                        id={`chk-ani${key}`}
                                                                        type="checkbox" />
                                                                    {name}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    </ScrollArea>
                                                    :
                                                    <Alert color="light">Nenhuma Permissão foi Encontrada</Alert>
                                            }
                                        </Col>
                                        <Col xs="6">
                                            <div
                                                className="mb-2"
                                                style={{
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0.375rem 0.75rem',
                                                    fontSize: '1rem'
                                                }}>
                                                <span className="pull-right">{`Selecionados ${funcionalidade.permissions.length}`}</span>
                                            </div>
                                            {!!funcionalidade.permissions.length ?
                                                <ScrollArea vertical={true} horizontal={false}>
                                                    <ListGroup>
                                                        {funcionalidade.permissions.map(({ name }, key) => (
                                                            <ListGroupItem
                                                                key={key}
                                                                className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    const permissions = [...funcionalidade.permissions];
                                                                    permissions.splice(key, 1);
                                                                    setFuncionalidade({ ...funcionalidade, permissions });
                                                                }}>
                                                                {name}
                                                                <span>X</span>
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                </ScrollArea>
                                                :
                                                <Alert color="light">Nenhuma Permissão foi Selecionada</Alert>
                                            }
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Perfis</Label>
                                    <Row>
                                        <Col xs="6">
                                            <Input
                                                placeholder="Pesquisar Perfis"
                                                autoComplete="off"
                                                name="group"
                                                onChange={handleSearch}
                                                className="mb-2" />
                                            {loadingGroups ?
                                                <div className="loader-box">
                                                    <div className="loader-1"></div>
                                                </div>
                                                :
                                                !!filterGroups.length ?
                                                    <ScrollArea vertical={true} horizontal={false}>
                                                        <ListGroup>
                                                            {filterGroups.map(({ name, id }, key) => {
                                                                return (
                                                                    <ListGroupItem
                                                                        key={key}
                                                                        className="list-group-item-action"
                                                                        onClick={() => {
                                                                            const groups = [...funcionalidade.groups];
                                                                            const index = groups.findIndex((el) => el.id === id);
                                                                            if (index !== -1) {
                                                                                groups.splice(index, 1);
                                                                            } else {
                                                                                groups.push({ id, name });
                                                                            }
                                                                            setFuncionalidade({ ...funcionalidade, groups })
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        <Input
                                                                            className="checkbox_animated"
                                                                            checked={!!funcionalidade.groups.filter((el) => el.id === id).length}
                                                                            onChange={() => { }}
                                                                            id={`chk-ani${key}`}
                                                                            type="checkbox" />
                                                                        {name}
                                                                    </ListGroupItem>
                                                                );
                                                            })}
                                                        </ListGroup>
                                                    </ScrollArea>
                                                    :
                                                    <Alert color="light">Nenhum Grupo foi Encontrado</Alert>
                                            }
                                        </Col>
                                        <Col xs="6">
                                            <div
                                                className="mb-2"
                                                style={{
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0.375rem 0.75rem',
                                                    fontSize: '1rem'
                                                }}>
                                                <span className="pull-right">{`Selecionados ${funcionalidade.groups.length}`}</span>
                                            </div>
                                            {!!funcionalidade.groups.length ?
                                                <ScrollArea vertical={true} horizontal={false}>
                                                    <ListGroup>
                                                        {funcionalidade.groups.map(({ name, id }, key) => {
                                                            return (
                                                                <ListGroupItem
                                                                    key={key}
                                                                    className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                    onClick={() => {
                                                                        const groups = [...funcionalidade.groups];
                                                                        groups.splice(key, 1);
                                                                        setFuncionalidade({ ...funcionalidade, groups })
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    {name}
                                                                    <span>X</span>
                                                                </ListGroupItem>
                                                            );
                                                        })}
                                                    </ListGroup>
                                                </ScrollArea>
                                                :
                                                <Alert color="light">Nenhum Grupo foi Selecionado</Alert>
                                            }
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button disabled={loadingUpdateFunc} onClick={() => clickClose()} type="button">Fechar</Button>
                    <Button type="submit" disabled={loadingUpdateFunc} color="primary">{loadingUpdateFunc ? 'Processando...' : 'Salvar'}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Modulos, Permissions, Groups, Funcionalidades }) => {
    const { list: modulos, loadingList: loadingModulos } = Modulos;
    const { list: groups, loadingList: loadingGroups } = Groups;
    const { permissions, loading: loadingPermissions } = Permissions;
    const { funcionalidades, loadingUpdateFunc } = Funcionalidades;
    return { modulos, loadingModulos, permissions, loadingPermissions, groups, loadingGroups, funcionalidades, loadingUpdateFunc };
}
export default connect(mapStateToProps, {
    listModulos,
    listGroups,
    updateFuncionalidade
})(EditFuncionalidade);