import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import sagas from "./sagas";
import permissions from "../services/permissions";
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['Auth', 'SelCentrosCusto', 'EDocs']
}

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
store.subscribe(() => {
    setTimeout(() => { permissions(store.getState().Auth.user) }, 0.1)
})
const persistor = persistStore(store);
sagaMiddleware.run(sagas);
export { store, persistor };
