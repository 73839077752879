import React from 'react';
import { Col, FormGroup, Row, Label, Input } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { connect } from "react-redux";

function Outros({
    handleChangeSelect,
    handleChange
}) {

    return (
        <div className="b-light border-2 p-10">
            <h6>Outras Informações</h6>
            <Row>
                <Col md="6" xs="12">
                    <FormGroup>
                        <FormGroup>
                            <Label>Produto</Label>
                            <Input
                                onChange={handleChange}
                                name="produto"
                                placeholder="Informe o Produto" />
                        </FormGroup>
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <FormGroup>
                        <Label>
                            Tipo
                            </Label>
                        <Typeahead
                            id="selectTipo"
                            labelKey="name"
                            clearButton
                            placeholder="Informe o Tipo"
                            options={[{ name: 'Entrada', value: 'E' }, { name: 'Saida', value: 'S' }]}
                            onChange={(selected) => handleChangeSelect('tipo', selected)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </ div>
    )
}

const mapStateToProps = ({ Auth }) => {
    const { active_user_empresa } = Auth;

    return { active_user_empresa };
}

export default connect(mapStateToProps, {})(Outros);