import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
    useRef,
    useCallback
} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback
} from 'reactstrap';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import {
    getListContasBancos,
    readOfxExtratoBanco,
    readTxtExtratoBanco,
    getFaturasConcilar,
    conciliarFaturas,
    clearExtratoBanco,
    clearConciliarFaturas,
    changeConcilarFaturas,
    concilarFaturasAuto
} from "../../../redux/actions";
import SelectPagination from "../../../components/SelectPagination";
import { format, parseISO } from 'date-fns';
import { validatorMessages } from "../../../constraints/variables";
import { ChevronsDown, ChevronsUp } from "react-feather";
import SimpleReactValidator from 'simple-react-validator';
import sweetalert2 from "sweetalert2";
import br from "date-fns/locale/pt-BR";
import { toast } from 'react-toastify';
import Extratos from "./Extratos";
import Conciliados from "./Conciliados";
import Faturas from "./Faturas";
import GerarLan from "./GerarLan/";
import Relatorio from "./Relatorio";
import ListConcAuto from "./ListConcAuto";

const ConcBancaria = ({
    getListContasBancos,
    listBancos,
    loadingListCtnBancos,
    readOfxExtratoBanco,
    readTxtExtratoBanco,
    getFaturasConcilar,
    loadingReadOfxBanco,
    conciliarFaturas,
    clearExtratoBanco,
    clearConciliarFaturas,
    loadingConciliarFtr,
    ftrParaConcilar,
    changeConcilarFaturas,
    extratoBanco,
    concilarFaturasAuto
}) => {
    useEffect(() => {
        getListContasBancos()
        return () => {
            clearExtratoBanco();
            clearConciliarFaturas();
        }
    }, []);

    //ESTADOS
    const [filter, setFilter] = useState({
        data: "",
        arquivoBanco: "",
        conciliarValor: false
    });
    const [fields, setFields] = useState({
        banco: ""
    });

    const [selectedExtrato, setSelectedExtrato] = useState(null);
    const [listConciliados, setListConciliados] = useState([]);
    const [selectedFtr, setSelectedFtr] = useState([]);
    const [selConciliados, setSelConciliados] = useState([]);
    const [openModal, setOpenModal] = useState(null);

    //REF
    const scrollExtratoRef = useRef();

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages
    })).current;

    //FUNÇÕES
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'data':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setFilter({ ...filter, [name]: "" });
                }
                scrollExtratoRef.current.scrollArea.scrollTop()
                break;
            case 'banco':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0] });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value });
                } else {
                    setFilter({ ...filter, [name]: null });
                }
                break;
        }
    }

    const handleChange = (e) => {
        const { checked, name, files } = e.target;

        switch (name) {
            case 'arquivoBanco':
                if (!!files.length) {
                    setFilter({ ...filter, [name]: files[0] });
                    readExtratoBanco(files[0]);
                }
                break;
            case 'conciliarValor':
                setFilter({ ...filter, [name]: checked })
                break;
        }

    }

    //VERIFICAR O TIPO DE ARQUIVO DO BANCO (EXTRATO)
    const readExtratoBanco = arquivoBanco => {

        const arquivoTipo = arquivoBanco.name.split('.');

        switch (arquivoTipo[arquivoTipo.length - 1].toLowerCase()) {
            case 'ofx':
                readOfxExtratoBanco({ fields: { arquivoBanco } });
                break;
            case '002':
            case 'txt':
                readTxtExtratoBanco({ fields: { arquivoBanco } });
                break;
        }
    }

    //AO SELECIONAR O EXTRATO PROCURA AS FATURAS CORRESPONDENTES
    const onSelectExtrato = extrato => {
        if (validator.allValid()) {
            const { conciliarValor } = filter;
            const { valor, dia } = extrato;
            const campos = {
                conciliarValor,
                valor,
                dia
            }
            if (!ftrParaConcilar.length || campos.conciliarValor || ftrParaConcilar[0]?.ftr_dt_vencimento.replace('00:00:00.000', '').trim() !== filter.data) {
                getFaturasConcilar({ fields: campos });
            }
            setSelectedExtrato(extrato.pos)
            setSelectedFtr([]);
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }

    }

    //AO SELECIONAR A OPÇÃO DE ADICIONAR CONTRAPARTIDA
    const onSelectTar = extrato => {
        if (validator.allValid()) {
            setSelectedExtrato(extrato.pos)
            setOpenModal('gerarLan');
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }

    //AO PRESSIONAR O BOTÃO DE CONCILAR A FATURA (BOTÃO COM SETAS PARA BAIXO)
    const handleConcilar = () => {
        selectedFtr.forEach(({ ftr_id_fatura }) => {
            const indexFtr = ftrParaConcilar.findIndex((el) => el.ftr_id_fatura === ftr_id_fatura);
            ftrParaConcilar.splice(indexFtr, 1);
        });

        setListConciliados([...listConciliados, ...selectedFtr]);
        setSelectedFtr([]);
        changeConcilarFaturas(ftrParaConcilar);
    }

    //AO PRESSIONAR O BOTÃO DE DESCONCILIAR A FATURA (BOTÃO COM SETAS PARA CIMA)
    const handleDesconciliar = () => {
        const conciliados = [...listConciliados];
        selConciliados.forEach(({ ftr_id_fatura }) => {
            const indexConc = conciliados.findIndex((el) => el.ftr_id_fatura === ftr_id_fatura);
            conciliados.splice(indexConc, 1);
        });

        changeConcilarFaturas([...ftrParaConcilar, ...selConciliados]);
        setListConciliados(conciliados);
        setSelConciliados([]);
    }

    //AO CONFIRMAR CONCILIAÇÃO 
    const submitConciliar = () => {
        if (validator.allValid()) {
            const onSave = () => {
                setListConciliados([]);
                setSelectedExtrato(null);
                readExtratoBanco(filter.arquivoBanco);
            }
            conciliarFaturas({ fields: { faturas: listConciliados, banco: fields.banco }, onSave });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }

    //VALIDADOR DE CAMPOS
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    //AO PRESSIONAR O BOTÃO DE GERAR O RELATÓRIO
    const onHandleRlt = () => {
        if (validator.allValid()) {
            setOpenModal('rltConc');
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }

    //AO PRESSIONAR A CONCILIAÇÃO AUTOMÁTICA
    const onHandleConcAuto = async extratos => {
        if (validator.allValid()) {
            const confirm = await sweetalert2.fire({
                title: 'Deseja realmente conciliar automaticamente?',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
                reverseButtons: true,
                icon: 'question',
            });

            if (confirm.isConfirmed) {
                const onSave = () => {
                    readExtratoBanco(filter.arquivoBanco);
                    setOpenModal('listConcAuto');
                }
                concilarFaturasAuto({ fields: { extratos: extratos.filter((ext) => !ext.chkConc), banco: fields.banco }, onSave });
            }

        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }

    //VARIÁVEIS
    const optionsBancos = useMemo(() =>
        listBancos.data.filter(({ pessoa }) => !!pessoa).map(({ ctn_nm_conta, ctn_cd_conta, pessoa, ctn_st_lan_deb_cre }) => ({
            name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
            value: ctn_cd_conta,
            tiposLancamento: ctn_st_lan_deb_cre,
            pessoa,
            ctn_cd_conta,
            ctn_nm_conta
        })),
        [listBancos.data]
    );

    return (
        <Fragment>
            <ListConcAuto
                isOpen={openModal === 'listConcAuto'}
                clickClose={() => {
                    setOpenModal(null)
                }}
            />
            <Relatorio
                fields={fields}
                filter={filter}
                extratoBanco={extratoBanco}
                isOpen={openModal === 'rltConc'}
                clickClose={() => {
                    setOpenModal(null)
                }}
            />

            <GerarLan
                isOpen={openModal === 'gerarLan'}
                clickClose={() => {
                    clearConciliarFaturas();
                    setOpenModal(null)
                    setSelectedExtrato(null)
                }}
                dados={{ ...extratoBanco?.extratos.find((ext) => ext.pos === selectedExtrato), ...fields.banco }}
                onCreate={() => {
                    setSelectedExtrato(null);
                    readExtratoBanco(filter.arquivoBanco);
                    setOpenModal(null)
                }}
            />
            <Breadcrumb parent="Operação" title="Conciliação Bancária" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>Arquivo*</Label>
                                            <div className="custom-file">
                                                <Input
                                                    className="custom-file-input"
                                                    name="arquivoBanco"
                                                    type="file"
                                                    accept=".ofx,.002,.txt"
                                                    required=""
                                                    onChange={handleChange}
                                                    onBlur={() => validFields('arquivoBanco')}
                                                />
                                                <Label className="custom-file-label">
                                                    {!filter.arquivoBanco ? 'Escolha o arquivo...' : filter.arquivoBanco.name}
                                                </Label>
                                            </div>
                                            <FormFeedback>
                                                {validator.message('arquivoBanco', filter.arquivoBanco, 'required')}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md="2" xs="12">
                                        <FormGroup>
                                            <Label>Data</Label>
                                            <DatePicker
                                                locale={br}
                                                disabled={!filter.arquivoBanco || loadingReadOfxBanco}
                                                autoComplete="off"
                                                clearButtonTitle="Limpar"
                                                isClearable
                                                placeholderText="Informe a data"
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control digits"
                                                selected={!!filter.data && parseISO(filter.data)}
                                                onChange={(date) => handleChangeSelect('data', date)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label>Banco*</Label>
                                            <SelectPagination
                                                id="selectBanco"
                                                labelKey="name"
                                                disabled={!filter.arquivoBanco || loadingReadOfxBanco}
                                                placeholder="Selecione o Banco"
                                                clearButton
                                                maxResults={50}
                                                isLoading={loadingListCtnBancos}
                                                totalResults={listBancos.total}
                                                data={optionsBancos}
                                                onBlur={() => validFields('banco')}
                                                isInvalid={
                                                    (validator.visibleFields.includes('banco') ||
                                                        (!validator.fields.banco && validator.messagesShown)) &&
                                                    !fields.banco
                                                }
                                                onChange={(selected) => handleChangeSelect('banco', selected)}
                                                reduxAction={getListContasBancos}
                                            />
                                            <FormFeedback style={{
                                                display: validator.visibleFields.includes('banco') ||
                                                    (!validator.fields.banco && validator.messagesShown) &&
                                                    !fields.banco
                                                    ? 'block' : 'none'
                                            }}>
                                                {validator.message('banco', fields.banco, 'required')}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4" xs="12">
                                        <FormGroup>
                                            <Label className="m-t-30">
                                                <Input
                                                    className="checkbox_animated"
                                                    name="conciliarValor"
                                                    onChange={handleChange}
                                                    id="chk-ani00"
                                                    type="checkbox" />Conciliar por valor se não encontrar a data
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Row>
                            <Col md="6" xs="12">
                                <Extratos
                                    filter={filter}
                                    onHandleRlt={onHandleRlt}
                                    ScrollExtratoRef={scrollExtratoRef}
                                    listConciliados={listConciliados}
                                    onSelectExtrato={onSelectExtrato}
                                    selectedExtrato={selectedExtrato}
                                    setSelectedExtrato={setSelectedExtrato}
                                    onSelectTar={onSelectTar}
                                    onHandleConcAuto={onHandleConcAuto}
                                />
                            </Col>
                            <Col md="6" xs="12">
                                <Row>
                                    <Col xs="12">
                                        <Faturas
                                            selectedExtrato={selectedExtrato}
                                            selectedFtr={selectedFtr}
                                            listConciliados={listConciliados}
                                            setSelectedFtr={setSelectedFtr}
                                        />
                                    </Col>
                                    <Col xs="12" className="d-flex justify-content-center m-b-10">
                                        <div className="d-flex justify-content-end" style={{ width: '80%' }}>
                                            <Button
                                                color="light"
                                                size="sm"
                                                className="m-r-40"
                                                disabled={loadingConciliarFtr}
                                                onClick={handleConcilar}
                                            >
                                                <ChevronsDown />
                                            </Button>
                                            <Button
                                                disabled={loadingConciliarFtr}
                                                onClick={handleDesconciliar}
                                                color="light"
                                                size="sm">
                                                <ChevronsUp />
                                            </Button>
                                        </div>
                                        <div className="d-flex justify-content-end" style={{ width: '50%' }}>
                                            <Button
                                                disabled={loadingConciliarFtr || !listConciliados.length}
                                                onClick={submitConciliar}
                                                color="primary">
                                                {loadingConciliarFtr ? 'Processando...' : 'Confirmar'}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs="12">
                                        <Conciliados
                                            selConciliados={selConciliados}
                                            listConciliados={listConciliados}
                                            setSelConciliados={setSelConciliados}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Contas, Arquivos, Faturas }) => {
    const { list_bancos: listBancos, loadingListCtnBancos } = Contas;
    const { loadingConciliarFtr, ftrParaConcilar } = Faturas;
    const { loadingReadOfxBanco, extratoBanco } = Arquivos;
    return {
        listBancos,
        loadingListCtnBancos,
        loadingReadOfxBanco,
        loadingConciliarFtr,
        ftrParaConcilar,
        extratoBanco
    }
}

export default connect(mapStateToProps, {
    getListContasBancos,
    readOfxExtratoBanco,
    readTxtExtratoBanco,
    getFaturasConcilar,
    conciliarFaturas,
    clearExtratoBanco,
    clearConciliarFaturas,
    changeConcilarFaturas,
    concilarFaturasAuto
})(ConcBancaria);