
import React, { useState, useCallback} from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { getCentrosCusto, destroyCentrosCusto } from "../../../redux/actions";
import { Button } from "reactstrap";
import sweetalert2 from "sweetalert2";
function Table({
    loadingCentrosCusto,
    centrosCusto,
    getCentrosCusto,
    setSelectedCct,
    setOpenModal,
    curFilter,
    destroyCentrosCusto,
    loadingDestroyCct
}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, cct_nm_centro }) => ({ id, cct_nm_centro })))
    }, []);

    const deleteCentrosCusto = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir os registros selecionados?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ cct_nm_centro }) => {
                return (`<li class="list-group-item-action list-group-item">${cct_nm_centro}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!centrosCusto && centrosCusto.current_page !== 1) ? centrosCusto.current_page : 1;
            const fields = { centrosCusto: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyCentrosCusto({ fields, onDelete });
        }
    }

    return (
        <>
            {loadingCentrosCusto ?
                <div className="loader-box">
                    <div className="loader-1"></div>
                </div>
                :
                !!centrosCusto &&
                <DataTable
                    persistTableHead
                    selectableRows
                    pagination
                    paginationServer
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={toggleCleared}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                    }}
                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                    progressComponent={
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    }
                    noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                    contextActions={
                        <Button
                            sid="centro_custo.destroy"
                            disabled={loadingDestroyCct}
                            onClick={deleteCentrosCusto}
                            color="danger">{loadingDestroyCct ? 'Processando...' : 'Remover Centros(s) de Custo'}
                        </Button>
                    }
                    progressPending={loadingDestroyCct}
                    paginationPerPage={centrosCusto.per_page}
                    paginationTotalRows={centrosCusto.total}
                    paginationDefaultPage={centrosCusto.current_page}
                    onChangePage={page => {
                        getCentrosCusto({ fields: { ...curFilter.current, page } })
                    }}
                    columns={[
                        {
                            name: 'Nome',
                            selector: 'cct_nm_centro',
                            center: true
                        },
                        {
                            name: 'Número de Identificação',
                            selector: 'nu_ident',
                            center: true
                        },
                        {
                            name: 'Ações',
                            selector: 'acoes',
                            center: true
                        }
                    ]}
                    data={centrosCusto.data.map(({ id, cct_nm_centro, cct_nu_identificacao }, index) => {

                        const nu_ident = cct_nu_identificacao || 'Não Informado';

                        const acoes = (
                            <div style={{ display: "flex", overflow: "auto" }}>
                                    <a
                                    style={{ cursor: 'pointer' }}
                                    title="Editar informações"
                                    onClick={() => {
                                        setSelectedCct(index)
                                        setOpenModal('view')
                                    }}
                                >
                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                </a>
                                <a
                                    sid="centro_custo.update"
                                    style={{ cursor: 'pointer' }}
                                    title="Editar informações"
                                    onClick={() => {
                                        setSelectedCct(index)
                                        setOpenModal('edit')
                                    }}
                                >
                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                </a>
                            </div>
                        )
                        return { id, cct_nm_centro, nu_ident, acoes }
                    })}
                />

            }
        </>
    )
}

const mapStateToProps = ({ CentroCusto }) => {
    const { loadingCentrosCusto, centrosCusto, loadingDestroyCct } = CentroCusto;
    return { loadingCentrosCusto, centrosCusto, loadingDestroyCct };
}

export default connect(mapStateToProps, {
    getCentrosCusto,
    destroyCentrosCusto
})(Table);