import React, { useCallback, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { Badge, Button } from "reactstrap";
import sweetalert2 from "sweetalert2";
import { destroyCustoProduto, getCustosProdutos } from "../../../../redux/actions";

function Table({
    loadingCustosProd,
    custosProdutos,
    setOpenModal,
    setSelectedCustoProd,
    curFilter,
    destroyCustoProduto,
    getCustosProdutos,
    loadingDestroyCustoProd
}) {

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ cmv_id, cmv_prd_produto }) => ({ cmv_id, cmv_prd_produto })))
    }, []);

    const deleteCustosProdutos = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir os registros selecionados?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!custosProdutos && custosProdutos.current_page !== 1) ? custosProdutos.current_page : 1;
            const fields = { cmvIds: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyCustoProduto({ fields, onDelete });
        }
    }
    return (
        <>
            {!!loadingCustosProd ?
                <div className="loader-box">
                    <div className="loader-1"></div>
                </div>
                :
                !!custosProdutos &&
                <DataTable
                    persistTableHead
                    selectableRows
                    pagination
                    paginationServer
                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={toggleCleared}
                    progressComponent={
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    }
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                    }}
                    onChangePage={page => {
                        getCustosProdutos({ fields: { page, ...curFilter.current } })
                    }}
                    selectableRowSelected={row => selectedRows.some(el => el.cmv_id === row.cmv_id)}
                    selectableRowDisabled={(row) => row.cmv_status === 'F'}
                    paginationPerPage={custosProdutos.per_page}
                    paginationTotalRows={custosProdutos.total}
                    paginationDefaultPage={custosProdutos.current_page}
                    noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                    contextActions={
                        <Button
                            sid="cmv_diario.destroy"
                            disabled={loadingDestroyCustoProd}
                            onClick={() => deleteCustosProdutos()}
                            color="danger">{loadingDestroyCustoProd ? 'Processando...' : 'Remover Custo(s) Produto(s)'}
                        </Button>
                    }
                    columns={
                        [
                            {
                                name: 'ID',
                                selector: 'cmv_id',
                                center: true
                            },
                            {
                                name: 'Data',
                                selector: 'data',
                                center: true
                            },
                            {
                                name: 'Produto',
                                selector: 'cmv_prd_produto',
                                center: true
                            },
                            {
                                name: 'Quantidade',
                                selector: 'quantidade',
                                center: true
                            },
                            {
                                name: 'Valor',
                                selector: 'valor',
                                center: true
                            },
                            {
                                name: 'Valor Total',
                                selector: 'valor_prod',
                                center: true
                            },
                            {
                                name: 'Tipo',
                                selector: 'tipo',
                                center: true
                            },
                            {
                                name: 'Status',
                                selector: 'status',
                                center: true
                            },
                            {
                                name: 'Ações',
                                selector: 'acoes',
                                center: true
                            }
                        ]
                    }
                    progressPending={loadingDestroyCustoProd}
                    data={custosProdutos.data.map(({
                        cmv_id,
                        cmv_dt_data,
                        cmv_prd_produto,
                        cmv_qtd_quantidade,
                        cmv_vlr_valor,
                        cmv_vlr_valor_produto,
                        cmv_tp_tipo,
                        cmv_status
                    }, index) => {
                        const data = format(parseISO(cmv_dt_data), 'dd/MM/yyyy');
                        const quantidade = parseFloat(cmv_qtd_quantidade || 0);
                        const valor = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(cmv_vlr_valor || 0);
                        const valor_prod = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(cmv_vlr_valor_produto || 0);
                        const tipo = !!cmv_tp_tipo ? (cmv_tp_tipo === 'E' ? 'Entrada' : cmv_tp_tipo === 'C' ? 'Devolução' : 'Saida') : 'Não Informado';
                        const status = (
                            <Badge color={!!cmv_status ? (cmv_status == 'A' ? 'success' : 'danger') : 'secondary'}>
                                {!!cmv_status ? (cmv_status == 'A' ? 'Aberto' : 'Fechado') : 'Não Informado'}
                            </Badge>
                        )
                        const acoes = (
                            <div style={{ display: "flex", overflow: "auto" }}>
                                <a
                                    sid="cmv_diario.index"
                                    style={{ cursor: 'pointer' }}
                                    title="Exibir todas informações"
                                    onClick={() => {
                                        setSelectedCustoProd(index)
                                        setOpenModal('view')
                                    }}
                                >
                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                </a>
                                <a
                                    sid="cmv_diario.update"
                                    style={{ cursor: cmv_status === 'F' ? 'auto' : 'pointer' }}
                                    title="Editar informações"
                                    className={`${cmv_status === 'F' ? 'disabled' : ""}`}
                                    onClick={() => {
                                        if (cmv_status !== 'F') {
                                            setSelectedCustoProd(index)
                                            setOpenModal('edit')
                                        }
                                    }}
                                >
                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                </a>
                            </div>
                        )
                        return { cmv_id, data, cmv_prd_produto, cmv_status, quantidade, valor, valor_prod, tipo, status, acoes }
                    })}
                />
            }
        </>
    )
}

const mapStateToProps = ({ CmvDiario }) => {
    const { loadingCustosProd, custosProdutos, loadingDestroyCustoProd } = CmvDiario;
    return { loadingCustosProd, custosProdutos, loadingDestroyCustoProd }
}

export default connect(mapStateToProps, {
    destroyCustoProduto,
    getCustosProdutos
})(Table);