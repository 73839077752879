import React from "react";
import { useMemo } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Form
} from "reactstrap";
import { switchUserEmpresa } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import sweetalert2 from "sweetalert2";

const ChangeEmpresa = ({ isOpen, active_user_empresa, user_empresas, switchUserEmpresa, loadingSwUsrEmpresa, clickClose }) => {
    const history = useHistory();
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const optionsEmpresas = useMemo(() => user_empresas.map((el) => {
        return ({ name: el.emp_nm_empresa, value: el.id })
    }), [user_empresas])

    const submitChangeEmpresa = async (e) => {
        e.preventDefault();
        const confirm = await sweetalert2.fire({
            title: 'Tem certeza?',
            text: `Tem certeza que desesa alterar para a empresa ${selectedEmpresa.name}?`,
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            icon: 'warning',
        });
        if (confirm.value) {
            const onChange = () => {
                history.push('/profile');
                clickClose();
            }
            switchUserEmpresa({ fields: { empresa_id: selectedEmpresa.value }, onChange })
        }
    }
    return (
        <Modal isOpen={isOpen} size="md">
            <ModalHeader toggle={() => clickClose()}>Alterar Empresa</ModalHeader>
            <Form onSubmit={submitChangeEmpresa}>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <Card >
                                <CardBody className="shadow">
                                    <FormGroup>
                                        <Label>Empresa Atual:</Label>
                                        <h6>{active_user_empresa?.emp_nm_empresa}</h6>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Selecione a Empresa</Label>
                                <Typeahead
                                    id="selectUsrEmpresa"
                                    labelKey="name"
                                    defaultSelected={optionsEmpresas.filter((el) => el.value === active_user_empresa.id)}
                                    placeholder="Selecione a Empresa"
                                    clearButton
                                    options={optionsEmpresas}
                                    onChange={(selected) => {
                                        if (!!selected) {
                                            setSelectedEmpresa(selected[0])
                                        } else {
                                            setSelectedEmpresa(null)
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" disabled={loadingSwUsrEmpresa} onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={!selectedEmpresa || loadingSwUsrEmpresa || selectedEmpresa.value === active_user_empresa.id} color="primary">
                        {loadingSwUsrEmpresa ? 'Processando...' : 'Alterar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Auth }) => {
    const { active_user_empresa, user_empresas, loadingSwUsrEmpresa } = Auth;
    return { active_user_empresa, user_empresas, loadingSwUsrEmpresa }
}

export default connect(mapStateToProps, {
    switchUserEmpresa
})(ChangeEmpresa);