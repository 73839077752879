import React, { Fragment, useRef, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, FormGroup, Button, Form, Alert } from 'reactstrap';
import { getRltFluxoCaixa } from "../../../../redux/actions";
import { connect } from 'react-redux';
import { format } from "date-fns";
import PrintComponent from "../../../../components/PrintComponent";
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import Periodo from "./Periodo";
import Tipos from "./Tipos";
import Opcoes from "./Opcoes";
import Campos from "./Campos";
import RltSintetico from "./RltSintetico";
import RltAnalitico from "./RltAnalitico";

const FluxoCaixa = ({ getRltFluxoCaixa, loadingRltFluxoCaixa, rltFluxoCaixa }) => {

    const [filter, setFilter] = useState({
        dataInicial: format(new Date(), 'yyyy-MM-dd'),
        dataFinal: format(new Date(), 'yyyy-MM-dd'),
        tipo: 'S',
        mostrarPessoas: false
    });

    const curFilter = useRef(null);
    const printCompRef = useRef(null);

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dataFinal':
            case 'dataInicial':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: format(new Date(), 'yyyy-MM-dd') })
                }
                break;
            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value });
                } else {
                    setFilter({ ...filter, [name]: null });
                }
                break;
        }
    }

    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Gerencial Fluxo de Caixa ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        // onBeforePrint: () => setLoadingPrint(true),
        // onAfterPrint: () => setLoadingPrint(false),
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });


    const handleChange = e => {
        const { name, checked } = e.target;

        if (name === 'mostrarPessoas') {
            setFilter({ ...filter, [name]: checked });
        }
    }

    const onSubmitFilter = e => {
        e.preventDefault();

        getRltFluxoCaixa({ fields: filter })

        curFilter.current = filter;

    }

    return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title="Fluxo de Caixa" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                <Form onSubmit={onSubmitFilter}>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Periodo
                                                    filter={filter}
                                                    handleChangeSelect={handleChangeSelect}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Tipos
                                                    handleChangeSelect={handleChangeSelect}
                                                    filter={filter}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Opcoes handleChange={handleChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Campos />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" className="d-flex justify-content-center">
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingRltFluxoCaixa}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                {
                                    loadingRltFluxoCaixa ?
                                        <div className="loader-box">
                                            <div className="loader-1"></div>
                                        </div>
                                        :
                                        <>
                                            {!!rltFluxoCaixa.lancamentos.length &&
                                                <div className="m-b-10 d-flex justify-content-end">
                                                    <Button size="sm" onClick={handlePrint} className="f-16">
                                                        <i className="fa fa-print"></i>
                                                        <span className="p-l-5">
                                                            Imprimir
                                                     </span>
                                                    </Button>
                                                </div>
                                            }
                                            <PrintComponent ref={printCompRef}>

                                                {
                                                    curFilter.current?.tipo === 'S' &&
                                                    <RltSintetico />
                                                }
                                                {
                                                    curFilter.current?.tipo === 'A' &&
                                                    <RltAnalitico />
                                                }
                                                {
                                                    !curFilter.current &&
                                                    <Alert color="light">Pesquise as informações acima.</Alert>
                                                }
                                            </PrintComponent>
                                            {!!rltFluxoCaixa.lancamentos.length &&
                                                <div className="m-b-10 d-flex justify-content-end">
                                                    <Button size="sm" onClick={handlePrint} className="f-16">
                                                        <i className="fa fa-print"></i>
                                                        <span className="p-l-5">
                                                            Imprimir
                                                     </span>
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ RltGerenciais }) => {
    const { loadingRltFluxoCaixa, rltFluxoCaixa } = RltGerenciais;

    return { loadingRltFluxoCaixa, rltFluxoCaixa }
}

export default connect(mapStateToProps, {
    getRltFluxoCaixa
})(FluxoCaixa);