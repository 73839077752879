import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, Form } from 'reactstrap';
import { connect } from "react-redux";
import { getNotasFiscais } from "../../../../redux/actions";
import Table from "./Table";
import View from "./View";
import Edit from "./Edit";
import Filtro from "./Filtro";
import { format } from 'date-fns';
const NotasFiscais = ({
    getNotasFiscais,
    notasFiscais,
    loadingNotasFiscais
}) => {

    useEffect(() => {
        getNotasFiscais()
    }, [])

    const [openModal, setOpenModal] = useState(null);
    const [selectedNF, setSelectedNF] = useState(null);
    const [filter, setFilter] = useState({ dhEmi: null, cNF: null, nNF: null, cProd: null, vProd: null, nfe_tp_nota: null });

    const curFilter = useRef(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value || null });
    }
    const handleValores = (name, value) => {
        setFilter({ ...filter, [name]: value });
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dhEmi':
                setFilter({ ...filter, [name]: !!selected ? format(selected, 'yyyy-MM-dd') : null });
                break;
            default:
                setFilter({ ...filter, [name]: !!selected.length ? selected[0].value : null })
                break;
        }
    }


    const submitFilter = e => {
        e.preventDefault();
        getNotasFiscais({ fields: filter });
        curFilter.current = filter;
    }
    return (
        <Fragment>
            <View isOpen={openModal === 'view'} notaFiscal={!!notasFiscais && notasFiscais.data[selectedNF]} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === 'edit'} selectedNF={selectedNF} clickClose={() => setOpenModal(null)} />
            <Breadcrumb parent="Estoque" title="Notas Fiscais" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Filtro
                                        filter={filter}
                                        handleValores={handleValores}
                                        handleChangeSelect={handleChangeSelect}
                                        loadingNotasFiscais={loadingNotasFiscais}
                                        handleChange={handleChange} />
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-t-20">
                                <Table
                                    setSelectedNF={setSelectedNF}
                                    setOpenModal={setOpenModal}
                                    curFilter={curFilter} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ NotasFiscais }) => {
    const { notasFiscais, loadingNotasFiscais } = NotasFiscais;
    return { notasFiscais, loadingNotasFiscais }
}

export default connect(mapStateToProps, {
    getNotasFiscais
})(NotasFiscais);