import React, {
    Fragment,
    useEffect,
    useState,
    useCallback,
    useRef
} from 'react';
import Breadcrumb from '../../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import {
    getTiposEmpresa,
    destroyTiposEmpresa
} from "../../../../redux/actions";
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import sweetalert2 from "sweetalert2";
import Add from "./Add";
import Edit from "./Edit";

const TipoEmpresas = ({
    getTiposEmpresa,
    tiposEmpresa,
    loadingTiposEmp,
    destroyTiposEmpresa,
    loadingDestroyTiposEmp
}) => {
    useEffect(() => {
        getTiposEmpresa();

    }, []);

    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedTipoEmp, setSelectedTipoEmp] = useState(null);

    //REFS
    const curFilter = useRef(null);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ tpe_cd_tipo, tpe_nm_tipo }) => ({ tpe_cd_tipo, tpe_nm_tipo })))
    }, []);

    const deleteTiposEmpresa = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ tpe_nm_tipo }) => {
                return (`<li class="list-group-item-action list-group-item">${tpe_nm_tipo}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!tiposEmpresa && tiposEmpresa.current_page !== 1) ? tiposEmpresa.current_page : 1;
            const fields = { empresaTipos: selectedRows, page };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyTiposEmpresa({ fields, onDelete });
        }
    }

    return (
        <Fragment>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit
                isOpen={openModal === 'edit'}
                selectedTipoEmp={selectedTipoEmp}
                clickClose={() => setOpenModal(null)} />
            <Breadcrumb parent="Tabelas" title="Tipos Empresa" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <Button
                            color="primary"
                            size="md"
                            onClick={() => setOpenModal('add')}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                        </Button>
                    </Col>
                    <Col xs="12">
                        <Card>
                            <CardBody className="p-t-20">
                                {loadingTiposEmp ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!tiposEmpresa &&
                                    <DataTable
                                        persistTableHead
                                        selectableRows
                                        pagination
                                        paginationServer
                                        onSelectedRowsChange={handleSelectedRows}
                                        clearSelectedRows={toggleCleared}
                                        progressPending={loadingDestroyTiposEmp}
                                        selectableRowSelected={(row) => {
                                            return selectedRows.some(el => el.tpe_cd_tipo === row.tpe_cd_tipo)
                                        }}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                            rangeSeparatorText: 'de',
                                        }}
                                        columns={[
                                            {
                                                name: 'Nome',
                                                selector: 'tpe_nm_tipo',
                                                center: true
                                            },
                                            {
                                                name: 'Descrição',
                                                selector: 'tpe_ds_tipo',
                                                center: true
                                            },
                                            {
                                                name: 'Ações',
                                                selector: 'acoes',
                                                center: true
                                            }

                                        ]}

                                        paginationPerPage={tiposEmpresa.per_page}
                                        paginationTotalRows={tiposEmpresa.total}
                                        paginationDefaultPage={tiposEmpresa.current_page}
                                        contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                        contextActions={
                                            <Button
                                                disabled={loadingDestroyTiposEmp}
                                                onClick={deleteTiposEmpresa}
                                                color="danger">
                                                {loadingDestroyTiposEmp ? 'Processando...' : 'Remover Tipo(s) Empresa(s)'}
                                            </Button>
                                        }
                                        progressComponent={
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        }
                                        data={tiposEmpresa.data.map(({ tpe_cd_tipo, tpe_nm_tipo, tpe_ds_tipo }, index) => {
                                            const acoes = (
                                                <div style={{ display: "flex", overflow: "auto" }}>
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedTipoEmp(index);
                                                            setOpenModal('edit');
                                                        }}
                                                    >
                                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                    </a>
                                                </div>
                                            )
                                            return {
                                                tpe_cd_tipo,
                                                tpe_nm_tipo,
                                                tpe_ds_tipo,
                                                acoes
                                            }
                                        })}
                                        noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ TiposEmpresa }) => {
    const { tiposEmpresa, loadingTiposEmp, loadingDestroyTiposEmp } = TiposEmpresa;
    return { tiposEmpresa, loadingTiposEmp, loadingDestroyTiposEmp };
}

export default connect(mapStateToProps, {
    getTiposEmpresa,
    destroyTiposEmpresa
})(TipoEmpresas);