import React, { Fragment } from 'react'
import {
    Table
} from 'reactstrap';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

function Margem({
    rltMaster
}) {
    const { lancamentos, fields } = rltMaster;

    const calcTotalList = list => list.reduce((prev, current) => prev + parseFloat(current.total), 0);
    const getPercentualValue = num => parseFloat(num.toFixed(2)).toLocaleString();

    const optionsIndice = [
        {
            name: '(Nenhum Índice)',
            value: 'nenhum'
        }
    ];

    const periodoIni = format(parseISO(fields?.periodoInicial), "EEE',' dd/MM/yyyy", {
        locale: pt
    }).split('').map((v, i) => i > 0 ? v : v.toUpperCase()).join('');

    const periodoFim = format(parseISO(fields?.periodoFinal), "EEE',' dd/MM/yyyy", {
        locale: pt
    }).split('').map((v, i) => i > 0 ? v : v.toUpperCase()).join('');

    const pessoas = [
        ...new Set(
            lancamentos.map(
                ({ pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao }) => ({
                    pss_cd_pessoa,
                    pss_nm_pessoa,
                    pss_nu_identificacao
                })
            ).map(JSON.stringify)
        )
    ]
        .map(JSON.parse);

    return (
        <div className="p-15 font-arial-rlt">
            <p>Master - Margem, por Caixa, de {periodoIni} a {periodoFim}</p>

            <p className="float-right">Índice: {optionsIndice.find(({ value }) => value == fields?.indice)?.name}</p>

            <Table bordered>
                <thead>
                    <tr>
                        <th>Pessoa</th>
                        <th className="text-center">Receita</th>
                        <th className="text-center">Despesa</th>
                        <th className="text-center">Resultado</th>
                        <th className="text-center">Margem %</th>
                    </tr>
                </thead>

                <tbody>
                    {pessoas.map(
                        ({ pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao }, index) => {
                            const receitas = lancamentos.filter(
                                l =>
                                    l.grc_cd_grupo_pai == 3 &&
                                    l.pss_cd_pessoa == pss_cd_pessoa &&
                                    l.pss_nm_pessoa == pss_nm_pessoa &&
                                    l.pss_nu_identificacao == pss_nu_identificacao
                            );

                            const despesas = lancamentos.filter(
                                l => l.grc_cd_grupo_pai == 4 &&
                                    l.pss_cd_pessoa == pss_cd_pessoa &&
                                    l.pss_nm_pessoa == pss_nm_pessoa &&
                                    l.pss_nu_identificacao == pss_nu_identificacao
                            );

                            const totalReceitas = calcTotalList(receitas);
                            const totalDespesas = calcTotalList(despesas);
                            const total = totalReceitas - totalDespesas;
                            const margem = totalReceitas / total * 100;

                            return (
                                <tr key={index}>
                                    <td>
                                        {pss_cd_pessoa} - {pss_nm_pessoa}
                                    </td>
                                    <td className="text-center">
                                        {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' })
                                            .format(totalReceitas)
                                        }
                                    </td>
                                    <td className="text-center">
                                        {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' })
                                            .format(totalDespesas)
                                        }
                                    </td>
                                    <td className="text-center">
                                        {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' })
                                            .format(total)
                                        }
                                    </td>
                                    <td className="text-center">
                                        {getPercentualValue(margem)}%
                                    </td>
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default Margem;