export default function (user) {
    if (!!user && !!user.permissions) {
        //REMOVE BOTÕES NORMAIS
        const buttonsPermissions = document.querySelectorAll('[sid]');
        buttonsPermissions.forEach(btn => {
            if (!user.permissions.includes(btn.getAttribute('sid'))) {
                if ((btn.previousElementSibling?.className === 'sidebar-main-title' && btn.nextElementSibling?.className === 'sidebar-main-title') ||
                    (btn.previousElementSibling?.className === 'sidebar-main-title' && !btn.nextElementSibling?.className)) {
                    btn.previousElementSibling.remove();
                }
                btn.remove()
            }
        });
        //REMOVE BOTÕES COM SUBITEM NO SIDEBAR MENU
        const menuItens = document.querySelectorAll('li[data_parent]');
        menuItens.forEach((item, index) => {
            const ul = item.children.item(1);
            if (!!ul.children.length) {
                const arrUl = [...ul.children];
                arrUl.forEach(row => {
                    const ulSub = row.children.item(1);
                    if (!!ulSub && !ulSub.children.length) {
                        row.remove();
                    }
                })
            } else {
                if ((item.nextElementSibling?.className === 'sidebar-main-title' && item.previousElementSibling?.className === 'sidebar-main-title') ||
                    (item.previousElementSibling?.className === 'sidebar-main-title' && !item.nextElementSibling?.className)) {
                    item.previousElementSibling.remove();
                }
                item.remove();
            }

        })
    }
}