import React, { useRef, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import {
    getListCentroCusto,
    getListContas,
    getListPessoas,
    updateLancamentos,
    getListContaPessoas
} from "../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { format } from "date-fns";
import { toast } from "react-toastify";
import DadosGerais from "./DadosGerais";
import Conta from "./Conta";
import Datas from "./Datas";
import ContraPartidas from "./ContraPartidas/";
import Faturas from "./Faturas/";
import ValorMinMax from "./ValorMinMax";

const Edit = ({
    isOpen,
    clickClose,
    errors,
    getListCentroCusto,
    getListContas,
    updateLancamentos,
    loadingUpdate,
    listContas,
    listCentroCusto,
    listContaPessoas,
    selectedLancamento,
    lancamentos,
    getListContaPessoas
}) => {
    //ESTADOS
    const [fields, setFields] = useState(null);

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            verifica_valor: {
                message: 'O valor deve está entre o mínimo e máximo da conta.',
                rule: (val, params) => {
                    const valor = parseFloat(params[0]);
                    const valor_minimo = parseFloat(params[1]);
                    const valor_maximo = parseFloat(params[2]);

                    return valor >= valor_minimo && valor <= valor_maximo;
                }
            },
            calcula_cnt: {
                message: 'O valor de contrapartida(s) não é igual ao do lançamento.',
                rule: (val, params) => {
                    const total_contrapartidas = parseFloat(params[0]);
                    const valor_lancamento = parseFloat(params[1]);
                    
                    return total_contrapartidas === valor_lancamento
                }
            },
            calcula_ftr: {
                message: 'A soma dos valores das faturas da contrapartida :attribute não é igual ao valor da contrapartida.',
                rule: (val, params) => {
                    const totalFatura = parseFloat(params[0]);
                    const valorContra = parseFloat(params[1]);

                    return totalFatura === valorContra;
                }
            }
        }
    })).current;


    //FUNÇÕES
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            updateLancamentos({ fields, index: selectedLancamento, onUpdate: clickClose });
        } else {
            validator.showMessages();
            toast.error('Preencha todos os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }

    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    const clearErrorsForm = (name) => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;

        switch (name) {
            case ('lan_nu_doc'):
                if (!!fields.contrapartidas.length) {
                    fields.contrapartidas[0].cnt_nu_doc = value;
                }
                setFields({ ...fields, [name]: value, contrapartidas: fields.contrapartidas });
                break;
            default:
                setFields({ ...fields, [name]: value });
                break;
        }
        clearErrorsForm(name)
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'lan_cd_conta':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].ctn_cd_conta,
                        lan_ds_historico: selected[0].ctn_ds_hist_padrao,
                        lan_cd_subconta: selected[0]?.def_pessoa?.pss_cd_pessoa,
                        conta: selected[0],
                        pessoa: {
                            value: selected[0]?.def_pessoa?.id,
                            pss_nm_pessoa: selected[0]?.def_pessoa?.pss_nm_pessoa,
                            pss_cd_pessoa: selected[0]?.def_pessoa?.pss_cd_pessoa,
                            pss_nu_identificacao: selected[0]?.def_pessoa?.pss_nu_identificacao
                        }

                    });
                    listContaPessoas.data = [];
                    listContaPessoas.total = 0;
                    !selected[0].def_pessoa && getListContaPessoas({ conta: selected[0].ctn_cd_conta, fields: {} });
                } else {
                    setFields({
                        ...fields,
                        [name]: "",
                        lan_ds_historico: "",
                        lan_cd_subconta: "",
                        conta: {},
                    });
                }
                break;
            case 'lan_dt_competencia':
                if (!!selected) {
                    setFields({
                        ...fields,
                        [name]: format(selected, 'yyyy-MM-dd'),
                        lan_dt_lancamento: format(selected, 'yyyy-MM-dd')
                    });
                } else {
                    setFields({ ...fields, [name]: "", lan_dt_lancamento: "" });
                }
                break;
            case 'lan_cd_subconta':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].pss_cd_pessoa });
                } else {
                    setFields({ ...fields, [name]: "", pessoa: {} });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
        clearErrorsForm(name)
    }
    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFields({ ...fields, [name]: value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
    }
    const setContraPartidas = (contrapartidas) => {
        setFields({ ...fields, contrapartidas });
    }

    return (
        <Modal
            isOpen={isOpen}
            size="xxl"
            onOpened={() => {
                const lancamento = { ...lancamentos.data[selectedLancamento] };
                delete lancamento.user;
                delete lancamento.id;
                delete lancamento.row_num;
                setFields(lancamento)
                getListCentroCusto()
                getListContas()
            }}
            onClosed={() => {
                setFields(null)
                validator.visibleFields = [];
                validator.messagesShown = false;
                listContas.data = [];
                listContas.total = 0;
                listCentroCusto.data = [];
                listCentroCusto.total = [];
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => !loadingUpdate && clickClose()}>
                Editar Lançamento
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col lg="6" md="12">
                                <Row>
                                    <Datas
                                        validFields={validFields}
                                        validator={validator}
                                        fields={fields}
                                        errors={errors}
                                        handleChangeSelect={handleChangeSelect}
                                    />
                                    <Conta
                                        validator={validator}
                                        errors={errors}
                                        fields={fields}
                                        validFields={validFields}
                                        handleChangeSelect={handleChangeSelect}
                                        handleChange={handleChange}
                                    />
                                </Row>
                            </Col>
                            <Col lg="6" md="12">
                                <Row>
                                    <DadosGerais
                                        validator={validator}
                                        errors={errors}
                                        fields={fields}
                                        validFields={validFields}
                                        handleChangeSelect={handleChangeSelect}
                                        handleChange={handleChange}
                                        handleValor={handleValor}
                                    />
                                    {!!Object.keys(fields.conta).length &&
                                        <ValorMinMax
                                            lancamento={fields}
                                        />
                                    }
                                </Row>
                            </Col>
                            <ContraPartidas
                                validator={validator}
                                errors={errors}
                                contrapartidas={[...fields.contrapartidas]}
                                lancamento={fields}
                                setContraPartidas={setContraPartidas}
                            />
                            <Faturas
                                contrapartidas={[...fields.contrapartidas]}
                                setContraPartidas={setContraPartidas}
                                valorLancamento={fields.lan_vl_lancamento}
                                validator={validator}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" disabled={loadingUpdate} onClick={() => clickClose()}>
                            Fechar
                </Button>
                        <Button color="primary" disabled={loadingUpdate} type="submit">
                            {loadingUpdate ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Lancamentos, Contas, CentroCusto }) => {
    const { errors, loadingUpdate, lancamentos } = Lancamentos;
    const { list: listContas, loadingList: loadingListContas, listContaPessoas } = Contas;
    const { list: listCentroCusto } = CentroCusto;
    return { errors, loadingUpdate, listContas, loadingListContas, listCentroCusto, listContaPessoas, lancamentos }
}

export default connect(mapStateToProps, {
    getListCentroCusto,
    getListContas,
    getListPessoas,
    updateLancamentos,
    getListContaPessoas
})(Edit);