import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Input,
    ModalHeader,
    Label,
    ModalFooter,
    Button,
    FormGroup,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Alert,

} from "reactstrap";
import ScrollArea from 'react-scrollbar';
import { getListFuncionalidades, storeModulos } from "../../../../redux/actions";

const initialState = { name: null, active: null, funcionalidades: [] };
const AddModulo = (props) => {
    //STATES
    const [funcionalidades, setFuncionalidades] = useState([]);
    const [fields, setFields] = useState(initialState);


    useEffect(() => {
        if (!!props.funcionalidades) {
            setFuncionalidades([...props.funcionalidades]);
        }
    }, [props.funcionalidades]);


    //VARIÁVEIS


    //FUNÇÕES
    const submitForm = (e) => {
        e.preventDefault();
        props.storeModulos({ onSave: props.clickClose, fields });

    }
    const handleSearch = ({ target: { value } }) => {
        if (!!props.funcionalidades) {
            const filter = props.funcionalidades.filter(({ name }) => new RegExp(`${value}`, 'i').test(name));
            setFuncionalidades(filter);
        }
    }

    return (
        <>
            <Modal isOpen={props.isOpen} size="lg"
                onOpened={() => {
                    props.getListFuncionalidades();
                }}
                onClosed={() => {
                    setFields(initialState);
                }}
            >
                <Form onSubmit={submitForm}>
                    <ModalHeader toggle={() => props.clickClose()}>
                        Adicionar Módulo
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="8" sm="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        name="name"
                                        placeholder="Nome do Módulo"
                                        onChange={({ target: { value } }) => setFields({ ...fields, name: value })} />
                                </FormGroup>
                            </Col>
                            <Col md="4" sm="12">
                                <Label className="d-block">Status</Label>
                                <ButtonGroup>
                                    <Button outline active={fields.active === 1} onClick={() => setFields({ ...fields, active: 1 })} color="success">
                                        Ativo
                                        </Button>
                                    <Button outline active={fields.active === 0} onClick={() => setFields({ ...fields, active: 0 })} color="danger">
                                        Inativo
                                        </Button>
                                </ButtonGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Funcionalidades</Label>
                                    <Row>
                                        <Col xs="6">
                                            <Input
                                                placeholder="Pesquisar Funcionalidade"
                                                autoComplete="off"
                                                name="funcionalidade"
                                                onChange={handleSearch}
                                                className="mb-2" />
                                            {props.loadingFuncionalidades ?
                                                <div className="loader-box">
                                                    <div className="loader-1"></div>
                                                </div>
                                                :
                                                !!funcionalidades.length ?
                                                    <ScrollArea vertical={true} horizontal={false} >
                                                        <ListGroup>
                                                            {funcionalidades.map(({ name, id }, key) => {
                                                                return (
                                                                    <ListGroupItem
                                                                        key={key}
                                                                        className="list-group-item-action"
                                                                        onClick={() => {
                                                                            const funcionalidades = [...fields.funcionalidades];
                                                                            const index = funcionalidades.findIndex((el) => el.id === id);
                                                                            if (index !== -1) {
                                                                                funcionalidades.splice(index, 1);
                                                                            } else {
                                                                                funcionalidades.push({ id, name });
                                                                            }
                                                                            setFields({ ...fields, funcionalidades })
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        <Input
                                                                            className="checkbox_animated"
                                                                            checked={!!fields.funcionalidades.filter((el) => el.id === id).length}
                                                                            onChange={() => { }}
                                                                            id={`chk-ani${key}`}
                                                                            type="checkbox" />
                                                                        {name}
                                                                    </ListGroupItem>
                                                                );
                                                            })
                                                            }
                                                        </ListGroup>
                                                    </ScrollArea>
                                                    :
                                                    <Alert color="light">Nenhuma Funcionalidade foi Encontrada</Alert>
                                            }
                                        </Col>
                                        <Col xs="6">
                                            <div
                                                className="mb-2"
                                                style={{
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0.375rem 0.75rem',
                                                    fontSize: '1rem'
                                                }}>
                                                <span className="pull-right">{`Selecionados ${fields.funcionalidades.length}`}</span>
                                            </div>
                                            <ScrollArea vertical={true} horizontal={false} >
                                                <ListGroup>
                                                    {!!fields.funcionalidades.length ?
                                                        fields.funcionalidades.map(({ name }, index) => {
                                                            return (
                                                                <ListGroupItem
                                                                    key={index}
                                                                    className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                    onClick={() => {
                                                                        const funcionalidades = [...fields.funcionalidades];
                                                                        funcionalidades.splice(index, 1);
                                                                        setFields({ ...fields, funcionalidades })
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    {name}
                                                                    <span>X</span>
                                                                </ListGroupItem>
                                                            );
                                                        })
                                                        :
                                                        <Alert color="light">Nenhuma Funcionalidade Selecionada</Alert>
                                                    }
                                                </ListGroup>
                                            </ScrollArea>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => props.clickClose()} type="button">Fechar</Button>
                        <Button color="primary" disabled={props.loadingStoreModulo} type="submit">{props.loadingStoreModulo ? 'Processando...' : 'Adicionar'}</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ Funcionalidades, Modulos }) => {
    const { list: funcionalidades, loadingList: loadingFuncionalidades } = Funcionalidades;
    const { loadingStoreModulo } = Modulos;
    return { funcionalidades, loadingFuncionalidades, loadingStoreModulo };
}

export default connect(mapStateToProps, {
    getListFuncionalidades,
    storeModulos,
    
})(AddModulo);