import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Table, CardTitle, Alert } from 'reactstrap';
import { connect } from "react-redux";
import { getModulos } from "../../../../redux/actions";
import DataTable from 'react-data-table-component';
import UpdatePermissions from "../Permissions/UpdatePermissions";
import Pagination from "../../../../components/Pagination";
const Inicio = ({
    loadingFuncionalidades,
    funcionalidades,
    modulos,
    loadingModulos,
    groups,
    loadingGroups,
    permissions,
    loadingPermissions,
    changeTab,
    findFuncionalidade,
    getModulos
}) => {
    //STATES
    const [modalOpen, setModalOpen] = useState(null);
    //VARIÁVEIS
    const tableColumns = [
        {
            name: 'Funcionalidade',
            selector: 'name',
            sortable: true,
            center: true,
        },
        {
            name: 'Grupos Associados',
            selector: 'groups_count',
            sortable: true,
            center: true,
        },
        {
            name: 'Permissões Associadas',
            selector: 'permissions_count',
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: 'status',
            center: true
        }
    ];
    return (
        <>
            <UpdatePermissions isOpen={modalOpen === 'updatePermission'} clickClose={() => setModalOpen(null)} />
            <Row>
                <Col sm="12" lg="6">
                    <Card className="shadow shadow-showcase card-absolute">
                        <CardHeader className="bg-primary">
                            <h5>Permissões</h5>
                        </CardHeader>
                        <CardBody>
                            <CardTitle style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <Button color="primary" outline onClick={() => setModalOpen('updatePermission')}>Atualizar Automaticamente</Button>
                            </CardTitle>
                            {loadingPermissions ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Total</th>
                                            <th>Sem Funcionalidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!permissions ?
                                            <tr>
                                                <td>{permissions.data.length}</td>
                                                <td>{permissions.sem_funcionalidades}</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan="2"><Alert color="light">Nenhuma Permissão Cadastrada</Alert></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" lg="6">
                    <Card className="shadow shadow-showcase card-absolute">
                        <CardHeader className="bg-primary">
                            <h5>Funcionalidades</h5>
                        </CardHeader>
                        <CardBody>
                            <CardTitle style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <Button color="primary" onClick={() => changeTab(2)} outline>Gerir</Button>
                            </CardTitle>
                            {loadingFuncionalidades ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Total</th>
                                            <th>Sem Permissão</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!funcionalidades ?
                                            <tr>
                                                <td>{funcionalidades.total}</td>
                                                <td>{funcionalidades.sem_permissoes}</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan="2"><Alert color="light" >Nenhum Funcionalidade Cadastrada</Alert></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" lg="6">
                    <Card className="shadow shadow-showcase card-absolute">
                        <CardHeader className="bg-primary">
                            <h5>Módulos</h5>
                        </CardHeader>
                        <CardBody>
                            <CardTitle style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <Button color="primary" onClick={() => changeTab(3)} outline>Gerir</Button>
                            </CardTitle>
                            {loadingModulos ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Total</th>
                                            <th>Sem Funcionalidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!modulos ?
                                            <tr>
                                                <td>{modulos.total}</td>
                                                <td>{modulos.sem_funcionalidades}</td>
                                            </tr> :
                                            <tr>
                                                <td colSpan="2"><Alert color="light">Nenhum Módulo Cadastrado</Alert></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" lg="6" >
                    <Card className="shadow shadow-showcase card-absolute">
                        <CardHeader className="bg-primary">
                            <h5>Perfis</h5>
                        </CardHeader>
                        <CardBody>
                            <CardTitle style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <Button color="primary" onClick={() => changeTab(4)} outline>Gerir</Button>
                            </CardTitle>
                            {loadingGroups ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Total</th>
                                            <th>Sem Funcionalidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!groups ?
                                            <tr>
                                                <td>{groups.total}</td>
                                                <td>{groups.sem_funcionalidades}</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan="2"><Alert color="light">Nenhuma Funcionalidade Cadastrada</Alert></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="p-10">
                <Col xs="12">
                    {loadingModulos ?
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                        :
                        !!modulos &&
                        modulos.data.map(({ name, funcionalidades }, key) => {
                            return (
                                <div className="ribbon-wrapper mb-4" key={key}>
                                    <div className="ribbon ribbon-primary ribbon-space-bottom">{name}</div>
                                    <DataTable
                                        noHeader={true}
                                        responsive={true}
                                        highlightOnHover
                                        pointerOnHover
                                        onRowClicked={(funcionalidade) => {
                                            findFuncionalidade(funcionalidade.name)
                                            changeTab(2)
                                        }}
                                        columns={tableColumns}
                                        data={funcionalidades.map(({ name, permissions_count, groups_count, active }) => {
                                            const status = (active === "1" ? <i className="fa fa-circle font-success"></i> : <i className="fa fa-circle font-danger"></i>)
                                            return ({
                                                name,
                                                permissions_count,
                                                groups_count,
                                                status
                                            })
                                        })}
                                        pagination={true}
                                        paginationPerPage={5}
                                        noDataComponent={<span className="p-20">Nenhuma Funcionalidade Vinculada</span>}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                            rangeSeparatorText: 'de'
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </Col>
                {(!loadingModulos && !!modulos) &&
                    <Col xs="12">
                        <nav className="m-b-50" >
                            <Pagination
                                totalPage={modulos.last_page}
                                currentPage={modulos.current_page}
                                onChangePage={(page) => {
                                    getModulos({ fields: { page } })
                                }}
                            />
                        </nav>
                    </Col>
                }
            </Row>
        </>
    );
}

const mapStateToProps = ({ Funcionalidades, Modulos, Groups, Permissions }) => {

    //FUNCIONALIDADES
    const {
        funcionalidades,
        loading: loadingFuncionalidades
    } = Funcionalidades

    //MODULOS
    const {
        modulos,
        loading: loadingModulos
    } = Modulos

    //GROUPS
    const {
        groups,
        loading: loadingGroups
    } = Groups;

    //PERMISSIONS
    const {
        permissions,
        loading: loadingPermissions
    } = Permissions

    return { funcionalidades, loadingFuncionalidades, modulos, loadingModulos, groups, loadingGroups, permissions, loadingPermissions }
}

export default connect(mapStateToProps, {
    getModulos
})(Inicio);