import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Button, Form } from 'reactstrap';
import { connect } from "react-redux";
import { format } from "date-fns";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Periodo from "./Periodo";
import Opcoes from "./Opcoes";
import CentrosCusto from "./CentrosCusto";
import ReceitaDespesa from "./ReceitaDespesa";
import ExibirEmAtivo from "./ExibirEmAtivo";
import RltMovimentacao from "./RltMovimentacao";
import {
    getListCentroCusto,
    getRltMovimentacao,
    clearRltMovimentacao
} from "../../../../redux/actions";


const Movimentacao = ({
    getListCentroCusto,
    getRltMovimentacao,
    loadingRltMovimentacao,
    clearRltMovimentacao,
    loadingStoreCct
}) => {
    const [filter, setFilter] = useState({
        dataInicial: "",
        dataFinal: "",
        tipoRegime: "caixa",
        tipoExibir: 'todos',
        exibirRecDesCredito: "N",
        exibirRecDesDebito: "N",
        exibirRecDesCctAgrupado: "N",
        exibirRecDesGruposConta: "N",
        exibirRecDesContas: "N",
        exibirRecDesPessoas: "N",
        exibirRecDesCct: "N",
        exibirAtivoCttAgrupando: "N",
        exibirAtivoGruposConta: "N",
        exibirAtivoContas: "N",
        exibirAtivoPessoas: "N",
        exibirAtivoCct: "N"
    });

    const curFilter = useRef({});
    const printCompRef = useRef();

    useEffect(() => {
        getListCentroCusto()
        return () => {
            clearRltMovimentacao()
        }
    }, []);

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dataFinal':
            case 'dataInicial':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: "" })
                }
                break;
            case 'centrosCusto':
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected });
                } else {
                    setFilter({ ...filter, [name]: [] });
                }
                break;

            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value });
                } else {
                    setFilter({ ...filter, [name]: [] });
                }
                break;
        }
    }

    const handleChange = name => {
        setFilter({ ...filter, [name]: filter[name] === "N" ? "S" : "N" })
    }
    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Movimentação ${format(new Date(), 'dd/MM/yyyy H:mm:ss')}`,
        // onBeforePrint: () => setLoadingPrint(true),
        // onAfterPrint: () => setLoadingPrint(false),
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT),

    });
    const onSubmitFilter = e => {
        e.preventDefault();
        getRltMovimentacao({ fields: filter });
        curFilter.current = filter;
    }
    return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title="Movimentação" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={onSubmitFilter}>
                                    <Row>
                                        <Col lg="6" xs="12" className="m-b-5">
                                            <Row>
                                                <Col className="m-b-5" xs="12">
                                                    <Periodo
                                                        handleChangeSelect={handleChangeSelect}
                                                        filter={filter}
                                                    />
                                                </Col>
                                                <Col xs="12">
                                                    <Opcoes
                                                        handleChangeSelect={handleChangeSelect}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="6" xs="12" className="m-b-5">
                                            <CentrosCusto />
                                        </Col>
                                        <Col lg="6" xs="12">
                                            <ReceitaDespesa
                                                filter={filter}
                                                handleChange={handleChange}
                                            />
                                        </Col>
                                        <Col lg="6" xs="12">
                                            <ExibirEmAtivo
                                                filter={filter}
                                                handleChange={handleChange}
                                            />
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    disabled={loadingRltMovimentacao || loadingStoreCct}
                                                    title="Pesquisar"
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <RltMovimentacao
                            filter={curFilter.current}
                            printCompRef={printCompRef}
                            handlePrint={handlePrint}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ SelCentrosCusto, RltGerenciais }) => {
    const { loadingRltMovimentacao } = RltGerenciais;
    const { loadingStoreCct } = SelCentrosCusto;
    return { loadingRltMovimentacao, loadingStoreCct }
}

export default connect(mapStateToProps, {
    getListCentroCusto,
    getRltMovimentacao,
    clearRltMovimentacao
})(Movimentacao);