import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    ModalFooter,
    Button,
    Alert,
    ListGroupItem,
    ListGroup
} from "reactstrap";
import ScrollArea from 'react-scrollbar';
import { getListFuncionalidades, storeGroups } from "../../../../redux/actions";
const initialState = { name: null, funcionalidades: [] };
const AddGroup = ({ isOpen, clickClose, funcionalidades, loadingFuncionalidades, getListFuncionalidades, storeGroups, loadingStoreGroups }) => {
    //ESTADOS
    const [fields, setFields] = useState(initialState);
    const [listFuncionalidades, setListFuncionalidades] = useState([]);

    useEffect(() => {
        setListFuncionalidades(funcionalidades);
    }, [funcionalidades]);


    //FUNÇÕES
    const handleSearch = ({ target: { value } }) => {
        if (!!funcionalidades) {
            const filter = funcionalidades.filter(({ name }) => new RegExp(`${value}`, 'i').test(name));
            setListFuncionalidades(filter);
        }
    }
    const submitForm = e => {
        e.preventDefault();
        storeGroups({ fields, onSave: clickClose });
    }

    return (
        <Modal isOpen={isOpen} size="lg"
            onOpened={() => {
                getListFuncionalidades();
            }}
            onClosed={() => {
                setFields(initialState)
            }}>
            <ModalHeader toggle={() => {
                !loadingStoreGroups && clickClose();
            }}>
                Adicionar Perfis
           </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input name="name" placeholder="Informe o nome do perfil" onChange={({ target: { value } }) => setFields({ ...fields, name: value })} />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Funcionalidades</Label>
                                <Row>
                                    <Col xs="6">
                                        <Input
                                            placeholder="Pesquisar Funcionalidade"
                                            autoComplete="off"
                                            name="funcionalidade"
                                            onChange={handleSearch}
                                            className="mb-2" />
                                        {loadingFuncionalidades ?
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                            :
                                            !!listFuncionalidades.length ?
                                                <ScrollArea vertical={true} horizontal={false} style={{ maxHeight: 300 }}>
                                                    <ListGroup>
                                                        {listFuncionalidades.map(({ name, id }, key) => {
                                                            return (
                                                                <ListGroupItem
                                                                    key={key}
                                                                    className="list-group-item-action"
                                                                    onClick={() => {
                                                                        const funcionalidades = [...fields.funcionalidades];
                                                                        const index = funcionalidades.findIndex((el) => el.id === id);
                                                                        if (index !== -1) {
                                                                            funcionalidades.splice(index, 1);
                                                                        } else {
                                                                            funcionalidades.push({ id, name });
                                                                        }
                                                                        setFields({ ...fields, funcionalidades })
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        checked={!!fields.funcionalidades.filter((el) => el.id === id).length}
                                                                        onChange={() => { }}
                                                                        id={`chk-ani${key}`}
                                                                        type="checkbox" />
                                                                    {name}
                                                                </ListGroupItem>
                                                            )
                                                        })}
                                                    </ListGroup>
                                                </ScrollArea>
                                                :
                                                <Alert color="light">Nenhum Funcionalidade Encontrada</Alert>
                                        }
                                    </Col>
                                    <Col xs="6">
                                        <div
                                            className="mb-2"
                                            style={{
                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem'
                                            }}>
                                            <span className="pull-right">{`Selecionados ${fields.funcionalidades.length}`}</span>
                                        </div>
                                        <ScrollArea vertical={true} horizontal={false} >
                                            <ListGroup>
                                                {!!fields.funcionalidades.length ?
                                                    fields.funcionalidades.map(({ name }, index) => {
                                                        return (
                                                            <ListGroupItem
                                                                key={index}
                                                                className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                onClick={() => {
                                                                    const funcionalidades = [...fields.funcionalidades];
                                                                    funcionalidades.splice(index, 1);
                                                                    setFields({ ...fields, funcionalidades })
                                                                }}
                                                                style={{ cursor: 'pointer' }}>
                                                                {name}
                                                                <span>X</span>
                                                            </ListGroupItem>
                                                        );
                                                    })
                                                    :
                                                    <Alert color="light">Nenhuma Funcionalidade Selecionada</Alert>
                                                }
                                            </ListGroup>
                                        </ScrollArea>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => clickClose()} disabled={loadingStoreGroups} type="button">Fechar</Button>
                    <Button color="primary" type="submit" disabled={loadingStoreGroups}>{loadingStoreGroups ? 'Processando...' : 'Adicionar'}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Funcionalidades, Groups }) => {
    const { list: funcionalidades, loadingList: loadingFuncionalidades, } = Funcionalidades;
    const { loadingStoreGroups } = Groups;
    return { funcionalidades, loadingFuncionalidades, loadingStoreGroups }
}
export default connect(mapStateToProps, {
    getListFuncionalidades,
    storeGroups
})(AddGroup);