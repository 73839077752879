import React, { useMemo, useState } from 'react';
import { Col, FormGroup, Row, Label, FormFeedback, Input } from "reactstrap";
import { connect } from "react-redux";
import SelectPagination from "../../../../../components/SelectPagination";
import { getListContas } from "../../../../../redux/actions";

const labelsContas = [
    {
        label: 'Conta de Venda Débito',
        name: 'prd_cd_ctn_ven_deb',
        relationName: 'cnt_venda_debito'
    },
    {
        label: 'Conta de Venda Crédito',
        name: 'prd_cd_ctn_ven_cre',
        relationName: 'cnt_venda_credito'
    },
    {
        label: 'Conta de Venda CMV',
        name: 'prd_cd_ctn_ven_cmv',
        relationName: 'cnt_venda_cmv'
    }

];


function Contas({
    produto

}) {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    {labelsContas.map(({ label, relationName }, index) => {
                        const defValue = !!produto[relationName] ? `${produto[relationName].ctn_cd_conta} - ${produto[relationName].ctn_nm_conta}` : "Não Informado";
                        return (
                            <Col xs="12" key={index}>
                                <FormGroup>
                                    <Label>{label}</Label>
                                    <Input 
                                        defaultValue={defValue}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, loadingList: loadingListContas } = Contas;
    return { listContas, loadingListContas }
}

export default connect(mapStateToProps, {
    getListContas
})(Contas);