import {
    GET_LIST_TIPO_PESSOAS_ASYNC,
    GET_LIST_TIPO_PESSOAS,
    GET_TIPOS_PESSOA_ASYNC,
    GET_TIPOS_PESSOA,
    DESTROY_TIPOS_PESSOA_ASYNC,
    DESTROY_TIPOS_PESSOA,
    STORE_TIPO_PESSOA_ASYNC,
    STORE_TIPO_PESSOA,
    UPDATE_TIPO_PESSOA_ASYNC,
    UPDATE_TIPO_PESSOA
} from "../actionTypes";

const initial_state = {
    errors: {},
    list: [],
    loadingList: false,
    loadingTiposPessoa: false,
    tiposPessoa: null,
    loadingDestroyTps: false,
    loadingStoreTps: false,
    loadingUpdateTps: false,
};
export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_LIST_TIPO_PESSOAS_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_TIPO_PESSOAS:
            return { ...state, ...action.payload, loadingList: false };

        case GET_TIPOS_PESSOA_ASYNC:
            return { ...state, loadingTiposPessoa: true };

        case GET_TIPOS_PESSOA:
            return { ...state, ...action.payload, loadingTiposPessoa: false };

        case DESTROY_TIPOS_PESSOA_ASYNC:
            return { ...state, loadingDestroyTps: true };

        case DESTROY_TIPOS_PESSOA:
            return { ...state, ...action.payload, loadingDestroyTps: false }

        case STORE_TIPO_PESSOA_ASYNC:
            return { ...state, loadingStoreTps: true };

        case STORE_TIPO_PESSOA:
            return { ...state, ...action.payload, loadingStoreTps: false };

        case UPDATE_TIPO_PESSOA_ASYNC:
            return { ...state, loadingUpdateTps: true };

        case UPDATE_TIPO_PESSOA:
            return { ...state, ...action.payload, loadingUpdateTps: false };

        default: return state;
    }
}