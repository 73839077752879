import { takeLatest, call, put, fork, all, select } from "redux-saga/effects";
import {
    GET_PRODUTOS_ASYNC,
    GET_PRODUTOS,
    STORE_PRODUTO_ASYNC,
    STORE_PRODUTO,
    UPDATE_PRODUTO_ASYNC,
    UPDATE_PRODUTO,
    DESTROY_PRODUTOS_ASYNC,
    DESTROY_PRODUTOS
} from "../actionTypes";
import Api from "../../services/api";
import { toast } from "react-toastify";
import { selectorProdutos } from "../selectors";

function* getProdutosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetProdutos, fields);
        if (data.ok) {
            const { produtos } = data;
            yield put({ type: GET_PRODUTOS, payload: { produtos } });
        }
    } catch (error) {
        yield put({ type: GET_PRODUTOS, payload: {} });
    }
}
const apiGetProdutos = async fields => {
    const { data } = await Api.get(`/api/produtos`, { params: fields });

    return data;
}

function* storeProdutosSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreProdutos, fields);
        if (!!data && data.ok) {
            const { produtos } = data;
            yield put({ type: STORE_PRODUTO, payload: { produtos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }
        if (!!errors) {
            yield put({ type: STORE_PRODUTO, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_PRODUTO, payload: {} });
    }
}
const apiStoreProdutos = async fields => {
    const data = await Api.post(`/api/produtos/store`, fields);

    return data;
}

function* updateProdutosSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;
        const produtos = { ...yield select(selectorProdutos) };
        const { id } = produtos.data[index];
        const { data, errors } = yield call(apiUpdateProdutos, fields, id);

        if (!!data && !!data.ok) {
            produtos.data.splice(index, 1, data.produto);
            yield put({ type: UPDATE_PRODUTO, payload: { produtos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: UPDATE_PRODUTO, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: UPDATE_PRODUTO, payload: {} });
    }
}
const apiUpdateProdutos = async (fields, id) => {
    const data = await Api.put(`/api/produtos/update/${id}`, fields);

    return data;
}

function* destroyProdutosSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyProdutos, fields);
        if (data.ok) {
            const { produtos } = data;
            yield put({ type: DESTROY_PRODUTOS, payload: { produtos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_PRODUTOS, payload: {} });
    }
}
const apiDestroyProdutos = async fields => {
    const { data } = await Api.delete(`/api/produtos/destroy`, { data: fields });

    return data;
}

function* watchGetProdutos() {
    yield takeLatest(GET_PRODUTOS_ASYNC, getProdutosSaga);
}
function* watchStoreProdutos() {
    yield takeLatest(STORE_PRODUTO_ASYNC, storeProdutosSaga);
}
function* watchUpdateProdutos() {
    yield takeLatest(UPDATE_PRODUTO_ASYNC, updateProdutosSaga);
}
function* watchDestroyProdutos() {
    yield takeLatest(DESTROY_PRODUTOS_ASYNC, destroyProdutosSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetProdutos),
        fork(watchStoreProdutos),
        fork(watchUpdateProdutos),
        fork(watchDestroyProdutos)
    ])
}