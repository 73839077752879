import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import DadosGerais from "./DadosGerais";
import Conta from "./Conta";
import Datas from "./Datas";
import ContraPartidas from "./ContraPartidas/";
import Faturas from "./Faturas/";
import ValorMinMax from "./ValorMinMax";
import InfosCadastro from "./InfosCadastro";

const Add = ({
    isOpen,
    clickClose,
    loadingStore,
    lancamento,
}) => {

    return (
        <Modal
            isOpen={isOpen}
            size="xxl"
        >
            <ModalHeader toggle={() => !loadingStore && clickClose()}>
                Informações Lançamento
            </ModalHeader>
            <ModalBody>
                {!!lancamento &&
                    <Row>
                        <Col lg="6" md="12">
                            <Row>
                                <Datas
                                    lancamento={lancamento}
                                />
                                <Conta
                                    lancamento={lancamento}
                                />
                            </Row>
                        </Col>

                        <Col lg="6" md="12">
                            <Row>
                                <InfosCadastro
                                    lancamento={lancamento}
                                />
                                <DadosGerais
                                    lancamento={lancamento}
                                />
                                <ValorMinMax
                                    lancamento={lancamento}
                                />
                            </Row>
                        </Col>
                        <ContraPartidas
                            lancamento={lancamento}
                        />
                        {!!lancamento.contrapartidas.length &&
                            <Faturas
                                contrapartidas={lancamento.contrapartidas}
                            />
                        }
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button type="button" disabled={loadingStore} onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {

})(Add);