import {
    STORE_SEL_CENTROS_CUSTO_ASYNC,
    STORE_SEL_CENTROS_CUSTO
} from "../actionTypes";

const initialState = {
    loadingStoreCct: false,
    selCentroCusto: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_SEL_CENTROS_CUSTO_ASYNC:
            return { ...state, loadingStoreCct: true };
        case STORE_SEL_CENTROS_CUSTO:
            return { ...state, ...action.payload, loadingStoreCct: false };
        default: return state;
    }
}