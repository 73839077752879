
import React, { useCallback, useState } from 'react';
import { Card, CardBody, Button, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import sweetalert2 from "sweetalert2";
import { connect } from "react-redux";
import { destroyGroups } from "../../../../redux/actions";
import Add from './Add';
import Edit from "./Edit";
import View from "./View";
const Groups = ({ groups, loadingGroups, destroyGroups, loadingDelGroups }) => {
    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedGroup, setselectedGroup] = useState(null);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, name }) => ({ id, name })))
    }, []);
    const deleteRowsGroups = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ name }) => {
                return (`<li class="list-group-item-action list-group-item">${name}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!groups && groups.current_page !== 1) ? groups.current_page : 1;
            const fields = { groups_ids: selectedRows, page }
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyGroups({ fields, onDelete });
        }

    }
    return (
        <>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} selectedGroup={selectedGroup} />
            <Edit isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} selectedGroup={selectedGroup} />
            <Row>
                <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                    <Button color="primary" size="md" onClick={() => setOpenModal('add')}><i className="fa fa-plus"></i><span className="p-l-5">Incluir</span></Button>
                </Col>
                <Col xs="12">
                    <Card className="shadow shadow-showcase">
                        <CardBody className="p-t-20">
                            {loadingGroups ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                !!groups &&
                                <DataTable
                                    selectableRows
                                    persistTableHead
                                    selectableRows
                                    pagination
                                    paginationServer
                                    paginationPerPage={groups.per_page}
                                    paginationTotalRows={groups.total}
                                    onChangePage={(page) => console.log(page)}
                                    onSelectedRowsChange={handleSelectedRows}
                                    clearSelectedRows={toggleCleared}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                        rangeSeparatorText: 'de',
                                    }}
                                    paginationDefaultPage={groups.current_page}
                                    progressComponent={
                                        <div className="loader-box">
                                            <div className="loader-1"></div>
                                        </div>
                                    }
                                    progressPending={loadingGroups || loadingDelGroups}
                                    noDataComponent={<span className="p-20">Nenhum Perfil Cadastrado</span>}
                                    contextActions={
                                        <Button
                                            color="danger"
                                            disabled={loadingDelGroups}
                                            onClick={() => deleteRowsGroups()}>
                                            {loadingDelGroups ? 'Processando...' : 'Remover'}
                                        </Button>
                                    }
                                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                    columns={[
                                        {
                                            name: 'Nome',
                                            selector: 'name',
                                            center: true,
                                        },
                                        {
                                            name: 'Ações',
                                            selector: 'acoes',
                                            center: true,

                                        }
                                    ]}
                                    data={groups.data.map(({ name, id }, key) => {
                                        const acoes = (
                                            <div key={key} style={{ display: "flex", overflow: "auto" }}>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setselectedGroup(key);
                                                        setOpenModal('view');
                                                    }}
                                                >
                                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                </a>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setselectedGroup(key);
                                                        setOpenModal('edit');
                                                    }}
                                                >
                                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                </a>
                                            </div>
                                        )
                                        return ({ id, name, acoes });
                                    })}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = ({ Groups }) => {
    const {
        groups,
        loading: loadingGroups,
        loadingDelGroups
    } = Groups;
    return { groups, loadingGroups, loadingDelGroups };
}

export default connect(mapStateToProps, {
    destroyGroups
})(Groups);