import { format, parseISO } from 'date-fns';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import {
    Button,
    Card,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledTooltip,
    Badge,
    CardBody,
    Form,
    FormGroup,
    CardTitle,
    Label,
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap';
import {
    getLancamentosFechados,
    reabrirLancamentos,
    getLancamentos
} from "../../../../redux/actions";
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import sweetalert2 from "sweetalert2";
import br from "date-fns/locale/pt-BR";

const initalFilter = {
    lan_dt_competencia: null,
    lan_vl_lancamento: null,
    lan_tp_lancamento: null
};

function ReabrirLancamentos({
    isOpen,
    clickClose,
    getLancamentosFechados,
    lancamentosFechados,
    loadingLanFechados,
    reabrirLancamentos,
    loadingReabirLan,
    getLancamentos
}) {
    //ESTADOS
    const [filter, setFilter] = useState(initalFilter);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //REFS
    const curFilter = useRef(null);
    const lanIsUpdated = useRef(false);

    //FUNÇÕES
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'lan_dt_competencia':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: null })
                }
                break;

            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value })
                } else {
                    setFilter({ ...filter, [name]: null })
                }
                break;
        }
    }

    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFilter({ ...filter, [name]: value })
                } else {
                    setFilter({ ...filter, [name]: null })
                }
                break;
        }
    }

    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, data_competencia, lan_nu_doc }) => (
            {
                id,
                name: `Data: ${data_competencia} <br/> Doc: ${lan_nu_doc}`
            }
        )))
    }, [setSelectedRows]);

    const SubmitFilter = e => {
        e.preventDefault();
        getLancamentosFechados({ fields: filter });
        curFilter.current = filter;
    }
    const handleReabrirLan = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente reabrir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ name }) => {
                return (`<li class="list-group-item-action list-group-item">${name}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const onSave = () => {
                setToggleCleared(!toggleCleared);
                lanIsUpdated.current = true;
            };
            const page = (!!lancamentosFechados && lancamentosFechados.current_page !== 1) ? lancamentosFechados.current_page : 1;
            const fields = { lancamentos: selectedRows, page, curFilter: curFilter.current };
            reabrirLancamentos({ fields, onSave });
        }
    }

    return (
        <Modal
            size="xl"
            isOpen={isOpen}
            onOpened={() => {
                getLancamentosFechados({});
            }}
            onClosed={() => {
                setFilter(initalFilter)
                lanIsUpdated.current && getLancamentos();
                lanIsUpdated.current = false;
            }}
        >
            <ModalHeader toggle={() => clickClose()}>Reabrir Lançamentos</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody className="shadow shadow-showcase">
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={SubmitFilter}>
                                    <Row>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Data Competência</Label>
                                                <DatePicker
                                                    locale={br}
                                                    autoComplete="off"
                                                    placeholderText="Selecione a Data"
                                                    minDate={new Date(1930, 0, 1)}
                                                    maxDate={new Date()}
                                                    selected={!!filter.lan_dt_competencia && parseISO(filter.lan_dt_competencia)}
                                                    isClearable
                                                    dateFormat="dd/MM/yyyy"
                                                    className={`form-control digits`}
                                                    onChange={(selected) => handleChangeSelect('lan_dt_competencia', selected)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Valor</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                                    <NumberFormat
                                                        placeholder="Informe o Valor"
                                                        thousandSeparator='.'
                                                        customInput={Input}
                                                        name="lan_vl_lancamento"
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        onValueChange={({ value }) => handleValor('lan_vl_lancamento', value)}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="3" sm="12">
                                            <FormGroup>
                                                <Label>Tipo</Label>
                                                <Typeahead
                                                    id="selectTipo"
                                                    clearButton
                                                    labelKey="name"
                                                    placeholder="Informe o Tipo"
                                                    options={[{ name: 'Débito', value: 'D' }, { name: 'Crédito', value: 'C' }]}
                                                    onChange={(selected) => handleChangeSelect('lan_tp_lancamento', selected)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingLanFechados}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        {loadingLanFechados ?
                            <div className="loader-box">
                                <div className="loader-1"></div>
                            </div>
                            :
                            !!lancamentosFechados &&
                            <DataTable
                                persistTableHead
                                pagination
                                paginationServer
                                selectableRows
                                clearSelectedRows={toggleCleared}
                                paginationPerPage={lancamentosFechados.per_page}
                                paginationTotalRows={lancamentosFechados.total}
                                paginationDefaultPage={lancamentosFechados.current_page}
                                onChangePage={(page) => {
                                    getLancamentosFechados({ fields: { ...curFilter.current, page } })
                                }}
                                onSelectedRowsChange={handleSelectedRows}
                                paginationComponentOptions={{
                                    noRowsPerPage: true,
                                    rangeSeparatorText: 'de',
                                }}
                                contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                contextActions={
                                    <Button
                                        onClick={handleReabrirLan}
                                        color="primary"
                                        disabled={loadingReabirLan}>
                                        {loadingReabirLan ? 'Processado...' : 'Reabrir Lançamento(s)'}
                                    </Button>
                                }
                                progressPending={loadingLanFechados || loadingReabirLan}
                                columns={[
                                    {
                                        name: 'Data Competência',
                                        selector: 'data_competencia',
                                        width: '11%',
                                    },
                                    {
                                        name: "Conta",
                                        selector: "conta_name",
                                        width: '8%',

                                    },
                                    {
                                        name: "Pessoa",
                                        selector: "pessoa_name",
                                        width: '8%',

                                    },
                                    {
                                        name: 'Tipo',
                                        selector: "tipo",
                                        width: '7%',
                                        center: true
                                    },
                                    {
                                        name: 'Documento',
                                        selector: "lan_doc",

                                        center: true
                                    },
                                    {
                                        name: 'Histórico',
                                        selector: 'hist_name',
                                        maxWidth: '200px',
                                        center: true
                                    },
                                    {
                                        name: 'Valor',
                                        selector: "valor_num",
                                        center: true
                                    },
                                    {
                                        name: 'Situação',
                                        selector: 'st_fechamento',
                                        center: true
                                    }
                                ]}
                                progressComponent={
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                }
                                noDataComponent={<span className="p-20">Nenhuma Lançamento Encontrado</span>}
                                data={lancamentosFechados.data.map((lan, index) => {
                                    const data_competencia = format(parseISO(lan.lan_dt_competencia), 'dd/MM/yyyy');
                                    const conta_name = (
                                        <>
                                            <span id={`lan_conta${index}`}>{lan.conta?.ctn_cd_conta}</span>
                                            <UncontrolledTooltip target={`lan_conta${index}`}>
                                                {`${lan.conta?.ctn_cd_conta} - ${lan.conta?.ctn_nm_conta}`}
                                            </UncontrolledTooltip>
                                        </>
                                    );
                                    const pessoa_name = (
                                        <>
                                            <span id={`lan_pessoa${index}`}>{lan.pessoa?.pss_cd_pessoa}</span>
                                            <UncontrolledTooltip target={`lan_pessoa${index}`}>
                                                {`${lan.pessoa?.pss_cd_pessoa} - ${lan.pessoa?.pss_nm_pessoa}`}
                                            </UncontrolledTooltip>
                                        </>
                                    );
                                    const tipo = lan.lan_tp_lancamento === "D" ? 'Débito' : 'Crédito';
                                    const lan_doc = lan.lan_nu_doc;
                                    const hist_name = (
                                        <>
                                            <span id={`lan_hist${index}`}>{lan.lan_ds_historico}</span>
                                            <UncontrolledTooltip target={`lan_hist${index}`}>
                                                {`${lan.lan_ds_historico}`}
                                            </UncontrolledTooltip>
                                        </>
                                    );
                                    const valor_num = !!lan.lan_vl_lancamento && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lan.lan_vl_lancamento);
                                    const st_fechamento = lan.lan_st_fechamento === "A" ?
                                        <Badge color="success">Aberto</Badge> :
                                        <Badge color="danger">Fechado</Badge>;
                                    return {
                                        id: lan.id,
                                        data_competencia,
                                        conta_name,
                                        pessoa_name,
                                        tipo,
                                        lan_doc,
                                        lan_nu_doc: lan.lan_nu_doc,
                                        hist_name,
                                        valor_num,
                                        st_fechamento
                                    }
                                })}
                            />
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={clickClose}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ Lancamentos }) => {
    const { loadingLanFechados, lancamentosFechados, loadingReabirLan } = Lancamentos;
    return { loadingLanFechados, lancamentosFechados, loadingReabirLan }
}

export default connect(mapStateToProps, {
    getLancamentosFechados,
    reabrirLancamentos,
    getLancamentos
})(ReabrirLancamentos);