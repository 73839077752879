import {
    GET_LANCAMENTOS,
    GET_LANCAMENTOS_ASYNC,
    STORE_LANCAMENTOS_ASYNC,
    STORE_LANCAMENTOS,
    GET_LIST_BY_DATE_LANCAMENTOS_ASYNC,
    GET_LIST_BY_DATE_LANCAMENTOS,
    UPDATE_LANCAMENTOS,
    UPDATE_LANCAMENTOS_ASYNC,
    DESTROY_LANCAMENTOS_ASYNC,
    DESTROY_LANCAMENTOS,
    FECHAR_LANCAMENTOS_ASYNC,
    FECHAR_LANCAMENTOS,
    GET_LANCAMENTOS_FECHADOS_ASYNC,
    GET_LANCAMENTOS_FECHADOS,
    REABIR_LANCAMENTOS_ASYNC,
    REABIR_LANCAMENTOS
} from "../actionTypes";

const inital_state = {
    errors: {},
    lancamentos: null,
    lancamentosFechados: null,
    loading: false,
    loadingStore: false,
    loadingUpdate: false,
    loadingListByDate: false,
    loadingDestroy: false,
    loadingFecharLan: false,
    loadingLanFechados: false,
    loadingReabirLan: false
}

export default (state = inital_state, action) => {
    switch (action.type) {
        case GET_LANCAMENTOS_ASYNC:
            return { ...state, loading: true };
        case GET_LANCAMENTOS:
            return { ...state, ...action.payload, loading: false };
        case STORE_LANCAMENTOS_ASYNC:
            return { ...state, loadingStore: true };
        case STORE_LANCAMENTOS:
            return { ...state, ...action.payload, loadingStore: false };
        case GET_LIST_BY_DATE_LANCAMENTOS_ASYNC:
            return { ...state, loadingListByDate: true };
        case GET_LIST_BY_DATE_LANCAMENTOS:
            return { ...state, ...action.payload, loadingListByDate: false };
        case UPDATE_LANCAMENTOS_ASYNC:
            return { ...state, loadingUpdate: true };
        case UPDATE_LANCAMENTOS:
            return { ...state, ...action.payload, loadingUpdate: false };
        case DESTROY_LANCAMENTOS_ASYNC:
            return { ...state, loadingDestroy: true };
        case DESTROY_LANCAMENTOS:
            return { ...state, ...action.payload, loadingDestroy: false };
        case FECHAR_LANCAMENTOS_ASYNC:
            return { ...state, loadingFecharLan: true };
        case FECHAR_LANCAMENTOS:
            return { ...state, ...action.payload, loadingFecharLan: false };
        case GET_LANCAMENTOS_FECHADOS_ASYNC:
            return { ...state, loadingLanFechados: true };
        case GET_LANCAMENTOS_FECHADOS:
            return { ...state, ...action.payload, loadingLanFechados: false };
        case REABIR_LANCAMENTOS_ASYNC:
            return { ...state, loadingReabirLan: true };
        case REABIR_LANCAMENTOS:
            return { ...state, ...action.payload, loadingReabirLan: false };
        default: return state;

    }
}