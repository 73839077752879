import { GET_NOTAS_FISCAIS_ASYNC, UPDATE_DEVOLUCAO_NFE_ASYNC } from "../actionTypes";

export const getNotasFiscais = (payload = {}) => ({
    type: GET_NOTAS_FISCAIS_ASYNC,
    payload
});

export const updateDevelucaoNFe = payload => ({
    type: UPDATE_DEVOLUCAO_NFE_ASYNC,
    payload
})