import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Row,
    Label,
    Input,
    FormFeedback,

} from "reactstrap";

const SecondCard = ({ 
    fields, 
    validator, 
    validFields, 
    handleChange
 }) => {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                {/* <Label><h6>Valores</h6></Label> */}
                <Row>
                    <Col xs="12" className="m-b-10">
                        <Label>Lançamentos*</Label>
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="radio radio-primary m-r-50">
                                <Input
                                    id="radioinline1"
                                    type="radio"
                                    name="ctn_st_lan_deb_cre"
                                    value="D"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    onChange={handleChange} />
                                <Label className="mb-0" for="radioinline1">Só a débito</Label>
                            </div>
                            <div className="radio radio-primary m-r-50">
                                <Input
                                    id="radioinline2"
                                    type="radio"
                                    name="ctn_st_lan_deb_cre"
                                    value="C"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    onChange={handleChange} />
                                <Label className="mb-0" for="radioinline2">Só a crédito</Label>
                            </div>
                            <div className="radio radio-primary m-r-50">
                                <Input
                                    id="radioinline3"
                                    type="radio"
                                    name="ctn_st_lan_deb_cre"
                                    value="A"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    onChange={handleChange} />
                                <Label className="mb-0" for="radioinline3">Ambos</Label>
                            </div>
                            <FormFeedback
                                style={{ display: validator.visibleFields.includes('ctn_st_lan_deb_cre') ? 'block' : 'none' }}>
                                {validator.message('ctn_st_lan_deb_cre', fields.ctn_st_lan_deb_cre, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <Label>Opções*</Label>
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="checkbox checkbox-dark m-r-50">
                                <Input
                                    id="inline-1"
                                    type="checkbox"
                                    name="ctn_st_saldo_neg"
                                    onBlur={() => validFields('chkOpcoes')}
                                    value="S"
                                    onChange={handleChange} />
                                <Label for="inline-1">Saldo negativo</Label>
                            </div>
                            <div className="checkbox checkbox-dark m-r-50">
                                <Input
                                    id="inline-2"
                                    type="checkbox"
                                    name="ctn_st_lanc_manual"
                                    value="S"
                                    onBlur={() => validFields('chkOpcoes')}
                                    onChange={handleChange} />
                                <Label for="inline-2">Lançamento manual</Label>
                            </div>
                            <div className="checkbox checkbox-dark m-r-50">
                                <Input
                                    id="inline-3"
                                    type="checkbox"
                                    name="ctn_st_pag_cheque"
                                    onBlur={() => validFields('chkOpcoes')}
                                    value="S"
                                    onChange={handleChange}
                                />
                                <Label for="inline-3">Paga com cheque</Label>
                            </div>
                            <FormFeedback
                                style={{ display: validator.visibleFields.includes('chkOpcoes') ? 'block' : 'none' }}
                            >
                                {validator.message('chkOpcoes', (
                                    !fields.ctn_st_pag_cheque.length &&
                                    !fields.ctn_st_lanc_manual.length &&
                                    !fields.ctn_st_saldo_neg.length),
                                    `opcoes:${(
                                        !fields.ctn_st_pag_cheque.length &&
                                        !fields.ctn_st_lanc_manual.length &&
                                        !fields.ctn_st_saldo_neg.length)}`
                                )}
                            </FormFeedback>
                        </FormGroup>
                    </Col>

                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ }) => {
    return {};
}
export default connect(mapStateToProps, {})(SecondCard);