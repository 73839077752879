import React from 'react';
import { Col, FormGroup, Row, Label, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { parseISO } from 'date-fns';
function Periodo({
    filter,
    handleChangeSelect,
    title = "Período",
    type = "periodo"
}) {
    return (
        <div className="b-light border-2 p-10">
            <h6>{title}</h6>
            <Row>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Data Inicial
                        </Label>
                        <DatePicker
                            locale={br}
                            autoComplete="off"
                            dateFormat="dd/MM/yyyy"
                            selected={parseISO(type == "periodo" ? filter.periodoInicial : filter.referenciaInicial)}
                            className="form-control digits"
                            placeholderText="Informe a data inicial"
                            onChange={(date) => {
                                if (type == "periodo") {
                                    handleChangeSelect('periodoInicial', date)
                                } else {
                                    handleChangeSelect('referenciaInicial', date)
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Data Final
                        </Label>
                        <DatePicker
                            locale={br}
                            autoComplete="off"
                            minDate={parseISO(type == "periodo" ? filter.periodoInicial : filter.referenciaInicial)}
                            dateFormat="dd/MM/yyyy"
                            selected={parseISO(type == "periodo" ? filter.periodoFinal : filter.referenciaFinal)}
                            className="form-control digits"
                            placeholderText="Informe a data final"
                            onChange={(date) => {
                                if (type == "periodo") {
                                    handleChangeSelect('periodoFinal', date)
                                } else {
                                    handleChangeSelect('referenciaFinal', date)
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </ div>
    );
}

export default Periodo;