import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, Input, Form } from 'reactstrap';
import Breadcrumb from '../../../../layout/breadcrumb';
import { getTiposMovPrd } from "../../../../redux/actions";
import { Typeahead } from 'react-bootstrap-typeahead';
import Table from "./Table";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
function TipoMovimento({ getTiposMovPrd, tiposMovsPrd }) {
    const [filter, setFilter] = useState({ tmp_tp_movimento: null, tmp_nm_tipo_mvp: null });
    const [openModal, setOpenModal] = useState(null);
    const [selectedTipoMovPrd, setSelectedTipoMovPrd] = useState(null);

    const curFilter = useRef(null);

    useEffect(() => {
        getTiposMovPrd()
    }, []);

    const submitFilter = e => {
        e.preventDefault();
        getTiposMovPrd({ fields: filter });
        curFilter.current = filter;
    }
    return (
        <>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit
                isOpen={openModal === 'edit'}
                clickClose={() => setOpenModal(null)}
                selectedTipoMovProduto={selectedTipoMovPrd} />
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} tipoMovPrd={!!tiposMovsPrd && tiposMovsPrd.data[selectedTipoMovPrd]} />
            <Breadcrumb parent="Estoque" title="Tipo Movimentação" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                sid="tmp_tipo_mov.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('add')
                                }}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="7" xs="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    placeholder="Informe o Nome"
                                                    maxLength={30}
                                                    onChange={({ target: { value } }) => {
                                                        setFilter({ ...filter, tmp_nm_tipo_mvp: !!value ? value : null })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Tipo</Label>
                                                <Typeahead
                                                    id="selectTip"
                                                    labelKey="name"
                                                    options={[{ name: 'Entrada', value: 'E' }, { name: 'Saida', value: 'S' }]}
                                                    placeholder="Informe o Tipo"
                                                    clearButton
                                                    onChange={(selected) => {
                                                        if (!!selected.length) {
                                                            setFilter({ ...filter, tmp_tp_movimento: selected[0].value })
                                                        } else {
                                                            setFilter({ ...filter, tmp_tp_movimento: null })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" xs="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    className="m-t-35"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Table
                                    curFilter={curFilter}
                                    setSelectedTipoMovPrd={setSelectedTipoMovPrd}
                                    setOpenModal={setOpenModal} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = ({ TipoMovPrd }) => {
    const { tiposMovsPrd } = TipoMovPrd;
    return { tiposMovsPrd }
}


export default connect(mapStateToProps, {
    getTiposMovPrd
})(TipoMovimento);