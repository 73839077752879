import React, { Fragment, useState, useLayoutEffect, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap'
import { Sliders } from 'react-feather'
import { Link } from 'react-router-dom'
import { defaultMainPath } from "../../constraints/variables";
import { switchUserEmpresa } from "../../redux/actions";
import { connect } from "react-redux";

import ModalChangeEmpresa from "./changeEmpresa";
const Leftbar = ({ user_empresas, active_user_empresa, switchUserEmpresa, loadingSwUsrEmpresa }) => {

  const [sidebartoggle, setSidebartoggle] = useState(true)
  const [openModal, setOpenModal] = useState(null);
  const width = useWindowSize()

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }


  useEffect(() => {

    var ignoreClick_On_Out_side_Element = document.getElementById('out_side_click');
    var ignoreClick_On_Main_Nav_Element = document.getElementById('sidebar-menu');
    document.addEventListener('click', function (event) {
      var isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(event.target);
      var isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(event.target);
      if (window.innerWidth <= 991 && !isClickOutSideElement && !isClickMainNavElement) {
        //Do something click is outside specified element
        document.querySelector(".page-header").className = "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
      }
    });



  }, [width])


  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {

      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector(".page-header").className = "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
        // document.querySelector(".mega-menu-container").classList.remove("d-block")
      } else {
        setSidebartoggle(!toggle);
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
      }
    }
  };

  return (
    <Fragment>
      <ModalChangeEmpresa isOpen={openModal === 'changeEmp'} clickClose={() => setOpenModal(null)} />
      <div className="header-logo-wrapper" id="out_side_click">
        <div className="logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/${defaultMainPath}`}>
            <img className="img-fluid for-light" src={require("../../assets/images/logo/logo.png")} alt="" />
            <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="" />
          </Link>
        </div>
        <div className="toggle-sidebar" onClick={() => responsive_openCloseSidebar(sidebartoggle)} style={window.innerWidth <= 991 ? { display: "block" } : { display: "none" }}>
          <Sliders className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
        </div>
      </div>
      <Col className="left-header horizontal-wrapper pl-0">
        <ul className="horizontal-menu">
          <li className="mega-menu outside">
            <a
              className={`nav-link ${loadingSwUsrEmpresa ? 'disabled' : ''}`}
              href="#javascript"
              onClick={(e) => {
                e.preventDefault()
                setOpenModal('changeEmp')
              }}
            >
              <i className="fa fa-building-o f-16 p-r-5"></i><span>{!!active_user_empresa ? 'EMPRESA: ' + active_user_empresa.emp_nm_empresa : 'SEM EMPRESA'}</span>
            </a>

          </li>
        </ul>
      </Col>
    </Fragment>
  );
}

const mapStateToProps = ({ Auth }) => {
  const { user_empresas, active_user_empresa, loadingSwUsrEmpresa } = Auth;
  return ({ user_empresas, active_user_empresa, loadingSwUsrEmpresa })
}

export default connect(mapStateToProps, {
  switchUserEmpresa
})(Leftbar);