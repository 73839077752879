import React, { useEffect, useState, useCallback } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { serverUrlImagesUser } from "../../../constraints/variables";
import { Container, Row, Col, Card, CardBody, CardTitle, Input, FormGroup, Label, Button, Form, Alert } from 'reactstrap';
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import { Typeahead } from 'react-bootstrap-typeahead';
import sweetalert2 from "sweetalert2";
import { getUsers, changeStatusUser, getListDepartamentos, destroyUsers } from "../../../redux/actions";
import View from "./View/";
import Add from "./Add/";
import Edit from "./Edit/";
const Usuarios = ({
    getUsers,
    users,
    loading,
    changeStatusUser,
    loadingChangeStatus,
    getListDepartamentos,
    departamentos,
    loadingDepartamentos,
    destroyUsers,
    loadingDestroy
}) => {
    useEffect(() => {
        getUsers();
        getListDepartamentos();
    }, []);

    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [filter, setFilter] = useState({ name: null, departamento_id: null, active: null })
    const [curFilter, setCurFilter] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, name }) => ({ id, name })))
    }, []);
    const deleteUsers = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ name }) => {
                return (`<li class="list-group-item-action list-group-item">${name}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!users && users.current_page !== 1) ? users.current_page : 1;
            const fields = { users_ids: selectedRows, page, curFilter };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyUsers({ fields, onDelete });
        }
    }
    const handleStatus = async (index) => {
        const user = users.data[index];

        const confirm = await sweetalert2.fire({
            title: `Deseja realmente ${user.active === "1" ? 'inativar' : 'ativar'} este usuário?`,
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            icon: 'warning',
        });
        if (confirm.value) {
            changeStatusUser({ index })
        }
    }
    const submitFilter = e => {
        e.preventDefault();
        getUsers({ fields: filter });
        setCurFilter(filter);
        setToggleCleared(!toggleCleared);
    }

    return (
        <>
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} selectedUser={selectedUser} />
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} selectedUser={selectedUser} />
            <Breadcrumb parent="Gestão" title="Usuários" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                        <Button
                            sid="users.store"
                            color="primary"
                            size="md"
                            onClick={() => setOpenModal('add')}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                        </Button>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="5">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input placeholder="Nome do usuário" onChange={({ target: { value } }) => setFilter({ ...filter, name: value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm="12">
                                            <FormGroup>
                                                <Label>Departamento</Label>
                                                <Typeahead
                                                    id="selectDepartamento"
                                                    labelKey="name"
                                                    clearButton
                                                    placeholder="Selecione o Departamento"
                                                    isLoading={loadingDepartamentos}
                                                    disabled={loadingDepartamentos}
                                                    options={departamentos.map(({ id, dep_nm_departamento }) => ({ name: dep_nm_departamento, value: id }))}
                                                    onChange={(departamento) => {
                                                        if (!!departamento.length) {
                                                            setFilter({ ...filter, departamento_id: departamento[0].value });
                                                        } else {
                                                            setFilter({ ...filter, departamento_id: null });
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" sm="12">
                                            <FormGroup>
                                                <Label>Status</Label>
                                                <Typeahead
                                                    id="selectStatus"
                                                    labelKey="name"
                                                    clearButton
                                                    placeholder="Selecione o Status"
                                                    options={[{ name: 'Ativo', value: 1 }, { name: 'Inativo', value: 0 }]}
                                                    onChange={(status) => {
                                                        if (!!status.length) {
                                                            setFilter({ ...filter, active: status[0].value });
                                                        } else {
                                                            setFilter({ ...filter, active: null });
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loading}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody className="p-t-20">
                                {loading ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!users ?
                                        <DataTable
                                            persistTableHead
                                            selectableRows
                                            pagination
                                            paginationServer
                                            clearSelectedRows={toggleCleared}
                                            onSelectedRowsChange={handleSelectedRows}
                                            columns={[
                                                {
                                                    selector: 'img_user',
                                                    center: true,
                                                },
                                                {
                                                    name: "Nome",
                                                    selector: 'name',
                                                    center: true,
                                                },
                                                {
                                                    name: "Email",
                                                    selector: 'email',
                                                    center: true,
                                                },
                                                {
                                                    name: 'Departamento',
                                                    selector: 'departamento_name',
                                                    center: true,

                                                },
                                                {
                                                    name: "Status",
                                                    selector: 'status',
                                                    center: true,
                                                },
                                                {
                                                    name: 'Ações',
                                                    selector: 'acoes',
                                                    center: true,
                                                }
                                            ]}
                                            onChangePage={(page) => getUsers({ fields: { page, ...curFilter } })}
                                            paginationPerPage={users.per_page}
                                            paginationTotalRows={users.total}
                                            paginationDefaultPage={users.current_page}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                                rangeSeparatorText: 'de',
                                            }}
                                            progressComponent={
                                                <div className="loader-box">
                                                    <div className="loader-1"></div>
                                                </div>
                                            }
                                            progressPending={loadingChangeStatus || loadingDestroy}
                                            contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                            contextActions={
                                                <Button
                                                    sid="users.destroy"
                                                    onClick={deleteUsers}
                                                    disabled={loadingDestroy}
                                                    color="danger">{loadingDestroy ? 'Processando...' : 'Remover Usuário(s)'}
                                                </Button>
                                            }
                                            noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                                            data={users.data.map(({ id, name, email, active, departamento, image }, index) => {
                                                const img_user = <img src={!!image ?
                                                    `${serverUrlImagesUser}${image}` :
                                                    require("../../../assets/images/user/user.png")}
                                                    className="img-50 img-fluid m-10 rounded-circle" alt="" />;
                                                const status = (active === "1" ?
                                                    <i className="fa fa-circle font-success" title="Ativo"></i> :
                                                    <i className="fa fa-circle font-danger" title="Inativo"></i>)
                                                const departamento_name = !!departamento && !!departamento.dep_nm_departamento ? departamento.dep_nm_departamento : 'Não Informado';
                                                const acoes = (
                                                    <div style={{ display: "flex", overflow: "auto" }}>
                                                        <a
                                                            sid="users.index"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSelectedUser(index);
                                                                setOpenModal('view');
                                                            }}
                                                        >
                                                            <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                        </a>
                                                        <a
                                                            sid="users.update"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSelectedUser(index);
                                                                setOpenModal('edit');
                                                            }}
                                                        >
                                                            <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                        </a>
                                                        <a
                                                            sid="users.change_status"
                                                            title={active === "1" ? 'Inativar' : 'Ativar'}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleStatus(index)
                                                            }}
                                                        >
                                                            <i className={`
                                                        fa ${active === "1" ? "fa-lock" : "fa-unlock-alt"} 
                                                        font-${active === "1" ? 'danger' : 'success'} f-24 m-10`}></i>
                                                        </a>
                                                    </div>
                                                )
                                                return ({ id, img_user, name, email, departamento_name, status, acoes })
                                            })}
                                        />
                                        :
                                        <Alert color="light">Nenhum usuário foi carregado.</Alert>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = ({ Users, Departamentos }) => {
    const { users, loading, loadingChangeStatus, loadingDestroy } = Users;
    const { list: departamentos, loadingList: loadingDepartamentos } = Departamentos;
    return { users, loading, loadingChangeStatus, departamentos, loadingDepartamentos, loadingDestroy }
}
export default connect(mapStateToProps, {
    getUsers,
    changeStatusUser,
    getListDepartamentos,
    destroyUsers
})(Usuarios);