import React from "react";
import InputMask from "react-input-mask";
import { Input } from 'reactstrap';

function CpfCnpjInput(props) {
    const fillValue = !!props.value && props.value.replace(/\D+/g, '');
    const fillDefValue = !!props.defaultValue && props.defaultValue.replace(/\D+/g, '');
    return (
        <Input
            {...props}
            beforeMaskedValueChange={(newState, oldState, userInput) => {
                if (oldState.value.length === 14 && !!userInput) {
                    newState.value += userInput;
                    newState.selection = { start: null, end: null };
                }
                return newState
            }}
            maskChar={""}
            tag={InputMask}
            mask={(!!fillValue && fillValue.length > 11) || (!!fillDefValue && fillDefValue.length > 11) ? '99.999.999/9999-99' : '999.999.999-99'}
        />
    )

}

export default CpfCnpjInput;