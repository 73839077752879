import { call, put, takeLatest, fork, all, select, debounce } from 'redux-saga/effects'
import Api from "../../services/api";
import {
    GET_CONTAS_ASYNC,
    GET_CONTAS,
    STORE_CONTAS_ASYNC,
    STORE_CONTAS,
    UPDATE_CONTAS_ASYNC,
    UPDATE_CONTAS,
    DESTROY_CONTAS_ASYNC,
    DESTROY_CONTAS,
    GET_LIST_CONTAS_ASYNC,
    GET_LIST_CONTAS,
    GET_LIST_CONTAS_BANCOS_ASYNC,
    GET_LIST_CONTAS_BANCOS,
    GET_LIST_CONTA_PESSOAS_ASYNC,
    GET_LIST_CONTA_PESSOAS,
    GET_LIST_CONTAS_EMPRESA_ASYNC,
    GET_LIST_CONTAS_EMPRESA,
    GET_SALDOS_CONTA_ASYNC,
    GET_SALDOS_CONTA
} from '../actionTypes';
import { toast } from 'react-toastify';
import {
    selectorContas,
    selectorListContas,
    selectorListContasBancos,
    selectorListContaPessoas,
    selectorListContasEmp
} from '../selectors';
import { mountList } from "../../helpers";

function* getContasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiPlanosContas, fields);
        if (data.ok) {
            const { contas } = data;
            yield put({ type: GET_CONTAS, payload: { contas } });
        }
    } catch (error) {
        yield put({ type: GET_CONTAS, payload: {} });
    }
}
const apiPlanosContas = async fields => {
    const { data } = await Api.get(`/api/contas`, { params: { ...fields } });

    return data;
}

function* storePlanoContasSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStorePlanoContas, fields);
        if (!!data && !!data.ok) {
            const { contas } = data;
            yield put({ type: STORE_CONTAS, payload: { contas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_CONTAS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_CONTAS, payload: {} });
    }
}
const apiStorePlanoContas = async fields => {
    const data = await Api.post(`/api/contas/store`, { ...fields });

    return data;
}

function* updateContasSaga({ payload }) {
    try {
        const { fields, onUpdate, index, id } = payload;

        const { data, errors } = yield call(apiUpdatePlanoContas, fields, id);
        if (!!data && data.ok) {
            const contas = yield select(selectorContas);
            contas.data.splice(index, 1, data.conta);
            yield put({ type: UPDATE_CONTAS, payload: { contas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onUpdate();
        }
        if (!!errors) {
            yield put({ type: UPDATE_CONTAS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_CONTAS, payload: {} });
    }
}
const apiUpdatePlanoContas = async (fields, id) => {

    const data = await Api.put(`/api/contas/update/${id}`, { ...fields });

    return data;
}

function* destroyContasSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyPlanoContas, fields);
        if (data.ok) {
            const { contas } = data;
            yield put({ type: DESTROY_CONTAS, payload: { contas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_CONTAS, payload: {} });
    }
}
const apiDestroyPlanoContas = async fields => {
    const { data } = await Api.delete(`/api/contas/destroy`, { data: fields });

    return data;
}

function* getListContasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListPlanoContas, fields);
        if (data.ok) {
            const list = yield select(selectorListContas);
            list.data = mountList(list.data, data.list.data, ['ctn_cd_conta']);
            list.total = data.list.total;
            yield put({ type: GET_LIST_CONTAS, payload: { list } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_CONTAS, payload: {} });
    }
}
const apiGetListPlanoContas = async fields => {
    const { data } = await Api.get(`/api/contas/list`, { params: fields });

    return data;
}


function* getListContasBancosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListContasBancos, fields);
        if (data.ok) {
            const list_bancos = yield select(selectorListContasBancos);
            list_bancos.data = mountList(list_bancos.data, data.list_bancos.data, ['ctn_cd_conta']);
            list_bancos.total = data.list_bancos.total;
            yield put({ type: GET_LIST_CONTAS_BANCOS, payload: { list_bancos } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_CONTAS_BANCOS, payload: {} });
    }
}
const apiGetListContasBancos = async fields => {

    const { data } = await Api.get(`/api/contas/listBancos`, { params: fields });

    return data;
}

function* getContaPessoasSaga({ payload }) {
    try {
        const { fields, conta } = payload;
        const data = yield call(apiGetContaPessoas, conta, fields);
        if (data.ok) {
            const listContaPessoas = yield select(selectorListContaPessoas);
            listContaPessoas.data = mountList(listContaPessoas.data, data.listContaPessoas.data, ['pss_cd_pessoa']);
            listContaPessoas.total = data.listContaPessoas.total;
            yield put({ type: GET_LIST_CONTA_PESSOAS, payload: { listContaPessoas } });

        }
    } catch (error) {
        yield put({ type: GET_LIST_CONTA_PESSOAS, payload: {} });
    }
}
const apiGetContaPessoas = async (conta, fields) => {
    const { data } = await Api.get(`/api/contas/listContaPessoas/${conta}`, { params: fields });

    return data;
}

function* getListContasEmpSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListContasEmp, fields);
        if (data.ok) {
            if (!!fields?.offset) {
                const listContasEmpresa = yield select(selectorListContasEmp);
                listContasEmpresa.data = mountList(listContasEmpresa.data, data.listContasEmpresa.data, ['ctn_cd_conta']);
                listContasEmpresa.total = data.listContasEmpresa.total;

                yield put({ type: GET_LIST_CONTAS_EMPRESA, payload: { listContasEmpresa } })
            } else {
                yield put({ type: GET_LIST_CONTAS_EMPRESA, payload: { listContasEmpresa: data.listContasEmpresa } });
            }


        }
    } catch (error) {
        yield put({ type: GET_LIST_CONTAS_EMPRESA, payload: {} })
    }
}
const apiGetListContasEmp = async fields => {
    const { data } = await Api.get(`/api/contas/listContasEmpresa`, { params: fields });

    return data;
}

function* getSaldoContaSaga({ payload }) {
    try {
        const { fields } = payload;
   
        const data = yield call(apiGetSaldoConta, fields);

        const { saldoConta } = data;
        yield put({ type: GET_SALDOS_CONTA, payload: { saldoConta } });

    } catch (error) {
        yield put({ type: GET_SALDOS_CONTA, payload: {} });
    }
}
const apiGetSaldoConta = async fields => {
    const { data } = await Api.get(`/api/saldos/getSaldoConta`, { params: fields });

    return data;
}

function* watchGetPlanosContas() {
    yield takeLatest(GET_CONTAS_ASYNC, getContasSaga);
}
function* watchStorePlanoContas() {
    yield takeLatest(STORE_CONTAS_ASYNC, storePlanoContasSaga);
}
function* watchUpdatePlanoContas() {
    yield takeLatest(UPDATE_CONTAS_ASYNC, updateContasSaga);
}
function* watchDestroyPlanoContas() {
    yield takeLatest(DESTROY_CONTAS_ASYNC, destroyContasSaga);
}
function* watchGetListPlanoContas() {
    yield debounce(1000, GET_LIST_CONTAS_ASYNC, getListContasSaga);
}
function* watchGetListContasBancos() {
    yield debounce(1000, GET_LIST_CONTAS_BANCOS_ASYNC, getListContasBancosSaga);
}
function* watchGetListContaPessoas() {
    yield debounce(1000, GET_LIST_CONTA_PESSOAS_ASYNC, getContaPessoasSaga);
}
function* watchGetListContasEmp() {
    yield debounce(1000, GET_LIST_CONTAS_EMPRESA_ASYNC, getListContasEmpSaga);
}
function* watchGetSaldoConta() {
    yield takeLatest(GET_SALDOS_CONTA_ASYNC, getSaldoContaSaga);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetPlanosContas),
        fork(watchStorePlanoContas),
        fork(watchUpdatePlanoContas),
        fork(watchDestroyPlanoContas),
        fork(watchGetListPlanoContas),
        fork(watchGetListContasBancos),
        fork(watchGetListContaPessoas),
        fork(watchGetListContasEmp),
        fork(watchGetSaldoConta)
    ]);
}