import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
    getListCentroCusto,
} from "../../../../redux/actions";
import { Col, FormGroup, Label, Row, FormFeedback, Input, InputGroup, InputGroupAddon } from "reactstrap";
import SelectPagination from "../../../../components/SelectPagination";
import NumberFormat from 'react-number-format';

const DadosGerais = ({
    validator,
    errors,
    fields,
    validFields,
    handleChangeSelect,
    listCentroCusto,
    loadingListCentroCusto,
    getListCentroCusto,
    handleChange,
    handleValor
}) => {
    const optionsCentroCusto = useMemo(() =>
        listCentroCusto.data.map(({ cct_nm_centro, cct_cd_centro }) =>
            ({
                name: `${cct_cd_centro} - ${cct_nm_centro}`,
                value: cct_cd_centro,
            })),
        [listCentroCusto.data]);

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col lg="7" md="12">
                            <FormGroup>
                                <Label>Número do Documento*</Label>
                                <Input
                                    title="Número do documento que está gerando a contrapartida"
                                    placeholder="Informe o Número do Documento"
                                    defaultValue={fields.lan_nu_doc}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={
                                        validator.visibleFields.includes('lan_nu_doc') ||
                                        !!errors.lan_nu_doc ||
                                        (!validator.fields.lan_nu_doc && validator.messagesShown)
                                    }
                                    name="lan_nu_doc"
                                    maxLength={20}
                                    onChange={handleChange}
                                />
                                <FormFeedback>
                                    {validator.message('lan_nu_doc', fields.lan_nu_doc, 'required|max:20')}
                                    {(!!errors.lan_nu_doc) && errors.lan_nu_doc.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="5" md="12">
                            <FormGroup>
                                <Label>Valor do Lançamento*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        id="fieldValorLan"
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        defaultValue={parseFloat(fields.lan_vl_lancamento)}
                                        customInput={Input}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('lan_vl_lancamento') ||
                                            (!validator.fields.lan_vl_lancamento && validator.messagesShown)
                                        }
                                        name="lan_vl_lancamento"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ value }) => handleValor('lan_vl_lancamento', value)}
                                    />
                                </InputGroup>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('lan_vl_lancamento') ||
                                            (!validator.fields.lan_vl_lancamento && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('lan_vl_lancamento', fields.lan_vl_lancamento,
                                        `required|verifica_valor:${fields.lan_vl_lancamento},${fields.conta?.ctn_vl_minimo_lan},${fields.conta?.ctn_vl_maximo_lan}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="7" md="12">
                            <FormGroup>
                                <Label>Centro de Custo*</Label>
                                <SelectPagination
                                    id="selectCentroCusto"
                                    labelKey={(option) => `${option.name}`}
                                    clearButton
                                    placeholder="Selecione o Centro Custo"
                                    emptyLabel="Nenhum resultado encontrado"
                                    data={optionsCentroCusto}
                                    selected={(options) => options.filter((option) => option.value === fields.lan_cd_centro_custo)}
                                    onBlur={() => validFields('lan_cd_centro_custo')}
                                    reduxAction={getListCentroCusto}
                                    maxResults={50}
                                    isLoading={loadingListCentroCusto}
                                    isInvalid={validator.visibleFields.includes('lan_cd_centro_custo') ||
                                        !!errors.lan_cd_centro_custo ||
                                        (!validator.fields.lan_cd_centro_custo && validator.messagesShown)
                                    }
                                    totalResults={listCentroCusto.total}
                                    onChange={(selected) => handleChangeSelect('lan_cd_centro_custo', selected)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('lan_cd_centro_custo') ||
                                            !!errors.lan_cd_centro_custo ||
                                            (!validator.fields.lan_cd_centro_custo && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('lan_cd_centro_custo', fields.lan_cd_centro_custo, 'required')}
                                    {(!!errors.lan_cd_centro_custo) && errors.lan_cd_centro_custo.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="5" md="12">
                            <FormGroup>
                                <Label>Número do Pedido</Label>
                                <Input
                                    maxLength={4}
                                    defaultValue={fields.lan_nu_pedido}
                                    name="lan_nu_pedido"
                                    placeholder="Informe o Número do Pedido"
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ CentroCusto, Contas }) => {
    const { list: listContas } = Contas;
    const { list: listCentroCusto, loadingList: loadingListCentroCusto } = CentroCusto;
    return {
        listCentroCusto,
        loadingListCentroCusto,
        listContas
    }
}

export default connect(mapStateToProps, {
    getListCentroCusto,

})(DadosGerais)