import React, { Fragment, useState, useRef, useEffect, useCallback } from "react";
import Periodo from "./Periodo";
import Pessoas from "./Pessoas";
import CentroCusto from "./CentroCusto";
import RltPessoas from "./RltPessoas.jsx";
import Breadcrumb from "../../../../layout/breadcrumb";
import { format } from "date-fns";
import SimpleReactValidator from 'simple-react-validator';
import { validatorMessages } from "../../../../constraints/variables";
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import PrintComponent from "../../../../components/PrintComponent";
import { connect } from "react-redux";

import {
  getListPessoas,
  getListContas,
  getListCentroCusto,
  getRltExtratoPessoas,
} from "../../../../redux/actions";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Button,
} from "reactstrap";

const RelExtratoPessoa = ({
  getListPessoas,
  getListContas,
  getListCentroCusto,
  getRltExtratoPessoas,
  loadingRltExtratoPessoas,
  rltExtratoPessoas,
}) => {
  useEffect(() => {
    getListPessoas()
    getListContas()
    getListCentroCusto()
  },[])

  const printCompRef = useRef();

  const onSubmitFilter = e => {
    e.preventDefault();
    getRltExtratoPessoas({fields:filter});
  };


  const handlePrint = useReactToPrint({
    content: () => printCompRef.current,
    documentTitle: `Relatório Operacional Extrato de Pessoas ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
    // onBeforePrint: () => setLoadingPrint(true),
    // onAfterPrint: () => setLoadingPrint(false),
    onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
  });

  const [filter, setFilter] = useState({
    pessoas: [],
    centroCusto: [],
    dataInicial: format(new Date(), "yyyy-MM-dd"),
    dataFinal: format(new Date(), "yyyy-MM-dd"),
  });

 
  const handleChangeSelect = (name, selected) => {
    switch (name) {
        case "dataInicial":
        case "dataFinal":
            if (!!selected) {
                setFilter({ ...filter, [name]: format(selected, "yyyy-MM-dd") });
            } else {
                setFilter({ ...filter, [name]: format(new Date(), "yyyy-MM-dd") });
            }
        break;
        case "centroCusto":
        case "pessoas":
            if (!!selected.length) {
              setFilter({ ...filter, [name]: selected.map(({ value }) => value) });
              //validator.hideMessageFor(name)
            } else {
              setFilter({ ...filter, [name]: []});
              //validator.showMessageFor(name)
            }
        break;      
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Operacional" title="Extrato Pessoas" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <h5>Filtro</h5>
                </CardTitle>
                <Form  onSubmit={onSubmitFilter}>
                  <Row>
                    <Col lg="12" xs="12" className="m-b-5">
                        <Periodo filter={filter} handleChangeSelect={handleChangeSelect}/>
                    </Col>
                  </Row>
                  <Row>
                      <Col lg="6" className="m-b-5"> 
                          <Pessoas handleChangeSelect={handleChangeSelect} />
                      </Col>
                      <Col lg="6" className="m-b-5">
                          <CentroCusto handleChangeSelect={handleChangeSelect} />
                      </Col>
                  </Row>
                  <Row>
                  <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormGroup>
                          <Button
                              title="Pesquisar"
                              disabled={loadingRltExtratoPessoas} 
                              className="m-t-30"
                              color="primary">
                              <i className="fa fa-search"></i>
                          </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            {
              loadingRltExtratoPessoas ?
              <div className="loader-box">
                  <div className="loader-1"></div>
              </div>
              :
              <Card>
                  <CardBody>  
                      { !!rltExtratoPessoas.dados &&
                        <div className="m-b-10 d-flex justify-content-end">
                            <Button size="sm" onClick={handlePrint}className="f-16">
                              <i className="fa fa-print"></i>
                              <span className="p-l-5">
                                Imprimir
                              </span>
                            </Button>
                        </div>
                      }
                      <PrintComponent ref={printCompRef}>
                          <RltPessoas filter={filter} handlePrint={handlePrint} />
                      </PrintComponent>
                  </CardBody>
              </Card>
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({Auth, RltOperacionais}) => {
  const { active_user_empresa } = Auth;
  const { rltExtratoPessoas, loadingRltExtratoPessoas } = RltOperacionais

  return {active_user_empresa, rltExtratoPessoas, loadingRltExtratoPessoas}
}

export default connect(mapStateToProps, {
  getListPessoas,
  getListContas,
  getListCentroCusto,
  getRltExtratoPessoas
})(RelExtratoPessoa)
