import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Button, Input, FormGroup, Label, Form, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { getCentrosCusto } from "../../../redux/actions";
import Table from "./Table";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import { CpfCnpjInput } from '../../../components/CustomInputs';

const CentroCusto = ({ centrosCusto, getCentrosCusto, loadingCentrosCusto }) => {

    useEffect(() => {
        getCentrosCusto();
    }, []);

    const [openModal, setOpenModal] = useState(null);
    const [selectedCct, setSelectedCct] = useState(null);
    const [filter, setFilter] = useState({
        cct_nu_identificacao: null,
        cct_nm_centro: null

    })

    const curFilter = useRef(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value || null });
    }

    const submitFilter = e => {
        e.preventDefault();
        getCentrosCusto({ fields: filter });
        curFilter.current = filter;
    }

    return (
        <Fragment>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit
                isOpen={openModal === 'edit'}
                clickClose={() => setOpenModal(null)}
                selectedCct={selectedCct}
            />
            <View
                isOpen={openModal === 'view'}
                clickClose={() => setOpenModal(null)}
                centroCusto={!!centrosCusto && centrosCusto.data[selectedCct]}
            />
            <Breadcrumb parent="Tabelas" title="Centro de Custo" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                sid="centro_custo.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('add')
                                }}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                    </Col>
                    <Col sm="12">
                            <Card className="shadow">
                                <CardBody>
                                    <CardTitle>
                                        <h5>Filtro</h5>
                                    </CardTitle>
                                    <Form onSubmit={submitFilter}>
                                        <Row>
                                            <Col md="6" xs="12">
                                                <FormGroup>
                                                    <Label>Nome</Label>
                                                    <Input
                                                        placeholder="Informe o Nome"
                                                        name="cct_nm_centro"
                                                        onChange={handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="5" xs="12">
                                                <FormGroup>
                                                    <Label>Num.Identificação</Label>
                                                    <CpfCnpjInput
                                                        placeholder="Informe o Num.Identificação"
                                                        name="cct_nu_identificacao"
                                                        onChange={handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1" sm="12" className="d-flex justify-content-center">
                                                <FormGroup>
                                                    <Button
                                                        title="Pesquisar"
                                                        disabled={loadingCentrosCusto}
                                                        className="m-t-30"
                                                        color="primary">
                                                        <i className="fa fa-search"></i>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                    </Col>
                    <Col sm="12">
                        <Card className="shadow shadow-showcase">
                            <CardBody className="p-t-20">
                                <Table
                                    setOpenModal={setOpenModal}
                                    setSelectedCct={setSelectedCct}
                                    curFilter={curFilter}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStatToProps = ({ CentroCusto }) => {
    const { centrosCusto, loadingCentrosCusto } = CentroCusto;
    return { centrosCusto, loadingCentrosCusto }
}

export default connect(mapStatToProps, {
    getCentrosCusto
})(CentroCusto);