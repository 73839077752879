import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";

const Conta = ({ contrapartida }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Conta</Label>
                                <Input defaultValue={`${contrapartida.conta.ctn_cd_conta} - ${contrapartida.conta.ctn_nm_conta}`} readOnly />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Mínimo</Label>
                                <Input
                                    defaultValue={!!contrapartida.conta?.ctn_vl_minimo_lan ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(contrapartida.conta.ctn_vl_minimo_lan)
                                        :
                                        'Não Informado'
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Máximo</Label>
                                <Input
                                    defaultValue={!!contrapartida.conta?.ctn_vl_maximo_lan ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(contrapartida.conta.ctn_vl_maximo_lan)
                                        :
                                        'Não Informado'
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Histórico</Label>
                                <Input
                                    defaultValue={contrapartida.cnt_ds_historico}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Pessoa</Label>
                                <Input defaultValue={`${contrapartida.pessoa.pss_cd_pessoa} - ${contrapartida.pessoa.pss_nm_pessoa}`} readOnly/>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({  }) => {
    return {  }
}

export default connect(mapStateToProps, {
})(Conta);