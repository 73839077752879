import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Input,
    Row,
    Label,
    ListGroup,
    ListGroupItem,
    Alert
} from "reactstrap";
import ScrollArea from "react-scrollbar";

function Contas({ user }) {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Contas</h6></Label>
                    {!!user.contas.length ?
                        <ScrollArea vertical={true} horizontal={false}>
                            <ListGroup>
                                {user.contas.map((conta, key) => {
                                    return (
                                        <ListGroupItem className="list-group-item-action" key={key}>
                                            <span style={{ display: 'block' }}>{`${conta.ctn_cd_conta} - ${conta.ctn_nm_conta}`}</span>
                                            <small>Empresa: {`${conta.empresa?.emp_nm_empresa}`}</small>
                                        </ListGroupItem>
                                    )
                                })}
                            </ListGroup>
                        </ScrollArea>
                        :
                        <Alert color="light">Nenhuma Conta Vinculada</Alert>
                    }
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Contas);