import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Button } from "reactstrap";
import sweetalert2 from "sweetalert2";
import { destroyProdutos, getProdutos } from "../../../../redux/actions";

function Table({
    loadingProdutos,
    produtos,
    setOpenModal,
    setSelectedProduto,
    curFilter,
    destroyProdutos,
    loadingDestroyProdutos,
    getProdutos
}) {


    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, prd_nm_produto }) => ({ id, prd_nm_produto })))
    }, []);

    const deleteProdutos = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir os registros selecionados?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ prd_nm_produto }) => {
                return (`<li class="list-group-item-action list-group-item">${prd_nm_produto}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!produtos && produtos.current_page !== 1) ? produtos.current_page : 1;
            const fields = { produtos: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyProdutos({ fields, onDelete });
        }
    }

    return (
        <>
            {loadingProdutos ?
                <div className="loader-box">
                    <div className="loader-1"></div>
                </div>
                :
                !!produtos &&
                <DataTable
                    persistTableHead
                    selectableRows
                    pagination
                    paginationServer
                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={toggleCleared}
                    progressComponent={
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    }
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                    }}
                    paginationPerPage={produtos.per_page}
                    paginationTotalRows={produtos.total}
                    paginationDefaultPage={produtos.current_page}
                    onChangePage={page => getProdutos({ fields: { ...curFilter.current, page } })}
                    noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                    contextActions={
                        <Button
                            sid="produtos.destroy"
                            disabled={loadingDestroyProdutos}
                            onClick={deleteProdutos}
                            color="danger">{loadingDestroyProdutos ? 'Processando...' : 'Remover Produto(s)'}
                        </Button>
                    }
                    selectableRowSelected={(row) => selectedRows.some(el => el.id === row.id)}
                    progressPending={loadingDestroyProdutos}
                    columns={[
                        {
                            name: 'Nome',
                            selector: 'prd_nm_produto',
                            center: true
                        },
                        {
                            name: 'Sigla',
                            selector: 'prd_nm_sigla',
                            center: true
                        },
                        {
                            name: 'Situação',
                            selector: 'status',
                            center: true
                        },
                        {
                            name: 'Ações',
                            selector: 'acoes',
                            center: true
                        }
                    ]}
                    data={produtos.data.map(({ id, prd_nm_produto, prd_nm_sigla, prd_st_ativo }, index) => {

                        const status = (prd_st_ativo === "S" ?
                            <i className="fa fa-circle font-success" title="Ativo"></i> :
                            <i className="fa fa-circle font-danger" title="Inativo"></i>)

                        const acoes = (
                            <div style={{ display: "flex", overflow: "auto" }}>
                                <a
                                    style={{ cursor: 'pointer' }}
                                    title="Exibir todas informações"
                                    onClick={() => {
                                        setSelectedProduto(index)
                                        setOpenModal('view')
                                    }}
                                >
                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                </a>
                                <a
                                    sid="produtos.update"
                                    style={{ cursor: 'pointer' }}
                                    title="Editar informações"
                                    onClick={() => {
                                        setSelectedProduto(index)
                                        setOpenModal('edit')
                                    }}
                                >
                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                </a>
                            </div>
                        )
                        return { id, prd_nm_produto, prd_nm_sigla, prd_st_ativo, status, acoes }
                    })}
                />
            }
        </>)
}

const mapStateToProps = ({ Produtos }) => {
    const { produtos, loadingProdutos, loadingDestroyProdutos } = Produtos;
    return { produtos, loadingProdutos, loadingDestroyProdutos }
}

export default connect(mapStateToProps, {
    destroyProdutos,
    getProdutos
})(Table)