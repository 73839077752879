import React from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { getNotasFiscais } from "../../../../redux/actions";
import { format, parseISO } from "date-fns";
import { Col, Row } from "reactstrap";
function Table({
    loadingNotasFiscais,
    notasFiscais,
    getNotasFiscais,
    curFilter,
    setOpenModal,
    setSelectedNF,
    totalValorNfe
}) {
 
    return (
        <>
            {loadingNotasFiscais ?
                <div className="loader-box">
                    <div className="loader-1"></div>
                </div>
                :
                !!notasFiscais &&
                <DataTable
                    persistTableHead
                    subHeader
                    subHeaderComponent={!!curFilter ?
                        <div>
                            <h6 className="txt-primary">
                                Quantidade: {notasFiscais.total}
                            </h6>
                            <h6 className="txt-primary">
                                Valor total: {new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(totalValorNfe)}
                            </h6>

                        </div>
                        : <></>}
                    pagination
                    paginationServer
                    progressComponent={
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    }
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                    }}
                    noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                    onChangePage={(page) => {
                        getNotasFiscais({ fields: { ...curFilter.current, page } })
                    }}
                    paginationPerPage={notasFiscais.per_page}
                    paginationTotalRows={notasFiscais.total}
                    paginationDefaultPage={notasFiscais.current_page}
                    columns={[
                        {
                            name: 'Data de Emissão',
                            selector: 'dataEmi',
                            width: '18%',
                            center: true
                        },
                        {
                            name: 'Código Numérico',
                            selector: 'cNF',
                            center: true
                        },
                        {
                            name: 'Número do Documento',
                            selector: 'nNF',
                            center: true
                        },
                        {
                            name: 'Código do Produto ou Serviço',
                            selector: 'cProd',
                            center: true
                        },
                        {
                            name: 'Valor Total Bruto dos Produtos ou Serviços',
                            selector: 'valorProd',
                            center: true
                        },
                        {
                            name: 'Tipo',
                            selector: 'tipoNfe',
                            center: true
                        },
                        {
                            name: 'Ações',
                            selector: 'acoes',
                            center: true
                        }
                    ]}
                    data={notasFiscais.data.map(({ nu_id, dhEmi, cNF, nNF, nfe_tp_nota, cProd, vProd }, index) => {

                        const dataEmi = !!dhEmi && format(parseISO(dhEmi), 'dd/MM/yyyy HH:mm');

                        const tipoNfe = nfe_tp_nota === 'C' ? 'Cancelada' : nfe_tp_nota === 'S' ? 'Saida' : nfe_tp_nota === 'E' ? 'Entrada' : 'Não Informado';

                        const valorProd = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(vProd || 0);

                        const acoes = (
                            <div style={{ display: "flex", overflow: "auto" }}>
                                <a
                                    style={{ cursor: 'pointer' }}
                                    title="Exibir todas informações"
                                    onClick={() => {
                                        setSelectedNF(index)
                                        setOpenModal('view')
                                    }}
                                >
                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                </a>
                                <a
                                    sid="notas_fiscais.updateDevelucao"
                                    style={{ cursor: nfe_tp_nota !== 'C' ? 'auto' : 'pointer' }}
                                    title="Editar informações"
                                    className={`${nfe_tp_nota !== 'C' ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (nfe_tp_nota === 'C') {
                                            setSelectedNF(index)
                                            setOpenModal('edit')
                                        }
                                    }}
                                >
                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                </a>
                            </div>
                        )
                        return { nu_id, dataEmi, cNF, nNF, tipoNfe, cProd, valorProd, acoes }
                    })}
                />
            }
        </>
    )
}

const mapStateToProps = ({ NotasFiscais }) => {
    const { loadingNotasFiscais, notasFiscais, totalValorNfe } = NotasFiscais;
    return { loadingNotasFiscais, notasFiscais, totalValorNfe }
}

export default connect(mapStateToProps, {
    getNotasFiscais
})(Table)
