import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Button, Col, Form } from "reactstrap";
import DadosGerais from "./DadosGerais";
import Contas from "./Contas";
import { getListContas, storeProdutos } from "../../../../../redux/actions";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../../../constraints/variables";
import { toast } from "react-toastify";

const initalState = {
    prd_nm_produto: "",
    prd_dt_atualiz: "",
    prd_nm_sigla: "",
    prd_cd_ctn_ven_deb: "",
    prd_cd_ctn_ven_cre: "",
    prd_cd_ctn_ven_cmv: "",
    prd_st_ativo: ""
}

function Add({
    isOpen,
    clickClose,
    getListContas,
    loadingStoreProduto,
    storeProdutos,
    produto
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    const [fields, setFields] = useState(initalState);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }


    return (
        <Modal
            size="lg"
            isOpen={isOpen}
        >
            <ModalHeader toggle={() => clickClose()}>
                Informações do Produto
            </ModalHeader>
            {!!produto &&
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <DadosGerais
                                produto={produto}
                            />
                        </Col>
                        <Col xs="12">
                            <Contas
                                produto={produto}
                            />
                        </Col>
                    </Row>
                </ModalBody>
            }
            <ModalFooter>
                <Button type="button" onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ Produtos }) => {
    const { loadingStoreProduto } = Produtos;
    return { loadingStoreProduto }
}

export default connect(mapStateToProps, {
    getListContas,
    storeProdutos
})(Add);