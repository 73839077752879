import React, { useState, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import br from "date-fns/locale/pt-BR";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../constraints/variables";
import { updateFeriados } from "../../../redux/actions";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";

function Edit({
    isOpen,
    clickClose,
    updateFeriados,
    loadingUpdateFeriado,
    selectedFeriado,
    feriados,
    errors
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    const [fields, setFields] = useState(null);

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }


    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }


    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            updateFeriados({ fields, onSave: clickClose, index: selectedFeriado });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }

    }

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
                const dados = { ...feriados.data[selectedFeriado] };
                delete dados.id;
                delete dados.row_num;
                setFields(dados);
            }}
            onClosed={() => {
                validator.visibleFields = [];
                validator.messagesShown = false;
                setFields(null)
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => clickClose()}>
                Editar Feriado
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col xs="12" md="8">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        placeholder="Informe o Nome"
                                        name="frd_nm_feriado"
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        defaultValue={fields.frd_nm_feriado}
                                        onChange={({ target: { name, value } }) => {
                                            setFields({ ...fields, [name]: value });
                                        }}
                                        invalid={isInvalid('frd_nm_feriado')}
                                    />
                                    <FormFeedback>
                                        {validator.message('frd_nm_feriado', fields.frd_nm_feriado, 'required|max:255')}
                                        {(!!errors.frd_nm_feriado) && errors.frd_nm_feriado.map((erro, index) => <span key={index}>{erro}</span>)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4">
                                <FormGroup>
                                    <Label>Data</Label>
                                    <DatePicker
                                        locale={br}
                                        autoComplete="off"
                                        dateFormat="dd/MM/yyyy"
                                        className={`form-control digits ${isInvalid('frd_dt_feriado') ? 'is-invalid' : ""}`}
                                        placeholderText="Informe a Data"
                                        selected={!!fields.frd_dt_feriado && parseISO(fields.frd_dt_feriado)}
                                        onCalendarClose={() => validFields('frd_dt_feriado')}
                                        onChange={(selected) => {
                                            setFields({ ...fields, frd_dt_feriado: !!selected ? format(selected, 'yyyy-MM-dd') : "" });
                                        }}
                                    />
                                    <FormFeedback style={{ display: isInvalid('frd_dt_feriado') ? 'block' : 'none' }}>
                                        {validator.message('frd_dt_feriado', fields.frd_dt_feriado, 'required')}
                                        {(!!errors.frd_dt_feriado) && errors.frd_dt_feriado.map((erro, index) => <span key={index}>{erro}</span>)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={loadingUpdateFeriado} onClick={() => !loadingUpdateFeriado && clickClose()} type="button">
                            Fechar
                        </Button>
                        <Button disabled={loadingUpdateFeriado} color="primary" type="submit">
                            {loadingUpdateFeriado ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Feriados }) => {
    const { loadingUpdateFeriado, feriados, errors } = Feriados;
    return { loadingUpdateFeriado, feriados, errors }
}

export default connect(mapStateToProps, {
    updateFeriados
})(Edit);