import React, { useState, useEffect, Fragment, useRef } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  getListPessoas,
  getRltTitulosNaoRecPagos,
  clearRltTitulosNaoRecPagos,
} from "../../../../redux/actions";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Form,
} from "reactstrap";
import PrintComponent from "../../../../components/PrintComponent";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { connect } from "react-redux";
import Periodo from "./Periodo";
import Pessoas from "./Pessoas";
import Opcoes from "./Opcoes";
import Relatorio from "./Relatorio";

function TitulosNaoPagos({
  getListPessoas,
  getRltTitulosNaoRecPagos,
  loadingRltTitulosNaoRecPagos,
  rltTitulosNaoRecPagos,
  active_user_empresa,
  clearRltTitulosNaoRecPagos,
}) {
  useEffect(() => {
    getListPessoas();

    return () => {
      clearRltTitulosNaoRecPagos();
    };
  }, []);

  const printCompRef = useRef(null);

  const [filter, setFilter] = useState({
    dataInicial: format(new Date(), "yyyy-MM-dd"),
    dataFinal: format(new Date(), "yyyy-MM-dd"),
    pessoas: [],
    tipo: "C",
    faturaBaixada: null,
  });

  const curFilter = useRef();

  const handleChangeSelect = (name, selected) => {
    switch (name) {
      case "dataFinal":
      case "dataInicial":
        if (!!selected) {
          setFilter({ ...filter, [name]: format(selected, "yyyy-MM-dd") });
        } else {
          setFilter({ ...filter, [name]: format(new Date(), "yyyy-MM-dd") });
        }
        break;
      case "pessoas":
        if (!!selected.length) {
          setFilter({ ...filter, [name]: selected.map(({ value }) => value) });
        } else {
          setFilter({ ...filter, [name]: [] });
        }
        break;

      case "faturaBaixada":
        setFilter({ ...filter, [name]: selected[0]?.value || null });
        break;

      default:
        setFilter({ ...filter, [name]: selected[0]?.value || "C" });
        break;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printCompRef.current,
    documentTitle: `Relatório Operacional Titulos ${format(
      new Date(),
      "dd/MM/Y H:mm:ss"
    )}`,
    // onBeforePrint: () => setLoadingPrint(true),
    // onAfterPrint: () => setLoadingPrint(false),
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
  });

  const subitFilter = (e) => {
    e.preventDefault();
    getRltTitulosNaoRecPagos({ fields: filter });
    curFilter.current = filter;
  };

  return (
    <Fragment>
      <Breadcrumb parent="Operacional" title="Titulos" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="shadow">
              <CardBody>
                <CardTitle>
                  <h5>Filtro</h5>
                </CardTitle>
                <Form onSubmit={subitFilter}>
                  <Row>
                    <Col md="5" xs="12">
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Periodo
                              filter={filter}
                              handleChangeSelect={handleChangeSelect}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12">
                          <FormGroup>
                            <Opcoes handleChangeSelect={handleChangeSelect} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" xs="12">
                      <Pessoas handleChangeSelect={handleChangeSelect} />
                    </Col>
                    <Col
                      md="1"
                      sm="12"
                      className="d-flex justify-content-center"
                      style={{ alignItems: "center" }}
                    >
                      <FormGroup>
                        <Button
                          title="Pesquisar"
                          disabled={loadingRltTitulosNaoRecPagos}
                          className="m-t-30"
                          color="primary"
                        >
                          <i className="fa fa-search"></i>
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                {loadingRltTitulosNaoRecPagos ? (
                  <div className="loader-box">
                    <div className="loader-1"></div>
                  </div>
                ) : (
                  <Card className="shadow">
                    <CardBody>
                      {!!rltTitulosNaoRecPagos.length && (
                        <div className="m-b-10 d-flex justify-content-end">
                          <Button
                            size="sm"
                            onClick={handlePrint}
                            className="f-16"
                          >
                            <i className="fa fa-print"></i>
                            <span className="p-l-5">Imprimir</span>
                          </Button>
                        </div>
                      )}
                      <PrintComponent ref={printCompRef}>
                        {!!rltTitulosNaoRecPagos.length && (
                          <div className="d-flex justify-content-start font-arial-rlt">
                            <span className="">
                              {`${
                                !!active_user_empresa
                                  ? active_user_empresa?.emp_nm_empresa
                                  : "SEM EMPRESA"
                              } - 
                                    Titulos Não Recebidos / Titulos Não Pagos (${
                                      curFilter.current.tipo === "C"
                                        ? "Crédito"
                                        : "Débito"
                                    }) ${new Date(
                                curFilter.current.dataInicial
                              ).toLocaleString([], { weekday: "short" })}, 
                                    ${format(
                                      parseISO(curFilter.current.dataInicial),
                                      "dd/MM/yy"
                                    )} a ${new Date(
                                curFilter.current.dataFinal
                              ).toLocaleString([], { weekday: "short" })} ,
                                    ${format(
                                      parseISO(curFilter.current.dataFinal),
                                      "dd/MM/yy"
                                    )}`}
                            </span>
                          </div>
                        )}
                        <Relatorio />
                      </PrintComponent>
                    </CardBody>
                  </Card>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const mapStateToProps = ({ RltOperacionais, Auth }) => {
  const { loadingRltTitulosNaoRecPagos, rltTitulosNaoRecPagos } =
    RltOperacionais;
  const { active_user_empresa } = Auth;
  return {
    loadingRltTitulosNaoRecPagos,
    rltTitulosNaoRecPagos,
    active_user_empresa,
  };
};

export default connect(mapStateToProps, {
  getListPessoas,
  getRltTitulosNaoRecPagos,
  clearRltTitulosNaoRecPagos,
})(TitulosNaoPagos);
