import React from "react";
import { Col, Row, Label, Input } from "reactstrap";

function Opcoes({ handleChange }) {
    return (
        <div className="b-light border-2 p-10">
            <h6>Opções</h6>
            <Row>
                <Col xs="12">
                    <Label className="d-block" for="chk-ani">
                        <Input
                            className="checkbox_animated"
                            name="totalDia"
                            onChange={handleChange}
                            id="chk-ani"
                            type="checkbox"

                        /> Mostrar Pessoas
                    </Label>
                </Col>
            </Row>
        </div>
    )
}

export default Opcoes