import React, { useState, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Form,
} from "reactstrap";
import {
    listGroups,
    storeUsers,
    updateUsers,
    clearErrorsUser,
    getListEmpresas,
    getListContasEmpresa
} from "../../../../redux/actions";
import { testaCPF } from "../../../../helpers";
import SimpleReactValidator from 'simple-react-validator';
import { format } from 'date-fns';
import { toast } from "react-toastify";
import DadosGerais from "./DadosGerais";
import Empresas from "./Empresas";
import NivelAcesso from "./NivelAcesso";
import Lancamentos from "./Lancamentos";
import Contas from "./Contas";

const Edit = ({
    isOpen,
    clickClose,
    listGroups,
    loadingUpdate,
    selectedUser,
    users,
    updateUsers,
    getListEmpresas,
    errors,
    getListContasEmpresa
}) => {
    //STATES
    const [user, setUser] = useState(null);
    const [, updateState] = useState();

    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const fields = { ...user };

            delete fields.empresaConta;

            const id = users.data[selectedUser].id;

            updateUsers({
                fields,
                id,
                onUpdate: clickClose,
                index: selectedUser
            })
        } else {
            validator.showMessages();
            toast.error("Preencha todos os campos corretamente.", toast.POSITION.TOP_RIGHT);
        }
    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'password_confirmation':
            case 'password':
                setUser({ ...user, [name]: value.trim() });
                break;
            case 'changeSenha':
                setUser({ ...user, [name]: !user[name] });
                break;
            default:
                setUser({ ...user, [name]: value });
                break;
        }
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleLancamentos = (name, value) => {
        switch (name) {
            case 'valor_minimo':
            case 'valor_maximo':
                setUser({ ...user, [name]: value })
                break;
            default:
                break;
        }
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'empresas':
                if (!!selected.length) {
                    setUser({
                        ...user,
                        [name]: selected,
                        contas: user.contas.filter((ctn) => selected.some((emp) => emp.emp_cd_empresa === ctn.ctn_cd_empresa)),
                        empresaConta: selected.find((el) => el.emp_cd_empresa === user.empresaConta)?.emp_cd_empresa || ""
                    });
                } else {
                    setUser({ ...user, [name]: [], contas: [], empresaConta: "" });
                }
                break;
            case 'groups':
                setUser({ ...user, [name]: !!selected.length ? selected : [] });
                break;

            case 'empresa_padrao':
                setUser({
                    ...user,
                    empresas: selected,
                });

                break;

            case 'empresaConta':
                if (!!selected.length) {
                    setUser({ ...user, empresaConta: selected[0].emp_cd_empresa })
                    getListContasEmpresa({ fields: { empresaCod: selected[0].emp_cd_empresa } })
                } else {
                    setUser({ ...user, empresaConta: "" })
                }
                break;

            case 'data_nascimento':
                if (!!selected) {
                    setUser({ ...user, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setUser({ ...user, [name]: null });
                }
                break;

            case 'groups':
                setUser({ ...user, [name]: selected })
                break;

            default:
                if (!!selected.length) {
                    setUser({ ...user, [name]: selected[0].value });
                } else {
                    setUser({ ...user, [name]: "" });
                }
                break;
        }
    }

    const onOpenModal = () => {

        const dados = { ...users.data[selectedUser] };

        const empresas = dados.empresas.map(({ emp_nm_empresa, emp_cd_empresa, id, pivot: { principal } }) =>
            ({ name: `${emp_cd_empresa} - ${emp_nm_empresa}`, value: id, id, principal, emp_cd_empresa }));

        const contas = dados.contas.map(({ ctn_cd_conta, ctn_nm_conta, id, ctn_cd_empresa }) => ({
            name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
            value: id,
            id,
            ctn_cd_empresa
        }));


        const empresaConta = empresas.find((emp) => emp.principal === '1').emp_cd_empresa;

        const user = { ...dados, empresas, contas, empresaConta, changeSenha: false, password: "", password_confirmation: "" };


        delete user.id;
        delete user.departamento;
        delete user.row_num;

        setUser(user);
        listGroups();
        getListEmpresas();


        getListContasEmpresa({ fields: { empresaCod: empresaConta } })
    };

    //VALIDATOR    
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        validators: {
            cpf: {
                message: 'CPF inválido.',
                rule: (val) => {
                    return testaCPF(val)
                }
            },
            name: {
                message: 'Nome inválido.',
                rule: (val) => {
                    return val.includes(" ");
                }
            },
            valor_minimo: {
                message: "Valor minímo dever ser menor que máximo",
                rule: (val, params) => {
                    if (!!params[1]) {
                        return parseFloat(params[1]) >= parseFloat(params[0]);
                    }
                }
            },
            valor_maximo: {
                message: "Valor máximo deve ser maior que o minímo.",
                rule: (val, params) => {
                    return parseFloat(params[0]) <= parseFloat(params[1]);
                }
            }

        },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.'
        }
    })).current;

    return (
        <Modal isOpen={isOpen} size="lg"
            onOpened={onOpenModal}
            onClosed={() => {
                setUser(null)
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}>
            <ModalHeader toggle={() => !loadingUpdate && clickClose()}>Editar Usuário</ModalHeader>
            {!!user &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <DadosGerais
                                validator={validator}
                                validFields={validFields}
                                errors={errors}
                                handleChange={handleChange}
                                handleChangeSelect={handleChangeSelect}
                                user={user}
                            />
                            <Empresas
                                validator={validator}
                                validFields={validFields}
                                errors={errors}
                                handleChangeSelect={handleChangeSelect}
                                user={user}
                            />
                            <Contas
                                handleChangeSelect={handleChangeSelect}
                                user={user}
                            />
                            <NivelAcesso
                                validFields={validFields}
                                validator={validator}
                                handleChangeSelect={handleChangeSelect}
                                user={user}
                            />
                            <Lancamentos
                                validFields={validFields}
                                validator={validator}
                                user={user}
                                handleLancamentos={handleLancamentos}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={loadingUpdate} onClick={() => clickClose()} type="button">Fechar</Button>
                        <Button color="primary" disabled={loadingUpdate} type="submit">{loadingUpdate ? 'Processando...' : 'Salvar'}</Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Departamentos, Groups, Users, Empresas }) => {
    const { users, loadingUpdate, errors } = Users;
    const { list: groups, loadingList: loadingGroups } = Groups;
    const { list: listEmpresas, loadingList: loadingListEmpresas } = Empresas;
    const { list: departamentos, loadingList: loadingDepartamentos } = Departamentos;
    return {
        departamentos,
        loadingDepartamentos,
        groups,
        loadingGroups,
        users,
        loadingUpdate,
        errors,
        loadingListEmpresas,
        listEmpresas
    };
}
export default connect(mapStateToProps, {
    listGroups,
    storeUsers,
    updateUsers,
    getListEmpresas,
    clearErrorsUser,
    getListContasEmpresa
})(Edit);