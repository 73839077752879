import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import { parseISO } from "date-fns";
import { Col, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap";
import br from "date-fns/locale/pt-BR";

function DadosGerais({
    fields,
    handleChangeSelect,
    handleChange,
    validator,
    validFields
}) {

    const optionsTipos = [
        { name: 'Devolução', value: 'C' },
        { name: 'Entrada', value: 'E' },
        { name: 'Saida', value: 'S' }
    ];
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Produto*</Label>
                            <Input
                                onChange={handleChange}
                                defaultValue={fields.cmv_prd_produto}
                                name="cmv_prd_produto"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('cmv_prd_produto') ||
                                    (!validator.fields.cmv_prd_produto && validator.messagesShown)}
                                placeholder="Informe o Produto" />
                            <FormFeedback>
                                {validator.message('cmv_prd_produto', fields.cmv_prd_produto, 'required')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="7" xs="12">
                        <Label>Data*</Label>
                        <FormGroup>
                            <DatePicker
                                isClearable
                                selected={!!fields.cmv_dt_data ? parseISO(fields.cmv_dt_data) : null}
                                placeholderText="Informe o Data"
                                dateFormat="dd/MM/yyyy"
                                locale={br}
                                onCalendarClose={() => validFields('cmv_dt_data')}
                                className={`form-control digits
                                    ${validator.visibleFields.includes('cmv_dt_data') ||
                                        // !!errors.lan_dt_competencia ||
                                        (!validator.fields.cmv_dt_data && validator.messagesShown)
                                        ? 'is-invalid' : ''}
                            `}
                                onChange={(selected) => handleChangeSelect('cmv_dt_data', selected)}
                            />
                            <FormFeedback
                                style={{
                                    display: validator.visibleFields.includes('cmv_dt_data') ||
                                        // !!errors.lan_dt_competencia ||
                                        (!validator.fields.cmv_dt_data && validator.messagesShown) ? 'block' : 'none'
                                }}
                            >
                                {validator.message('cmv_dt_data', fields.cmv_dt_data, 'required')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="5" xs="12">
                        <FormGroup>
                            <Label>
                                Tipo*
                            </Label>
                            <Typeahead
                                id="selectTipo"
                                labelKey="name"
                                clearButton
                                onBlur={() => validFields('cmv_tp_tipo')}
                                selected={optionsTipos.filter((opt) => opt.value === fields.cmv_tp_tipo)}
                                placeholder="Informe o Tipo"
                                options={optionsTipos}
                                onChange={(selected) => handleChangeSelect('cmv_tp_tipo', selected)}
                                isInvalid={validator.visibleFields.includes('cmv_tp_tipo') || (!validator.fields.cmv_tp_tipo && validator.messagesShown)}
                            />
                            <FormFeedback
                                style={{
                                    display: validator.visibleFields.includes('cmv_tp_tipo') ||
                                        // !!errors.lan_dt_competencia ||
                                        (!validator.fields.cmv_tp_tipo && validator.messagesShown) ? 'block' : 'none'
                                }}
                            >
                                {validator.message('cmv_tp_tipo', fields.cmv_tp_tipo, 'required')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default DadosGerais;