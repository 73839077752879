import React from 'react';
import { Col, FormGroup, Row, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { connect } from "react-redux";
import { parseISO } from 'date-fns';
function Periodo({
    filter,
    handleChangeSelect,
    active_user_empresa
}) {

    return (
        <div className="b-light border-2 p-10">
            <h6>Período</h6>
            <Row>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Data Inicial*
                        </Label>
                        <DatePicker
                            required
                            locale={br}
                            autoComplete="off"
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            maxDate={!!filter.dataFinal ?
                                parseISO(filter.dataFinal) : !!active_user_empresa.emp_dt_ult_fech ?
                                    parseISO(active_user_empresa.emp_dt_ult_fech) : new Date('2050')}
                            selected={!!filter.dataInicial && parseISO(filter.dataInicial)}
                            className="form-control digits"
                            placeholderText="Informe a data inicial"
                            onChange={(date) => handleChangeSelect('dataInicial', date)}
                        />
                    </FormGroup>
                </Col>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Data Final*
                        </Label>
                        <DatePicker
                            required
                            locale={br}
                            autoComplete="off"
                            minDate={parseISO(filter.dataInicial)}
                            isClearable
                            maxDate={!!active_user_empresa.emp_dt_ult_fech ? parseISO(active_user_empresa.emp_dt_ult_fech) : new Date('2050')}
                            dateFormat="dd/MM/yyyy"
                            selected={!!filter.dataFinal && parseISO(filter.dataFinal)}
                            className="form-control digits"
                            placeholderText="Informe a data final"
                            onChange={(date) => handleChangeSelect('dataFinal', date)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </ div>
    )
}

const mapStateToProps = ({ Auth }) => {
    const { active_user_empresa } = Auth;

    return { active_user_empresa };
}

export default connect(mapStateToProps, {})(Periodo);