import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Form,
} from 'reactstrap';
import { getTiposPessoa } from "../../../redux/actions";
import { Typeahead } from 'react-bootstrap-typeahead';
import Table from "./Table";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";

const TipoPessoa = ({ getTiposPessoa, tiposPessoa,loadingTiposPessoa }) => {

    const [openModal, setOpenModal] = useState(null);

    const [selectedTps, setSelectedTps] = useState(null);

    const [filter, setFilter] = useState({
        tps_nm_tipo: null,
        tps_tp_documento: null,
        tps_ds_tipo: null,
    });

    useEffect(() => {
        getTiposPessoa()
    }, []);

    const curFilter = useRef(null);


    const handleChange = e => {
        const { name, value } = e.target;

        setFilter({ ...filter, [name]: value || null });
    }

    const sumitFilter = e => {
        e.preventDefault();

        getTiposPessoa({ fields: filter });

        curFilter.current = filter;
    }

    return (
        <Fragment>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit
                isOpen={openModal === 'edit'}
                clickClose={() => setOpenModal(null)}
                selectedTps={selectedTps}
            />
            <View
                isOpen={openModal == 'view'}
                clickClose={() => setOpenModal(null)}
                tipoPessoa={!!tiposPessoa && tiposPessoa.data[selectedTps]}
            />
            <Breadcrumb parent="Tabelas" title="Tipo de Pessoa" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                sid="tipos_pessoas.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('add')
                                }}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={sumitFilter}>
                                    <Row>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    name="tps_nm_tipo"
                                                    placeholder="Informe o nome"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Descrição</Label>
                                                <Input
                                                    name="tps_ds_tipo"
                                                    placeholder="Informe a descricao"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Label>Tipo de Documento</Label>
                                                <Typeahead
                                                    id="selectTipoDoc"
                                                    placeholder="Informe o Tipo de Documento"
                                                    labelKey="name"
                                                    options={[
                                                        { name: 'CPF', value: '1' },
                                                        { name: 'CNPJ', value: '0' }
                                                    ]}
                                                    clearButton
                                                    onChange={(selected) => {
                                                        setFilter({ ...filter, tps_tp_documento: selected[0]?.value || null });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" className="d-flex justify-content-center">
                                            <FormGroup>
                                                <Button
                                                    disabled={loadingTiposPessoa}
                                                    title="Pesquisar"
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card className="shadow shadow-showcase">
                            <CardBody className="p-t-20">
                                <Table
                                    curFilter={curFilter}
                                    setSelectedTps={setSelectedTps}
                                    setOpenModal={setOpenModal}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ TiposPessoas }) => {
    const { tiposPessoa,loadingTiposPessoa } = TiposPessoas;
    return { tiposPessoa ,loadingTiposPessoa}
}

export default connect(mapStateToProps, {
    getTiposPessoa
})(TipoPessoa);