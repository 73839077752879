import React from 'react';
import {  Col, Label, Row } from 'reactstrap';
import { connect } from "react-redux";
import { getListPessoas, getFaturasSemBaixa } from "../../../../../redux/actions";
import { useMemo } from 'react';
import ListSelect from "../../../../../components/ListSelect";

function TabFaturas({
    listPessoas,
    loadingListPessoas,
    getListPessoas,
    handleChangeSelect,

}) {

    const optionsPessoas = useMemo(() =>
        listPessoas.data.map(({ pss_nm_pessoa, pss_cd_pessoa }) => ({ name: `${pss_cd_pessoa} - ${pss_nm_pessoa}`, value: pss_cd_pessoa })),
        [listPessoas.data]
    )
    return (
        <>
            <div className="shadow shadow-showcase p-15">
                <Label>
                    <h6>Pessoas</h6>
                </Label>
                    <Row>
                        <Col xs="12">
                            <ListSelect
                                data={optionsPessoas}
                                isLoading={loadingListPessoas}
                                onChange={(items) => handleChangeSelect('pessoas', items)}
                                onPaginate={(rows, searchValue) => {
                                    const offset = rows + 50;
                                    getListPessoas({ fields: { offset, name: searchValue } })
                                }}
                                onSearch={(name) => {
                                    if (name.length > 2 && !optionsPessoas.find((option) => new RegExp(`${name}`, 'i').test(option.name.trimEnd()))) {
                                        getListPessoas({ fields: { name } })
                                    }
                                }}
                                totalResults={listPessoas.total}
                                containerStyle={{ maxHeight: 135 }}
                                pagination
                            />
                        </Col>
                    </Row>
            </div>
        </>
    )
}
const mapStateToProps = ({ Faturas, Pessoas }) => {
    const { ftr_sem_baixa, loadingFtrSemBaixa } = Faturas;
    const { list: listPessoas, loadingList: loadingListPessoas } = Pessoas;
    return { ftr_sem_baixa, loadingFtrSemBaixa, listPessoas, loadingListPessoas }
}

export default connect(mapStateToProps, {
    getListPessoas,
    getFaturasSemBaixa
})(TabFaturas);