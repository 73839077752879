import {
    GET_LANCAMENTOS_ASYNC,
    STORE_LANCAMENTOS_ASYNC,
    GET_LIST_BY_DATE_LANCAMENTOS_ASYNC,
    UPDATE_LANCAMENTOS_ASYNC,
    DESTROY_LANCAMENTOS_ASYNC,
    FECHAR_LANCAMENTOS_ASYNC,
    GET_LANCAMENTOS_FECHADOS_ASYNC,
    REABIR_LANCAMENTOS_ASYNC
} from "../actionTypes";

export const getLancamentos = (payload = {}) => ({
    type: GET_LANCAMENTOS_ASYNC,
    payload
});

export const storeLancamentos = payload => ({
    type: STORE_LANCAMENTOS_ASYNC,
    payload
});

export const getListByDateLancamentos = payload => ({
    type: GET_LIST_BY_DATE_LANCAMENTOS_ASYNC,
    payload
});

export const updateLancamentos = payload => ({
    type: UPDATE_LANCAMENTOS_ASYNC,
    payload
});

export const destroyLancamentos = payload => ({
    type: DESTROY_LANCAMENTOS_ASYNC,
    payload
});

export const fecharLancamentos = payload => ({
    type: FECHAR_LANCAMENTOS_ASYNC,
    payload
});

export const getLancamentosFechados = (payload = {}) => ({
    type: GET_LANCAMENTOS_FECHADOS_ASYNC,
    payload
});

export const reabrirLancamentos = payload => ({
    type: REABIR_LANCAMENTOS_ASYNC,
    payload
})