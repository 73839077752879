import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, Input, Form } from 'reactstrap';
import { connect } from "react-redux";
import { getFeriados } from "../../../redux/actions";
import br from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import Table from "./Table";
import Add from "./Add";
import Edit from "./Edit";

const Feriados = ({ getFeriados, loadingFeriados }) => {

    useEffect(() => {
        getFeriados()
    }, []);

    const [openModal, setOpenModal] = useState(null);
    const [selectedFeriado, setSelectedFeriado] = useState(null);
    const [filter, setFilter] = useState({ frd_nm_feriado: null, frd_dt_feriado: null });

    const curFilter = useRef(null);


    const submitFilter = e => {
        e.preventDefault();
        getFeriados({ fields: filter });
        curFilter.current = filter;
    }

    return (
        <Fragment>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} selectedFeriado={selectedFeriado} />
            <Breadcrumb parent="Tabelas" title="Feriados" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                sid="feriados.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    setOpenModal('add')
                                }}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>
                    </Col>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="7" xs="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    name="frd_nm_feriado"
                                                    onChange={({ target: { name, value } }) => {
                                                        setFilter({ ...filter, [name]: value || null })
                                                    }}
                                                    placeholder="Informe o Nome" />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Data</Label>
                                                <DatePicker
                                                    locale={br}
                                                    autoComplete="off"
                                                    dateFormat="dd/MM/yyyy"
                                                    className={`form-control digits`}
                                                    placeholderText="Informe a Data"
                                                    isClearable
                                                    selected={!!filter.frd_dt_feriado && parseISO(filter.frd_dt_feriado)}
                                                    onChange={(selected) => {
                                                        setFilter({ ...filter, frd_dt_feriado: !!selected ? format(selected, 'yyyy-MM-dd') : null });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" className="d-flex justify-content-center">
                                            <FormGroup>
                                                <Button
                                                    disabled={loadingFeriados}
                                                    title="Pesquisar"
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-t-20">
                                <Table
                                    curFilter={curFilter}
                                    setOpenModal={setOpenModal}
                                    setSelectedFeriado={setSelectedFeriado}
                                    setOpenModal={setOpenModal}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Feriados }) => {
    const { feriados, loadingFeriados } = Feriados;
    return { feriados, loadingFeriados }
}

export default connect(mapStateToProps, {
    getFeriados
})(Feriados);