import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Input,
    Row,
    Label,
} from "reactstrap";

const Lancamentos = ({ user }) => {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Lançamentos</h6></Label>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Minímo</Label>
                                <Input readOnly defaultValue={
                                    !!user.valor_minimo ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(user.valor_minimo)
                                        :
                                        "Não Informado"
                                } />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Máximo</Label>
                                <Input readOnly defaultValue={
                                    !!user.valor_maximo ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(user.valor_maximo)
                                        :
                                        "Não Informado"
                                } />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>

    )
}

const mapStateToProps = ({  }) => {

    return {  };
}
export default connect(mapStateToProps, {})(Lancamentos);