import {
    REGISTER_USER_ASYNC,
    CLEAR_ERRORS_USER,
    GET_USERS_ASYNC,
    CHANGE_STATUS_USER_ASYNC,
    DESTROY_USERS_ASYNC,
    STORE_USERS_ASYNC,
    UPDATE_USERS_ASYNC
} from "../actionTypes";

export const clearErrorsUser = payload => ({
    type: CLEAR_ERRORS_USER,
    payload
});

export const registerUser = payload => ({
    type: REGISTER_USER_ASYNC,
    payload
});

export const getUsers = (payload = {}) => ({
    type: GET_USERS_ASYNC,
    payload
});

export const changeStatusUser = payload => ({
    type: CHANGE_STATUS_USER_ASYNC,
    payload
});

export const destroyUsers = payload => ({
    type: DESTROY_USERS_ASYNC,
    payload
});

export const storeUsers = payload => ({
    type: STORE_USERS_ASYNC,
    payload
});

export const updateUsers = payload => ({
    type: UPDATE_USERS_ASYNC,
    payload
});