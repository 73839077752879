import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormGroup, Label, Row } from 'reactstrap';


function ReceitaDespesa({
    handleChangeSelect
}) {
    const optionsExibir = [
        {
            name: 'Todos',
            value: 'todos'
        },
        {
            name: 'Saldo Diferente de 0',
            value: 'diff'
        }
    ];

    const optionsRegime = [
        {
            name: 'Caixa',
            value: 'C'
        },
        {
            name: 'Mensal',
            value: 'M'
        }
    ];

    return (
        <>
            <div className="b-light border-2 p-10">

                <Row>
                    <Col lg="6" xs="12">
                        <FormGroup>
                            <Label>Regime</Label>
                            <Typeahead
                                id="selectRegime"
                                labelKey="name"
                                placeholder="Selecione"
                                onChange={(selected) => handleChangeSelect('tipoRegime', selected)}
                                defaultSelected={optionsRegime.filter((el) => el.value === 'C')}
                                clearButton
                                options={optionsRegime}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" xs="12">
                        <FormGroup>
                            <Label>Exibir</Label>
                            <Typeahead
                                id="selectExibir"
                                clearButton
                                defaultSelected={optionsExibir.filter((el) => el.value === 'todos')}
                                onChange={(selected) => handleChangeSelect('tipoExibir', selected)}
                                labelKey="name"
                                placeholder="Selecione"
                                options={optionsExibir}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ReceitaDespesa;