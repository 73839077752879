import { GET_LIST_GRUPOS_PAI_ASYNC, GET_LIST_CONTAS_GRUPOS_PAI } from "../actionTypes";

export const getListGrupoPai = (payload = {}) => ({
    type: GET_LIST_GRUPOS_PAI_ASYNC,
    payload
});

export const changeListGruposPai = payload => ({
    type: GET_LIST_CONTAS_GRUPOS_PAI,
    payload
})