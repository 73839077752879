import React, {  } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Form } from "reactstrap";
import { updateTipoPessoa } from "../../../../redux/actions";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";

function View({
    isOpen,
    clickClose,
    tipoPessoa
}) {

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
        >
            <ModalHeader toggle={() => clickClose()}>
                Informações Tipo de Pessoa
            </ModalHeader>
            <ModalBody>
                {!!tipoPessoa &&
                    <Row>
                        <Col xs="12">
                            <FirstCard
                                tipoPessoa={tipoPessoa}
                            />
                        </Col>
                        <Col xs="12">
                            <SecondCard
                                tipoPessoa={tipoPessoa}
                            />
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={() => clickClose()}>
                    Fechar
                        </Button>
            </ModalFooter>
        </Modal>
    )
}


export default View;