import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Form } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../../constraints/variables";
import { updateTipoPessoa } from "../../../../redux/actions";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";

function Edit({
    isOpen,
    clickClose,
    errors,
    updateTipoPessoa,
    loadingUpdateTps,
    selectedTps,
    tiposPessoa
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;


    const [fields, setFields] = useState(null);


    const clearErrors = name => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;

        setFields({ ...fields, [name]: value });

        clearErrors(name)
    }

    const handleChangeSelect = (name, selected) => {
        setFields({ ...fields, [name]: selected[0]?.value || "" });
    }

    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            updateTipoPessoa({ fields, index: selectedTps, onSave: clickClose })
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }
    }

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
                const dados = { ...tiposPessoa.data[selectedTps] };

                delete dados.row_num;
                delete dados.tps_cd_tipo;

                setFields(dados);
            }}
            onClosed={() => {
                setFields(null)
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => !loadingUpdateTps && clickClose()}>
                Editar Tipo de Pessoa
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <FirstCard
                                    handleChange={handleChange}
                                    handleChangeSelect={handleChangeSelect}
                                    validator={validator}
                                    fields={fields}
                                    validFields={validFields}
                                    isInvalid={isInvalid}
                                    errors={errors}
                                />
                            </Col>
                            <Col xs="12">
                                <SecondCard
                                    handleChange={handleChange}
                                    validator={validator}
                                    fields={fields}
                                    isInvalid={isInvalid}
                                    errors={errors}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" disabled={loadingUpdateTps} onClick={() => clickClose()}>
                            Fechar
                        </Button>
                        <Button disabled={loadingUpdateTps} color="primary">
                            {loadingUpdateTps ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ TiposPessoas }) => {
    const { errors, loadingUpdateTps, tiposPessoa } = TiposPessoas;
    return { errors, loadingUpdateTps, tiposPessoa }
}

export default connect(mapStateToProps, {
    updateTipoPessoa
})(Edit)