
//AUTH
export const selectorActiveEmp = state => state.Auth.active_user_empresa;

//USERS
export const selectorUsers = state => state.Users.users;

//PESSOAS
export const selectorListPessoas = state => state.Pessoas.list;
export const selectorPessoas = state => state.Pessoas.pessoas;
export const selectorListPessoasEmp = state => state.Pessoas.listPessoasEmp;

//CONTAS
export const selectorContas = state => state.Contas.contas;
export const selectorListContas = state => state.Contas.list;
export const selectorListContasBancos = state => state.Contas.list_bancos;
export const selectorListContaPessoas = state => state.Contas.listContaPessoas;
export const selectorListContasEmp = state => state.Contas.listContasEmpresa;

//GRUPOS
export const selectorContasGrupos = state => state.ContasGrupos.contasGrupos;
export const selectorContasGruposList = state => state.ContasGrupos.list;

//GRUPOS PAI
export const selectorGruposPaiList = state => state.GruposPai.list;

//LANÇAMENTOS
export const selectorLancamentos = state => state.Lancamentos.lancamentos;

//EMPRESAS
export const selectorEmpresas = state => state.Empresas.empEmpresas;

//TIPOS EMPRESA
export const selectorTiposEmpresa = state => state.TiposEmpresa.tiposEmpresa;

//CUSTO POR PRODUTO
export const selectorCustoProduto = state => state.CmvDiario.custosProdutos;

//TIPOS MOVIMENTAÇÕES PRODUTOS
export const selectorTiposMovsPrd = state => state.TipoMovPrd.tiposMovsPrd;

//PRODUTOS
export const selectorProdutos = state => state.Produtos.produtos;

//NOTAS FISCAIS
export const selectorNFe = state => state.NotasFiscais.notasFiscais;

//FERIADOS
export const selectorFeriados = state => state.Feriados.feriados;

//CENTROS CUSTO
export const selectorCentroCusto = state => state.CentroCusto.centrosCusto;

//TIPOS PESSOAS
export const selectorTiposPessoas = state => state.TiposPessoas.tiposPessoa;