import React, { useEffect, useState, useCallback, useRef } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
    getContasGrupos,
    getListGrupoPai,
    getListEmpresas,
    changeListEmpresas,
    destroyContaGrupos,
    changeListGruposPai
} from "../../../../redux/actions";
import sweetalert2 from "sweetalert2";
import View from "./View";
import Add from "./Add";
import Edit from "./Edit";

const maxResultsSelect = 50;

const PlanoContasGrupos = ({
    getContasGrupos,
    contasGrupos,
    getListGrupoPai,
    loading,
    listGrupoPai,
    loadingListGrupoPai,
    destroyContaGrupos,
    loadingDestroy,
    changeListGruposPai
},
) => {

    useEffect(() => {
        getContasGrupos();
        getListGrupoPai();
    }, []);

    //ESTADOS
    const [filter, setFilter] = useState({ name: null, cd_grupo_pai: null });
    const [selectedGrupo, setSelectedGrupo] = useState(null);
    const [openModal, setOpenModal] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [cacheListGruposPai, setCacheGruposPai] = useState(null);
    const [offsetListGruposPai, setOffSetGruposPai] = useState(maxResultsSelect);

    //REFS 
    const curFilter = useRef(null);

    //FUNÇÕES
    const handleOffSetGruposPai = useCallback(() => {
        setOffSetGruposPai(offsetListGruposPai + maxResultsSelect);
    });
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, grc_nm_grupo }) => ({ id, grc_nm_grupo })))
    }, []);
    const deleteGrupos = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ grc_nm_grupo }) => {
                return (`<li class="list-group-item-action list-group-item">${grc_nm_grupo}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!contasGrupos && contasGrupos.current_page !== 1) ? contasGrupos.current_page : 1;
            const fields = { contasGruposIds: selectedRows, page, curFilter };
            const onDelete = () => setToggleCleared(!toggleCleared);
            destroyContaGrupos({ fields, onDelete });
        }
    }
    const submitFilter = e => {
        e.preventDefault();
        getContasGrupos({ fields: filter })
        curFilter.current = filter;
        setToggleCleared(!toggleCleared)
    }
    return (
        <>
            <View isOpen={openModal === "view"} selectedGrupo={selectedGrupo} clickClose={() => setOpenModal(null)} />
            <Add isOpen={openModal === "add"} clickClose={() => setOpenModal(null)} />
            <Edit
                isOpen={openModal === "edit"}
                selectedGrupo={selectedGrupo}
                clickClose={() => setOpenModal(null)}
                cacheListGruposPai={cacheListGruposPai}
            />
            <Breadcrumb parent="Plano de Contas" title="Grupos" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                        <Button
                            sid="grupos_contas.store"
                            color="primary" size="md"
                            onClick={() => setOpenModal('add')}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                        </Button>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="8" sm="12">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input
                                                    placeholder="Nome do Grupo"
                                                    onChange={({ target: { value } }) => setFilter({ ...filter, name: value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" sm="12">
                                            <FormGroup>
                                                <Label>Grupo Pai</Label>
                                                <AsyncTypeahead
                                                    id="selectGrupoPai"
                                                    labelKey="name"
                                                    searchText="Procurando..."
                                                    minLength={0}
                                                    maxResults={maxResultsSelect - 1}
                                                    clearButton
                                                    paginate
                                                    onSearch={(value) => {
                                                        if (value.length >= 2 &&
                                                            !listGrupoPai.data.find(({ name }) => new RegExp(`${value}`, 'i').test(name))) {
                                                            getListGrupoPai({ fields: { name: value } })
                                                        }
                                                        if (!cacheListGruposPai) {
                                                            setCacheGruposPai(listGrupoPai);
                                                        }
                                                    }}
                                                    paginationText={<span className="d-flex justify-content-center">Carregar Mais</span>}
                                                    isLoading={(loadingListGrupoPai && openModal === null)}
                                                    onInputChange={input => {
                                                        if (!input) {
                                                            changeListGruposPai({ list: cacheListGruposPai });
                                                        }
                                                    }}
                                                    onPaginate={(e, offset) => {
                                                        if ((offset > listGrupoPai.data.length) && (listGrupoPai.data.length < listGrupoPai.total)) {
                                                            getListGrupoPai({ fields: { offset: offsetListGruposPai } });
                                                        }
                                                        handleOffSetGruposPai();
                                                    }}
                                                    placeholder="Selecione o Grupo Pai"
                                                    useCache={false}
                                                    options={
                                                        listGrupoPai.data.map(({ cd_grupo_pai, name }) => {
                                                            return ({ name, cd_grupo_pai })
                                                        })
                                                    }
                                                    onChange={(grupo_pai) => {
                                                        if (!!grupo_pai.length) {
                                                            setFilter({ ...filter, cd_grupo_pai: grupo_pai[0].cd_grupo_pai })
                                                        } else {
                                                            setFilter({ ...filter, cd_grupo_pai: null })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loading}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody className="p-20">
                                {loading ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!contasGrupos &&
                                    <DataTable
                                        persistTableHead
                                        selectableRows
                                        pagination
                                        paginationServer
                                        clearSelectedRows={toggleCleared}
                                        onSelectedRowsChange={handleSelectedRows}
                                        columns={[
                                            {
                                                name: "Código",
                                                selector: 'grc_cd_grupo',
                                                center: true,
                                            },
                                            {
                                                name: "Nome",
                                                selector: 'grc_nm_grupo',
                                                center: true,
                                            },

                                            {
                                                name: "Grupo Pai",
                                                selector: "grupo_pai_name",

                                                center: true
                                            },
                                            {
                                                name: "Ações",
                                                selector: 'acoes',
                                                center: true,
                                            },
                                        ]}
                                        data={contasGrupos.data.map(({ id, grc_cd_grupo, grc_nm_grupo, grupo_pai }, index) => {
                                            const grupo_pai_name = !!grupo_pai && !!grupo_pai.name ? grupo_pai.name : 'Não Informado';
                                            const acoes = (
                                                <div style={{ display: "flex", overflow: "auto" }}>
                                                    <a
                                                        sid="grupos_contas.index"
                                                        style={{ cursor: 'pointer' }}
                                                        title="Informações"
                                                        onClick={() => {
                                                            setSelectedGrupo(index)
                                                            setOpenModal('view')
                                                        }}
                                                    >
                                                        <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                    </a>
                                                    <a
                                                        sid="grupos_contas.update"
                                                        style={{ cursor: 'pointer' }}
                                                        title="Editar"
                                                        onClick={() => {
                                                            setSelectedGrupo(index)
                                                            setOpenModal('edit')
                                                        }}
                                                    >
                                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                    </a>

                                                </div>
                                            )
                                            return ({ id, grc_cd_grupo, grc_nm_grupo, grupo_pai_name, acoes })
                                        })}
                                        contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                        progressComponent={
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        }
                                        contextActions={
                                            <Button
                                                sid="grupos_contas.destroy"
                                                color="danger"
                                                disabled={loadingDestroy}
                                                onClick={deleteGrupos}
                                            >
                                                {loadingDestroy ? 'Processando...' : ' Remover Grupo(s)'}
                                            </Button>}
                                        progressPending={loading || loadingDestroy}
                                        paginationPerPage={contasGrupos.per_page}
                                        paginationTotalRows={contasGrupos.total}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                            rangeSeparatorText: 'de',
                                        }}
                                        paginationDefaultPage={contasGrupos.current_page}
                                        onChangePage={(page) => getContasGrupos({ fields: { ...curFilter, page } })}
                                        noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = ({ ContasGrupos, GruposPai, Empresas }) => {
    const { contasGrupos, loading, loadingDestroy } = ContasGrupos;
    const { list: listGrupoPai, loadingList: loadingListGrupoPai } = GruposPai;
    const { list: listEmpresas, loadingList: loadingListEmpresas } = Empresas;
    return { contasGrupos, loading, listGrupoPai, loadingListGrupoPai, listEmpresas, loadingListEmpresas, loadingDestroy }
}
export default connect(mapStateToProps, {
    getContasGrupos,
    getListGrupoPai,
    changeListEmpresas,
    destroyContaGrupos,
    changeListGruposPai
})(PlanoContasGrupos);