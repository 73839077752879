import React from "react";
import { ModalBody, Modal, ModalHeader, ModalFooter, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";

const View = ({ isOpen, clickClose, contas, selectedConta }) => {
    const conta = !!contas && contas.data[selectedConta];
    return (
        <Modal isOpen={isOpen} size="lg">
            <ModalHeader toggle={() => clickClose()}>Informações da Conta</ModalHeader>
            <ModalBody>
                {!!conta &&
                    <Row>
                        <Col xs="12">
                            <FirstCard
                                conta={conta}
                            />
                        </Col>
                        <Col xs="12">
                            <SecondCard
                                conta={conta}
                            />
                        </Col>
                        <Col xs="12">
                            <ThirdCard
                                conta={conta}
                            />
                        </Col>
                        <Col xs="12">
                            <FourthCard
                                conta={conta}
                            />
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = ({ Contas }) => {
    const { contas } = Contas
    return { contas }
}

export default connect(mapStateToProps, {})(View);