import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Input, Label, Row, } from "reactstrap";

const DadosGerais = ({ contrapartida }) => {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label><h6>Dados Gerais</h6></Label> */}
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Número do Documento</Label>
                                <Input
                                    defaultValue={contrapartida.cnt_nu_doc}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Centro de Custo</Label>
                                <Input
                                    defaultValue={`${contrapartida.centro_custo.cct_cd_centro} - ${contrapartida.centro_custo.cct_nm_centro}`}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Valor Contrapartida</Label>
                                <Input
                                    defaultValue={!!contrapartida?.cnt_vl_contrapartida ?
                                        new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(contrapartida?.cnt_vl_contrapartida)
                                        :
                                        'Não Informado'
                                    }
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {

    return {}
}
export default connect(mapStateToProps, {
})(DadosGerais)