import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import SelectPagination from "../../../../components/SelectPagination";
import { getListContas, getListContaPessoas } from "../../../../redux/actions";

const Conta = ({
    listContas,
    loadingListContas,
    listContaPessoas,
    loadingListCtnPessoa,
    getListContas,
    getListContaPessoas,
    validFields,
    handleChangeSelect,
    handleChange,
    validator,
    fields,
    errors

}) => {
    const optionsContas = useMemo(() => {
        const fillContas = listContas.data.filter(({ ctn_st_lanc_manual }) => ctn_st_lanc_manual === "S")
        return fillContas.map(({
            id,
            ctn_cd_conta,
            ctn_nm_conta,
            ctn_vl_minimo_lan,
            ctn_vl_maximo_lan,
            ctn_ds_hist_padrao,
            pessoa,
            ctn_st_lan_deb_cre
        }) =>
        ({
            name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
            value: id,
            ctn_cd_conta,
            ctn_vl_minimo_lan,
            ctn_vl_maximo_lan,
            ctn_ds_hist_padrao,
            def_pessoa: pessoa,
            ctn_st_lan_deb_cre,
        }))
    }
        ,
        [listContas.data]);
    const optionsPessoas = useMemo(() => {
        const res = [];
        if (!!fields.pessoa.value && !listContaPessoas.data.find((pessoa) => pessoa.pss_cd_pessoa === fields.lan_cd_subconta)) {
            res.push({
                value: fields.pessoa.value,
                name: `${fields.pessoa.pss_cd_pessoa} - ${fields.pessoa.pss_nm_pessoa}`,
                pss_cd_pessoa: fields.pessoa.pss_cd_pessoa,
                pss_nm_pessoa: fields.pessoa.pss_nm_pessoa,
                pss_nu_identificacao: fields.pessoa.pss_nu_identificacao,
                subItem: `Num.Identificação: ${fields.pessoa.pss_nu_identificacao}`,
            })

        }
        res.push(...listContaPessoas.data.map(({ id, pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao }) => ({
            value: id,
            pss_cd_pessoa,
            name: `${pss_cd_pessoa} - ${pss_nm_pessoa} - ${pss_nu_identificacao}`,
            pss_nm_pessoa,
            pss_nu_identificacao,
            subItem: `Num.Identificação: ${pss_nu_identificacao}`,
        })))

        return res;
    }, [listContaPessoas.data])

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Conta*</Label>
                                <SelectPagination
                                    id="selectConta"
                                    clearButton
                                    labelKey={(option) => `${option.name}`}
                                    placeholder="Informe a Conta"
                                    onBlur={() => validFields('lan_cd_conta')}
                                    maxResults={50}
                                    isLoading={loadingListContas}
                                    totalResults={listContas.total}
                                    data={optionsContas}
                                    reduxAction={getListContas}
                                    onChange={(selected) => handleChangeSelect('lan_cd_conta', selected)}
                                    isInvalid={
                                        validator.visibleFields.includes('lan_cd_conta') ||
                                        !!errors.lan_cd_conta ||
                                        (!validator.fields.lan_cd_conta && validator.messagesShown)
                                    }
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('lan_cd_conta') ||
                                            !!errors.lan_cd_conta ||
                                            (!validator.fields.lan_cd_conta && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('lan_cd_conta', fields.lan_cd_conta, 'required')}
                                    {(!!errors.lan_cd_conta) && errors.lan_cd_conta.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        {!!Object.keys(fields.conta).length &&
                            <>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label>Histórico*</Label>
                                        <Input
                                            placeholder="Informe a Descrição Histórico"
                                            name="lan_ds_historico"
                                            defaultValue={fields.conta.desc_historico}
                                            onBlur={() => validFields('lan_ds_historico')}
                                            onChange={handleChange}
                                            invalid={
                                                validator.visibleFields.includes('lan_ds_historico') ||
                                                (!validator.fields.lan_ds_historico && validator.messagesShown)
                                            }
                                        />
                                        <FormFeedback
                                            style={{
                                                display: validator.visibleFields.includes('lan_ds_historico') ||
                                                    (!validator.fields.cnt_ds_historico && validator.messagesShown)
                                                    ? 'block' : 'none'
                                            }}>
                                            {validator.message('lan_ds_historico', fields.lan_ds_historico, 'required')}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label>Pessoa*</Label>
                                        <SelectPagination
                                            id="selectPessoa"
                                            clearButton
                                            labelKey={(option) => `${option.name}`}
                                            placeholder="Informe a Pessoa"
                                            onBlur={() => validFields('lan_cd_subconta')}
                                            maxResults={50}
                                            isLoading={loadingListCtnPessoa}
                                            totalResults={listContaPessoas.total}
                                            useCache={false}
                                            selected={(options) => options.filter((option) => option.pss_cd_pessoa === fields.lan_cd_subconta)}
                                            data={optionsPessoas}
                                            reduxAction={(obj) => {
                                                if (!!obj) {
                                                    getListContaPessoas({ ...obj, conta: fields.lan_cd_conta })
                                                } else {
                                                    getListContaPessoas({ conta: fields.lan_cd_conta })
                                                }
                                            }}
                                            onChange={(selected) => handleChangeSelect('lan_cd_subconta', selected)}
                                            isInvalid={
                                                validator.visibleFields.includes('lan_cd_subconta') ||
                                                !!errors.lan_cd_subconta ||
                                                (!validator.fields.lan_cd_subconta && validator.messagesShown)
                                            }
                                        />
                                        <FormFeedback
                                            style={{
                                                display: validator.visibleFields.includes('lan_cd_subconta') ||
                                                    !!errors.lan_cd_subconta ||
                                                    (!validator.fields.lan_cd_subconta && validator.messagesShown)
                                                    ? 'block' : 'none'
                                            }}>
                                            {validator.message('lan_cd_subconta', fields.lan_cd_subconta, 'required')}
                                            {(!!errors.lan_cd_subconta) && errors.lan_cd_subconta.map((erro, index) => <span key={index}>{erro}</span>)}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" className="m-b-10">
                                    <Label>Tipo de Lançamento*</Label>
                                    <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                                        {fields.conta.ctn_st_lan_deb_cre === "A" ?
                                            <>
                                                <div className="radio radio-primary m-r-50">
                                                    <Input
                                                        id="radioinline1"
                                                        name="lan_tp_lancamento"
                                                        value="D"
                                                        type="radio"
                                                        onChange={handleChange}
                                                    />
                                                    <Label className="mb-0" for="radioinline1">Débito</Label>
                                                </div>
                                                <div className="radio radio-primary m-r-50">
                                                    <Input
                                                        id="radioinline2"
                                                        name="lan_tp_lancamento"
                                                        value="C"
                                                        type="radio"
                                                        onChange={handleChange}
                                                    />
                                                    <Label className="mb-0" for="radioinline2">Crédito</Label>
                                                </div>
                                            </>
                                            :
                                            fields.conta.ctn_st_lan_deb_cre === "D" ?
                                                <div className="radio radio-primary m-r-50">
                                                    <Input
                                                        id="radioinline1"
                                                        name="lan_tp_lancamento"
                                                        value="D"
                                                        type="radio"
                                                        onChange={handleChange}
                                                    />
                                                    <Label className="mb-0" for="radioinline1">Débito</Label>
                                                </div>
                                                :
                                                fields.conta.ctn_st_lan_deb_cre === "C" ?
                                                    <div className="radio radio-primary m-r-50">
                                                        <Input
                                                            id="radioinline2"
                                                            name="lan_tp_lancamento"
                                                            value="C"
                                                            type="radio"
                                                            onChange={handleChange}
                                                        />
                                                        <Label className="mb-0" for="radioinline2">Crédito</Label>
                                                    </div>
                                                    :
                                                    <Alert color="light">Não Informado</Alert>
                                        }
                                        <FormFeedback
                                            style={{
                                                display: validator.visibleFields.includes('lan_tp_lancamento') ||
                                                    !!errors.lan_tp_lancamento ||
                                                    (!validator.fields.lan_tp_lancamento && validator.messagesShown)
                                                    ? 'block' : 'none'
                                            }}>
                                            {validator.message('lan_tp_lancamento', fields.lan_tp_lancamento, 'required')}
                                            {(!!errors.lan_tp_lancamento) && errors.lan_tp_lancamento.map((erro, index) => <span key={index}>{erro}</span>)}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </>
                        }
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, loadingList: loadingListContas, listContaPessoas, loadingListCtnPessoa } = Contas;
    return { listContas, loadingListContas, listContaPessoas, loadingListCtnPessoa }
}

export default connect(mapStateToProps, {
    getListContas,
    getListContaPessoas
})(Conta);