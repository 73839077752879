import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Row,
    Label,
    ButtonGroup,
    FormFeedback,
} from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import InputMask from "react-input-mask";
import br from "date-fns/locale/pt-BR";
import { parseISO } from 'date-fns';
import DatePicker from "react-datepicker";

const DadosGerais = ({
    handleChangeSelect,
    handleChange,
    validFields,
    validator,
    fields,
    departamentos,
    errors,
    loadingDepartamentos
}) => {
    const optionsDepartamentos = useMemo(() =>
        departamentos.map(({ id, dep_nm_departamento }) => ({ name: dep_nm_departamento, value: id })),
        [departamentos]);
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Dados Gerais</h6></Label>
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Nome Completo*</Label>
                                <Input
                                    placeholder="Informe o Nome Completo"
                                    onBlur={({ target: { name } }) => { validFields(name) }}
                                    invalid={validator.visibleFields.includes('name') ||
                                        (!validator.fields.name && validator.messagesShown)
                                    }
                                    name="name"
                                    onChange={handleChange} />
                                <FormFeedback>
                                    {validator.message('name', fields.name, `required|name:${fields.name}`)}
                                </FormFeedback>
                            </FormGroup>

                        </Col>
                        <Col md="4" xs="12">
                            <Label className="d-block">Status*</Label>
                            <ButtonGroup>
                                <Button
                                    outline
                                    onClick={handleChange}
                                    color="success"
                                    value="1"
                                    name="active"
                                    active={fields.active === "1"}
                                >
                                    Ativo
                            </Button>
                                <Button
                                    outline
                                    onClick={handleChange}
                                    color="danger"
                                    value="0"
                                    name="active"
                                    active={fields.active === "0"}
                                >
                                    Inativo
                            </Button>
                            </ButtonGroup>
                            <FormFeedback
                                style={{
                                    display: validator.visibleFields.includes('active') ||
                                        (!validator.fields.active && validator.messagesShown)
                                        ? 'block' : 'none'
                                }}
                            >
                                {validator.message('active', fields.active, `required`)}
                            </FormFeedback>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Email*</Label>
                                <Input
                                    type="email"
                                    placeholder="Informe o Email"
                                    name="email"
                                    onBlur={({ target: { name } }) => { validFields(name) }}
                                    invalid={validator.visibleFields.includes('email') ||
                                        !!errors.email ||
                                        (!validator.fields.email && validator.messagesShown)
                                    }
                                    onChange={handleChange} />
                                <FormFeedback>
                                    {(!!errors.email) && errors.email.map((erro, index) => <span key={index}>{erro}</span>)}
                                    {validator.message('email', fields.email, `required|email`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Departamento*</Label>
                                <Typeahead
                                    id="selectDepartamento"
                                    labelKey="name"
                                    clearButton
                                    emptyLabel={"Nenhum Departamento Encontrado"}
                                    placeholder="Selecione o Departamento"
                                    onBlur={() => { validFields('departamento_id') }}
                                    isInvalid={
                                        validator.visibleFields.includes('departamento_id') ||
                                        (!validator.fields.departamento_id && validator.messagesShown)
                                    }
                                    isLoading={loadingDepartamentos}
                                    disabled={loadingDepartamentos}
                                    options={optionsDepartamentos}
                                    onChange={(selected) => handleChangeSelect('departamento_id', selected)}
                                />
                                <FormFeedback style={{
                                    display:
                                        validator.visibleFields.includes('departamento_id') ||
                                            (!validator.fields.departamento_id && validator.messagesShown)
                                            ? 'block' : 'none'
                                }}>
                                    {validator.message('departamento_id', fields.departamento_id, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>CPF*</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Informe o CPF"
                                    maskChar={""}
                                    onBlur={({ target: { name } }) => { validFields(name) }}
                                    invalid={
                                        validator.visibleFields.includes('cpf') ||
                                        !!errors.cpf ||
                                        (!validator.fields.cpf && validator.messagesShown)
                                    }
                                    tag={InputMask}
                                    mask={'999.999.999-99'}
                                    name="cpf"
                                    onChange={handleChange} />
                                <FormFeedback>
                                    {(!!errors.cpf) && errors.cpf.map((erro, index) => <span key={index}>{erro}</span>)}
                                    {validator.message('cpf', fields.cpf, `required|cpf:${fields.cpf}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Data de Nascimento*</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Informe a Data de Nascimento"
                                    name="data_nascimento"
                                    minDate={new Date(1930, 0, 1)}
                                    maxDate={new Date()}
                                    selected={!!fields.data_nascimento ? parseISO(fields.data_nascimento) : null}
                                    onChange={(date) => handleChangeSelect('data_nascimento', date)}
                                    onClickOutside={() => validFields('data_nascimento')}
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control digits ${validator.visibleFields.includes('data_nascimento') ||
                                        (!validator.fields.data_nascimento && validator.messagesShown)
                                        ? 'is-invalid' : ''}`}
                                />
                                <FormFeedback style={{
                                    display:
                                        validator.visibleFields.includes('data_nascimento') ||
                                            (!validator.fields.data_nascimento && validator.messagesShown)
                                            ? 'block' : 'none'
                                }}>
                                    {validator.message('data_nascimento', fields.data_nascimento, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Genêro*</Label>
                                <Typeahead
                                    id="selectGenero"
                                    labelKey="name"
                                    name="gender"
                                    onBlur={() => { validFields('gender') }}
                                    isInvalid={validator.visibleFields.includes('gender') ||
                                        (!validator.fields.gender && validator.messagesShown)
                                    }
                                    placeholder="Informe o Genêro"
                                    options={[{ name: 'Masculino', value: 'M' }, { name: 'Feminino', value: 'F' }]}
                                    onChange={(selected) => handleChangeSelect('gender', selected)}
                                />
                                <FormFeedback style={{
                                    display: validator.visibleFields.includes('gender') ||
                                        (!validator.fields.gender && validator.messagesShown)
                                        ? 'block' : 'none'
                                }}>
                                    {validator.message('gender', fields.gender, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Senha*</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="Informe a Senha"
                                    onBlur={({ target: { name } }) => { validFields(name) }}
                                    invalid={
                                        validator.visibleFields.includes('password') ||
                                        (!validator.fields.password && validator.messagesShown)
                                    }
                                    onChange={handleChange} />
                                <FormFeedback>
                                    {validator.message('password', fields.password, `required|min:6`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Confirmar Senha*</Label>
                                <Input
                                    type="password"
                                    name="password_confirmation"
                                    onChange={handleChange}
                                    invalid={validator.visibleFields.includes('password_confirmation') ||
                                        (!validator.fields.password_confirmation && validator.messagesShown)
                                    }
                                    onBlur={({ target: { name } }) => { validFields(name) }}
                                    placeholder="Confirme a Senha" />
                                <FormFeedback>
                                    {validator.message(
                                        'password_confirmation',
                                        fields.password_confirmation,
                                        `required|min:6|in:${fields.password}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Departamentos }) => {
    const { list: departamentos, loadingList: loadingDepartamentos } = Departamentos;
    return { departamentos, loadingDepartamentos };
}
export default connect(mapStateToProps, {
})(DadosGerais);