import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from "react-redux";
import { getListContas, getListCentroCusto } from "../../../../redux/actions";
import { useMemo } from 'react';
import ListSelect from "../../../../components/ListSelect";

function CentroCusto({
    handleChangeSelect,
    getListCentroCusto,
    listCentroCusto,
    loadingListCentroCusto
}) {

    const optionsCentroCusto = useMemo(() =>
        listCentroCusto.data.map(({ cct_cd_centro, cct_nm_centro }) => ({ name: `${cct_cd_centro} - ${cct_nm_centro}`, value: cct_cd_centro })),
        [listCentroCusto.data])
    return (
        <>
            <div className="b-light border-2 p-10">
                <h6>Centro de Custo</h6>
                <Row>
                    <Col xs="12">
                        <ListSelect
                            data={optionsCentroCusto}
                            isLoading={loadingListCentroCusto}
                            onChange={(items) => handleChangeSelect('centroCusto', items)}
                            onPaginate={(rows, searchValue) => {
                                const offset = rows + 50;
                                getListCentroCusto({ fields: { offset, name: searchValue } })
                            }}
                            onSearch={(name,options) => {
                                if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name))) {
                                    getListCentroCusto({ fields: { name } })
                                }
                            }}
                            totalResults={listCentroCusto.total}
                            containerStyle={{ maxHeight: 135 }}
                            pagination
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}
const mapStateToProps = ({ CentroCusto }) => {
    const { list: listCentroCusto, loadingList: loadingListCentroCusto } = CentroCusto;
    return { listCentroCusto, loadingListCentroCusto }
}

export default connect(mapStateToProps, {
    getListContas,
    getListCentroCusto
})(CentroCusto);