import React from 'react';
import { Col, FormGroup, Row, Label, Input } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
function Opcoes({ handleChange, handleChangeSelect }) {
    const optionsModelo = [
        { name: 'Conta', value: 'conta' },
        { name: 'Centro de Custo', value: 'centroCusto' },
        { name: 'Pessoa', value: 'pessoa' }
    ];
    return (
        <div className="b-light border-2 p-10">
            <h6>Opções</h6>
            <Row>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Modelo
                         </Label>
                        <Typeahead
                            id="selectModelo"
                            labelKey="name"
                            defaultSelected={optionsModelo.filter((option) => option.value === 'conta')}
                            options={optionsModelo}
                            clearButton
                            onChange={(selected) => handleChangeSelect('modelo', selected)}
                        />
                    </FormGroup>
                </Col>
                <Col lg="6" className="m-t-15" sm="12">
                    <Label className="d-block" for="chk-ani">
                        <Input
                            className="checkbox_animated"
                            name="totalDia"
                            onChange={handleChange}
                            id="chk-ani"
                            type="checkbox"
                            defaultChecked
                        /> Exibir Totais por Dia
                    </Label>
                    <Label className="d-block" for="chk-ani2">
                        <Input
                            className="checkbox_animated"
                            id="chk-ani2"
                            type="checkbox"
                            disabled
                        /> Exibir apenas seleção
                    </Label>
                </Col>
            </Row>
        </ div>
    )
}

export default Opcoes;