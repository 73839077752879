import { IMPORT_XMLS_ASYNC, LOGIN_EDOCS_ASYNC } from "../actionTypes";

export const importarXmls = payload => ({
    type: IMPORT_XMLS_ASYNC,
    payload
});

export const loginEdocs = payload => ({
    type: LOGIN_EDOCS_ASYNC,
    payload
});