
import React, { useState } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Table, Row, Col, Alert, Label, Input } from 'reactstrap';
import ScrollArea from "react-scrollbar";
import { format, parseISO } from 'date-fns';

function Conciliados({
    listConciliados,
    selConciliados,
    setSelConciliados,
    loadingConciliarFtr
}) {
    const totalConc = listConciliados.reduce((acu, { ftr_vl_fatura }) => acu + parseFloat(ftr_vl_fatura), 0);
    const totalMarcados = selConciliados.reduce((acu, { ftr_vl_fatura }) => acu + parseFloat(ftr_vl_fatura), 0);
    return (
        <Card className="card-absolute">
            <CardHeader className="bg-primary">
                <h5>Conciliados</h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs="12">
                        {
                            loadingConciliarFtr ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                <ScrollArea horizontal={false}>
                                    <Table responsive style={{ textAlign: 'center' }}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Vencimento</th>
                                                <th>Documento</th>
                                                <th>Valor</th>
                                                <th>Data no extrato</th>
                                                <th>Doc.Extrato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!listConciliados.length ?
                                                listConciliados.map((concilado, indexConc) => {
                                                    return (
                                                        <tr key={indexConc}>
                                                            <td>
                                                                <Label>
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        checked={!!selConciliados.find((selExtrato) => selExtrato.ftr_id_fatura === concilado.ftr_id_fatura)}
                                                                        onChange={() => {
                                                                            const curSelExtrato = [...selConciliados];
                                                                            const indexSel = curSelExtrato.findIndex((selExtrato) => selExtrato.ftr_id_fatura === concilado.ftr_id_fatura);
                                                                            if (indexSel === -1) {
                                                                                curSelExtrato.push({ ...concilado })
                                                                            } else {
                                                                                curSelExtrato.splice(indexSel, 1);
                                                                            }

                                                                            setSelConciliados(curSelExtrato);
                                                                        }}
                                                                        id={`chk-ani${indexConc}`}
                                                                        type="checkbox" />
                                                                </Label>
                                                            </td>
                                                            <td>{format(parseISO(concilado.ftr_dt_vencimento), 'dd/MM/yyyy')}</td>
                                                            <td>{concilado.ftr_nu_fatura}</td>
                                                            <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(concilado.ftr_vl_fatura)}</td>
                                                            <td>{!!concilado.extrato?.dia && format(parseISO(concilado.extrato.dia), 'dd/MM/yyyy')}</td>
                                                            <td>{concilado.extrato.documento}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan="6"><Alert color="light">Nenhuma conciliação </Alert></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </ScrollArea>
                        }
                        <div className="p-10 b-dark m-t-15 d-flex justify-content-between">
                            <span>Marcados: {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalMarcados)}</span>
                            <span>Total {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalConc)}</span>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = ({ Arquivos, Faturas }) => {
    const { extratoBanco } = Arquivos;
    const { loadingConciliarFtr } = Faturas;
    return { extratoBanco, loadingConciliarFtr };
}

export default connect(mapStateToProps, {

})(Conciliados);