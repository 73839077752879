import React, { useState, useRef, useCallback, useMemo } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, ModalFooter, Button, Form, FormFeedback } from "reactstrap";
import { connect } from "react-redux";
import {
    getListGrupoPai,
    updateContasGrupos,
    changeListGruposPai,
    clearErrorsContasGrupos
} from "../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import SelectPagination from "../../../../components/SelectPagination";
const Edit = ({
    clickClose,
    isOpen,
    contasGrupos,
    selectedGrupo,
    listGrupoPai,
    loadingListGrupoPai,
    getListGrupoPai,
    updateContasGrupos,
    loadingUpdate,
    errors,
}) => {
    //ESTADOS
    const [grupo, setGrupo] = useState(null);
    const [, updateState] = useState();

    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const submitForm = e => {
        e.preventDefault();
        const fields = { ...grupo };
        delete fields.empresa;
        delete fields.grupo_pai;
        if (validator.allValid()) {
            updateContasGrupos({ fields, onUpdate: clickClose });
        } else {
            toast.error("Preencha todos os campos corretamente.", toast.POSITION.TOP_RIGHT)
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    const onOpenModal = () => {
        const dados = { ...contasGrupos.data[selectedGrupo] };
        delete dados.row_num;

        setGrupo(dados);
    }

    //VARIÁVEIS
    const optionsGruposPai = useMemo(() => listGrupoPai.data.map(({ cd_grupo_pai, name }) => {
        return ({ name, value: cd_grupo_pai })
    }), [listGrupoPai.data]);
    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        }
    })).current;

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onOpened={onOpenModal}
            onClosed={() => {
                setGrupo(null)
                validator.visibleFields = [];
            }}
        >
            <ModalHeader
                toggle={() => !loadingUpdate && clickClose()}
            >
                Editar Grupo
            </ModalHeader>
            {!!grupo &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col md="9" xs="12">
                                <FormGroup>
                                    <Label>Nome*</Label>
                                    <Input
                                        placeholder="Informe o nome do grupo"
                                        maxLength={60}
                                        defaultValue={grupo.grc_nm_grupo.trimEnd()}
                                        name="grc_nm_grupo"
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('grc_nm_grupo')}
                                        onChange={({ target: { name, value } }) => setGrupo({ ...grupo, [name]: value })}
                                    />
                                    <FormFeedback>
                                        {validator.message('grc_nm_grupo', grupo.grc_nm_grupo, `required`)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="12">
                                <FormGroup>
                                    <Label>Código*</Label>
                                    <Input
                                        maxLength={2}
                                        placeholder="Informe o código"
                                        value={grupo.grc_cd_grupo.trimEnd()}
                                        name="grc_cd_grupo"
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('grc_cd_grupo') || !!errors.grc_cd_grupo}
                                        onChange={({ target: { name, value } }) => setGrupo({ ...grupo, [name]: value })}
                                    />
                                    <FormFeedback>
                                        {validator.message('grc_cd_grupo', grupo.grc_cd_grupo, `required|max:2`)}
                                        {(!!errors.grc_cd_grupo) && errors.grc_cd_grupo.map((erro, index) => <span key={index}>{erro}</span>)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Grupo Pai*</Label>
                                    <SelectPagination
                                        id="selectGrupoPai"
                                        labelKey="name"
                                        searchText="Procurando..."
                                        isInvalid={validator.visibleFields.includes('grc_cd_grupo_pai')}
                                        onBlur={() => { validFields('grc_cd_grupo_pai') }}
                                        maxResults={50}
                                        placeholder="Selecione o Grupo Pai"
                                        reduxAction={getListGrupoPai}
                                        clearButton
                                        data={optionsGruposPai}
                                        isLoading={loadingListGrupoPai}
                                        onChange={(grupos) => {
                                            if (!!grupos.length) {
                                                setGrupo({ ...grupo, grc_cd_grupo_pai: grupos[0].value });
                                            } else {
                                                setGrupo({ ...grupo, grc_cd_grupo_pai: "" });
                                            }
                                        }}
                                        selected={(options) => options.filter((option) => option.value === grupo.grc_cd_grupo_pai)}
                                        totalResults={listGrupoPai.total}

                                    />
                                    <FormFeedback style={{ display: validator.visibleFields.includes('grc_cd_grupo_pai') ? 'block' : 'none' }}>
                                        {validator.message('grc_cd_grupo_pai', grupo.grc_cd_grupo_pai, `required`)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => clickClose()} disabled={loadingUpdate} type="button">Fechar</Button>
                        <Button type="submit" disabled={loadingUpdate} color="primary">{loadingUpdate ? 'Processando...' : 'Salvar'}</Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ GruposPai, ContasGrupos }) => {
    const { loadingUpdate, contasGrupos, errors, } = ContasGrupos;
    const { list: listGrupoPai, loadingList: loadingListGrupoPai } = GruposPai;
    return { listGrupoPai, loadingListGrupoPai, loadingUpdate, contasGrupos, errors }
}

export default connect(mapStateToProps, {
    getListGrupoPai,
    updateContasGrupos,
    changeListGruposPai,
    clearErrorsContasGrupos
})(Edit);