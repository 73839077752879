import React from 'react';
import { Col, FormGroup, Label, Row, Input, InputGroupAddon, InputGroup } from "reactstrap";
import NumberFormat from 'react-number-format';
function QntAndValor({
    custoProduto,
}) {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Quantidade</Label>
                            <Input
                                name="cmv_qtd_quantidade"
                                step="0.01"
                                type="number"
                                readOnly
                                defaultValue={parseFloat(!!custoProduto.cmv_qtd_quantidade ? custoProduto.cmv_qtd_quantidade : 0).toFixed(4)} />

                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Valor Unidade</Label>
                            <Input
                                readOnly
                                defaultValue={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(custoProduto.cmv_vlr_valor)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Valor Total</Label>
                            <Input
                                readOnly
                                defaultValue={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(
                                    !!custoProduto.cmv_vlr_valor && !!custoProduto.cmv_qtd_quantidade ?
                                        parseFloat(custoProduto.cmv_qtd_quantidade) * parseFloat(custoProduto.cmv_vlr_valor) :
                                        0
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Saldo Quantidade</Label>
                            <Input
                                readOnly
                                defaultValue={parseFloat(!!custoProduto.cmv_sld_quantidade ? custoProduto.cmv_sld_quantidade : 0).toFixed(4)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Saldo Valor</Label>
                            <Input
                                readOnly
                                defaultValue={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(custoProduto.cmv_sld_valor)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default QntAndValor;