import { READOFX_EXTRATO_BANCO_ASYNC, CLEAR_EXTRATO_BANCO, READTXT_EXTRATO_BANCO_ASYNC } from "../actionTypes";

export const readOfxExtratoBanco = payload => ({
    type: READOFX_EXTRATO_BANCO_ASYNC,
    payload
});

export const readTxtExtratoBanco = payload => ({
    type: READTXT_EXTRATO_BANCO_ASYNC,
    payload
});

export const clearExtratoBanco = () => ({
    type: CLEAR_EXTRATO_BANCO
});

