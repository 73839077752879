import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    Row,
    Table,
    Col,
    Button,
    FormGroup,
} from "reactstrap";
import ScrollArea from "react-scrollbar";
import { Typeahead } from "react-bootstrap-typeahead";
import ComponentValor from "./ComponentValor";
import { getListEmpresas } from "../../../../redux/actions";


function filterBy(option, state) {
    if (state.selected.length) {
        return true;
    }
    return option.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
}

const optionsCampos = [
    { name: 'Empresa', value: 'vla_cd_empresa' },
    { name: 'Data de Lançamento', value: 'vla_dt_lancamento' },
    { name: 'Conta', value: 'vla_cd_conta' },
    { name: 'Histórico do Lançamento', value: 'vla_ds_historico' },
    { name: 'Pessoa', value: 'vla_cd_subconta' },
    { name: 'Tipo', value: 'vla_tp_lancamento' },
    { name: 'Valor', value: 'vla_vl_lancamento' },
    { name: 'Data de Vencimento', value: 'vla_dt_vencimento' },
    { name: 'Número da Fatura', value: 'ftr_nu_fatura' },
    { name: 'N° do Documento', value: 'vla_nu_doc' }
]

function CritPesquisa({
    filter,
    handleChangeSelect,
    addLinha,
    removeLinha,
    handleValor,
    handleChange,
    getListEmpresas
}) {

    useEffect(() => {
        getListEmpresas()
    }, [])


    return (
        <div className="b-light border-2 p-10">
            <h6>Critério de Pesquisa</h6>
            <Row>
                <Col xs="12" className="d-flex justify-content-end">
                    <FormGroup>
                        <Button onClick={() => addLinha()}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Linha</span>
                        </Button>
                    </FormGroup>
                </Col>
                <Col xs="12">
                    <ScrollArea horizontal>
                        <Table>
                            <thead>
                                <tr className="text-center">
                                    <th>Campo</th>
                                    <th>Operador</th>
                                    <th>Valor</th>
                                    <th>Lógico</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filter.linhas.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <Typeahead
                                                    filterBy={filterBy}
                                                    id={`selectCampo${key}`}
                                                    placeholder="Informe o campo"
                                                    labelKey="name"
                                                    options={optionsCampos}
                                                    clearButton
                                                    onChange={(selected) => {
                                                        handleChangeSelect('campo', selected, key)
                                                    }}
                                                    positionFixed
                                                />
                                            </td>
                                            <td>
                                                <Typeahead
                                                    id={`selectOperador${key}`}
                                                    labelKey="name"
                                                    placeholder="Informe o operador"
                                                    clearButton
                                                    options={[
                                                        { name: '= (Igual)', value: '=' },
                                                        { name: '> (Maior que)', value: '>' },
                                                        { name: '< (Menor que)', value: '<' },
                                                        { name: '>= (Maior ou Igual)', value: '>=' },
                                                        { name: '<= (Menor ou Igual)', value: '<=' }
                                                    ]}
                                                    onChange={(selected) => {
                                                        handleChangeSelect('operador', selected, key)
                                                    }}
                                                    positionFixed
                                                />
                                            </td>
                                            <td width={'500px'} >
                                                <ComponentValor
                                                    item={item}
                                                    index={key}
                                                    handleChange={handleChange}
                                                    handleChangeSelect={handleChangeSelect}
                                                    handleValor={handleValor}
                                                />
                                            </td>
                                            <td>
                                                <Typeahead
                                                    placeholder="Informe o lógico"
                                                    id={`selectLogi${key}`}
                                                    labelKey="name"
                                                    clearButton
                                                    options={[
                                                        {
                                                            name: 'E',
                                                            value: 'AND'
                                                        },
                                                        {
                                                            name: 'OU',
                                                            value: 'OR'
                                                        }
                                                    ]}
                                                    onChange={(selected) => {
                                                        handleChangeSelect('logico', selected, key)
                                                    }}
                                                    positionFixed
                                                />
                                            </td>
                                            <td className="text-center">
                                                <Button
                                                    color="danger"
                                                    outline
                                                    disabled={!key}
                                                    onClick={() => {
                                                        removeLinha(key)
                                                    }}>
                                                    <i className="fa fa-trash-o"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </ScrollArea>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {
    getListEmpresas
})(CritPesquisa);