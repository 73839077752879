import React from "react";
import DatePicker from "react-datepicker";
import { Col, FormGroup, Row, Label, Input, Button, InputGroup, InputGroupAddon } from "reactstrap";
import br from "date-fns/locale/pt-BR";
import { Typeahead } from "react-bootstrap-typeahead";
import { parseISO } from "date-fns";
import NumberFormat from 'react-number-format';

function Filtro({
    handleChange,
    loadingNotasFiscais,
    handleChangeSelect,
    filter,
    handleValores
}) {
    return (
        <Row>
            <Col md="4" xs="12">
                <FormGroup>
                    <Label>Data de Emissão</Label>
                    <DatePicker
                        className="form-control digits"
                        locale={br}
                        placeholderText="Informe a Data de Emissão"
                        selected={!!filter.dhEmi && parseISO(filter.dhEmi)}
                        dateFormat="dd/MM/yyyy"
                        onChange={(selected) => handleChangeSelect('dhEmi', selected)}
                        isClearable
                    />
                </FormGroup>
            </Col>
            <Col md="4" xs="12">
                <FormGroup>
                    <Label>Código Numérico</Label>
                    <Input
                        placeholder="Informe o Código Numérico"
                        name="cNF"
                        onChange={handleChange}
                    />
                </FormGroup>
            </Col>
            <Col md="4" xs="12">
                <FormGroup>
                    <Label>Número do Documento</Label>
                    <Input
                        placeholder="Informe o Número do Documento"
                        name="nNF"
                        onChange={handleChange}
                    />
                </FormGroup>
            </Col>
            <Col md="4" xs="12">
                <FormGroup>
                    <Label>Código do Produto ou Serviço</Label>
                    <Input
                        placeholder="Informe o Código do Produto ou Serviço"
                        name="cProd"
                        onChange={handleChange}
                    />
                </FormGroup>
            </Col>
            <Col md="4" xs="12">
                <FormGroup>
                    <Label>Valor Total Bruto dos Produtos ou Serviços</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            R$
                    </InputGroupAddon>
                        <NumberFormat
                            placeholder="Informe o Valor"
                            thousandSeparator='.'
                            customInput={Input}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            onValueChange={({ value }) => handleValores('vProd', value)}
                        />
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col md="3" xs="12">
                <FormGroup>
                    <Label>Tipo</Label>
                    <Typeahead
                        id="selectTipo"
                        labelKey="name"
                        placeholder="Informe o Tipo"
                        options={[
                            {
                                name: 'Cancelada',
                                value: 'C'
                            },
                            {
                                name: 'Saida',
                                value: 'S'
                            },
                            {
                                name: 'Entrada',
                                value: 'E'
                            }
                        ]}
                        onChange={(selected) => handleChangeSelect('nfe_tp_nota', selected)}
                        clearButton
                    />
                </FormGroup>
            </Col>
            <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                <FormGroup>
                    <Button
                        disabled={loadingNotasFiscais}
                        title="Pesquisar"
                        className="m-t-30"
                        color="primary">
                        <i className="fa fa-search"></i>
                    </Button>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default Filtro;