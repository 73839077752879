import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Button, UncontrolledTooltip } from "reactstrap";
import { getTiposMovPrd, destroyTiposMovPrd } from "../../../../redux/actions";
import sweetalert2 from "sweetalert2";
function Table({
    loadingTiposMovPrd,
    tiposMovsPrd,
    setOpenModal,
    curFilter,
    getTiposMovPrd,
    destroyTiposMovPrd,
    loadingDestroyTiposMovPrd,
    setSelectedTipoMovPrd
}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, tmp_nm_tipo_mvp }) => ({ id, tmp_nm_tipo_mvp })))
    }, []);
    const deleteTiposMovimentosPrd = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir os registros selecionados?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ tmp_nm_tipo_mvp }) => {
                return (`<li class="list-group-item-action list-group-item">${tmp_nm_tipo_mvp}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!tiposMovsPrd && tiposMovsPrd.current_page !== 1) ? tiposMovsPrd.current_page : 1;
            const fields = { tiposMovsIds: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyTiposMovPrd({ fields, onDelete });
        }
    }
    return (
        <>
            {
                loadingTiposMovPrd ?
                    <div className="loader-box">
                        <div className="loader-1"></div>
                    </div>
                    :
                    !!tiposMovsPrd &&
                    <DataTable
                        persistTableHead
                        selectableRows
                        pagination
                        paginationServer
                        contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                        onSelectedRowsChange={handleSelectedRows}
                        clearSelectedRows={toggleCleared}
                        progressComponent={
                            <div className="loader-box">
                                <div className="loader-1"></div>
                            </div>
                        }
                        paginationComponentOptions={{
                            noRowsPerPage: true,
                            rangeSeparatorText: 'de',
                        }}
                        noDataComponent={<span className="p-20">Nenhum Registro Encontrado</span>}
                        contextActions={
                            <Button
                                sid="tmp_tipo_mov.destroy"
                                disabled={loadingDestroyTiposMovPrd}
                                onClick={() => deleteTiposMovimentosPrd()}
                                color="danger">{loadingDestroyTiposMovPrd ? 'Processando...' : 'Remover Tipo(s) de Movimentação'}
                            </Button>
                        }
                        onChangePage={(page) => {
                            getTiposMovPrd({ fields: { ...curFilter.current, page } })
                        }}
                        progressPending={loadingDestroyTiposMovPrd}
                        paginationPerPage={tiposMovsPrd.per_page}
                        paginationTotalRows={tiposMovsPrd.total}
                        paginationDefaultPage={tiposMovsPrd.current_page}
                        columns={[
                            {
                                name: 'Nome',
                                selector: 'tmp_nm_tipo_mvp',
                                center: true
                            },
                            {
                                name: 'Histórico',
                                selector: 'historico',
                                center: true
                            },
                            {
                                name: 'Tipo',
                                selector: 'tipo_mov',
                                center: true
                            },
                            {
                                name: 'Ações',
                                selector: 'acoes',
                                center: true
                            }
                        ]}
                        data={tiposMovsPrd.data.map(({ id, tmp_nm_tipo_mvp, tmp_ds_historico, tmp_tp_movimento }, index) => {
                            const historico = <>
                                <span id={`hist_${index}`}>{tmp_ds_historico}</span>
                                <UncontrolledTooltip target={`hist_${index}`}>
                                    {`${tmp_ds_historico}`}
                                </UncontrolledTooltip>
                            </>
                            const tipo_mov = tmp_tp_movimento === 'E' ? 'Entrada' : 'Saida';
                            const acoes = (
                                <div style={{ display: "flex", overflow: "auto" }}>
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        title="Exibir todas informações"
                                        onClick={() => {
                                            setSelectedTipoMovPrd(index)
                                            setOpenModal('view')
                                        }}
                                    >
                                        <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                    </a>
                                    <a
                                        sid="tmp_tipo_mov.update"
                                        style={{ cursor: 'pointer' }}
                                        title="Editar informações"
                                        onClick={() => {
                                            setSelectedTipoMovPrd(index)
                                            setOpenModal('edit')
                                        }}
                                    >
                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                    </a>
                                </div>
                            )
                            return { id, tmp_nm_tipo_mvp, historico, tipo_mov, acoes }
                        })}
                    />
            }
        </>
    )
}

const mapStateToProps = ({ TipoMovPrd }) => {
    const { loadingTiposMovPrd, tiposMovsPrd, loadingDestroyTiposMovPrd } = TipoMovPrd;
    return { loadingTiposMovPrd, tiposMovsPrd, loadingDestroyTiposMovPrd }
}

export default connect(mapStateToProps, {
    getTiposMovPrd,
    destroyTiposMovPrd
})(Table)