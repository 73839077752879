import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { getFuncionalidades, getModulos, getGroups, getPermissions } from "../../../redux/actions";
import Inicio from "./Inicio";
import Funcionalidades from './Funcionalidades';
import Modulos from './Modulos';
import Groups from './Groups'
const ControleAcesso = ({ getFuncionalidades, getModulos, getGroups, getPermissions }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [funcionalidadeToFind, setFuncionalidadeToFind] = useState(null);
    useEffect(() => {
        getFuncionalidades()
        getModulos()
        getGroups()
        getPermissions()
    }, []);
    return (
        <Fragment>
            <Breadcrumb parent="Gestão" title="Controle de Acesso" />
            <Container fluid={true}>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader style={{ borderBottom: '1px solid #ecf3fa' }}>
                                <h5>
                                    {activeTab === 1 ? 'Início' : activeTab === 2 ? 'Funcionalidades' : activeTab === 3 ? 'Módulos' : activeTab === 4 ? 'Perfis' : ''}
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <div className="tabbed-card">
                                    <Nav className="pull-right nav-pills nav-primary"  >
                                        <NavItem>
                                            <NavLink href="#" className={`${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                                                Início
                                    </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className={`${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                                                Funcionalidades
                                    </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className={`${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>
                                                Módulos
                                    </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className={`${activeTab === 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}>
                                                Perfis
                                    </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab} >
                                        <TabPane tabId={1}>
                                            <Inicio changeTab={(num) => setActiveTab(num)} findFuncionalidade={(name) => setFuncionalidadeToFind(name)} />
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <Funcionalidades funcionalidadeToFind={funcionalidadeToFind} clearFindFuncionalidade={() => setFuncionalidadeToFind(null)} />
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <Modulos />
                                        </TabPane>
                                        <TabPane tabId={4}>
                                            <Groups />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
const mapStateToProps = ({ }) => {
    return {}
}
export default connect(mapStateToProps, {
    getFuncionalidades,
    getModulos,
    getGroups,
    getPermissions
})(ControleAcesso);