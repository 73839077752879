import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Table, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PrintComponent from "../../../components/PrintComponent";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { getSaldoConta } from "../../../redux/actions";

function Relatorio({
    active_user_empresa,
    extratoBanco,
    filter,
    getSaldoConta,
    fields,
    saldoConta,
    isOpen,
    clickClose,
    loadingSaldoConta
}) {
    const printCompRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Conciliação Bancária ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT),

    });

    const extratos = !!extratoBanco ? extratoBanco.extratos.filter((el) => !!filter.data ? el.dia === filter.data : true) : [];

    const extratosDeb = extratos.filter((el) => el.tipo === "D");
    const extratosCred = extratos.filter((el) => el.tipo === "C");

    const totalDeb = extratosDeb.reduce((acu, cur) => acu + cur.valor, 0);
    const totalCred = extratosCred.reduce((acu, cur) => acu + cur.valor, 0);

    return (
        <Modal
            isOpen={isOpen}
            size="xl"
            onOpened={() => {
                getSaldoConta({ fields: { codigo: fields.banco?.value, data: filter.data } })
            }}

        >
            <ModalHeader toggle={() => {
                clickClose()
            }}>
                Relatório Extrato Bancário
            </ModalHeader>
            {!!extratoBanco &&
                <ModalBody>
                    <div className="m-b-10 d-flex justify-content-end p-10">
                        <Button size="sm" onClick={handlePrint} className="f-16">
                            <i className="fa fa-print"></i>
                            <span className="p-l-5">
                                Imprimir
                     </span>
                        </Button>
                    </div>
                    <PrintComponent ref={printCompRef}>
                        <Row className="b-light border-5 m-b-20">
                            <Col xs="12">
                                <Table className="font-arial-rlt" borderless>
                                    <thead>
                                        <tr>
                                            <td style={{ borderWidth: 0 }} className="p-b-0 f-w-900">
                                                {active_user_empresa?.emp_nm_empresa}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="f-w-900">
                                                {`Conciliação Bancária - ${extratoBanco?.banco}`}
                                            </td>
                                            <td>
                                                {` `}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="f-w-900">
                                                {`Agencia e Conta Corrente: ${extratoBanco?.agCc}`}
                                            </td>
                                            <td className="f-w-900">
                                                {!!filter.data ? format(parseISO(filter.data), 'dd/MM/yyyy') : 'Sem data informada'}
                                            </td>
                                        </tr>
                                        <tr className="text-center">
                                            <th>Data</th>
                                            <th>Histórico</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{` `}</td>
                                            <td className="f-w-900">
                                                Saldo do STRATUS
                                            </td>
                                            <td className="f-w-900">
                                                {loadingSaldoConta ?
                                                    <div className="loader-box">
                                                        <div className="loader-1"></div>
                                                    </div>
                                                    :
                                                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(saldoConta)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{` `}</td>
                                            <td>
                                                (1) Débitos efetuados para a empresa não creditados para o banco
                                    </td>
                                            <td>
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{` `}</td>
                                            <td>
                                                (2) Créditos efetuados para a empresa não creditados para o banco
                                    </td>
                                            <td>
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCred)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{` `}</td>
                                            <td className="f-w-900">Saldo do Banco</td>
                                            <td className="f-w-900">
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb - (totalCred * -1))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">{` `}</td>
                                        </tr>
                                        <tr className="f-w-900">
                                            <td>
                                                {` `}
                                            </td>
                                            <td>
                                                (1) Débitos efetuados para a empresa não creditados para o banco
                                    </td>
                                            <td>
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalDeb)}
                                            </td>
                                        </tr>
                                        {extratosDeb.map(({ dia, desc, valor }, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {format(parseISO(dia), 'dd/MM/yyyy')}
                                                    </td>
                                                    <td>
                                                        {desc}
                                                    </td>
                                                    <td>
                                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr className="f-w-900">
                                            <td>
                                                {` `}
                                            </td>
                                            <td>
                                                (2) Créditos efetuados para a empresa não creditados para o banco
                                    </td>
                                            <td>
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCred)}
                                            </td>
                                        </tr>
                                        {extratosCred.map(({ dia, desc, valor }, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {format(parseISO(dia), 'dd/MM/yyyy')}
                                                    </td>
                                                    <td>
                                                        {desc}
                                                    </td>
                                                    <td>
                                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </PrintComponent>
                </ModalBody>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Auth, Contas }) => {
    const { active_user_empresa } = Auth;
    const { saldoConta, loadingSaldoConta } = Contas;
    return { active_user_empresa, saldoConta, loadingSaldoConta }
}

export default connect(mapStateToProps, {
    getSaldoConta
})(Relatorio);