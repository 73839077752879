import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup
} from 'reactstrap';
import { connect } from 'react-redux';
import PrintComponent from "../../../../components/PrintComponent";
import { getRltPesquisa, clearRltPesquisa } from "../../../../redux/actions";
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import CritPesquisa from "./CritPesquisa";
import Relatorio from "./Relatorio";

const Pesquisa = ({
    loadingRltPesquisa,
    rltPesquisa,
    active_user_empresa,
    getRltPesquisa,
    clearRltPesquisa
}) => {

    useEffect(() => () => clearRltPesquisa(), [])

    //ESTADOS
    const [filter, setFilter] = useState({
        linhas: [
            { campo: null, operador: null, valor: null, logico: null }
        ],
    });

    //REFS
    const printCompRef = useRef(null);

    //FUNÇÕES
    const addLinha = () => {
        const currentFill = { ...filter };
        currentFill.linhas.push({ campo: null, operador: null, valor: null, logico: null });

        setFilter(currentFill)

    }

    const removeLinha = index => {
        const currentFill = { ...filter };
        currentFill.linhas.splice(index, 1);
        setFilter(currentFill)
    }


    const handleChangeSelect = (name, selected, indexLinha) => {

        setFilter(prevState => {
            if (selected instanceof Array && !!selected.length) {
                prevState.linhas[indexLinha][name] = selected[0].value;
                return { ...prevState };
            } else if (selected instanceof Date) {
                prevState.linhas[indexLinha][name] = format(selected, 'yyyy-MM-dd');
            } else {
                prevState.linhas[indexLinha][name] = null;
            }

            if (name === 'campo') {
                prevState.linhas[indexLinha].valor = null;
            }

            return { ...prevState };
        })
    }

    const handleValor = (value, indexLinha) => {
        const currentFill = { ...filter };
        currentFill.linhas[indexLinha].valor = value;

        setFilter(currentFill)
    }

    const handleChange = (e, indexLinha) => {
        const { name, value } = e.target;
        setFilter(prevState => {
            prevState.linhas[indexLinha][name] = value || null;

            return { ...prevState }
        });
    }

    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Operacional Pesquisa ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        // onBeforePrint: () => setLoadingPrint(true),
        // onAfterPrint: () => setLoadingPrint(false),
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });

    const onSubmitFilter = e => {
        e.preventDefault();

        getRltPesquisa({ fields: filter })

    }

    return (
        <Fragment>
            <Breadcrumb parent="Operacional" title="Pesquisa" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                <Row>
                                    <Col xs="12">
                                        <CritPesquisa
                                            filter={filter}
                                            handleChangeSelect={handleChangeSelect}
                                            addLinha={addLinha}
                                            removeLinha={removeLinha}
                                            handleValor={handleValor}
                                            handleChange={handleChange}
                                        />
                                    </Col>
                                    <Col md="1" sm="12" className="d-flex justify-content-center" style={{ alignItems: 'center' }} >
                                        <FormGroup>
                                            <Button
                                                title="Pesquisar"
                                                onClick={onSubmitFilter}
                                                disabled={loadingRltPesquisa}
                                                className="m-t-30"
                                                color="primary">
                                                <i className="fa fa-search"></i>
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        {loadingRltPesquisa ?
                            <div className="loader-box">
                                <div className="loader-1"></div>
                            </div>
                            :
                            <Card className="shadow">
                                <CardBody>
                                    {!!rltPesquisa.length &&
                                        <div className="m-b-10 d-flex justify-content-end">
                                            <Button size="sm" onClick={handlePrint} className="f-16">
                                                <i className="fa fa-print"></i>
                                                <span className="p-l-5">
                                                    Imprimir
                                                </span>
                                            </Button>
                                        </div>
                                    }
                                    <PrintComponent ref={printCompRef}>
                                        {!!rltPesquisa.length &&
                                            <div className="d-flex justify-content-start font-arial-rlt">
                                                <span className="">
                                                    {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - Pesquisa`}
                                                </span>
                                            </div>
                                        }
                                        <Relatorio />
                                    </PrintComponent>
                                </CardBody>
                            </Card>
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ RltOperacionais, Auth }) => {
    const { rltPesquisa, loadingRltPesquisa } = RltOperacionais
    const { active_user_empresa } = Auth;

    return { rltPesquisa, loadingRltPesquisa, active_user_empresa };
}

export default connect(mapStateToProps, {
    getRltPesquisa,
    clearRltPesquisa
})(Pesquisa);