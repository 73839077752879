import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function FourthCard({ notaFiscal }) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Código do Produto ou Serviço </Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.cProd || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Descrição do Produto ou Serviço</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.xProd || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Valor Total Bruto dos Produtos ou Serviços</Label>
                            <Input
                                readOnly
                                defaultValue={new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(notaFiscal.vProd || 0)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Quantidade Comercial</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.qCom || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Valor Unitário de Comercialização</Label>
                            <Input
                                readOnly
                                defaultValue={new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(notaFiscal.vUnCom || 0)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default FourthCard;