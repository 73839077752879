import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form } from 'reactstrap';
import { getCustosProdutos } from "../../../../redux/actions";
import { format } from "date-fns";
import Table from "./Table";
import Periodo from "./Periodo";
import Outros from "./Outros";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";

function CustoPorProduto({
    getCustosProdutos,
    loadingCustosProd,
    custosProdutos
}) {
    useEffect(() => {
        getCustosProdutos();
    }, []);

    const [openModal, setOpenModal] = useState(null);
    const [selectedCustoProd, setSelectedCustoProd] = useState(null);
    const [filter, setFilter] = useState({
        dataInicial: "",
        dataFinal: "",
        produto: "",
        tipo: ""
    });

    const curFilter = useRef(null);

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dataFinal':
            case 'dataInicial':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: "" })
                }
                break;
            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value });
                } else {
                    setFilter({ ...filter, [name]: [] });
                }
                break;
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    }

    const onSubmitFilter = e => {
        e.preventDefault();
        getCustosProdutos({ fields: filter });
        curFilter.current = filter;
    }
    return (
        <>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit selectedCustoProd={selectedCustoProd} isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} />
            <View
                isOpen={openModal === 'view'}
                clickClose={() => setOpenModal(null)}
                custoProduto={!!custosProdutos && custosProdutos.data[selectedCustoProd]} />
            <Breadcrumb parent="Estoque" title="Custo por Produto" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <div className="d-flex" style={{ overflowX: "auto" }}>
                            <Button
                                // sid="cmv_diario.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => {
                                    alert('Funcionalidade em desenvolvimento.')
                                }}>
                                <i className="fa fa-lock"></i><span className="p-l-5">Fechar Custos Por Produto</span>
                            </Button>
                            <Button
                                sid="cmv_diario.store"
                                color="primary"
                                className="m-l-10"
                                size="md"
                                onClick={() => setOpenModal('add')}>
                                <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                            </Button>
                        </div>

                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={onSubmitFilter}>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <Outros handleChange={handleChange} handleChangeSelect={handleChangeSelect} />
                                        </Col>
                                        <Col md="5" xs="12">
                                            <Periodo filter={filter} handleChangeSelect={handleChangeSelect} />
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    disabled={loadingCustosProd}
                                                    title="Pesquisar"
                                                    className="m-t-35"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody className="p-t-20">
                                <Table
                                    setSelectedCustoProd={setSelectedCustoProd}
                                    setOpenModal={setOpenModal}
                                    curFilter={curFilter}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = ({ CmvDiario }) => {
    const { loadingCustosProd, custosProdutos } = CmvDiario;
    return { loadingCustosProd, custosProdutos }
}

export default connect(mapStateToProps, {
    getCustosProdutos,
})(CustoPorProduto);