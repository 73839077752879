import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from "react-redux";
import { getListContas } from "../../../../redux/actions";
import { useMemo } from 'react';
import ListSelect from "../../../../components/ListSelect";
import FormFeedback from 'reactstrap/lib/FormFeedback';

function Contas({
    handleChangeSelect,
    listContas,
    loadingListContas,
    getListContas,
    errors,
    validator,
    filter
}) {

    const optionsContas = useMemo(() => listContas.data.map(({ ctn_cd_conta, ctn_nm_conta }) => ({ name: `${ctn_cd_conta} - ${ctn_nm_conta}`, value: ctn_cd_conta })),
        [listContas.data])
    return (
        <>
            <div className="b-light border-2 p-10">
                <h6>Contas*</h6>
                <Row>
                    <Col xs="12">
                        <ListSelect
                            data={optionsContas}
                            isLoading={loadingListContas}
                            onChange={(items) => handleChangeSelect('contas', items)}
                            onPaginate={(offset, searchValue) => {
                                getListContas({ fields: { offset, name: searchValue } })
                            }}
                            onSearch={(name, options) => {
                                if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name.trimEnd()))) {
                                    getListContas({ fields: { name } })
                                }
                            }}
                            totalResults={listContas.total}
                            containerStyle={{ maxHeight: 180 }}
                            pagination
                        />
                        <FormFeedback style={{
                            display: validator.visibleFields.includes('contas') ||
                                (!validator.fields.contas && validator.messagesShown)
                                ? 'block' : 'none'
                        }}>
                            {validator.message('contas', filter.contas.toString(), 'required')}
                        </FormFeedback>
                    </Col>
                </Row>
            </div>
        </>
    )
}
const mapStateToProps = ({ Faturas, Pessoas, Contas }) => {
    const { list: listContas, loadingList: loadingListContas, errors } = Contas;
    const { ftr_sem_baixa, loadingFtrSemBaixa } = Faturas;
    const { list: listPessoas, loadingList: loadingListPessoas } = Pessoas;
    return { ftr_sem_baixa, loadingFtrSemBaixa, listPessoas, loadingListPessoas, listContas, loadingListContas, errors }
}

export default connect(mapStateToProps, {
    getListContas
})(Contas);