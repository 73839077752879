import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import SecondCard from "./SecondCard";
import FirstCard from "./FirstCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";
import { format } from "date-fns";
import { updateDevelucaoNFe } from "../../../../../redux/actions";

function Edit({
    isOpen,
    clickClose,
    selectedNF,
    notasFiscais,
    updateDevelucaoNFe,
    loadingUpdDevolucaoNFe
}) {
    const [notaFiscal, setNotaFiscal] = useState(null);


    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dat_emi_devolucao':
            case 'nfe_devoucao':
                if (!!selected) {
                    setNotaFiscal({ ...notaFiscal, [name]: format(selected, 'yyyy-MM-dd HH:mm') });
                } else {
                    setNotaFiscal({ ...notaFiscal, [name]: null });
                }
                break;
        }
    }

    const submitForm = e => {
        e.preventDefault();
        const { nfe_devoucao, dat_emi_devolucao } = notaFiscal;
        updateDevelucaoNFe({ fields: { nfe_devoucao, dat_emi_devolucao }, onSave: clickClose, index: selectedNF });
    }

    return (
        <Modal
            size="xxl"
            isOpen={isOpen}
            onOpened={() => {
                const dados = { ...notasFiscais.data[selectedNF] };
                delete dados.row_num;
                delete dados.nu_id;
                setNotaFiscal(dados);
            }}
            onClosed={() => {
                setNotaFiscal(null)
            }}
        >
            <ModalHeader toggle={(() => !loadingUpdDevolucaoNFe && clickClose())}>
                Editar da Nota Fiscal
            </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    {notaFiscal &&
                        <Row>
                            <Col md="6" xs="12">
                                <Row>
                                    <Col xs="12">
                                        <FirstCard notaFiscal={notaFiscal} />
                                    </Col>
                                    <Col xs="12">
                                        <SecondCard
                                            handleChangeSelect={handleChangeSelect}
                                            notaFiscal={notaFiscal} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" xs="12">
                                <Row>
                                    <Col xs="12">
                                        <ThirdCard notaFiscal={notaFiscal} />
                                    </Col>
                                    <Col xs="12">
                                        <FourthCard notaFiscal={notaFiscal} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button disabled={loadingUpdDevolucaoNFe} type="button" onClick={() => clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={loadingUpdDevolucaoNFe} color="primary">
                        {loadingUpdDevolucaoNFe ? 'Processando...' : 'Salvar'}
                </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ NotasFiscais }) => {
    const { notasFiscais,loadingUpdDevolucaoNFe } = NotasFiscais;
    return { notasFiscais,loadingUpdDevolucaoNFe }
}

export default connect(mapStateToProps, {
    updateDevelucaoNFe
})(Edit);