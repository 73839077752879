import React from "react";
import { Col, FormGroup, Label, Row, InputGroup, Input, InputGroupAddon, FormFeedback } from "reactstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
const Valores = ({ validator, fields, errors, validFields, handleValores }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Valores</h6></Label>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor juros diário*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        name="pss_vl_juro_banco"
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('pss_vl_juro_banco') ||
                                            !!errors.pss_vl_juro_banco ||
                                            (!validator.fields.pss_vl_juro_banco && validator.messagesShown)
                                        }
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ value }) => handleValores('pss_vl_juro_banco', value)}
                                    />
                                </InputGroup>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_vl_juro_banco') ||
                                            !!errors.pss_vl_juro_banco ||
                                            (!validator.fields.pss_vl_juro_banco && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_vl_juro_banco', fields.pss_vl_juro_banco, 'required')}
                                    {(!!errors.pss_vl_juro_banco) && errors.pss_vl_juro_banco.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor margem*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        name="pss_vl_juro_margem"
                                        decimalSeparator=","
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        invalid={validator.visibleFields.includes('pss_vl_juro_margem') ||
                                            !!errors.pss_vl_juro_margem ||
                                            (!validator.fields.pss_vl_juro_margem && validator.messagesShown)
                                        }
                                        onValueChange={({ value }) => handleValores('pss_vl_juro_margem', value)}
                                    />
                                </InputGroup>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_vl_juro_margem') ||
                                            !!errors.pss_vl_juro_margem ||
                                            (!validator.fields.pss_vl_juro_margem && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_vl_juro_margem', fields.pss_vl_juro_margem, 'required')}
                                    {(!!errors.pss_vl_juro_margem) && errors.pss_vl_juro_margem.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}
export default connect(mapStateToProps, {})(Valores)