import React, { Fragment } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { Col, FormGroup, Input, Label, Row, FormFeedback, InputGroup, InputGroupAddon } from "reactstrap";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';

const DadosGerais = ({
    handleChangeSelect,
    validator,
    validFields,
    fields,
    handleValor,
    contrapartidas
}) => {
    const optionsTipo = [{ name: 'Autômatica', value: 'A' }, { name: 'Manual', value: 'M' }];
    const optionsContrapartidas = contrapartidas.map(({ cnt_ds_historico, cnt_nu_doc }, key) => {
        return { name: `${cnt_nu_doc} - ${cnt_ds_historico}`, value: key }
    })

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Contrapartida*</Label>
                                <Typeahead
                                    id="selectContrapartida"
                                    labelKey="name"
                                    placeholder="Informe a contrapartida"
                                    onChange={(selected) => handleChangeSelect('contrapartida', selected)}
                                    onBlur={() => validFields('contrapartida')}
                                    options={optionsContrapartidas}
                                    isInvalid={
                                        validator.visibleFields.includes('contrapartida') ||
                                        (!validator.fields.contrapartida && validator.messagesShown)
                                    }
                                    clearButton
                                    renderMenuItemChildren={option => {
                                        return (
                                            <Fragment key={option.value}>
                                                <div>
                                                    <span>{option.name}</span>
                                                </div>
                                                <div>
                                                    <small>{option.subItem}</small>
                                                </div>
                                            </Fragment>
                                        )
                                    }}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('contrapartida') ||
                                            (!validator.fields.contrapartida && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}
                                >
                                    {validator.message('contrapartida', fields.contrapartida, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Número da Fatura*</Label>
                                <Input
                                    maxLength={20}
                                    placeholder="Informe o número da fatura"
                                    name="ftr_nu_fatura"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={
                                        validator.visibleFields.includes('ftr_nu_fatura') || (!validator.fields.ftr_nu_fatura && validator.messagesShown)
                                    }
                                    disabled
                                    value={fields.ftr_nu_fatura}
                                />
                                <FormFeedback>
                                    {validator.message('ftr_nu_fatura', fields.ftr_nu_fatura, 'required|max:20')}
                                    {/* {(!!errors.cnt_nu_doc) && errors.cnt_nu_doc.map((erro, index) => <span key={index}>{erro}</span>)} */}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="8" md="12">
                            <FormGroup>
                                <Label>Data de Vencimento*</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Selecione a Data Vencimento"
                                    selected={!!fields.ftr_dt_vencimento ? parseISO(fields.ftr_dt_vencimento) : null}
                                    onCalendarClose={() => validFields('ftr_dt_vencimento')}
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control digits 
                                    ${validator.visibleFields.includes('ftr_dt_vencimento') ||
                                            (!validator.fields.ftr_dt_vencimento && validator.messagesShown)
                                            ? 'is-invalid' : ''}`}
                                    onChange={(selected) => handleChangeSelect('ftr_dt_vencimento', selected)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('ftr_dt_vencimento') ||
                                            (!validator.fields.ftr_dt_vencimento && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}
                                >
                                    {validator.message('ftr_dt_vencimento', fields.ftr_dt_vencimento, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="12">
                            <FormGroup>
                                <Label>Tipo de Baixa*</Label>
                                <Typeahead
                                    id="selectBaixa"
                                    labelKey="name"
                                    emptyLabel="Nenhuma registro encontrado"
                                    clearButton
                                    options={optionsTipo}
                                    placeholder="Informe o tipo de baixa"
                                    onChange={(selected) => handleChangeSelect('ftr_tp_baixa', selected)}
                                    onBlur={() => validFields('ftr_tp_baixa')}
                                    isInvalid={
                                        validator.visibleFields.includes('ftr_tp_baixa') ||
                                        (!validator.fields.ftr_tp_baixa && validator.messagesShown)
                                    }
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('ftr_tp_baixa') ||
                                            (!validator.fields.ftr_tp_baixa && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}
                                >
                                    {validator.message('ftr_tp_baixa', fields.ftr_tp_baixa, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Valor da Fatura*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('ftr_vl_fatura') ||
                                            (!validator.fields.ftr_vl_fatura && validator.messagesShown)
                                        }
                                        name="ftr_vl_fatura"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ value }) => handleValor('ftr_vl_fatura', value)}
                                    />
                                </InputGroup>
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('ftr_vl_fatura') ||
                                            (!validator.fields.ftr_vl_fatura && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('ftr_vl_fatura', fields.ftr_vl_fatura, `required`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas } = Contas;
    return { listContas }
}
export default connect(mapStateToProps, {})(DadosGerais)