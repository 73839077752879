import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Row,
    Label,
    ListGroup,
    ListGroupItem,
    Alert
} from "reactstrap";
import ScrollArea from "react-scrollbar";
const NivelAcesso = ({ user }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Nivel de Acesso</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Perfis</Label>
                                {!!user.groups.length ?
                                    <ScrollArea vertical={true} horizontal={false}>
                                        <ListGroup>
                                            {user.groups.map(({ name }, key) => (
                                                <ListGroupItem className="list-group-item-action" key={key}>{name}</ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </ScrollArea>
                                    :
                                    <Alert color="light">Nenhum Perfil Vinculado</Alert>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>

    )
}

const mapStateToProps = ({  }) => {
    return { };
}
export default connect(mapStateToProps, {})(NivelAcesso);