import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Col, FormGroup, Label, Row } from "reactstrap";

function filterBy(option, state) {
  if (state.selected.length) {
    return true;
  }

  return option.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
}

function Opcoes({ handleChangeSelect }) {
  const optionsTipo = [
    {
      name: "Crédito",
      value: "C",
    },
    {
      name: "Débito",
      value: "D",
    },
  ];

  const optionsFatura = [
    {
      name: "Sim",
      value: "S",
    },
    {
      name: "Não",
      value: "N",
    },
  ];

  return (
    <div className="b-light border-2 p-10">
      <h6>Opções</h6>
      <Row>
        <Col xs="12" md="6">
          <FormGroup>
            <Label>Tipo</Label>
            <Typeahead
              filterBy={filterBy}
              id="selectOrder"
              labelKey="name"
              defaultSelected={optionsTipo.filter((el) => el.value === "C")}
              options={optionsTipo}
              onChange={(selected) => handleChangeSelect("tipo", selected)}
              placeholder="Selecione"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label>Fatura baixada</Label>
            <Typeahead
              filterBy={filterBy}
              id="selectOrder"
              labelKey="name"
              clearButton
              defaultSelected={optionsFatura.filter((el) => el.value === 0)}
              options={optionsFatura}
              onChange={(selected) =>
                handleChangeSelect("faturaBaixada", selected)
              }
              placeholder="Selecione"
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default Opcoes;
