import React from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup,
    Label,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Alert
} from "reactstrap";
import ScrollArea from 'react-scrollbar';
const ViewFuncionalidade = ({ isOpen, clickClose, selectedFunc, funcionalidades }) => {
    const dados = !!funcionalidades && funcionalidades.data[selectedFunc];
    return (
        <Modal isOpen={isOpen} size="lg">
            <ModalHeader toggle={() => clickClose()}>Informações da Funcionalidade</ModalHeader>
            <ModalBody>
                {!!dados &&
                    <Row>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input readOnly defaultValue={dados.name} />
                            </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Módulo</Label>
                                <Input readOnly defaultValue={!!dados.modulo ? dados.modulo.name : 'Não Informado'} />
                            </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Permissões</Label>
                                {!!dados.permissions.length ?
                                    <ScrollArea vertical={true} horizontal={false}>
                                        <ListGroup>
                                            {dados.permissions.map(({ name }, key) => (
                                                <ListGroupItem className="list-group-item-action" key={key}>{name}</ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </ScrollArea>
                                    :
                                    <Alert color="light">Nenhuma Permissão Vinculada</Alert>
                                }

                            </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Perfis</Label>
                                {!!dados.groups.length ?
                                    <ScrollArea vertical={true} horizontal={false}>
                                        <ListGroup>
                                            {dados.groups.map(({ name }, key) => (
                                                <ListGroupItem className="list-group-item-action" key={key}>{name}</ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </ScrollArea>
                                    :
                                    <Alert color="light">Nenhum Perfil Vinculado</Alert>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>Fechar</Button>
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = ({ Funcionalidades }) => {
    const { funcionalidades } = Funcionalidades;
    return { funcionalidades }
}

export default connect(mapStateToProps, {})(ViewFuncionalidade);