import { all, takeLatest, call, put, fork } from "redux-saga/effects";
import {
    GET_RLT_OPERACIONAL_LANCAMENTO_ASYNC,
    GET_RLT_OPERACIONAL_LANCAMENTO
} from "../actionTypes";
import Api from "../../services/api";


function* getRltOperacionalLanSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetRltOperacionalLan, fields);

        if (data.ok) {
            const { dados } = data;
            yield put({ type: GET_RLT_OPERACIONAL_LANCAMENTO, payload: { rltOperacionalLan: dados } });
        }

    } catch (error) {
        yield put({ type: GET_RLT_OPERACIONAL_LANCAMENTO, payload: {} });
    }
}
const apiGetRltOperacionalLan = async fields => {
    const { data } = await Api.get(`/api/lancamentos/relatorioOperacional`, { params: fields });

    return data;
}

function* watchGetRltOperacionalLan() {
    yield takeLatest(GET_RLT_OPERACIONAL_LANCAMENTO_ASYNC, getRltOperacionalLanSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetRltOperacionalLan)
    ])
}
