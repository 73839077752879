import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { 
    Modal,
     ModalHeader, 
     ModalBody, 
     Form, 
     Row, 
     Col, 
     FormGroup, 
     Label, 
     Input, 
     ModalFooter, 
     Button, 
     Alert, 
     ListGroupItem, 
     ListGroup
     } from "reactstrap";
import ScrollArea from 'react-scrollbar';
import { getListFuncionalidades, storeGroups, updateGroups } from "../../../../redux/actions";
const initialState = { name: null, funcionalidades: [] };
const EditGroup = (
    {
        selectedGroup,
        groups,
        isOpen,
        clickClose,
        funcionalidades,
        loadingFuncionalidades,
        getListFuncionalidades,
        updateGroups,
        loadingUpdateGroups
    }) => {
    //ESTADOS
    const [fields, setFields] = useState(initialState);
    const [group, setGroup] = useState(null);
    const [listFuncionalidades, setListFuncionalidades] = useState([]);

    useEffect(() => {
        setListFuncionalidades(funcionalidades);
    }, [funcionalidades]);


    //FUNÇÕES
    const handleSearch = ({ target: { value } }) => {
        if (!!funcionalidades) {
            const filter = funcionalidades.filter(({ name }) => new RegExp(`${value}`, 'i').test(name));
            setListFuncionalidades(filter);
        }
    }
    const submitForm = e => {
        e.preventDefault();
        const fields = { ...group, page: groups.current_page };
        updateGroups({ fields, onUpdate: clickClose });
    }

    return (
        <Modal isOpen={isOpen} size="lg"
            onOpened={() => {
                const dados = { ...groups.data[selectedGroup] };
                delete dados.row_num;
                getListFuncionalidades();
                setGroup(dados);
            }}
            onClosed={() => {
                setFields(initialState)
            }}>
            <ModalHeader toggle={() => {
                !loadingUpdateGroups && clickClose();
            }}>
                Editar Perfil
           </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    {!!group &&
                        <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        name="name"
                                        placeholder="Informe o nome do perfil"
                                        defaultValue={group.name}
                                        onChange={({ target: { value } }) => setGroup({ ...group, name: value })} />
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Funcionalidades</Label>
                                    <Row>
                                        <Col xs="6">
                                            <Input
                                                placeholder="Pesquisar Funcionalidade"
                                                autoComplete="off"
                                                name="funcionalidade"
                                                onChange={handleSearch}
                                                className="mb-2" />
                                            {loadingFuncionalidades ?
                                                <div className="loader-box">
                                                    <div className="loader-1"></div>
                                                </div>
                                                :
                                                !!listFuncionalidades.length ?
                                                    <ScrollArea vertical={true} horizontal={false} style={{ maxHeight: 300 }}>
                                                        <ListGroup>
                                                            {listFuncionalidades.map(({ name, id }, key) => {
                                                                return (
                                                                    <ListGroupItem
                                                                        key={key}
                                                                        className="list-group-item-action"
                                                                        onClick={() => {
                                                                            const funcionalidades = [...fields.funcionalidades];
                                                                            const index = funcionalidades.findIndex((el) => el.id === id);
                                                                            if (index !== -1) {
                                                                                funcionalidades.splice(index, 1);
                                                                            } else {
                                                                                funcionalidades.push({ id, name });
                                                                            }
                                                                            setGroup({ ...group, funcionalidades })
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        <Input
                                                                            className="checkbox_animated"
                                                                            checked={!!group.funcionalidades.filter((el) => el.id === id).length}
                                                                            onChange={() => { }}
                                                                            id={`chk-ani${key}`}
                                                                            type="checkbox" />
                                                                        {name}
                                                                    </ListGroupItem>
                                                                )
                                                            })}
                                                        </ListGroup>
                                                    </ScrollArea>
                                                    :
                                                    <Alert color="light">Nenhum Funcionalidade Encontrada</Alert>
                                            }
                                        </Col>
                                        <Col xs="6">
                                            <div
                                                className="mb-2"
                                                style={{
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0.375rem 0.75rem',
                                                    fontSize: '1rem'
                                                }}>
                                                <span className="pull-right">{`Selecionados ${group.funcionalidades.length}`}</span>
                                            </div>
                                            <ScrollArea vertical={true} horizontal={false} >
                                                <ListGroup>
                                                    {!!group.funcionalidades.length ?
                                                        group.funcionalidades.map(({ name }, index) => {
                                                            return (
                                                                <ListGroupItem
                                                                    key={index}
                                                                    className="d-flex justify-content-between align-items-center list-group-item-action"
                                                                    onClick={() => {
                                                                        const funcionalidades = [...group.funcionalidades];
                                                                        funcionalidades.splice(index, 1);
                                                                        setGroup({ ...group, funcionalidades })
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    {name}
                                                                    <span>X</span>
                                                                </ListGroupItem>
                                                            );
                                                        })
                                                        :
                                                        <Alert color="light">Nenhuma Funcionalidade Selecionada</Alert>
                                                    }
                                                </ListGroup>
                                            </ScrollArea>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => clickClose()} disabled={loadingUpdateGroups} type="button">Fechar</Button>
                    <Button color="primary" type="submit" disabled={loadingUpdateGroups}>{loadingUpdateGroups ? 'Processando...' : 'Salvar'}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Funcionalidades, Groups }) => {
    const { list: funcionalidades, loadingList: loadingFuncionalidades, } = Funcionalidades;
    const { loadingUpdateGroups, groups } = Groups;
    return { funcionalidades, loadingFuncionalidades, loadingUpdateGroups, groups }
}
export default connect(mapStateToProps, {
    getListFuncionalidades,
    storeGroups,
    updateGroups
})(EditGroup);