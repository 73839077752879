import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Button,
    Form,
    Table
} from 'reactstrap';

import { connect } from "react-redux";
import { format, parseISO, differenceInDays } from "date-fns";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

import Periodo from './Periodo';
import Opcoes from './Opcoes';
import TabOptions from './TabOptions';
import PrintComponent from "../../../../components/PrintComponent";
import Mensal from './modelos/Mensal';
import MensalCentroAgrupado from './modelos/MensalCentroAgrupado';
import Margem from './modelos/Margem';
import Percentual from './modelos/Percentual';
import PercentualCentroAgrupado from './modelos/PercentualCentroAgrupado';

import {
    getRltMaster,
    getListEmpresas,
    getListContas,
    getListCentroCusto,
    getListPessoas
} from "../../../../redux/actions";

const Master = ({
    getRltMaster,
    loadingRltMaster,
    rltMaster,
    empresas,
    getListEmpresas,
    loadingList,
    listContas,
    loadingListContas,
    getListContas,
    listCentroCusto,
    loadingListCentroCusto,
    getListCentroCusto,
    listPessoas,
    loadingListPessoas,
    getListPessoas
}) => {

    const printRef = useRef();

    const [filter, setFilter] = useState({
        periodoInicial: format(new Date, 'yyyy-MM-dd'),
        periodoFinal: format(new Date, 'yyyy-MM-dd'),
        referenciaInicial: format(new Date, 'yyyy-MM-dd'),
        referenciaFinal: format(new Date, 'yyyy-MM-dd'),
        modelo: '',
        regime: 'C',
        dec: 0,
        exibir: [],
        empresas: [],
        contas: [],
        centroCusto: [],
        pessoas: [],
        indice: ''
    });

    useEffect(() => {
        getListEmpresas();
        getListContas();
        getListCentroCusto();
        getListPessoas();
    }, []);

    useEffect(() => {
        const { periodoInicial, periodoFinal, referenciaInicial, referenciaFinal } = filter;

        const periodoInicialParsed = parseISO(periodoInicial);
        const periodoFinalParsed = parseISO(periodoFinal);
        const periodoDifference = differenceInDays(periodoFinalParsed, periodoInicialParsed);

        const referenciaInicialParsed = parseISO(referenciaInicial);
        const referenciaFinalParsed = parseISO(referenciaFinal);
        const referenciaDifference = differenceInDays(referenciaFinalParsed, referenciaInicialParsed);

        if (periodoDifference < 0 || referenciaDifference < 0) {
            setFilter({
                ...filter,
                periodoFinal: periodoDifference < 0 ? periodoInicial : periodoFinal,
                referenciaFinal: referenciaDifference < 0 ? referenciaInicial : referenciaFinal,
            });
        }
    }, [filter]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `Relatório - Gerencial - Master ${format(new Date, 'dd/MM/yyyy HH:mm:ss')}`,
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });

    const renderRelatorio = () => {
        switch (rltMaster?.fields?.modelo) {
            case 'grafico':
                return null;

            case 'margem':
                return <Margem rltMaster={rltMaster} />;

            case 'mensal':
                return rltMaster?.fields?.exibir?.includes('CttAgrupando')
                    ? <MensalCentroAgrupado rltMaster={rltMaster} />
                    : <Mensal rltMaster={rltMaster} />;

            case 'percentual':
                return rltMaster?.fields?.exibir?.includes('CttAgrupando')
                    ? <PercentualCentroAgrupado rltMaster={rltMaster} />
                    : <Percentual rltMaster={rltMaster} />;

            case 'periodo':
                return null;

            case 'referencia':
                return null;

            default:
                return null;
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title="Relatório Master" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <h5>Filtro</h5>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();

                                        getRltMaster(filter);
                                    }}
                                >
                                    <Row>
                                        <Col lg="6" sm="12">
                                            <Periodo
                                                filter={filter}
                                                handleChangeSelect={(key, value) => {
                                                    setFilter({
                                                        ...filter,
                                                        [key]: format(value, 'yyyy-MM-dd')
                                                    });
                                                }}
                                            />
                                        </Col>

                                        <Col lg="6" sm="12">
                                            <Periodo
                                                filter={filter}
                                                handleChangeSelect={(key, value) => {
                                                    setFilter({
                                                        ...filter,
                                                        [key]: format(value, 'yyyy-MM-dd')
                                                    });
                                                }}
                                                title="Período de Referência"
                                                type="referencia"
                                            />
                                        </Col>

                                        <Col sm="12">
                                            <Opcoes
                                                handleChangeSelect={(key, value) => {
                                                    setFilter({
                                                        ...filter,
                                                        [key]: value
                                                    });
                                                }}
                                                handleChangeExibir={(selected) => {
                                                    setFilter({
                                                        ...filter,
                                                        exibir: selected
                                                    });
                                                }}
                                                handleChangeEmpresas={selected => {
                                                    const itens = [...filter.empresas];
                                                    const contain = itens.includes(selected);
                                                    if (contain) {
                                                        setFilter({
                                                            ...filter,
                                                            empresas: itens.filter(i => i != selected)
                                                        });
                                                    } else {
                                                        itens.push(selected);

                                                        setFilter({
                                                            ...filter,
                                                            empresas: itens
                                                        });
                                                    }
                                                }}
                                                filter={filter}
                                                empresas={empresas}
                                                loadingEmpresas={loadingList}
                                            />
                                        </Col>

                                        <Col sm="12">
                                            <TabOptions
                                                listContas={listContas}
                                                loadingListContas={loadingListContas}
                                                listCentroCusto={listCentroCusto}
                                                loadingListCentroCusto={loadingListCentroCusto}
                                                listPessoas={listPessoas}
                                                loadingListPessoas={loadingListPessoas}
                                                getListContas={getListContas}
                                                getListPessoas={getListPessoas}
                                                getListCentroCusto={getListCentroCusto}
                                                handleChangeSelect={(key, value) => {
                                                    setFilter({
                                                        ...filter,
                                                        [key]: value
                                                    });
                                                }}
                                            />
                                        </Col>

                                        <Col sm="12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <FormGroup>
                                                <Button
                                                    disabled={loadingRltMaster}
                                                    title="Pesquisar"
                                                    className="m-t-30"
                                                    color="primary"
                                                >
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm="12">
                        {loadingRltMaster
                            ? (
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                            )
                            : (
                                <>
                                    {!!Object.keys(rltMaster).length && (
                                        <Card>
                                            <CardBody>
                                                <div className="m-b-10 d-flex justify-content-end">
                                                    <Button size="sm" onClick={handlePrint} className="f-16">
                                                        <i className="fa fa-print"></i>
                                                        <span className="p-l-5">Imprimir</span>
                                                    </Button>
                                                </div>


                                                <PrintComponent
                                                    ref={printRef}
                                                >
                                                    {renderRelatorio()}
                                                </PrintComponent>
                                            </CardBody>
                                        </Card>
                                    )}
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ RltGerenciais, Empresas, Contas, CentroCusto, Pessoas }) => {
    const { loadingRltMaster, rltMaster } = RltGerenciais;
    const { list: { data }, loadingList } = Empresas;
    const { list, loadingList: loadingListContas } = Contas;
    const { list: listCentroCusto, loadingList: loadingListCentroCusto } = CentroCusto;
    const { list: listPessoas, loadingList: loadingListPessoas } = Pessoas;

    return {
        loadingRltMaster,
        rltMaster,
        empresas: data,
        loadingList,
        loadingListContas,
        listContas: list,
        listCentroCusto,
        loadingListCentroCusto,
        listPessoas,
        loadingListPessoas
    };
}

export default connect(mapStateToProps, {
    getRltMaster,
    getListEmpresas,
    getListContas,
    getListCentroCusto,
    getListPessoas
})(Master);