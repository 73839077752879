import React from "react";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";

const DadosGerais = ({ pessoa }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Dados Gerais</h6></Label>
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input
                                    name="pss_nm_pessoa"
                                    placeholder="Informe o nome"
                                    defaultValue={pessoa.pss_nm_pessoa}
                                    readOnly
                                    maxLength={60}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Código</Label>
                                <Input
                                    name="pss_cd_pessoa"
                                    placeholder="Informe o código"
                                    defaultValue={pessoa.pss_cd_pessoa}
                                    maxLength={5}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Tipo</Label>
                                <Input
                                    name="pss_cd_pessoa"
                                    placeholder="Informe o código"
                                    defaultValue={!!pessoa.tipo ? pessoa.tipo.tps_nm_tipo : 'Não Informado'}
                                    maxLength={5}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Número Identificação</Label>
                                <Input
                                    name="pss_nu_identificacao"
                                    placeholder="Informe o Número de Identificação"
                                    readOnly
                                    value={pessoa.pss_nu_identificacao}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Conta Contábil</Label>
                                <Input
                                    name="pss_cd_conta_contabil"
                                    readOnly
                                    maxLength={15}
                                    placeholder="Informe a Conta Contábil"
                                    defaultValue={!!pessoa.pss_cd_conta_contabil ? pessoa.pss_cd_conta_contabil : "Não Informado"}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

export default DadosGerais;