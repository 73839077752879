import React, { useState, useEffect, useCallback } from 'react';
import { ListGroup, ListGroupItem, Input, Alert, Row, Col, Label } from "reactstrap";
import PropTypes from "prop-types";
import ScrollArea from "react-scrollbar";
import { useRef } from 'react';


const ListSelect = ({
    data,
    isLoading,
    onChange,
    pagination = false,
    totalResults = 0,
    selected,
    onPaginate,
    emptyLabel = "Nenhum Registro",
    containerStyle = {},
    onSearch
}) => {
    const [list, setList] = useState(
        {
            items: [],
            total: 0
        }
    );
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const cacheItems = useRef([]);
    const cacheTotal = useRef(0);
    const scrollRef = useRef();

    //ADICIONA INFORMAÇÕES AO ESTADO E ARMAZENA VALORES NA REF CACHE
    useEffect(() => {
        setList({ items: data, total: totalResults });
        data.forEach(element => {
            if (!cacheItems.current.find((cache) => (cache.value === element.value))) {
                cacheItems.current.push(element);
            }
        });
        if (totalResults > cacheTotal.current) {
            cacheTotal.current = totalResults;
        }
    }, [data, totalResults]);

    //CASO EXISTA PROP SELECTED MARCA CAMPOS;
    useEffect(() => {
        !!selected && setSelectedItems(selected(cacheItems.current));
    }, [selected]);

    const handleSelectedItems = (item) => {
        const sItems = [...selectedItems];
        const index = sItems.findIndex((el) => el.value === item.value);
        if (index !== -1) {
            sItems.splice(index, 1);
        } else {
            sItems.push(item);
        }
        setSelectedItems(sItems);
        onChange(sItems);
    }

    const handleSelectAll = e => {
        const sItems = [...selectedItems];
        const { checked } = e.target;
        if (checked) {
            list.items.forEach((el) => {
                if (!sItems.find((sEL) => sEL.value === el.value)) {
                    sItems.push({ name: el.name, value: el.value });
                }
            });
        } else {
            sItems.splice(0, sItems.length);
        }
        setSelectedItems(sItems)
        onChange(sItems)
    }

    //FUNÇÃO SERÁ CHAMADA CASO O INPUT DE PESQUISA FOR ULTILIZADO
    const onChangeInputSearch = useCallback(({ target: { value } }) => {
        scrollRef.current.scrollArea.scrollTop();
        if (!value.length) {
            setList({
                items: cacheItems.current,
                total: cacheTotal.current
            });
        }
        onSearch(value, cacheItems.current);
        setSearchValue(value);
        setSelectedItems([])
    }, [setSearchValue, setList, setSelectedItems]);

    const filterItems = list.items.filter(({ name }) => new RegExp(`${searchValue}`, 'i').test(name));

    return (
        <Row>
            <Col xs="12 m-b-5">
                <Row>
                    <Col lg="3" sm="12">
                        <Label className="d-block m-b-0 m-t-10" for="chk-ani">
                            <Input
                                className="checkbox_animated"
                                onChange={handleSelectAll}
                                checked={selectedItems.length === filterItems.length}
                                disabled={!filterItems.length}
                                id="chk-ani"
                                type="checkbox" />Selecionar Tudo
                    </Label>
                    </Col>
                    <Col lg="9" sm="12">
                        <Input
                            name="search"
                            placeholder="Pesquisar..."
                            onChange={onChangeInputSearch}
                            type="text" />
                    </Col>
                </Row>

            </Col>
            <Col xs="12">
                {isLoading &&
                    <div style={{ zIndex: 2, position: 'absolute', left: 0, right: 0 }} className="loader-box">
                        <div className="loader-1"></div>
                    </div>
                }
                <ScrollArea ref={scrollRef} horizontal={false} style={containerStyle}>
                    <ListGroup>
                        {!!filterItems.length ?
                            <>
                                {filterItems.map((el, key) => {
                                    return (
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            disabled={isLoading}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleSelectedItems(el)}
                                            key={key}
                                        >
                                            <div>
                                                <Input
                                                    className="checkbox_animated"
                                                    checked={!!selectedItems.find((cItem) => cItem.value === el.value)}
                                                    onChange={() => { }}
                                                    id={`chk-ani${key}`}
                                                    type="checkbox" />
                                                <span>{`${el.name}`}</span>
                                            </div>
                                        </ListGroupItem>
                                    )
                                })}
                                {(!searchValue && !!list.items.length && pagination && !isLoading && list.items.length < list.total) &&
                                    <ListGroupItem
                                        className="list-group-item-action d-flex justify-content-center"
                                        onClick={() => {
                                            onPaginate(list.items.length, searchValue);
                                        }}
                                        style={{ cursor: 'pointer', borderWidth: 0 }}
                                    >
                                        <span className="font-primary">Carregar Mais</span>
                                    </ListGroupItem>
                                }
                            </>
                            :
                            !isLoading &&
                            <Alert className="d-flex justify-content-center" color="light">{emptyLabel}</Alert>
                        }
                    </ListGroup>
                </ScrollArea>
            </Col>
        </Row>

    )
}

ListSelect.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.func,
    pagination: PropTypes.bool,
    onPaginate: PropTypes.func,
    emptyLabel: PropTypes.string,
    containerStyle: PropTypes.object,
    onSearch: PropTypes.func.isRequired

}

export default React.memo(ListSelect);