import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap";
import SelectPagination from "../../../../components/SelectPagination";
import { getListContas, getListContaPessoas } from "../../../../redux/actions";

const Conta = ({
    listContas,
    loadingListContas,
    getListContas,
    getListContaPessoas,
    listContaPessoas,
    loadingListCtnPessoa,
    validFields,
    handleChangeSelect,
    handleChange,
    validator,
    fields,
}) => {
    const optionsContas = useMemo(() => {
        const fillContas = listContas.data.filter(({ ctn_st_lanc_manual }) => ctn_st_lanc_manual === "S")
        return fillContas.map(({
            id,
            ctn_cd_conta,
            ctn_nm_conta,
            ctn_vl_minimo_lan,
            ctn_vl_maximo_lan,
            ctn_ds_hist_padrao,
            pessoa,
            ctn_st_lan_deb_cre
        }) =>
        ({
            name: `${ctn_cd_conta} - ${ctn_nm_conta}`,
            value: id,
            ctn_nm_conta,
            ctn_cd_conta,
            ctn_vl_minimo_lan,
            ctn_vl_maximo_lan,
            ctn_ds_hist_padrao,
            def_pessoa: pessoa,
            ctn_st_lan_deb_cre,
        }))
    }, [listContas.data]);

    const optionsPessoas = useMemo(() => {
        const res = [];
        if (!listContaPessoas.data.find((pessoa) => pessoa.pss_cd_pessoa === fields.cnt_cd_subconta) && !!fields.pessoa.value) {
            res.push({
                value: fields.pessoa.value,
                name: `${fields.pessoa.pss_cd_pessoa} - ${fields.pessoa.pss_nm_pessoa} - ${fields.pessoa.pss_nu_identificacao}`,
                pss_nm_pessoa: fields.pessoa.pss_nm_pessoa,
                pss_cd_pessoa: fields.pessoa.pss_cd_pessoa,
                pss_nu_identificacao: fields.pessoa.pss_nu_identificacao,
                subItem: `Num.Identificação: ${fields.pessoa.pss_nu_identificacao}`,
            });

        }
        res.push(...listContaPessoas.data.map(({ id, pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao }) => ({
            value: id,
            name: `${pss_cd_pessoa} - ${pss_nm_pessoa} - ${pss_nu_identificacao}`,
            pss_cd_pessoa,
            pss_nm_pessoa,
            pss_nu_identificacao,
            subItem: `Num.Identificação: ${pss_nu_identificacao}`,
        })))

        return res;
    },[listContaPessoas.data]);
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Conta*</Label>
                                <SelectPagination
                                    id="selectConta"
                                    clearButton
                                    labelKey={(option) => `${option.name}`}
                                    placeholder="Informe a Conta"
                                    onBlur={() => validFields('cnt_cd_conta')}
                                    maxResults={50}
                                    isLoading={loadingListContas}
                                    totalResults={listContas.total}
                                    data={optionsContas}
                                    reduxAction={getListContas}
                                    onChange={(selected) => handleChangeSelect('cnt_cd_conta', selected)}
                                    isInvalid={
                                        validator.visibleFields.includes('cnt_cd_conta') ||
                                        (!validator.fields.cnt_cd_conta && validator.messagesShown)
                                    }
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('cnt_cd_conta') ||
                                            (!validator.fields.cnt_cd_conta && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('cnt_cd_conta', fields.cnt_cd_conta, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        {!!Object.keys(fields.conta).length &&
                            <>
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Valor Mínimo</Label>
                                        <Input
                                            defaultValue={!!fields.conta?.ctn_vl_minimo_lan ?
                                                new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(fields.conta.ctn_vl_minimo_lan)
                                                :
                                                'Não Informado'
                                            }
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Valor Máximo</Label>
                                        <Input
                                            defaultValue={!!fields.conta?.ctn_vl_maximo_lan ?
                                                new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(fields.conta.ctn_vl_maximo_lan)
                                                :
                                                'Não Informado'
                                            }
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label>Histórico</Label>
                                        <Input
                                            placeholder="Informe a Descrição Histórico"
                                            name="cnt_ds_historico"
                                            defaultValue={fields.conta.ctn_ds_hist_padrao}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label>Pessoa*</Label>
                                        <SelectPagination
                                            id="selectPessoa"
                                            clearButton
                                            labelKey={(option) => `${option.name}`}
                                            placeholder="Informe a Pessoa"
                                            onBlur={() => validFields('cnt_cd_subconta')}
                                            maxResults={50}
                                            isLoading={loadingListCtnPessoa}
                                            totalResults={listContaPessoas.total}
                                            useCache={false}
                                            selected={(options) => options.filter((option) => option.pss_cd_pessoa === fields.cnt_cd_subconta)}
                                            data={optionsPessoas}
                                            reduxAction={(obj) => {
                                                if (!!obj) {
                                                    getListContaPessoas({ ...obj, conta: fields.cnt_cd_conta })
                                                } else {
                                                    getListContaPessoas({ conta: fields.cnt_cd_conta })
                                                }

                                            }}
                                            onChange={(selected) => handleChangeSelect('cnt_cd_subconta', selected)}
                                            isInvalid={
                                                validator.visibleFields.includes('cnt_cd_subconta') ||
                                                (!validator.fields.cnt_cd_subconta && validator.messagesShown)
                                            }
                                        />
                                        <FormFeedback
                                            style={{
                                                display: validator.visibleFields.includes('cnt_cd_subconta') ||
                                                    (!validator.fields.cnt_cd_subconta && validator.messagesShown)
                                                    ? 'block' : 'none'
                                            }}>
                                            {validator.message('cnt_cd_subconta', fields.cnt_cd_subconta, 'required')}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>

                            </>
                        }
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, loadingList: loadingListContas, listContaPessoas, loadingListCtnPessoa } = Contas;
    return { listContas, loadingListContas, listContaPessoas, loadingListCtnPessoa }
}

export default connect(mapStateToProps, {
    getListContas,
    getListContaPessoas
})(Conta);