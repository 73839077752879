import React, { Fragment } from 'react'
import {
    Table
} from 'reactstrap';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

function Mensal({
    rltMaster
}) {
    const { lancamentos, fields, qtdEmpresas } = rltMaster;

    const ordenadorExibir = {
        GruposConta: 1,
        Contas: 2,
        Pessoas: 3,
        Cct: 4,
    };

    const orderDistance = fields.exibir
        .map(item => ({
            value: ordenadorExibir[item],
            label: item
        }))
        .sort((a, b) => {
            return a.value - b.value;
        })
        .map(({ label }, index) => ({
            label,
            value: index + 1
        }));

    const leftDistance = orderDistance
        .reduce((prev, { label, value }) => {
            prev[label] = value;

            return prev;
        }, {});

    const calcTotalList = list => list.reduce((prev, current) => prev + parseFloat(current.total), 0);
    const calcMedia = total => total / qtdEmpresas;
    const getPercentualValue = num => parseFloat(num.toFixed(2)).toLocaleString();
    const calcPercent = (a, b) => getPercentualValue(a / b * 100);

    const optionsIndice = [
        {
            name: '(Nenhum Índice)',
            value: 'nenhum'
        }
    ];

    const getOrderObject = option => orderDistance.find(({ label }) => label == option);
    const getOrderObjectIndex = option => orderDistance.find(({ value }) => value == option);

    const mountList = tipo => {

        const list = lancamentos.filter(l => l.grc_cd_grupo_pai == tipo);
        const totalGeral = calcTotalList(list);

        const grupos = [...new Set(list.map(({ grc_cd_grupo, grc_nm_grupo }) => ({
            grc_cd_grupo,
            grc_nm_grupo
        })).map(JSON.stringify))].map(JSON.parse);

        return grupos.map(
            ({ grc_nm_grupo, grc_cd_grupo }, index) => {
                const listByGrupo = list.filter(
                    ({ grc_cd_grupo: grupo_cd, grc_nm_grupo: grupo_nm }) =>
                        grupo_cd == grc_cd_grupo &&
                        grupo_nm == grc_nm_grupo
                );

                const totalGruposConta = calcTotalList(listByGrupo);

                const contas = [
                    ...new Set(listByGrupo
                        .map(
                            ({ ctn_cd_conta, ctn_nm_conta }) => ({
                                ctn_cd_conta,
                                ctn_nm_conta,
                            })
                        )
                        .map(JSON.stringify)
                    )
                ].map(JSON.parse);

                return (
                    <Fragment key={index}>
                        {fields.exibir.includes('GruposConta') && (
                            <tr>
                                <td>
                                    <div
                                        style={{
                                            marginLeft: 10 * leftDistance['GruposConta'] || 1,
                                            color: 'darkblue'
                                        }}
                                    >
                                        {grc_nm_grupo}
                                    </div>
                                </td>
                                <td className="text-center">
                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(totalGruposConta)}
                                </td>
                                <td className="text-center">
                                    {calcPercent(totalGruposConta, totalGeral)}%
                                </td>
                                <td className="text-center">
                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(calcMedia(totalGruposConta))}
                                </td>
                            </tr>
                        )}

                        {contas.map(
                            ({ ctn_cd_conta, ctn_nm_conta }, contaKey) => {
                                const listContas = listByGrupo
                                    .filter(
                                        (l) => ctn_nm_conta == l.ctn_nm_conta
                                            && ctn_cd_conta == l.ctn_cd_conta
                                            && grc_nm_grupo == l.grc_nm_grupo
                                            && grc_cd_grupo == l.grc_cd_grupo
                                    );

                                const pessoas = [
                                    ...new Set(listContas
                                        .map(
                                            ({ pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao }) => ({
                                                pss_cd_pessoa,
                                                pss_nm_pessoa,
                                                pss_nu_identificacao
                                            })
                                        )
                                        .map(JSON.stringify)
                                    )
                                ].map(JSON.parse);

                                const totalContas = calcTotalList(listContas);

                                let mediaContas = calcPercent(totalContas, totalGeral);
                                const contaMedia = getOrderObject('Contas');
                                if (contaMedia?.value > 1) {
                                    mediaContas = calcPercent(totalContas, totalGruposConta);
                                }

                                return (
                                    <Fragment key={contaKey}>

                                        {fields.exibir.includes('Contas') && (
                                            <tr>
                                                <td>
                                                    <div
                                                        style={{
                                                            marginLeft: 10 * leftDistance['Contas'] || 1
                                                        }}
                                                    >
                                                        {ctn_cd_conta} - {ctn_nm_conta}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(totalContas)}
                                                </td>
                                                <td className="text-center">
                                                    {mediaContas}%
                                                </td>
                                                <td className="text-center">
                                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(calcMedia(totalContas))}
                                                </td>
                                            </tr>
                                        )}

                                        {pessoas.map(
                                            ({ pss_cd_pessoa, pss_nm_pessoa, pss_nu_identificacao }, pessoaKey) => {

                                                const listPessoas = listByGrupo
                                                    .filter(
                                                        (l) => ctn_nm_conta == l.ctn_nm_conta
                                                            && ctn_cd_conta == l.ctn_cd_conta
                                                            && grc_nm_grupo == l.grc_nm_grupo
                                                            && grc_cd_grupo == l.grc_cd_grupo
                                                            && pss_cd_pessoa == l.pss_cd_pessoa
                                                            && pss_nm_pessoa == l.pss_nm_pessoa
                                                            && pss_nu_identificacao == l.pss_nu_identificacao
                                                    );

                                                const centros = [
                                                    ...new Set(listPessoas
                                                        .map(
                                                            ({ cct_cd_centro, cct_nm_centro }) => ({
                                                                cct_cd_centro,
                                                                cct_nm_centro
                                                            })
                                                        )
                                                        .map(JSON.stringify)
                                                    )
                                                ].map(JSON.parse);

                                                const totalPessoas = calcTotalList(listPessoas);

                                                let mediaPessoas = calcPercent(totalPessoas, totalGeral);
                                                const contaMedia = getOrderObject('Pessoas');
                                                if (contaMedia?.value > 1) {
                                                    switch (getOrderObjectIndex(contaMedia?.value - 1)?.label) {
                                                        case 'GruposConta':
                                                            mediaPessoas = calcPercent(totalPessoas, totalGruposConta);
                                                            break;

                                                        case 'Contas':
                                                            mediaPessoas = calcPercent(totalPessoas, totalContas);
                                                            break;

                                                        default:
                                                            break;
                                                    }
                                                }

                                                return (
                                                    <Fragment key={pessoaKey}>

                                                        {fields.exibir.includes('Pessoas') && (
                                                            <tr>
                                                                <td>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: 10 * leftDistance['Pessoas'] || 1,
                                                                            color: 'darkred'
                                                                        }}
                                                                    >
                                                                        {pss_cd_pessoa} - {pss_nm_pessoa}
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(totalPessoas)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {mediaPessoas}%
                                                                </td>
                                                                <td className="text-center">
                                                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(calcMedia(totalPessoas))}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {centros.map(
                                                            ({ cct_cd_centro, cct_nm_centro }, centrosKey) => {
                                                                const listCentros = listByGrupo
                                                                    .filter(
                                                                        (l) => ctn_nm_conta == l.ctn_nm_conta
                                                                            && ctn_cd_conta == l.ctn_cd_conta
                                                                            && grc_nm_grupo == l.grc_nm_grupo
                                                                            && grc_cd_grupo == l.grc_cd_grupo
                                                                            && pss_cd_pessoa == l.pss_cd_pessoa
                                                                            && pss_nm_pessoa == l.pss_nm_pessoa
                                                                            && pss_nu_identificacao == l.pss_nu_identificacao
                                                                            && cct_cd_centro == l.cct_cd_centro
                                                                            && cct_nm_centro == l.cct_nm_centro
                                                                    );

                                                                const totalCct = calcTotalList(listCentros);

                                                                let mediaCct = calcPercent(totalCct, totalGeral);
                                                                const contaMedia = getOrderObject('Cct');
                                                                if (contaMedia?.value > 1) {
                                                                    switch (getOrderObjectIndex(contaMedia?.value - 1)?.label) {
                                                                        case 'GruposConta':
                                                                            mediaCct = calcPercent(totalCct, totalGruposConta);
                                                                            break;

                                                                        case 'Contas':
                                                                            mediaCct = calcPercent(totalCct, totalContas);
                                                                            break;

                                                                        case 'Pessoas':
                                                                            mediaCct = calcPercent(totalCct, totalPessoas);
                                                                            break;

                                                                        default:
                                                                            break;
                                                                    }
                                                                }

                                                                return (
                                                                    <Fragment key={centrosKey}>

                                                                        {fields.exibir.includes('Cct') && (
                                                                            <tr>
                                                                                <td>
                                                                                    <div
                                                                                        style={{
                                                                                            marginLeft: 10 * leftDistance['Cct'] || 1
                                                                                        }}
                                                                                    >
                                                                                        {cct_cd_centro} - {cct_nm_centro}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(totalCct)}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {mediaCct}%
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(calcMedia(totalCct))}
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                    </Fragment>
                                                                )
                                                            }
                                                        )}
                                                    </Fragment>
                                                )
                                            }
                                        )}
                                    </Fragment>
                                )
                            }
                        )}
                    </Fragment>
                )
            }
        )

    }

    const receitas = lancamentos.filter(l => l.grc_cd_grupo_pai == 3);
    const despesas = lancamentos.filter(l => l.grc_cd_grupo_pai == 4);

    const totalReceitas = calcTotalList(receitas);
    const totalDespesas = calcTotalList(despesas);
    const resultado = totalReceitas - totalDespesas;
    const resultadoMedia = calcMedia(totalReceitas) - calcMedia(totalDespesas);

    const periodoIni = format(parseISO(fields?.periodoInicial), "EEE',' dd/MM/yyyy", {
        locale: pt
    }).split('').map((v, i) => i > 0 ? v : v.toUpperCase()).join('');

    const periodoFim = format(parseISO(fields?.periodoFinal), "EEE',' dd/MM/yyyy", {
        locale: pt
    }).split('').map((v, i) => i > 0 ? v : v.toUpperCase()).join('');

    return (
        <div className="p-15 font-arial-rlt">
            <p>Master - Mensal/Período/Referência, por Caixa, de {periodoIni} a {periodoFim}</p>

            <p>Índice: {optionsIndice.find(({ value }) => value == fields?.indice)?.name}</p>

            <Table bordered>
                <thead>
                    <tr>
                        <th>Grupo/Conta/Pessoa/Centro de Custo</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">%</th>
                        <th className="text-center">Média</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <th>TOTAL DESPESAS</th>
                        <td className="text-center">
                            {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(totalDespesas)}
                        </td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(calcMedia(totalDespesas))}
                        </td>
                    </tr>

                    {mountList(4)}

                    <tr>
                        <th>TOTAL RECEITAS</th>
                        <td className="text-center">
                            {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(totalReceitas)}
                        </td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(calcMedia(totalReceitas))}
                        </td>
                    </tr>

                    {mountList(3)}

                    <tr>
                        <th>Resultado</th>
                        <td className={`text-center ${resultado > 0 ? 'text-success' : 'text-danger'}`}>
                            {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(resultado)}
                        </td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(resultadoMedia)}
                        </td>
                    </tr>

                    <tr>
                        <th>% da Receita</th>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default Mensal;