/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ADD_SIDEBAR_BACKGROUND = "ADD_SIDEBAR_BACKGROUND";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

//AUTH
export const LOGIN_USER_ASYNC = "LOGIN_USER_ASYNC";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER_ASYNC = "LOGOUT_USER_ASYNC";
export const LOGOUT_USER = "LOGOUT_USER";
export const SWITCH_USER_EMPRESA_ASYNC = "SWITCH_USER_EMPRESA_ASYNC";
export const SWITCH_USER_EMPRESA = "SWITCH_USER_EMPRESA";
export const UPDATE_USER_EMPRESA = "UPDATE_USER_EMPRESA";

//USERS
export const CLEAR_ERRORS_USER = "CLEAR_ERRORS_USER";
export const REGISTER_USER_ASYNC = "REGISTER_USER_ASYNC";
export const REGISTER_USER = "REGISTER_USER";
export const GET_USERS_ASYNC = "GET_USERS_ASYNC";
export const GET_USERS = "GET_USERS";
export const CHANGE_STATUS_USER_ASYNC = "CHANGE_STATUS_USER_ASYNC";
export const CHANGE_STATUS_USER = "CHANGE_STATUS_USER";
export const DESTROY_USERS_ASYNC = "DESTROY_USERS_ASYNC";
export const DESTROY_USERS = "DESTROY_USERS";
export const STORE_USERS_ASYNC = "STORE_USERS_ASYNC";
export const STORE_USERS = "STORE_USERS";
export const UPDATE_USERS_ASYNC = "UPDATE_USERS_ASYNC";
export const UPDATE_USERS = "UPDATE_USERS";

//DEPARTAMENTOS
export const GET_LIST_DEPARTAMENTOS_ASYNC = "GET_LIST_DEPARTAMENTOS_ASYNC";
export const GET_LIST_DEPARTAMENTOS = "GET_LIST_DEPARTAMENTOS";

//ESTADOS
export const GET_LIST_ESTADOS_ASYNC = "GET_LIST_ESTADOS_ASYNC";
export const GET_LIST_ESTADOS = "GET_LIST_ESTADOS";

//CIDADES
export const GET_LIST_CIDADES_ASYNC = "GET_LIST_CIDADES_ASYNC";
export const GET_LIST_CIDADES = "GET_LIST_CIDADES";

//PESSOAS
export const GET_LIST_PESSOAS_ASYNC = "GET_LIST_PESSOAS_ASYNC";
export const GET_LIST_PESSOAS = "GET_LIST_PESSOAS";
export const GET_PESSOAS_ASYNC = "GET_PESSOAS_ASYNC";
export const GET_PESSOAS = "GET_PESSOAS";
export const STORE_PESSOAS_ASYNC = "STORE_PESSOAS_ASYNC";
export const STORE_PESSOAS = "STORE_PESSOAS";
export const DESTROY_PESSOAS_ASYNC = "DESTROY_PESSOAS_ASYNC";
export const DESTROY_PESSOAS = "DESTROY_PESSOAS";
export const UPDATE_PESSOAS_ASYNC = "UPDATE_PESSOAS_ASYNC";
export const UPDATE_PESSOAS = "UPDATE_PESSOAS";
export const GET_LIST_PESSOAS_EMPRESA_ASYNC = "GET_LIST_PESSOAS_EMPRESA_ASYNC";
export const GET_LIST_PESSOAS_EMPRESA = "GET_LIST_PESSOAS_EMPRESA";

//TIPO PESSOAS
export const GET_LIST_TIPO_PESSOAS_ASYNC = "GET_LIST_TIPO_PESSOAS_ASYNC";
export const GET_LIST_TIPO_PESSOAS = "GET_LIST_TIPO_PESSOAS";
export const GET_TIPOS_PESSOA_ASYNC = "GET_TIPOS_PESSOA_ASYNC";
export const GET_TIPOS_PESSOA = "GET_TIPOS_PESSOA";
export const STORE_TIPO_PESSOA_ASYNC = "STORE_TIPO_PESSOA_ASYNC";
export const STORE_TIPO_PESSOA = "STORE_TIPO_PESSOA";
export const UPDATE_TIPO_PESSOA_ASYNC = "UPDATE_TIPO_PESSOA_ASYNC";
export const UPDATE_TIPO_PESSOA = "UPDATE_TIPO_PESSOA";
export const DESTROY_TIPOS_PESSOA_ASYNC = "DESTROY_TIPOS_PESSOA_ASYNC";
export const DESTROY_TIPOS_PESSOA = "DESTROY_TIPOS_PESSOA";

//CONTAS
export const GET_CONTAS_ASYNC = "GET_PLANO_CONTAS_ASYNC";
export const GET_CONTAS = "GET_CONTAS";
export const STORE_CONTAS_ASYNC = "STORE_CONTAS_ASYNC";
export const STORE_CONTAS = "STORE_CONTAS";
export const UPDATE_CONTAS_ASYNC = "UPDATE_CONTAS_ASYNC";
export const UPDATE_CONTAS = "UPDATE_CONTAS";
export const DESTROY_CONTAS_ASYNC = "DESTROY_CONTAS_ASYNC";
export const DESTROY_CONTAS = "DESTROY_CONTAS";
export const GET_LIST_CONTAS_ASYNC = "GET_LIST_CONTAS_ASYNC";
export const GET_LIST_CONTAS = "GET_LIST_CONTAS";
export const GET_RELATORIO_GERENCIA_CONTAS_ASNYC = "GET_RELATORIO_GERENCIA_CONTAS_ASNYC";
export const GET_RELATORIO_GERENCIA_CONTAS = "GET_RELATORIO_GERENCIA_CONTAS";
export const GET_LIST_CONTAS_BANCOS_ASYNC = "GET_LIST_CONTAS_BANCOS_ASYNC";
export const GET_LIST_CONTAS_BANCOS = "GET_LIST_CONTAS_BANCOS";
export const GET_LIST_CONTA_PESSOAS_ASYNC = "GET_LIST_CONTA_PESSOAS_ASYNC";
export const GET_LIST_CONTA_PESSOAS = "GET_LIST_CONTA_PESSOAS";
export const GET_LIST_CONTAS_EMPRESA_ASYNC = "GET_LIST_CONTAS_EMPRESA_ASYNC";
export const GET_LIST_CONTAS_EMPRESA = "GET_LIST_CONTAS_EMPRESA";
export const GET_SALDOS_CONTA_ASYNC = "GET_SALDOS_CONTA_ASYNC";
export const GET_SALDOS_CONTA = "GET_SALDOS_CONTA";

//RELATÓRIOS GERENCIAIS
// --- MOVIMENTAÇÃO ---
export const GET_RLT_MOVIMENTACAO_ASYNC = "GET_RLT_MOVIMENTACAO_ASYNC";
export const GET_RLT_MOVIMENTACAO = "GET_RLT_MOVIMENTACAO";
export const CLEAR_RLT_MOVIMENTACAO = "CLEAR_RLT_MOVIMENTACAO";
//--- PLANO DE CONTAS ---
export const GET_RLT_PLANO_CONTAS_ASYNC = "GET_RLT_PLANO_CONTAS_ASYNC";
export const GET_RLT_PLANO_CONTAS = "GET_RLT_PLANO_CONTAS";
// --- MASTER ---
export const GET_RLT_MASTER_ASYNC = "GET_RLT_MASTER_ASYNC";
export const GET_RLT_MASTER = "GET_RLT_MASTER";
// --- FLUXO DE CAIXA
export const GET_RLT_FLUXO_CAIXA_ASYNC = "GET_RLT_FLUXO_CAIXA_ASYNC";
export const GET_RLT_FLUXO_CAIXA = "GET_RLT_FLUXO_CAIXA";

//RELATÓRIOS OPERACIONAIS

//--- EXTRATO DE CONTAS ---
export const GET_RLT_EXTRATO_CONTAS_ASYNC = "GET_RLT_EXTRATO_CONTAS_ASYNC";
export const GET_RLT_EXTRATO_CONTAS = "GET_RLT_EXTRATO_CONTAS";
export const CLEAR_RLT_EXTRATO_CONTAS = "CLEAR_RLT_EXTRATO_CONTAS";

//--- EXTRATO DE PESSOAS ---
export const GET_RLT_EXTRATO_PESSOAS_ASYNC = "GET_RLT_EXTRATO_PESSOAS_ASYNC";
export const GET_RLT_EXTRATO_PESSOAS = "GET_RLT_EXTRATO_PESSOAS";

//--- NÃO RECEBIDOS ---
export const GET_RTL_NAO_RECEBIDOS_ASYNC = "GET_RTL_NAO_RECEBIDOS_ASYNC";
export const GET_RTL_NAO_RECEBIDOS = "GET_RTL_NAO_RECEBIDOS";

//--- NÃO PAGOS ---
export const GET_RLT_NAO_PAGOS_ASYNC = "GET_RLT_NAO_PAGOS_ASYNC";
export const GET_RLT_NAO_PAGOS = "GET_RLT_NAO_PAGOS";
export const CLEAR_RLT_NA0_PAGOS = "CLEAR_RLT_NA0_PAGOS";

//--- PESQUISA ---
export const GET_RLT_PESQUISA_ASYNC = "GET_RLT_PESQUISA_ASYNC";
export const GET_RLT_PESQUISA = "GET_RLT_PESQUISA";
export const CLEAR_RLT_PESQUISA = "CLEAR_RLT_PESQUISA";

//--CRÉDITO E DÉBITO
export const GET_RLT_DEB_CRED_ASYNC = "GET_RLT_DEB_CRED_ASYNC";
export const GET_RLT_DEB_CRED = "GET_RLT_DEB_CRED";

//--CONTRAPARTIDAS
export const GET_RLT_CNT_ASYNC = "GET_RLT_CNT_ASYNC";
export const GET_RLT_CNT = "GET_RLT_CNT";

//--TITULOS NÃO PAGOS / TITULOS NÃO PAGOS
export const GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC = "GET_RLT_TITULOS_NAO_RECPAGOS_ASYNC";
export const GET_RLT_TITULOS_NAO_RECPAGOS = "GET_RLT_TITULOS_NAO_RECPAGOS";
export const CLEAR_RLT_TITULOS_NAO_RECPAGOS = "CLEAR_RLT_TITULOS_NAO_RECPAGOS";

//GRUPOS CONTAS
export const CLEAR_ERRORS_CONTAS_GRUPOS_CONTAS = "CLEAR_ERRORS_CONTAS_GRUPOS_CONTAS";
export const GET_CONTAS_GRUPOS_ASYNC = "GET_CONTAS_GRUPOS_ASYNC";
export const GET_CONTAS_GRUPOS = "GET_CONTAS_GRUPOS";
export const STORE_CONTAS_GRUPOS_ASYNC = "STORE_CONTAS_GRUPOS_ASYNC";
export const STORE_CONTAS_GRUPOS = "STORE_CONTAS_GRUPOS";
export const UPDATE_CONTAS_GRUPOS_ASYNC = "UPDATE_CONTAS_GRUPOS_ASYNC";
export const UPDATE_CONTAS_GRUPOS = "UPDATE_CONTAS_GRUPOS";
export const DESTROY_CONTAS_GRUPOS_ASYNC = "DESTROY_CONTAS_GRUPOS_ASYNC";
export const DESTROY_CONTAS_GRUPOS = "DESTROY_CONTAS_GRUPOS";
export const GET_LIST_CONTAS_GRUPOS_ASYNC = "GET_LIST_CONTAS_GRUPOS_ASYNC";
export const GET_LIST_CONTAS_GRUPOS = "GET_LIST_CONTAS_GRUPOS";
export const GET_LIST_GRC_CONTA_EMP_ASYNC = "GET_LIST_GRC_CONTA_EMP_ASYNC";
export const GET_LIST_GRC_CONTA_EMP = "GET_LIST_GRC_CONTA_EMP";

//TIPOS MOV PRODUTOS
export const GET_TIPOS_MOV_PRODUTOS_ASYNC = "GET_TIPOS_MOV_PRODUTOS_ASYNC";
export const GET_TIPOS_MOV_PRODUTOS = "GET_TIPOS_MOV_PRODUTOS";
export const STORE_TIPOS_MOV_PRODUTOS_ASYNC = "STORE_TIPOS_MOV_PRODUTOS_ASYNC";
export const STORE_TIPOS_MOV_PRODUTOS = "STORE_TIPOS_MOV_PRODUTOS";
export const DESTROY_TIPOS_MOV_PRODUTOS_ASYNC = "DESTROY_TIPOS_MOV_PRODUTOS_ASYNC";
export const DESTROY_TIPOS_MOV_PRODUTOS = "DESTROY_TIPOS_MOV_PRODUTOS";
export const UPDATE_TIPOS_MOV_PRODUTOS_ASYNC = "UPDATE_TIPOS_MOV_PRODUTOS_ASYNC";
export const UPDATE_TIPOS_MOV_PRODUTOS = "UPDATE_TIPOS_MOV_PRODUTOS";

//NOTAS FISCAIS
export const GET_NOTAS_FISCAIS_ASYNC = "GET_NOTAS_FISCAIS_ASYNC";
export const GET_NOTAS_FISCAIS = "GET_NOTAS_FISCAIS";
export const UPDATE_DEVOLUCAO_NFE_ASYNC = "UPDATE_DEVOLUCAO_NFE_ASYNC";
export const UPDATE_DEVOLUCAO_NFE = "UPDATE_DEVOLUCAO_NFE";

//FERIADOS
export const GET_FERIADOS_ASYNC = "GET_FERIADOS_ASYNC";
export const GET_FERIADOS = "GET_FERIADOS";
export const STORE_FERIADO_ASYNC = "STORE_FERIADO_ASYNC";
export const STORE_FERIADO = "STORE_FERIADO";
export const UPDATE_FERIADO_ASYNC = "UPDATE_FERIADO_ASYNC";
export const UPDATE_FERIADO = "UPDATE_FERIADO";
export const DESTROY_FERIADOS_ASYNC = "DESTROY_FERIADOS_ASYNC";
export const DESTROY_FERIADOS = "DESTROY_FERIADOS";

//GRUPOS PAI
export const GET_LIST_GRUPOS_PAI_ASYNC = "GET_LIST_GRUPOS_PAI_ASYNC";
export const GET_LIST_CONTAS_GRUPOS_PAI = "GET_LIST_CONTAS_GRUPOS_PAI";

//LANÇAMENTOS
export const GET_LANCAMENTOS_ASYNC = "GET_LANCAMENTOS_ASYNC";
export const GET_LANCAMENTOS = "GET_LANCAMENTOS";
export const STORE_LANCAMENTOS_ASYNC = "STORE_LANCAMENTOS_ASYNC";
export const STORE_LANCAMENTOS = "STORE_LANCAMENTOS";
export const GET_LIST_BY_DATE_LANCAMENTOS_ASYNC = "GET_LIST_BY_DATE_LANCAMENTOS_ASYNC";
export const GET_LIST_BY_DATE_LANCAMENTOS = "GET_LIST_BY_DATE_LANCAMENTOS";
export const UPDATE_LANCAMENTOS_ASYNC = "UPDATE_LANCAMENTOS_ASYNC";
export const UPDATE_LANCAMENTOS = "UPDATE_LANCAMENTOS";
export const DESTROY_LANCAMENTOS_ASYNC = "DESTROY_LANCAMENTOS_ASYNC";
export const DESTROY_LANCAMENTOS = "DESTROY_LANCAMENTOS";
export const FECHAR_LANCAMENTOS_ASYNC = "FECHAR_LANCAMENTOS_ASYNC";
export const FECHAR_LANCAMENTOS = "FECHAR_LANCAMENTOS";
export const GET_LANCAMENTOS_FECHADOS_ASYNC = "GET_LANCAMENTOS_FECHADOS_ASYNC";
export const GET_LANCAMENTOS_FECHADOS = "GET_LANCAMENTOS_FECHADOS";
export const REABIR_LANCAMENTOS_ASYNC = "REABIR_LANCAMENTOS_ASYNC";
export const REABIR_LANCAMENTOS = "REABIR_LANCAMENTOS";

//VLA LANÇAMENTOS
export const GET_RLT_OPERACIONAL_LANCAMENTO_ASYNC = "GET_RLT_OPERACIONAL_LANCAMENTO_ASYNC";
export const GET_RLT_OPERACIONAL_LANCAMENTO = "GET_RLT_OPERACIONAL_LANCAMENTO";
export const CLEAR_RLT_OPERACIONAL_LANCAMENTO = "CLEAR_RLT_OPERACIONAL_LANCAMENTO";

//CONTRAPARTIDAS
export const DESTROY_CONTRAPARTIDA_ASYNC = "DESTROY_CONTRAPARTIDA_ASYNC";
export const DESTROY_CONTRAPARTIDA = "DESTROY_CONTRAPARTIDA";
export const STORE_CONTRAPARTIDA_ASYNC = "STORE_CONTRAPARTIDA_ASYNC";
export const STORE_CONTRAPARTIDA = "STORE_CONTRAPARTIDA";

//CENTRO CUSTO
export const GET_LIST_CENTRO_CUSTO_ASYNC = "GET_LIST_CENTRO_CUSTO_ASYNC";
export const GET_LIST_CENTRO_CUSTO = "GET_LIST_CENTRO_CUSTO";
export const GET_CENTROS_CUSTO_ASYNC = "GET_CENTROS_CUSTO_ASYNC";
export const GET_CENTROS_CUSTO = "GET_CENTROS_CUSTO";
export const STORE_CENTRO_CUSTO_ASYNC = "STORE_CENTRO_CUSTO_ASYNC";
export const STORE_CENTRO_CUSTO = "STORE_CENTRO_CUSTO";
export const UPDATE_CENTRO_CUSTO_ASYNC = "UPDATE_CENTRO_CUSTO_ASYNC";
export const UPDATE_CENTRO_CUSTO = "UPDATE_CENTRO_CUSTO";
export const DESTROY_CENTROS_CUSTO_ASYNC = "DESTROY_CENTROS_CUSTO_ASYNC";
export const DESTROY_CENTROS_CUSTO = "DESTROY_CENTROS_CUSTO";

//FATURAS
export const GET_FATURAS_SEM_BAIXAS_ASYNC = "GET_FATURAS_SEM_BAIXAS_ASYNC";
export const GET_FATURAS_SEM_BAIXAS = "GET_FATURAS_SEM_BAIXAS";
export const BAIXAR_FATURAS_ASYNC = "BAIXAR_FATURAS_ASYNC";
export const BAIXAR_FATURAS = "BAIXAR_FATURAS";
export const GET_FATURAS_CONCILAR_ASYNC = "GET_FATURAS_CONCILAR_ASYNC";
export const GET_FATURAS_CONCILAR = "GET_FATURAS_CONCILAR";
export const CONCILIAR_FATURAS_ASYNC = "CONCILIAR_FATURAS_ASYNC";
export const CONCILIAR_FATURAS = "CONCILIAR_FATURAS";
export const CONCILAR_FATURAS_AUTO_ASYNC = "CONCILAR_FATURAS_AUTO_ASYNC";
export const CONCILAR_FATURAS_AUTO = "CONCILAR_FATURAS_AUTO";
export const CHANGE_CONCILIAR_FATURAS = "CHANGE_CONCILIAR_FATURAS";
export const CLEAR_CONCILIAR_FATURAS = "CLEAR_CONCILIAR_FATURAS";

//EMPRESAS
export const GET_EMPRESAS = "GET_EMPRESAS";
export const GET_EMPRESAS_ASYNC = "GET_EMPRESAS_ASYNC";
export const GET_LIST_EMPRESAS_ASYNC = "GET_LIST_EMPRESAS_ASYNC";
export const GET_LIST_EMPRESAS = "GET_LIST_EMPRESAS";
export const STORE_EMPRESAS_ASYNC = "STORE_EMPRESAS_ASYNC";
export const STORE_EMPRESAS = "STORE_EMPRESAS";
export const UPDATE_EMPRESAS_ASYNC = "UPDATE_EMPRESAS_ASYNC";
export const UPDATE_EMPRESAS = "UPDATE_EMPRESAS";
export const DESTROY_EMPRESAS_ASYNC = "DESTROY_EMPRESAS_ASYNC";
export const DESTROY_EMPRESAS = "DESTROY_EMPRESAS";

//PRODUTOS
export const GET_PRODUTOS_ASYNC = "GET_PRODUTOS_ASYNC";
export const GET_PRODUTOS = "GET_PRODUTOS";
export const STORE_PRODUTO_ASYNC = "STORE_PRODUTOS_ASYNC";
export const STORE_PRODUTO = "STORE_PRODUTOS";
export const UPDATE_PRODUTO_ASYNC = "UPDATE_PRODUTO_ASYNC";
export const UPDATE_PRODUTO = "UPDATE_PRODUTO";
export const DESTROY_PRODUTOS_ASYNC = "REMOVER_PRODUTOS_ASYNC";
export const DESTROY_PRODUTOS = "REMOVER_PRODUTOS";

//CUSTO POR PRODUTO
export const GET_CUSTOS_PRODUTOS_ASYNC = "GET_CUSTOS_PRODUTOS_ASYNC";
export const GET_CUSTOS_PRODUTOS = "GET_CUSTOS_PRODUTOS";
export const STORE_CUSTO_PRODUTO_ASYNC = "STORE_CUSTO_PRODUTO_ASYNC"
export const STORE_CUSTO_PRODUTO = "STORE_CUSTO_PRODUTO";
export const UPDATE_CUSTO_PRODUTO_ASYNC = "UPDATE_CUSTO_PRODUTO_ASYNC";
export const UPDATE_CUSTO_PRODUTO = "UPDATE_CUSTO_PRODUTO";
export const DESTROY_CUSTO_PRODUTO_ASYNC = "DESTROY_CUSTO_PRODUTO_ASYNC";
export const DESTROY_CUSTO_PRODUTO = "DESTROY_CUSTO_PRODUTO";

//EDOCS
export const LOGIN_EDOCS_ASYNC = "LOGIN_EDOCS_ASYNC";
export const LOGIN_EDOCS = "LOGIN_EDOCS"
export const IMPORT_XMLS_ASYNC = "IMPORT_XMLS_ASYNC";
export const IMPORT_XMLS = "IMPORT_XMLS";

//ARQUIVOS
export const READOFX_EXTRATO_BANCO_ASYNC = 'READOFX_EXTRATO_BANCO_ASYNC';
export const READOFX_EXTRATO_BANCO = "READOFX_EXTRATO_BANCO";
export const READTXT_EXTRATO_BANCO_ASYNC = "READTXT_EXTRATO_BANCO_ASYNC";
export const READTXT_EXTRATO_BANCO = "READTXT_EXTRATO_BANCO";
export const CLEAR_EXTRATO_BANCO = "CLEAR_EXTRATO_BANCO";

//CLIENTES
export const GET_LIST_CLIENTES_ASYNC = "GET_LIST_CLIENTES_ASYNC";
export const GET_LIST_CLIENTES = "GET_LIST_CLIENTES";
export const GET_LIST_CLIENTES_EMPRESA_ASYNC = "GET_LIST_CLIENTES_EMPRESA_ASYNC";
export const GET_LIST_CLIENTES_EMPRESA = "GET_LIST_CLIENTES_EMPRESA";

//TIPOS EMPRESAS
export const GET_LIST_TIPOS_EMPRESAS_ASYNC = "GET_LIST_TIPOS_EMPRESAS_ASYNC";
export const GET_LIST_TIPOS_EMPRESAS = "GET_LIST_TIPOS_EMPRESAS";
export const GET_TIPOS_EMPRESA_ASYNC = "GET_TIPOS_EMPRESA_ASYNC";
export const GET_TIPOS_EMPRESA = "GET_TIPOS_EMPRESA";
export const STORE_TIPO_EMPRESA_ASYNC = "STORE_TIPO_EMPRESA_ASYNC";
export const STORE_TIPO_EMPRESA = "STORE_TIPO_EMPRESA";
export const DESTROY_TIPOS_EMPRESA_ASYNC = "DESTROY_TIPOS_EMPRESA_ASYNC";
export const DESTROY_TIPOS_EMPRESA = "DESTROY_TIPOS_EMPRESA";
export const UPDATE_TIPO_EMPRESA_ASYNC = "UPDATE_TIPO_EMPRESA_ASYNC";
export const UPDATE_TIPO_EMPRESA = "UPDATE_TIPO_EMPRESA";

//FUNCIONALIDADES
export const GET_FUNCIONALIDADES_ASYNC = "GET_FUNCIONALIDADES_ASYNC";
export const GET_FUNCIONALIDADES = "GET_FUNCIONALIDADES";
export const LIST_FUNCIONALIDADES_ASYNC = "LIST_FUNCIONALIDADES_ASYNC";
export const LIST_FUNCIONALIDADES = "LIST_FUNCIONALIDADES";
export const STORE_FUNCIONALIDADES_ASYNC = "STORE_FUNCIONALIDADES_ASYNC";
export const STORE_FUNCIONALIDADES = "STORE_FUNCIONALIDADES";
export const UPDATE_FUNCIONALIDADES_ASYNC = "UPDATE_FUNCIONALIDADES_ASYNC";
export const UPDATE_FUNCIONALIDADES = "UPDATE_FUNCIONALIDADES";
export const DESTROY_FUNCIONALIDADES_ASYNC = "DESTROY_FUNCIONALIDADES_ASYNC";
export const DESTROY_FUNCIONALIDADES = "DESTROY_FUNCIONALIDADES";

//MODULOS
export const GET_MODULOS_ASYNC = "GET_MODULOS_ASYNC";
export const GET_MODULOS = "GET_MODULOS";
export const LIST_MODULOS_ASYNC = "LIST_MODULOS_ASYNC";
export const LIST_MODULOS = "LIST_MODULOS";
export const STORE_MODULOS_ASYNC = "STORE_MODULOS_ASYNC";
export const STORE_MODULOS = "STORE_MODULOS";
export const UPDATE_MODULOS_ASYNC = "UPDATE_MODULOS_ASYNC";
export const UPDATE_MODULOS = "UPDATE_MODULOS";
export const DESTORY_MODULOS_ASYNC = "DESTORY_MODULOS_ASYNC";
export const DESTORY_MODULOS = "DESTORY_MODULOS";

//GROUPS
export const GET_GROUPS_ASYNC = "GET_GROUPS_ASYNC";
export const GET_GROUPS = "GET_GROUPS";
export const LIST_GROUPS_ASYNC = "LIST_GROUPS_ASYNC";
export const LIST_GROUPS = "LIST_GROUPS";
export const STORE_GROUPS_ASYNC = "STORE_GROUPS_ASYNC";
export const STORE_GROUPS = "STORE_GROUPS";
export const UPDATE_GROUPS_ASYNC = "UPDATE_GROUPS_ASYNC";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const DESTROY_GROUPS_ASYNC = "DESTROY_GROUPS_ASYNC";
export const DESTROY_GROUPS = "DESTROY_GROUPS";

//PERMISSIONS
export const GET_PERMISSIONS_ASYNC = "GET_PERMISSIONS_ASYNC";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const UPDATE_PERMISSIONS_ASYNC = "UPDATE_PERMISSIONS_ASYNC";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

//TEMP CENTROS DE CUSTO
export const STORE_SEL_CENTROS_CUSTO_ASYNC = "STORE_SEL_CENTROS_CUSTO_ASYNC";
export const STORE_SEL_CENTROS_CUSTO = "STORE_SEL_CENTROS_CUSTO";