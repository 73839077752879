import React from "react";
import { FormGroup, Row, Col, Label, Input } from "reactstrap";
function DadosGerais({
    produto
}) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Nome*</Label>
                            <Input
                                readOnly
                                maxLength={60}
                                defaultValue={produto.prd_nm_produto}
                                name="prd_nm_produto"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Sigla*</Label>
                            <Input
                                readOnly
                                name="prd_nm_sigla"
                                maxLength={15}
                                defaultValue={produto.prd_nm_sigla || 'Não Informado'} />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Situação*</Label>
                            <Input
                                defaultValue={produto.prd_st_ativo === 'S' ? 'Ativo' : 'Inativo'}
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default DadosGerais;