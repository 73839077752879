import {
    GET_CONTAS_GRUPOS_ASYNC,
    STORE_CONTAS_GRUPOS_ASYNC,
    UPDATE_CONTAS_GRUPOS_ASYNC,
    DESTROY_CONTAS_GRUPOS_ASYNC,
    GET_LIST_CONTAS_GRUPOS_ASYNC,
    GET_LIST_CONTAS_GRUPOS,
    CLEAR_ERRORS_CONTAS_GRUPOS_CONTAS,
    GET_LIST_GRC_CONTA_EMP_ASYNC,
    GET_LIST_GRC_CONTA_EMP
} from "../actionTypes";

export const clearErrorsContasGrupos = payload => ({
    type: CLEAR_ERRORS_CONTAS_GRUPOS_CONTAS,
    payload
})

export const getContasGrupos = (payload = {}) => ({
    type: GET_CONTAS_GRUPOS_ASYNC,
    payload
});

export const storeContasGrupos = payload => ({
    type: STORE_CONTAS_GRUPOS_ASYNC,
    payload
});

export const updateContasGrupos = payload => ({
    type: UPDATE_CONTAS_GRUPOS_ASYNC,
    payload
});

export const destroyContaGrupos = payload => ({
    type: DESTROY_CONTAS_GRUPOS_ASYNC,
    payload
});

export const getListContasGrupos = (payload = {}) => ({
    type: GET_LIST_CONTAS_GRUPOS_ASYNC,
    payload
});

export const changeListGrupos = payload => ({
    type: GET_LIST_CONTAS_GRUPOS,
    payload
});

export const getListGrcContasEmp = payload => ({
    type: GET_LIST_GRC_CONTA_EMP_ASYNC,
    payload
});