import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.png'
import { LogIn, User, Minimize } from 'react-feather';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser, importarXmls } from "../../redux/actions";
import sweetalert2 from "sweetalert2";

const Rightbar = (props) => {
  const [moonlight, setMoonlight] = useState(false);
  const [dropdownProfile, setDropDownProfile] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
      document.body.className = "dark-only";
    }
  }, []);

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const handleLogOut = async () => {
    const confirm = await sweetalert2.fire({
      title: 'Deseja Realmente Sair?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    });
    if (confirm.value) {

      props.logoutUser();
    }
  }
  const groupsName = props.user.groups.map(({ name }) => name).join(', ');
  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header">
        <ul className="nav-menus">
          {/* <li><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li> */}
          <li>
            <a
              onClick={() => props.importarXmls({ fields: { integra: "0", ambiente: 2 } })}
              href="#javascript"
              className={`${props.loadingImport ? 'disabled' : ''}`}
            >
              {props.loadingImport ? 'Processando...' : <div className="media xml-media">
                <i className="fa fa-refresh f-16 p-r-5"></i><div className="media-body-xml">
                  <span>Importar XML'S</span>
                </div>
              </div>}
            </a>
          </li>
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>

          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>
          <li className="onhover-dropdown p-0">
            <div className="media profile-media" onClick={() => setDropDownProfile(!dropdownProfile)}>
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body"><span>{`${props.user.name.split(' ')[0]} ${props.user.name.split(' ')[props.user.name.split(' ').length - 1]}`}</span>
                <p className="mb-0 font-roboto">{props.user.departamento?.dep_nm_departamento} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className={`profile-dropdown onhover-show-div ${dropdownProfile ? 'active' : ''}`}>
              <li onClick={() => history.push('/profile')}><User /><span>Perfil </span></li>
              <li onClick={handleLogOut} ><LogIn /><span>Sair</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
const mapStateToProps = ({ Auth, EDocs }) => {
  const { user } = Auth;
  const { loading: loadingImport } = EDocs;
  return { user, loadingImport }
}
export default connect(mapStateToProps, {
  logoutUser,
  importarXmls
})(Rightbar);