import { all, put, call, fork, debounce, select, takeLatest } from "redux-saga/effects";
import Api from "../../services/api";
import {
    GET_LIST_PESSOAS,
    GET_LIST_PESSOAS_ASYNC,
    GET_PESSOAS_ASYNC,
    GET_PESSOAS,
    STORE_PESSOAS_ASYNC,
    STORE_PESSOAS,
    DESTROY_PESSOAS_ASYNC,
    DESTROY_PESSOAS,
    UPDATE_PESSOAS_ASYNC,
    UPDATE_PESSOAS,
    GET_LIST_PESSOAS_EMPRESA_ASYNC,
    GET_LIST_PESSOAS_EMPRESA
} from "../actionTypes";
import { selectorListPessoas, selectorPessoas, selectorListPessoasEmp } from "../selectors";
import { toast } from "react-toastify";
import { mountList } from "../../helpers";

function* getListPessoasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListPessoas, fields);
        if (data.ok) {
            if (!!fields?.offset) {
                const list = yield select(selectorListPessoas);
                list.data = mountList(list.data, data.list.data, ['pss_cd_pessoa'])
                list.total = data.list.total;
                yield put({ type: GET_LIST_PESSOAS, payload: { list } });
            } else {
                yield put({ type: GET_LIST_PESSOAS, payload: { list: data.list } });
            }

        }
    } catch (error) {
        yield put({ type: GET_LIST_PESSOAS, payload: {} })
    }
}
const apiGetListPessoas = async fields => {
    const { data } = await Api.get(`/api/pessoas/list`, { params: fields });

    return data;
}

function* getPessoasSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetPessoas, fields);
        if (data.ok) {
            const { pessoas } = data;
            yield put({ type: GET_PESSOAS, payload: { pessoas } });
        }
    } catch (error) {
        yield put({ type: GET_PESSOAS, payload: {} });
    }
}
const apiGetPessoas = async fields => {
    const { data } = await Api.get('/api/pessoas', { params: fields });

    return data;
}

function* storePessoasSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStorePessoas, fields);
        if (!!data && data.ok) {
            const { pessoas } = data;
            yield put({ type: STORE_PESSOAS, payload: { pessoas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_PESSOAS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_PESSOAS, payload: {} });
    }
}
const apiStorePessoas = async fields => {
    const data = Api.post(`/api/pessoas/store`, { ...fields });

    return data;
}

function* destroyPessoasSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyPessoas, fields);
        if (data.ok) {
            const { pessoas } = data;
            yield put({ type: DESTROY_PESSOAS, payload: { pessoas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_PESSOAS, payload: {} });
    }
}
const apiDestroyPessoas = async fields => {
    const { data } = await Api.delete(`/api/pessoas/destroy`, { data: fields });

    return data
}

function* updatePessoasSaga({ payload }) {
    try {
        const { fields, onUpdate, index, id } = payload;
        const { data, errors } = yield call(apiUpdatePessoas, fields, id);
        if (!!data && data.ok) {
            const pessoas = yield select(selectorPessoas);
            pessoas.data.splice(index, 1, data.pessoa);
            yield put({ type: UPDATE_PESSOAS, payload: { pessoas } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onUpdate();
        }
        if (!!errors) {
            yield put({ type: UPDATE_PESSOAS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_PESSOAS, payload: {} });
    }
}
const apiUpdatePessoas = async (fields, id) => {
    const data = await Api.put(`/api/pessoas/update/${id}`, { ...fields });

    return data;
}

function* getListPessoasEmpSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiPessoasEmpresa, fields);
        if (data.ok) {
            const listPessoasEmp = yield select(selectorListPessoasEmp);
            listPessoasEmp.data = mountList(listPessoasEmp.data, data.listPessoasEmp.data, ['pss_cd_pessoa']);
            listPessoasEmp.total = data.listPessoasEmp.total;
            yield put({ type: GET_LIST_PESSOAS_EMPRESA, payload: { listPessoasEmp } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_PESSOAS_EMPRESA, payload: {} });
    }
}
const apiPessoasEmpresa = async fields => {
    const { data } = await Api.get(`/api/pessoas/listPessoasEmp`, { params: fields });

    return data;
}

function* watchGetListPessoas() {
    yield debounce(1000, GET_LIST_PESSOAS_ASYNC, getListPessoasSaga);
}
function* watchGetPessoas() {
    yield takeLatest(GET_PESSOAS_ASYNC, getPessoasSaga);
}
function* watchStorePessoas() {
    yield takeLatest(STORE_PESSOAS_ASYNC, storePessoasSaga);
}
function* watchDestroyPessoas() {
    yield takeLatest(DESTROY_PESSOAS_ASYNC, destroyPessoasSaga);
}
function* watchUpdatePessoa() {
    yield takeLatest(UPDATE_PESSOAS_ASYNC, updatePessoasSaga);
}
function* watchGetListPessoasEmp() {
    yield debounce(1000, GET_LIST_PESSOAS_EMPRESA_ASYNC, getListPessoasEmpSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListPessoas),
        fork(watchGetPessoas),
        fork(watchStorePessoas),
        fork(watchDestroyPessoas),
        fork(watchUpdatePessoa),
        fork(watchGetListPessoasEmp)
    ]);
}