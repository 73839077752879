import React, { Fragment, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Form,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import { connect } from "react-redux";
import { getEmpresas, destroyEmpresa, getListTiposEmp } from "../../../../redux/actions";
import sweetalert2 from "sweetalert2";
import DataTable from 'react-data-table-component';
import SelectPagination from "../../../../components/SelectPagination";
import { Typeahead } from "react-bootstrap-typeahead";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";

const Empresas = ({
    getEmpresas,
    empEmpresas,
    loadingEmpresas,
    destroyEmpresa,
    loadingDestroyEmp,
    listTiposEmp,
    loadingListTiposEmp,
    getListTiposEmp
}) => {
    useEffect(() => {
        getEmpresas()
        getListTiposEmp()
    }, []);
    //ESTADOS
    const [openModal, setOpenModal] = useState(null);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [filter, setFilter] = useState({
        emp_cd_empresa: null,
        emp_nu_cnpj_cpf: null,
        emp_tp_empresa: null,
        emp_tp_dados: null,
        emp_nm_empresa: null
    })

    //VARIÁVEIS
    const optionsDados = [{ name: 'Local', value: 'L' }, { name: 'Importado', value: 'I' }];
    const optionsTiposEmpresa = useMemo(() => listTiposEmp.data.map((tipo) => ({
        value: tipo.tpe_cd_tipo,
        name: tipo.tpe_nm_tipo,
        subItem: tipo.tpe_ds_tipo
    })), [listTiposEmp.data]);
    //REFS
    const curFilter = useRef(null);

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, emp_nm_empresa }) => ({ id, emp_nm_empresa })))
    }, []);

    const deleteUsers = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ emp_nm_empresa }) => {
                return (`<li class="list-group-item-action list-group-item">${emp_nm_empresa}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!empEmpresas && empEmpresas.current_page !== 1) ? empEmpresas.current_page : 1;
            const fields = { empresasIds: selectedRows, page, curFilter: curFilter.current };
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyEmpresa({ fields, onDelete });
        }
    }
    const submitFilter = e => {
        e.preventDefault();
        getEmpresas({ fields: filter });
        curFilter.current = filter;
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value });
                } else {
                    setFilter({ ...filter, [name]: "" });
                }
                break;
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        switch (name) {
            default:
                setFilter({ ...filter, [name]: value })
                break;
        }
    }

    return (
        <Fragment>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} />
            <Edit isOpen={openModal === 'edit'} selectedEmpresa={selectedEmpresa} clickClose={() => setOpenModal(null)} />
            <View isOpen={openModal === 'view'} empresa={!!empEmpresas && empEmpresas.data[selectedEmpresa]} clickClose={() => setOpenModal(null)} />
            <Breadcrumb parent="Tabelas" title="Empresas" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12" className="d-flex justify-content-end m-b-10">
                        <Button
                            sid="emp_empresa.store"
                            color="primary"
                            size="md"
                            onClick={() => setOpenModal('add')}>
                            <i className="fa fa-plus"></i><span className="p-l-5">Incluir</span>
                        </Button>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={submitFilter}>
                                    <Row>
                                        <Col md="2" xs="12">
                                            <FormGroup>
                                                <Label>Código</Label>
                                                <Input
                                                    name="emp_cd_empresa"
                                                    placeholder="Informe o Código"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Label>CNPJ/CPF</Label>
                                                <Input
                                                    name="emp_nu_cnpj_cpf"
                                                    placeholder="Informe o CNPJ/CPF"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" xs="12">
                                            <FormGroup>
                                                <Label>Tipo</Label>
                                                <SelectPagination
                                                    id="selectTipoEmpresa"
                                                    onChange={(selected) => handleChangeSelect('emp_tp_empresa', selected)}
                                                    labelKey="name"
                                                    placeholder="Informe o tipo da empresa"
                                                    data={optionsTiposEmpresa}
                                                    clearButton
                                                    isLoading={loadingListTiposEmp && !openModal}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" xs="12">
                                            <FormGroup>
                                                <Label>Dados</Label>
                                                <Typeahead
                                                    id="selectDados"
                                                    labelKey="name"
                                                    placeholder="Infome o Dados"
                                                    onChange={(selected) => handleChangeSelect('emp_tp_dados', selected)}
                                                    options={optionsDados}
                                                    clearButton
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="11" xs="12">
                                            <FormGroup>
                                                <Label>Nome/Razão Social</Label>
                                                <Input
                                                    name="emp_nm_empresa"
                                                    placeholder="Infome o Nome/Razão Social"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingEmpresas}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <Card className="shadow">
                            <CardBody>
                                {loadingEmpresas ?
                                    <div className="loader-box">
                                        <div className="loader-1"></div>
                                    </div>
                                    :
                                    !!empEmpresas &&
                                    <DataTable
                                        persistTableHead
                                        pagination
                                        paginationServer
                                        selectableRows
                                        onSelectedRowsChange={handleSelectedRows}
                                        clearSelectedRows={toggleCleared}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                            rangeSeparatorText: 'de',
                                        }}
                                        paginationPerPage={empEmpresas.per_page}
                                        paginationTotalRows={empEmpresas.total}
                                        contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                        progressComponent={
                                            <div className="loader-box">
                                                <div className="loader-1"></div>
                                            </div>
                                        }
                                        paginationDefaultPage={empEmpresas.current_page}
                                        onChangePage={(page) => {
                                            getEmpresas({ fields: { ...curFilter.current, page } })
                                        }}
                                        progressPending={loadingDestroyEmp}
                                        contextActions={
                                            <Button
                                                sid="emp_empresa.destroy"
                                                disabled={loadingDestroyEmp}
                                                onClick={() => deleteUsers()}
                                                color="danger">{loadingDestroyEmp ? 'Processando...' : 'Remover Empresa(s)'}
                                            </Button>
                                        }
                                        columns={[
                                            {
                                                name: 'Código',
                                                selector: 'emp_cd_empresa',
                                                center: true,
                                            },
                                            {
                                                name: 'Nome/Razão Social',
                                                selector: 'emp_nm_empresa',
                                                center: true
                                            },
                                            {
                                                name: 'CNPJ/CPF',
                                                selector: 'emp_nu_cnpj_cpf',
                                                center: true
                                            },
                                            {
                                                name: 'Tipo',
                                                selector: 'tipo_emp',
                                                center: true
                                            },
                                            {
                                                name: 'Dados',
                                                selector: 'dados_emp',
                                                center: true,
                                            },
                                            {
                                                name: 'Ações',
                                                selector: 'acoes',
                                                center: true
                                            }
                                        ]}
                                        data={empEmpresas.data.map(({
                                            emp_cd_empresa,
                                            emp_nm_empresa,
                                            emp_nu_cnpj_cpf,
                                            tipo,
                                            emp_tp_dados,
                                            id
                                        }, index) => {
                                            const tipo_emp = tipo?.tpe_nm_tipo;
                                            const dados_emp = emp_tp_dados === "I" ? 'Importado' : 'Local';
                                            const acoes = (
                                                <div style={{ display: "flex", overflow: "auto" }}>
                                                    <a
                                                        sid="emp_empresa.index"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedEmpresa(index);
                                                            setOpenModal('view');
                                                        }}
                                                    >
                                                        <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                    </a>
                                                    <a
                                                        sid="emp_empresa.update"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedEmpresa(index);
                                                            setOpenModal('edit');
                                                        }}
                                                    >
                                                        <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                    </a>
                                                </div>
                                            )
                                            return { id, emp_cd_empresa, emp_nm_empresa, emp_nu_cnpj_cpf, tipo_emp, dados_emp, acoes }
                                        })}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Empresas, TiposEmpresa }) => {
    const { listTiposEmp, loadingListTiposEmp } = TiposEmpresa;
    const { empEmpresas, loadingEmpresas, loadingDestroyEmp } = Empresas;
    return { empEmpresas, loadingEmpresas, loadingDestroyEmp, listTiposEmp, loadingListTiposEmp }
}

export default connect(mapStateToProps, {
    getEmpresas,
    destroyEmpresa,
    getListTiposEmp
})(Empresas);