import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
const View = ({ selectedGrupo, clickClose, isOpen, contasGrupos }) => {
    const grupo = !!contasGrupos && contasGrupos.data[selectedGrupo];
    return (
        <Modal isOpen={isOpen} size="lg">
            <ModalHeader toggle={() => clickClose()}>
                Informações do Grupo
            </ModalHeader>
            <ModalBody>
                {!!grupo &&
                    <Row>
                        <Col md="9" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input readOnly defaultValue={grupo.grc_nm_grupo} />
                            </FormGroup>
                        </Col>
                        <Col md="3" xs="12">
                            <FormGroup>
                                <Label>Código</Label>
                                <Input readOnly defaultValue={grupo.grc_cd_grupo} />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Grupo Pai</Label>
                                <Input readOnly defaultValue={!!grupo.grupo_pai && grupo.grupo_pai.name ? grupo.grupo_pai.name : 'Não Informado'} />
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ ContasGrupos }) => {
    const { contasGrupos } = ContasGrupos;
    return { contasGrupos }
}

export default connect(mapStateToProps, {})(View);