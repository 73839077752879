import { all, fork, put, call, takeLatest, select } from "redux-saga/effects";
import {
    GET_TIPOS_MOV_PRODUTOS_ASYNC,
    GET_TIPOS_MOV_PRODUTOS,
    STORE_TIPOS_MOV_PRODUTOS_ASYNC,
    STORE_TIPOS_MOV_PRODUTOS,
    DESTROY_TIPOS_MOV_PRODUTOS_ASYNC,
    DESTROY_TIPOS_MOV_PRODUTOS,
    UPDATE_TIPOS_MOV_PRODUTOS_ASYNC,
    UPDATE_TIPOS_MOV_PRODUTOS
} from "../actionTypes";
import Api from "../../services/api";
import { toast } from "react-toastify";
import { selectorTiposMovsPrd } from "../selectors";

function* getTiposMovProdutosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetTiposMovProdutos, fields);
        if (data.ok) {
            const { tiposMovsPrd } = data;
            yield put({ type: GET_TIPOS_MOV_PRODUTOS, payload: { tiposMovsPrd } });
        }
    } catch (error) {
        yield put({ type: GET_TIPOS_MOV_PRODUTOS, payload: {} });
    }
}
const apiGetTiposMovProdutos = async fields => {
    const { data } = await Api.get(`/api/tipo_mov_prd`, { params: fields });

    return data;
}

function* storeTiposMovProdutosSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreTiposMovProdutos, fields);

        if (!!data && data.ok) {
            const { tiposMovsPrd } = data;
            yield put({ type: STORE_TIPOS_MOV_PRODUTOS, payload: { tiposMovsPrd } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: STORE_TIPOS_MOV_PRODUTOS, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: STORE_TIPOS_MOV_PRODUTOS, payload: {} });
    }
}
const apiStoreTiposMovProdutos = async fields => {
    const data = await Api.post(`/api/tipo_mov_prd/store`, fields);

    return data;
}

function* destroyTiposMovProdutosSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyTiposMovProdutos, fields);
        if (data.ok) {
            const { tiposMovsPrd } = data;
            yield put({ type: DESTROY_TIPOS_MOV_PRODUTOS, payload: { tiposMovsPrd } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_TIPOS_MOV_PRODUTOS, payload: {} });
    }
}
const apiDestroyTiposMovProdutos = async fields => {
    const { data } = await Api.delete(`/api/tipo_mov_prd/destroy`, { data: fields });

    return data;
}

function* updateTipoMovProdutoSaga({ payload }) {
    try {
        const { fields, onSave, index } = payload;
        const tiposMovsPrd = { ...yield select(selectorTiposMovsPrd) };
        const { id } = tiposMovsPrd.data[index];
        const { data, errors } = yield call(apiUpdateTipoMovProduto, fields, id);
        if (!!data && data.ok) {
            tiposMovsPrd.data.splice(index, 1, data.tipoMovPrd);
            yield put({ type: UPDATE_TIPOS_MOV_PRODUTOS, payload: { tiposMovsPrd } });
            toast.success(data.msg,toast.POSITION.TOP_RIGHT);
            onSave();
        }
        if (!!errors) {
            yield put({ type: UPDATE_TIPOS_MOV_PRODUTOS, payload: { errors } });
            toast.error('Preencha os campos corretamente.',toast.POSITION.TOP_RIGHT);
        }
    } catch (error) {
        yield put({ type: UPDATE_TIPOS_MOV_PRODUTOS, payload: {} })
    }
}
const apiUpdateTipoMovProduto = async (fields, id) => {
    const data = await Api.put(`/api/tipo_mov_prd/update/${id}`, fields);

    return data;
}

function* watchGetTiposMovProdutos() {
    yield takeLatest(GET_TIPOS_MOV_PRODUTOS_ASYNC, getTiposMovProdutosSaga);
}
function* watchStoreTiposMovProdutos() {
    yield takeLatest(STORE_TIPOS_MOV_PRODUTOS_ASYNC, storeTiposMovProdutosSaga);
}
function* watchDestroyTiposMovProdutos() {
    yield takeLatest(DESTROY_TIPOS_MOV_PRODUTOS_ASYNC, destroyTiposMovProdutosSaga);
}
function* watchUpdateTipoMovProduto() {
    yield takeLatest(UPDATE_TIPOS_MOV_PRODUTOS_ASYNC, updateTipoMovProdutoSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetTiposMovProdutos),
        fork(watchStoreTiposMovProdutos),
        fork(watchDestroyTiposMovProdutos),
        fork(watchUpdateTipoMovProduto)
    ]);
}