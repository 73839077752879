import { combineReducers } from 'redux'
import Customizer from './customizer/reducer';
import Auth from "./auth/reducer";
import Funcionalidades from './funcionalidades/reducer';
import Modulos from "./modulos/reducer";
import Groups from "./groups/reducer";
import Permissions from "./permissions/reducer";
import Users from "./users/reducer";
import Departamentos from "./departamentos/reducer";
import Contas from "./contas/reducer";
import ContasGrupos from "./contasGrupos/reducer";
import GruposPai from "./gruposPai/reducer";
import Empresas from "./empresas/reducer";
import Pessoas from "./pessoas/reducer";
import Estados from "./estados/reducer";
import Cidades from "./cidades/reducer";
import TiposPessoas from "./tipoPessoas/reducer";
import EDocs from "./edocs/reducer";
import Lancamentos from "./lancamentos/reducer";
import CentroCusto from "./centroCusto/reducer";
import Faturas from "./faturas/reducer";
import VlaLancamentos from "./vlaLancamentos/reducer"
import SelCentrosCusto from "./selCentrosCusto/reducer";
import RltGerenciais from "./relatoriosGerenciais/reducer";
import RltOperacionais from "./relatorioOperacional/reducer";
import Arquivos from "./arquivos/reducer";
import Clientes from "./clientes/reducer";
import TiposEmpresa from "./tiposEmpresa/reducer";
import CmvDiario from "./cmvDiario/reducer";
import TipoMovPrd from "./tipoMovPrd/reducer";
import Produtos from "./produtos/reducer";
import NotasFiscais from "./notasFiscais/reducer";
import Feriados from "./feriados/reducer";

const reducers = combineReducers({
    Customizer,
    Auth,
    Funcionalidades,
    Modulos,
    Groups,
    Permissions,
    Users,
    Departamentos,
    Contas,
    ContasGrupos,
    GruposPai,
    Empresas,
    Pessoas,
    Estados,
    Cidades,
    TiposPessoas,
    EDocs,
    Lancamentos,
    CentroCusto,
    Faturas,
    VlaLancamentos,
    SelCentrosCusto,
    RltGerenciais,
    RltOperacionais,
    Arquivos,
    Clientes,
    TiposEmpresa,
    CmvDiario,
    TipoMovPrd,
    Produtos,
    NotasFiscais,
    Feriados
});

export default reducers;