import React, { useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { format } from 'date-fns';

import DadosGerais from "./DadosGerais";
import { toast } from "react-toastify";

const Edit = ({
    isOpen,
    clickClose,
    contrapartidas,
    setContraPartidas,
    selectedFatura,
    faturas
}) => {
    //ESTADOS
    const [fields, setFields] = useState(null);

   //REF
   const intialContrapartida = useRef(null);

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
        validators: {
            verifica_valor: {
                message: 'O valor deve está entre o mínimo e máximo da conta.',
                rule: (val, params) => {
                    const valor_contrapartida = parseFloat(params[0]);
                    const valor_minimo = parseFloat(params[1]);
                    const valor_maximo = parseFloat(params[2]);

                    return valor_contrapartida >= valor_minimo && valor_contrapartida <= valor_maximo;
                }
            }
        }
    })).current;

    //FUNÇÕES
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name);
        }
    }
    const submitForm = e => {
        e.preventDefault();
        e.stopPropagation();
        if (validator.allValid()) {
            if (intialContrapartida.current !== fields.contrapartida) {
                contrapartidas[intialContrapartida.current].faturas.splice(fields.index_fatura, 1);
            }
            contrapartidas[fields.contrapartida].faturas[fields.index_fatura] = fields;
            setContraPartidas(contrapartidas);
            toast.success('Registro vinculado com sucesso.', toast.POSITION.TOP_RIGHT);
            clickClose();
        } else {
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
            validator.showMessages();
        }

    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dt_lancamento_baixa':
                break;

            case 'ftr_dt_vencimento':
                if (!!selected) {
                    setFields({ ...fields, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;

            case 'contrapartida':
                if (!!selected.length) {
                    setFields({
                        ...fields,
                        [name]: selected[0].value,
                        ftr_nu_fatura: `${contrapartidas[selected[0].value].cnt_nu_doc}${String.fromCharCode((!!contrapartidas[selected[0].value].faturas.length - 1 ?
                            contrapartidas[selected[0].value].faturas.length + 1 : 1) + 64)}`

                    });
                } else {
                    setFields({ ...fields, [name]: "",ftr_nu_fatura:"" });
                }
                break;

            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;

        switch (name) {
            default:
                setFields({ ...fields, [name]: value })
                break;
        }
    }
    const handleValor = (name, value) => {
        switch (name) {
            default:
                if (!!value) {
                    setFields({ ...fields, [name]: value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
    }
    const onOpenModal = () => {
        const fatura = {...faturas[selectedFatura]};
        intialContrapartida.current = fatura.contrapartida;
        setFields(fatura)
    }

    return (
        <Modal
            isOpen={isOpen}
            onOpened={onOpenModal}
            size="lg"
            onClosed={() => {
                setFields(null)
                validator.visibleFields = [];
                validator.messagesShown = false;
                intialContrapartida.current = null;
            }}
        >
            <ModalHeader toggle={() => clickClose()}>Incluir Fatura</ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <DadosGerais
                                fields={fields}
                                validator={validator}
                                validFields={validFields}
                                handleChange={handleChange}
                                handleChangeSelect={handleChangeSelect}
                                handleValor={handleValor}
                                contrapartidas={contrapartidas}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={() => clickClose()}>
                            Fechar
                       </Button>
                        <Button type="submit" color="primary">
                            Salvar
                       </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Edit);