import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';


function Grupos({ empresa }) {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Label><h6>Grupos</h6></Label>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Imobilizado</Label>
                            <Input
                                defaultValue={!!empresa.grc_imobilizado ?
                                    `${empresa.grc_imobilizado.grc_cd_grupo} - ${empresa.grc_imobilizado.grc_nm_grupo} (Grupo Pai: ${empresa.grc_imobilizado.grupo_pai.name})`
                                    : ''}
                                readOnly />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Banco</Label>
                            <Input
                                defaultValue={!!empresa.grc_banco ?
                                    `${empresa.grc_banco.grc_cd_grupo} - ${empresa.grc_banco.grc_nm_grupo} (Grupo Pai: ${empresa.grc_banco.grupo_pai.name})`
                                    : ''}
                                readOnly />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Aplicação</Label>
                            <Input
                                defaultValue={!!empresa.grc_aplicacao ?
                                    `${empresa.grc_aplicacao.grc_cd_grupo} - ${empresa.grc_aplicacao.grc_nm_grupo} (Grupo Pai: ${empresa.grc_aplicacao.grupo_pai.name})`
                                    : ''}
                                readOnly />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>

    )
}


export default Grupos;