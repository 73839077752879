import React from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Row,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    FormFeedback,

} from "reactstrap";
import NumberFormat from 'react-number-format';
const SecondCard = ({ conta, validator, validFields, handleValor }) => {
    return (
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label><h6>Valores</h6></Label> */}
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Minímo*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        name="ctn_vl_minimo_lan"
                                        defaultValue={!!conta.ctn_vl_minimo_lan ? new Intl.NumberFormat('pt-Br').format(conta.ctn_vl_minimo_lan) : null}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('ctn_vl_minimo_lan')}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ value }) => {
                                            handleValor('ctn_vl_minimo_lan', value)
                                        }}
                                    />
                                </InputGroup>
                                <FormFeedback style={{ display: validator.visibleFields.includes('ctn_vl_minimo_lan') ? 'block' : 'none' }}>
                                    {validator.message('ctn_vl_minimo_lan', conta.ctn_vl_minimo_lan,
                                        `required|valor_minimo:${conta.ctn_vl_minimo_lan},${conta.ctn_vl_maximo_lan}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Máximo*</Label>
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                    <NumberFormat
                                        placeholder="Informe o Valor"
                                        disabled={!conta.ctn_vl_minimo_lan}
                                        thousandSeparator='.'
                                        customInput={Input}
                                        defaultValue={!!conta.ctn_vl_maximo_lan ? new Intl.NumberFormat('pt-Br').format(conta.ctn_vl_maximo_lan) : null}
                                        name="ctn_vl_maximo_lan"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={validator.visibleFields.includes('ctn_vl_maximo_lan')}
                                        onValueChange={({ value }) => {
                                            handleValor('ctn_vl_maximo_lan', value)
                                        }}
                                    />
                                </InputGroup>
                                <FormFeedback style={{ display: validator.visibleFields.includes('ctn_vl_maximo_lan') ? 'block' : 'none' }}>
                                    {validator.message('ctn_vl_maximo_lan',
                                        conta.ctn_vl_maximo_lan,
                                        `required|valor_maximo:${conta.ctn_vl_minimo_lan},${conta.ctn_vl_maximo_lan}`)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
    )
}

const mapStateToProps = ({ }) => {
    return {};
}
export default connect(mapStateToProps, {})(SecondCard);