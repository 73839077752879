import React, { } from 'react';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { connect } from "react-redux";
import { parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getListPessoas, getFaturasSemBaixa, getListContasBancos } from "../../../../../redux/actions";
import NumberFormat from 'react-number-format';
import br from "date-fns/locale/pt-BR";
function TabFaturas({
    handleChangeSelect,
    handleValor,
    filter,
    handleChange
}) {

    return (
        <>
            <div className="shadow shadow-showcase p-15">
                <Label>
                    <h6>Opções</h6>
                </Label>
                <Row>
                    <Col sm="12" lg="8">
                        <FormGroup>
                            <div className="d-flex justify-content-between">
                                <Label>Data</Label>
                                <Label>
                                    <Input
                                        className="checkbox_animated"
                                        name="vencimentos_dia"
                                        onChange={handleChange}
                                        id={`chk-ani00`}
                                        type="checkbox" />Apenas vencimentos do dia
                             </Label>
                            </div>
                            <DatePicker
                                locale={br}
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                className="form-control digits"
                                selected={!!filter.ftr_dt_vencimento && parseISO(filter.ftr_dt_vencimento)}
                                placeholderText="Informe a data"
                                onChange={(date) => handleChangeSelect('ftr_dt_vencimento', date)}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="12" lg="4">
                        <FormGroup>
                            <Label>Tipo</Label>
                            <Typeahead
                                id="selectTipoFat"
                                labelKey="name"
                                options={[{ name: 'A pagar', value: 0 }]}
                                placeholder="Informe o Tipo"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label>Valor</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="append">R$</InputGroupAddon>
                                <NumberFormat
                                    id="fieldValorFat"
                                    placeholder="Informe o Valor"
                                    thousandSeparator='.'
                                    customInput={Input}
                                    name="ftr_vl_fatura"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    onValueChange={({ value }) => handleValor('ftr_vl_fatura', value)}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </>
    )
}
const mapStateToProps = ({ Faturas, Pessoas, Contas }) => {
    const { ftr_sem_baixa, loadingFtrSemBaixa } = Faturas;
    const { list: listPessoas, loadingList: loadingListPessoas } = Pessoas;
    const { list_bancos: listBancos, loadingListCtnBancos } = Contas;
    return { ftr_sem_baixa, loadingFtrSemBaixa, listPessoas, loadingListPessoas, listBancos, loadingListCtnBancos }
}

export default connect(mapStateToProps, {
    getListPessoas,
    getFaturasSemBaixa,
    getListContasBancos
})(TabFaturas);