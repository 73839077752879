import React from "react";
import { connect } from "react-redux";
import {
    getListCentroCusto,
} from "../../../../redux/actions";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";

const DadosGerais = ({
    lancamento
}) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label>
                        <h6>Dados Gerais</h6>
                    </Label> */}
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Número do Documento</Label>
                                <Input defaultValue={lancamento.lan_nu_doc} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Valor do Lançamento*</Label>
                                <Input
                                        defaultValue={!!lancamento.lan_vl_lancamento ?
                                            new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(lancamento.lan_vl_lancamento)
                                            :
                                            'Não Informado'
                                        }
                                        readOnly
                                    />
                            </FormGroup>
                        </Col>
                        {/* <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Situação Fechamento</Label>
                                <Input defaultValue={lancamento.lan_st_fechamento === "A" ? 'Aberto' : 'Fechado'} readOnly />
                            </FormGroup>
                        </Col> */}
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Centro de Custo</Label>
                                <Input defaultValue={`${lancamento.centro_custo?.cct_cd_centro} - ${lancamento.centro_custo?.cct_nm_centro}`} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Número do Pedido</Label>
                                <Input defaultValue={lancamento.lan_nu_pedido} readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ CentroCusto, Contas }) => {
    const { list: listContas } = Contas;
    const { list: listCentroCusto, loadingList: loadingListCentroCusto } = CentroCusto;
    return {
        listCentroCusto,
        loadingListCentroCusto,
        listContas
    }
}

export default connect(mapStateToProps, {
    getListCentroCusto,

})(DadosGerais)