import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import Api from "../../services/api";
import { GET_PERMISSIONS_ASYNC, GET_PERMISSIONS, UPDATE_PERMISSIONS_ASYNC, UPDATE_PERMISSIONS } from '../actionTypes';

function* getPermissionsSaga() {
    try {

        const data = yield call(apiGetPermissions);
        if (data.ok) {
            const { permissions } = data;
            yield put({ type: GET_PERMISSIONS, payload: { permissions } });
        }
    } catch (error) {
        yield put({ type: GET_PERMISSIONS, payload: {} });
    }
}
const apiGetPermissions = async fields => {
    const { data } = await Api.get('/api/permissions', { params: fields });

    return data;
}

function* updatePermissionsSaga() {
    try {
        const data = yield call(apiUpdatePermissions);
        if (data.ok) {
            const { adicionadas, removidas, permissions } = data;
            yield put({ type: UPDATE_PERMISSIONS, payload: { adicionadas, removidas, permissions } });
        }
    } catch (error) {
        yield put({ type: UPDATE_PERMISSIONS, payload: {} });
    }
}
const apiUpdatePermissions = async () => {
    const { data } = await Api.put(`/api/permissions/update`);

    return data;
}



function* watchGetPermissions() {
    yield takeLatest(GET_PERMISSIONS_ASYNC, getPermissionsSaga);
}
function* watchUpdatePermissions() {
    yield takeLatest(UPDATE_PERMISSIONS_ASYNC, updatePermissionsSaga);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetPermissions),
        fork(watchUpdatePermissions)
    ]);
}