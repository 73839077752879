import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { FormGroup, Row, Col, Label, Input, FormFeedback } from 'reactstrap';
import {
    getListContasGrupos,
    updateContas,
    changeListGrupos,
    getListPessoas,
    changeListPessoas,
} from "../../../../../redux/actions";
import SelectPagination from "../../../../../components/SelectPagination";
import { Typeahead } from "react-bootstrap-typeahead";
const FirstCard = ({
    conta,
    validFields,
    handleChange,
    validator,
    errors,
    loadingListGrupos,
    loadingListPessoas,
    listPessoas,
    listGrupos,
    handleChangeSelect,
    getListPessoas,
    getListContasGrupos
}) => {
    const optionsGrupos = useMemo(() =>
        listGrupos.data.map(({ id, grc_cd_grupo, grc_nm_grupo, grupo_pai }) => {
            const name = grc_nm_grupo.trimEnd();
            return (
                {
                    name,
                    value: id,
                    grc_cd_grupo,
                    grupo_pai_name: !!grupo_pai ? grupo_pai.name : "Não informado",
                    cd_grupo_pai: !!grupo_pai ? grupo_pai.cd_grupo_pai : null,
                    subItem: `Grupo Pai: ${!!grupo_pai ? grupo_pai.name : "Não informado"}`
                }
            )
        })
        , [listGrupos.data]);
    const selectedGrupo = optionsGrupos.filter((el) =>
        el.grc_cd_grupo === conta.ctn_cd_grupo &&
        el.cd_grupo_pai === conta.ctn_cd_grupo_pai);

    const optionsPessoas = useMemo(() => listPessoas.data.map(({ id, pss_nm_pessoa, pss_cd_pessoa, pss_nu_identificacao }) => {
        return ({
            value: id,
            name: `${pss_cd_pessoa} - ${pss_nm_pessoa}`,
            subItem: `Num.Identificação: ${pss_nu_identificacao}`,
            pss_cd_pessoa
        })
    }), [listPessoas.data]);

    const optionsTipo = useMemo(() => [{ name: "Venda", value: "V" }, { name: "Diversas", value: "D" }]);

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Nome*</Label>
                            <Input
                                maxLength={60}
                                placeholder="Informe o nome"
                                name="ctn_nm_conta"
                                defaultValue={conta.ctn_nm_conta}
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('ctn_nm_conta')}
                                onChange={handleChange} />
                            <FormFeedback>
                                {validator.message('ctn_nm_conta', conta.ctn_nm_conta, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Código*</Label>
                            <Input
                                maxLength={3}
                                placeholder="Informe o Código"
                                defaultValue={conta.ctn_cd_conta}
                                name="ctn_cd_conta"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('ctn_cd_conta') || !!errors.ctn_cd_conta}
                                onChange={handleChange} />
                            <FormFeedback>
                                {(!!errors.ctn_cd_conta) && errors.ctn_cd_conta.map((erro, index) => <span key={index}>{erro}</span>)}
                                {validator.message('ctn_cd_conta', conta.ctn_cd_conta, `required|max:3`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Grupo*</Label>
                            <SelectPagination
                                id="selectGrupos"
                                labelKey={(option) => `${option.name}`}
                                onBlur={() => validFields('ctn_cd_grupo')}
                                isInvalid={validator.visibleFields.includes('ctn_cd_grupo')}
                                maxResults={50}
                                placeholder="Selecione o Grupo"
                                data={optionsGrupos}
                                reduxAction={getListContasGrupos}
                                totalResults={listGrupos.total}
                                clearButton
                                isLoading={loadingListGrupos}
                                onChange={(selected) => handleChangeSelect('ctn_cd_grupo', selected)}
                                selected={(options) => options.filter((option) =>
                                    option.grc_cd_grupo === conta.ctn_cd_grupo && option.cd_grupo_pai === conta.ctn_cd_grupo_pai)}
                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_cd_grupo') ? 'block' : 'none' }}>
                                {validator.message('ctn_cd_grupo', conta.ctn_cd_grupo, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Grupo Pai</Label>
                            <Input
                                name="ctn_cd_grupo_pai"
                                disabled
                                value={!!selectedGrupo.length ? selectedGrupo[0].grupo_pai_name : ""} />
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Pessoa Padrão*</Label>
                            <SelectPagination
                                id="selectPessoa"
                                labelKey="name"
                                onBlur={() => validFields('ctn_cd_pessoa')}
                                isInvalid={validator.visibleFields.includes('ctn_cd_pessoa')}
                                maxResults={50}
                                placeholder="Selecione a Pessoa Padrão"
                                data={optionsPessoas}
                                totalResults={listPessoas.total}
                                reduxAction={getListPessoas}
                                clearButton
                                isLoading={loadingListPessoas}
                                onChange={(selected) => handleChangeSelect('ctn_cd_pessoa', selected)}
                                selected={(options) => options.filter((option) => option.pss_cd_pessoa === conta.ctn_cd_pessoa)}
                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_cd_pessoa') ? 'block' : 'none' }}>
                                {validator.message('ctn_cd_pessoa', conta.ctn_cd_pessoa, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Tipo*</Label>
                            <Typeahead
                                id="selectTipo"
                                labelKey="name"
                                options={optionsTipo}
                                onBlur={() => validFields('ctn_tp_conta')}
                                isInvalid={validator.visibleFields.includes('ctn_tp_conta')}
                                selected={optionsTipo.filter((el) => el.value === conta.ctn_tp_conta)}
                                onChange={(selected) => handleChangeSelect('ctn_tp_conta', selected)}
                                placeholder="Informe o Tipo"

                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_tp_conta') ? 'block' : 'none' }}>
                                {validator.message('ctn_tp_conta', conta.ctn_tp_conta, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ ContasGrupos, Pessoas }) => {
    const { list: listPessoas, loadingList: loadingListPessoas } = Pessoas;
    const { list: listGrupos, loadingList: loadingListGrupos } = ContasGrupos;
    return { listGrupos, loadingListGrupos, listPessoas, loadingListPessoas }
}
export default connect(mapStateToProps, {
    getListContasGrupos,
    changeListGrupos,
    updateContas,
    getListPessoas,
    changeListPessoas
})(FirstCard);