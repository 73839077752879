import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormGroup, Label, Row } from 'reactstrap';

function Opcoes({ handleChangeSelect }) {
    const optionsOrder = [
        {
            name: 'Valor',
            value: 'vla_vl_lancamento'
        },
        {
            name: 'Lançamento',
            value: 'vla_dt_lancamento',
        },

    ];
    const optionsExibir = [
        {
            name: 'Todos',
            value: 'todos'
        },
        {
            name: 'Com Documento',
            value: 'comDocumento'
        },
        {
            name: 'Sem Documento',
            value: 'semDocumento'
        },
    ];
    return (
        <div className="b-light border-2 p-10">
            <h6>Opções</h6>
            <Row>
                <Col lg="6" xs="12">
                    <FormGroup>
                        <Label>Ordernar por:</Label>
                        <Typeahead
                            id="selectOrder"
                            labelKey="name"
                            defaultSelected={optionsOrder.filter((el) => el.value === 'vla_vl_lancamento')}
                            options={optionsOrder}
                            onChange={(selected) => handleChangeSelect('orderBy', selected)}
                            placeholder="Selecione"
                            clearButton
                        />
                    </FormGroup>
                </Col>
                <Col lg="6" xs="12">
                    <FormGroup>
                        <Label>Exibir:</Label>
                        <Typeahead
                            id="selectExibir"
                            labelKey="name"
                            defaultSelected={optionsExibir.filter((el) => el.value === 'todos')}
                            options={optionsExibir}
                            placeholder="Selecione"
                            onChange={(selected) => handleChangeSelect('exibir', selected)}
                            clearButton
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default Opcoes;