import React, { useMemo } from "react";
import { Col, FormGroup, Label, Row, FormFeedback, Input } from "reactstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";

const Endereco = ({
    validator,
    errors,
    handleChange,
    handleChangeSelect,
    validFields,
    pessoa,
    listEstados,
    loadingListEstados,
    listCidades,
    loadingListCidades
}) => {
    const optionsEstados = useMemo(() =>
        listEstados.map((el) => ({ name: el.est_nm_estado, value: el.est_id_estado })),
        [listEstados]);
    const optionsCidades = useMemo(() =>
        listCidades.map((el) => ({ name: el.cid_nm_cidade, value: el.cid_id_cidade })),
        [listCidades]);
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Endereço</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Telefone*</Label>
                                <Input
                                    placeholder="Informe o Telefone"
                                    tag={InputMask}
                                    defaultValue={pessoa.pss_nu_telefone}
                                    mask={(!!pessoa.pss_nu_telefone && pessoa.pss_nu_telefone.length <= 10) ? '(99) 9999-99999' : '(99) 99999-9999'}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    name="pss_nu_telefone"
                                    invalid={validator.visibleFields.includes('pss_nu_telefone') ||
                                        !!errors.pss_nu_telefone ||
                                        (!validator.fields.pss_nu_telefone && validator.messagesShown)
                                    }
                                    onChange={handleChange}
                                    maskChar={null}
                                />
                                <FormFeedback>
                                    {validator.message('pss_nu_telefone', pessoa.pss_nu_telefone, 'required')}
                                    {(!!errors.pss_nu_telefone) && errors.pss_nu_telefone.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Logradouro*</Label>
                                <Input
                                    name="pss_ds_endereco"
                                    defaultValue={pessoa.pss_ds_endereco}
                                    placeholder="Informe o Logradouro"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    maxLength={100}
                                    onChange={handleChange}
                                    invalid={validator.visibleFields.includes('pss_ds_endereco') ||
                                        !!errors.pss_ds_endereco ||
                                        (!validator.fields.pss_ds_endereco && validator.messagesShown)
                                    }
                                />
                                <FormFeedback>
                                    {validator.message('pss_ds_endereco', pessoa.pss_ds_endereco, 'required')}
                                    {(!!errors.pss_ds_endereco) && errors.pss_ds_endereco.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>CEP*</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Informe o CEP"
                                    maskChar={""}
                                    defaultValue={pessoa.pss_nu_cep}
                                    tag={InputMask}
                                    mask={'99999-999'}
                                    name="pss_nu_cep"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('pss_nu_cep') ||
                                        !!errors.pss_nu_cep ||
                                        (!validator.fields.pss_nu_cep && validator.messagesShown)
                                    }
                                    onChange={handleChange} />
                                <FormFeedback>
                                    {validator.message('pss_nu_cep', pessoa.pss_nu_cep, 'required')}
                                    {(!!errors.pss_nu_cep) && errors.pss_nu_cep.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Estado*</Label>
                                <Typeahead
                                    id="selectEstado"
                                    labelKey="name"
                                    placeholder="Selecione o Estado"
                                    selected={optionsEstados.filter((el) => el.value === pessoa.pss_estado_id)}
                                    isLoading={loadingListEstados}
                                    disabled={loadingListEstados}
                                    clearButton
                                    onBlur={() => validFields('pss_estado_id')}
                                    isInvalid={validator.visibleFields.includes('pss_estado_id') ||
                                        !!errors.pss_estado_id ||
                                        (!validator.fields.pss_estado_id && validator.messagesShown)
                                    }
                                    emptyLabel="Nenhum registro encontrado."
                                    options={optionsEstados}
                                    onChange={(selected) => handleChangeSelect('pss_estado_id', selected)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_estado_id') ||
                                            !!errors.pss_estado_id ||
                                            (!validator.fields.pss_estado_id && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_estado_id', pessoa.pss_estado_id, 'required')}
                                    {(!!errors.pss_estado_id) && errors.pss_estado_id.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Cidade*</Label>
                                <Typeahead
                                    id="selectCidade"
                                    labelKey="name"
                                    clearButton
                                    selected={optionsCidades.filter((el) => el.value === pessoa.pss_cidade_id)}
                                    disabled={!pessoa.pss_estado_id || loadingListCidades}
                                    isLoading={loadingListCidades}
                                    emptyLabel="Nenhum registro encontrado, selecione o estado"
                                    placeholder="Selecione a Cidade"
                                    options={optionsCidades}
                                    onBlur={() => validFields('pss_cidade_id')}
                                    isInvalid={validator.visibleFields.includes('pss_cidade_id') ||
                                        !!errors.pss_cidade_id ||
                                        (!validator.fields.pss_cidade_id && validator.messagesShown)
                                    }
                                    paginationText={<span className="d-flex justify-content-center">Carregar Mais</span>}
                                    onChange={(selected) => handleChangeSelect('pss_cidade_id', selected)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('pss_cidade_id') ||
                                            !!errors.pss_cidade_id ||
                                            (!validator.fields.pss_cidade_id && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('pss_cidade_id', pessoa.pss_cidade_id, 'required')}
                                    {(!!errors.pss_cidade_id) && errors.pss_cidade_id.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Estados, Cidades }) => {
    const { list: listEstados, loadingList: loadingListEstados } = Estados;
    const { list: listCidades, loadingList: loadingListCidades } = Cidades;
    return { listEstados, loadingListEstados, listCidades, loadingListCidades }
}

export default connect(mapStateToProps, {})(Endereco)