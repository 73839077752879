import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody
} from 'reactstrap'
import DadosGerais from "./DadosGerais";
import AlterarSenha from './AlterarSenha';
import { format, parseISO } from 'date-fns';
import { loginEdocs } from "../../redux/actions";
const UserProfile = ({ user, tokenEdocs ,loginEdocs}) => {
  useEffect(() => {
    if (!tokenEdocs) {
      const fields = { username: 'adm@go.com', password: 'go25667' };
      loginEdocs({ fields })
    }
  }, []);
  const [url, setUrl] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="Início" title="Perfil" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader className="cardheader" style={{ height: 150 }}></CardHeader>
                <div className="user-image">
                  <div className="avatar"><Media body alt="" src={url ? url : require('../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                  <div className="icon-wrapper" data-intro="Change Profile image here">
                    <i className="icofont icofont-pencil-alt-5">
                      <input className="upload" type="file" onChange={(e) => readUrl(e)} />
                    </i>
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row >
                        <Col md="7">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-envelope mr-2"></i> Email</h6><span>{user.email}</span>
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-calendar"></i>   Data de Nascimento</h6><span>{format(parseISO(user.data_nascimento), 'dd/MM/Y')}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title"><a target="_blank" href="#javascript">{`${user.name.split(' ')[0]} ${user.name.split(' ')[user.name.split(' ').length - 1]}`}</a></div>
                        {user.groups.map((el, key) => {
                          return (<div key={key} className="desc mt-2">{el.name}</div>)
                        })}
                        {/* <div className="desc mt-2">designer</div> */}
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6><i className="fa fa-phone"></i>   Departamento </h6><span>{!!user.departamento ? user.departamento.dep_nm_departamento : 'Não Informado'}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-location-arrow"></i>   Gênero</h6><span>{user.gender === "M" ? 'Masculino' : 'Feminino'}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Card>
                    <CardBody className="p-0">
                      <Row>
                        <Col md="3" xs="12" className="b-r-primary">
                          <Nav tabs className="flex-column nav-pills" vertical={true} >
                            <NavItem>
                              <NavLink href="#javascript" className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
                                Dados Gerais
                            </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink href="#javascript" className={activeTab === 2 ? "active" : ""} onClick={() => setActiveTab(2)}>
                                Alterar Senha
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col md="9" xs="12">
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <DadosGerais />
                            </TabPane>
                            <TabPane tabId={2}>
                              <AlterarSenha />
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
const mapStateToProps = ({ Auth, EDocs }) => {
  const { user } = Auth;
  const { tokenEdocs } = EDocs;
  return { user, tokenEdocs }
}

export default connect(mapStateToProps, {
  loginEdocs
})(UserProfile);