import React, { useMemo, useState } from 'react';
import { connect } from "react-redux";
import { FormGroup, Label, Row, Col } from 'reactstrap';
import SelectPagination from "../../../../../components/SelectPagination";
import { getListGrcContasEmp } from "../../../../../redux/actions";

const labelGrupos = [
    {
        name: 'emp_cd_grp_imobilizado',
        label: 'Imobilizado',
        grupoPai: 'emp_cd_grp_pai_imobilizado',
        relationName: 'grc_imobilizado'
    },
    {
        name: 'emp_cd_grp_banco',
        label: 'Banco',
        grupoPai: 'emp_cd_grp_pai_banco',
        relationName: 'grc_banco'
    },
    {
        name: 'emp_cd_grp_aplicacao',
        label: 'Aplicação',
        grupoPai: 'emp_cd_grp_pai_aplicacao',
        relationName: 'grc_aplicacao'
    }
];
function Grupos({
    listGrcContaEmp,
    loadingGrcContaEmp,
    getListGrcContasEmp,
    handleChangeSelect,
    fields
}) {

    const optionsGrupos = useMemo(() => {
        const res = listGrcContaEmp.data.map((grupo) => ({
            name: `${grupo.grc_cd_grupo} - ${grupo.grc_nm_grupo}`,
            subItem: `Grupo pai: ${grupo.grupo_pai?.name}`,
            value: grupo.id,
            codigo: grupo.grc_cd_grupo,
            codGrupoPai: grupo.grupo_pai?.cd_grupo_pai
        }));
        labelGrupos.forEach(({ name, grupoPai, relationName }) => {
            if (!!fields[name] &&
                !!fields[grupoPai] &&
                !!fields[relationName] &&
                !res.find((grc) => grc.codigo === fields[name] && grc.codGrupoPai === fields[grupoPai])) {
                const grupo = fields[relationName];
                res.push({
                    name: `${grupo.grc_cd_grupo} - ${grupo.grc_nm_grupo}`,
                    subItem: `Grupo pai: ${grupo.grupo_pai?.name}`,
                    value: grupo.id,
                    codigo: grupo.grc_cd_grupo,
                    codGrupoPai: grupo.grupo_pai?.cd_grupo_pai
                });
            }

        });
        return res;
    }, [listGrcContaEmp.data]);

    const [indexFocusGrc, setIndexFocusGrc] = useState(null);

    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Grupos</h6></Label>
                    <Row>
                        {labelGrupos.map(({ name, label, grupoPai }, index) => {
                            return (
                                <Col xs="12" key={index}>
                                    <FormGroup>
                                        <Label>{label}</Label>
                                        <SelectPagination
                                            id={`selectImobilizados${index}`}
                                            labelKey="name"
                                            placeholder={`Informe o Grupo ${label}`}
                                            selected={options => options.filter(option =>
                                                option.codigo === fields[name] &&
                                                option.codGrupoPai === fields[grupoPai]
                                            )}
                                            clearButton
                                            data={optionsGrupos}
                                            onFocus={() => {
                                                setIndexFocusGrc(index);
                                            }}
                                            onBlur={() => {
                                                setIndexFocusGrc(null);
                                            }}
                                            isLoading={loadingGrcContaEmp && (indexFocusGrc === index || indexFocusGrc === null)}
                                            reduxAction={getListGrcContasEmp}
                                            onChange={selected => handleChangeSelect(name, selected)}
                                        />
                                    </FormGroup>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </FormGroup>
        </>
    )
}

const mapStateToProps = ({ ContasGrupos }) => {
    const { listGrcContaEmp, loadingGrcContaEmp } = ContasGrupos;
    return { listGrcContaEmp, loadingGrcContaEmp }
}

export default connect(mapStateToProps, {
    getListGrcContasEmp
})(Grupos);