import {
    GET_TIPOS_MOV_PRODUTOS_ASYNC,
    STORE_TIPOS_MOV_PRODUTOS_ASYNC,
    DESTROY_TIPOS_MOV_PRODUTOS_ASYNC,
    UPDATE_TIPOS_MOV_PRODUTOS_ASYNC
} from "../actionTypes";

export const getTiposMovPrd = (payload = {}) => ({
    type: GET_TIPOS_MOV_PRODUTOS_ASYNC,
    payload
});

export const storeTiposMovPrd = payload => ({
    type: STORE_TIPOS_MOV_PRODUTOS_ASYNC,
    payload
});

export const destroyTiposMovPrd = payload => ({
    type: DESTROY_TIPOS_MOV_PRODUTOS_ASYNC,
    payload
});

export const updateTiposMovPrd = payload => ({
    type: UPDATE_TIPOS_MOV_PRODUTOS_ASYNC,
    payload
});
