import React, { Fragment, useEffect, useRef, useState } from 'react';
import { format, parseISO } from "date-fns";
import Breadcrumb from '../../../../layout/breadcrumb'
import PrintComponent from "../../../../components/PrintComponent";
import { Container, Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { getRltCnt } from "../../../../redux/actions";
import { toast } from "react-toastify";
import Relatorio from "./Relatorio";
import Periodo from "./Periodo";
import CentroCusto from "./CentroCusto";
import Contas from "./Contas";
import Pessoas from "./Pessoas";

function RltContraPartidas({
    getRltCnt,
    rltCnt,
    active_user_empresa,
    loadingRltCnt
}) {

    const [filter, setFilter] = useState({
        dataInicial: format(new Date(), 'yyyy-MM-dd'),
        dataFinal: format(new Date(), 'yyyy-MM-dd'),
        centrosCusto: [],
        contas: [],
        pessoas: []
    });

    useEffect(() => {
        getRltCnt({ fields: { dataInicial: '2021-01-01', dataFinal: '2021-05-05' } });
    }, []);


    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Operacional Contrapartidas ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });


    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dataFinal':
            case 'dataInicial':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: format(new Date(), 'yyyy-MM-dd') })
                }
                break;
            case 'centrosCusto':
            case 'contas':
            case 'pessoas':
                setFilter({ ...filter, [name]: !!selected.length ? selected.map((opt) => opt.value) : [] });
                break;
            default:
                setFilter({ ...filter, [name]: !!selected[0]?.value || null })
                break;
        }
    }

    const onSubmitFilter = e => {
        e.preventDefault();
        getRltCnt({ fields: filter })
    }

    const printCompRef = useRef();


    return (
        <Fragment>
            <Breadcrumb parent="Operacional" title="Contrapartidas" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={onSubmitFilter}>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Periodo filter={filter} handleChangeSelect={handleChangeSelect} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <CentroCusto handleChangeSelect={handleChangeSelect} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Contas handleChangeSelect={handleChangeSelect} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Pessoas handleChangeSelect={handleChangeSelect} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" sm="12" className="d-flex justify-content-center" style={{ alignItems: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingRltCnt}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card className="shadow">
                            <CardBody>
                                {!!Object.keys(rltCnt).length &&
                                    <div className="m-b-10 d-flex justify-content-end">
                                        <Button size="sm" onClick={handlePrint} className="f-16">
                                            <i className="fa fa-print"></i>
                                            <span className="p-l-5">
                                                Imprimir
                                             </span>
                                        </Button>
                                    </div>
                                }
                                <PrintComponent ref={printCompRef}>
                                    {!!Object.keys(rltCnt).length &&
                                        <div className="d-flex justify-content-start font-arial-rlt">
                                            <span className="">
                                                {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - 
                                        Relatório de Contrapartidas ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })}, 
                                        ${format(parseISO(filter.dataInicial), 'dd/MM/yy')} a ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })} ,
                                        ${format(parseISO(filter.dataFinal), 'dd/MM/yy')}`}
                                            </span>
                                        </div>
                                    }
                                    <Relatorio />
                                </PrintComponent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ RltOperacionais, Auth }) => {
    const { active_user_empresa } = Auth;
    const { rltCnt, loadingRltCnt } = RltOperacionais;

    return { rltCnt, active_user_empresa, loadingRltCnt }
}


export default connect(mapStateToProps, {
    getRltCnt
})(RltContraPartidas);