import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, FormFeedback } from "reactstrap";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { parseISO } from 'date-fns';
const Datas = ({ fields, validator, validFields, handleChangeSelect, errors }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label><h6>Datas</h6></Label> */}
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Data Competência*</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Selecione a Data Competência"
                                    minDate={new Date(1930, 0, 1)}
                                    maxDate={new Date()}
                                    selected={!!fields.lan_dt_competencia ? parseISO(fields.lan_dt_competencia) : null}
                                    onCalendarClose={() => validFields('lan_dt_competencia')}
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control digits 
                                                 ${validator.visibleFields.includes('lan_dt_competencia') ||
                                            !!errors.lan_dt_competencia ||
                                            (!validator.fields.lan_dt_competencia && validator.messagesShown)
                                            ? 'is-invalid' : ''}`}
                                    onChange={(selected) => handleChangeSelect('lan_dt_competencia', selected)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('lan_dt_competencia') ||
                                            !!errors.lan_dt_competencia ||
                                            (!validator.fields.lan_dt_competencia && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}
                                >
                                    {validator.message('lan_dt_competencia', fields.lan_dt_competencia, `required`)}
                                    {(!!errors.lan_dt_competencia) && errors.lan_dt_competencia.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(Datas);