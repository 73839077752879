import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";
import MultiSelect from "../../../../components/MultiSelect";
import {
    getListContas,
} from "../../../../redux/actions";
const Contas = ({
    listContas,
    pessoa,
    loadingListContas,
    getListContas,
    handleChangeSelect
}) => {
    const optionsContas = useMemo(() =>
        listContas.data.map(({ id, ctn_cd_conta, ctn_nm_conta }) =>
        ({
            label: `${ctn_cd_conta} - ${ctn_nm_conta}`,
            value: id,
            codigo: ctn_cd_conta,
        })),
        [listContas.data]);
   
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Vincular Contas</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Contas</Label>
                                <MultiSelect
                                    data={optionsContas}
                                    onChange={(selected) => {
                          
                                        handleChangeSelect('contas', selected)
                                    }}
                                    isLoading={loadingListContas}
                                    onSearch={(value) => {
                                        const filter = optionsContas.filter(({ label }) => new RegExp(`${value}`, 'i').test(label));
                                        if (!filter.length) {
                                            getListContas({ fields: { name: value } });
                                        }
                                        if (!value) {
                                            getListContas();
                                        }
                                    }}
                                    defaultSelected={pessoa.contas.map(({ id, ctn_nm_conta, ctn_cd_conta }) =>
                                    ({
                                        label: `${ctn_cd_conta}-${ctn_nm_conta}`,
                                        value: id,
                                        codigo: ctn_cd_conta,
                                    }))}
                                    pagination
                                    onPaginate={(offset, name) => {
                                        getListContas({ fields: { offset, name } })
                                    }}
                                    totalResults={listContas.total}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, loadingList: loadingListContas } = Contas;
    return { listContas, loadingListContas }
}

export default connect(mapStateToProps, {
    getListContas
})(Contas);