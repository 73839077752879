import React from 'react';
import { Col, FormGroup, Label, Row, Input, FormFeedback, InputGroupAddon, InputGroup } from "reactstrap";
import NumberFormat from 'react-number-format';
function QntAndValor({
    validator,
    validFields,
    fields,
    handleValor,
    handleChange
}) {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Quantidade*</Label>
                            <Input
                                name="cmv_qtd_quantidade"
                                step="0.01"
                                onChange={handleChange}
                                defaultValue={parseFloat(fields.cmv_qtd_quantidade || 0)}
                                type="number"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={
                                    validator.visibleFields.includes('cmv_qtd_quantidade')
                                    || (!validator.fields.cmv_qtd_quantidade && validator.messagesShown)}
                                placeholder="Informe a Quantidade" />
                            <FormFeedback>
                                {validator.message('cmv_qtd_quantidade', fields.cmv_qtd_quantidade, 'required')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Valor Unidade*</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                <NumberFormat
                                    placeholder="Informe o Valor"
                                    thousandSeparator='.'
                                    defaultValue={parseFloat(fields.cmv_vlr_valor || 0)}
                                    customInput={Input}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={
                                        validator.visibleFields.includes('cmv_vlr_valor') ||
                                        (!validator.fields.cmv_vlr_valor && validator.messagesShown)
                                    }
                                    name="cmv_sld_valor"
                                    decimalSeparator=","
                                    decimalScale={4}
                                    fixedDecimalScale
                                    onValueChange={({ value }) => handleValor('cmv_vlr_valor', value)}
                                />
                            </InputGroup>
                            <FormFeedback style={{
                                display: validator.visibleFields.includes('cmv_sld_valor')
                                    || (!validator.fields.cmv_sld_valor && validator.messagesShown) ? 'block' : 'none'
                            }
                            }>
                                {validator.message('cmv_vlr_valor', fields.cmv_vlr_valor, 'required')}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Valor Total</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                                <NumberFormat
                                    disabled
                                    thousandSeparator='.'
                                    customInput={Input}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={!!fields.cmv_vlr_valor && !!fields.cmv_qtd_quantidade ?
                                        parseFloat(fields.cmv_qtd_quantidade) * parseFloat(fields.cmv_vlr_valor) :
                                        ""
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default QntAndValor;