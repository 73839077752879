import React, { useState } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, FormGroup, Input, Row, Col, Label, Table, Badge, Alert, UncontrolledTooltip, Button } from 'reactstrap';
import ScrollArea from "react-scrollbar";
import { format, parseISO } from 'date-fns';
import { Fragment } from 'react';
function Extratos({
    loadingReadOfxBanco,
    extratoBanco,
    filter,
    ScrollExtratoRef,
    onSelectExtrato,
    selectedExtrato,
    listConciliados,
    onSelectTar,
    onHandleRlt,
    onHandleConcAuto,
    loadingConciliarFtr
}) {

    const [hoverIndex, setHoverIndex] = useState(null);


    let totalDeb = 0;
    let totalCred = 0;
    let extratos = !!extratoBanco ? extratoBanco.extratos : [];

    if (!!filter.data && !!extratoBanco) {
        extratos = extratoBanco.extratos.filter(({ dia, valor, tipo }) => {
            let res = false;
            if (dia === filter.data) {
                totalDeb += tipo === "D" ? valor : 0;
                totalCred += tipo === "C" ? valor : 0;
                res = true;
            }
            return res;
        });
    } else {
        totalDeb = extratos.reduce((acu, { valor, tipo }) => acu + (tipo === "D" ? valor : 0), 0);
        totalCred = extratos.reduce((acu, { valor, tipo }) => acu + (tipo === "C" ? valor : 0), 0);
    }

    return (
        <>
            <Card className="card-absolute">
                <CardHeader className="bg-primary">
                    <h5>Extratos</h5>
                </CardHeader>
                <CardBody>
                    {
                        loadingReadOfxBanco ?
                            <div className="loader-box">
                                <div className="loader-1"></div>
                            </div>
                            :
                            <Row>
                                <Col xs="12" className="d-flex justify-content-end m-b-10">
                                    <div className="d-flex" style={{ overflowX: "auto" }}>
                                        <Button
                                            onClick={() => { onHandleRlt() }}
                                            disabled={!extratos.filter((ext) => ext.valor !== 0).length}

                                        >
                                            <i className="fa fa-file-text"></i> Gerar Relatório
                                            </Button>
                                        <Button
                                            onClick={() => { onHandleConcAuto(extratos.filter((ext) => ext.valor !== 0)) }}
                                            disabled={!extratos.filter((ext) => ext.valor !== 0).length || loadingConciliarFtr}
                                            className="m-l-10"
                                        >
                                            <i className="fa fa-random"></i> {loadingConciliarFtr ? 'Processando...' : 'Concilar Automaticamente'}
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <Row>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Label>Banco</Label>
                                                <Input
                                                    value={extratoBanco?.banco} readOnly
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Label>Período</Label>
                                                <Input
                                                    value={!!extratoBanco?.perInicial && !!extratoBanco?.perFinal ?
                                                        `${format(parseISO(extratoBanco.perInicial), 'dd/MM/yyyy')} a ${format(parseISO(extratoBanco.perFinal), 'dd/MM/yyyy')}`
                                                        :
                                                        ''
                                                    }
                                                    readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label>AG/CC</Label>
                                                <Input
                                                    value={extratoBanco?.agCc}
                                                    readOnly />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" className="p-10">
                                    <ScrollArea ref={ScrollExtratoRef} horizontal={false}>
                                        {!!extratos.length ?
                                            <Table
                                                onMouseLeave={() => {
                                                    setHoverIndex(null)
                                                }}
                                                responsive
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <thead>
                                                    <tr>
                                                        {/* <th></th> */}
                                                        <th>C</th>
                                                        <th>Data</th>
                                                        <th>Documento</th>
                                                        <th>D/C</th>
                                                        <th>Valor</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {extratos.map((extrato, indexExt) => {
                                                        const chkConciliado = !!listConciliados.find((el) => el.extrato.documento === extrato.documento);
                                                        const valorZerado = !extrato.valor;
                                                        return (
                                                            <Fragment key={indexExt}>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        setHoverIndex(extrato.pos)
                                                                    }}
                                                                    onClick={() => {
                                                                        if ((!extrato.chkConc && !valorZerado) || loadingConciliarFtr) {
                                                                            onSelectExtrato(extrato);
                                                                        }
                                                                    }}
                                                                    className={valorZerado || loadingConciliarFtr ? 'disabled' : ''}
                                                                    style={{
                                                                        cursor: extrato.chkConc || valorZerado || loadingConciliarFtr ? 'auto' : 'pointer',
                                                                        backgroundColor: ((hoverIndex === extrato.pos) || (selectedExtrato === extrato.pos)) &&
                                                                            !extrato.chkConc ?
                                                                            'rgba(52, 52, 52, 0.2)' : ''
                                                                    }} >
                                                                    <td
                                                                        className={indexExt < (extratos.length - 1) ? 'b-b-dark border-2' : ''}
                                                                        style={{ textAlign: 'center', width: 20 }}
                                                                        rowSpan="2"
                                                                    >
                                                                        <Badge className="m-t-20" color={chkConciliado || extrato.chkConc ? 'success' : 'danger'}>
                                                                            {chkConciliado || extrato.chkConc ? 'S' : 'N'}
                                                                        </Badge>
                                                                    </td>
                                                                    <td>
                                                                        {format(parseISO(extrato.dia), 'dd/MM/yyyy')}
                                                                    </td>
                                                                    <td>
                                                                        {extrato.documento}
                                                                    </td>
                                                                    <td>
                                                                        {extrato.tipo}
                                                                    </td>
                                                                    <td>
                                                                        {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(extrato.valor)}
                                                                    </td>
                                                                    <td className="d-flex jutify-content-center">
                                                                        <div style={{ display: "flex", overflow: "auto" }}>
                                                                            <a
                                                                                sid="lancamentos.store"
                                                                                title="Adicionar Contrapartida"
                                                                                id={`addCnt${indexExt}`}
                                                                                className={`${extrato.chkConc || loadingConciliarFtr ? 'disabled' : ''}`}
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    if ((!extrato.chkConc && !valorZerado) || loadingConciliarFtr) {
                                                                                        onSelectTar(extrato);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-plus-square font-primary f-24"></i>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        setHoverIndex(extrato.pos)
                                                                    }}
                                                                    onClick={() => {
                                                                        if (!extrato.chkConc && !valorZerado) {
                                                                            onSelectExtrato(extrato);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        cursor: extrato.chkConc || valorZerado ? 'auto' : 'pointer',
                                                                        backgroundColor: ((hoverIndex === extrato.pos) || (selectedExtrato === extrato.pos)) &&
                                                                            !extrato.chkConc ?
                                                                            'rgba(52, 52, 52, 0.2)' : ''
                                                                    }}
                                                                    className={`
                                                                ${indexExt < (extratos.length - 1) ? 'b-b-dark border-2' : ''}
                                                                ${valorZerado ? 'disabled' : ''}
                                                                `}>
                                                                    <td colSpan="6">
                                                                        {extrato.desc}
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </Table>

                                            :
                                            <Alert color="light">Nenhum extrato foi encontrado para a data informada.</Alert>
                                        }
                                    </ScrollArea>
                                    <div className="p-10 b-dark m-t-15 d-flex justify-content-around">
                                        <span>Débito: {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalDeb)}</span>
                                        <span>Crédito {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalCred)}</span>
                                    </div>
                                </Col>
                            </Row>
                    }
                </CardBody>
            </Card>
        </>
    )
}

const mapStateToProps = ({ Arquivos, Faturas }) => {
    const { extratoBanco, loadingReadOfxBanco } = Arquivos;
    const { loadingConciliarFtr } = Faturas;
    return { extratoBanco, loadingReadOfxBanco, loadingConciliarFtr };
}

export default connect(mapStateToProps, {

})(Extratos);