import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, Row, Col, FormGroup, Input, ButtonGroup, Button, Label, ModalHeader, ModalFooter, Alert, ListGroup, ListGroupItem } from "reactstrap";
import ScrollArea from 'react-scrollbar';
const ViewModulos = ({ isOpen, selectedModulo, modulos, clickClose }) => {
    const dados = !!modulos && modulos.data[selectedModulo];
    return (
        <Modal isOpen={isOpen} size="lg">
            <ModalHeader toggle={() => clickClose()}>
                Informações do Módulo
            </ModalHeader>
            <ModalBody>
                {!!dados &&
                    <Row>
                        <Col md="8" sm="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input readOnly defaultValue={dados.name} />
                            </FormGroup>
                        </Col>
                        <Col md="4" sm="12">
                            <Label className="d-block">Status</Label>
                            <ButtonGroup>
                                <Button outline disabled active={dados.active === "1"} color="success">
                                    Ativo
                                        </Button>
                                <Button outline disabled active={dados.active === "0"} color="danger">
                                    Inativo
                                        </Button>
                            </ButtonGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Funcionalidades</Label>
                                {!!dados.funcionalidades.length ?
                                    <ScrollArea vertical={true} horizontal={false}>
                                        <ListGroup>
                                            {dados.funcionalidades.map(({ name, id }) => {
                                                return (
                                                    <ListGroupItem
                                                        key={id}
                                                        className="list-group-item-action">
                                                        {name}
                                                    </ListGroupItem>
                                                );
                                            })
                                            }
                                        </ListGroup>
                                    </ScrollArea>
                                    :
                                    <Alert color="light">Nenhuma Funcionalidade Vinculada</Alert>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()} color="secondary">Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ Modulos }) => {
    const { modulos } = Modulos;
    return { modulos };
}
export default connect(mapStateToProps, {})(ViewModulos);