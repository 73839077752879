import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";
import View from "./View/"
import DataTable from "react-data-table-component";

const ContraPartidas = ({ lancamento }) => {
    const [openModal, setOpenModal] = useState(null);
    const [selectedContrapartida, setSelectedContrapartida] = useState(null);
    return (
        <>
            <View
                isOpen={openModal === 'view'}
                clickClose={() => setOpenModal(null)}
                contrapartida={lancamento.contrapartidas[selectedContrapartida]}
            />
            <Col xs="12">
                <FormGroup>
                    <div className="shadow shadow-showcase p-15">
                        <div className="d-flex justify-content-between b-b-light">
                            <Label><h6>Contrapartida</h6></Label>
                        </div>
                        <Row>
                            <Col xs="12">
                                <Fragment>
                                    <DataTable
                                        noHeader
                                        noDataComponent={<span className="p-20">Nenhuma Contrapartida Vinculada</span>}
                                        highlightOnHover
                                        pointerOnHover
                                        onRowClicked={(row) => {
                                            setSelectedContrapartida(row.key)
                                            setOpenModal('view')
                                        }}
                                        columns={[
                                            {
                                                name: 'Conta',
                                                selector: 'conta_name',
                                            },
                                            {
                                                name: 'Pessoa',
                                                selector: 'pessoa_name',
                                            },
                                            {
                                                name: 'Centro',
                                                selector: 'centro_name',
                                            },
                                            {
                                                name: 'Documento',
                                                selector: 'cnt_nu_doc',
                                            },
                                            {
                                                name: 'Valor',
                                                selector: 'valor_contrapartida',
                                            },
                                            {
                                                name: 'Histórico',
                                                selector: 'cnt_ds_historico',
                                            }
                                        ]}
                                        data={lancamento.contrapartidas.map(({
                                            conta,
                                            pessoa,
                                            centro_custo,
                                            cnt_nu_doc,
                                            cnt_vl_contrapartida,
                                            cnt_ds_historico
                                        },
                                            key
                                        ) => {
                                            const conta_name = `${conta?.ctn_cd_conta} - ${conta?.ctn_nm_conta}`;
                                            const pessoa_name = `${pessoa?.pss_cd_pessoa} - ${pessoa?.pss_nm_pessoa}`;
                                            const centro_name = `${centro_custo?.cct_cd_centro} - ${centro_custo?.cct_nm_centro}`
                                            const valor_contrapartida = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(cnt_vl_contrapartida);
                                            return { key, conta_name, pessoa_name, centro_name, cnt_nu_doc, valor_contrapartida, cnt_ds_historico }
                                        })}
                                    />
                                </Fragment>
                            </Col>
                        </Row>
                    </div>
                </FormGroup>
            </Col>
        </>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(ContraPartidas)