import React, { Fragment, useState, useRef, useEffect } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardTitle, Form, Button, FormGroup } from 'reactstrap';
import { getRltOperacionalLan, clearRltOperacionalLan } from "../../../../redux/actions";
import { connect } from "react-redux";
import { format } from 'date-fns';
import Periodo from "./Periodo";
import Opcoes from "./Opcoes";
import RtlLancamento from "./RtlLancamento";
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
const RltLancamentos = ({
    getRltOperacionalLan,
    loadingRltOperacinalLan,
    clearRltOperacionalLan
}) => {
    useEffect(() => {
        return () => {
            clearRltOperacionalLan()
        }
    }, [])

    const [filter, setFilter] = useState({
        dataInicial: format(new Date(), 'Y-MM-dd'),
        dataFinal: format(new Date(), 'Y-MM-dd'),
        orderBy: 'vla_vl_lancamento',
        exibir: 'todos'

    });
    const printCompRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Operacional Lançamentos ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        // onBeforePrint: () => setLoadingPrint(true),
        // onAfterPrint: () => setLoadingPrint(false),
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });

    const onSubmitForm = e => {
        e.preventDefault();

        getRltOperacionalLan({ fields: filter })
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dataFinal':
            case 'dataInicial':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'Y-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: format(new Date(), 'Y-MM-dd') })
                }
                break;
            default:
                if (!!selected.length) {
                    setFilter({ ...filter, [name]: selected[0].value });
                } else {
                    setFilter({ ...filter, [name]: null });
                }
                break;
        }

    }
    return (
        <Fragment>
            <Breadcrumb parent="Operacional" title="Lançamentos" />
            <Container fluid={true}>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <CardTitle><h5>Filtro</h5></CardTitle>
                                <Form onSubmit={onSubmitForm}>
                                    <Row>
                                        <Col lg="6" xs="12" className="m-b-5">
                                            <Periodo
                                                handleChangeSelect={handleChangeSelect}
                                                filter={filter}
                                            />
                                        </Col>
                                        <Col lg="5" xs="12" className="m-b-5">
                                            <Opcoes
                                                handleChangeSelect={handleChangeSelect}
                                            />
                                        </Col>
                                        <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingRltOperacinalLan}
                                                    className="m-t-45"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12">
                        <RtlLancamento
                            filter={filter}
                            printCompRef={printCompRef}
                            handlePrint={handlePrint}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ VlaLancamentos }) => {
    const { loadingRltOperacinalLan } = VlaLancamentos;
    return { loadingRltOperacinalLan }
}

export default connect(mapStateToProps, {
    getRltOperacionalLan,
    clearRltOperacionalLan
})(RltLancamentos);