import { all, put, call, fork, takeLatest } from "redux-saga/effects";
import Api from "../../services/api";
import {
    READOFX_EXTRATO_BANCO_ASYNC,
    READOFX_EXTRATO_BANCO,
    READTXT_EXTRATO_BANCO_ASYNC,
    READTXT_EXTRATO_BANCO
} from "../actionTypes";

function* readOfxExtratoBancoSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiReadOfxExtratoBanco, fields);
        if (data.ok) {
            const { extratoBanco } = data;
            yield put({ type: READOFX_EXTRATO_BANCO, payload: { extratoBanco } });
        }
    } catch (error) {
        yield put({ type: READOFX_EXTRATO_BANCO, payload: {} });
    }
}
const apiReadOfxExtratoBanco = async fields => {

    const formData = new FormData();
    formData.append('arquivoOfx', fields.arquivoBanco, fields.arquivoBanco.name);


    const { data } = await Api.post(`/api/arquivos/readOfx`, formData);

    return data;
}

function* readTxtExtratoBancoSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiReadTxtExtratoBanco, fields);
        if (data.ok) {
            const { extratoBanco } = data;
            yield put({ type: READTXT_EXTRATO_BANCO, payload: { extratoBanco } });
        }
    } catch (error) {
        yield put({ type: READTXT_EXTRATO_BANCO, payload: { } });
    }
}
const apiReadTxtExtratoBanco = async fields => {
    const formData = new FormData();
    formData.append('arquivoTxt', fields.arquivoBanco, fields.arquivoBanco.name);
    const { data } = await Api.post(`/api/arquivos/readBancoTxt`, formData);

    return data;
}

function* watchReadOfxExtratoBanco() {
    yield takeLatest(READOFX_EXTRATO_BANCO_ASYNC, readOfxExtratoBancoSaga);
}
function* watchReadTxtExtratoBanco() {
    yield takeLatest(READTXT_EXTRATO_BANCO_ASYNC, readTxtExtratoBancoSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchReadOfxExtratoBanco),
        fork(watchReadTxtExtratoBanco)
    ]);

}