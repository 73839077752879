import React, { useState, useCallback, useRef } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Form,
    Row,
    Col
} from "reactstrap";
import { connect } from "react-redux";
import DadosGerais from "./DadosGerais";
import QntAndValor from "./QntAndValor";
import { format } from "date-fns";
import { storeCustoProduto } from "../../../../../redux/actions";
import { validatorMessages } from "../../../../../constraints/variables";
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';

const intialState = {
    cmv_dt_data: "",
    cmv_prd_produto: "",
    cmv_qtd_quantidade: "",
    cmv_vlr_valor_produto: "",
    cmv_sld_valor: "",
    cmv_tp_tipo: "",
    cmv_vlr_valor: "",
};
function Add({
    isOpen,
    clickClose,
    storeCustoProduto,
    loadingStoreCustoProd,
    custosProdutos
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    const [fields, setFields] = useState(intialState);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleValor = (name, value) => {
        switch (name) {
            case 'cmv_vlr_valor':
                setFields({ ...fields, [name]: value })
                break;
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'cmv_dt_data':
                if (!!selected) {
                    setFields({ ...fields, [name]: format(selected, 'yyyy-MM-dd') });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const cmv_vlr_valor_produto = parseFloat(fields.cmv_qtd_quantidade) * parseFloat(fields.cmv_vlr_valor);
            storeCustoProduto({ fields: { ...fields, cmv_vlr_valor_produto }, onCreate: clickClose });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }
    }
 
    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            onClosed={() => {
                setFields(intialState)
                validator.visibleFields = [];
                validator.messagesShown = false;
            }}
        >
            <ModalHeader toggle={() => !loadingStoreCustoProd && clickClose()}>
                Adicionar Custo por Produto
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <DadosGerais
                                fields={fields}
                                handleChange={handleChange}
                                validator={validator}
                                handleChangeSelect={handleChangeSelect}
                                validFields={validFields}
                            />
                        </Col>
                        <Col xs="12">
                            <QntAndValor
                                validator={validator}
                                validFields={validFields}
                                handleChange={handleChange}
                                handleValor={handleValor}
                                fields={fields}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={loadingStoreCustoProd}
                        onClick={() => !loadingStoreCustoProd && clickClose()}
                        type="button">
                        Fechar
                </Button>
                    <Button disabled={loadingStoreCustoProd} color="primary">
                        {loadingStoreCustoProd ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ CmvDiario }) => {
    const { loadingStoreCustoProd, custosProdutos } = CmvDiario;
    return { loadingStoreCustoProd, custosProdutos }
}

export default connect(mapStateToProps, {
    storeCustoProduto
})(Add);