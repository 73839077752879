import {
    GET_TIPOS_MOV_PRODUTOS_ASYNC,
    GET_TIPOS_MOV_PRODUTOS,
    STORE_TIPOS_MOV_PRODUTOS_ASYNC,
    STORE_TIPOS_MOV_PRODUTOS,
    DESTROY_TIPOS_MOV_PRODUTOS_ASYNC,
    DESTROY_TIPOS_MOV_PRODUTOS,
    UPDATE_TIPOS_MOV_PRODUTOS_ASYNC,
    UPDATE_TIPOS_MOV_PRODUTOS
} from "../actionTypes";

const initialState = {
    errors: {},
    loadingTiposMovPrd: false,
    loadingStoreTiposMovPrd: false,
    loadingDestroyTiposMovPrd: false,
    loadingUpdateTipoMovPrd: false,
    tiposMovsPrd: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TIPOS_MOV_PRODUTOS_ASYNC:
            return { ...state, loadingTiposMovPrd: true };

        case GET_TIPOS_MOV_PRODUTOS:
            return { ...state, ...action.payload, loadingTiposMovPrd: false };

        case STORE_TIPOS_MOV_PRODUTOS_ASYNC:
            return { ...state, loadingStoreTiposMovPrd: true };

        case STORE_TIPOS_MOV_PRODUTOS:
            return { ...state, ...action.payload, loadingStoreTiposMovPrd: false };

        case DESTROY_TIPOS_MOV_PRODUTOS_ASYNC:
            return { ...state, loadingDestroyTiposMovPrd: true };

        case DESTROY_TIPOS_MOV_PRODUTOS:
            return { ...state, ...action.payload, loadingDestroyTiposMovPrd: false };

        case UPDATE_TIPOS_MOV_PRODUTOS_ASYNC:
            return { ...state, loadingUpdateTipoMovPrd: true };

        case UPDATE_TIPOS_MOV_PRODUTOS:
            return { ...state, ...action.payload, loadingUpdateTipoMovPrd: false };

        default: return state;
    }
}