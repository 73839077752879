import React, { } from 'react';
import { connect } from "react-redux";
import { Row, Table, Col, Alert } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import { Fragment } from 'react';

function Relatorio({ rltTitulosNaoRecPagos }) {
    let total = 0;
    return (
        <Row className="b-light p-15 border-5 m-b-20">
            <Col xs="12">
                <Table className="font-arial-rlt">
                    <thead>
                        <tr className="text-center">
                            <th className="b-light border-3">Pessoa</th>
                            <th className="b-light border-3">Fatura</th>
                            <th className="b-light border-3">Valor</th>
                            <th className="b-light border-3">Lançamento</th>
                            {/* <th className="b-light border-3">Tipo</th> */}
                            <th className="b-light border-3">Vencimento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rltTitulosNaoRecPagos.length ?
                                <Fragment>
                                    {rltTitulosNaoRecPagos.map((rlt, key) => {
                                        total += parseFloat(rlt.ftr_vl_fatura);
                                        return (
                                            <tr key={key} >
                                                <td>{`${rlt.ftr_cd_subconta} - ${rlt.pss_nm_pessoa}`}</td>
                                                <td >{rlt.ftr_nu_fatura}</td>
                                                <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rlt.ftr_vl_fatura)}</td>
                                                <td>{!!rlt.ftr_dt_lancamento && format(parseISO(rlt.ftr_dt_lancamento), 'dd/MM/yyyy')}</td>
                                                {/* <td>{rlt.lan_tp_lancamento === 'C' ? 'Crédito' : rlt.lan_tp_lancamento === 'D' ? 'Débito' : 'Não Informado'}</td> */}
                                                <td>{!!rlt.ftr_dt_vencimento && format(parseISO(rlt.ftr_dt_vencimento), 'dd/MM/yyyy')}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr className="b-t-dark border-2">
                                        <td colSpan="2" className="text-right">Total:</td>
                                        <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</td>
                                        <td colSpan="2"></td>
                                    </tr>
                                </Fragment>
                                :
                                <tr>
                                    <td colSpan="5"><Alert color="light">Nenhum Registro encontrado.</Alert></td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ RltOperacionais }) => {
    const { rltTitulosNaoRecPagos } = RltOperacionais;
    return { rltTitulosNaoRecPagos }
}

export default connect(mapStateToProps, {})(Relatorio);