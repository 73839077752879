import { toast } from "react-toastify";
import { all, fork, call, put, takeLatest, select } from "redux-saga/effects";
import Api from "../../services/api";
import {
    GET_LIST_TIPO_PESSOAS,
    GET_LIST_TIPO_PESSOAS_ASYNC,
    GET_TIPOS_PESSOA_ASYNC,
    GET_TIPOS_PESSOA,
    DESTROY_TIPOS_PESSOA_ASYNC,
    DESTROY_TIPOS_PESSOA,
    STORE_TIPO_PESSOA_ASYNC,
    STORE_TIPO_PESSOA,
    UPDATE_TIPO_PESSOA_ASYNC,
    UPDATE_TIPO_PESSOA
} from "../actionTypes";
import { selectorTiposPessoas } from "../selectors";

function* getListTipoPessasSaga({ }) {
    try {
        const data = yield call(apiListTipoPessoas);
        if (data.ok) {
            const { list } = data;
            yield put({ type: GET_LIST_TIPO_PESSOAS, payload: { list } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_TIPO_PESSOAS, payload: {} });
    }
}
const apiListTipoPessoas = async () => {
    const { data } = await Api.get(`/api/tipos_pessoas/list`);

    return data;
}

function* getTiposPessoaSaga({ payload }) {
    try {
        const { fields } = payload;

        const data = yield call(apiGetTiposPessoa, fields);

        if (data.ok) {
            const { tiposPessoa } = data;
            yield put({ type: GET_TIPOS_PESSOA, payload: { tiposPessoa } });
        }

    } catch (error) {
        yield put({ type: GET_TIPOS_PESSOA, payload: {} });
    }
}
const apiGetTiposPessoa = async fields => {
    const { data } = await Api.get(`/api/tipos_pessoas`, { params: fields });

    return data;
}

function* destroyTiposPessoaSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;

        const data = yield call(apiDestroyTiposPessoa, fields);

        if (data.ok) {
            const { tiposPessoa } = data;
            yield put({ type: DESTROY_TIPOS_PESSOA, payload: { tiposPessoa } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }

    } catch (error) {
        yield put({ type: DESTROY_TIPOS_PESSOA, payload: {} });
    }
}
const apiDestroyTiposPessoa = async fields => {
    const { data } = await Api.delete(`/api/tipos_pessoas/destroy`, { data: fields });

    return data;
}

function* storeTipoPessoaSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreTipoPessoa, fields);

        if (!!data && data.ok) {
            const { tiposPessoa } = data;
            yield put({ type: STORE_TIPO_PESSOA, payload: { tiposPessoa } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave()
        }

        if (!!errors) {
            yield put({ type: STORE_TIPO_PESSOA, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: STORE_TIPO_PESSOA, payload: {} });
    }
}
const apiStoreTipoPessoa = async fields => {
    const data = await Api.post('/api/tipos_pessoas/store', fields);

    return data;
}

function* updateTipoPessoaSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;

        const tiposPessoa = { ...yield select(selectorTiposPessoas) };

        const { tps_cd_tipo } = tiposPessoa.data[index];

        const { data, errors } = yield call(apiUpdateTipoPessoa, fields, tps_cd_tipo);

        if (!!data && data.ok) {
            tiposPessoa.data.splice(index, 1, data.tipoPessoa);

            yield put({ type: UPDATE_TIPO_PESSOA, payload: { tiposPessoa } });

            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }

        if (!!errors) {
            yield put({ type: UPDATE_TIPO_PESSOA, payload: { errors } });
        }

    } catch (error) {
        yield put({ type: UPDATE_TIPO_PESSOA, payload: {} });
    }
}
const apiUpdateTipoPessoa = async (fields, tps_cd_tipo) => {
    const data = await Api.put(`/api/tipos_pessoas/update/${tps_cd_tipo}`, fields);

    return data;
}

function* watchListTiposPessoas() {
    yield takeLatest(GET_LIST_TIPO_PESSOAS_ASYNC, getListTipoPessasSaga);
}
function* watchGetTiposPessoa() {
    yield takeLatest(GET_TIPOS_PESSOA_ASYNC, getTiposPessoaSaga);
}
function* watchDestroyTiposPessoa() {
    yield takeLatest(DESTROY_TIPOS_PESSOA_ASYNC, destroyTiposPessoaSaga);
}
function* watchStoreTipoPessoa() {
    yield takeLatest(STORE_TIPO_PESSOA_ASYNC, storeTipoPessoaSaga);
}
function* watchUpdateTipoPessoa() {
    yield takeLatest(UPDATE_TIPO_PESSOA_ASYNC, updateTipoPessoaSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchListTiposPessoas),
        fork(watchGetTiposPessoa),
        fork(watchDestroyTiposPessoa),
        fork(watchStoreTipoPessoa),
        fork(watchUpdateTipoPessoa)
    ]);
}