import {
    GET_LIST_CLIENTES_ASYNC,
    GET_LIST_CLIENTES,
    GET_LIST_CLIENTES_EMPRESA_ASYNC,
    GET_LIST_CLIENTES_EMPRESA
} from "../actionTypes";

const initialState = {
    listClientes: { data: [], total: 0 },
    loadingListClientes: false,
    loadingListClientesEmp: false,
    listClientesEmp: { data: [], total: 0 }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_CLIENTES_ASYNC:
            return { ...state, loadingListClientes: true };
        case GET_LIST_CLIENTES:
            return { ...state, ...action.payload, loadingListClientes: false };
        case GET_LIST_CLIENTES_EMPRESA_ASYNC:
            return { ...state, loadingListClientesEmp: true };
        case GET_LIST_CLIENTES_EMPRESA:
            return { ...state, ...action.payload, loadingListClientesEmp: false };
        default: return state;
    }
}