import React from 'react';
import { FormGroup, Row, Col, Label, Input } from "reactstrap";
const SecondCard = ({ conta }) => {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Valor Minímo</Label>
                            <Input
                                readOnly
                                defaultValue={!!conta.ctn_vl_minimo_lan ?
                                    new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(conta.ctn_vl_minimo_lan) :
                                    "Não Informado"} />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Valor Máximo</Label>
                            <Input
                                readOnly
                                defaultValue={!!conta.ctn_vl_maximo_lan ?
                                    new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(conta.ctn_vl_maximo_lan) :
                                    "Não Informado"} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default SecondCard;