import { takeLatest, call, put, fork, all } from "redux-saga/effects";
import Api from "../../services/api";
import { GET_LIST_CIDADES_ASYNC,GET_LIST_CIDADES } from "../actionTypes";

function* getListCidadesSaga({ payload }) {
    try {
        const { estado_id } = payload;
        const data = yield call(apiListCidades, estado_id);

        if (data.ok) {
            const { list } = data;
            yield put({ type: GET_LIST_CIDADES, payload: { list } });
        }

    } catch (error) {
        yield put({ type: GET_LIST_CIDADES, payload: {} });
    }
}
const apiListCidades = async estado_id => {
    const { data } = await Api.get(`/api/cidades/list/${estado_id}`);

    return data;
}

function* watchGetListCidades() {
    yield takeLatest(GET_LIST_CIDADES_ASYNC, getListCidadesSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListCidades)
    ]);
}