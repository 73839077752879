import {
    GET_LIST_TIPOS_EMPRESAS_ASYNC,
    GET_LIST_TIPOS_EMPRESAS,
    GET_TIPOS_EMPRESA_ASYNC,
    GET_TIPOS_EMPRESA,
    STORE_TIPO_EMPRESA_ASYNC,
    STORE_TIPO_EMPRESA,
    DESTROY_TIPOS_EMPRESA_ASYNC,
    DESTROY_TIPOS_EMPRESA,
    UPDATE_TIPO_EMPRESA_ASYNC,
    UPDATE_TIPO_EMPRESA
} from "../actionTypes";

const initalState = {
    errors:{},
    listTiposEmp: { data: [], total: 0 },
    loadingListTiposEmp: false,
    loadingTiposEmp: false,
    tiposEmpresa: null,
    loadingStoreTipoEmp: false,
    loadingDestroyTiposEmp: false,
    loadingUpdateTiposEmp: false
}

export default (state = initalState, action) => {
    switch (action.type) {
        case GET_LIST_TIPOS_EMPRESAS_ASYNC:
            return { ...state, loadingListTiposEmp: true };
        case GET_LIST_TIPOS_EMPRESAS:
            return { ...state, ...action.payload, loadingListTiposEmp: false };
        case GET_TIPOS_EMPRESA_ASYNC:
            return { ...state, loadingTiposEmp: true };
        case GET_TIPOS_EMPRESA:
            return { ...state, ...action.payload, loadingTiposEmp: false };
        case STORE_TIPO_EMPRESA_ASYNC:
            return { ...state, loadingStoreTipoEmp: true };
        case STORE_TIPO_EMPRESA:
            return { ...state, ...action.payload, loadingStoreTipoEmp: false };
        case DESTROY_TIPOS_EMPRESA_ASYNC:
            return { ...state, loadingDestroyTiposEmp: true };
        case DESTROY_TIPOS_EMPRESA:
            return { ...state, ...action.payload, loadingDestroyTiposEmp: false };
        case UPDATE_TIPO_EMPRESA_ASYNC:
            return { ...state, loadingUpdateTiposEmp: true };
        case UPDATE_TIPO_EMPRESA:
            return { ...state, ...action.payload, loadingUpdateTiposEmp: false };
        default: return state;
    }
}