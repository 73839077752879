import { format, parseISO } from 'date-fns';
import React from 'react';
import { connect } from "react-redux";
import { Alert, Card, CardBody, Table, Button } from 'reactstrap';
import PrintComponent from "../../../../components/PrintComponent";

function RtlLancamento({
    rltOperacionalLan,
    loadingRltOperacinalLan,
    printCompRef,
    active_user_empresa,
    filter,
    handlePrint
}) {

    return (
        <Card>
            <CardBody>
                {loadingRltOperacinalLan ?
                    <div className="loader-box">
                        <div className="loader-1"></div>
                    </div>
                    :
                    <>
                        {!!rltOperacionalLan.length &&
                            <div className="m-b-10 d-flex justify-content-end">
                                <Button size="sm" onClick={handlePrint} className="f-16">
                                    <i className="fa fa-print"></i>
                                    <span className="p-l-5">
                                        Imprimir
                                    </span>
                                </Button>
                            </div>
                        }
                        <PrintComponent ref={printCompRef}>
                            {!!rltOperacionalLan.length &&
                                <div style={{ fontFamily: 'arial' }} className="d-flex justify-content-start f-12">
                                    <span className="">
                                        {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - 
                                        Lançamentos ${format(parseISO(filter.dataInicial), 'dd/MM/yy')} a ${format(parseISO(filter.dataFinal), 'dd/MM/yy')}`}
                                    </span>
                                </div>

                            }
                            <Table className="font-arial-rlt">
                                <thead>
                                    <tr>
                                        <th className="b-light border-3">Data</th>
                                        <th className="b-light border-3">Conta</th>
                                        <th className="b-light border-3">Tipo</th>
                                        <th className="b-light border-3">Valor</th>
                                        <th className="b-light border-3">Competência</th>
                                        <th className="b-light border-3">Vencimento</th>
                                        <th className="b-light border-3">Fatura</th>
                                        <th className="b-light border-3">Histórico</th>
                                        <th className="b-light border-3">Doc</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!rltOperacionalLan.length ?
                                        rltOperacionalLan.map((lancamento, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{!!lancamento.vla_dt_lancamento && format(parseISO(lancamento.vla_dt_lancamento), 'dd/MM/yy')}</td>
                                                    <td>{`${lancamento?.conta?.ctn_nm_conta}`}</td>
                                                    <td>{lancamento.vla_tp_lancamento}</td>
                                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lancamento?.vla_vl_lancamento)}</td>
                                                    <td>{!!lancamento.vla_dt_competencia && format(parseISO(lancamento.vla_dt_competencia), 'dd/MM/yy')}</td>
                                                    <td>{!!lancamento.fatura?.ftr_dt_vencimento && format(parseISO(lancamento.fatura?.ftr_dt_vencimento), 'dd/MM/yy')}</td>
                                                    <td>{lancamento.fatura?.ftr_nu_fatura}</td>
                                                    <td>{`${lancamento.vla_ds_historico} - ${lancamento?.pessoa?.pss_nm_pessoa}`}</td>
                                                    <td>{lancamento.vla_nu_doc}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan="9"><Alert color="light">Nenhum registro encontrado</Alert></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </PrintComponent>
                    </>
                }
            </CardBody>
        </Card>
    )
}

const mapStateToProps = ({ VlaLancamentos, Auth }) => {
    const { rltOperacionalLan, loadingRltOperacinalLan } = VlaLancamentos;
    const { active_user_empresa } = Auth;
    return { rltOperacionalLan, loadingRltOperacinalLan, active_user_empresa }
}

export default connect(mapStateToProps, {

})(RtlLancamento);