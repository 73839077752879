import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Form, Button } from 'reactstrap';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { parseISO, format } from 'date-fns';
import br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from 'react-bootstrap-typeahead';


const DadosGerais = ({ user }) => {
    const [stateUser, setStateUser] = useState(null)

    useEffect(() => {
        const { name, data_nascimento, gender, email } = user;
        setStateUser({ ...stateUser, name, data_nascimento, gender, email });
    }, [user])

    const handleChange = e => {

        const { name, value } = e.target;
        setStateUser({ ...stateUser, [name]: value });
    }
    const handleChangeDate = (inputName, date) => {
        setStateUser({ ...stateUser, [inputName]: format(date, 'yyyy-MM-dd') })
    };

    const handleChangeSelect = (inputName, optSelected) => {
        if (!!optSelected.length) {
            setStateUser({ ...stateUser, [inputName]: optSelected[0].value })
        } else {
            setStateUser({ ...stateUser, [inputName]: null })
        }
    }
    const optionsGender = [{ name: 'Masculino', value: 'M' }, { name: 'Feminino', value: 'F' }];
    return (
        <Fragment>
            <Container fluid={true}>
                {!!stateUser &&
                    <Row>
                        <Col sm="12">
                            <Form >
                                <Row style={{ textAlign: 'initial' }}>
                                    <Col xs="12" md="9">
                                        <FormGroup>
                                            <Label for="inputNome">Nome Completo</Label>
                                            <Input
                                                id="inputNome"
                                                type="text"
                                                name="name"
                                                value={stateUser.name}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="3">
                                        <FormGroup>
                                            <Label>Gênero</Label>
                                            <Typeahead
                                                selected={optionsGender.filter((el) => el.value === stateUser.gender)}
                                                id="optionsGender"
                                                labelKey="name"
                                                options={optionsGender}
                                                onChange={(gender) => handleChangeSelect("gender", gender)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="inputEamil">Email</Label>
                                            <Input
                                                id="inputEamil"
                                                type="email"
                                                onChange={handleChange}
                                                value={stateUser.email} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label>Data de Nascimento</Label>
                                            <DatePicker
                                                locale={br}
                                                className="form-control digits"
                                                selected={parseISO(user.data_nascimento)}
                                                onChange={(date) => handleChangeDate('data_nascimento', date)} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" style={{ justifyContent: 'flex-end', display: "flex" }}>
                                        <Button color="primary" onClick={() => alert('Funcionalidade em desenvolvimento.')}>Salvar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                }
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Auth }) => {
    const { user } = Auth;
    return { user }
}

export default connect(mapStateToProps, {})(DadosGerais);