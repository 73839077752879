import React, { Fragment, useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb';
import PrintComponent from "../../../../components/PrintComponent";
import { Container, Row, Col, Card, CardBody, CardTitle, Button, Label, Input, FormGroup, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { format, parseISO } from "date-fns";
import { getRltDebCred } from "../../../../redux/actions";
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import Relatorio from "./Relatorio";
import Periodo from "./Periodo";



const CredDeb = ({ getRltDebCred, rltDebCred, loadingRltDebCred, active_user_empresa }) => {

    const [filter, setFilter] = useState({
        dataInicial: format(new Date(), 'yyyy-MM-dd'),
        dataFinal: format(new Date(), 'yyyy-MM-dd'),
        duplicar: false,
    });

    const printCompRef = useRef();

    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'dataFinal':
            case 'dataInicial':
                if (!!selected) {
                    setFilter({ ...filter, [name]: format(selected, 'yyyy-MM-dd') })
                } else {
                    setFilter({ ...filter, [name]: format(new Date(), 'yyyy-MM-dd') })
                }
                break;
        }

    }


    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        documentTitle: `Relatório Operacional Débito e Crédito ${format(new Date(), 'dd/MM/Y H:mm:ss')}`,
        // onBeforePrint: () => setLoadingPrint(true),
        // onAfterPrint: () => setLoadingPrint(false),
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });


    const onSubmitFilter = e => {
        e.preventDefault();
        getRltDebCred({ fields: filter });
    }


    return (
        <Fragment>
            <Breadcrumb parent="Operacional" title="Crédito e Débito" />
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    <h5>Filtro</h5>
                                </CardTitle>
                                <Form onSubmit={onSubmitFilter}>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <Periodo
                                                filter={filter}
                                                handleChangeSelect={handleChangeSelect}
                                            />
                                        </Col>
                                        <Col md="5" xs="12" className="d-flex" style={{ alignItems: "center" }}>
                                            <Label className="d-block" for="chk-ani">
                                                <Input
                                                    className="checkbox_animated"
                                                    name="duplicar"
                                                    onChange={({ target }) => {
                                                        const { name, checked } = target;
                                                        setFilter({ ...filter, [name]: checked });
                                                    }}
                                                    id="chk-ani"
                                                    type="checkbox"

                                                /> Espaço Duplo
                                        </Label>
                                        </Col>
                                        <Col md="1" sm="12" className="d-flex justify-content-center" style={{ alignItems: 'center' }}>
                                            <FormGroup>
                                                <Button
                                                    title="Pesquisar"
                                                    disabled={loadingRltDebCred}
                                                    className="m-t-30"
                                                    color="primary">
                                                    <i className="fa fa-search"></i>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12">
                        {loadingRltDebCred ?
                            <div className="loader-box">
                                <div className="loader-1"></div>
                            </div>
                            :
                            <Card className="shadow">
                                <CardBody>
                                    {!!Object.keys(rltDebCred).length &&
                                        <div className="m-b-10 d-flex justify-content-end">
                                            <Button size="sm" onClick={handlePrint} className="f-16">
                                                <i className="fa fa-print"></i>
                                                <span className="p-l-5">
                                                    Imprimir
                                             </span>
                                            </Button>
                                        </div>
                                    }
                                    <PrintComponent ref={printCompRef}>
                                        {!!Object.keys(rltDebCred).length &&
                                            <div className="d-flex justify-content-start font-arial-rlt">
                                                <span className="">
                                                    {`${!!active_user_empresa ? active_user_empresa?.emp_nm_empresa : 'SEM EMPRESA'} - 
                                        Débito e Crédito ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })}, 
                                        ${format(parseISO(filter.dataInicial), 'dd/MM/yy')} a ${new Date(filter.dataInicial).toLocaleString([], { weekday: 'short' })} ,
                                        ${format(parseISO(filter.dataFinal), 'dd/MM/yy')}`}
                                                </span>
                                            </div>
                                        }
                                        <Relatorio filter={filter} />
                                    </PrintComponent>
                                </CardBody>
                            </Card>
                        }

                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ RltOperacionais, Auth }) => {
    const { active_user_empresa } = Auth;
    const { rltDebCred, loadingRltDebCred } = RltOperacionais;

    return { rltDebCred, loadingRltDebCred, active_user_empresa }
}

export default connect(mapStateToProps, {
    getRltDebCred
})(CredDeb);