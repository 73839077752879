import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import { connect } from "react-redux";
import { getListPessoas, getFaturasSemBaixa } from "../../../../redux/actions";
import { useMemo } from 'react';
import ListSelect from "../../../../components/ListSelect";

function Pessoas({
    listPessoas,
    getListPessoas,
    handleChangeSelect,
    loadingListPessoas
}){ 

    const optionsPessoas = useMemo(() =>
    listPessoas.data.map(({ pss_nm_pessoa, pss_cd_pessoa,pss_nu_identificacao }) => ({ 
        name: `${pss_cd_pessoa} - ${pss_nm_pessoa} - ${!!pss_nu_identificacao ? pss_nu_identificacao : ""}`,
        value: pss_cd_pessoa
     })),
    [listPessoas.data]
)
    
    return (
        <>
            <div className="b-light border-2 p-10">
                <h6> Pessoas </h6>
                <Row>
                    <Col xs="12">
                      <ListSelect 
                        data={optionsPessoas}
                        isLoading={loadingListPessoas}
                        onChange={(items) => handleChangeSelect('pessoas', items)}
                        onPaginate={ (rows, searchValue) => {
                            const offset = rows + 50;
                            getListPessoas({ fields: { offset, name: searchValue } })
                        }}
                        onSearch={(name,options) => {
                            if (!options.find((option) => new RegExp(`${name}`, 'i').test(option.name.trimEnd()))) {
                                getListPessoas({ fields: { name } })
                            }
                        }}
                        totalResults={listPessoas.total}
                        containerStyle={{maxHeight: 135}}  
                        pagination  
                      />
                    </Col>
                </Row>
            </div>
        </>
    );
}

const mapStateToProps = ({Pessoas}) => {
    const { list: listPessoas, loadingList: loadingListPessoas} = Pessoas;
    return {listPessoas, loadingListPessoas}
}

export default connect(mapStateToProps,{
    getListPessoas,
    getFaturasSemBaixa,
})(Pessoas);