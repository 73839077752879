import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function FirstCard({
    tipoPessoa
}) {


    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Nome*</Label>
                            <Input
                                defaultValue={tipoPessoa.tps_nm_tipo || 'Não Informado'}
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Tipo Documento</Label>
                            <Input
                                defaultValue={tipoPessoa.tps_tp_documento === "1" ? "CPF" : tipoPessoa.tps_tp_documento === "0" ? "CNPJ" : 'Não Informado'}
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>Descrição</Label>
                            <Input
                                readOnly
                                defaultValue={tipoPessoa.tps_ds_tipo || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>

                </Row>
            </div>
        </FormGroup>
    )
}

export default FirstCard;