import React from 'react';
import { FormGroup, Row, Col, Input, Label } from 'reactstrap';

const FirstCard = ({ conta }) => {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Nome</Label>
                            <Input readOnly defaultValue={conta.ctn_nm_conta} />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Código</Label>
                            <Input readOnly defaultValue={conta.ctn_cd_conta} />
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Grupo</Label>
                            <Input readOnly defaultValue={!!conta.grupo ? conta.grupo.grc_nm_grupo : 'Não Informado'} />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Grupo Pai</Label>
                            <Input readOnly defaultValue={!!conta.grupo_pai ? conta.grupo_pai.name : 'Não Informado'} />
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Pessoa</Label>
                            <Input readOnly defaultValue={
                                !!conta.pessoa ? `${conta.pessoa.pss_cd_pessoa} - ${conta.pessoa.pss_nm_pessoa} - ${conta.pessoa.pss_nu_identificacao}` : "Não Informado"
                                } />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Tipo</Label>
                            <Input readOnly defaultValue={!!conta.ctn_tp_conta ? conta.ctn_tp_conta === "V" ? 'Venda' : "Diversas" : "Não Informado"} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default FirstCard;