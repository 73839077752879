import React, { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { storeTipoEmpresa } from "../../../../../redux/actions";
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';

const initalState = {
    tpe_nm_tipo: "",
    tpe_ds_tipo: ""
}
function Add({
    isOpen,
    clickClose,
    storeTipoEmpresa,
    loadingStoreTipoEmp,
    errors
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            in: 'As senhas devem ser iguais.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.'
        }
    })).current;


    const [fields, setFields] = useState(initalState);

    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeTipoEmpresa({ fields, onSave: clickClose });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente', toast.POSITION.TOP_RIGHT);
        }

    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
        if (!!errors[name]) {
            delete errors[name];
        }
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }


    return (
        <Modal
            onClosed={() => {
                validator.visibleFields = [];
                validator.messagesShown = false;
                setFields(initalState)
                for (const key in errors) {
                    delete errors[key]
                }
            }}
            isOpen={isOpen}
            size="lg"
        >
            <ModalHeader toggle={() => !loadingStoreTipoEmp && clickClose()}>
                Adicionar Tipo Empresa
           </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input
                                    name="tpe_nm_tipo"
                                    onChange={handleChange}
                                    maxLength={36}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    placeholder="Informe o Nome"
                                    invalid={isInvalid('tpe_nm_tipo')}
                                />
                                <FormFeedback>
                                    {(!!errors.tpe_nm_tipo) && errors.tpe_nm_tipo.map((erro, index) => <span key={index}>{erro}</span>)}
                                    {validator.message('tpe_nm_tipo', fields.tpe_nm_tipo, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Descrição</Label>
                                <Input
                                    name="tpe_ds_tipo"
                                    onChange={handleChange}
                                    maxLength={100}
                                    placeholder="Informe a Descrição"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={isInvalid('tpe_ds_tipo')}
                                />
                                <FormFeedback>
                                    {(!!errors.tpe_ds_tipo) && errors.tpe_ds_tipo.map((erro, index) => <span key={index}>{erro}</span>)}
                                    {validator.message('tpe_ds_tipo', fields.tpe_ds_tipo, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={loadingStoreTipoEmp}
                        onClick={() => !loadingStoreTipoEmp && clickClose()}
                        type="button">
                        Fechar
                </Button>
                    <Button disabled={loadingStoreTipoEmp} color="primary">
                        {loadingStoreTipoEmp ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ TiposEmpresa }) => {
    const { loadingStoreTipoEmp, errors } = TiposEmpresa
    return { loadingStoreTipoEmp, errors }
}

export default connect(mapStateToProps, {
    storeTipoEmpresa
})(Add);