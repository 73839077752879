import React, { useState, useRef, useCallback } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    ModalHeader,
    Row,

} from "reactstrap";
import { connect } from "react-redux";
import {
    getListEstados,
    getListCidades,
    storePessoas,
    getListTiposPessoas,
    getListContas,
} from "../../../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import DadosGerais from "./DadosGerais";
import Opcoes from "./Opcoes";
import Contas from "./Contas";
import Endereco from "./Endereco";
import Valores from "./Valores";

const initialState = {
    pss_nm_pessoa: "",
    pss_cd_pessoa: "",
    pss_cd_tipo: "",
    pss_nu_identificacao: "",
    pss_estado_id: "",
    pss_cidade_id: "",
    pss_nu_cep: "",
    pss_st_ctr_vlr_dup: "",
    pss_st_conf_fluxo: "",
    pss_st_imp_ativo: "",
    pss_nu_telefone: "",
    pss_st_senha_juro: "",
    pss_st_senha_desc: "",
    pss_vl_juro_margem: "",
    pss_vl_juro_banco: "",
    pss_cd_conta_contabil: "",
    contas: []
};
const Add = ({
    isOpen,
    clickClose,
    getListEstados,
    getListCidades,
    storePessoas,
    loadingStore,
    getListTiposPessoas,
    getListContas,
    errors
}) => {
    //ESTADOS
    const [fields, setFields] = useState(initialState);
    const [, updateState] = useState();

    //VARIÁVEIS

    //FUNÇÕES
    const forceUpdate = useCallback(() => updateState({}), []);
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storePessoas({ fields, onCreate: clickClose })
        } else {
            validator.showMessages();
            toast.error('Preencha todos os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }
    const clearErrorsForm = (name) => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleChange = e => {
        const { name, value, checked } = e.target;
        switch (name) {
            case 'pss_nu_cep':
                setFields({ ...fields, [name]: value.replace('-', '') });
                break;
            case 'pss_nu_telefone':
                setFields({ ...fields, [name]: value.replace(/\D/g, "") });
                break;
            case 'pss_cd_pessoa':
            case 'pss_cd_conta_contabil':
                setFields({ ...fields, [name]: value.replace(' ', '') });
                break;
            case 'pss_st_senha_desc':
            case 'pss_st_senha_juro':
                checked ? setFields({ ...fields, [name]: value }) : setFields({ ...fields, [name]: "" });
                break;
            default:
                setFields({ ...fields, [name]: value });
                break;
        }
        clearErrorsForm(name)
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            case 'pss_estado_id':
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                    getListCidades({ estado_id: selected[0].value })
                } else {
                    setFields({ ...fields, [name]: "", pss_cidade_id: "" })
                }
                break;
            case 'contas':
                setFields({ ...fields, [name]: selected });
                break;
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value });
                } else {
                    setFields({ ...fields, [name]: "" });
                }
                break;
        }
        clearErrorsForm(name)
    }
    const handleValores = (name, value) => {
        setFields({ ...fields, [name]: value });
        clearErrorsForm(name)
    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    //VALIDATOR
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: {
            required: 'Este Campo é obrigatório.',
            email: 'Email inválido.',
            min: 'Este campo deve ter no minímo :min caracteres.',
            max: 'Este campo deve ter no máximo :max caracteres.',
        },
    })).current;

    return (
        <Modal isOpen={isOpen}
            onOpened={() => {
                getListEstados()
                getListTiposPessoas()
                getListContas()
                // getListEmpresas()
            }}
            onClosed={() => {
                setFields(initialState);
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }

            }}
            size="lg"
        >
            <ModalHeader toggle={() => !loadingStore && clickClose()}>
                Incluir Pessoa
            </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <DadosGerais
                            validator={validator}
                            validFields={validFields}
                            fields={fields}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                            errors={errors}
                        />
                        <Opcoes
                            validator={validator}
                            validFields={validFields}
                            fields={fields}
                            handleChange={handleChange}
                            errors={errors}
                        />
                        <Contas
                            handleChangeSelect={handleChangeSelect}
                        />
                        <Endereco
                            validator={validator}
                            validFields={validFields}
                            fields={fields}
                            errors={errors}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                        />
                        <Valores
                            errors={errors}
                            validator={validator}
                            fields={fields}
                            handleValores={handleValores}
                            validFields={validFields}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => clickClose()} disabled={loadingStore}>
                        Fechar
                </Button>
                    <Button type="submit" disabled={loadingStore} color="primary">
                        {loadingStore ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ Pessoas }) => {

    const { loadingStore, errors } = Pessoas;
    return {
        errors,
        loadingStore,
    };
}

export default connect(mapStateToProps, {
    getListEstados,
    getListCidades,
    storePessoas,
    getListTiposPessoas,
    getListContas,
})(Add);
