import { takeLatest, put, call, fork, all } from "redux-saga/effects";
import Api from "../../services/api";
import { GET_LIST_DEPARTAMENTOS, GET_LIST_DEPARTAMENTOS_ASYNC } from "../actionTypes";

function* getListDepartamentos() {
    try {
        const data = yield call(apiGetListDepartamentos);
        if (data.ok) {
            const { list } = data;
            yield put({ type: GET_LIST_DEPARTAMENTOS, payload: { list } })
        }
    } catch (error) {
        yield put({ type: GET_LIST_DEPARTAMENTOS, payload: {} })
    }
}
const apiGetListDepartamentos = async () => {
    const { data } = await Api.get(`/api/departamentos/list`);

    return data;
}

function* watchGetListDepartamentos() {
    yield takeLatest(GET_LIST_DEPARTAMENTOS_ASYNC, getListDepartamentos);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListDepartamentos)
    ]);
}