import { FileText, Settings, Users, DollarSign, Octagon, PlusSquare, Server } from 'react-feather'
export const MENUITEMS = [
    {
        menutitle: "Gestão",
        menucontent: "",
        Items: [
            { path: `${process.env.PUBLIC_URL}/gestao/controle_acesso`, active: false, icon: Settings, sid: "funcionalidades.index", title: 'Controle de Acesso', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/gestao/usuarios`, active: false, icon: Users, sid: "users.index", title: 'Usuários', type: 'link' },
        ]

    },
    {
        menutitle: "Tabelas",
        menucontent: "",
        Items: [
            {
                title: 'Empresa', icon: Server, data_parent: 'empresa', active: false, type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/tabelas/empresas`, active: false, sid: 'emp_empresa.index', icon: Server, title: 'Cadastro', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/tabelas/tipos_empresa`, active: false, sid: 'tipos_empresa.index', icon: Server, title: 'Tipos', type: 'link' },
                ]
            },
            {
                title: 'Estoque', data_parent: 'estoque', icon: Server, active: false, type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/tabelas/estoque/custo_produto`, sid: 'cmv_diario.index', active: false, title: 'Custo por Produto', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/tabelas/estoque/produtos`, active: false, sid: 'produtos.index', title: 'Produtos', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/tabelas/estoque/tipo_movimentacao`, sid: 'tmp_tipo_mov.index', active: false, title: 'Tipo Movimentação', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/tabelas/estoque/notas_fiscais`, sid: 'notas_fiscais.index', active: false, title: 'Notas Fiscais', type: 'link' }
                ]
            },
            { path: `${process.env.PUBLIC_URL}/tabelas/feriados`, sid: 'feriados.index', active: false, icon: Server, type: 'link', title: 'Feriados' },
            { path: `${process.env.PUBLIC_URL}/tabelas/centro_custo`, sid: 'centro_custo.index', active: false, icon: Server, type: 'link', title: 'Centro de Custo' },
            { path: `${process.env.PUBLIC_URL}/tabelas/tipo_pessoa`, active: false, sid: 'tipos_pessoas.index', icon: Server, type: 'link', title: 'Tipo de Pessoa' }
        ]
    },
    {
        menutitle: "Cadastros",
        menucontent: "",
        Items: [
            {
                title: 'Plano de Contas', icon: PlusSquare, data_parent: 'plano_contas', active: false, type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/cadastros/plano_contas/contas`, active: false, sid: "contas.index", title: 'Contas', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/cadastros/plano_contas/grupos`, active: false, sid: "grupos_contas.index", title: 'Grupos', type: 'link' },
                ],

            },
            { path: `${process.env.PUBLIC_URL}/cadastros/pessoas`, sid: 'pessoas.index', active: false, icon: PlusSquare, title: 'Pessoas', type: 'link' },
        ]
    },
    {
        menutitle: "Operação",
        menucontent: "",
        Items: [
            { title: 'Lançamentos', icon: DollarSign, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/operacao/lancamentos`, sid: 'lancamentos.index' },
            { title: 'Conciliação Bancária', icon: DollarSign, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/operacao/conc_bancaria`, sid: 'faturas.conciliarFtr' }
        ]
    },
    {
        menutitle: "Relatórios",
        menucontent: "",
        Items: [
            {

                title: 'Gerencial', data_parent: "gerencial", icon: FileText, type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/plano_contas`, active: false, sid: "rltGerenciais.relatorioPlanoDeContas", title: 'Plano de Contas', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/movimentacao`, active: false, title: 'Movimentação', sid: "rltGerenciais.relatorioMovimentacao", type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/master`, active: false, title: 'Master', sid: "rltGerenciais.relatorioMaster", type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/gerencial/fluxo_caixa`, active: false, sid: 'rltGerenciais.relatorioFluxoCaixa', title: 'Fluxo de Caixa', type: 'link' }
                ]

            },
            {
                title: 'Operacional', data_parent: "operacional", icon: FileText, type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/extrato_conta`, active: false, sid: "rltOperacionais.relatorioExtratoContas", title: 'Extrato de Conta', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/lancamentos`, active: false, sid: "lancamentos.relatorioOperacional", title: 'Lançamentos', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/extrato_pessoa`, active: false, sid: "rltOperacionais.relatorioExtratoPessoa", title: 'Extrato de Pessoas', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/nao_recebidos`, sid: 'rltOperacionais.relatorioNaoRecebidos', active: false, title: 'Não Recebidos', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/nao_pagos`, active: false, sid: 'rltOperacionais.relatorioNaoPagos', title: 'Não Pagos', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/pesquisa`, active: false, sid: 'rltOperacionais.relatorioPesquisa', title: 'Pesquisa', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorios/operacional/cred_deb`, active: false, sid: 'rltOperacionais.relatorioDebCred', title: 'Crédito e Débito', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorio/operacional/contrapartidas`, action: false, sid: 'rltOperacionais.relatorioCnt', title: 'Contrapartidas', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/relatorio/operacional/titulosNaoRecebidos`, action: false, sid: 'rltOperacionais.relatorioTitulosRecPagos', title: 'Titulos', type: 'link' }
                ]

            },
        ]
    }
]