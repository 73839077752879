import React, { useState, Fragment, useRef, useCallback, useEffect } from "react";

import {
    AsyncTypeahead,
    Menu,
    MenuItem,
} from "react-bootstrap-typeahead";
const SelectPagination = ({
    id,
    labelKey,
    maxResults = 50,
    placeholder,
    emptyLabel = "Nenhum registro encontrado",
    isLoading = false,
    data = [],
    reduxAction = () => { },
    isInvalid = false,
    onBlur = () => { },
    onChange = () => { },
    onFocus = () => { },
    multiple = false,
    clearButton = false,
    totalResults = 0,
    disabled = false,
    useCache = true,
    selected = () => { },
    ...restProps
}) => {
    const [offset, setOffset] = useState(maxResults);
    const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState([]);
    const cacheList = useRef([]);
    const cacheTotal = useRef(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (data.length) {
            setOptions(data);
        }
        if (useCache) {
            data.forEach(element => {
                if (!cacheList.current.find((cache) => (cache.value === element.value))) {
                    cacheList.current.push(element);
                }
            });
        }
    }, [data]);

    useEffect(() => {
        setTotal(totalResults);

        if (totalResults > cacheTotal.current) {
            cacheTotal.current = totalResults;
        }

    }, [totalResults]);

    const handleCacheList = useCallback(() => {
        if (useCache) {
            if (cacheList.current.length < total && cacheList.current.length < maxResults) {
                reduxAction()
            } else {
                setOptions(cacheList.current);
            }
        } else {
            reduxAction()
        }
    });

    return (
        <AsyncTypeahead
            id={id}
            labelKey={labelKey}
            minLength={0}
            maxResults={maxResults - 1}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            multiple={multiple}
            renderMenu={useCallback((results, menuProps) => {
                return (
                    <Menu {...menuProps} >
                        {(isLoading && !options.length) || (isLoading && searchValue.length > 2) ?
                            <MenuItem disabled key={1} option={{}} position={1}>
                                <span>Procurando...</span>
                            </MenuItem>
                            :
                            !!results.length ?
                                results.map((result, index) => {
                                    if (!result.paginationOption) {
                                        return (
                                            <MenuItem key={index} option={result} position={index}>
                                                <>
                                                    <div>
                                                        {!!result[labelKey] ? result[labelKey] : result.name}
                                                    </div>
                                                    {!!result.subItem &&
                                                        <div>
                                                            <small>{result.subItem}</small>
                                                        </div>
                                                    }
                                                </>
                                            </MenuItem>
                                        )
                                    } else {
                                        return (
                                            <Fragment key="pagination-item">
                                                <Menu.Divider />
                                                <MenuItem
                                                    position={index}
                                                    option={result}
                                                    className="rbt-menu-pagination-option"
                                                    label={menuProps.paginationText}>
                                                    {menuProps.paginationText}
                                                </MenuItem>
                                            </Fragment>
                                        )
                                    }
                                })
                                :
                                <MenuItem disabled key={1} option={{}} position={1}>
                                    <span>{emptyLabel}</span>
                                </MenuItem>
                        }
                    </Menu>
                )
            })}
            promptText="Digite para pesquisar"
            searchText="Procurando..."
            emptyLabel={emptyLabel}
            options={options}
            clearButton={clearButton}
            isInvalid={isInvalid}
            isLoading={isLoading}
            disabled={(isLoading && !searchValue.length && !options.length) || disabled}
            onSearch={() => { }}
            paginate
            paginationText={<span className="d-flex justify-content-center">Carregar Mais</span>}
            onPaginate={(e, showResults) => {
                if ((options.length < total) && !(options.length > showResults)) {
                    reduxAction({ fields: { offset, name: searchValue } });
                    setOffset(offset + maxResults);
                }
            }}
            onInputChange={input => {
                if (input.length > 2 && !options.find((option) => new RegExp(`${input}`, 'i').test(option.name.trimEnd()))) {
                    reduxAction({ fields: { name: input } })
                }
                if (!input) {
                    handleCacheList()
                    setTotal(cacheTotal.current)
                }
                setSearchValue(input);
            }}
            useCache={false}
            onChange={(selected) => {

                if (!selected.length) {
                    handleCacheList()
                }
                onChange(selected)
            }}
            selected={selected(options)}
            {...restProps}
        />
    )
}

// SelectPagination.propTypes = {
//     id: PropTypes.string.isRequired,
//     labelKey: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.func
//     ]).isRequired,
//     maxResults: PropTypes.number,
//     placeholder: PropTypes.string,
//     emptyLabel: PropTypes.string,
//     isLoading: PropTypes.bool,
//     data: PropTypes.array.isRequired,
//     reduxAction: PropTypes.func.isRequired,
//     isInvalid: PropTypes.bool,
//     onBlur: PropTypes.func,
//     onChange: PropTypes.func,
//     multiple:PropTypes.bool,

// }

export default React.memo(SelectPagination);