import React from 'react';
import { Col, ListGroup, ListGroupItem, Row, Input } from 'reactstrap';
import ScrollArea from "react-scrollbar";

function ReceitaDespesa({
    handleChange,
    filter
}) {
    const prefix = 'exibirRecDes';
    const options = [
        {
            label: '% Crédito',
            inputName: `${prefix}Credito`,
            isDisabled: true,
        },
        {
            label: '% Débito',
            inputName: `${prefix}Debito`,
            isDisabled: true,
        },
        {
            label: 'Centros de Custo Agrupado',
            inputName: `${prefix}CctAgrupado`,
            isDisabled: false,
        },
        {
            label: 'Grupos Conta',
            inputName: `${prefix}GruposConta`,
            isDisabled: false,
        },
        {
            label: 'Contas',
            inputName: `${prefix}Contas`,
            isDisabled: false,
        },
        {
            label: 'Pessoas',
            inputName: `${prefix}Pessoas`,
            isDisabled: false,
        },
        {
            label: 'Centros de Custo',
            inputName: `${prefix}Cct`,
            isDisabled: false,
        },
    ]
    return (
        <>
            <div className="b-light border-2 p-10">
                <h6>Exibir em Receita/Despesa</h6>
                <Row>
                    <Col xs="12">
                        <ScrollArea horizontal={false} style={{ maxHeight: 150 }}>
                            <ListGroup>
                                {options.map(({ label, inputName, isDisabled }, index) => {
                                    return (
                                        <ListGroupItem
                                            key={index}
                                            className={`list-group-item-action ${isDisabled ? 'disabled' : ""}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleChange(inputName)}
                                        >
                                            <div>
                                                <Input
                                                    className="checkbox_animated"
                                                    name={inputName}
                                                    checked={filter[inputName] === 'S'}
                                                    onChange={() => { }}
                                                    type="checkbox" />
                                                <span>{label}</span>
                                            </div>
                                        </ListGroupItem>
                                    )
                                })}

                            </ListGroup>
                        </ScrollArea>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ReceitaDespesa;