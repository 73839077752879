import React from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
} from "reactstrap";
import DadosGerais from "./DadosGerais";
import Empresas from "./Empresas";
import NivelAcesso from "./NivelAcesso";
import Lancamentos from "./Lancamentos";
import Contas from "./Contas";

const View = ({ isOpen, clickClose, users, selectedUser }) => {
    const user = !!users && users.data[selectedUser];
    return (
        <Modal isOpen={isOpen} size="lg" >
            <ModalHeader toggle={() => clickClose()}>Informações do Usuário</ModalHeader>
            <ModalBody>
                {!!user &&
                    <Row>
                        <DadosGerais
                            user={user}
                        />
                        <Empresas
                            user={user}
                        />
                        <Contas
                            user={user}
                        />
                        <NivelAcesso
                            user={user}
                        />
                        <Lancamentos
                            user={user}
                        />
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => clickClose()}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = ({ Users }) => {
    const { users } = Users;
    return { users };
}
export default connect(mapStateToProps, {})(View);