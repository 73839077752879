import React, { useState, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Form
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../constraints/variables";
import { CpfCnpjInput } from "../../../components/CustomInputs";
import SelectPagination from "../../../components/SelectPagination"
import { getListPessoas, updateCentroCusto } from "../../../redux/actions";
import { toast } from "react-toastify";


function Add({
    isOpen,
    clickClose,
    errors,
    loadingUpdateCct,
    loadingListPesssoas,
    listPessoas,
    getListPessoas,
    updateCentroCusto,
    selectedCct,
    centrosCusto
}) {

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;


    const [fields, setFields] = useState(null);

    const isInvalid = name => {
        return validator.visibleFields.includes(name) ||
            !!errors[name] ||
            (!validator.fields[name] && validator.messagesShown)
    }

    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }

    const clearErrors = name => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
        clearErrors(name)
    }
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {

            updateCentroCusto({ fields, onSave: clickClose, index: selectedCct });
        } else {
            validator.showMessages();
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT);
        }
    }

    const optionsPessoas = useMemo(() => {
        const res = listPessoas.data.map(({ id, pss_nm_pessoa, pss_cd_pessoa, pss_nu_identificacao }) => {
            return ({
                value: id,
                name: `${pss_cd_pessoa} - ${pss_nm_pessoa}`,
                subItem: `Num.Identificação: ${pss_nu_identificacao}`,
                pss_cd_pessoa
            })
        });
        if (!!fields?.pessoa && !listPessoas.data.find((pessoa) => pessoa.pss_cd_pessoa === fields.cct_cd_pessoa)) {
            res.push({
                value: fields.pessoa.id,
                name: `${fields.pessoa.pss_cd_pessoa} - ${fields.pessoa.pss_nm_pessoa}`,
                subItem: `Num.Identificação: ${fields.pessoa.pss_nu_identificacao}`,
                pss_cd_pessoa: fields.pessoa.pss_cd_pessoa
            })
        }

        return res;

    }, [listPessoas.data, fields?.cct_cd_pessoa]);

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
                const dados = { ...centrosCusto.data[selectedCct] };
                delete dados.row_num;
                delete dados.id;
                setFields(dados);
                !listPessoas.data.length && getListPessoas()
            }}
            onClosed={() => {
                setFields(null);
            }}
        >
            <ModalHeader toggle={() => !loadingUpdateCct && clickClose()}>
                Editar Centro de Custo
            </ModalHeader>
            {!!fields &&
                <Form onSubmit={submitForm}>
                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Nome*</Label>
                                    <Input
                                        placeholder="Informe o Nome"
                                        name="cct_nm_centro"
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={isInvalid('cct_nm_centro')}
                                        defaultValue={fields.cct_nm_centro}
                                        onChange={handleChange}
                                    />
                                    <FormFeedback>
                                        {validator.message('cct_nm_centro', fields.cct_nm_centro, 'required|max:30')}
                                        {(!!errors.cct_nm_centro) && errors.cct_nm_centro.map((erro, index) => <span key={index}>{erro}</span>)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Num.Identificação</Label>
                                    <CpfCnpjInput
                                        placeholder="Informe o Num.Identifição"
                                        name="cct_nu_identificacao"
                                        defaultValue={fields.cct_nu_identificacao}
                                        onChange={handleChange}
                                        onBlur={({ target: { name } }) => validFields(name)}
                                        invalid={isInvalid('cct_nu_identificacao')}
                                    />
                                    <FormFeedback>
                                        {validator.message('cct_nu_identificacao', fields.cct_nu_identificacao, 'max:20')}
                                        {(!!errors.cct_nu_identificacao) && errors.cct_nu_identificacao.map((erro, index) => <span key={index}>{erro}</span>)}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Pessoa</Label>
                                    <SelectPagination
                                        id="selectPessoa"
                                        labelKey="name"
                                        maxResults={50}
                                        placeholder="Selecione a Pessoa Padrão"
                                        data={optionsPessoas}
                                        selected={options => options.filter(opt => opt.pss_cd_pessoa === fields.cct_cd_pessoa)}
                                        totalResults={listPessoas.total}
                                        reduxAction={getListPessoas}
                                        clearButton
                                        isLoading={loadingListPesssoas}
                                        onChange={(selected) => {
                                            setFields({ ...fields, cct_cd_pessoa: selected[0]?.pss_cd_pessoa || "" });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" disabled={loadingUpdateCct} onClick={() => clickClose()}>
                            Fechar
                </Button>
                        <Button disabled={loadingUpdateCct} color="primary">
                            {loadingUpdateCct ? 'Processando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    );
}

const mapStateToProps = ({ CentroCusto, Pessoas }) => {

    const { errors, loadingUpdateCct, centrosCusto } = CentroCusto;
    const { list: listPessoas, loadingList: loadingListPesssoas } = Pessoas;

    return { errors, loadingUpdateCct, listPessoas, loadingListPesssoas, centrosCusto }
}

export default connect(mapStateToProps, {
    getListPessoas,
    updateCentroCusto
})(Add);
