import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row, Input } from "reactstrap";
import { getListContas } from "../../../../../../redux/actions";

const Conta = ({

    fatura
}) => {

    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    {/* <Label><h6>Vincular Conta</h6></Label> */}
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Conta</Label>
                                <Input defaultValue={`${fatura.conta?.name}`} readOnly />
                            </FormGroup>
                        </Col>

                        <>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Valor Mínimo</Label>
                                    <Input
                                        defaultValue={!!fatura.conta?.valor_minimo ?
                                            new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(fatura.conta.valor_minimo)
                                            :
                                            'Não Informado'
                                        }
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Valor Máximo</Label>
                                    <Input
                                        defaultValue={!!fatura.conta?.valor_maximo ?
                                            new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(fatura.conta.valor_maximo)
                                            :
                                            'Não Informado'
                                        }
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label>Pessoa</Label>
                                    <Input defaultValue={`${fatura.pessoa?.value} - ${fatura.pessoa?.name}`} readOnly/>
                                </FormGroup>
                            </Col>
                        </>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

const mapStateToProps = ({ Contas }) => {
    const { list: listContas, loadingList: loadingListContas } = Contas;
    return { listContas, loadingListContas }
}

export default connect(mapStateToProps, {
    getListContas
})(Conta);