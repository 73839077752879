import {
    GET_PRODUTOS_ASYNC,
    GET_PRODUTOS,
    STORE_PRODUTO_ASYNC,
    STORE_PRODUTO,
    UPDATE_PRODUTO_ASYNC,
    UPDATE_PRODUTO,
    DESTROY_PRODUTOS_ASYNC,
    DESTROY_PRODUTOS
} from "../actionTypes";

const initalState = {
    errors: {},
    produtos: null,
    loadingProdutos: false,
    loadingStoreProduto: false,
    loadingUpdateProduto: false,
    loadingDestroyProdutos: false
}

export default (state = initalState, action) => {
    switch (action.type) {
        case GET_PRODUTOS_ASYNC:
            return { ...state, loadingProdutos: true };

        case GET_PRODUTOS:
            return { ...state, ...action.payload, loadingProdutos: false };

        case STORE_PRODUTO_ASYNC:
            return { ...state, loadingStoreProduto: true };

        case STORE_PRODUTO:
            return { ...state, ...action.payload, loadingStoreProduto: false };

        case UPDATE_PRODUTO_ASYNC:
            return { ...state, loadingUpdateProduto: true };

        case UPDATE_PRODUTO:
            return { ...state, ...action.payload, loadingUpdateProduto: false };

        case DESTROY_PRODUTOS_ASYNC:
            return { ...state, loadingDestroyProdutos: true };

        case DESTROY_PRODUTOS:
            return { ...state, ...action.payload, loadingDestroyProdutos: false }

        default: return state;
    }
}