import React, { useMemo } from 'react';
import { Col, FormGroup, Label, Row, Input, FormFeedback } from 'reactstrap';
import { connect } from "react-redux";
import SelectPagination from "../../../../../components/SelectPagination";
import { getListPessoasEmp } from "../../../../../redux/actions";
import { Typeahead } from "react-bootstrap-typeahead";
import { CpfCnpjInput } from "../../../../../components/CustomInputs";

function DadosGerais({
    listTiposEmp,
    loadingListTiposEmp,
    handleChange,
    handleChangeSelect,
    validFields,
    validator,
    fields,
    getListPessoasEmp,
    loadingPessoasEmp,
    listPessoasEmp
}) {

    const optionsTiposEmpresa = useMemo(() => listTiposEmp.data.map((tipo) => ({
        value: tipo.tpe_cd_tipo,
        name: tipo.tpe_nm_tipo,
        subItem: tipo.tpe_ds_tipo
    })), [listTiposEmp.data]);

    const optionsClientes = useMemo(() => {
        const res = [];
        if (!!fields.cliente && !listPessoasEmp.data.find((el) => el.pss_cd_pessoa === fields.cliente.pss_cd_pessoa)) {
            const { cliente } = fields;
            res.push({
                value: cliente.pss_cd_pessoa,
                name: `${cliente.pss_cd_pessoa} - ${cliente.pss_nm_pessoa}`,
                cnpj: cliente.pss_nu_identificacao,
                subItem: `CNPJ: ${cliente.pss_nu_identificacao}`
            })
        } 
            res.push(...listPessoasEmp.data.map((pessoa) => ({
                value: pessoa.pss_cd_pessoa,
                name: `${pessoa.pss_cd_pessoa} - ${pessoa.pss_nm_pessoa}`,
                cnpj: pessoa.pss_nu_identificacao,
                subItem: `CNPJ: ${pessoa.pss_nu_identificacao}`
            })));

        return res;

    }, [listPessoasEmp.data]);

    const optionsDados = [
        {
            name: 'Local',
            value: 'L'
        },
        {
            name: 'Importado',
            value: 'I'
        }
    ];

    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Empresa</h6></Label>
                    <Row>
                        <Col md="2" sm="12">
                            <FormGroup>
                                <Label>Código*</Label>
                                <Input
                                    onChange={handleChange}
                                    disabled
                                    placeholder="Código"
                                    name="emp_cd_empresa"
                                    value={fields.emp_cd_empresa}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    maxLength={2}
                                    invalid={validator.visibleFields.includes('emp_cd_empresa') ||
                                        (!validator.fields.emp_cd_empresa && validator.messagesShown)}
                                />
                                <FormFeedback>
                                    {validator.message('emp_cd_empresa', fields.emp_cd_empresa, 'required|min:2')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="7" sm="12">
                            <FormGroup>
                                <Label>Tipo*</Label>
                                <SelectPagination
                                    id="selectTipoEmpresa"
                                    onChange={(selected) => handleChangeSelect('emp_tp_empresa', selected)}
                                    selected={(options) => options.filter((option) => option.value === fields.emp_tp_empresa)}
                                    labelKey="name"
                                    placeholder="Informe o tipo da empresa"
                                    onBlur={() => validFields('emp_tp_empresa')}
                                    data={optionsTiposEmpresa}
                                    clearButton
                                    isLoading={loadingListTiposEmp}
                                    isInvalid={validator.visibleFields.includes('emp_tp_empresa') ||
                                        (!validator.fields.emp_tp_empresa && validator.messagesShown)}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('emp_tp_empresa') ||
                                            (!validator.fields.emp_tp_empresa && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('emp_tp_empresa', fields.emp_tp_empresa, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="12">
                            <FormGroup>
                                <Label>Dados*</Label>
                                <Typeahead
                                    onChange={(selected) => handleChangeSelect('emp_tp_dados', selected)}
                                    id="selectDados"
                                    selected={optionsDados.filter((option) => option.value === fields.emp_tp_dados)}
                                    onBlur={() => validFields('emp_tp_dados')}
                                    labelKey="name"
                                    clearButton
                                    placeholder="Informe dados"
                                    isInvalid={validator.visibleFields.includes('emp_tp_dados') ||
                                        (!validator.fields.emp_tp_dados && validator.messagesShown)}
                                    options={optionsDados}
                                />
                                <FormFeedback
                                    style={{
                                        display: validator.visibleFields.includes('emp_tp_dados') ||
                                            (!validator.fields.emp_tp_dados && validator.messagesShown)
                                            ? 'block' : 'none'
                                    }}>
                                    {validator.message('emp_tp_dados', fields.emp_tp_dados, 'required')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup>
                                <Label>Nome/Razão Social*</Label>
                                <Input
                                    onChange={handleChange}
                                    maxLength={60}
                                    value={fields.emp_nm_empresa}
                                    name="emp_nm_empresa"
                                    placeholder="Informe o Nome/Razão Social"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('emp_nm_empresa') ||
                                        (!validator.fields.emp_nm_empresa && validator.messagesShown)}
                                />
                                <FormFeedback>
                                    {validator.message('emp_nm_empresa', fields.emp_nm_empresa, 'required|max:60')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" sm="12">
                            <FormGroup>
                                <Label>CNPJ/CPF*</Label>
                                <CpfCnpjInput
                                    onChange={handleChange}
                                    value={fields.emp_nu_cnpj_cpf}
                                    placeholder="Informe o CNPJ"
                                    name="emp_nu_cnpj_cpf"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('emp_nu_cnpj_cpf') ||
                                        (!validator.fields.emp_nu_cnpj_cpf && validator.messagesShown)}
                                />
                                <FormFeedback>
                                    {validator.message('emp_nu_cnpj_cpf', fields.emp_nu_cnpj_cpf, 'required|min:11|max:14')}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8" sm="12">
                            <FormGroup>
                                <Label>Cliente</Label>
                                <SelectPagination
                                    onChange={(selected) => handleChangeSelect('emp_cd_cliente', selected)}
                                    id="selectClientes"
                                    selected={options => options.filter((el) => el.value === fields.emp_cd_cliente)}
                                    labelKey={(option) => `${option.name} - ${option.cnpj}`}
                                    maxResults={50}
                                    totalResults={listPessoasEmp.total}
                                    placeholder="Informe o Cliente"
                                    reduxAction={({ fields: params }) => {
                                        
                                        getListPessoasEmp({ fields: { ...params, empresaCod: fields.emp_id_empresa } })
                                    }}
                                    data={optionsClientes}
                                    clearButton
                                    isLoading={loadingPessoasEmp}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </>
    )
}

const mapStateToProps = ({ TiposEmpresa, Pessoas }) => {
    const { loadingPessoasEmp, listPessoasEmp } = Pessoas;
    const { listTiposEmp, loadingListTiposEmp } = TiposEmpresa;
    return {
        listTiposEmp,
        loadingListTiposEmp,
        loadingPessoasEmp,
        listPessoasEmp
    }
}

export default connect(mapStateToProps, {
    getListPessoasEmp
})(DadosGerais);