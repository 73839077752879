import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Col, Row } from "reactstrap";

function Campos({ }) {

    const optionsCampos = [
        {
            name: 'Todos',
            value: 'T',
        }
    ];

    return (
        <div className="b-light border-2 p-10">
            <h6>Filtro</h6>
            <Row>
                <Col xs="12">
                    <Typeahead
                        id="selectTipo"
                        labelKey="name"
                        placeholder="Informe o tipo"
                        defaultSelected={optionsCampos.filter((opt) => opt.value === 'T')}
                        options={optionsCampos}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Campos