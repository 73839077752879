import {
    GET_CONTAS_ASYNC,
    STORE_CONTAS_ASYNC,
    UPDATE_CONTAS_ASYNC,
    DESTROY_CONTAS_ASYNC,
    GET_LIST_CONTAS_ASYNC,
    GET_LIST_CONTAS_BANCOS_ASYNC,
    GET_LIST_CONTA_PESSOAS_ASYNC,
    GET_LIST_CONTAS_EMPRESA_ASYNC,
    GET_SALDOS_CONTA_ASYNC
} from "../actionTypes";

export const getContas = (payload = {}) => ({
    type: GET_CONTAS_ASYNC,
    payload
});

export const storeContas = payload => ({
    type: STORE_CONTAS_ASYNC,
    payload
});

export const updateContas = payload => ({
    type: UPDATE_CONTAS_ASYNC,
    payload
});

export const destroyContas = payload => ({
    type: DESTROY_CONTAS_ASYNC,
    payload
});

export const getListContas = (payload = {}) => ({
    type: GET_LIST_CONTAS_ASYNC,
    payload
});

export const getListContasBancos = (payload = {}) => ({
    type: GET_LIST_CONTAS_BANCOS_ASYNC,
    payload
});

export const getListContaPessoas = (payload = {}) => ({
    type: GET_LIST_CONTA_PESSOAS_ASYNC,
    payload
});

export const getListContasEmpresa = payload => ({
    type: GET_LIST_CONTAS_EMPRESA_ASYNC,
    payload
});

export const getSaldoConta = payload => ({
    type: GET_SALDOS_CONTA_ASYNC,
    payload
})
