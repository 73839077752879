import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
    Col,
    FormGroup,
    Row,
    Label,
    Input,
    FormFeedback,

} from "reactstrap";
import {
    getListContasGrupos,
    storeContas,
    changeListGrupos,
    getListPessoas,
    changeListPessoas
} from "../../../../../redux/actions";
import { Typeahead } from "react-bootstrap-typeahead";
import SelectPagination from "../../../../../components/SelectPagination";
const FirstCard = ({
    fields,
    validator,
    validFields,
    listPessoas,
    loadingListPesssoas,
    errors,
    listGrupos,
    loadingListGrupos,
    handleChange,
    handleChangeSelect,
    getListContasGrupos,
    getListPessoas
}) => {
    const optionsGrupos = useMemo(() => {
        return listGrupos.data.map(({ id, grc_cd_grupo, grc_nm_grupo, grupo_pai }) => {
            const name = grc_nm_grupo.trimEnd();
            return (
                {
                    name,
                    value: id,
                    grc_cd_grupo,
                    grupo_pai_name: !!grupo_pai ? grupo_pai.name : "Não informado",
                    cd_grupo_pai: !!grupo_pai ? grupo_pai.cd_grupo_pai : null,
                    subItem: `Grupo Pai: ${!!grupo_pai ? grupo_pai.name : "Não informado"}`
                }
            )
        })
    }, [listGrupos.data]);
    const selectedGrupo = optionsGrupos.find((el) => el.grc_cd_grupo === fields.ctn_cd_grupo);

    const optionsPessoas = useMemo(() =>
        listPessoas.data.map(({ id, pss_nm_pessoa, pss_cd_pessoa, pss_nu_identificacao }) => {
            return ({
                value: id,
                name: `${pss_cd_pessoa} - ${pss_nm_pessoa}`,
                subItem: `Num.Identificação: ${pss_nu_identificacao}`,
                pss_cd_pessoa
            })
        }), [listPessoas.data]);

    const optionsTipo = [{ name: "Venda", value: "V" }, { name: "Diversas", value: "D" }];

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Nome*</Label>
                            <Input
                                maxLength={60}
                                placeholder="Informe o nome"
                                name="ctn_nm_conta"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('ctn_nm_conta')}
                                onChange={handleChange} />
                            <FormFeedback>
                                {validator.message('ctn_nm_conta', fields.ctn_nm_conta, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Código*</Label>
                            <Input
                                maxLength={3}
                                placeholder="Informe o Código"
                                name="ctn_cd_conta"
                                onBlur={({ target: { name } }) => validFields(name)}
                                invalid={validator.visibleFields.includes('ctn_cd_conta') || !!errors.ctn_cd_conta}
                                onChange={handleChange} />
                            <FormFeedback>
                                {(!!errors.ctn_cd_conta) && errors.ctn_cd_conta.map((erro, index) => <span key={index}>{erro}</span>)}
                                {validator.message('ctn_cd_conta', fields.ctn_cd_conta, `required|max:3`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Grupo*</Label>
                            <SelectPagination
                                id="selectGrupos"
                                labelKey={(option) => `${option.name}`}
                                onBlur={() => validFields('ctn_cd_grupo')}
                                isInvalid={validator.visibleFields.includes('ctn_cd_grupo')}
                                maxResults={50}
                                placeholder="Selecione o Grupo"
                                data={optionsGrupos}
                                reduxAction={getListContasGrupos}
                                totalResults={listGrupos.total}
                                clearButton
                                isLoading={loadingListGrupos}
                                onChange={(selected) => handleChangeSelect('ctn_cd_grupo', selected)}
                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_cd_grupo') ? 'block' : 'none' }}>
                                {validator.message('ctn_cd_grupo', fields.ctn_cd_grupo, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Grupo Pai</Label>
                            <Input
                                name="ctn_cd_grupo_pai"
                                disabled
                                value={!!selectedGrupo ? selectedGrupo.grupo_pai_name : ""} />
                        </FormGroup>
                    </Col>
                    <Col md="8" xs="12">
                        <FormGroup>
                            <Label>Pessoa Padrão*</Label>
                            <SelectPagination
                                id="selectPessoa"
                                labelKey="name"
                                onBlur={() => validFields('ctn_cd_pessoa')}
                                isInvalid={validator.visibleFields.includes('ctn_cd_pessoa')}
                                maxResults={50}
                                placeholder="Selecione a Pessoa Padrão"
                                data={optionsPessoas}
                                totalResults={listPessoas.total}
                                reduxAction={getListPessoas}
                                clearButton
                                isLoading={loadingListPesssoas}
                                onChange={(selected) => handleChangeSelect('ctn_cd_pessoa', selected)}
                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_cd_pessoa') ? 'block' : 'none' }}>
                                {validator.message('ctn_cd_pessoa', fields.ctn_cd_pessoa, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Tipo*</Label>
                            <Typeahead
                                id="selectTipo"
                                labelKey="name"
                                onBlur={() => validFields('ctn_tp_conta')}
                                isInvalid={validator.visibleFields.includes('ctn_tp_conta')}
                                options={optionsTipo}
                                onChange={(selected) => handleChangeSelect('ctn_tp_conta', selected)}
                                clearButton
                                placeholder="Informe o Tipo"
                            />
                            <FormFeedback style={{ display: validator.visibleFields.includes('ctn_tp_conta') ? 'block' : 'none' }}>
                                {validator.message('ctn_tp_conta', fields.ctn_tp_conta, `required`)}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

const mapStateToProps = ({ Contas, ContasGrupos, Pessoas }) => {
    const { errors, loadingStore } = Contas;
    const { list: listPessoas, loadingList: loadingListPesssoas } = Pessoas;
    const { list: listGrupos, loadingList: loadingListGrupos } = ContasGrupos;
    return { errors, listGrupos, loadingListGrupos, loadingStore, listPessoas, loadingListPesssoas }
}

export default connect(mapStateToProps, {
    getListContasGrupos,
    changeListGrupos,
    storeContas,
    getListPessoas,
    changeListPessoas
})(FirstCard);