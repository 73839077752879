import { format, parseISO } from "date-fns";
import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function FirstCard({ notaFiscal }) {
    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Data de Emissão</Label>
                            <Input
                                readOnly
                                defaultValue={!!notaFiscal.dhEmi ? format(parseISO(notaFiscal.dhEmi), 'dd/MM/yyyy') : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Tipo</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.nfe_tp_nota === 'C' ? 'Cancelada' : notaFiscal.nfe_tp_nota === 'S' ? 'Saida' : notaFiscal.nfe_tp_nota === 'E' ? 'Entrada' : 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="2" xs="12">
                        <FormGroup>
                            <Label>Serie</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.serie || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                        <FormGroup>
                            <Label>Código Numérico</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.cNF || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                        <FormGroup>
                            <Label>Número do Documento</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.nNF || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="2" xs="12">
                        <FormGroup>
                            <Label>Chave</Label>
                            <Input
                                readOnly
                                defaultValue={notaFiscal.id || 'Não Informado'}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <Label>CFOP (Código Fiscal de Operações e Prestações) </Label>
                            <Input readOnly defaultValue={notaFiscal.CFOP || 'Não Informado'} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </FormGroup>
    )
}

export default FirstCard;