import React, {  useState, useCallback, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Form
} from "reactstrap";
import { storeTiposMovPrd } from "../../../../../redux/actions";
import SimpleReactValidator from "simple-react-validator";
import { validatorMessages } from "../../../../../constraints/variables";
import { toast } from "react-toastify";


const initalState = {
    tmp_nm_tipo_mvp: "",
    tmp_tp_movimento: "",
    tmp_tp_operacao: "",
    tmp_cd_ctn_ajuste: "",
    tmp_ds_historico: ""
}

function Add({
    isOpen,
    clickClose,
    storeTiposMovPrd,
    loadingStoreTiposMovPrd,
    errors
}) {
    const [fields, setFields] = useState(initalState);

    //VALIDATOR
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate },
        messages: validatorMessages,
    })).current;

    //FUNÇÕES
    const clearErrors = name => {
        if (!!errors[name]) {
            delete errors[name];
        }
    }
    const handleChangeSelect = (name, selected) => {
        switch (name) {
            default:
                if (!!selected.length) {
                    setFields({ ...fields, [name]: selected[0].value })
                } else {
                    setFields({ ...fields, [name]: "" })
                }
                break;
        }
        clearErrors(name)
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
        clearErrors(name);
    }
    const validFields = name => {
        if (!validator.fieldValid(name)) {
            validator.showMessageFor(name);
        } else {
            validator.hideMessageFor(name)
        }
    }
    const submitForm = e => {
        e.preventDefault();
        if (validator.allValid()) {
            storeTiposMovPrd({ fields, onSave: clickClose });
        } else {
            validator.showMessages();
            toast.error('Preencha os Campos Corretamente', toast.POSITION.TOP_RIGHT);
        }
    }

    //VARIÁVEIS
    const optionsTipoOperacao = [
        {
            name: 'Compra',
            value: 'CP'
        },
        {
            name: 'Venda',
            value: 'VD'
        },
        {
            name: 'Diff.Temp Compra',
            value: 'TC'
        },
        {
            name: 'Diff.Temp Venda',
            value: 'TV'
        },
        {
            name: 'Diff.Temp Estoque',
            value: 'TE'
        },
        {
            name: 'Empresa Obitido',
            value: 'EO'
        },
        {
            name: 'Empresa Concedido',
            value: 'EC'
        },
        {
            name: 'Transfêrencia Saida/Entrada',
            value: 'TR'
        },
        {
            name: 'Saida Devolução',
            value: 'SD'
        }
    ];

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
            }}
            onClosed={() => {
                setFields(initalState)
                validator.visibleFields = [];
                validator.messagesShown = false;
                for (const key in errors) {
                    delete errors[key]
                }
            }}
        >
            <ModalHeader toggle={() => !loadingStoreTiposMovPrd && clickClose()}>
                Adicionar Tipo de Movimentação
            </ModalHeader>
            <Form onSubmit={submitForm}>
                <ModalBody>
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input
                                    name="tmp_nm_tipo_mvp"
                                    placeholder="Informe o Nome"
                                    onChange={handleChange}
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    invalid={validator.visibleFields.includes('tmp_nm_tipo_mvp') ||
                                        !!errors.tmp_nm_tipo_mvp ||
                                        (!validator.fields.tmp_nm_tipo_mvp && validator.messagesShown)
                                    }
                                />
                                <FormFeedback>
                                    {validator.message('tmp_nm_tipo_mvp', fields.tmp_nm_tipo_mvp, 'required|min:2|max:30')}
                                    {(!!errors.tmp_nm_tipo_mvp) && errors.tmp_nm_tipo_mvp.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Tipo Movimentação</Label>
                                <Typeahead
                                    labelKey="name"
                                    id="selectTipo"
                                    options={[{ name: 'Entrada', value: 'E' }, { name: 'Saida', value: 'S' }]}
                                    placeholder="Informe o Tipo da Movimentação"
                                    onChange={(selected) => handleChangeSelect('tmp_tp_movimento', selected)}
                                    onBlur={() => validFields('tmp_tp_movimento')}
                                    isInvalid={validator.visibleFields.includes('tmp_tp_movimento') ||
                                        !!errors.tmp_tp_movimento ||
                                        (!validator.fields.tmp_tp_movimento && validator.messagesShown)
                                    }
                                    clearButton
                                />
                                <FormFeedback style={{
                                    display: validator.visibleFields.includes('tmp_tp_movimento') ||
                                        !!errors.tmp_tp_movimento ||
                                        (!validator.fields.tmp_tp_movimento && validator.messagesShown) ? 'block' : 'none'
                                }}>
                                    {validator.message('tmp_tp_movimento', fields.tmp_tp_movimento, 'required')}
                                    {(!!errors.tmp_tp_movimento) && errors.tmp_tp_movimento.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Histórico</Label>
                                <Input
                                    name="tmp_ds_historico"
                                    placeholder="Informe o Histórico"
                                    onBlur={({ target: { name } }) => validFields(name)}
                                    onChange={handleChange}
                                    invalid={validator.visibleFields.includes('tmp_ds_historico') ||
                                        !!errors.tmp_ds_historico ||
                                        (!validator.fields.tmp_ds_historico && validator.messagesShown)
                                    }
                                />
                                <FormFeedback>
                                    {validator.message('tmp_ds_historico', fields.tmp_ds_historico, 'required|min:2|max:30')}
                                    {(!!errors.tmp_ds_historico) && errors.tmp_ds_historico.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Tipo Operação</Label>
                                <Typeahead
                                    labelKey="name"
                                    id="selectOperacao"
                                    options={optionsTipoOperacao}
                                    placeholder="Informe o Tipo da Operação"
                                    onChange={(selected) => handleChangeSelect('tmp_tp_operacao', selected)}
                                    onBlur={() => validFields('tmp_tp_operacao')}
                                    isInvalid={validator.visibleFields.includes('tmp_tp_operacao') ||
                                        !!errors.tmp_tp_operacao ||
                                        (!validator.fields.tmp_tp_operacao && validator.messagesShown)
                                    }
                                    clearButton
                                />
                                <FormFeedback style={{
                                    display: validator.visibleFields.includes('tmp_tp_operacao') ||
                                        !!errors.tmp_tp_operacao ||
                                        (!validator.fields.tmp_tp_operacao && validator.messagesShown) ? 'block' : 'none'
                                }}>
                                    {validator.message('tmp_tp_operacao', fields.tmp_tp_operacao, 'required')}
                                    {(!!errors.tmp_tp_operacao) && errors.tmp_tp_operacao.map((erro, index) => <span key={index}>{erro}</span>)}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={loadingStoreTiposMovPrd}
                        type="button"
                        onClick={() => !loadingStoreTiposMovPrd && clickClose()}>
                        Fechar
                </Button>
                    <Button disabled={loadingStoreTiposMovPrd} color="primary">
                        {loadingStoreTiposMovPrd ? 'Processando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ TipoMovPrd }) => {
    const { loadingStoreTiposMovPrd, errors } = TipoMovPrd;
    return { loadingStoreTiposMovPrd, errors }
}

export default connect(mapStateToProps, {
    storeTiposMovPrd
})(Add);