import React from 'react';
import { Col, FormGroup, Row, Label, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { parseISO } from 'date-fns';

function Periodo({filter,handleChangeSelect}) { 

    return (
        <div className="b-light border-2 p-10">
            <h6> Periodo </h6>
            <Row>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Data Inicial
                        </Label>
                        <DatePicker
                            locale={br}
                            autoComplete="off"
                            dateFormat="dd/MM/yyyy"
                            selected={parseISO(filter.dataInicial)}
                            className="form-control digits"
                            placeholderText="Informe a data inicial"
                            onChange={(date) => handleChangeSelect('dataInicial', date)}
                        />
                    </FormGroup>
                </Col>
                <Col lg="6" sm="12">
                    <FormGroup>
                        <Label>
                            Data Final
                        </Label>
                        <DatePicker
                            locale={br}
                            autoComplete="off"
                            minDate={parseISO(filter.dataInicial)}
                            dateFormat="dd/MM/yyyy"
                            selected={parseISO(filter.dataFinal)}
                            className="form-control digits"
                            placeholderText="Informe a data final"
                            onChange={(date) => handleChangeSelect('dataFinal', date)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );

}


export default Periodo;
