import React, { } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

function View({
    isOpen,
    clickClose,
    tipoMovPrd
}) {

    //VARIÁVEIS
    const optionsTipoOperacao = [
        {
            name: 'Compra',
            value: 'CP'
        },
        {
            name: 'Venda',
            value: 'VD'
        },
        {
            name: 'Diff.Temp Compra',
            value: 'TC'
        },
        {
            name: 'Diff.Temp Venda',
            value: 'TV'
        },
        {
            name: 'Diff.Temp Estoque',
            value: 'TE'
        },
        {
            name: 'Empresa Obitido',
            value: 'EO'
        },
        {
            name: 'Empresa Concedido',
            value: 'EC'
        },
        {
            name: 'Transfêrencia Saida/Entrada',
            value: 'TR'
        },
        {
            name: 'Saida Devolução',
            value: 'SD'
        }
    ];

    const optionsTiposMov = [{ name: 'Entrada', value: 'E' }, { name: 'Saida', value: 'S' }];

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpened={() => {
            }}
            onClosed={() => {

            }}
        >
            <ModalHeader toggle={() => clickClose()}>
                Informações Tipo de Movimentação
            </ModalHeader>
            <ModalBody>
                {!!tipoMovPrd &&
                    <Row>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input
                                    name="tmp_nm_tipo_mvp"
                                    placeholder="Informe o Nome"
                                    defaultValue={tipoMovPrd.tmp_nm_tipo_mvp}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Tipo Movimentação</Label>
                                <Typeahead
                                    labelKey="name"
                                    id="selectTipo"
                                    options={optionsTiposMov}
                                    placeholder="Informe o Tipo da Movimentação"
                                    disabled
                                    defaultSelected={optionsTiposMov.filter((option) => option.value === tipoMovPrd.tmp_tp_movimento)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Histórico</Label>
                                <Input
                                    name="tmp_ds_historico"
                                    placeholder="Informe o Histórico"
                                    readOnly
                                    defaultValue={tipoMovPrd.tmp_ds_historico}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>Tipo Operação</Label>
                                <Typeahead
                                    labelKey="name"
                                    id="selectOperacao"
                                    options={optionsTipoOperacao}
                                    disabled
                                    defaultSelected={optionsTipoOperacao.filter((option) => option.value === tipoMovPrd.tmp_tp_operacao)}
                                    placeholder="Informe o Tipo da Operação"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    onClick={() => clickClose()}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default View;