import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Table, Row, Col, Alert, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { format, parseISO } from 'date-fns';
import ScrollArea from "react-scrollbar";
function Faturas({
    ftrParaConcilar,
    loadingGetFtrConcilar,
    extratoBanco,
    selectedExtrato,
    selectedFtr,
    setSelectedFtr,
    listConciliados,
    loadingConciliarFtr
}) {

    const extrato = selectedExtrato !== null && extratoBanco?.extratos.find((el) => el.pos === selectedExtrato);
    const total = ftrParaConcilar.reduce((acu, { ftr_vl_fatura }) => acu + parseFloat(ftr_vl_fatura), 0);
    const totalMarcados = selectedFtr.reduce((acu, { ftr_vl_fatura }) => acu + parseFloat(ftr_vl_fatura), 0);
    const curConciliados = listConciliados.filter((concliado) => concliado.extrato.documento === extrato.documento);
    const chkValidFaturas = (arrContent, fatura) => {
        return !!arrContent.find((el) =>
            el.lancamento.lan_cd_subconta === fatura.lancamento.lan_cd_subconta &&
            el.lancamento.lan_cd_conta === fatura.lancamento.lan_cd_conta &&
            el.lancamento.lan_cd_centro_custo === fatura.lancamento.lan_cd_centro_custo &&
            el.contrapartida.cnt_cd_conta === fatura.contrapartida.cnt_cd_conta &&
            el.contrapartida.cnt_cd_subconta === fatura.contrapartida.cnt_cd_subconta &&
            el.contrapartida.cnt_cd_centro_custo === fatura.contrapartida.cnt_cd_centro_custo &&
            el.extrato.documento === extrato.documento
        );


    }

    return (
        <Card className="card-absolute">
            <CardHeader className="bg-primary">
                <h5>Faturas</h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs="12">
                        {loadingGetFtrConcilar || loadingConciliarFtr ?
                            <div className="loader-box">
                                <div className="loader-1"></div>
                            </div> :
                            <ScrollArea horizontal={false}>
                                <Table responsive style={{ textAlign: 'center' }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Vencimento</th>
                                            <th>Documento</th>
                                            <th>Valor</th>
                                            <th>Data no extrato</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!ftrParaConcilar.length ?
                                            ftrParaConcilar.map((fatura, indexFtr) => {
                                                const chkFaturas = chkValidFaturas(selectedFtr, fatura);
                                                const chkConciliado = chkValidFaturas(curConciliados, fatura);
                                                return (
                                                    <Fragment key={indexFtr}>
                                                        <tr id={`rowFtr${indexFtr}`} className={
                                                            (!!selectedFtr.length && !chkFaturas) || (!!curConciliados.length && !chkConciliado)
                                                                ? 'disabled' : ''}>
                                                            <td rowSpan="2" className={indexFtr < (ftrParaConcilar.length - 1) ? 'b-b-dark border-2' : ''}>
                                                                <Label>
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        disabled={(!!selectedFtr.length && !chkFaturas)}
                                                                        checked={!!selectedFtr.find((selFtr) => selFtr.ftr_id_fatura === fatura.ftr_id_fatura)}
                                                                        onChange={() => {
                                                                            const curSelectedFtr = [...selectedFtr];
                                                                            const indexSel = curSelectedFtr.findIndex((selFtr) => selFtr.ftr_id_fatura === fatura.ftr_id_fatura);
                                                                            if (indexSel === -1) {
                                                                                curSelectedFtr.push({ ...fatura, extrato })
                                                                            } else {
                                                                                curSelectedFtr.splice(indexSel, 1);
                                                                            }
                                                                            setSelectedFtr(curSelectedFtr);
                                                                        }}
                                                                        id={`chk-ani${indexFtr}`}
                                                                        type="checkbox" />
                                                                </Label>
                                                            </td>
                                                            <td>{format(parseISO(fatura.ftr_dt_vencimento), 'dd/MM/yyyy')}</td>
                                                            <td>{fatura.ftr_nu_fatura}</td>
                                                            <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fatura.ftr_vl_fatura)}</td>
                                                            <td>{!!extrato?.dia && format(parseISO(extrato.dia), 'dd/MM/yyyy')}</td>
                                                        </tr>
                                                        <tr
                                                            className={`
                                                            ${(!!selectedFtr.length && !chkFaturas) || (!!curConciliados.length && !chkConciliado)
                                                                    ? 'disabled' : ''
                                                                }
                                                                ${indexFtr < (ftrParaConcilar.length - 1) ? 'b-b-dark border-2' : ''}
                                                                `}
                                                        >
                                                            <td style={{ textAlign: 'left' }} colSpan="5">{`${fatura.pessoa?.pss_cd_pessoa} - ${fatura.pessoa?.pss_nm_pessoa}`}</td>
                                                        </tr>
                                                        {
                                                            (!!selectedFtr.length && !chkFaturas) &&
                                                            <UncontrolledTooltip target={`rowFtr${indexFtr}`}>
                                                                Informações do lançamento/contrapartida que gerou esta fatura diverge dos lancamentos/contrapartida das faturas selecionadas/conciliadas.
                                                            </UncontrolledTooltip>
                                                        }

                                                    </Fragment>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan="5"><Alert color="light">Nenhum fatura encontrada</Alert></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </ScrollArea>
                        }
                        <div className="p-10 b-dark m-t-15 d-flex justify-content-between">
                            <span>Marcados: {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalMarcados)}</span>
                            <span>Total {Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(total)}</span>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = ({ Faturas, Arquivos }) => {
    const { extratoBanco } = Arquivos;
    const { ftrParaConcilar, loadingGetFtrConcilar, loadingConciliarFtr } = Faturas
    return { ftrParaConcilar, loadingGetFtrConcilar, extratoBanco, loadingConciliarFtr };
}

export default connect(mapStateToProps, {

})(Faturas);