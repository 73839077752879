import { call, put, fork, all, debounce } from 'redux-saga/effects'
import Api from "../../services/api";
import { STORE_SEL_CENTROS_CUSTO, STORE_SEL_CENTROS_CUSTO_ASYNC } from "../actionTypes";

function* storeSelCentrosCustoSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiStoreSelCentrosCusto, fields);
        if (data.ok) {
            const { selCentroCusto } = data;
            yield put({ type: STORE_SEL_CENTROS_CUSTO, payload: { selCentroCusto } });
        }
    } catch (error) {
        yield put({ type: STORE_SEL_CENTROS_CUSTO });
    }
}
const apiStoreSelCentrosCusto = async fields => {
    const { data } = await Api.post(`/api/sel_cct/store`, { ...fields });

    return data;
}

function* watchStoreSelCentrosCusto() {
    yield debounce(1000, STORE_SEL_CENTROS_CUSTO_ASYNC, storeSelCentrosCustoSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchStoreSelCentrosCusto)
    ]);
}