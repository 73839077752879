import {
    GET_RLT_MOVIMENTACAO_ASYNC,
    CLEAR_RLT_MOVIMENTACAO,
    GET_RLT_FLUXO_CAIXA_ASYNC,
    GET_RLT_PLANO_CONTAS_ASYNC,
    GET_RLT_MASTER,
    GET_RLT_MASTER_ASYNC
} from "../actionTypes";

// --- MOVIMENTAÇÃO ---
export const getRltMovimentacao = (payload = {}) => ({
    type: GET_RLT_MOVIMENTACAO_ASYNC,
    payload
});
export const clearRltMovimentacao = () => ({
    type: CLEAR_RLT_MOVIMENTACAO
});

// --- PLANO DE CONTAS ---
export const getRltPlanoContas = payload => ({
    type: GET_RLT_PLANO_CONTAS_ASYNC,
    payload
});

// --- MASTER ---
export const getRltMaster = payload => ({
    type: GET_RLT_MASTER_ASYNC,
    payload
});

export const setRltMaster = payload => ({
    type: GET_RLT_MASTER,
    payload
});

//--- FLUXO DE CAIXA ---
export const getRltFluxoCaixa = payload => ({
    type:GET_RLT_FLUXO_CAIXA_ASYNC,
    payload
})