import React from 'react';
import { connect } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { CpfCnpjInput } from "../../../../../components/CustomInputs";

function DadosGerais({
    empresa
}) {


    return (
        <>
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Dados Gerais</h6></Label>
                    <Row>
                        <Col md="2" xs="12">
                            <FormGroup>
                                <Label>Código</Label>
                                <Input defaultValue={empresa.emp_cd_empresa} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="7" xs="12">
                            <FormGroup>
                                <Label>Tipo</Label>
                                <Input defaultValue={`${!!empresa.tipo ? `${empresa.tipo.tpe_nm_tipo} - ${empresa.tipo.tpe_ds_tipo}` : ''}`} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="3" xs="12">
                            <FormGroup>
                                <Label>Dados</Label>
                                <Input defaultValue={!!empresa.emp_tp_dados ? empresa.emp_tp_dados === 'L' ? 'Local' : 'Distribuição' : ''} readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Nome/Razão Social</Label>
                                <Input defaultValue={empresa.emp_nm_empresa} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="4" xs="12">
                            <FormGroup>
                                <Label>CNPJ/CPF</Label>
                                <CpfCnpjInput defaultValue={empresa.emp_nu_cnpj_cpf} readOnly />
                            </FormGroup>
                        </Col>
                        <Col md="8" xs="12">
                            <FormGroup>
                                <Label>Cliente</Label>
                                <Input
                                    defaultValue={!!empresa.cliente ? `${empresa.cliente.clt_cd_cliente} - ${empresa.cliente.clt_nm_razao_social} - ${empresa.cliente.clt_nu_ident}` : ''}
                                    readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </>
    )
}

const mapStateToProps = ({ }) => {
    return {}
}

export default connect(mapStateToProps, {})(DadosGerais);