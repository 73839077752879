import { takeLatest, call, put, fork, all } from "redux-saga/effects";
import Api from "../../services/api";
import { GET_LIST_ESTADOS, GET_LIST_ESTADOS_ASYNC } from "../actionTypes";

function* getListEstadosSaga() {
    try {
        const data = yield call(apiGetListEstados);
        if (data.ok) {
            const { list } = data;
            yield put({ type: GET_LIST_ESTADOS, payload: { list } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_ESTADOS, payload: {} });
    }
}
const apiGetListEstados = async () => {
    const { data } = await Api.get(`/api/estados/list`);

    return data;
}

function* watchGetListEstados() {
    yield takeLatest(GET_LIST_ESTADOS_ASYNC, getListEstadosSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetListEstados)
    ]);
} 