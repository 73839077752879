import React from 'react';
import { FormGroup, Col, Row, Input, Label } from "reactstrap";

const FourthCard = ({ conta }) => {

    return (
        <FormGroup>
            <div className="shadow shadow-showcase p-15">
                <Row>
                    <Col xs="12" className="m-b-10">
                        <Label>Lançamentos</Label>
                        <FormGroup className=" m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="radio radio-primary m-r-50">
                                <Input id="radioinline1" type="radio" disabled checked={conta.ctn_st_lan_deb_cre === "D"} />
                                <Label className="mb-0" for="radioinline1">Só a débito</Label>
                            </div>
                            <div className="radio radio-primary m-r-50">
                                <Input id="radioinline2" type="radio" disabled checked={conta.ctn_st_lan_deb_cre === "C"} />
                                <Label className="mb-0" for="radioinline2">Só a crédito</Label>
                            </div>
                            <div className="radio radio-primary m-r-50">
                                <Input id="radioinline3" type="radio" disabled checked={conta.ctn_st_lan_deb_cre === "A"} />
                                <Label className="mb-0" for="radioinline3">Ambos</Label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <Label>Opções</Label>
                        <FormGroup className=" m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="checkbox checkbox-dark  m-r-50">
                                <Input id="inline-1" disabled type="checkbox" checked={conta.ctn_st_saldo_neg === "S"} />
                                <Label for="inline-1">Saldo negativo</Label>
                            </div>
                            <div className="checkbox checkbox-dark  m-r-50">
                                <Input id="inline-1" disabled type="checkbox" checked={conta.ctn_st_lanc_manual === "S"} />
                                <Label for="inline-1">Lançamento manual</Label>
                            </div>
                            <div className="checkbox checkbox-dark  m-r-50">
                                <Input id="inline-1" disabled type="checkbox" checked={conta.ctn_st_pag_cheque === "S"} />
                                <Label for="inline-1">Paga com cheque</Label>
                            </div>
                        </FormGroup>
                    </Col>

                </Row>
            </div>
        </FormGroup>
    )
}

export default FourthCard;