import axios from "axios";
import { toast } from 'react-toastify';
import { store } from "../redux/store";
toast.configure();
const api = axios.create({
        // baseURL: 'http://localhost:8000/',
    // baseURL: 'http://167.99.12.227:8000/' // DEV
    baseURL: 'http://stratus.gopontoaponto.com:8000/' 
});

api.interceptors.request.use(async config => {
    const token = store.getState().Auth.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(async response => {
    return response
}, async error => {
    switch (error.response.status) {
        case 401:
            if (!!error.response.data.error) {
                toast.error(error.response.data.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            break;
        case 404:
            toast.error("O registro não foi localizado", {
                position: toast.POSITION.TOP_RIGHT,
            });
            break;
        case 422:
            //CASO EXISTA O PARAM ERRORS RETORNA RESPOSTA PARA PROMISE 
            toast.error('Preencha os campos corretamente.', toast.POSITION.TOP_RIGHT)
            if (!!JSON.parse(error.response.request.response).errors) {
                return JSON.parse(error.response.request.response);
            }
            break;
        case 500:
            if (!!JSON.parse(error.response.request.response).msg) {
                toast.error(JSON.parse(error.response.request.response).msg, {
                    position: toast.POSITION.TOP_RIGHT,

                });
            } else {
                toast.error("Ocorreu um erro, Tente mais tarde", {
                    position: toast.POSITION.TOP_RIGHT,

                });
            }
            break;
        default:
            toast.error("Ocorreu um erro, Tente mais tarde", {
                position: toast.POSITION.TOP_RIGHT,

            });
            break;
    }

})

export default api;