import React, { useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,TabContent, TabPane, FormFeedback } from 'reactstrap'
import { loginUser } from "../../redux/actions";
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
const Login = ({ loginUser, loading }) => {

  const [togglePassword, setTogglePassword] = useState(false)
  const [fields, setFields] = useState({ email: null, password: null });
  const [selected, setSelected] = useState("jwt");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  }
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }
  const validFields = ({ target: { name } }) => {
    if (!validator.fieldValid(name)) {
      validator.showMessageFor(name);
    } else {
      validator.hideMessageFor(name)
    }
  }

  const submitLogin = e => {
    e.preventDefault();
    if (validator.allValid()) {
      loginUser({ fields });
    } else {
      validator.showMessages();
    }
  }

  const validator = useRef(new SimpleReactValidator({
    autoForceUpdate: { forceUpdate },
    messages: {
      required: 'Este campo é obrigatório.',
      email: 'Email inválido'
    }
  })).current;
  return (
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="#javascript">
                  <img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="" />
                  <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="" />
                </a>
              </div>
              <div className="login-main login-tab">
                {/* <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => setSelected('jwt')}>
                      <img src={require("../../assets/images/jwt.svg")} alt="" />
                      <span>{JWT}</span>
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <TabContent activeTab={selected} className="content-login">
                  <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form" onSubmit={submitLogin}>
                      <h4>{"Login"}</h4>
                      <p>{"Informe email e senha para entrar"}</p>
                      <FormGroup>
                        <Label className="col-form-label">Email</Label>
                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          required=""
                          onBlur={validFields}
                          onChange={handleChange}
                          invalid={validator.visibleFields.includes('email')}
                          placeholder="exemplo@gmail.com"
                        />
                        <FormFeedback>
                          {validator.message('email', fields.email, 'required')}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Senha</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          name="password"
                          onChange={handleChange}
                          required=""
                          placeholder="*********"
                          onBlur={validFields}
                          invalid={validator.visibleFields.includes('password')}
                        />
                        <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                        <FormFeedback>
                          {validator.message('password', fields.password, 'required')}
                        </FormFeedback>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">Manter conectado</Label>
                        </div>
                        <a className="link disabled" href="#javascript">Esqueceu a senha?</a>

                        <Button color="primary" className="btn-block" disabled={loading}>{loading ? 'Processando...' : 'Login'}</Button>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = ({ Auth }) => {
  const { loading } = Auth;
  return { loading }
}
export default connect(mapStateToProps, {
  loginUser
})(Login);