import React, { Fragment } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Form, Button } from 'reactstrap';
import { connect } from "react-redux";

const AlterarSenha = ({ user }) => {
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form >
                            <Row style={{ textAlign: 'initial' }}>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="inputSenhaAtual">Senha Atual</Label>
                                        <Input id="inputSenhaAtual" type="password" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6">
                                    <FormGroup>
                                        <Label for="inputNovaSenha">Nova Senha</Label>
                                        <Input id="inputNovaSenha" type="password" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6">
                                    <FormGroup>
                                        <Label for="inputCNovaSenha">Confirmar Nova Senha</Label>
                                        <Input id="inputCNovaSenha" type="password" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" style={{ justifyContent: 'flex-end', display: "flex" }}>
                                    <Button color="primary" onClick={() => alert('Funcionalidade em desenvolvimento.')}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Auth }) => {
    const { user } = Auth;
    return { user }
}

export default connect(mapStateToProps, {})(AlterarSenha);