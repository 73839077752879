import { call, takeLatest, put, fork, all, select, debounce } from "redux-saga/effects";
import { selectorContasGrupos } from "../selectors";
import {
    GET_CONTAS_GRUPOS_ASYNC,
    GET_CONTAS_GRUPOS,
    STORE_CONTAS_GRUPOS_ASYNC,
    STORE_CONTAS_GRUPOS,
    UPDATE_CONTAS_GRUPOS_ASYNC,
    UPDATE_CONTAS_GRUPOS,
    DESTROY_CONTAS_GRUPOS_ASYNC,
    DESTROY_CONTAS_GRUPOS,
    GET_LIST_CONTAS_GRUPOS_ASYNC,
    GET_LIST_CONTAS_GRUPOS,
    GET_LIST_GRC_CONTA_EMP_ASYNC,
    GET_LIST_GRC_CONTA_EMP
} from "../actionTypes";
import Api from "../../services/api";
import { toast } from 'react-toastify';
import { selectorContasGruposList } from "../selectors";

function* getContasGruposSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetContasGrupos, fields);
        if (data.ok) {
            const { contasGrupos } = data;
            yield put({ type: GET_CONTAS_GRUPOS, payload: { contasGrupos } });
        }
    } catch (error) {
        yield put({ type: GET_CONTAS_GRUPOS, payload: {} });
    }
}
const apiGetContasGrupos = async fields => {
    const { data } = await Api.get(`/api/grupos_contas`, { params: { ...fields } });

    return data;
}

function* storeContasGruposSaga({ payload }) {
    try {
        const { fields, onCreate } = payload;
        const { data, errors } = yield call(apiStoreContasGrupos, fields);
        if (!!data && data.ok) {
            const { contasGrupos } = data;
            yield put({ type: STORE_CONTAS_GRUPOS, payload: { contasGrupos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onCreate();
        }
        if (!!errors) {
            yield put({ type: STORE_CONTAS_GRUPOS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_CONTAS_GRUPOS, payload: {} });
    }
}
const apiStoreContasGrupos = async fields => {
    const data = await Api.post(`/api/grupos_contas/store`, { ...fields });

    return data;
}

function* updateContasGruposSaga({ payload }) {
    try {
        const { fields, onUpdate, index } = payload;
        const { data, errors } = yield call(apiUpdateContasGrupos, fields);
        if (!!data && data.ok) {
            const { grupoConta } = data;
            const contasGrupos = yield select(selectorContasGrupos);
            contasGrupos.data.splice(index, 1, grupoConta);
            yield put({ type: UPDATE_CONTAS_GRUPOS, payload: { contasGrupos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onUpdate();
        }
        if (!!errors) {
            yield put({ type: UPDATE_CONTAS_GRUPOS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_CONTAS_GRUPOS, payload: {} });
    }
}
const apiUpdateContasGrupos = async fields => {
    const id = fields.id;
    delete fields.id;
    const data = await Api.put(`/api/grupos_contas/update/${id}`, { ...fields });

    return data;
}

function* destroyContasGruposSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyContasGrupos, fields);
        if (data.ok) {
            const { contasGrupos } = data;
            yield put({ type: DESTROY_CONTAS_GRUPOS, payload: { contasGrupos } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTROY_CONTAS_GRUPOS, payload: {} });
    }
}
const apiDestroyContasGrupos = async fields => {
    const { data } = await Api.delete(`/api/grupos_contas/destroy`, { data: { ...fields } });

    return data;
}

function* getListGrcContasEmpSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListGrcContasEmp, fields);
        if (data.ok) {
            const { listGrcContaEmp } = data;
            yield put({ type: GET_LIST_GRC_CONTA_EMP, payload: { listGrcContaEmp } });
        }
    } catch (error) {
        yield put({ type: GET_LIST_GRC_CONTA_EMP, payload: {} });
    }
}
const apiGetListGrcContasEmp = async fields => {
    const { data } = await Api.get(`/api/grupos_contas/listGrcContaEmp`, { params: fields });

    return data;
}

function* getListContasGruposSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListContasGrupos, fields);
        if (data.ok) {
            if (!!fields && !!fields.offset) {
                const list = yield select(selectorContasGruposList);
                list.data = [...list.data, ...data.list.data]
                list.total = data.list.total;
                yield put({ type: GET_LIST_CONTAS_GRUPOS, payload: { list } });
            } else {
                yield put({ type: GET_LIST_CONTAS_GRUPOS, payload: { list: data.list } });
            }
        }
    } catch (error) {
        yield put({ type: GET_LIST_CONTAS_GRUPOS, payload: {} })
    }
}
const apiGetListContasGrupos = async fields => {
    const { data } = await Api.get(`/api/grupos_contas/list`, { params: fields });

    return data;
}

function* watchGetContasGrupos() {
    yield takeLatest(GET_CONTAS_GRUPOS_ASYNC, getContasGruposSaga)
}
function* watchStoreContasGrupos() {
    yield takeLatest(STORE_CONTAS_GRUPOS_ASYNC, storeContasGruposSaga);
}
function* watchUpdateContasGrupos() {
    yield takeLatest(UPDATE_CONTAS_GRUPOS_ASYNC, updateContasGruposSaga);
}
function* watchDestroyContasGrupos() {
    yield takeLatest(DESTROY_CONTAS_GRUPOS_ASYNC, destroyContasGruposSaga);
}
function* watchGetListContasGrupos() {
    yield debounce(1000, GET_LIST_CONTAS_GRUPOS_ASYNC, getListContasGruposSaga);
}
function* watchGetListGrcContasEmp() {
    yield debounce(1000, GET_LIST_GRC_CONTA_EMP_ASYNC, getListGrcContasEmpSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetContasGrupos),
        fork(watchStoreContasGrupos),
        fork(watchUpdateContasGrupos),
        fork(watchDestroyContasGrupos),
        fork(watchGetListContasGrupos),
        fork(watchGetListGrcContasEmp)
    ])
}