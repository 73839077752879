import {
    GET_CONTAS_GRUPOS_ASYNC,
    GET_CONTAS_GRUPOS,
    STORE_CONTAS_GRUPOS_ASYNC,
    STORE_CONTAS_GRUPOS,
    UPDATE_CONTAS_GRUPOS_ASYNC,
    UPDATE_CONTAS_GRUPOS,
    DESTROY_CONTAS_GRUPOS_ASYNC,
    DESTROY_CONTAS_GRUPOS,
    GET_LIST_CONTAS_GRUPOS_ASYNC,
    GET_LIST_CONTAS_GRUPOS,
    CLEAR_ERRORS_CONTAS_GRUPOS_CONTAS,
    GET_LIST_GRC_CONTA_EMP_ASYNC,
    GET_LIST_GRC_CONTA_EMP
} from "../actionTypes";


const initial_state = {
    errors: {},
    contasGrupos: null,
    list: {
        data: [],
        total: 0
    },
    loading: false,
    loadingStore: false,
    loadingUpdate: false,
    loadingDestroy: false,
    loadingList: false,
    loadingGrcContaEmp: false,
    listGrcContaEmp: { data: [], total: 0 }
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_CONTAS_GRUPOS_ASYNC:
            return { ...state, loading: true };
        case GET_CONTAS_GRUPOS:
            return { ...state, ...action.payload, loading: false };
        case STORE_CONTAS_GRUPOS_ASYNC:
            return { ...state, loadingStore: true };
        case STORE_CONTAS_GRUPOS:
            return { ...state, ...action.payload, loadingStore: false };
        case UPDATE_CONTAS_GRUPOS_ASYNC:
            return { ...state, loadingUpdate: true };
        case UPDATE_CONTAS_GRUPOS:
            return { ...state, ...action.payload, loadingUpdate: false };
        case DESTROY_CONTAS_GRUPOS_ASYNC:
            return { ...state, loadingDestroy: true };
        case DESTROY_CONTAS_GRUPOS:
            return { ...state, ...action.payload, loadingDestroy: false };
        case GET_LIST_CONTAS_GRUPOS_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_CONTAS_GRUPOS:
            return { ...state, ...action.payload, loadingList: false };
        case GET_LIST_GRC_CONTA_EMP_ASYNC:
            return { ...state, loadingGrcContaEmp: true };
        case GET_LIST_GRC_CONTA_EMP:
            return { ...state, ...action.payload, loadingGrcContaEmp: false };
        case CLEAR_ERRORS_CONTAS_GRUPOS_CONTAS:
            return { ...state, ...action.payload };
        default: return state;
    }
}
