import React from "react";
import { Col, FormGroup, Row, Label, ListGroup, Alert, ListGroupItem } from "reactstrap";

const Contas = ({ pessoa }) => {
    return (
        <Col xs="12">
            <FormGroup>
                <div className="shadow shadow-showcase p-15">
                    <Label><h6>Vincular Contas</h6></Label>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label>Contas</Label>
                                <ListGroup>
                                    {!!pessoa.contas.length ?
                                        pessoa.contas.map((el, key) => {
                                            return (
                                                <ListGroupItem
                                                    key={key}
                                                    className="list-group-item-action">
                                                    {`${el.ctn_cd_conta} - ${el.ctn_nm_conta}`}
                                                </ListGroupItem>
                                            )
                                        })
                                        :
                                        <Alert color="light">Nenhuma Conta Vinculada</Alert>
                                    }
                                </ListGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>
        </Col>
    )
}

export default Contas;