import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import {
    GET_NOTAS_FISCAIS_ASYNC,
    GET_NOTAS_FISCAIS,
    UPDATE_DEVOLUCAO_NFE_ASYNC,
    UPDATE_DEVOLUCAO_NFE
} from "../actionTypes";
import Api from "../../services/api";
import { selectorNFe } from "../selectors";
import { toast } from "react-toastify";

function* getNotasFiscaisSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetNotasFiscais, fields);
        if (data.ok) {
            const { notasFiscais, totalValorNfe } = data;
            yield put({ type: GET_NOTAS_FISCAIS, payload: { notasFiscais, totalValorNfe } });
        }
    } catch (error) {
        yield put({ type: GET_NOTAS_FISCAIS, payload: {} });
    }
}
const apiGetNotasFiscais = async fields => {
    const { data } = await Api.get(`/api/notas_fiscais`, { params: fields });

    return data;
}

function* updateDevolucaoNFeSaga({ payload }) {
    try {
        const { fields, index, onSave } = payload;
        const notasFiscais = { ...yield select(selectorNFe) };
        const { nu_id } = notasFiscais.data[index];
        const { data, errors } = yield call(apiUpdateDevolucaoNfe, fields, nu_id);
        if (!!data && !!data.ok) {
            notasFiscais.data.splice(index, 1, data.notaFiscal);
            yield put({ type: UPDATE_DEVOLUCAO_NFE, payload: { notasFiscais } });
            toast.success(data.msg, toast.POSITION.TOP_RIGHT);
            onSave();
        }
        if (!!errors) {
            yield put({ type: UPDATE_DEVOLUCAO_NFE, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_DEVOLUCAO_NFE, payload: {} });
    }
}
const apiUpdateDevolucaoNfe = async (fields, nu_id) => {
    const data = await Api.put(`/api/notas_fiscais/updateDevelucao/${nu_id}`, fields);

    return data;
}


function* watchGetNotasFiscais() {
    yield takeLatest(GET_NOTAS_FISCAIS_ASYNC, getNotasFiscaisSaga);
}
function* watchUpdateDevolucaoNFe() {
    yield takeLatest(UPDATE_DEVOLUCAO_NFE_ASYNC, updateDevolucaoNFeSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetNotasFiscais),
        fork(watchUpdateDevolucaoNFe)
    ]);
}