
import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, Button, Row, Col, Form, FormGroup, Label, Input, CardTitle } from 'reactstrap';
import DataTable from 'react-data-table-component';
import sweetalert2 from "sweetalert2";
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import Add from "./Add";
import View from "./View";
import Edit from "./Edit";
import { getFuncionalidades, destroyFuncionalidades } from "../../../../redux/actions";
const Funcionalidades = ({
    loadingFuncionalidades,
    funcionalidades,
    getFuncionalidades,
    destroyFuncionalidades,
    loadingDeleteFunc,
    funcionalidadeToFind,
    clearFindFuncionalidade }) => {
    useEffect(() => {
        if (!!funcionalidadeToFind) {
            getFuncionalidades({ fields: { name: funcionalidadeToFind } });
            clearFindFuncionalidade();
        }
    }, [funcionalidadeToFind])
    //STATES
    const [filter, setFilter] = useState({ name: null, modulo_id: null, active: null });
    const [openModal, setOpenModal] = useState(null);
    const [selectedFunc, setselectedFunc] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //VARIÁVEIS
    const tableColumns = [
        {
            name: 'Nome',
            selector: 'name',
            center: true,
        },
        {
            name: 'Modulo',
            selector: 'modulo',
            center: true,
        },
        {
            name: 'Status',
            selector: 'status',
            center: true,
        },
        {
            name: 'Ações',
            selector: 'acoes',
            center: true
        }
    ];

    //FUNÇÕES
    const handleSelectedRows = useCallback(({ selectedRows }) => {
        setSelectedRows(selectedRows.map(({ id, name }) => ({ id, name })))
    }, [])
    const deleteRowsFunc = async () => {
        const confirm = await sweetalert2.fire({
            title: 'Deseja realmente excluir?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            reverseButtons: true,
            html: `
            <div style="max-height:200px; overflow-y:auto;">
                <ul class="list-group">
                    ${selectedRows.map(({ name }) => {
                return (`<li class="list-group-item-action list-group-item">${name}</li>`)
            }).join('')}
                </ul>
            </div>
        </div>`,
            icon: 'warning',
        });
        if (confirm.value) {
            const page = (!!funcionalidades && funcionalidades.current_page !== 1) ? funcionalidades.current_page : 1;
            const fields = { funcionalidades_ids: selectedRows, page }
            const onDelete = () => setToggleCleared(!toggleCleared);

            destroyFuncionalidades({ fields, onDelete });
        }

    }
    const submitFilter = e => {
        e.preventDefault();
        const fields = filter;
        getFuncionalidades({ fields });
    }
    return (
        <>
            <Add isOpen={openModal === 'add'} clickClose={() => setOpenModal(null)} onSave={() => setOpenModal(null)} />
            <View isOpen={openModal === 'view'} clickClose={() => setOpenModal(null)} selectedFunc={selectedFunc} />
            <Edit isOpen={openModal === 'edit'} clickClose={() => setOpenModal(null)} selectedFunc={selectedFunc} onUpdate={() => setOpenModal(null)} />
            <Row>
                <Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-10">
                    <Button color="primary" size="md" onClick={() => setOpenModal('add')}><i className="fa fa-plus"></i><span className="p-l-5">Incluir</span></Button>
                </Col>
                <Col xs="12">
                    <Card className="shadow">
                        <CardBody>
                            <CardTitle>
                                <h5>Filtro</h5>
                            </CardTitle>
                            <Form onSubmit={submitFilter}>
                                <Row>
                                    <Col md="5" sm="12">
                                        <FormGroup>
                                            <Label>Nome</Label>
                                            <Input placeholder="Informe o nome da funcionalidade"
                                                onChange={({ target: { value } }) => {
                                                    setFilter({ ...filter, name: value })
                                                }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4" sm="12">
                                        <FormGroup>
                                            <Label>Módulo</Label>
                                            <Input placeholder="Informe o módulo" disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2" sm="12">
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Typeahead
                                                id="selectStatus"
                                                labelKey="name"
                                                clearButton
                                                placeholder="Informe o Status"
                                                options={[{ name: 'Ativo', value: 1 }, { name: 'Inativo', value: 0 }]}
                                                onChange={(status) => {
                                                    if (!!status.length) {
                                                        setFilter({ ...filter, active: status[0].value })
                                                    } else {
                                                        setFilter({ ...filter, active: null })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="1" sm="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <FormGroup>
                                            <Button
                                                title="Pesquisar"
                                                disabled={loadingFuncionalidades}
                                                className="m-t-30"
                                                color="primary">
                                                <i className="fa fa-search"></i>
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs="12">
                    <Card className="shadow">
                        <CardBody className="p-t-20">
                            {loadingFuncionalidades ?
                                <div className="loader-box">
                                    <div className="loader-1"></div>
                                </div>
                                :
                                !!funcionalidades &&
                                <DataTable
                                    persistTableHead
                                    selectableRows
                                    pagination
                                    paginationServer
                                    columns={tableColumns}
                                    clearSelectedRows={toggleCleared}
                                    paginationPerPage={funcionalidades.per_page}
                                    paginationTotalRows={funcionalidades.total}
                                    onSelectedRowsChange={handleSelectedRows}
                                    contextMessage={{ message: 'Selecionado(s)', singular: 'item', plural: 'itens' }}
                                    contextActions={
                                        <Button color="danger" disabled={loadingDeleteFunc} onClick={() => deleteRowsFunc()}>{loadingDeleteFunc ? 'Processando...' : 'Remover'}</Button>
                                    }
                                    progressComponent={
                                        <div className="loader-box">
                                            <div className="loader-1"></div>
                                        </div>
                                    }
                                    progressPending={loadingFuncionalidades || loadingDeleteFunc}
                                    onChangePage={(page) => getFuncionalidades({ fields: { page } })}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                        rangeSeparatorText: 'de',

                                    }}
                                    paginationDefaultPage={funcionalidades.current_page}
                                    noDataComponent={<span className="p-20">Nenhuma Funcionalidade Cadastrada</span>}
                                    data={funcionalidades.data.map(({ name, modulo, active, id }, index) => {
                                        const status = (active === "1" ? <i className="fa fa-circle font-success"></i> : <i className="fa fa-circle font-danger"></i>)
                                        const acoes = (
                                            <div key={id} style={{ display: "flex", overflow: "auto" }}>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setselectedFunc(index);
                                                        setOpenModal('view');
                                                    }}
                                                >
                                                    <i className="fa fa-info-circle font-info f-24 m-10"></i>
                                                </a>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setselectedFunc(index);
                                                        setOpenModal('edit');
                                                    }}
                                                >
                                                    <i className="fa fa-edit font-primary f-24 m-10"></i>
                                                </a>
                                            </div>
                                        )
                                        return ({ id, name, modulo: !!modulo ? modulo.name : 'Não Informado', status, acoes })
                                    })}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = ({ Funcionalidades }) => {
    const { funcionalidades, loading: loadingFuncionalidades, loadingDeleteFunc } = Funcionalidades;
    return { funcionalidades, loadingFuncionalidades, loadingDeleteFunc };
}

export default connect(mapStateToProps, {
    getFuncionalidades,
    destroyFuncionalidades
})(Funcionalidades)