import {
    GET_FUNCIONALIDADES_ASYNC,
    STORE_FUNCIONALIDADES_ASYNC,
    UPDATE_FUNCIONALIDADES_ASYNC,
    DESTROY_FUNCIONALIDADES_ASYNC,
    LIST_FUNCIONALIDADES_ASYNC,
} from "../actionTypes";

export const getFuncionalidades = (payload = {}) => ({
    type: GET_FUNCIONALIDADES_ASYNC,
    payload
});

export const getListFuncionalidades = (payload = {}) => ({
    type: LIST_FUNCIONALIDADES_ASYNC,
    payload
});

export const storeFuncionalidade = payload => ({
    type: STORE_FUNCIONALIDADES_ASYNC,
    payload
});

export const updateFuncionalidade = payload => ({
    type: UPDATE_FUNCIONALIDADES_ASYNC,
    payload
});

export const destroyFuncionalidades = payload => ({
    type: DESTROY_FUNCIONALIDADES_ASYNC,
    payload
});

