import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    ModalHeader,
    Row,
} from "reactstrap";
import { connect } from "react-redux";
import DadosGerais from "./DadosGerais";
import Opcoes from "./Opcoes";
import Contas from "./Contas";
import Endereco from "./Endereco";
import Valores from "./Valores";

const View = ({
    isOpen,
    clickClose,
    selectedPessoa,
    pessoas,
}) => {
    //ESTADOS
    const pessoa = !!pessoas && pessoas.data[selectedPessoa];

    //VARIÁVEIS
    return (
        <Modal isOpen={isOpen} size="lg" >
            <ModalHeader toggle={() => clickClose()}>
                Informações Pessoa
            </ModalHeader>
            {!!pessoa &&
                <Form>
                    <ModalBody>
                        <Row>
                            <DadosGerais
                                pessoa={pessoa}
                            />
                            <Opcoes
                                pessoa={pessoa}
                            />
                            <Contas
                                pessoa={pessoa}
                            />
                            <Endereco
                                pessoa={pessoa}
                            />
                            <Valores
                                pessoa={pessoa}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={() => clickClose()}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}

const mapStateToProps = ({ Pessoas, TiposPessoas }) => {

    const { pessoas } = Pessoas;
    return {
        pessoas,
    };
}

export default connect(mapStateToProps, {

})(View);
