import {
    GET_LIST_PESSOAS_ASYNC,
    GET_LIST_PESSOAS,
    GET_PESSOAS_ASYNC,
    GET_PESSOAS,
    STORE_PESSOAS_ASYNC,
    STORE_PESSOAS,
    DESTROY_PESSOAS_ASYNC,
    DESTROY_PESSOAS,
    UPDATE_PESSOAS_ASYNC,
    UPDATE_PESSOAS,
    GET_LIST_PESSOAS_EMPRESA_ASYNC,
    GET_LIST_PESSOAS_EMPRESA
} from "../actionTypes";

const initial_state = {
    errors: {},
    list: { data: [], total: 0 },
    loadingList: false,
    pessoas: null,
    loading: false,
    loadingDestroy: false,
    listPessoasEmp: { data: [], total: 0 },
    loadingPessoasEmp: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_LIST_PESSOAS_ASYNC:
            return { ...state, loadingList: true };
        case GET_LIST_PESSOAS:
            return { ...state, ...action.payload, loadingList: false };
        case GET_PESSOAS_ASYNC:
            return { ...state, loading: true };
        case GET_PESSOAS:
            return { ...state, ...action.payload, loading: false };
        case STORE_PESSOAS_ASYNC:
            return { ...state, loadingStore: true };
        case STORE_PESSOAS:
            return { ...state, ...action.payload, loadingStore: false };
        case DESTROY_PESSOAS_ASYNC:
            return { ...state, loadingDestroy: true };
        case DESTROY_PESSOAS:
            return { ...state, ...action.payload, loadingDestroy: false };
        case UPDATE_PESSOAS_ASYNC:
            return { ...state, loadingUpdate: true };
        case UPDATE_PESSOAS:
            return { ...state, ...action.payload, loadingUpdate: false };
        case GET_LIST_PESSOAS_EMPRESA_ASYNC:
            return { ...state, loadingPessoasEmp: true };
        case GET_LIST_PESSOAS_EMPRESA:
            return { ...state, ...action.payload, loadingPessoasEmp: false };
        default: return state;
    }
}